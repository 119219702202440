import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";

import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete } from "@mui/material";

import Dropzone from "../../../components/Extra/Dropzone";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { objectDefault } from "../data";
import { useModalStyle } from "../../styles/styleModal";

import useMultimediasUpload from "../../hooks/useMultimediasUpload";
function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();

  // eslint-disable-next-line
  const { acti_fotos } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});

  useEffect(() => {
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = (eliminar) => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosGenerales: true,
      DatosArchivos: false,
    });

    setcargando(false);
    setimagenes([]);
    setmodificoImagenes(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);

  const [
    ocultar,
    // setocultar
  ] = useState(false);

  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    statusSeccion: false,
    DatosArchivos: false,
  });
  // eslint-disable-next-line
  const { DatosGenerales, statusSeccion, DatosArchivos } = seccionActive;

  const validacion = () => {
    if (true) {
      mostrarAlerta(`Faltan campos obligatorios `, "error");
      seterror({});

      return true;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return;
    }
    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, acti_fotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          acti_fotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          acti_user: usuario.usuario,
          acti_empresa: usuario.rucempresa,
          tabla: "actividad_cabecera",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return;
    }

    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, acti_fotos);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          acti_fotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          acti_user: usuario.usuario,
          acti_empresa: usuario.rucempresa,
          tabla: "actividad_cabecera",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  if (!open) {
    return null;
  }

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Draggable handle="#handle">
            <Box display="flex" justifyContent="center">
              <div
                style={ocultar ? modalStyleOculto : modalStyle}
                className={clases.paper}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography
                    id="handle"
                    style={{ cursor: "-webkit-grab" }}
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {editar ? "Editar" : "Agregar"} Actividad Cabecera.
                  </Typography>
                  <Typography
                    variant="h5"
                    color="initial"
                    align="center"
                  ></Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      aria-label=""
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive(true);
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems=" stretch"
                  width="100%"
                >
                  <Box>
                    <Button
                      variant={DatosGenerales ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ DatosGenerales: true })}
                    >
                      Datos Generales
                    </Button>
                  </Box>
                  {/* <FormControlLabel
                  control={
                    <Switch
                      checked={
                        conEstado === "ACTIVO" || conEstado === "ACTIVA"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setitem({
                          ...item,
                          conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                      color="primary"
                    />
                  }
                  label="Estado"
                /> */}
                </Box>
                <Divider />
                <Box className={clases.container}>
                  {DatosGenerales ? <></> : null}
                </Box>

                {/* <Divider /> */}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={
                      cargando ||
                      ((ObjectoActivo.nomEstado === "ANULADA" ||
                        ObjectoActivo.nomEstado === "APROBADO") &&
                        editar)
                    }
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>
    </>
  );
};

export default memo(ModalAsistencia);
