import React, { memo, useEffect, useState } from "react";
// MODULOS
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, Dialog } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100%",
    height: "75vh",
  },
  boxWidth: {
    width: "400px",
    [theme.breakpoints.up("sm")]: {
      width: "600px",
    },
    [theme.breakpoints.up("md")]: {
      width: "700px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1000px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "1200px",
    },
  },
  placesContainer: {
    position: "absolute",
    top: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 10,
    width: "300px",
  },
  comboInput: {
    width: "100%",
    padding: ".6rem",
  },
}));

const DialogMap = (props) => {
  const clases = useStyles();
  const {
    open,
    setopen,
    setitem,
    item,
    editar,
    propiedad,
    nombrePropiedad,
    solover,
  } = props;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_URL,
    libraries: ["places"],
  });
  const [position, setposition] = useState({
    lat: propiedad.latitud,
    lng: propiedad.longitud,
  });
  const [center, setcenter] = useState({
    lat: propiedad.latitud,
    lng: propiedad.longitud,
  });
  useEffect(() => {
    if (open) {
      setposition({
        lat: propiedad.latitud,
        lng: propiedad.longitud,
      });
      setcenter({
        lat: propiedad.latitud,
        lng: propiedad.longitud,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    const funcionInit = async () => {
      if (!navigator.geolocation) {
        alert(
          "Tu navegador no soporta el acceso a la ubicación. Intenta con otro"
        );
        if (editar) {
          return;
        }
        return setitem({
          ...item,
          [nombrePropiedad]: {
            longitud: -79.178298,
            latitud: -0.254916,
          },
        });
      }

      const onUbicacionConcedida = async (ubicacion) => {
        if (editar) {
          return;
        }

        return setitem({
          ...item,
          [nombrePropiedad]: {
            longitud: ubicacion.coords.longitude,
            latitud: ubicacion.coords.latitude,
          },
        });
      };

      const onErrorDeUbicacion = (err) => {};

      // Solicitar
      await navigator.geolocation.getCurrentPosition(
        onUbicacionConcedida,
        onErrorDeUbicacion,
        { enableHighAccuracy: true }
      );
    };
    funcionInit(); // eslint-disable-next-line
  }, []);

  if (!isLoaded) {
    return null;
  }
  if (!open) {
    return null;
  }
  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={() => {
        setcenter({ lat: propiedad.latitud, lng: propiedad.longitud });
        setopen(false);
      }}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={clases.boxWidth}>
        <Box className={clases.placesContainer}>
          <PlacesAutoComplete
            nombrePropiedad={nombrePropiedad}
            setcenter={setcenter}
            setposition={setposition}
            item={item}
            setitem={setitem}
            solover={solover}
          />
        </Box>
        <GoogleMap
          zoom={15}
          center={center}
          mapContainerClassName={clases.mapContainer}
          onClick={(e) => {
            if (solover) {
              return;
            }
            setposition({
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            });
            setitem({
              ...item,
              [nombrePropiedad]: {
                latitud: e.latLng.lat(),
                longitud: e.latLng.lng(),
              },
            });
          }}
        >
          <Marker position={position} />
        </GoogleMap>
      </Box>
    </Dialog>
  );
};

const PlacesAutoComplete = ({
  // propiedad,
  nombrePropiedad,
  setcenter,
  setposition,
  item,
  setitem,
  solover,
}) => {
  const clases = useStyles();
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });

    const { lat, lng } = await getLatLng(results[0]);

    setcenter({
      lat: lat,
      lng: lng,
    });

    if (solover) {
      return;
    }
    setitem({
      ...item,
      [nombrePropiedad]: {
        longitud: lng,
        latitud: lat,
      },
    });
    setposition({
      lat: lat,
      lng: lng,
    });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSelect(e.target.value);
          }
        }}
        disabled={!ready}
        className={clases.comboInput}
        placeholder="Busca una dirección"
      />
      <ComboboxPopover style={{ position: "absolute", zIndex: "9999" }}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

export default memo(DialogMap);
