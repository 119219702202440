import React, { useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useState } from "react";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import clienteAxios from "../../../config/axios";
import { Cancel, ChangeCircle, DeleteForever } from "@mui/icons-material";
import ConfirmacionDialog from "../../../views/components/ConfirmacionDialog";
import { withRouter } from "react-router-dom";
import MenuContext from "../../../Context/Menu/MenuContext";
const DialogEmpresas = ({ open, setopen, history }) => {
  const { usuario, iniciarSesion, usuarioAutenticado } = useContext(
    AuthContext
  );
  const { obtenerCategorias } = useContext(MenuContext);

  const [empresasData, setempresasData] = useState([]);
  const [nuevaEmpresa, setnuevaEmpresa] = useState("");
  const [openConfirm, setopenConfirm] = useState(false);

  useEffect(() => {
    const getempresasData = async () => {
      try {
        const res = await clienteAxios.post("empresas/logos-por-codigos", {
          mis_empresas: usuario.mis_empresas,
        });
        setempresasData(res.data || []);
      } catch (error) {
        console.log(error);

        setempresasData([]);
      }
    };
    getempresasData();
  }, [usuario.mis_empresas]);
  const handleChangeEmpresa = async () => {
    await iniciarSesion({
      usuario: usuario.usuario,
      sinpassword: true,
      password: "",
      empresa: nuevaEmpresa,
      from: "web",
    });
    // console.log({ res });
    await usuarioAutenticado();
    await setopenConfirm(false);
    obtenerCategorias();
    setopen(false);
    history.push("/Seguridad/Dashboard");
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        setopen(false);
        setnuevaEmpresa("");
      }}
    >
      <DialogTitle>MIS EMPRESAS</DialogTitle>
      <ConfirmacionDialog
        open={openConfirm}
        setopen={setopenConfirm}
        categoria="warning"
        titulo={`¿Esta seguro de cambiar a la empresa: ${nuevaEmpresa}?`}
        botones={[
          {
            tooltip: "Se cambiará de empresa",
            texto: "SI",
            funcion: () => {
              handleChangeEmpresa();
            },
            disabled: false,
            Icon: ChangeCircle,
            color: "success",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirm(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <List sx={{ pt: 0 }}>
        {empresasData.map((empresa) => (
          <ListItem disableGutters disablePadding key={empresa.empCodigo}>
            <ListItemButton
              onClick={() => {
                setnuevaEmpresa(empresa.empCodigo);
                setopenConfirm(true);
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: blue[100], color: blue[600] }}
                  src={empresa.empLogo}
                />
              </ListItemAvatar>
              <ListItemText primary={empresa.empCodigo} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <DialogContent>
        <DialogContentText></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setopen(false)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(DialogEmpresas);
