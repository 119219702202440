import React from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, Dialog, Slide, Typography } from "@mui/material";
import Botones from "./Botones";
const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: "#00a000",
    color: "white",
  },
  warning: {
    backgroundColor: "#ffa90b",
    color: "black",
  },
  error: {
    backgroundColor: "#ff1c06",
    color: "white",
  },
  font: { fontSize: ".9rem", fontWeight: "700" },
}));

export default function ConfirmacionDialog(props) {
  const clases = useStyles();
  const { open, setopen, titulo, categoria, botones } = props;
  const handleClose = () => {
    setopen(false);
  };

  if (!open) {
    return null;
  }
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ zIndex: "5000" }}
    >
      <Box className={categoria ? clases[categoria] : clases.warning}>
        <Box
          display="flex"
          justifyContent="space-between"
          p={1.5}
          alignItems={"center"}
        >
          <Typography
            variant="subtitle1"
            color=""
            component={"span"}
            className={clases.font}
          >
            {titulo}
          </Typography>
          <Box mr={1}></Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Botones botones={botones} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
