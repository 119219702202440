import * as React from "react";
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ButtonsAction from "../../components/ButtonsAction";
import {
  Cancel,
  Delete,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import TablaCabecera from "../../components/TablaCabecera";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import AuthContext from "../../../Context/Auth/AuthContext";

function Row({ row, index, usuario, socket }) {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const [openConfirmDialog, setopenConfirmDialog] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <ConfirmacionDialog
          open={openConfirmDialog}
          setopen={setopenConfirmDialog}
          categoria="warning"
          titulo={`¿Esta seguro de eliminar este registro?`}
          botones={[
            {
              tooltip: "Se eliminaran los registros",
              texto: "SI",
              funcion: () => {
                socket.current.emit("client:eliminarData", {
                  tabla: "turno_extra",
                  rucempresa: usuario.rucempresa,
                  rol: usuario.rol,
                  turnUser: usuario.usuario,
                  turnEmpresa: usuario.rucempresa,
                  turnId: row.turnId,
                  turnFecReg: row.turnFecReg,
                });
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Cancelar",
              texto: "NO",
              funcion: () => {
                setopenConfirmDialog(false);
              },
              disabled: false,
              Icon: Cancel,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <ButtonsAction
          claseCelda={claseCelda}
          agregarCelda={false}
          botones={[
            {
              tooltip: "Expandir",
              texto: row.dias.length,
              funcion: () => {
                setOpen(!open);
              },
              disabled: false,
              Icon: open ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: Delete,
              color: "error",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
          ]}
        />
        <TableCell component="th" scope="row" className={claseCelda}>
          {row.nombres}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Dia</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.dias.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell align="left" padding="none">
                        {new Date(
                          Date.parse(historyRow.fecha_inicio) +
                            new Date().getTimezoneOffset() * 60 * 1000
                        ).toLocaleString("es-ES", {
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TableTurnos({ turnos, socket }) {
  const { usuario } = React.useContext(AuthContext);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <TablaCabecera
            columns={[
              {
                id: "Persona",
                align: "left",
                minWidth: 200,
                tipo: "string",
                label: "Persona",
              },
            ]}
            habilitarOrdenar={false}
          />
        </TableHead>

        <TableBody>
          {turnos.map((row, index) => (
            <Row
              key={row.name}
              row={row}
              index={index}
              usuario={usuario}
              socket={socket}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
