import { useLocation } from "react-router-dom";

import { useCallback, useContext, useEffect, useState } from "react";

import queryString from "query-string";

import { IncomingMessage, OutgoingMessage, SendMessage } from "./Components";
// import { useChatStore, useAuthStore } from "../../../hooks";
import { Box, Divider, Typography } from "@mui/material";
import { BoxChat, TypographyChatActive } from "./style";
import clienteAxios from "../../config/axios";
import { useProvideSocket } from "./socket/useProvideSocket";
import { animateScroll } from "react-scroll";
import AuthContext from "../../Context/Auth/AuthContext";

export function scrollToBottom(id) {
  animateScroll.scrollToBottom({
    containerId: id,
    duration: 0,
  });
}

export function scrollToBottomAnimated(id) {
  animateScroll.scrollToBottom({
    containerId: id,
    duration: 100,
  });
}

export const ChatPage = () => {
  // const { onSelectChat, oneGetMensajes } = useChatStore();
  const location = useLocation();
  const [mensajes, setMensajes] = useState([]);
  const { socket } = useProvideSocket();
  const { usuario } = useContext(AuthContext);
  const [cargando, setCargando] = useState(false);
  const [primeraCarga, setprimeraCarga] = useState(true);
  const [primeraCargaConsulta, setprimeraCargaConsulta] = useState(true);
  const [pagina, setPagina] = useState(0);
  const { chat_id = "", name = "" } = queryString.parse(location.search);
  const getChat = useCallback(
    async (chat_id, page) => {
      setCargando(true);
      const res = await clienteAxios.get(
        `/messages?page=${page}&cantidad=20&search=&input=message_id&orden=false&chat_id=${chat_id}`
      );

      setMensajes((prev) => [...res.data.data.results.reverse(), ...prev]);
      const mensajesElement = document.getElementById("mensajes");
      if (mensajesElement && mensajesElement.scrollTop === 0) {
        mensajesElement.scrollTop = 10; // Ajusta este valor según sea necesario
      }
      setCargando(false);
      const ultimoMensaje =
        res.data.data.results[res.data.data.results.length - 1];

      if (primeraCargaConsulta) {
        setprimeraCargaConsulta(false);

        socket?.emit("client:read-mensaje", {
          tabla: "messages",
          rucempresa: usuario.rucempresa,
          rol: [], //vacio
          chat_id: chat_id,
          person_id: usuario.id, // login id persona
          last_read_message_id: ultimoMensaje.message_id, // el id del ultimo mensaje
        });
      }
    },
    [primeraCargaConsulta]
  );
  useEffect(() => {
    if (chat_id !== "") {
      getChat(Number(chat_id), pagina);
    }
  }, [chat_id, pagina]);
  const handleScroll = (e) => {
    const element = e.target;
    if (element.scrollTop === 0 && !cargando) {
      setPagina((prev) => prev + 1);
    }
  };
  useEffect(() => {
    const handleSendMensaje = (data) => {
      console.log('nuevo mensaje');
      
      if (data.chat_id === Number(chat_id)) {
        setMensajes((prev) => [...prev, data]);
        scrollToBottomAnimated("mensajes");
      }
      socket?.emit("client:read-mensaje", {
        tabla: "messages",
        rucempresa: usuario.rucempresa,
        rol: [], //vacio
        chat_id: chat_id,
        person_id: usuario.id, // login id persona
        last_read_message_id: data.message_id, // el id del ultimo mensaje
      });
    };

    const handleEliminadoExitoso = (data) => {
      setMensajes((prev) =>
        prev.filter((message) => message.message_id !== data.message_id)
      );
    };

    socket?.on("server:send-mensaje", handleSendMensaje);
    socket?.on("server:eliminadoExitoso", handleEliminadoExitoso);

    return () => {
      socket?.off("server:send-mensaje", handleSendMensaje);
      socket?.off("server:eliminadoExitoso", handleEliminadoExitoso);
    };
  }, [socket]);

  useEffect(() => {
    if (mensajes.length !== 0 && primeraCarga) {
      setTimeout(() => {
        setprimeraCarga(false);
        scrollToBottomAnimated("mensajes");
      }, 250);
    }
  }, [mensajes]);
  const handleEliminar = useCallback(
    async (message_id, urlImage) => {
      console.log({ message_id });
      if (urlImage) {
        await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
          urls: [urlImage],
        });
      }
      socket?.emit("client:eliminarData", {
        tabla: "messages",
        rucempresa: usuario.rucempresa,
        rol: [],
        message_id: message_id,
      });
    },
    [socket]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "80vh",
        ".swal2-container": {
          zIndex: 1301,
        },
        ".swal2-popup": {
          background: "red",
        },
      }}
    >
      <Box>
        <Divider
          sx={{
            display: "flex",

            alignItems: "center",
          }}
        >
          <TypographyChatActive
            variant="subtitle2"
            color="primary"
            textAlign={"center"}
            fontWeight={"700"}
          >
            {name}
          </TypographyChatActive>
          {cargando && <Typography variant="overline">CARGANDO...</Typography>}
        </Divider>
      </Box>
      <BoxChat id="mensajes" onScroll={handleScroll}>
        {mensajes.map((msg) =>
          usuario.id === msg.person_id ? (
            <OutgoingMessage
              msg={msg}
              key={msg.message_id}
              handleEliminar={handleEliminar}
            />
          ) : (
            <IncomingMessage msg={msg} key={msg.message_id} />
          )
        )}

        {/* <!-- Historia Fin --> */}
      </BoxChat>
      <SendMessage name={name} chat_id={chat_id} />
    </Box>
  );
};

export default ChatPage;
