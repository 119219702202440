import React from "react";
import Editor from "../../../../components/Extra/Editor";
import { ImageList, ImageListItem, TextField, Typography } from "@mui/material";
import { useModalStyle } from "../../../styles/styleModal";
import CampoDoc from "../../../components/CampoDoc";
import { useState } from "react";
import { useEffect } from "react";

const Contenido = ({ seccion2, item, setitem, disabled }) => {
  const clases = useModalStyle();
  const [cargando, setcargando] = useState(false);
  const handleChange = (e) => {
    setitem((prev) => ({
      ...prev,
      actaContenido: {
        ...prev.actaContenido,
        [seccion2]: {
          ...prev.actaContenido[seccion2],
          [e.target.name]: e.target.value,
        },
      },
    }));
  };
  useEffect(() => {
    setcargando(true);
    setTimeout(() => {
      setcargando(false);
    }, 500);
  }, [seccion2]);
  if (seccion2 === "") {
    return null;
  }
  if (!item.actaContenido || !item.actaContenido[seccion2]) {
    return null;
  }

  return (
    <>
      <TextField
        disabled={disabled}
        className={clases.formD}
        size="small"
        label="Titulo"
        value={item.actaContenido[seccion2].titulo}
        name="titulo"
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <CampoDoc
        disabled={disabled}
        disabledE={disabled}
        extension="IMAGEN"
        label="Imagen"
        setcargando={() => {}}
        propiedad={"foto"}
        item={item.actaContenido[seccion2]}
        setitem={(e) => {
          handleChange({
            target: {
              name: "foto",
              value: e.foto,
            },
          });
        }}
        celda={false}
      />
      {!cargando && (
        <Editor
        disabled={disabled}
          label={
            false ? (
              <>
                <Typography variant="body1" color="error" component={"span"}>
                  Contenido
                </Typography>
              </>
            ) : (
              "Contenido"
            )
          }
          propiedad={"contenido"}
          item={item.actaContenido[seccion2]}
          setitem={(e) => {
            if (disabled) {
              return;
            }
            if (typeof e === "function") {
              handleChange({
                target: {
                  name: "contenido",
                  value: e().contenido,
                },
              });
            }
            if (typeof e === "object") {
              handleChange({
                target: {
                  name: "contenido",
                  value: e.contenido,
                },
              });
            }
          }}
        />
      )}
      <ImageList sx={{ width: 500, height: 130 }} cols={4} rowHeight={120}>
        <ImageListItem>
          <img
            srcSet={`${item.actaContenido[seccion2].foto}?w=200h=120&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.actaContenido[seccion2].foto}?w=200h=120&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      </ImageList>
    </>
  );
};

export default Contenido;
