import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../../config/validaciones";
import MenuContext from "../../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../../config/const";
import TablaCabecera from "../../../../../components/Extra/TablaCabecera";
import RowItem from "./RowItemEdit/RowItemDocumentos";
import RowAgregarItem from "./RowItemEdit/RowAgregarItemDocumentos";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  Collapse,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  MenuItem,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const { arrayExport, setarrayExport, row, index, campoExport } = props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { socket, usuario } = useContext(AuthContext);
  const [expandir, setexpandir] = useState(false);
  const [agregarItemLista, setagregarItemLista] = useState(false);

  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row });
  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.emit(
        "client:eliminarData",
        trimPropsItem({
          tabla: "documento",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          docUser: usuario.usuario,
          docEmpresa: usuario.rucempresa,
          docId: row.docId,
        })
      );
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem({ ...row });
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "documento",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          novUser: usuario.usuario,
          novEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (item.banNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro       ?`}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          expandir={expandir}
          setexpandir={setexpandir}
          mostrarEspandir={true}
          campoExport={campoExport}
          campoEstado="plancEstado"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          item={item}
          setitem={setitem}
        />{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id="docTipo"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.docTipo}
              onChange={(e) =>
                setitem({
                  ...item,
                  docTipo: e.target.value,
                })
              }
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["CLIENTE", "GUARDIA", "SUPERVISOR", "ADMINISTRACION","EMPRESA"].map(
                (item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                }
              )}
            </TextField>
          ) : (
            `${item.docTipo}`
          )}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docCodigo}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.docFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docUser}
        </TableCell>
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
          
                alignItems="center"
              >
                <Typography variant="body1" gutterBottom component="span">
                  Propiedades
                </Typography>{" "}
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "nombre",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Nombre",
                    },

                    {
                      id: "obligatorio",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Obligatorio",
                    },
                    {
                      id: "extension",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Extensión",
                    },
                    {
                      id: "fecExpira",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Fec. Expira",
                    },
                  ]}
                />
                <TableBody>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      item={item}
                      setitem={setitem}
                      setagregarItemLista={setagregarItemLista}
                    />
                  ) : null}
                  {item.docDocumentos.map((consigna, index) => {
                    return (
                      <RowItem
                        consigna={consigna}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
