export const columns = [
  {
    id: "preTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "prePeriodo",
    label: "Periodo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prePeriodo",
    align: "center",
    ocultar: true,
  },
  {
    id: "tsueldo",
    label: "Valor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "tsueldo",
    align: "center",
    ocultar: true,
  },
];
export const columnsPnormal = [
  {
    id: "preTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preTipo",
    align: "center",
    ocultar: true,
  },

  {
    id: "prePeriodo",
    label: "Periodo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prePeriodo",
    align: "center",
    ocultar: true,
  },

  {
    id: "tsueldo",
    label: "sueldo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "tsueldo",
    align: "center",
    ocultar: true,
  },

  {
    id: "tpatronal",
    label: "patronal",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "tpatronal",
    align: "center",
    ocultar: true,
  },

  {
    id: "tindividual",
    label: "individual",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "tindividual",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "preTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "prePeriodo",
    label: "Periodo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "preCedula",
    label: "Cédula",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "preNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "left",
    ocultar: true,
  },

  {
    id: "preValor",
    label: "Valor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "preFecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "preUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijosPnormal = [
  {
    id: "preTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preTipo",
    align: "center",
    ocultar: true,
  },

  {
    id: "prePeriodo",
    label: "Periodo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prePeriodo",
    align: "center",
    ocultar: true,
  },

  {
    id: "preCedula",
    label: "Cédula",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preCedula",
    align: "center",
    ocultar: true,
  },

  {
    id: "preNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preNombre",
    align: "left",
    ocultar: true,
  },

  {
    id: "preDias",
    label: "Dias",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preDias",
    align: "center",
    ocultar: true,
  },

  {
    id: "preValor",
    label: "Sueldo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preValor",
    align: "center",
    ocultar: true,
  },

  {
    id: "prePatronal",
    label: "Patronal",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "prePatronal",
    align: "center",
    ocultar: true,
  },

  {
    id: "preIndividual",
    label: "Individual",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preIndividual",
    align: "center",
    ocultar: true,
  },

  {
    id: "preFecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "preUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "preUser",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  //   preId: 1,
  preIdPersona: "",
  ausDocuPersona: "",
  ausNomPersona: "",
  ausPerIdCliente: "",
  ausPerDocuCliente: "",
  ausPerNombreCliente: "",
  ausPuesto: [],
  ausMotivo: "", // [ENFERMEDADES IESS, PERMISO PERSONAL,PATERNIDAD,DEFUNCION FAMILIAR, INJUSTIFICADA]

  ausFechas: [],
  ausFechasConsultaDB: [],
  ausDetalle: "",
  ausDocumento: "", // input file
  ausFotos: [], // dropzone
  preIdMulta: "", // vacio
  preUser: "",
  preEmpresa: "",
  preFecReg: "",
  ausDiasPermiso: "1",
  Todos: "",
  ausEstado: "EN PROCESO",
  ausStatusDescripcion: "",
};
