export const columns = [
  {
    id: "turnFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "fecha",
    orden: 1,
    propiedad: "turnFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "tregistros",
    label: "Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "cedula",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "cedula",
    align: "left",
    ocultar: true,
  },

  {
    id: "ruc",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "ruc",
    align: "left",
    ocultar: true,
  },

  {
    id: "turnEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turnEstado",
    align: "center",
    ocultar: true,
  },

  {
    id: "turnMotivo",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turnMotivo",
    align: "center",
    ocultar: true,
  },

  {
    id: "turnStatusDescripcion",
    label: "Descripción",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turnStatusDescripcion",
    align: "center",
    ocultar: true,
  },

  {
    id: "turnUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turnUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // turnId: 1,
  turnIdPersona: 0,
  turnIdDOperativo: 0,
  turnIdPermiso: 0,
  turnIdMulta: 0,
  turnMotivo: "",
  turnEstado: "",
  turnAutorizado: "",
  turnDetalle: "",
  turnStatusDescripcion: "",
  turnUser: "",
  turnEmpresa: "",
  turnFecReg: "",
  turnFecUpd: "",
  cedula: "",
  nombres: "",
  idCliente: 0,
  ruc: "",
  razonSocial: "",
  ubicacion: "",
  puesto: "",
  turnIdsJornadaLaboral: [],
  dias: [],
  turnIdPersonaReemplazada: 0,
  turnIdHorario: 0,
};
