import React, { memo } from "react";
import { withRouter } from "react-router";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import { ExpandLess, ExpandMore, PictureAsPdf } from "@mui/icons-material";
import clienteAxios from "../../../config/axios";
import { saveAs } from "save-as";
import { useContext } from "react";
import AuthContext from "../../../Context/Auth/AuthContext";
import { useState } from "react";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    setexpandido,
    expandido,
    funcionReload,
    personasData,
    itemExport,
    setitemExport,
    subEstado,
    perfil,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const [cargando, setcargando] = useState(false);
  return (
    <>
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  className={classes.margin}
                  checked={
                    personasData.length === 0
                      ? false
                      : !personasData.some((item) => {
                          return !itemExport.hijos.some((itemPersonaData) => {
                            return itemPersonaData === item[campoExport];
                          });
                        })
                  }
                  onChange={async (e) => {
                    if (e.target.checked) {
                      if (personasData.length === 0 && !expandido) {
                        const response = await funcionReload();
                        const itemDeEsteRow = {
                          padre: row[campoExport],
                          hijos: response.map(
                            (itemPersona) => itemPersona[campoExport]
                          ),
                        };

                        if (!itemExport.padre === "") {
                          const res = arrayExport.map((itemArray) => {
                            if (itemArray.padre === row[campoExport]) {
                              return itemDeEsteRow;
                            } else {
                              return itemArray;
                            }
                          });

                          setarrayExport(res);
                          setitemExport(itemDeEsteRow);
                        } else {
                          setarrayExport([...arrayExport, itemDeEsteRow]);
                          setitemExport(itemDeEsteRow);
                        }
                        return setexpandido(true);
                      }

                      if (itemExport.padre === "") {
                        const itemDeEsteRow = {
                          padre: row[campoExport],
                          hijos: personasData.map(
                            (itemPersona) => itemPersona[campoExport]
                          ),
                        };
                        setarrayExport([...arrayExport, itemDeEsteRow]);
                        setitemExport(itemDeEsteRow);
                      } else {
                        const itemDeEsteRow = {
                          padre: row[campoExport],
                          hijos: personasData.map(
                            (itemPersona) => itemPersona[campoExport]
                          ),
                        };
                        const res = arrayExport.map((itemArray) => {
                          if (itemArray.padre === row[campoExport]) {
                            return itemDeEsteRow;
                          } else {
                            return itemArray;
                          }
                        });

                        setarrayExport(res);
                        setitemExport(itemDeEsteRow);
                      }
                    } else {
                      setarrayExport(
                        arrayExport.filter((itemArray) => {
                          return itemArray.padre !== row[campoExport];
                        })
                      );
                      setitemExport({
                        padre: "",
                        hijos: [],
                      });
                    }
                  }}
                  color="secondary"
                />
              }
            />{" "}
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          ml={1}
          mr={1}
        >
          <>
            <Tooltip title="Expandir">
              <IconButton
                className={classes.margin}
                onClick={() => {
                  setexpandido(!expandido);

                  if (personasData.length === 0 && !expandido) {
                    funcionReload();
                  }
                }}
              >
                {expandido ? (
                  <ExpandLess color="secondary" />
                ) : (
                  <ExpandMore color="secondary" />
                )}
              </IconButton>
            </Tooltip>{" "}
            <Tooltip title="PDF">
              <IconButton
                disabled={cargando}
                className={classes.margin}
                onClick={async () => {
                  setcargando(true);
                  const json = {
                    rucempresa: row.docEmpresa, // propiedad del item
                    destinatario: subEstado, // propiedad del item o del tab
                    encDocId: row.encDocId, // propiedad encDocId del item
                    usuario: usuario.usuario,
                    nombre: usuario.nombre,
                  };

                  const res = await clienteAxios.post(
                    `/reportes/encuesta_evaluacion`,
                    json,
                    { responseType: "blob" }
                  );

                  const pdfBlob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  saveAs(pdfBlob, `${perfil} - ${subEstado}.pdf`);
                  setcargando(false);
                }}
              >
                <PictureAsPdf color={cargando ? "inherit" : "error"} />
              </IconButton>
            </Tooltip>
          </>
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
