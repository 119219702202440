import React, { memo, useState, useContext } from "react";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregar = (props) => {
  const { setagregar } = props;
  const [item, setitem] = useState({
    plancCodigo: "",
    plancNombre: "",
    plancDebe: "",
    plancHaber: "",
    plancEmpresa: "",
    plancEstado: "ACTIVA",
    plancUser: "",
    plancFecReg: "",
    Todos: "",
  });

  const classes = useRowStyles();
   

  const { socket, usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.emit("client:guardarData", {
        ...item,
        tabla: "registro",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        plancUser: usuario.usuario,
        plancEmpresa: usuario.rucempresa,
      });
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (item.catNombre === "") {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  plancCodigo: "",
                  plancNombre: "",
                  plancDebe: "",
                  plancHaber: "",
                  plancEmpresa: "",
                  plancEstado: "ACTIVA",
                  plancUser: "",
                  plancFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  plancCodigo: "",
                  plancNombre: "",
                  plancDebe: "",
                  plancHaber: "",
                  plancEmpresa: "",
                  plancEstado: "ACTIVA",
                  plancUser: "",
                  plancFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <FormControlLabel
              className={classes.margin}
              onChange={(e) => {
                setitem({
                  ...item,
                  plancEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                });
              }}
              control={
                <Checkbox
                  value="ACTIVO"
                  className={classes.margin}
                  checked={
                    item["plancEstado"] === "ACTIVA" ||
                    item["plancEstado"] === "ACTIVO"
                      ? true
                      : false
                  }
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            value={item.plancCodigo}
            onChange={(e) =>
              setitem({
                ...item,
                plancCodigo: e.target.value,
              })
            }
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            value={item.plancNombre}
            onChange={(e) =>
              setitem({
                ...item,
                plancNombre: e.target.value,
              })
            }
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            type="number"
            value={item.plancDebe}
            onChange={(e) =>
              setitem({
                ...item,
                plancDebe: e.target.value,
              })
            }
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none">
          <TextField
            id=""
            type="number"
            value={item.plancHaber}
            onChange={(e) =>
              setitem({
                ...item,
                plancHaber: e.target.value,
              })
            }
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {usuario.usuario}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
