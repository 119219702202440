import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton, FormControlLabel, Checkbox } from "@mui/material";
// eslint-disable-next-line
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import Cargando from "../../../../../components/Extra/Cargando";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  // const { tienePermiso, alertaPermiso } = usePermiso("Examenes");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { open, setopen, item, setitem, disabled } = props;

  const { docCliDocNumero, docCliRazonSocial, docPersonas } = item;

  const [buscador, setbuscador] = useState("");
  const setitemsPersonas = (guardias, cli) => {
    const resultado = guardias.map((itemGuardia) => {
      if (cli) {
        return {
          foto: itemGuardia.perFoto,
          nombre: itemGuardia.cliNombreComercial,
          documento: itemGuardia.cliDocNumero,
          id: itemGuardia.cliId,
        };
      } else {
        return {
          foto: itemGuardia.perFoto,
          nombre: itemGuardia.perApellidos + " " + itemGuardia.perNombres,
          documento: itemGuardia.perDocNumero,
          id: itemGuardia.perId,
        };
      }
    });

    return resultado;
  };

  // eslint-disable-next-line

  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const [clientesData, setclientesData] = useState([]);
  const [modalStyle] = useState(getModalStyle);

  const [personasData, setpersonasData] = useState(null);
  useEffect(() => {
    const getDatasupervisoresData = async () => {
      if (
        item.docDestinatario === "CLIENTE" ||
        item.docDestinatario === "PROVEEDOR"
      ) {
        try {
          const resPersonas = await clienteAxios(
            `/clientes/filtrobusqueda/0?search=&estado${
              item.docDestinatario === "CLIENTE" ? "CLIENTES" : "PROVEEDORES"
            }`
          );

          setpersonasData(setitemsPersonas(resPersonas.data.data, true));
        } catch (error) {
          setpersonasData([]);
        }
      }
      if (
        item.docDestinatario === "SUPERVISOR" ||
        item.docDestinatario === "ADMINISTRACION"
      ) {
        try {
          const resPersonas = await clienteAxios(
            `/personas/filtro/0?search&estado=${
              item.docDestinatario === "SUPERVISOR"
                ? "SUPERVISORES"
                : "ADMINISTRACION"
            }`
          );

          setpersonasData(setitemsPersonas(resPersonas.data.data));
        } catch (error) {
          setpersonasData([]);
        }
      }
      if (item.docDestinatario === "GUARDIA") {
        try {
          try {
            if (item.docCliDocNumero === "") {
              return setpersonasData([]);
            }
            const res = await clienteAxios.get(
              `/personas/listado/:codigo?docnumero=${item.docCliDocNumero}`
            );

            setpersonasData(setitemsPersonas(res.data.data));
          } catch (error) {
            setpersonasData([]);
          }
        } catch (error) {
          setpersonasData([]);
        }
      }
    };
    if (!personasData && open) {
      getDatasupervisoresData();
    }

    // eslint-disable-next-line
  }, [open]);
  const defaultActive = () => {
    setopen(false);

    seterror({});
    setcargando(false);
    setpersonasData([]);
    setpersonasData(null);
    setbuscador("");
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const validacion = () => {
    if (item.docDestinatario === "GUARDIA") {
      if (docCliDocNumero.trim() === "" || docPersonas.length === 0) {
        seterror({
          docCliDocNumero: docCliDocNumero.trim() === "",
          docPersonas: docPersonas.length === 0,
        });
        return true;
      } else {
        return false;
      }
    } else {
      if (docPersonas.length === 0) {
        seterror({
          docPersonas: docPersonas.length === 0,
        });
        return true;
      } else {
        return false;
      }
    }
  };

 

   

  if (!open) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Seleccione.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Divider />
              {!personasData ? (
                <Cargando titulo={"Cargando..."} />
              ) : (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  alignItems="flex-end"
                  mt={1}
                  maxHeight={"60vh"}
                  overflow="scroll"
                  sx={{ overflowX: "hidden" }}
                >
                  <>
                    {item.docDestinatario === "GUARDIA" ? (
                      <FormControl
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                      >
                        <Autocomplete
                          disabled={disabled}
                          disableClearable={true}
                          options={clientesData.map((item) => {
                            return (
                              item.cliRazonSocial +
                              "/+/" +
                              item.cliDocNumero +
                              "/+/" +
                              item.cliId
                            );
                          })}
                          getOptionLabel={(option) => {
                            return option.replace("/+/", " ").split("/+/")[0];
                          }}
                          id="controlled-demo"
                          value={
                            (
                              docCliRazonSocial +
                              " " +
                              docCliDocNumero
                            ).trim() === ""
                              ? ""
                              : docCliRazonSocial + " " + docCliDocNumero
                          }
                          onChange={async (event, newValue) => {
                            if (newValue) {
                              const options = newValue.split("/+/");

                              const res = await clienteAxios.get(
                                `/personas/listado/:codigo?docnumero=${options[1]}`
                              );

                              setbuscador("");
                              setpersonasData(setitemsPersonas(res.data.data));
                              try {
                                setitem({
                                  ...item,
                                  docCliId: options[2],
                                  docCliDocNumero: options[1],
                                  docCliRazonSocial: options[0],
                                  docPersonas: [],
                                });
                              } catch (error) {
                                return mostrarAlerta(
                                  "No hay personas",
                                  "error"
                                );
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              disabled={disabled}
                              size="small"
                              required
                              fullWidth
                              label="Selección   Cliente"
                              error={error.docCliDocNumero}
                              onChange={async (e) => {
                                if (e.target.value === "") {
                                  return mostrarAlerta(
                                    "Ingrese datos",
                                    "error"
                                  );
                                }
                                try {
                                  if (e.target.value.length < 3) {
                                    return;
                                  }
                                  const res = await clienteAxios.get(
                                    `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                  );

                                  setclientesData(res.data.data);
                                } catch (error) {}
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    ) : null}

                    {/* docCliId */}

                    <Divider className={clases.formAllW} />
                    <Box
                      display={"flex"}
                      alignItems="center"
                      className={clases.formAllW}
                    >
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent={"center"}
                        mt={1}
                        maxHeight={"60vh"}
                        overflow="scroll"
                        sx={{ overflowX: "hidden" }}
                        className={clases.formD}
                      >
                        <Typography
                          variant="body1"
                          color={error.docPersonas ? "error" : "initial"}
                          className={clases.formD}
                        >
                          {docPersonas.length === 0 || docPersonas.length === 1
                            ? "Seleccionado: "
                            : "Seleccionados: "}
                          {docPersonas.length}{" "}
                          <FormControlLabel
                            label=""
                            control={
                              <Checkbox
                                disabled={disabled}
                                checked={
                                  [...personasData].length === 0
                                    ? false
                                    : buscador.trim() === ""
                                    ? [...personasData]
                                        .map((itemGuardia) => itemGuardia.id)
                                        .every((elemento) =>
                                          docPersonas
                                            .map(
                                              (itemelemento) => itemelemento.id
                                            )
                                            .includes(elemento)
                                        )
                                    : [...personasData]
                                        .filter(
                                          (itemGuardiaData) =>
                                            itemGuardiaData.nombre
                                              .toLowerCase()
                                              .includes(
                                                buscador.toLowerCase()
                                              ) ||
                                            itemGuardiaData.documento
                                              .toLowerCase()
                                              .includes(buscador.toLowerCase())
                                        )
                                        .map((itemGuardia) => itemGuardia.id)
                                        .every((elemento) =>
                                          docPersonas
                                            .map(
                                              (itemelemento) => itemelemento.id
                                            )
                                            .includes(elemento)
                                        )
                                }
                                onChange={(e) => {
                                  if (buscador.trim() === "") {
                                    if (e.target.checked) {
                                      setitem({
                                        ...item,
                                        docPersonas: [...personasData],
                                      });
                                    } else {
                                      setitem({
                                        ...item,
                                        docPersonas: [],
                                      });
                                    }
                                  } else {
                                    if (e.target.checked) {
                                      setitem({
                                        ...item,
                                        docPersonas: [...personasData].filter(
                                          (itemGuardiaData) =>
                                            itemGuardiaData.nombre
                                              .toLowerCase()
                                              .includes(
                                                buscador.toLowerCase()
                                              ) ||
                                            itemGuardiaData.documento
                                              .toLowerCase()
                                              .includes(buscador.toLowerCase())
                                        ),
                                      });
                                    } else {
                                      setitem({
                                        ...item,
                                        docPersonas: [],
                                      });
                                    }
                                  }
                                }}
                                color="primary"
                              />
                            }
                          />
                        </Typography>
                      </Box>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Buscar"
                        value={buscador}
                        onChange={(e) => {
                          setbuscador(e.target.value);
                        }}
                      />
                    </Box>
                    <List className={clases.formAllW} dense>
                      {buscador.trim() === ""
                        ? [...personasData].map((value) => {
                            const checked = docPersonas.some(
                              (itemGuardia) => itemGuardia.id === value.id
                            );
                            const labelId = `checkbox-list-secondary-label-${value}`;
                            return (
                              <ListItem
                                key={value.id}
                                secondaryAction={
                                  <Checkbox
                                    disabled={disabled}
                                    edge="end"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setitem({
                                          ...item,
                                          docPersonas: [
                                            ...item.docPersonas,
                                            value,
                                          ],
                                        });
                                      } else {
                                        setitem({
                                          ...item,
                                          docPersonas: item.docPersonas.filter(
                                            (itemFisguardia) =>
                                              itemFisguardia.id !== value.id
                                          ),
                                        });
                                      }
                                    }}
                                    checked={checked}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                }
                                disablePadding
                              >
                                <ListItemButton>
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={`Avatar n°${value + 1}`}
                                      src={`${value.foto}`}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    id={labelId}
                                    primary={`${value.nombre}`}
                                    primaryTypographyProps={{
                                      color: value.supervisor
                                        ? "primary"
                                        : "secondary",
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })
                        : [...personasData]
                            .filter(
                              (itemGuardiaData) =>
                                itemGuardiaData.nombre
                                  .toLowerCase()
                                  .includes(buscador.toLowerCase()) ||
                                itemGuardiaData.documento
                                  .toLowerCase()
                                  .includes(buscador.toLowerCase())
                            )
                            .map((value) => {
                              const checked = docPersonas.some(
                                (itemGuardia) => itemGuardia.id === value.id
                              );
                              const labelId = `checkbox-list-secondary-label-${value}`;
                              return (
                                <ListItem
                                  key={value.id}
                                  secondaryAction={
                                    <Checkbox
                                      disabled={disabled}
                                      edge="end"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setitem({
                                            ...item,
                                            docPersonas: [
                                              ...item.docPersonas,
                                              value,
                                            ],
                                          });
                                        } else {
                                          setitem({
                                            ...item,
                                            docPersonas:
                                              item.docPersonas.filter(
                                                (itemFisguardia) =>
                                                  itemFisguardia.id !== value.id
                                              ),
                                          });
                                        }
                                      }}
                                      checked={checked}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  }
                                  disablePadding
                                >
                                  <ListItemButton>
                                    <ListItemAvatar>
                                      <Avatar
                                        alt={`Avatar n°${value + 1}`}
                                        src={`${value.foto}`}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      id={labelId}
                                      primary={`${value.nombre}`}
                                      primaryTypographyProps={{
                                        color: value.supervisor
                                          ? "primary"
                                          : "secondary",
                                      }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                    </List>
                  </>
                </Box>
              )}

              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                ></Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    className={clases.formD}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      if (validacion()) {
                        return mostrarAlerta(
                          "Faltan campos por llenar",
                          "error"
                        );
                      }

                      setopen(false);
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
