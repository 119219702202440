import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Typography, Card } from "@mui/material";
// import { makeStyles } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "black",
    "&:hover": {
      color: "black",
      backgroundColor: "#ccc",
    },
  },
  margin: {
    margin: "15px",
  },
  caja: {
    [theme.breakpoints.up("xs")]: {
      flexBasis: "100%",
    },
    [theme.breakpoints.up("md")]: {
      flexBasis: "30%",
    },
    [theme.breakpoints.up("lg")]: {
      flexBasis: "45%",
    },
  },
  titulo: {
    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
    },
  },
  subTitulo: {
    [theme.breakpoints.down("md")]: {
      fontSize: "9px",
    },
  },
}));
const Caja = (props) => {
  const { titulo, descripcion, enlace } = props;
  const classes = useStyles();

  return (
    <Box m={1} flexGrow="1" className={classes.caja}>
      <Card className={classes.root}>
        <Box p={1}>
          <Link to={enlace} className={classes.root}>
            <Box>
              <Typography variant="h5" component="h2">
                {titulo}
              </Typography>
            </Box>
            <Typography color="primary">Descripción :</Typography>
            <Typography variant="body2" component="p">
              {descripcion}
            </Typography>
          </Link>
        </Box>
      </Card>
    </Box>
  );
};

export default Caja;
