import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../config/const";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";

import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { v4 } from "uuid";
import CampoDoc from "../../../components/CampoDoc";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  ocultar: { display: "none" },
}));

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    bancos,
    item,
    // setitem,
    setagregar,
    socket,
    tienePermiso,
    alertaPermiso,
  } = props;
  const [itemNuevo, setItemNuevo] = useState({
    ccComprobante: "",
    ccTipo: "EFECTIVO",
    ccBanco: "",
    ccNumero: "0",
    ccDeposito: "NO",
    ccValor: "0",
    ccFechaAbono: `${new Date().getFullYear()}-${(
      "0" +
      (new Date().getMonth() + 1)
    ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
    ccDetalle: "",
    ccProcedencia: "",
    ccEstado: "ACTIVO",
    imprimir: false,
  });
  const [cargando, setcargando] = useState(false);

  const classes = useRowStyles();
  //gql eliminar user

  const { usuario } = useContext(AuthContext);

  const handleAgregar = async (enviarCorreo) => {
    console.log("a");

    if (!tienePermiso("añadir")) {
      return alertaPermiso("añadir");
    }
    console.log("a");

    socket.current.emit(
      "client:actualizarData",
      trimPropsItem({
        ...item,
        enviarCorreo,
        ccPagos: [
          { ...itemNuevo, ccUsuario: usuario.usuario, uuid: v4(), nuevo: true },
          ...item.ccPagos,
        ],
        tabla: "cuentasporcobrar",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        ccUser: usuario.usuario,
        ccEmpresa: usuario.rucempresa,
      })
    );
    try {
    } catch (error) {}
  };
  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };
  const botonDisabled = () => {
    if (
      itemNuevo.ccTipo === "" ||
      itemNuevo.ccDeposito === "" ||
      itemNuevo.ccValor === "" ||
      Number(itemNuevo.ccValor) <= 0 ||
      itemNuevo.ccFechaAbono === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [openCDCorreo, setopenCDCorreo] = useState(false);
  const [openCDCorreoNC, setopenCDCorreoNC] = useState(false);
  // PARA EL DOCUMENTO

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openCDCorreoNC}
        setopen={setopenCDCorreoNC}
        titulo={`¿Guardar de todas formas?`}
        funcion={() => {
          setopenCDCorreo(true);
        }}
        funcionNo={() => {
          setopenCDCorreoNC(false);
        }}
      />
      <ConfirmacionDialog
        open={openCDCorreo}
        setopen={setopenCDCorreo}
        titulo={`¿Desea notificar al cliente sobre el pago realizado?`}
        funcion={() => {
          handleAgregar(true);
          setagregar(false);
          setItemNuevo({
            ccTipo: "EFECTIVO",
            ccBanco: "",
            ccNumero: "0",
            ccDeposito: "NO",
            ccValor: "0",
            ccFechaAbono: `${new Date().getFullYear()}-${(
              "0" +
              (new Date().getMonth() + 1)
            ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
            ccDetalle: "",
            ccProcedencia: "",
            ccEstado: "ACTIVO",
            imprimir: false,
          });
        }}
        funcionNo={() => {
          handleAgregar(false);
          setagregar(false);
          setItemNuevo({
            ccTipo: "EFECTIVO",
            ccBanco: "",
            ccNumero: "0",
            ccDeposito: "NO",
            ccValor: "0",
            ccFechaAbono: `${new Date().getFullYear()}-${(
              "0" +
              (new Date().getMonth() + 1)
            ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
            ccDetalle: "",
            ccProcedencia: "",
            ccEstado: "ACTIVO",
            imprimir: false,
          });
        }}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemNuevo({
                  ccTipo: "EFECTIVO",
                  ccBanco: "",
                  ccNumero: "0",
                  ccDeposito: "NO",
                  ccValor: "0",
                  ccFechaAbono: `${new Date().getFullYear()}-${(
                    "0" +
                    (new Date().getMonth() + 1)
                  ).slice(-2)}-${("0" + new Date().getDate()).slice(-2)}`,
                  ccDetalle: "",
                  ccProcedencia: "",
                  ccEstado: "ACTIVO",
                  imprimir: false,
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled() || cargando}
              className={classes.margin}
              onClick={async () => {
                console.log(itemNuevo);
                console.log(
                  (itemNuevo.ccTipo === "DEPOSITO" ||
                    itemNuevo.ccTipo === "TRANSFERENCIA") &&
                    itemNuevo.ccNumero !== "0"
                );
                if (
                  (itemNuevo.ccTipo === "DEPOSITO" ||
                    itemNuevo.ccTipo === "TRANSFERENCIA") &&
                  itemNuevo.ccNumero !== "0"
                ) {
                  const res = await clienteAxios.get(
                    `cuentasporcobrar/verificarSiExisteNumeroComprobante/${itemNuevo.ccNumero}`
                  );
                  if (res?.data?.existe) {
                    mostrarAlerta(res?.data?.msg?.join(""), "warning");
                    return setopenCDCorreoNC(true);
                  } else {
                    setopenCDCorreo(true);
                  }
                }
                setopenCDCorreo(true);
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}{" "}
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <CampoDoc
            disabled={cargando}
            disabledE={cargando}
            setcargando={setcargando}
            label={"Documento"}
            propiedad={"ccComprobante"}
            item={itemNuevo}
            setitem={setItemNuevo}
            celda={true}
            claseCelda={classes.celda}
            carpeta="ccComprobante"
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            value={itemNuevo.ccTipo}
            name="ccTipo"
            onChange={(e) => handleChange(e)}
            size="small"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            fullWidth
          >
            <MenuItem value="">Seleccione Tipo</MenuItem>
            {[
              "EFECTIVO",
              "RETENCION",
              "CHEQUE",
              "TRANSFERENCIA",
              "DEPOSITO",
              "TARJETA",
              "CRUCE COMPRA",
              "DONACION",
            ]
              .sort()
              .map((itemTipo, index) => {
                return (
                  <MenuItem key={index} value={itemTipo}>
                    {itemTipo}{" "}
                  </MenuItem>
                );
              })}
          </TextField>{" "}
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            disabled={itemNuevo.ccTipo === "EFECTIVO"}
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.ccNumero}
            name="ccNumero"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            disabled={itemNuevo.ccTipo === "EFECTIVO"}
            value={itemNuevo.ccBanco}
            name="ccBanco"
            onChange={(e) => handleChange(e)}
            size="small"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            fullWidth
          >
            <MenuItem value="">Seleccione Banco</MenuItem>
            {bancos.map((ccPago) => {
              return (
                <MenuItem key={ccPago.banId} value={ccPago.banNombre}>
                  {ccPago.banNombre}{" "}
                </MenuItem>
              );
            })}
          </TextField>{" "}
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            disabled={itemNuevo.ccTipo === "EFECTIVO"}
            value={itemNuevo.ccDeposito}
            name="ccDeposito"
            onChange={(e) => handleChange(e)}
            size="small"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            fullWidth
          >
            <MenuItem value="">Seleccione Tipo</MenuItem>
            {["SI", "NO", "NINGUNO"].map((itemccDeposito, index) => {
              return (
                <MenuItem key={index} value={itemccDeposito}>
                  {itemccDeposito}{" "}
                </MenuItem>
              );
            })}
          </TextField>{" "}
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            inputProps={{ style: { textAlign: "center" } }}
            type="date"
            value={itemNuevo.ccFechaAbono}
            name="ccFechaAbono"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            type="number"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.ccValor}
            name="ccValor"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          <TextField
            value={itemNuevo.ccDetalle}
            name="ccDetalle"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell fontSize="small" padding="none" align="center">
          {usuario.usuario}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
