/*eslint-disable*/

import React, { useState, memo, useEffect, useContext } from "react";
// MODULOS

import { NavLink, withRouter } from "react-router-dom";
// MATERIAL
import {
  Typography,
  Button,
  Box,
  IconButton,
  List,
  Drawer,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Badge,
  Avatar,
} from "@mui/material";
import Soporte from "./Soporte";
import logo from "../../assets/img/logo.png";
import { Message } from "@mui/icons-material";
import { lighten } from "@material-ui/core";
import { useProvideSocket } from "../../views/Chat/socket/useProvideSocket";
import clienteAxios from "../../config/axios";
import AuthContext from "../../Context/Auth/AuthContext";

const Footer = (props) => {
  const { usuario } = useContext(AuthContext);
  const [open, setopen] = useState(false);
  const [openDrawerChat, setopenDrawerChat] = useState(false);
  const onToogleDrawerChat = () => {
    setopenDrawerChat(!openDrawerChat);
  };
  const { socket, online } = useProvideSocket();
  const [grupos, setgrupos] = useState([]);
  const [totales, settotales] = useState(0);
  useEffect(() => {
    const handleListaChatsGrupos = (data) => {
      setgrupos(data);
    };

    const handleTotalesActualizados = (data) => {
      if (data) {
        setgrupos(data.refresh_groups);
        settotales(data.unread_messages);
      }
    };

    const handleSendMensaje = (data) => {
      socket?.emit("client:totales-actualizados", {
        rucempresa: usuario.rucempresa,
        id_user: usuario.id,
      });
    };

    socket?.on("server:lista-chats-grupos", handleListaChatsGrupos);
    socket?.on("server:totales-actualizados", handleTotalesActualizados);
    socket?.on("server:send-mensaje", handleSendMensaje);

    return () => {
      socket?.off("server:lista-chats-grupos", handleListaChatsGrupos);
      socket?.off("server:totales-actualizados", handleTotalesActualizados);
      socket?.off("server:send-mensaje", handleSendMensaje);
    };
  }, [socket, online]);

  const getData = async () => {
    const res = await clienteAxios.get("/messages/chatsNoLeidoYGruposChats/0"); 

    setgrupos(res.data.refresh_groups);
    settotales(res.data.unread_messages);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={openDrawerChat}
        onClose={onToogleDrawerChat}
        sx={(theme) => ({
          zIndex: theme.zIndex.drawer + 4,
        })}
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: lighten(theme.palette.primary.dark, 0.5),
          }),
        }}
      >
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
          onClick={onToogleDrawerChat}
          onKeyDown={onToogleDrawerChat}
        >
          <List>
            {grupos
              // .filter((usuario) => usuario.id !== authInfo.id)
              .map((grupo) => (
                <ListItemButton
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                  key={grupo.id}
                  onClick={() => {
                    props.history.push(
                      `/Seguridad/chat/?chat_id=${grupo.chat_id}&name=${grupo.chat_name}`
                    );
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      // color={grupo.online ? "success" : "error"}
                      badgeContent={grupo.unread_messages}
                      color="primary"
                    >
                      <Avatar src={grupo.unread_messages} />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                    }}
                    primary={grupo.chat_name}
                  />
                </ListItemButton>
              ))}
          </List>
        </Box>
      </Drawer>
      <Soporte setopen={setopen} open={open} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <Box>
          <Badge badgeContent={totales} color="primary">
            <IconButton
              onClick={() => {
                // props.history.push("/Seguridad/Chat");
                onToogleDrawerChat();
              }}
              variant="text"
              color="secondary"
            >
              <Message />
            </IconButton>
          </Badge>
          <Button
            onClick={() => setopen(true)}
            variant="text"
            color="secondary"
          >
            Soporte
            <img
              width={"55"}
              src={logo}
              alt="Logo Empresa"
              onClick={() => {
                props.history.push("/Seguridad/Dashboard");
              }}
            />
          </Button>
        </Box>
        <Typography
          variant="subtitle2"
          color="primary"
          component={"a"}
          href="https://neitor.com/"
          target="_blank"
        >
          &copy; {new Date().getFullYear()} || IMPULSADO POR NEITOR
        </Typography>
      </Box>
    </>
  );
};

export default memo(withRouter(Footer));
