import { lighten } from "@material-ui/core";
import {
  Cancel,
  Delete,
  DeleteForever,
  OpenInBrowser,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { useContext, useState } from "react";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

export const OutgoingMessage = ({ msg, handleEliminar }) => {
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [cargando, setcargando] = useState(false);
  const { mostrarAlerta } = useContext(AlertaContext);
  function has24HoursPassed(dateStr) {
    const date = new Date(dateStr);
    const now = new Date();
    const diff = now - date;
    return diff >= 24 * 60 * 60 * 1000; // 24 horas en milisegundos
  }

  return (
    <>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este mensaje?`}
        botones={[
          {
            tooltip: "Se eliminará el mensaje",
            texto: "SI",
            funcion: () => {
              setcargando(true);
              handleEliminar(msg.message_id, msg.message_fotos[0]);
            },
            disabled: cargando,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: cargando,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ListItem disablePadding className="ListItem">
        <Box className="BoxMessage bg_BoxMessage2">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <ListItemText
              className="ListItemText"
              primary={new Date(msg.msg_FecReg).toLocaleString("es-ES", {
                year: "numeric",
                month: "short",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
              })}
              primaryTypographyProps={{
                fontStyle: "italic",
                fontSize: ".7rem",
              }}
              secondary={msg.message_text}
              secondaryTypographyProps={{
                color: (theme) => theme.palette.secondary.contrastText,
                fontSize: ".9rem",
              }}
            />
            <IconButton
              color="primary"
              onClick={() => {
                if (has24HoursPassed(msg.msg_FecReg)) {
                  return mostrarAlerta(
                    "Ya pasaron 24 horas, no se puede eliminar el mensaje",
                    "error"
                  );
                }
                setopenConfirmDialog(true);
              }}
            >
              <Delete />
            </IconButton>
          </Box>
          <ImageList sx={{ width: "350px" }} cols={1} rowHeight={"auto"}>
            {msg.message_fotos.map((fileNuevo) => {
              return (
                <ImageListItem key={fileNuevo}>
                  <img
                    src={fileNuevo}
                    alt={"Archivo"}
                    loading="lazy"
                    style={{ minHeight: "50px", maxWidth: "350px" }}
                  />
                  <ImageListItemBar
                    sx={{
                      background: (theme) =>
                        lighten(theme.palette.secondary.dark, 0.5),
                    }}
                    title={"Adjunto"}
                    actionIcon={
                      <IconButton
                        aria-label="Abrir en el navegador"
                        color="inherit"
                        onClick={() => window.open(fileNuevo)}
                        size="small"
                      >
                        <OpenInBrowser fontSize="small" />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </Box>
      </ListItem>
    </>
  );
};
