import { Paper, Table, TableBody, TableHead } from "@mui/material";
import React from "react";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import { AddCircle } from "@mui/icons-material";
import RowAgregarArma from "./RowAgregarArma";
import RowArma from "./RowArma";
import { columnsArma } from "../../data";

const TablaArmas = (props) => {
  const { setitem, item, bodegaData, ver, devolucion } = props;
  const { disArmas } = item;
  const {
    agregandoArma,
    setagregandoArma,
    pageArma,
    // setPageArma,
    rowsPerPageArma,
    // setRowsPerPageArma,
    handleChangePageArma,
    handleChangeRowsPerPageArma,
  } = useSimpleTable("Arma");
  return (
    <Paper sx={{ width: "100%" }}>
      <ToolBarTable
        count={disArmas.length}
        rowsPerPage={rowsPerPageArma}
        page={pageArma}
        handleChangePage={handleChangePageArma}
        handleChangeRowsPerPage={handleChangeRowsPerPageArma}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setagregandoArma(true);
            },
            disabled: ver || devolucion,
            Icon: AddCircle,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[]}
      />

      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TablaCabecera columns={columnsArma} habilitarOrdenar={false} />
        </TableHead>

        <TableBody component={"div"}>
          {agregandoArma ? (
            <RowAgregarArma
              bodegaData={bodegaData}
              agregar={agregandoArma}
              setagregar={setagregandoArma}
              setitem={setitem}
              item={item}
            />
          ) : null}
          {disArmas
            .slice(
              pageArma * rowsPerPageArma,
              pageArma * rowsPerPageArma + rowsPerPageArma
            )
            .map((row, index) => {
              return (
                <RowArma
                  bodegaData={bodegaData}
                  ver={ver}
                  devolucion={devolucion}
                  item={item}
                  setitem={setitem}
                  row={row}
                  index={index}
                  key={row.idMapeo}
                />
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TablaArmas;
