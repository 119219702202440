import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";
import { withRouter } from "react-router";

import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
// MATERIAL
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";
import ButtonsAction from "../../../components/ButtonsAction";

const RowArma = (props) => {
  const { row, index, item, setitem, estadoRequest } = props;

  const { mostrarAlerta } = useContext(AlertaContext);
  const [itemHijoEdit, setitemHijoEdit] = useState(row);
  const [proveedoresData, setproveedoresData] = useState([]);

  useEffect(() => {
    setitemHijoEdit(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      invProveedor: item.invProveedor.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemHijoEdit(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemHijoEdit.numDocumento === "" ||
      itemHijoEdit.nombres === "" ||
      itemHijoEdit.numFactura === "" ||
      itemHijoEdit.cantidad === "" ||
      itemHijoEdit.fecFactura === "" ||
      itemHijoEdit.estado === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      invProveedor: item.invProveedor.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemHijoEdit;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog();
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        funcionEdit();
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        funcCancel();
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar esta Bodega?"
        _id={itemHijoEdit._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          botones={editar ? botonesEditar : botones}
        />

        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={proveedoresData.map((item) => {
                    return (
                      item.cliDocNumero +
                      "/+/" +
                      item.cliRazonSocial +
                      "/+/" +
                      item.cliId
                    );
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ").split("/+/")[0];
                  }}
                  value={itemHijoEdit.numDocumento}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");

                      setitemHijoEdit({
                        ...itemHijoEdit,
                        numDocumento: options[0],
                        nombres: options[1],
                        id: options[2],
                      });
                    } else {
                      setitemHijoEdit({
                        ...itemHijoEdit,
                        id: "",
                        numDocumento: "",
                        nombres: "",
                      });
                      setproveedoresData([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          return mostrarAlerta("Ingrese datos", "error");
                        }
                        try {
                          const res = await clienteAxios.get(
                            `/proveedores/filtro/0?search=${e.target.value}`
                          );

                          setproveedoresData(res.data.data);
                        } catch (error) {
                          setitemHijoEdit({
                            ...itemHijoEdit,
                            id: "",
                            numDocumento: "",
                            nombres: "",
                          });
                          setproveedoresData([]);

                          return mostrarAlerta("No hay proveedores", "error");
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.nombres}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <TextField
                value={itemHijoEdit.numFactura}
                onChange={(e) =>
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    numFactura: e.target.value,
                  })
                }
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <TextField
                type="date"
                value={itemHijoEdit.fecFactura}
                onChange={(e) =>
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    fecFactura: e.target.value,
                  })
                }
                size="small"
                fullWidth
              />
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <TextField
                type="number"
                value={itemHijoEdit.estado}
                onChange={(e) =>
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    estado: e.target.value,
                  })
                }
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {[
                  "NUEVO",
                  "USADO",
                  "OPERABLE",
                  "BUEN ESTADO",
                  "DEFECTUOSO",
                ].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <TextField
                disabled={estadoRequest === "ARMAS"}
                type="number"
                value={itemHijoEdit.cantidad}
                onChange={(e) =>
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    cantidad: Math.max(e.target.value, 1),
                  })
                }
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                disabled={estadoRequest === "ARMAS"}
                type="number"
                value={itemHijoEdit.minimo}
                onChange={(e) =>
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    minimo: Math.max(e.target.value, 1),
                  })
                }
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                disabled={estadoRequest === "ARMAS"}
                type="number"
                value={itemHijoEdit.maximo}
                onChange={(e) =>
                  setitemHijoEdit({
                    ...itemHijoEdit,
                    maximo: Math.max(e.target.value, 1),
                  })
                }
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.numDocumento}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.nombres}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.numFactura}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.fecFactura}
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.estado}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.cantidad}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.minimo}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              {itemHijoEdit.maximo}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
