import { TableCell } from "@mui/material";
import React from "react";
import { useRowStyles } from "../styles/stylesRows";
import clienteAxios from "../../config/axios";
import { useContext } from "react";
import AlertaContext from "../../Context/Alerta/AlertaContext";

const CeldaUser = ({ user, claseCelda }) => {
  const clases = useRowStyles();
  const { mostrarAlerta } = useContext(AlertaContext);
  const handleClickUser = async () => {
    try {
      const res = await clienteAxios(
        `personas/usuario/${user.split("/+/")[0]}`
      );
      mostrarAlerta(res.data.nombre, "success");
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };
  return (
    <TableCell
      size="small"
      align="center"
      onClick={handleClickUser}
      className={`${claseCelda} ${clases.hover}`}
    >
      {user.split("/+/")[0]}
    </TableCell>
  );
};

export default CeldaUser;
