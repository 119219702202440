export const columns = [
  {
    id: "gestCliDocNumero",
    label: "Cliente",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "gestCliDocNumero",
    align: "left",
    ocultar: true,
  },
  {
    id: "gestFechaInicio",
    label: "F. Inicio",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestFechaInicio",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestAsunto",
    label: "Asunto",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestAsunto",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestAntecedentes",
    label: "Antecedentes",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestAntecedentes",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestFechaDesde",
    label: "F. Desde",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestFechaDesde",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestFechaHasta",
    label: "F. Hasta",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestFechaHasta",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestConclusiones",
    label: "Conclusiones",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestConclusiones",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestRecomendaciones",
    label: "Recomendaciones",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestRecomendaciones",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "gestFecReg",
    label: "Fec. Reg.",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "gestFecReg",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // gestId: 9,
  gestCliId: "",
  gestCliDocNumero: "",
  gestCliRazonSocial: "",
  gestFechaInicio: "",
  gestAsunto: "",
  gestAntecedentes: "",
  gestFechaDesde: "",
  gestFechaHasta: "",
  gestConclusiones: "",
  gestRecomendaciones: "",
  gestUser: "",
  gestEmpresa: "",
  gestFecReg: "",
  gestFecUpd: "",
};
