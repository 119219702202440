export const columns = [
  {
    id: "conEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conEstado",
    align: "center",
    ocultar: true,
  },

  {
    id: "conNombreCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conNombreCliente",
    align: "center",
    ocultar: true,
  },

  {
    id: "conFotosCliente",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conFotosCliente",
    align: "center",
    ocultar: true,
  },

  {
    id: "conDocumento",
    label: "Documento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conDocumento",
    align: "center",
    ocultar: true,
  },

  {
    id: "conAsunto",
    label: "Asunto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conAsunto",
    align: "center",
    ocultar: true,
  },

  {
    id: "conDetalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conDetalle",
    align: "center",
    ocultar: true,
  },

  {
    id: "conPrioridad",
    label: "Prioridad",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conPrioridad",
    align: "center",
    ocultar: true,
  },
  {
    id: "conFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "conUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "conUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  conIdCliente: "",
  conNombreCliente: "",
  conAsunto: "",
  conDetalle: "",
  conFechasConsigna: [],
  conFechasConsignaConsultaDB: [],
  conTipoCalendario: "MULTIPLE",
  conDocumento: "",
  conPrioridad: "MEDIA",
  conProgreso: "NO REALIZADO",
  conEstado: "EN PROCESO", // ["EN PROCESO","INCUMPLIDO","CUMPLIDO","ANULADO",]
  conFotosCliente: [],
  conGuardias: [
    // {
    //   codigo: "",
    //   nombres: "",
    // },
  ],
  conSupervisores: [],
  conAdministracion: [],
  conLeidos: [],
  conUser: "",
  conEmpresa: "",
  conFecReg: "",
};
