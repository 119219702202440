import React, { useContext, useState, useEffect, memo } from "react";

import { withRouter } from "react-router-dom";
// import Spinner from "../../Spinner/Spinner";

import Row from "./Row";

import Cargando from "../../Extra/Cargando";
import TablaCabecera from "../../Extra/TablaCabecera";
import ToolBarTable from "./ToolBarTable";
import AuthContext from "../../../Context/Auth/AuthContext";
import CambioPuestoContext from "../../../Context/CambioPuesto/CambioPuestoContext";
import queryString from "query-string";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 800,
  },
});
const Tabla = (props) => {
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    setopen,
    rows,
    seteditar,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador,
    estadoRequest,
  } = props;

  const {
    columns,
    obtener,
    pagination,
    addRows,
    remplazarRows,
    getData,
    agregar,
  } = useContext(CambioPuestoContext);
  const {
    usuario: { rucempresa },
    socket,
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  //columnas y rows de la tabla

  // eslint-disable-next-line
  const clases = useStyles();
  // eslint-disable-next-line

  // eslint-disable-next-line
  // eslint-disable-next-line
  // eslint-disable-next-line

  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("camId");
  const [tabla] = useState("cambiopuesto");
  const [parametrosUtilizadosHijos, setparametrosUtilizadosHijos] =
    useState(false);
  //clases
  const classes = useStyles();

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getData(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        "padre" // "grupo",
        // fecha
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row.camId === item.camId;
        });

        return !existe;
      });

      addRows(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (page === 0) {
      if (event.target.value > rows.length) {
        obtener(
          event.target.value,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          estadoRequest,
          "padre" // "grupo",
          // fecha
        );
      }
    }
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre" // "grupo",
      // fecha
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  const funcionReload = async () => {
    setRowsPerPage(10);
    setPage(0);
    await obtener(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre" // "grupo",
      // fecha
    );
  };

  const parsed = queryString.parse(props.location.search);

  const { idRegistro, notificacion } = parsed;
  useEffect(() => {
    //eslint-disable-next-line

    if (notificacion) {
      obtener(
        10,
        0,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        "padre", // "grupo",
        "",
        idRegistro,
        notificacion
      );
    } else {
      obtener(
        10,
        0,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        "padre" // "grupo",
        // fecha
      );
    }

    // eslint-disable-next-line
  }, [idRegistro]);

  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
    validarExistePadre: false,
  });

  useEffect(() => {
    socket.on("server:guardadoExitoso", (data) => {
      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "agregar",
          item: data,
          validarExistePadre: true,
        });
      }
    });
    socket.on("server:actualizadoExitoso", (data) => {
      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "editar",
          item: data,
          validarExistePadre: false,
        });
      }
    });
    socket.on("server:eliminadoExitoso", (data) => {
      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "eliminar",
          item: data,
          validarExistePadre: false,
        });
      }
    });
    socket.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "eliminarSeleccion",
          item: data,
          validarExistePadre: false,
        });
      }
    });

    // eslint-disable-next-line
  }, []);
  const [itemSocketHijo, setitemSocketHijo] = useState({
    tipo: "",
    item: {},
  });
  useEffect(() => {
    const { item, validarExistePadre, tipo } = itemSocket;
    if (tipo !== "") {
      if (!rows) {
        return;
      }
      if (usuario.rucempresa !== item.rucempresa) {
        return;
      }
       ;

      const existePadre = rows.some((row) => {
        return row.camFecReg.substr(0, 7) === item.camFecReg.substr(0, 7);
      });

      if (validarExistePadre) {
        const funcionAalerta = () => {
          if (item.msg === "") {
            return null;
          }
          if (!item.msg) {
            return null;
          }
          mostrarAlerta(item.msg, item.alert ? item.alert : "success");
        };

        if (existePadre) {
          setitemSocketHijo({
            item,
            tipo,
          });
          setitemSocket({
            tipo: "",
            item: {},
            validarExistePadre: false,
          });
        } else {
          agregar(item, funcionAalerta);
          setitemSocket({
            tipo: "",
            item: {},
            validarExistePadre: false,
          });
        }
      } else {
        if (existePadre) {
          setitemSocketHijo({
            item,
            tipo,
          });
        }

        setitemSocket({
          tipo: "",
          item: {},
          validarExistePadre: false,
        });
      }
    }
    // eslint-disable-next-line
  }, [itemSocket]);
  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Paper className={classes.root}>
      <ToolBarTable
        search={search}
        titulo={"Cambio Puesto"}
        arrayExport={arrayExport}
        columns={columns}
        expandible={false}
        campoExport={campoExport}
        tabla="cambiopuesto"
        funcionReload={funcionReload}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ocultarDelete={true}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
            
          <TableHead>
            <TablaCabecera
              remplazarRows={remplazarRows}
              search={search}
              input={input}
              setinput={setinput}
              orden={orden}
              setorden={setorden}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setcargando={setcargando}
              rows={rows}
              columns={columns}
              habilitarOrdenar={true}
              funcionOrdenar={funcionOrdenar}
              agregarColumna={true}
            />
          </TableHead>
          <TableBody>
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columns.length + 2}
              >
                <Cargando titulo="Cargando cargando" />
              </TableCell>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      campoExport={campoExport}
                      rows={rows}
                      seteditar={seteditar}
                      arrayExport={arrayExport}
                      setarrayExport={setarrayExport}
                      row={row}
                      index={index}
                      setopen={setopen}
                      key={row.camId}
                      estadoRequest={estadoRequest}
                      itemSocketHijo={itemSocketHijo}
                      setitemSocketHijo={setitemSocketHijo}
                      tabla={tabla}
                      parametrosUtilizadosHijos={parametrosUtilizadosHijos}
                      setparametrosUtilizadosHijos={
                        setparametrosUtilizadosHijos
                      }
                      idRegistro={idRegistro}
                      notificacion={notificacion}
                    />
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(Tabla));
