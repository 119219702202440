import { FechaExacta } from "../../../config/const";

export const columns = [
  {
    id: "estClienteNombre",
    label: "Cliente",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "estClienteNombre",
    align: "left",
    ocultar: true,
  },
  {
    id: "estPeriodo",
    label: "Periodo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estPeriodo",
    align: "center",
    ocultar: true,
  },
  {
    id: "estFecInicio",
    label: "Fec. Inicio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estFecInicio",
    align: "center",
    ocultar: true,
  },
  {
    id: "estFecFinal",
    label: "Fec. Final",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estFecFinal",
    align: "center",
    ocultar: true,
  },
  {
    id: "estMonto",
    label: "Monto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estMonto",
    align: "center",
    ocultar: true,
  },
  {
    id: "estSaldo",
    label: "Saldo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estSaldo",
    align: "center",
    ocultar: true,
  },
  {
    id: "estEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estEstado",
    align: "center",
    ocultar: true,
  },
  {
    id: "estUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  estClienteId: "",
  estClienteDocu: "",
  estClienteNombre: "",
  estPeriodo: "DIARIO",
  estFecInicio: FechaExacta,
  estFecFinal: FechaExacta,
  estFecAbono: "",
  estMonto: "",
  estAbono: "",
  estSaldo: "",
  estEstado: "PENDIENTE",
  estAutomatico: "NO",
  estCuotas: [],
  estEmpresa: "",
  estUser: "",
  estFecReg: "",
  Todos: "",
};
export const itemHijoDefecto = {
  detalle: "",
  documento: "",
  fecha: FechaExacta,
  fechaCancelacion: FechaExacta,
  observacion: "",
  cuota: "",
  estado: "PENDIENTE",
  user: "",
};
export const columnasHijo = [
  {
    id: "documento",
    label: "Documento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "documento",
    align: "center",
    ocultar: true,
  },
  {
    id: "detalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "detalle",
    align: "center",
    ocultar: true,
  },
  {
    id: "fecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fecha",
    align: "center",
    ocultar: true,
  },
  {
    id: "cuota",
    label: "Cuota",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "cuota",
    align: "center",
    ocultar: true,
  },
  {
    id: "estado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "estado",
    align: "center",
    ocultar: true,
  },
  {
    id: "fechaCancelacion",
    label: "F. Cancelación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fechaCancelacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "observacion",
    label: "Observación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "observacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "user",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "user",
    align: "center",
    ocultar: true,
  },
];
