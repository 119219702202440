import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  MenuItem,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { FechaExacta, trimPropsItem } from "../../../../config/const";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { objectDefault } from "../data";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const { setopen, socket } = props;

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // COPIA DEL REGISTRO PARA EDITAR

  const [item, setitem] = useState(objectDefault);
  // CLIENTES
  const [clientesData, setclientesData] = useState([]);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.estClienteNombre === "" ||
      item.estPeriodo === "" ||
      item.estFecInicio === "" ||
      item.estFecFinal === "" ||
      item.estMonto === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "datosoperativos",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          estUser: usuario.usuario,
          estEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  const PopperMy = function (props) {
    return <Popper {...props} placement="bottom-start" />;
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell size="small" align="center" className={classes.celda}>
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={clientesData.map((item) => {
                  return (
                    item.cliRazonSocial +
                    "/+/" +
                    item.cliDocNumero +
                    "/+/" +
                    item.cliId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ** ").split("/+/")[0];
                }}
                value={item.estClienteNombre}
                input
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    setitem({
                      ...item,
                      estClienteNombre: options[0],
                      estClienteDocu: options[1],
                      estClienteId: options[2],
                    });
                  } else {
                    setitem({
                      ...item,
                      estClienteNombre: "",
                      estClienteDocu: "",
                      estClienteId: "",
                    });
                    setclientesData([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            fontSize: "1px",
                            maxHeight: 48 * 4.5 + 8,
                            width: 5000,
                          },
                        },
                      },
                    }}
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return mostrarAlerta("Ingrese datos", "error");
                      }
                      try {
                        if (e.target.value.length < 3) {
                          return;
                        }
                        const res = await clienteAxios.get(
                          `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                        );

                        setclientesData(res.data.data);
                      } catch (error) {
                        setitem({
                          ...item,
                          estClienteNombre: "",
                          estClienteDocu: "",
                          estClienteId: "",
                        });
                        setclientesData([]);

                        return mostrarAlerta("No hay clientes", "error");
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              inputProps={{ style: { textAlign: "center" } }}
              value={item.estPeriodo}
              name="estPeriodo"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {[
                "DIARIO",
                "SEMISNAL",
                "MENSUAL",
                "TRIMESTRAL",
                "SEMESTRAL",
                "ANUAL",
              ].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              type={"date"}
              inputProps={{ style: { textAlign: "center" }, min: FechaExacta }}
              value={item.estFecInicio.substr(0, 16).replace("T", " ")}
              name="estFecInicio"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              type={"date"}
              inputProps={{ style: { textAlign: "center" }, min: FechaExacta }}
              value={item.estFecFinal.substr(0, 16).replace("T", " ")}
              name="estFecFinal"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              type="number"
              inputProps={{ style: { textAlign: "center" } }}
              value={item.estMonto}
              name="estMonto"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.estSaldo}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {item.estEstado}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {usuario.usuario}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
