import React, { useContext, useState } from "react";

import {
  Box,
  IconButton,
  List,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  Tooltip,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import { Cancel, PictureAsPdfOutlined } from "@mui/icons-material";
import { useButonStyles } from "../../styles/styleButon";
import { useModalStyle } from "../../styles/styleModal";
import { FechaExacta } from "../../../config/const";
import AuthContext from "../../../Context/Auth/AuthContext";
import { saveAs } from "save-as";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";

export default function DialogOpciones(props) {
  const clases = useButonStyles();

  const clasesModal = useModalStyle();
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { open, setopen, botones, titulo, estadoRequest, tabla } = props;
  const defaultFechas = {
    desde: FechaExacta,
    hasta: FechaExacta,
    estado: "APROBADA",
  };
  const [fechas, setFechas] = useState(defaultFechas);
  const [openPDF, setopenPDF] = useState(false);
  const [cargando, setcargando] = useState(false);
  const handleClose = () => {
    setopen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>{" "}
            <IconButton aria-label="" onClick={handleClose} color="secondary">
              <Cancel />
            </IconButton>
          </Box>

          <Box pr={1} pl={1} pb={1}>
            <DialogContent>
              <List component="nav">
                {botones.map((itemBoton) => {
                  const {
                    tooltip,
                    funcion,
                    Icon,
                    color,
                    id,
                    disabled,
                    ocultar,
                  } = itemBoton;
                  if (ocultar) {
                    return null;
                  }
                  return (
                    <Tooltip title={tooltip} key={id}>
                      <ListItemButton
                        disabled={disabled}
                        onClick={() => {
                          funcion();
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar className={clases[color]}>
                            <Icon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText primary={tooltip} />
                      </ListItemButton>
                    </Tooltip>
                  );
                })}
                <Tooltip title={"Exportar por estado"}>
                  <ListItemButton
                    onClick={() => {
                      setopenPDF(!openPDF);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar className={clases["error"]}>
                        <PictureAsPdfOutlined />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText primary={"Exportar por estado"} />
                  </ListItemButton>
                </Tooltip>
              </List>
            </DialogContent>
            {openPDF && (
              <DialogContent className={clasesModal.container}>
                <TextField
                  className={clasesModal.formAllW}
                  type="date"
                  label="Estado"
                  value={fechas.estado}
                  onChange={(e) => {
                    setFechas({ ...fechas, estado: e.target.value });
                  }}
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                >
                  <MenuItem value="">Seleccione.</MenuItem>
                  {["APROBADA", "ANULADA"].map((item) => {
                    return <MenuItem value={item}>{item} </MenuItem>;
                  })}
                </TextField>
                <TextField
                  className={clasesModal.formAllW}
                  type="date"
                  label="Desde"
                  value={fechas.desde}
                  onChange={(e) => {
                    setFechas({ ...fechas, desde: e.target.value });
                  }}
                />
                <TextField
                  className={clasesModal.formAllW}
                  type="date"
                  label="Hasta"
                  value={fechas.hasta}
                  onChange={(e) => {
                    setFechas({ ...fechas, hasta: e.target.value });
                  }}
                />
              </DialogContent>
            )}
            <DialogActions>
              {openPDF && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={cargando}
                  onClick={async () => {
                    try {
                      setcargando(true);
                      const json = {
                        rucempresa: usuario.rucempresa,
                        usuario: usuario.usuario,
                        nombre: usuario.nombre,
                        rol: usuario.rol,
                        byestado: true,
                        otros: {
                          tipo: estadoRequest,
                          fecha1: fechas.desde,
                          fecha2: fechas.hasta,
                          estado: fechas.estado,
                        },
                      };
                      const res = await clienteAxios.post(
                        `reportes/${tabla}`,
                        {
                          ...json,
                        },
                        { responseType: "blob" }
                      );
                      const pdfBlob = new Blob([res.data], {
                        type: "application/pdf",
                      });
                      saveAs(pdfBlob, `${tabla}.pdf`);
                      setopen(false);
                      setFechas(defaultFechas);
                      setcargando(false);
                    } catch (error) {
                      console.log({ error });
                      mostrarAlerta("Hubo un error", "error");
                    }
                  }}
                >
                  GENERAR PDF ESTADO
                </Button>
              )}
            </DialogActions>
          </Box>
        </Box>
      </>
    </Dialog>
  );
}
