export const columns = [
  {
    id: "notDocuPersona",
    label: "Cédula",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "notDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "notNombrePersona",
    label: "Nombre",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "notNombrePersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "notRol",
    label: "Rol",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "notRol",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {};
