import React, { memo, useContext, useEffect } from "react";

import {
  Collapse,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  Autocomplete,
  Popper,
  Box,
  Button,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  QrCode,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { usePermiso } from "../../hooks/usePermiso";
import { trimPropsItem } from "../../../config/const";
import TablaCabecera from "../../components/TablaCabecera";
import RowAgregarHijo from "./RowsHijos/RowAgregarHijo";
import RowHijo from "./RowsHijos/RowHijo";
import RowAgregarVulneravilidad from "./Vulneravilidades/RowAgregarVulneravilidad";
import RowVulneravilidad from "./Vulneravilidades/RowVulneravilidad";
import {
  columnasHijo,
  columnasHijoRonda,
  columnasHijoRondaSupervisor,
  columnasHijoSupervisor,
  columnasHijoVulnerabilidad,
} from "../data";
import { useSimpleTable } from "../../hooks/useSimpleTable";
import ToolBarTable from "../../components/ToolBarTable";
import clienteAxios from "../../../config/axios";
import ModalQR from "./ModalQR";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { useModalStyle } from "../../styles/styleModal";
import CeldaUser from "../../components/CeldaUser";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  const classesModal = useModalStyle();
  // PROPS
  const {
    estadoRequest,
    arrayExport,
    setarrayExport,
    rowPadre,
    index,
    campoExport,
    socket,
    tiposData,
    // IOP
    fromIOP,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowPadre.editado === true
      ? classes.celdaEditado
      : rowPadre.nuevo === true
      ? classes.celdaNuevo
      : rowPadre.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("EventosActividades");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // DATA
  const [clientesData, setclientesData] = useState([]);
  const [datosOperativos, setdatosOperativos] = useState([]);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  const [seccion, setseccion] = useState("ACTIVIDADES");
  // errores
  const [horarioError, sethorarioError] = useState([]);

  // COPIA DEL REGISTRO PARA EDITAR
  const [itemPadre, setitemPadre] = useState({ ...rowPadre });

  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [openQr, setopenQr] = useState(false);
  // // HOOK TABLA
  const {
    agregandoActividad,
    setagregandoActividad,
    pageActividad,
    // setPageActividad,
    rowsPerPageActividad,
    // setRowsPerPageActividad,
    handleChangePageActividad,
    handleChangeRowsPerPageActividad,
  } = useSimpleTable("Actividad");
  const {
    agregandoVulneravilidad,
    setagregandoVulneravilidad,
    pageVulneravilidad,
    // setPageActividad,
    rowsPerPageVulneravilidad,
    // setRowsPerPageActividad,
    handleChangePageVulneravilidad,
    handleChangeRowsPerPageVulneravilidad,
  } = useSimpleTable("Vulneravilidad");
  //onChange
  const handleChange = (e) => {
    setitemPadre({ ...itemPadre, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      itemPadre.eveNombre === "" ||
      itemPadre.eveTipo === "" ||
      itemPadre.eveCliDocumento === "" ||
      (itemPadre.eveCliPuesto === "" && estadoRequest !== "SUPERVISOR")
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "eventoactividad",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        eveUser: usuario.usuario,
        eveEmpresa: usuario.rucempresa,
        [campoExport]: rowPadre[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...itemPadre,

          tabla: "eventoactividad",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          eveUser: usuario.usuario,
          eveEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowPadre,
          tabla: "eventoactividad",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          eveUser: usuario.usuario + "/+/" + rowPadre[campoExport],
          eveEmpresa: usuario.rucempresa,
          [campoExport]: rowPadre[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitemPadre({ ...rowPadre });
    seteditar(true);
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const obtenerData = async () => {
    const res = await clienteAxios.get(
      `/clientes/filtro/0?search=${rowPadre.eveCliDocumento}&estado=CLIENTES`
    );

    setclientesData(res.data.data);

    const itemF = res.data.data.find(
      (itemCliente) => itemCliente.cliId === Number(rowPadre.eveCliId)
    );
    setdatosOperativos(itemF.cliDatosOperativos);
  };
  useEffect(() => {
    if (expandir) {
      obtenerData();
    }
    // eslint-disable-next-line
  }, [expandir]);

  return (
    <React.Fragment>
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
        row={rowPadre}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== rowPadre.eveUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowPadre.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar || rowPadre.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || rowPadre.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitemPadre({ ...rowPadre });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },

            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              id: 5,
              ocultar:
                rowPadre.eliminado ||
                rowPadre.eveTipo !== "RONDAS" ||
                estadoRequest === "SUPERVISOR",
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={claseCelda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={clientesData.map((item) => {
                    return (
                      item.cliRazonSocial +
                      "/+/" +
                      item.cliDocNumero +
                      "/+/" +
                      item.cliId
                    );
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ").split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={
                    (
                      itemPadre.eveCliNombre +
                      " " +
                      itemPadre.eveCliDocumento
                    ).trim() === ""
                      ? ""
                      : itemPadre.eveCliNombre + " " + itemPadre.eveCliDocumento
                  }
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");

                      const itemF = clientesData.find(
                        (itemCliente) =>
                          itemCliente.cliId === Number(options[2])
                      );
                      setdatosOperativos(itemF.cliDatosOperativos);

                      setitemPadre({
                        ...itemPadre,
                        eveCliNombre: options[0],
                        eveCliDocumento: options[1],
                        eveCliId: options[2],
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          return setclientesData([]);
                        }
                        try {
                          const res = await clienteAxios.get(
                            `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                          );

                          setclientesData(res.data.data);
                        } catch (error) {
                          return mostrarAlerta("Hubo un error", "error");
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            {estadoRequest !== "SUPERVISOR" && (
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={claseCelda}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    PopperComponent={PopperMy}
                    disableClearable={true}
                    options={datosOperativos.map((item) => {
                      return item.puesto + "/+/" + item.ubicacion;
                    })}
                    getOptionLabel={(option) => {
                      return option.replace("/+/", "- ");
                    }}
                    id="controlled-demo"
                    value={
                      (
                        itemPadre.eveCliPuesto +
                        " " +
                        itemPadre.eveCliUbicacion
                      ).trim() === ""
                        ? ""
                        : itemPadre.eveCliPuesto +
                          " - " +
                          itemPadre.eveCliUbicacion
                    }
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const [eveCliPuesto, eveCliUbicacion] = newValue.split(
                          "/+/"
                        );
                        const itemF = datosOperativos.find(
                          (itemDatoO) =>
                            itemDatoO.puesto === eveCliPuesto &&
                            itemDatoO.ubicacion === eveCliUbicacion
                        );

                        setitemPadre({
                          ...itemPadre,
                          eveCoordenadas: itemF.coordenadas,
                          eveCliPuesto,
                          eveCliUbicacion,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>
            )}

            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={itemPadre.eveTipo}
                name="eveTipo"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {tiposData.map((item) => {
                  return (
                    <MenuItem key={item.novId} value={item.novTipo}>
                      {item.novTipo}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={itemPadre.eveNombre}
                name="eveNombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              {rowPadre.eveCliNombre + " " + rowPadre.eveCliDocumento}
            </TableCell>
            {estadoRequest !== "SUPERVISOR" && (
              <TableCell size="small" align="center" className={claseCelda}>
                {rowPadre.eveCliPuesto + " - " + rowPadre.eveCliUbicacion}
              </TableCell>
            )}
            <TableCell size="small" align="center" className={claseCelda}>
              {rowPadre.eveTipo}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {rowPadre.eveNombre}
            </TableCell>
          </>
        )}
        <TableCell size="small" align="center" className={claseCelda}>
          {new Date(rowPadre.eveFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
        <CeldaUser user={rowPadre.eveUser} claseCelda={claseCelda} />
      </TableRow>
      <TableRow>
        <TableCell padding="checkbox" className={claseCelda} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box>
                {["ACTIVIDADES", "VULNERABILIDADES"].map((seccionItem) => (
                  <Button
                    key={seccionItem}
                    size="small"
                    variant={seccion === seccionItem ? "contained" : "outlined"}
                    onClick={async () => {
                      setseccion(seccionItem);
                    }}
                    disableElevation
                    className={classesModal.buttonTab}
                    color="secondary"
                  >
                    {seccionItem}
                  </Button>
                ))}
              </Box>
            </Box>
            {seccion === "ACTIVIDADES" ? (
              <Paper>
                <ToolBarTable
                  count={rowPadre.eveActividades.length}
                  rowsPerPage={rowsPerPageActividad}
                  page={pageActividad}
                  handleChangePage={handleChangePageActividad}
                  handleChangeRowsPerPage={handleChangeRowsPerPageActividad}
                  ocultarPaginacion={false}
                  simplePagination={true}
                  botonesIzquierdos={[
                    {
                      tooltip: "Agregar",
                      texto: "Agregar Actividad",
                      funcion: () => {
                        setagregandoActividad(true);
                      },
                      disabled:
                        (rowPadre.eveTipo === "INVENTARIO INTERNO" ||
                          rowPadre.eveTipo === "INVENTARIO EXTERNO") &&
                        rowPadre.eveActividades.length >= 1,
                      Icon: AddCircle,
                      color: "primary",
                      id: 1,
                      ocultar: false,
                      tipo: "boton",
                      variante: "contained",
                      size: "medium",
                      sx: {},
                    },
                  ]}
                  botonesDerechos={[]}
                />

                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TablaCabecera
                      columns={
                        fromIOP
                          ? [
                              ...(rowPadre.eveTipo === "RONDAS"
                                ? estadoRequest === "SUPERVISOR"
                                  ? columnasHijoRondaSupervisor
                                  : columnasHijoRonda
                                : estadoRequest === "SUPERVISOR"
                                ? columnasHijoSupervisor
                                : columnasHijo),
                              {
                                id: "vulnerabilidad",
                                label: "vulnerabilidad",
                                minWidth: 25,
                                tipo: "string",
                                orden: 1,
                                propiedad: "vulnerabilidad",
                                align: "center",
                                ocultar: true,
                              },
                              {
                                id: "foto",
                                label: "foto",
                                minWidth: 25,
                                tipo: "string",
                                orden: 1,
                                propiedad: "foto",
                                align: "center",
                                ocultar: true,
                              },
                            ]
                          : [
                              ...(rowPadre.eveTipo === "RONDAS"
                                ? estadoRequest === "SUPERVISOR"
                                  ? columnasHijoRondaSupervisor
                                  : columnasHijoRonda
                                : estadoRequest === "SUPERVISOR"
                                ? columnasHijoSupervisor
                                : columnasHijo),
                            ]
                      }
                      habilitarOrdenar={false}
                    />
                  </TableHead>

                  <TableBody component={"div"}>
                    {agregandoActividad ? (
                      <RowAgregarHijo
                        datosOperativos={datosOperativos}
                        estadoRequest={estadoRequest}
                        rowPadre={rowPadre}
                        setagregandoActividad={setagregandoActividad}
                        socket={socket}
                        fromIOP={fromIOP}
                      />
                    ) : null}
                    {rowPadre.eveActividades
                      .slice(
                        pageActividad * rowsPerPageActividad,
                        pageActividad * rowsPerPageActividad +
                          rowsPerPageActividad
                      )
                      .map((rowHijo, indexHijo) => {
                        return (
                          <RowHijo
                            setitemPadre={setitemPadre}
                            datosOperativos={datosOperativos}
                            estadoRequest={estadoRequest}
                            horarioError={horarioError}
                            sethorarioError={sethorarioError}
                            rowHijo={rowHijo}
                            rowPadre={rowPadre}
                            indexHijo={indexHijo}
                            key={rowHijo.id}
                            socket={socket}
                            fromIOP={fromIOP}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </Paper>
            ) : (
              <Paper>
                <ToolBarTable
                  count={rowPadre.eveVulnerabilidades.length}
                  rowsPerPage={rowsPerPageVulneravilidad}
                  page={pageVulneravilidad}
                  handleChangePage={handleChangePageVulneravilidad}
                  handleChangeRowsPerPage={
                    handleChangeRowsPerPageVulneravilidad
                  }
                  ocultarPaginacion={false}
                  simplePagination={true}
                  botonesIzquierdos={[
                    {
                      tooltip: "Agregar",
                      texto: "Agregar Vulneravilidad",
                      funcion: () => {
                        setagregandoVulneravilidad(true);
                      },
                      disabled: false,
                      Icon: AddCircle,
                      color: "primary",
                      id: 1,
                      ocultar: false,
                      tipo: "boton",
                      variante: "contained",
                      size: "medium",
                      sx: {},
                    },
                  ]}
                  botonesDerechos={[]}
                />

                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TablaCabecera
                      columns={columnasHijoVulnerabilidad}
                      habilitarOrdenar={false}
                    />
                  </TableHead>

                  <TableBody component={"div"}>
                    {agregandoVulneravilidad ? (
                      <RowAgregarVulneravilidad
                        rowPadre={rowPadre}
                        setagregandoVulneravilidad={setagregandoVulneravilidad}
                        socket={socket}
                      />
                    ) : null}
                    {rowPadre.eveVulnerabilidades
                      .slice(
                        pageVulneravilidad * rowsPerPageVulneravilidad,
                        pageVulneravilidad * rowsPerPageVulneravilidad +
                          rowsPerPageVulneravilidad
                      )
                      .map((rowVulneravilidad, indexVulneravilidad) => {
                        return (
                          <RowVulneravilidad
                            rowVulneravilidad={rowVulneravilidad}
                            rowPadre={rowPadre}
                            indexVulneravilidad={indexVulneravilidad}
                            key={rowVulneravilidad.id}
                            socket={socket}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </Paper>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
