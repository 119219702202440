import {
  InputAdornment,
  IconButton,
  Tooltip,
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
} from "@mui/material";

import TextField from "@mui/material/TextField";
import { CloudUpload, Delete, OpenInBrowser, Send } from "@mui/icons-material";

import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useProvideSocket } from "../socket/useProvideSocket";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { useModalStyle } from "../../styles/styleModal";
import clienteAxios from "../../../config/axios";

export const SendMessage = ({ name, chat_id }) => {
  const { socket } = useProvideSocket();

  const clases = useModalStyle();
  const inputRef = useRef < HTMLInputElement > null;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [mensaje, setmensaje] = useState("");
  const [cargando, setcargando] = useState(false);
  const [file, setfile] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mensaje.trim()) {
      return mostrarAlerta("Mensaje obligatorio", "error");
    }
    e.preventDefault();
    // handleBlur();
    // setisSubmited(true);
    // if (isFormInvalidSubmit(formValues)) {
    //   return;
    // }
    // onResetForm();
    setmensaje("");
    try {
      setcargando(true);
      const formDataLogos = new FormData();

      await formDataLogos.append("archivo", file);
      formDataLogos.append("carpeta", "chat");
      console.log({ file });

      const resDocumentos = file
        ? await clienteAxios.post(
            "/subir_eliminar_archivo/subir",
            formDataLogos
          )
        : null;
      socket?.emit("client:send-mensaje", {
        opcion: "GROUP",
        rucempresa: usuario.rucempresa,
        rol: [],
        chat_id: chat_id,
        person_id: usuario.id,
        message_text: mensaje,
        message_audio: "",
        message_fotos: resDocumentos?.data?.url ? [resDocumentos.data.url] : [],
        message_videos: [],
      });
      setfile("");
      setcargando(false);
    } catch (error) {
      console.log({ error });

      setcargando(false);
      setfile("");
      return mostrarAlerta("Hubo un error", "error");
    }
  };

  useEffect(() => {
    inputRef.current?.select();
  }, [name]);

  const refArchivoDocumentoState = createRef();
  const focusInputDocumentoState = () => {
    refArchivoDocumentoState.current.click();
  };
  const [tipoArchivo, settipoArchivo] = useState("");
  const onChangeArchivoDocumentoState = async (e) => {
    if (!e.target.files[0]) {
      return setfile("");
    }
    const maxFileSize = 4 * 1024 * 1024;
    const archivo = [
      "pdf",
      "vnd.openxmlformats-officedocument.presentationml.presentation",
      "vnd.openxmlformats-officedocument.wordprocessingml.document",
      "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].some((item) => item === e.target.files[0].type.split("/")[1]);
    const imagen = ["png", "jpg", "jpeg"].some(
      (item) => item === e.target.files[0].type.split("/")[1]
    );
    if (archivo) {
      settipoArchivo("documento");
    }
    if (imagen) {
      settipoArchivo("imagen");
    }
    if (!archivo && !imagen) {
      return mostrarAlerta(
        "El archivo debe ser PDF, EXCEL, WORD, POWERPOINT, png, jpg o jpeg",
        "error"
      );
    }

    if (e.target.files[0].size > maxFileSize) {
      mostrarAlerta("El peso del archivo sobrepasa el limite de 4MB", "error");
    }
    setfile(e.target.files[0]);
  };
  return (
    <form onSubmit={handleSubmit}>
      <input
        className={clases.ocultar}
        type="file"
        name="myImage"
        onChange={(e) => onChangeArchivoDocumentoState(e)}
        ref={refArchivoDocumentoState}
        accept={"image/*, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx"}
      />

      {file && (
        <ImageList sx={{ width: "100%" }} cols={3} rowHeight={250}>
          {[file].map((fileNuevo) => {
            console.log(fileNuevo);

            return (
              <ImageListItem key={fileNuevo.name}>
                <img
                  src={URL.createObjectURL(fileNuevo)}
                  alt={fileNuevo.name}
                  loading="lazy"
                />
                <ImageListItemBar
                  sx={{
                    background: (theme) => theme.palette.success.dark,
                  }}
                  title={fileNuevo.name}
                  actionIcon={
                    <Box display={"flex"}>
                      <IconButton
                        aria-label="Abrir en el navegador"
                        color="secondary"
                        onClick={() =>
                          window.open(URL.createObjectURL(fileNuevo))
                        }
                        size="small"
                      >
                        <OpenInBrowser fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="Eliminar"
                        onClick={() => {
                          setfile(false);
                        }}
                        size="small"
                      >
                        <Delete color="error" fontSize="small" />
                      </IconButton>
                    </Box>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      )}
      <TextField
        autoFocus
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={`Subir Archivo`} arrow>
                <Box>
                  <IconButton
                    // disabled={disabled}
                    onClick={focusInputDocumentoState}
                  >
                    <CloudUpload
                      color={
                        // disabled ? "inherit" :
                        "secondary"
                      }
                    />
                  </IconButton>
                </Box>
              </Tooltip>
              <IconButton aria-label="Enviar" type="submit" disabled={cargando}>
                <Send color="success"></Send>
              </IconButton>
            </InputAdornment>
          ),
        }}
        fullWidth
        name="mensaje"
        label="Mensaje"
        value={mensaje}
        onChange={(e) => {
          setmensaje(e.target.value);
        }}
        // onBlur={handleBlur}
        // error={errorValues.mensaje.length > 0}
        // helperText={errorValues.mensaje.join(" - ")}
      />
      {cargando && <LinearProgress />}
    </form>
  );
};
