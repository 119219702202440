import React, { memo, useContext } from "react";

import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check, Create, DeleteForever } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../../config/const";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { arrayExport, setarrayExport, row, index, campoExport, socket } =
    props;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Bancos");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        
        tabla: "banco",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        banUser: usuario.usuario,
        banEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          
          tabla: "banco",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          banUser: usuario.usuario,
          banEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: false,
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        {editar ? (
          <>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                value={item.banNombre}
                onChange={(e) => {
                  setitem({
                    ...item,
                    banNombre: e.target.value,
                  });
                }}
                size="small"
                helperText="Editando"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                value={item.banCodigo}
                onChange={(e) => {
                  setitem({
                    ...item,
                    banCodigo: e.target.value,
                  });
                }}
                size="small"
                helperText="Editando"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={classes.celda}>
              {row.banNombre}
            </TableCell>
            <TableCell size="small" align="center" className={classes.celda}>
              {row.banCodigo}
            </TableCell>
          </>
        )}
        <TableCell size="small" align="center" className={classes.celda}>
          {item.banUser}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
