import React, { memo, useState, useContext } from "react";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";

// MATERIAL
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Popper,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import { FechaExacta } from "../../../../config/const";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import ButtonsAction from "../../../components/ButtonsAction";

const RowAgregarArma = (props) => {
  const { setagregar, setitem, item, estadoRequest } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const objetoDefault = {
    id: "",
    numDocumento: "",
    nombres: "",
    numFactura: "",
    cantidad: "1",
    fecFactura: FechaExacta,
    estado: "",
    minimo: "1",
    maximo: "1",
  };
  const [itemHijoNuevo, setitemHijoNuevo] = useState(objetoDefault);
  const [proveedoresData, setproveedoresData] = useState([]);
  const botonDisabled = () => {
    if (
      itemHijoNuevo.numDocumento === "" ||
      itemHijoNuevo.nombres === "" ||
      itemHijoNuevo.numFactura === "" ||
      itemHijoNuevo.cantidad === "" ||
      itemHijoNuevo.fecFactura === "" ||
      itemHijoNuevo.estado === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregar(false);
        setitemHijoNuevo(objetoDefault);
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        setagregar(false);
        setitemHijoNuevo(objetoDefault);
        handleAgregar();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  const handleAgregar = async () => {
    try {
      const invProveedor = [
        {
          ...itemHijoNuevo,
        },
        ...item.invProveedor,
      ];

      setitem({
        ...item,
        invProveedor,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <ButtonsAction botones={botones} />
        {/* las demas columnas */}
        <TableCell size="small" align="center" className={classes.celda}>
          <FormControl fullWidth>
            <Autocomplete
              PopperComponent={PopperMy}
              disableClearable={true}
              options={proveedoresData.map((item) => {
                return (
                  item.cliDocNumero +
                  "/+/" +
                  item.cliRazonSocial +
                  "/+/" +
                  item.cliId
                );
              })}
              getOptionLabel={(option) => {
                return option.replace("/+/", " ").split("/+/")[0];
              }}
              value={itemHijoNuevo.numDocumento}
              paperprops
              onChange={async (event, newValue) => {
                if (newValue) {
                  const options = newValue.split("/+/");

                  setitemHijoNuevo({
                    ...itemHijoNuevo,
                    numDocumento: options[0],
                    nombres: options[1],
                    id: options[2],
                  });
                } else {
                  setitemHijoNuevo({
                    ...itemHijoNuevo,
                    id: "",
                    numDocumento: "",
                    nombres: "",
                  });
                  setproveedoresData([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required
                  fullWidth
                  onChange={async (e) => {
                    if (e.target.value === "") {
                      return mostrarAlerta("Ingrese datos", "error");
                    }
                    try {
                      const res = await clienteAxios.get(
                        `/proveedores/filtro/0?search=${e.target.value}`
                      );

                      setproveedoresData(res.data.data);
                    } catch (error) {
                      setitemHijoNuevo({
                        ...itemHijoNuevo,
                        id: "",
                        numDocumento: "",
                        nombres: "",
                      });
                      setproveedoresData([]);

                      return mostrarAlerta("No hay proveedores", "error");
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {itemHijoNuevo.nombres}
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            value={itemHijoNuevo.numFactura}
            onChange={(e) =>
              setitemHijoNuevo({
                ...itemHijoNuevo,
                numFactura: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            type="date"
            value={itemHijoNuevo.fecFactura}
            onChange={(e) =>
              setitemHijoNuevo({
                ...itemHijoNuevo,
                fecFactura: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>{" "}
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            type="number"
            value={itemHijoNuevo.estado}
            onChange={(e) =>
              setitemHijoNuevo({
                ...itemHijoNuevo,
                estado: e.target.value,
              })
            }
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["NUEVO", "USADO", "OPERABLE", "BUEN ESTADO", "DEFECTUOSO"].map(
              (item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              }
            )}
          </TextField>
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            disabled={estadoRequest === "ARMAS"}
            type="number"
            value={itemHijoNuevo.cantidad}
            onChange={(e) =>
              setitemHijoNuevo({
                ...itemHijoNuevo,
                cantidad: Math.max(e.target.value, 1),
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            disabled={estadoRequest === "ARMAS"}
            type="number"
            value={itemHijoNuevo.minimo}
            onChange={(e) =>
              setitemHijoNuevo({
                ...itemHijoNuevo,
                minimo: Math.max(e.target.value, 1),
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            disabled={estadoRequest === "ARMAS"}
            type="number"
            value={itemHijoNuevo.maximo}
            onChange={(e) =>
              setitemHijoNuevo({
                ...itemHijoNuevo,
                maximo: Math.max(e.target.value, 1),
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
