import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "planDocumentoCabecera",
    label: "Documento",
    minWidth: 150,
    tipo: "",
    orden: 1,
    propiedad: "planDocumentoCabecera",
    align: "center",
    ocultar: true,
  },
  {
    id: "planNombre",
    label: "Nombre",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "planNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "planYear",
    label: "Año",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "planYear",
    align: "center",
    ocultar: true,
  },
  {
    id: "planFec1",
    label: "F. Emisión",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planFec1",
    align: "center",
    ocultar: true,
  },
  {
    id: "planFec2",
    label: "F. Revision",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planFec2",
    align: "center",
    ocultar: true,
  },
  {
    id: "planVersion",
    label: "V.",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planVersion",
    align: "center",
    ocultar: true,
  },
  {
    id: "planUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // planId: 16,
  // CABECERA LINEAL
  planDocumentoCabecera: "",
  planNombre: "", // input
  planYear: "2023", // year
  planFec1: FechaExacta, // date
  planFec2: FechaExacta, // date
  planVersion: "", // input
  // FORMULARIO
  // tab DATOS GENERALES
  planSecuencia: "", // yo lleno en el backend
  planMotivo: "RIESGO", // select=> RIESGO || OBJETIVO
  planTipo: "OBJETIVO", // tomar del tab, valor interno
  // tab METODOS
  // tab ACCIONES
  planIdCliente: "",
  planDocumentoCliente: "",
  planAsunto: "",
  planDatosHijos: [],
  // OTROS
  planUser: "admin",
  planEmpresa: "PRUEBA",
  planFecReg: "2023-04-21T22:38:31.000Z",
  planFecUpd: "2023-04-27T21:09:43.000Z",
};
export const objectDefaultHijo = {
  planFecha: FechaExacta, // Date
  planProceso: "ADMINISTRACION", //select=> LOGISTICA || ADMINISTRACION || GERENTE || ASISTENTE DE GERENCIA || JEFE DE OPERACIONES || COMPRAS PUBLICAS || CONTADOR || SECRETARIA || SERVICIOS VARIOS || OTROS
  planIdReceptor: "", // Endpoint
  planIdEmisor: "", // login id
  planNombreEmisor: "", // login nombre
  planDocumentoReceptor: "", //  Endpoint
  planNombreReceptor: "", // Endpoint
  planTipo: "OBJETIVO", // tomar del tab, valor interno
  planMotivo: "RIESGO", // select=> RIESGO || OBJETIVO
  planFechaAtencion: new Date(
    Date.now() - new Date().getTimezoneOffset() * 60 * 1000 + 86400000
  )
    .toISOString()
    .split("T")[0],
  planPrioridad: "MEDIA",
  planDetalles: "", // textarea
  planMetodo: [
    // { metodo: "", porque: "" }
  ],
  planAccionesFecha: "",
  planAccionesStatus: "NO PROCESADO", // PROCESADO
  planDetalleAcciones: "", // textarea
  planAccionesDocumento: "",
  planAccionesFotos: [],
  planDocumento: "",
  planDocumento2: "",
  planFotos: [],
};

export const itemHijoDefecto = {
  metodo: "",
  porque: "",
};
export const columnasHijo = [
  {
    id: "planFecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planFecha",
    align: "center",
    ocultar: true,
  },
  {
    id: "planDocumento",
    label: "Documento",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "planDocumento",
    align: "center",
    ocultar: true,
  },
  {
    id: "planAccionesStatus",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planAccionesStatus",
    align: "center",
    ocultar: true,
  },
  {
    id: "planProceso",
    label: "Area",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planProceso",
    align: "center",
    ocultar: true,
  },

  {
    id: "planNombreReceptor",
    label: "Receptor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planNombreReceptor",
    align: "center",
    ocultar: true,
  },
  {
    id: "planNombreEmisor",
    label: "Emisor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planNombreEmisor",
    align: "center",
    ocultar: true,
  },
  {
    id: "planNombreEmisor",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planNombreEmisor",
    align: "center",
    ocultar: true,
  },
  {
    id: "planDetalles",
    label: "Detalles",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planDetalles",
    align: "center",
    ocultar: true,
  },
];
