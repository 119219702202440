import React, { memo, useContext } from "react";

import { permisoDenagado } from "../../../config/const";
import { withRouter } from "react-router-dom";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import MenuContext from "../../../Context/Menu/MenuContext";
import { tienePermisoRuta } from "../../../config/validaciones";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Box,
} from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ExportDialog = (props) => {
  const classes = useStyles();
  const { open, setopen, setopenConfirmDialog, row } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);

  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Acciones.</DialogTitle>
        <List>
          <ListItem
            button
            disabled={row.estado === "AUTORIZADO"}
            onClick={() => {
              if (
                !tienePermisoRuta(
                  rows,
                  props.location.pathname,
                  usuario.rol,
                  "eliminar"
                )
              ) {
                return mostrarAlerta(permisoDenagado, "error");
              }
              setopenConfirmDialog(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <DeleteForever />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Eliminar" />
          </ListItem>{" "}
        </List>
      </Box>
    </Dialog>
  );
};

export default memo(withRouter(ExportDialog));
