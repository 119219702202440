export const columns = [
  {
    id: "bodNombre",
    label: "Nombre",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodDireccion",
    label: "Dirección",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodDireccion",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodCelular",
    label: "Celular",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodCelular",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodEmail",
    label: "Email",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodEmail",
    align: "center",
    ocultar: true,
  },
  {
    id: "bodResponsable",
    label: "Responsable",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "bodResponsable",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  bodNombre: "",
  bodDireccion: "",
  bodCelular: "",
  bodEmail: "",
  bodResponsable: "",
  bodEmpresa: "",
  bodEstado: "ACTIVO",
  bodUser: "",
};
