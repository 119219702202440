import React, { memo, useContext, useState } from "react";

// COMPONENTES

//CSS
import "../../Extra/GlobalCssSlider.css";

// CONTEXT
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { DeleteForever, PictureAsPdf, MenuBook } from "@mui/icons-material";
import ConfirmacionDialog from "../../Extra/ConfirmacionDialog";
import Cargando from "../../Extra/Cargando";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ExportDialog = (props) => {
  const classes = useStyles();
  const { socket, usuario } = useContext(AuthContext);
  const {
    open,
    setopen,
    funcPdf,
    funcExcel,
    arrayExport,
    tipo,
    settipo,
    // campoExport,
    tabla,
    otraFuncionExport,
    exportarExcel,
    ocultarDelete,
    ocultarExportarPdf,
  } = props;
  const [cargando, setcargando] = useState(false);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = async () => {
    await setcargando(true);

    await setTimeout(async () => {
      socket.emit("client:eliminarDataSeleccionado", {
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla,
        lista: arrayExport,
      });
      setcargando(false);
      setopen(false);
    }, 500);
  };
  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este/estos registros?`}
        funcion={handleEliminar}
      />
      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Opciones.</DialogTitle>
        <TextField
          id=""
          label=""
          fullWidth
          value={tipo}
          onChange={(e) => {
            settipo(e.target.value);
          }}
          select
        >
          {[
            { value: "seleccion", label: "Seleccion" },
            { value: "todos", label: "Todos" },
          ].map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
        {cargando ? (
          <Cargando titulo="Cargando." />
        ) : (
          <>
            <List>
              {ocultarExportarPdf ? null : (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    setcargando(true);
                    await funcPdf();
                    setcargando(false);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <PictureAsPdf />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Exportar" />
                </ListItem>
              )}
              {exportarExcel ? (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    await funcExcel();
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <MenuBook />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Excel" />
                </ListItem>
              ) : null}
              {ocultarDelete ? null : (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.delete}>
                      <DeleteForever />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Eliminar" />
                </ListItem>
              )}

              {otraFuncionExport ? (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    await setcargando(true);

                    await setTimeout(async () => {
                      otraFuncionExport.funcion(tipo);
                      setcargando(false);
                      setopen(false);
                    }, 500);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.bg}>
                      {otraFuncionExport.icono}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={otraFuncionExport.titulo} />
                </ListItem>
              ) : null}
            </List>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default memo(ExportDialog);
