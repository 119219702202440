export const columns = [
  {
    id: "apoTipo",
    label: "Tipo",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "apoTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "apoAporte",
    label: "Aporte",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "apoAporte",
    align: "center",
    ocultar: true,
  },
  {
    id: "apoPorcentaje",
    label: "Porcentaje",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "apoPorcentaje",
    align: "center",
    ocultar: true,
  },
  {
    id: "apoAcumula",
    label: "Acumula",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "apoAcumula",
    align: "center",
    ocultar: true,
  },
  {
    id: "apoFormula",
    label: "Formula",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "apoFormula",
    align: "center",
    ocultar: true,
  },
  {
    id: "apoUser",
    label: "User",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "apoUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  apoTipo: "INGRESO", // selectt => INGRESO  EGRESO
  apoAporte: "", // text
  apoPorcentaje: "0", // number
  apoAcumula: "NO", // select => SI  NO
  apoFormula: "", // text
  apoUser: "",
  apoEmpresa: "",
  apoFecReg: "",
};
