import React from "react";
import SubSubCategoria from "./SubSubCategoria";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  lighten,
  Tooltip,
  IconButton,
  Collapse,
} from "@mui/material";
import themeF from "../../../../config/themeUser";
import { useContext } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { hacerIcono } from "../../../../config/const";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
const SubCategoria = (props) => {
  const { usuario } = useContext(AuthContext);
  const theme = themeF(usuario);
  const {
    subCategoria,
    categoria,
    mobile,
    handleDrawerToggle,
    activeRoute,
    open,
    rows,
    openExpand,
    handleClick,
  } = props;

  return (
    <>
      <Tooltip title={subCategoria.sidInfo.name} placement="right-start">
        <ListItem
          disablePadding
          sx={{
            maxHeight: "2.25rem",
            backgroundColor: activeRoute(
              subCategoria.sidInfo.layout +
                subCategoria.sidInfo.categoria +
                subCategoria.sidInfo.path
            )
              ? lighten(theme.palette.primary.main, 0.7)
              : "",
            transitionProperty: "backgroundColor",
            transitionDuration: "1s",
          }}
        >
          <ListItemButton
            onClick={() => {
              if (mobile) handleDrawerToggle();
              props.history.push(
                subCategoria.sidInfo.layout +
                  subCategoria.sidInfo.categoria +
                  subCategoria.sidInfo.path
              );
            }}
            sx={{
              minHeight: 40,
              justifyContent: open ? "initial" : "center",
              px: 2,
              py: 0,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1 : "auto",
                justifyContent: "center",
              }}
            >
              {hacerIcono(subCategoria.sidInfo.icon, "secondary")}
            </ListItemIcon>
            <ListItemText
              primary={subCategoria.sidInfo.name}
              primaryTypographyProps={{
                color: "secondary",
                fontWeight: "bold",
                fontSize: ".8rem",
              }}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
          {subCategoria.subSubCategorias.length === 0 ? null : (
            <IconButton
              aria-label="Expandir"
              size="small"
              onClick={(e) => {
                handleClick(
                  subCategoria.sidInfo.name,
                  Boolean(openExpand[subCategoria.sidInfo.name])
                );
              }}
              color="primary"
            >
              {Boolean(openExpand[subCategoria.sidInfo.name]) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          )}
        </ListItem>
      </Tooltip>{" "}
      <Collapse
        in={openExpand[subCategoria.sidInfo.name]}
        timeout="auto"
        unmountOnExit
      >
        {subCategoria.subSubCategorias.map((subSubCategoria) => (
          <SubSubCategoria
            subSubCategoria={subSubCategoria}
            subCategoria={subCategoria}
            categoria={categoria}
            mobile={mobile}
            handleDrawerToggle={handleDrawerToggle}
            activeRoute={activeRoute}
            open={open}
            rows={rows}
            openExpand={openExpand}
            handleClick={handleClick}
          />
        ))}
      </Collapse>
    </>
  );
};

export default withRouter(SubCategoria);
