import React, { memo, useContext } from "react";

import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

const itemHijoDefecto = { estado: "", cantidad: 5 };
const RowAgregarStock = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setagregar, setitemPadre, itemPadre } = props;

  const [itemHijo, setitemHijo] = useState(itemHijoDefecto);
  //onChange
  const handleChange = (e) => {
    setitemHijo({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (itemHijo.estado.trim() === "" || itemHijo.cantidad === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      const existeEstado = itemPadre.invTotalesStock.some(
        ({ estado }) => estado === itemHijo.estado
      );
      if (existeEstado) {
        return mostrarAlerta("Ya hay un stock con este estado", "error");
      }
      setitemPadre({
        ...itemPadre,
        invTotalesStock: [
          trimPropsItem(itemHijo),
          ...itemPadre.invTotalesStock,
        ],
      });
      setitemHijo(itemHijoDefecto);
      setagregar(false);
    } catch (error) {}
  };
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ButtonsAction
        botones={[
          {
            tooltip: "Cancelar",
            texto: "",
            funcion: () => {
              setagregar(false);
              setitemHijo(itemHijoDefecto);
              // setItemEdit(itemHijo);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
          },
          {
            tooltip: "Guardar",
            texto: "",
            funcion: () => {
              funcSubmit();
            },
            disabled: botonDisabled(),
            Icon: Check,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
            submit: true,
          },
        ]}
      />
      <>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            autoFocus
            type="number"
            value={itemHijo.estado}
            name="estado"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["NUEVO", "USADO", "OPERABLE", "DEFECTUOSO"].map((item) => {
              return (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField>
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            type="number"
            value={itemHijo.cantidad}
            name="cantidad"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
      </>
    </TableRow>
  );
};

export default withRouter(memo(RowAgregarStock));
