import React, { memo, useContext, useMemo } from "react";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";

// MATERIAL
import {
  Autocomplete,
  Box,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Map,
  QrCode,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import ButtonsAction from "../../../components/ButtonsAction";
import RowHijoDeHijo from "./HijosDeHijos/RowHijoDeHijo";
import RowAgregarHijoDeHijo from "./HijosDeHijos/RowAgregarHijoDeHijo";
import { columnasHijoDelHijo, itemHijoDelHijoDefecto } from "../../data";
import TablaCabecera from "../../../components/TablaCabecera";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import ToolBarTable from "../../../components/ToolBarTable";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { trimPropsItem } from "../../../../config/const";
import { estaEntre, sumarMinutos } from "../../../functions/funciones";
import DialogMap from "../../../components/DialogMap";
import CampoDoc from "../../../components/CampoDoc";
import ImagenCelda from "../../../components/ImagenCelda";
import { v4 } from "uuid";
import clienteAxios from "../../../../config/axios";

const RowHijo = (props) => {
  const classes = useRowStyles();
  const {
    datosOperativos,
    estadoRequest,
    rowHijo,
    rowPadre,
    indexHijo,
    socket,
    horarioError,
    sethorarioError,
    fromIOP,
    // setitemPadre,
  } = props;
  const claseCelda = `${
    indexHijo % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    horarioError.some((idError) => idError === rowHijo.id)
      ? classes.celdaEliminado
      : ""
  }`;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [itemHijo, setitemHijo] = useState(rowHijo);
  const [editar, seteditar] = useState(false);
  const [openMap, setopenMap] = useState(false);
  const [soloVerMapa, setsoloVerMapa] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("EventosActividades");
  // HOOK TABLA
  const {
    agregandoHorario,
    setagregandoHorario,
    pageHorario,
    // setPageHorario,
    rowsPerPageHorario,
    // setRowsPerPageHorario,
    handleChangePageHorario,
    handleChangeRowsPerPageHorario,
  } = useSimpleTable("Horario");
  const esSupervisor = useMemo(() => estadoRequest === "SUPERVISOR", [
    estadoRequest,
  ]);
  const [expandir, setexpandir] = useState(false);
  const botonDisabled = () => {
    if (
      itemHijo.nombre === "" ||
      itemHijo.minutos === "" ||
      (itemHijo.puesto === "" && estadoRequest === "SUPERVISOR")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }
        setitemHijo(rowHijo);
        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Expandir",
      texto: "",
      funcion: () => {
        setexpandir((prev) => !prev);
      },
      disabled: false,
      Icon: expandir ? ExpandLess : ExpandMore,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "QR",
      texto: "",
      funcion: () => {
        console.log({ rowHijo, rowPadre });
        window.open(
          `${clienteAxios.defaults.baseURL}/reportes/qr_actividad_supervisor?cliId=${rowPadre.eveCliId}&rucempresa=${rowPadre.eveEmpresa}&hoja=A4&cantidad=1&ancho=95&alto=95&puesto=${rowHijo.puesto}&ubicacion=${rowHijo.ubicacion}`
        );
      },

      disabled: false,
      Icon: QrCode,
      color: "primary",
      id: 2,
      ocultar: estadoRequest !== "SUPERVISOR",
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        if (esSupervisor) {
          sethorarioError([]);
        }
        seteditar(false);
        setitemHijo(rowHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        let existe = false;
        let errores = [];

        rowPadre.eveActividades
          .map((itemActividad) =>
            itemActividad.id === itemHijo.id ? itemHijo : itemActividad
          )
          .forEach((itemHijoMap, indexHijoMap) => {
            itemHijo.horario.forEach((horarioHijo) => {
              itemHijoMap.horario.forEach((horarioVItem) => {
                if (
                  (estaEntre(
                    horarioVItem.hora,
                    horarioVItem.horaFinal,
                    horarioHijo.hora
                  ) ||
                    estaEntre(
                      horarioVItem.hora,
                      horarioVItem.horaFinal,
                      horarioHijo.horaFinal
                    )) &&
                  horarioVItem.id !== horarioHijo.id
                ) {
                  existe = true;
                  errores = [...errores, horarioVItem.id, itemHijoMap.id];
                }
              });
            });
          });
        if (existe) {
          sethorarioError(errores);
          // setitemPadre((prev) => ({ ...prev, eveActividades }));
          return mostrarAlerta("Ya se esta utilizando este horario", "error");
        }
        sethorarioError([]);
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    const existe = rowPadre.eveActividades.some(
      (itemActividad) =>
        itemHijo.nombre === itemActividad.nombre &&
        itemHijo.nombre !== rowHijo.nombre
    );
    if (existe) {
      return mostrarAlerta("Ya existe esta actividad", "error");
    }
    socket.current.emit("client:actualizarData", {
      ...rowPadre,
      tabla: "eventoactividad",
      eveActividades: rowPadre.eveActividades.map((item, itemIndex) => {
        if (indexHijo === itemIndex) {
          return trimPropsItem({
            ...itemHijo,
            horario: itemHijo.horario.map((itemHorarioHijo) => {
              return {
                ...itemHorarioHijo,
                horaFinal: esSupervisor
                  ? itemHorarioHijo.horaFinal
                  : sumarMinutos(
                      itemHorarioHijo.hora,
                      Number(itemHijo.minutos)
                    ),
              };
            }),
          });
        } else {
          return item;
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      eveUser: usuario.usuario,
      eveEmpresa: usuario.rucempresa,
    });

    seteditar(false);
  };
  const handleChange = (e) => {
    setitemHijo({
      ...itemHijo,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...rowPadre,
      tabla: "eventoactividad",
      eveActividades: rowPadre.eveActividades.filter((item, itemIndex) => {
        return indexHijo !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      eveUser: usuario.usuario,
      eveEmpresa: usuario.rucempresa,
    });
    setopenConfirmDialog(false);
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <DialogMap
        open={openMap}
        setopen={setopenMap}
        setitem={setitemHijo}
        item={itemHijo}
        editar={true}
        propiedad={itemHijo.coordenadas}
        nombrePropiedad={"coordenadas"}
        solover={soloVerMapa}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={rowHijo}
          botones={editar ? botonesEditar : botones}
        />
        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                autoFocus
                value={itemHijo.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            {estadoRequest === "SUPERVISOR" && (
              <TableCell
                padding="none"
                size="small"
                align="center"
                className={claseCelda}
              >
                <FormControl fullWidth>
                  <Autocomplete
                    PopperComponent={PopperMy}
                    disableClearable={true}
                    options={datosOperativos.map((item) => {
                      return item.puesto + "/+/" + item.ubicacion;
                    })}
                    getOptionLabel={(option) => {
                      return option.replace("/+/", "- ");
                    }}
                    id="controlled-demo"
                    value={
                      (itemHijo.puesto + " " + itemHijo.ubicacion).trim() === ""
                        ? ""
                        : itemHijo.puesto + " - " + itemHijo.ubicacion
                    }
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const [puesto, ubicacion] = newValue.split("/+/");
                        const itemF = datosOperativos.find(
                          (itemDatoO) =>
                            itemDatoO.puesto === puesto &&
                            itemDatoO.ubicacion === ubicacion
                        );

                        setitemHijo({
                          ...itemHijo,
                          coordenadas: itemF.coordenadas,
                          puesto,
                          ubicacion,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </TableCell>
            )}
            {rowPadre.eveTipo === "RONDAS" ? (
              <TableCell
                fontSize="small"
                align="center"
                className={classes.celda}
              >
                <TextField
                  disabled
                  value={
                    itemHijo.coordenadas.latitud +
                    " " +
                    itemHijo.coordenadas.longitud
                  }
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <Box htmlFor="contained-button-documento" fullWidth>
                        <InputAdornment>
                          <Tooltip title="Mostrar Mapa" arrow>
                            <Box>
                              <IconButton
                                aria-label=""
                                onClick={() => {
                                  setitemHijo({ ...rowHijo });
                                  setsoloVerMapa(false);
                                  setopenMap(true);
                                }}
                              >
                                <Map color="secondary" />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        </InputAdornment>
                      </Box>
                    ),
                  }}
                />
              </TableCell>
            ) : null}
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                type="number"
                value={itemHijo.minutos}
                name="minutos"
                onChange={(e) => {
                  function formatTime(date) {
                    let hours = date.getHours();
                    let minutes = date.getMinutes();

                    // Asegurarse de que las horas y los minutos tengan dos dígitos
                    hours = hours < 10 ? "0" + hours : hours;
                    minutes = minutes < 10 ? "0" + minutes : minutes;

                    return hours + ":" + minutes;
                  }

                  const minutos = Math.max(
                    e.target.value,
                    estadoRequest === "SUPERVISOR" ? 1 : 5
                  );
                  let array = [];
                  if (esSupervisor) {
                    let horaInicial = new Date();

                    // Establecer la hora inicial a las 10:00
                    horaInicial.setHours(10, 0, 0);

                    for (let index = 0; index < minutos; index++) {
                      // Crear una nueva fecha para la hora final
                      let horaFinal = new Date(horaInicial.getTime());

                      // Añadir 5 minutos a la hora final
                      horaFinal.setMinutes(horaFinal.getMinutes() + 5);
                      if (rowHijo.horario[index]) {
                        array.push(rowHijo.horario[index]);
                      } else {
                        array.push({
                          ...itemHijoDelHijoDefecto,
                          nuevo: true,
                          id: v4(),
                          hora: formatTime(horaInicial),
                          horaFinal: formatTime(horaFinal),
                        });
                      }

                      // Establecer la nueva hora inicial al valor de la hora final
                      horaInicial = horaFinal;
                      horaFinal.setMinutes(horaFinal.getMinutes() + 1);
                    }

                    // Función para formatear una fecha a una cadena de tiempo HH:mm
                  }
                  setitemHijo({
                    ...itemHijo,
                    minutos,
                    horario: esSupervisor ? array : itemHijo.horario,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            {fromIOP && (
              <>
                <TableCell
                  size="small"
                  align="center"
                  className={classes.celda}
                >
                  <TextField
                    multiline
                    value={itemHijo.vulnerabilidad}
                    name="vulnerabilidad"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <CampoDoc
                  extension={"IMAGEN"}
                  claseCelda={classes.celda}
                  cargando={cargando}
                  setcargando={setcargando}
                  propiedad={"foto"}
                  item={itemHijo}
                  setitem={setitemHijo}
                  celda={true}
                />
              </>
            )}
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {rowHijo.nombre}
            </TableCell>
            {estadoRequest === "SUPERVISOR" && (
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.puesto + " - " + rowHijo.ubicacion}
              </TableCell>
            )}
            {rowPadre.eveTipo === "RONDAS" ? (
              <TableCell
                fontSize="small"
                padding="none"
                align="center"
                className={claseCelda}
              >
                <IconButton
                  aria-label=""
                  onClick={() => {
                    setsoloVerMapa(true);
                    setopenMap(true);
                  }}
                >
                  <Map color="secondary" />
                </IconButton>
                {rowHijo.coordenadas
                  ? rowHijo.coordenadas.latitud +
                    " " +
                    rowHijo.coordenadas.longitud
                  : null}
              </TableCell>
            ) : null}
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {rowHijo.minutos}
            </TableCell>
            {fromIOP && (
              <>
                <TableCell
                  fontSize="small"
                  align="center"
                  className={claseCelda}
                >
                  {rowHijo.vulnerabilidad}
                </TableCell>
                <ImagenCelda claseCelda={claseCelda} propiedad={rowHijo.foto} />
              </>
            )}
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
        >
          <Collapse
            in={expandir || (esSupervisor && editar)}
            timeout="auto"
            unmountOnExit
          >
            <Box>
              <ToolBarTable
                count={rowHijo.horario.length}
                rowsPerPage={rowsPerPageHorario}
                page={pageHorario}
                handleChangePage={handleChangePageHorario}
                handleChangeRowsPerPage={handleChangeRowsPerPageHorario}
                ocultarPaginacion={false}
                simplePagination={true}
                botonesIzquierdos={[
                  {
                    tooltip: "Agregar",
                    texto: "Agregar Horario",
                    funcion: () => {
                      setagregandoHorario(true);
                    },
                    disabled: false,
                    Icon: AddCircle,
                    color: "primary",
                    id: 1,
                    ocultar: esSupervisor,
                    tipo: "boton",
                    variante: "contained",
                    size: "medium",
                    sx: {},
                  },
                ]}
                botonesDerechos={[]}
              />

              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TablaCabecera
                    columns={columnasHijoDelHijo}
                    habilitarOrdenar={false}
                  />
                </TableHead>

                <TableBody component={"div"}>
                  {agregandoHorario ? (
                    <RowAgregarHijoDeHijo
                      indexHijo={indexHijo}
                      rowPadre={rowPadre}
                      rowHijo={rowHijo}
                      setagregandoHorario={setagregandoHorario}
                      socket={socket}
                      sethorarioError={sethorarioError}
                    />
                  ) : null}
                  {esSupervisor
                    ? itemHijo.horario
                        .slice(
                          pageHorario * rowsPerPageHorario,
                          pageHorario * rowsPerPageHorario + rowsPerPageHorario
                        )
                        .map((rowHijoDeHijo, indexHijoDeHijo) => {
                          return (
                            <RowHijoDeHijo
                              setitemHijo={setitemHijo}
                              editarPadre={editar}
                              esSupervisor={esSupervisor}
                              rowHijoDeHijo={rowHijoDeHijo}
                              indexHijo={indexHijo}
                              rowPadre={rowPadre}
                              rowHijo={rowHijo}
                              indexHijoDeHijo={indexHijoDeHijo}
                              key={rowHijoDeHijo.id}
                              socket={socket}
                              horarioError={horarioError}
                              sethorarioError={sethorarioError}
                            />
                          );
                        })
                    : rowHijo.horario
                        .slice(
                          pageHorario * rowsPerPageHorario,
                          pageHorario * rowsPerPageHorario + rowsPerPageHorario
                        )
                        .map((rowHijoDeHijo, indexHijoDeHijo) => {
                          return (
                            <RowHijoDeHijo
                              rowHijoDeHijo={rowHijoDeHijo}
                              indexHijo={indexHijo}
                              rowPadre={rowPadre}
                              rowHijo={rowHijo}
                              indexHijoDeHijo={indexHijoDeHijo}
                              key={rowHijoDeHijo.id}
                              socket={socket}
                              horarioError={horarioError}
                              sethorarioError={sethorarioError}
                            />
                          );
                        })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowHijo);
