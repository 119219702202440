import { FechaExacta, FechaExactaConHoras } from "../../../config/const";

export const columns = [
  {
    id: "actaTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "fecha",
    orden: 1,
    propiedad: "actaFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "tregistros",
    label: "Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "actaDocumento1",
    label: "Documentos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaDocumento1",
    align: "center",
    ocultar: true,
  },

  {
    id: "actaSecuencia",
    label: "Número",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaSecuencia",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaEstado",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaAsunto",
    label: "Asunto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaAsunto",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaPrioridad",
    label: "Prioridad",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaPrioridad",
    align: "center",
    ocultar: true,
  },

  {
    id: "actaFecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaFecha",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaLugar",
    label: "Lugar",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaLugar",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "actaFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actaFecReg",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  // actaId: 6,
  actaDocumento1: "",
  actaDocumento2: "",
  actaTipo: "Informe",
  actaPrioridad: "BAJA",
  actaAsunto: "",
  actaLugar: "",
  actaFecha: FechaExacta,
  actaPerId: "",
  actaPerNombre: "",
  actaPerDocNumero: "",
  actaPerfilPara: "",
  actaGuardias: [],
  // actaSupervisores,
  // actaAdministracion,
  // actaCliente,
  actaContenido: {},
  actaUser: "",
  actaEmpresa: "",
  actaFecReg: "",
  actaEstado: "EN PROCESO",
  actaSecuencia: "",
  actaFechaMaxRespuesta: FechaExactaConHoras,
};
// //   actaId: 2,
// turIdPersona: "",
// turDocuPersona: "",
// turNomPersona: "",
// turIdCliente: "",
// turDocuCliente: "",
// turNomCliente: "",
// turPuesto: [
//   {
//     ubicacion: "",
//     puesto: "",
//     supervisor: "",
//     guardias: "",
//     horasservicio: "",
//     tipoinstalacion: "",
//     vulnerabilidades: "",
//     consignas: [],
//   },
// ],
// turMotivo: "EVENTO ESPECIAL", // select=> FALTA INJUSTIFICADA, PERMISO MEDICO, PATERNIDAD, EVENTO ESPECIAL
// turAutorizado: "", // input
// turFechas: [],
// turFechasConsultaDB: [],
// turDiasTurno: "1",
// turDetalle: "", // textarea
// actaUser: "",
// actaEmpresa: "",
// actaFecReg: "",
// turEstado: "EN PROCESO",
// turStatusDescripcion: "",
export const contenidoDefault = {
  titulo: "",
  contenido: "",
  foto: "",
};
