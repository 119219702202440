import React from "react";
import Persona from "./Persona";

const ListPersonas = (props) => {
  const { personas } = props;
  return personas.map((persona, index) => {
    return (
      <Persona persona={persona} key={persona.perDocNumero} index={index} />
    );
  });
};

export default ListPersonas;
