import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";

import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
  Restore,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";

import { useRowStyles, butonIconTransition } from "../../../styles/stylesRows";
import clienteAxios from "../../../../config/axios";
import { usePermiso } from "../../../hooks/usePermiso";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../config/const";
const Row = ({
  arrayExport,
  setarrayExport,
  row,
  index,
  campoExport,
  socket,

  chatsData,
}) => {
  // CLASES
  const classes = useRowStyles();

  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("ChatParticipants");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // SI SE AGREGA UN ITEM A LA TABLA HIJA
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  const [personasData, setPersonasData] = useState([]);
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (item.chat_name === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "chat_participants",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        chat_user: usuario.usuario,
        ciuEmpresa: usuario.rucempresa,
        chat_id: row.chat_id,
        person_id: row.person_id,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = (agregando) => {
    try {
      if (agregando) {
        socket.current.emit(
          "client:guardarData",
          trimPropsItem({
            ...row,

            tabla: "chat_participants",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            chat_user: usuario.usuario + "/+/" + row[campoExport],
            ciuEmpresa: usuario.rucempresa,
            [campoExport]: row[campoExport],
          })
        );
      } else {
        socket.current.emit(
          "client:actualizarData",
          trimPropsItem({
            ...item,
            tabla: "chat_participants",
            rucempresa: usuario.rucempresa,
            rol: usuario.rol,
            chat_user: usuario.usuario,
            ciuEmpresa: usuario.rucempresa,
          })
        );
      }

      seteditar(false);
    } catch (error) {}
  };
  const handleEditar = () => {
    console.log({
      ...item,

      tabla: "chat_participants",
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      chat_user: usuario.usuario + "/+/" + item[campoExport],
      ciuEmpresa: usuario.rucempresa,
      [campoExport]: item[campoExport],
    });

    socket.current.emit(
      "client:actualizarData",
      trimPropsItem({
        ...item,

        tabla: "chat_participants",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        chat_user: usuario.usuario + "/+/" + item[campoExport],
        ciuEmpresa: usuario.rucempresa,
        [campoExport]: item[campoExport],
      })
    );
    seteditar(false);
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          claseCelda={claseCelda}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                // if (!tienePermiso("agregar")) {
                //   return alertaPermiso("agregar");
                // }
                funcSubmit(true);
              },
              disabled: usuario.usuario !== row.chat_user,
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                // if (!tienePermiso("editar")) {
                //   return alertaPermiso("editar");
                // }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || row.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                // if (!tienePermiso("eliminar")) {
                //   return alertaPermiso("eliminar");
                // }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: Delete,
              color: "error",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                handleEditar();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={chatsData.map((item) => {
                    return item.chat_name + "/+/" + item.chat_id;
                  })}
                  getOptionLabel={(option) => {
                    return option.split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={item.chat_name}
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const values = newValue.split("/+/");
                      setitem({
                        ...item,
                        chat_name: values[0],
                        chat_id: values[1],
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={personasData.map((item) => {
                    return (
                      item.num_documento +
                      "/+/" +
                      item.nombres +
                      "/+/" +
                      item.perId
                    );
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ").split("/+/")[0];
                  }}
                  id="controlled-demo"
                  value={item.num_documento}
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const values = newValue.split("/+/");
                      setitem({
                        ...item,
                        num_documento: values[0],
                        nombres: values[1],
                        person_id: values[2],
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      onChange={async (e) => {
                        const res = await clienteAxios(
                          `/personas/chat/participantes?search=${e.target.value}`
                        );
                        console.log({ res });

                        setPersonasData(res.data.data);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.nombres}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.chat_name}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.num_documento}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.nombres}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
