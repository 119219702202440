import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import React from "react";
import Cargando from "../../components/Cargando";
import { useState } from "react";
import clienteAxios from "../../../config/axios";
import { useEffect } from "react";

const DialogFechas = ({ open, setopen, rowHijo, rowPadre }) => {
  const [eventos, seteventos] = useState(null);
  useEffect(() => {
    const geteventos = async () => {
      const res = await clienteAxios(
        `/actividades_asignadas/persona?idevento=${rowHijo.act_asigEveId}&idPersona=${rowPadre.act_asigPerId}`
      );
      seteventos(res.data.data);
    };
    geteventos();
    // eslint-disable-next-line
  }, []);
  if (!eventos) {
    return null;
  }
  return (
    <Dialog
      open={open}
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      onClose={() => setopen(false)}
      aria-labelledby={"FECHAS EVENTOS"}
    >
      <DialogTitle textAlign={"center"}>Eventos</DialogTitle>
      {eventos ? (
        <>
          <DialogContent dividers sx={{ py: 1, textAlign: "center" }}>
            Fechas
            {eventos
              .sort((a, b) => {
                const aTotal = Date.parse(a.act_asigFecha.substr(0, 10));
                const bTotal = Date.parse(b.act_asigFecha.substr(0, 10));

                return aTotal - bTotal;
              })
              .map((eventoData) => (
                <DialogContentText
                  variant="body2"
                  key={eventoData.act_asigEveId}
                >
                  {new Date(
                    Date.parse(eventoData.act_asigFecha.substr(0, 10)) +
                      new Date().getTimezoneOffset() * 60 * 1000
                  ).toLocaleString("es-ES", {
                    weekday: "short",
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                </DialogContentText>
              ))}
          </DialogContent>
          <DialogContent dividers sx={{ py: 0 }}>
            {/* {rowHijo.act_asigHorario
              .sort((a, b) => {
                const [horaA, minutosA] = a.horaFinal.split(":");
                const [horaB, minutosB] = b.horaFinal.split(":");

                const aTotal = Number(horaA) * 60 + Number(minutosA);
                const bTotal = Number(horaB) * 60 + Number(minutosB);

                return aTotal - bTotal;
              })
              .map((horarioData, index) => (
                <DialogContentText key={index}>
                  {horarioData.nombreEvento}: {horarioData.hora} -{" "}
                  {horarioData.horaFinal}
                </DialogContentText>
              ))} */}

            {rowHijo.planiActividades.map((item) => (
              <Box my={1} key={item.id}>
                <Box
                  textAlign={"center"}
                  display={"flex"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Typography variant="body1" color="secondary" width={"50%"}>
                    {item.nombre}
                  </Typography>
                  <Typography
                    textAlign={"center"}
                    key={item.id}
                    variant="body2"
                    color="secondary"
                    width={"50%"}
                  >
                    {false ? "Intervalo de minutos" : "Número de revisiones"}
                    {": "}
                    <Typography color="primary" component={"span"}>
                      {item.minutos}
                    </Typography>
                  </Typography>
                </Box>
                <Divider>
                  <Typography variant="body2" color="secondary">
                    Horarios
                  </Typography>
                </Divider>
                {item.horario
                  .sort((a, b) => {
                    const [horaA, minutosA] = a.horaFinal.split(":");
                    const [horaB, minutosB] = b.horaFinal.split(":");

                    const aTotal = Number(horaA) * 60 + Number(minutosA);
                    const bTotal = Number(horaB) * 60 + Number(minutosB);

                    return aTotal - bTotal;
                  })
                  .map((horario) => (
                    <Typography
                      key={item.id}
                      variant="body2"
                      color="primary"
                      textAlign={"center"}
                    >
                      {horario.hora} - {horario.horaFinal}
                    </Typography>
                  ))}
                <Divider />
              </Box>
            ))}
          </DialogContent>
        </>
      ) : (
        <DialogContent>
          <Cargando />
          <DialogContentText>Cargando Eventos...</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={() => setopen(false)} color="primary">
          LISTO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFechas;
