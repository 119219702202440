import { Button } from "@mui/material";
import React, { useState } from "react";

export default function App(props) {
  const { videos } = props;
  // [
  //   {
  //     url: "https://video.aliexpress-media.com/play/u/ae_sg_item/2213009316091/p/1/e/6/t/10301/1100065545497.mp4",
  //     nombre: "si",
  //   },
  //   {
  //     url: "https://video.aliexpress-media.com/play/u/ae_sg_item/2213009316091/p/1/e/6/t/10301/1100019551016.mp4",
  //     nombre: "si",
  //   },
  //   {
  //     url: "https://video.aliexpress-media.com/play/u/ae_sg_item/2213009316091/p/1/e/6/t/10301/1100020207788.mp4",
  //     nombre: "si",
  //   },
  //   {
  //     url: "https://video.aliexpress-media.com/play/u/ae_sg_item/2213009316091/p/1/e/6/t/10301/1100019551016.mp4",
  //     nombre: "si",
  //   },
  //   {
  //     url: "https://video.aliexpress-media.com/play/u/ae_sg_item/2213009316091/p/1/e/6/t/10301/1100019551016.mp4",
  //     nombre: "si",
  //   },
  // ];
  const [active, setactive] = useState({ ...videos[0], index: 0 });
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === "ArrowRight") {
          if (active.index === videos.length - 1) {
            return;
          } else {
            setactive({ ...videos[active.index + 1], index: active.index + 1 });
          }
        }
        if (e.key === "ArrowLeft") {
          if (active.index === 0) {
            return;
          } else {
            setactive({ ...videos[active.index - 1], index: active.index - 1 });
          }
        }
      }}
    >
      {videos.map((video, index) => (
        <Button
          key={index}
          variant={active.index === index ? "contained" : null}
          disableElevation
          style={{ padding: "8px" }}
          color="secondary"
          onClick={() => setactive({ ...video, index })}
        >
          {index + 1}
        </Button>
      ))}
      <div>
        <video src={active.url} width="640" height="480" controls={true} />
      </div>
    </div>
  );
}
