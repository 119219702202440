import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import ImagenCelda from "../../../../components/ImagenCelda";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  EventNote,
  ExpandLess,
  ExpandMore,
  PictureAsPdf,
  Restore,
  WorkOutline,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import clienteAxios from "../../../../../config/axios";
import CeldaUser from "../../../../components/CeldaUser";
import { useSimpleTable } from "../../../../hooks/useSimpleTable";
import TablaCabecera from "../../../../components/TablaCabecera";
import RowPersona from "./RowPersona";
import ModalTrabajo from "../../ModalTrabajo";
import DialogFechas from "../../DialogFechas";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const {
    agregandoPersona,
    setagregandoPersona,
    pagePersona,
    // setPagePersona,
    rowsPerPagePersona,
    // setRowsPerPagePersona,
    // handleChangePagePersona,
    // handleChangeRowsPerPagePersona,
  } = useSimpleTable("Persona");
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [columnasGeneradas, setcolumnasGeneradas] = useState(null);
  const [personas, setPersonas] = useState(null);
  const [expandir, setexpandir] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        acti_user: usuario.usuario,
        acti_empresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        id_cliente: rowHijo.id_cliente,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          acti_user: usuario.usuario + "/+/" + rowHijo[campoExport],
          acti_empresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  const [horarioActivo, sethorarioActivo] = useState(null);
  const getHorarioActivo = async (id) => {
    const res = await clienteAxios(`/horarios/${id}`);
    sethorarioActivo(res.data);
  };

  const [openDialog, setopenDialog] = useState(false);
  const [openModalTrabajos, setopenModalTrabajos] = useState(false);
  return (
    <React.Fragment>
      <ModalTrabajo
        setopen={setopenModalTrabajos}
        open={openModalTrabajos}
        item={rowHijo}
      />
      {openDialog ? (
        <DialogFechas
          setopen={setopenDialog}
          open={openDialog}
          rowHijo={rowHijo}
          rowPadre={rowPadre}
        />
      ) : null}
      {horarioActivo ? (
        <Dialog
          maxWidth="xl"
          open={horarioActivo}
          onClose={() => sethorarioActivo(null)}
        >
          <DialogTitle
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            {horarioActivo.codigo} - {horarioActivo.tipo}
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            Hora : {horarioActivo.hora_inicio} - {horarioActivo.hora_final}
          </DialogContent>
        </Dialog>
      ) : null}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowHijo.acti_user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/renuncia_individual?acti_id=${rowHijo.acti_id}&rucempresa=${rowHijo.acti_empresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Ver eventos",
              texto: rowHijo.teventos,
              funcion: () => {
                setopenDialog(true);
              },
              disabled: false,
              Icon: EventNote,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Trabajos",
              texto: rowHijo.trabajos?.length,
              funcion: () => {
                setopenModalTrabajos(true);
              },
              disabled: false,
              Icon: WorkOutline,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: expandir ? "Contraer" : "Expandir",
              texto: rowHijo.id_horarios.length,
              funcion: async () => {
                setexpandir(!expandir);
                if (columnasGeneradas) {
                  return;
                }
                const res = await clienteAxios.post(
                  "/horarios_jornada_laboral/listByIdsHorariosFromActividades/0",
                  {
                    id_horarios: rowHijo.id_horarios,
                    planiIdDOperativo: rowHijo.planiIdDOperativo,
                  }
                );
                const diasBase = res.data.data[0].horarios;
                const obtenerDiasDelMes = diasBase.map((dia) => {
                  const fechaString =
                    Date.parse(dia.fecha_inicio) +
                    new Date().getTimezoneOffset() * 60 * 1000;

                  return {
                    id: index + 1,
                    label: new Date(fechaString).toLocaleString("es-ES", {
                      weekday: "short",
                      day: "2-digit",
                    }),
                    dia: true,
                    minWidth: 50,
                    tipo: "string",
                    orden: 1,
                    propiedad: index + 1,
                    align: "center",
                    ocultar: true,
                  };
                });
                setPersonas(res.data.data);
                setcolumnasGeneradas(obtenerDiasDelMes);

                // const dias = obtenerDiasDelMes(
                //   anio, //year
                //   mes //month
                // );
                // setcolumnasGeneradas(dias);
              },
              disabled: rowHijo.id_horarios.length === 0,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "primary",
              id: 2,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <ImagenCelda
            propiedad={rowHijo.acti_fotos}
            array={true}
            claseCelda={claseCelda}
          />

          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.planiTipo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.planiNombreActividad}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.ubicacion}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.puesto}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.acti_observacion}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={`${claseCelda}  `}
            style={{
              color:
                rowHijo.acti_prioridad === "BAJA"
                  ? "green"
                  : rowHijo.acti_prioridad === "MEDIA"
                  ? "orange"
                  : rowHijo.acti_prioridad === "ALTA"
                  ? "red"
                  : "red",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {rowHijo.acti_prioridad}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.acti_FecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>

          <CeldaUser user={rowHijo.acti_user} claseCelda={claseCelda} />
        </>
      </TableRow>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
        >
          {columnasGeneradas && personas && (
            <Collapse in={expandir} timeout="auto" unmountOnExit>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TablaCabecera
                    ocultarPrimeraColumna
                    columns={[
                      {
                        id: "perDocNumero",
                        label: "Persona",
                        minWidth: 250,
                        tipo: "string",
                        orden: 1,
                        propiedad: "perDocNumero",
                        align: "left",
                      },
                      ...columnasGeneradas,
                    ]}
                    habilitarOrdenar={false}
                  />
                </TableHead>
                <TableBody component={"div"}>
                  {personas
                    .slice(
                      pagePersona * rowsPerPagePersona,
                      pagePersona * rowsPerPagePersona + rowsPerPagePersona
                    )
                    .map((itemPersona, index) => {
                      return (
                        <RowPersona
                          actividad={rowHijo}
                          itemPersona={itemPersona}
                          index={index}
                          sethorarioActivo={sethorarioActivo}
                          horarioActivo={horarioActivo}
                          getHorarioActivo={getHorarioActivo}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
