import {
  Box,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import DialogMap from "../../../components/DialogMap";
import { useState } from "react";
import { Map } from "@mui/icons-material";

const ActividadLIstPersona = (props) => {
  const { eveActividad, eventoPersona } = props;
  const [openMap, setopenMap] = useState(false);
  return (
    <DialogContent dividers>
      {openMap ? (
        <DialogMap
          open={openMap}
          setopen={setopenMap}
          setitem={() => {}}
          item={eveActividad}
          editar={true}
          propiedad={eveActividad.coordenadas}
          nombrePropiedad={"coordenadas"}
          solover={true}
        />
      ) : null}
      <DialogContentText>
        <Box
          key={eveActividad.id}
          display="flex"
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Typography variant="body1" color="secondary" fontWeight={"bold"}>
            Nombre:{" "}
            <Typography variant="body1" color="secondary" component={"span"}>
              {eveActividad.nombre}
            </Typography>
          </Typography>
          <Box ml={1} mr={1}></Box>
          {eventoPersona.act_asigEveTipo === "RONDAS" ? (
            <Typography variant="body1" color="secondary" fontWeight={"bold"}>
              Coordenadas:{" "}
              <Typography variant="body1" color="secondary" component={"span"}>
                <IconButton aria-label="" onClick={() => setopenMap(true)}>
                  <Map />
                </IconButton>
              </Typography>
            </Typography>
          ) : null}
        </Box>
        <Box display="flex" justifyContent={"center"} width={"100%"}>
          <Typography variant="body1" color="primary" fontWeight={"bold"}>
            H. Inicio
          </Typography>
          <Box ml={1} mr={1}></Box>
          <Typography variant="body1" color="primary" fontWeight={"bold"}>
            H. Final
          </Typography>
        </Box>
        {eveActividad.horario
          .sort((a, b) => {
            const [horaA, minutosA] = a.horaFinal.split(":");
            const [horaB, minutosB] = b.horaFinal.split(":");

            const aTotal = Number(horaA) * 60 + Number(minutosA);
            const bTotal = Number(horaB) * 60 + Number(minutosB);

            return aTotal - bTotal;
          })
          .map((itemHorario, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent={"center"}
              width={"100%"}
            >
              <Typography
                variant="body1"
                sx={{
                  textDecoration: itemHorario.eliminado ? "line-through" : "",
                }}
                color={itemHorario.eliminado ? "error" : "secondary"}
              >
                {itemHorario.hora}
              </Typography>
              <Box ml={1} mr={1}></Box>
              <Typography
                variant="body1"
                sx={{
                  textDecoration: itemHorario.eliminado ? "line-through" : "",
                }}
                color={itemHorario.eliminado ? "error" : "secondary"}
              >
                {itemHorario.horaFinal}
              </Typography>
            </Box>
          ))}
      </DialogContentText>
    </DialogContent>
  );
};

export default ActividadLIstPersona;
