import React, { useReducer, memo } from "react";
import clienteAxios from "../../../config/axios";

import {
  OBTENER,
  CREAR,
  EDITAR,
  ELIMINAR,
  CHANGE_ACTIVO,
  CLEAR_ROWS,
  ADD_ROWS,
  ELIMINAR_SELECCION,
} from "../../../types/index";

//context
import DocumentosContext from "./DocumentosContext";
//reducer
import DocumentosReduer from "./DocumentosReduer";
const DocumentosState = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "docTipo",
        label: "Tipo",
        minWidth: 150,
        tipo: "string",
        campo1: "docTipo",
      },
      {
        id: "docCodigo",
        label: "Código",
        minWidth: 150,
        tipo: "string",
        campo1: "docCodigo",
      },
      {
        id: "docFecReg",
        label: "Fec. Reg",
        minWidth: 150,
        tipo: "string",
        campo1: "docFecReg",
      },
      {
        id: "docUser",
        label: "User",
        minWidth: 150,
        tipo: "numero                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ",
        campo1: "docUser",
      },
    ],
    ObjectoActivo: {
      docCodigo: "",
      docTipo: "",
      docDocumentos: [
        // {
        //   nombre: "Datos personales",
        //   propiedad: "datospersonales",
        //   obligatorio: "SI",
        //   extension: "pdf",
        //   fecExpira: "SI",
        // },
      ],
      docEmpresa: "",
      docUser: "",
      docFecReg: "",
      Todos: "",
    },
    pagination: null,
  };

  const [state, dispatch] = useReducer(DocumentosReduer, initialState);
  const getData = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    if (estado === "DOCUMENTOS") {
      const res = await clienteAxios.get(
        `/documentos/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}`
      );

      return res;
    } else {
      const res = await clienteAxios.get(
        `/evaluaciones/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
          estado ? estado : ""
        }`
      );

      return res;
    }
  };

  const obtener = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getData(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );
    dispatch({
      type: OBTENER,
      payload: {
        results: res.data.data.results,
        pagination: res.data.data.pagination,
      },
    });
  };
  const addRows = async (items) => {
    dispatch({
      type: ADD_ROWS,
      payload: items,
    });
  };
  const remplazarRows = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado
  ) => {
    const res = await getData(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado
    );

    dispatch({
      type: OBTENER,
      payload: {
        results: res.data.data.results,
        pagination: res.data.data.pagination,
      },
    });
  };
  const agregar = async (item, funcionAalerta) => {
    try {
      dispatch({
        type: CREAR,
        payload: { item, funcionAalerta },
      });
    } catch (error) {}
  };
  const editar = async (item, funcionAalerta) => {
    try {
      dispatch({
        type: EDITAR,
        payload: { item, funcionAalerta },
      });
    } catch (error) {}
  };

  const eliminar = async (_id, funcionAalerta) => {
    try {
      dispatch({
        type: ELIMINAR,
        payload: { _id, funcionAalerta },
      });
    } catch (error) {}
  };
  const eliminarSeleccion = async (_ids, funcionAalerta) => {
    dispatch({
      type: ELIMINAR_SELECCION,
      payload: { _ids, funcionAalerta },
    });
  };
  const change = (asignatura) => {
    dispatch({
      type: CHANGE_ACTIVO,
      payload: asignatura,
    });
  };
  const clear = () => {
    dispatch({
      type: CLEAR_ROWS,
    });
  };
  return (
    <DocumentosContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        ObjectoActivo: state.ObjectoActivo,
        pagination: state.pagination,
        // funciones
        obtener,
        agregar,
        editar,
        eliminar,
        change,
        clear,
        addRows,
        remplazarRows,
        getData,
        eliminarSeleccion,
      }}
    >
      {props.children}
    </DocumentosContext.Provider>
  );
};

export default memo(DocumentosState);
