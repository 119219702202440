import React, { useContext, useState } from "react";

import {
  Box,
  IconButton,
  List,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  Tooltip,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Cancel, Download } from "@mui/icons-material";
import { useButonStyles } from "../../../styles/styleButon";
import { saveAs } from "save-as";
import clienteAxios from "../../../../config/axios";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
export default function DialogOpciones(props) {
  const clases = useButonStyles();
  const { open, setopen, botones, titulo, row, usuario } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const handleClose = () => {
    setopen(false);
  };
  const [mes, setmes] = useState("");
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`Si el PDF no tiene datos, es debido a que la persona no ha trabajado en el mes seleccinado`}
        botones={[
          {
            tooltip: "Descargar",
            texto: "Descargar",
            funcion: () => {
              try {
                console.log({
                  usuario: usuario.usuario,
                  nombre: usuario.nombre,
                  rucempresa: usuario.rucempresa,
                  idPersona: String(row.perId),
                  fecha: mes,
                });

                // const json = {
                //   usuario: usuario.usuario,
                //   nombre: usuario.nombre,
                //   rucempresa: usuario.rucempresa,
                //   idPersona: String(row.perId),
                //   fecha: mes,
                // };

                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/registro_individual?usuario=${usuario.usuario}&nombre=${usuario.nombre}&rucempresa=${usuario.rucempresa}&idPersona=${row.perId}&fecha=${mes}`
                );
                setopenConfirmDialog(false);
              } catch (error) {
                console.log({ error });
                mostrarAlerta("No se encontro asistencia de este mes", "error");
                setopenConfirmDialog(false);
              }
            },
            disabled: false,
            Icon: Download,
            color: "success",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "Cancelar",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <DialogTitle sx={{ p: 0.5 }} id="alert-dialog-title">
              {titulo}
            </DialogTitle>
            <IconButton aria-label="" onClick={handleClose} color="secondary">
              <Cancel />
            </IconButton>
          </Box>

          <Box>
            <DialogContent
              sx={{
                py: 0.5,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <List component="nav">
                {botones.map((itemBoton) => {
                  const {
                    tooltip,
                    funcion,
                    Icon,
                    color,
                    id,
                    disabled,
                    ocultar,
                  } = itemBoton;
                  if (ocultar) {
                    return null;
                  }
                  return (
                    <Tooltip title={tooltip} key={id}>
                      <ListItemButton
                        sx={{ p: 0.5 }}
                        disabled={disabled}
                        onClick={() => {
                          funcion();
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar className={clases[color]}>
                            <Icon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText primary={tooltip} />
                      </ListItemButton>
                    </Tooltip>
                  );
                })}
                <TextField
                  sx={{ width: "100%", margin: "0 auto" }}
                  InputLabelProps={{ shrink: true }}
                  type="month"
                  label="Asistencia:"
                  value={mes}
                  onChange={(e) => setmes(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          disabled={!mes}
                          color="error"
                          onClick={async () => {
                            setopenConfirmDialog(true);
                          }}
                        >
                          <Download />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </List>
            </DialogContent>
            <DialogActions></DialogActions>
          </Box>
        </Box>
      </>
    </Dialog>
  );
}
