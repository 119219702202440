import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";

import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";

import { replaceCaracteres, trimPropsItem } from "../../../config/const";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  // MenuItem,
  FormControl,
  Button,
  Autocomplete,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { objectDefault } from "../data";
import { useModalStyle } from "../../styles/styleModal";
import Dropzone from "../../components/Dropzone";
import Prendas from "./tablas/Prenda/Prendas";
// import Vulnerabilidad from "./tablas/Vulnerabilidad/Vulnerabilidad";
import Actividades from "../../Configuración/Actividades/Actividades";
import Consignas from "../../Personas/Consignas/Consignas";
import Distribucion from "../../Distribucion/Distribucion";
import DatosOperativos from "../../DatosOperativos/DatosOperativos";
import EventosActividades from "../../EventosActividades/EventosActividades";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import ActividadesCabecera from "../../ActividadesCabecera/ActividadesCabecera";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  //hook de estilos
  const clases = useModalStyle();

  const [modalStyle] = useState(getModalStyle);

  const [seccionActive, setseccionActive] = useState({
    seccionPrincipal: true,
    seccionPrenda: false,
    seccionVulnerabilidad: false,
    seccionPuesto: false,
    seccionDatosOperativos: false,
    seccionActividades: false,
    seccionNewActividades: false,
    seccionConsigna: false,
  });
  const {
    seccionPrincipal,
    seccionPrenda,
    seccionVulnerabilidad,
    seccionPuesto,
    seccionDatosOperativos,
    seccionActividades,
    seccionNewActividades,
    seccionConsigna,
  } = seccionActive;
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();

  const [error, seterror] = useState({});
  // const [puestosData, setpuestosData] = useState([]);
  const {
    // iopId: 1,
    iopCliId,
    iopCliDocNumero,
    iopCliRazonSocial,
    iopAsunto,
    // iopDocumento,
    iopFotos,
    iopEstado,
    // iopPrendas,
    iopVulnerabilidades,
    // iopEmpresa,
    // iopUser,
    // iopFecReg,
    // iopFecUpd,
  } = item;

  useEffect(() => {
    // if (open && ObjectoActivo.iopCliDocNumero !== "") {
    //   const getresguardiasData = async () => {
    //     try {
    //       // const itemF = await clienteAxios.get(
    //       //   `/clientes/filtro/0?search=${ObjectoActivo.iopCliDocNumero}&estado=CLIENTES`
    //       // );
    //       const res = await clienteAxios.get(
    //         `/personas/listado/:codigo?docnumero=${ObjectoActivo.iopCliDocNumero}`
    //       );
    //       setguardiasData(setitemsPersonas(res.data.data));
    //     } catch (error) {
    //       setguardiasData([]);
    //     }
    //   };

    //   getresguardiasData();
    // }

    setitem(ObjectoActivo);
  }, [ObjectoActivo, open]);

  const defaultActive = async (args) => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setimagenes([]);
    setmodificoImagenes(false);
    setseccionActive({
      seccionPrincipal: true,
      seccionPrenda: false,
      seccionVulnerabilidad: false,
      seccionPuesto: false,
      seccionDatosOperativos: false,
      seccionActividades: false,
      seccionConsigna: false,
    });
    if (iopVulnerabilidades.length > 0 && args?.eliminarEveAct && !editar) {
      try {
        socket.current.emit("client:eliminarDataSeleccionado", {
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "eventoactividad",
          lista: iopVulnerabilidades,
        });
      } catch (error) {}
    }
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };
  const validacion = () => {
    if (iopCliDocNumero.trim() === "" || iopAsunto.trim() === "") {
      seterror({
        iopCliDocNumero: iopCliDocNumero.trim() === "",
        iopAsunto: iopAsunto.trim() === "",
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, iopFotos);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          iopFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          iopUser: usuario.usuario,
          iopEmpresa: usuario.rucempresa,
          tabla: "iop",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, iopFotos);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          iopFotos: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          iopUser: usuario.usuario,
          iopEmpresa: usuario.rucempresa,
          tabla: "iop",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} IOP.
                </Typography>
                {editar && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={iopEstado === "ACTIVA" ? true : false}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            iopEstado: e.target.checked ? "ACTIVA" : "INACTIVA",
                          });
                        }}
                        name="antoine"
                      />
                    }
                    label="Estado"
                  />
                )}
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive({ eliminarEveAct: true });
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  border={"1px solid #e1e1e1"}
                >
                  <Button
                    variant={
                      seccionPrincipal
                        ? "contained"
                        : false
                        ? "outlined"
                        : "null"
                    }
                    color={false ? "error" : "secondary"}
                    disableElevation
                    sx={{ padding: "6px", flexGrow: "1" }}
                    size="small"
                    onClick={() => setseccionActive({ seccionPrincipal: true })}
                  >
                    Principal
                  </Button>
                  <Button
                    variant={
                      seccionPrenda ? "contained" : false ? "outlined" : "null"
                    }
                    color={false ? "error" : "secondary"}
                    disableElevation
                    sx={{ padding: "6px", flexGrow: "1" }}
                    size="small"
                    onClick={() => setseccionActive({ seccionPrenda: true })}
                  >
                    Prenda ({item.iopPrendas.length})
                  </Button>
                  <Button
                    variant={
                      seccionVulnerabilidad
                        ? "contained"
                        : false
                        ? "outlined"
                        : "null"
                    }
                    color={false ? "error" : "secondary"}
                    disableElevation
                    sx={{ padding: "6px", flexGrow: "1" }}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionVulnerabilidad: true })
                    }
                  >
                    Vulnerabilidad
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  border={"1px solid #e1e1e1"}
                >
                  {iopCliDocNumero !== "" && (
                    <>
                      <Button
                        variant={
                          seccionPuesto
                            ? "contained"
                            : false
                            ? "outlined"
                            : "null"
                        }
                        color={false ? "error" : "primary"}
                        disableElevation
                        sx={{ padding: "6px", flexGrow: "1" }}
                        size="small"
                        onClick={() =>
                          setseccionActive({ seccionPuesto: true })
                        }
                      >
                        Distribución
                      </Button>
                      <Button
                        variant={
                          seccionDatosOperativos
                            ? "contained"
                            : false
                            ? "outlined"
                            : "null"
                        }
                        color={false ? "error" : "primary"}
                        disableElevation
                        sx={{ padding: "6px", flexGrow: "1" }}
                        size="small"
                        onClick={() =>
                          setseccionActive({ seccionDatosOperativos: true })
                        }
                      >
                        Datos Operativos
                      </Button>
                      <Button
                        variant={
                          seccionActividades
                            ? "contained"
                            : false
                            ? "outlined"
                            : "null"
                        }
                        color={false ? "error" : "primary"}
                        disableElevation
                        sx={{ padding: "6px", flexGrow: "1" }}
                        size="small"
                        onClick={() =>
                          setseccionActive({ seccionActividades: true })
                        }
                      >
                        Actividades
                      </Button>{" "}
                      <Button
                        variant={
                          seccionNewActividades
                            ? "contained"
                            : false
                            ? "outlined"
                            : "null"
                        }
                        color={false ? "error" : "primary"}
                        disableElevation
                        sx={{ padding: "6px", flexGrow: "1" }}
                        size="small"
                        onClick={() =>
                          setseccionActive({ seccionNewActividades: true })
                        }
                      >
                        New Actividades
                      </Button>
                      <Button
                        variant={
                          seccionConsigna
                            ? "contained"
                            : false
                            ? "outlined"
                            : "null"
                        }
                        color={false ? "error" : "primary"}
                        disableElevation
                        sx={{ padding: "6px", flexGrow: "1" }}
                        size="small"
                        onClick={() =>
                          setseccionActive({ seccionConsigna: true })
                        }
                      >
                        Consigna
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionPrincipal && (
                  <>
                    <FormControl
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Autocomplete
                        disabled={editar}
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={(
                          iopCliDocNumero +
                          " " +
                          iopCliRazonSocial
                        ).trim()}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const [
                                iopCliRazonSocial,
                                iopCliDocNumero,
                                iopCliId,
                              ] = newValue.split("/+/");
                              const resIdsIop = await clienteAxios.post(
                                "/eventoactividades/",
                                {
                                  perId: iopCliId, // id cliente
                                  idEliminar: iopVulnerabilidades, // tomar data de la propiedad iopVulnerabilidades
                                }
                              );
                              setitem({
                                ...item,
                                iopCliRazonSocial,
                                iopCliDocNumero,
                                iopCliId,
                                iopVulnerabilidades: resIdsIop.data.data,
                              });
                            } catch (error) {
                              setitem({
                                ...item,
                                iopCliRazonSocial: "",
                                iopCliDocNumero: "",
                                iopCliId: "",
                                iopVulnerabilidades: [],
                              });
                              mostrarAlerta(
                                "Hubo un error al seleccionar el cliente",
                                "error"
                              );
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            error={error.iopCliDocNumero}
                            label="Cliente"
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {
                                setitem({
                                  ...item,
                                });
                                setclientesData([]);

                                return mostrarAlerta(
                                  "No hay clientes",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Asunto"
                      value={iopAsunto}
                      name="iopAsunto"
                      error={error.iopAsunto}
                      onChange={(e) => handleChange(e)}
                    />

                    <Dropzone
                      texto="Arrastra la imagen de portada"
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={iopFotos}
                    />
                  </>
                )}
                {seccionPrenda && <Prendas item={item} setitem={setitem} />}
                {seccionVulnerabilidad && (
                  <EventosActividades
                    fromIOP={true}
                    socketProp={socket}
                    clienteIOP={{
                      cliId: iopCliId,
                      cliDocNumero: iopCliDocNumero,
                      cliRazonSocial: iopCliRazonSocial,
                    }}
                    itemIOP={item}
                    setitemIOP={setitem}
                  />
                )}
                {seccionPuesto && (
                  <Distribucion
                    fromIOP={true}
                    socketProp={socket}
                    clienteIOP={{
                      cliId: iopCliId,
                      cliDocNumero: iopCliDocNumero,
                      cliRazonSocial: iopCliRazonSocial,
                    }}
                  />
                )}
                {seccionDatosOperativos && (
                  <DatosOperativos
                    fromIOP={true}
                    socketProp={socket}
                    clienteIOP={{
                      cliId: iopCliId,
                      cliDocNumero: iopCliDocNumero,
                      cliRazonSocial: iopCliRazonSocial,
                    }}
                  />
                )}
                {seccionActividades && (
                  <Actividades
                    fromIOP={true}
                    socketProp={socket}
                    clienteIOP={{
                      cliId: iopCliId,
                      cliDocNumero: iopCliDocNumero,
                      cliRazonSocial: iopCliRazonSocial,
                    }}
                  />
                )}
                {seccionNewActividades && (
                  <ActividadesCabecera
                    fromIOP={true}
                    socketProp={socket}
                    clienteIOP={{
                      cliId: iopCliId,
                      cliDocNumero: iopCliDocNumero,
                      cliRazonSocial: iopCliRazonSocial,
                    }}
                  />
                )}
                {seccionConsigna && (
                  <Consignas
                    fromIOP={true}
                    socketProp={socket}
                    clienteIOP={{
                      cliId: iopCliId,
                      cliDocNumero: iopCliDocNumero,
                      cliRazonSocial: iopCliRazonSocial,
                    }}
                  />
                )}
              </Box>

              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={cargando}
                  type="submit"
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
