import React, { useContext, useState, useEffect, memo } from "react";
import { withRouter } from "react-router-dom";
import Row from "./Row";
import Cargando from "../../../components/Extra/Cargando";
import RowAgregar from "./RowAgregar";

import TablaCabecera from "./TablaCabecera";
import ToolBarTable from "./ToolBarTable";
import AuthContext from "../../../Context/Auth/AuthContext";
import EncuestasEvaluacionesContext from "../../../Context/EncuestasEvaluaciones/EncuestasEvaluacionesContext";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import Modal from "./Modal";
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 800,
  },
});
const TablaAsignaturas = (props) => {
  const {
    agregar,
    setagregar,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    rows,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador,
    perfil,
    subEstado,
  } = props;
  const [openModal, setopenModal] = useState(false);
  const [objetoActivoModal, setobjetoActivoModal] = useState({
    // encId: 4354,
    // encPerId: 1469,
    // encDocId: 26,
    encOption: "",
    encEstado: "",
    encFecReg: "",
    docTipo: "",
    docDestinatario: "",
    docTitulo: "",
    docFechaEmision: "",
    docFechaFinalizacion: "",
    docObligatorio: "",
    docEstado: "",
    docPreguntas: [],
    docEmpresa: "",
    docUser: "",
  });
  const { columns, obtener, pagination, addRows, remplazarRows, getData } =
    useContext(EncuestasEvaluacionesContext);
  const {
    usuario: { rucempresa },
    socket,
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  //columnas y rows de la tabla

  // eslint-disable-next-line
  const clases = useStyles();
  // eslint-disable-next-line

  // eslint-disable-next-line
  // eslint-disable-next-line
  // eslint-disable-next-line

  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("encId");
  const [tabla] = useState("registro");
  //clases
  const classes = useStyles();

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getData(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        perfil,
        "padre",
        "",
        subEstado
      );

      const data = res.data.data.results;
      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row.encId === item.encId;
        });

        return !existe;
      });

      addRows(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (page === 0) {
      if (event.target.value > rows.length) {
        obtener(
          event.target.value,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          perfil,
          "padre",
          "",
          subEstado
        );
      }
    }
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      perfil,
      "padre",
      "",
      subEstado
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  const funcionReload = async () => {
    try {
      setcargando(true);
      setRowsPerPage(10);
      setPage(0);
      await obtener(
        10,
        0,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        perfil,
        "padre",
        "",
        subEstado
      );
      setcargando(false);
    } catch (error) {}
  };

  useEffect(() => {
    //eslint-disable-next-line

    if (!rows) {
      obtener(
        10,
        0,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        perfil,
        "padre",
        "",
        subEstado
      );
    }

    // eslint-disable-next-line
  }, []);
  const [itemSocket, setitemSocket] = useState(null);
  const [itemNuevo, setitemNuevo] = useState(null);
  useEffect(() => {
    socket.on("server:guardadoExitoso", (data) => {
      if (data.tabla === tabla) {
        setitemNuevo({
          tipo: "agregar",
          item: data,
          validarExistePadre: true,
        });
      }
    });
    socket.on("server:actualizadoExitoso", (data) => {
      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "editar",
          item: data,
        });
      }
    });
    socket.on("server:eliminadoExitoso", (data) => {
      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "eliminar",
          item: data,
        });
      }
    });
    socket.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla === tabla) {
        setitemSocket({
          tipo: "eliminarSeleccion",
          item: data,
        });
      }
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (itemNuevo) {
      const { item, validarExistePadre } = itemNuevo;

      if (validarExistePadre) {
        if (!rows) {
          return;
        }
        if (usuario.rucempresa !== item.rucempresa) {
          return;
        }
        const funcionAalerta = () => {
          if (item.msg === "") {
            return null;
          }
          if (!item.msg) {
            return null;
          }
          mostrarAlerta(item.msg, item.alert ? item.alert : "success");
        };

        const existePadre = rows.some(
          (row) => row.encFecReg.subsrt(0, 7) === item.encFecReg.subsrt(0, 7)
        );

        if (existePadre) {
          setitemSocket({
            tipo: itemNuevo.tipo,
            item: itemNuevo.item,
          });
        } else {
          agregar(item, funcionAalerta);
        }
      }
    }
    // eslint-disable-next-line
  }, [itemNuevo]);
  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Paper className={classes.root}>
      <Modal
        open={openModal}
        setopen={setopenModal}
        objetoActivoModal={objetoActivoModal}
        setobjetoActivoModal={setobjetoActivoModal}
        perfil={perfil}
      />
      <ToolBarTable
        search={search}
        titulo={"Asistencia"}
        arrayExport={arrayExport}
        columns={columns}
        expandible={false}
        campoExport={campoExport}
        tabla={tabla}
        funcionReload={funcionReload}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        fullBuscador={fullBuscador}
        estadoRequest={perfil}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
            
          <TableHead>
            <TablaCabecera
              remplazarRows={remplazarRows}
              search={search}
              input={input}
              setinput={setinput}
              orden={orden}
              setorden={setorden}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setcargando={setcargando}
              rows={rows}
              // setarrayExport={setarrayExport}
              columns={columns}
              habilitarOrdenar={true}
              funcionOrdenar={funcionOrdenar}
              campoExport={campoExport}
            />
          </TableHead>
          <TableBody>
            {agregar ? <RowAgregar setagregar={setagregar} /> : null}
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columns.length + 2}
              >
                <Cargando titulo="Cargando cargando" />
              </TableCell>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      tabla={tabla}
                      arrayExport={arrayExport}
                      setarrayExport={setarrayExport}
                      row={row}
                      index={index}
                      campoExport={campoExport}
                      key={row.encId}
                      itemSocket={itemSocket}
                      setitemSocket={setitemSocket}
                      perfil={perfil}
                      subEstado={subEstado}
                      setopenModal={setopenModal}
                      setobjetoActivoModal={setobjetoActivoModal}
                    />
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(TablaAsignaturas));
