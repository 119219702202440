import Chart from "./Chart";
import React, { useContext, useEffect, useState } from "react";
import Caja from "./Caja";
import Dona from "./Dona";
import { Box, Grid, Hidden } from "@mui/material";
import AuthContext from "../../Context/Auth/AuthContext";
import MenuContext from "../../Context/Menu/MenuContext";
import { makeStyles } from "@mui/styles";
import clienteAxios from "../../config/axios";
import GridCard from "../ArchivosCards/GridCards/Grid";
import { Button, Divider } from "@mui/material";
import Cargando from "../components/Cargando";
import NotificacionDialog from "./NotificacionDialog";
// import { secondary } from "../../config/const";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const Dashboard = () => {
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  const [cards, setcards] = useState(null);
  const { rows } = useContext(MenuContext);
  const [graficas_estadisticas, setgraficas_estadisticas] = useState(null);
  const [seccionActive, setseccionActive] = useState({
    info: true,
    cardsSeccion: false,
  });
  const { info, cardsSeccion } = seccionActive;
  const classes = useStyles();
  useEffect(() => {
    if (!cards) {
      const subsubC = rows.filter((item) => {
        return (
          item.sidInfo.tipo === "subsubCategoria" &&
          item.sidInfo.mostrar === "SI"
        );
      });

      const subC = rows.filter((item) => {
        return (
          item.sidInfo.tipo === "subCategoria" && item.sidInfo.mostrar === "SI"
        );
      });
      const Cat = rows.filter(
        (item) =>
          item.sidInfo.tipo === "categoria" && item.sidInfo.mostrar === "SI"
      );

      setcards([...subsubC, ...subC, ...Cat]);
    }
    const getgraficas_estadisticas = async () => {
      const res = await clienteAxios.get("/graficas_estadisticas");
      setgraficas_estadisticas(res.data);
    };
    if (!graficas_estadisticas) {
      getgraficas_estadisticas();
    }
    // eslint-disable-next-line
  }, []);
  const [openNotificacion, setopenNotificacion] = useState(false);
  const [notificacionesData, setnotificacionesData] = useState({
    notificacion1: [],
    notificacion2: [],
    estado: false,
  });
  useEffect(() => {
    const getData = async () => {
      const resNotificaciones = await clienteAxios.get(
        "/notificaciones/listado"
      );
      if (
        resNotificaciones.data.data.notificacion1 ||
        resNotificaciones.data.data.notificacion2
      ) {
        if (
          resNotificaciones.data.data.notificacion1.filter(
            (noti) => noti.notVisto === "NO"
          ).length > 0 ||
          resNotificaciones.data.data.notificacion2.filter(
            (noti) => noti.notVisto === "NO"
          ).length > 0
        ) {
          setopenNotificacion(true);
        }
        setnotificacionesData({ estado: true, ...resNotificaciones.data.data });
      }
    };
    if (!notificacionesData.estado) {
      getData();
    }
    // eslint-disable-next-line
  }, []);
  if (!cards) return null;
  if (!graficas_estadisticas) return <Cargando titulo="Cargando Data..." />;
  return (
    <div>
      <NotificacionDialog
        open={openNotificacion}
        setOpen={setopenNotificacion}
        notificacionesData={notificacionesData}
      />
      <Grid container>
        <Grid item xs={12} className={classes.paper}>
          <Hidden smDown>
            <Box display={"flex"} justifyContent="center" width={"100%"}>
              <img
                src={usuario.logo}
                alt="Logo empresa"
                style={{
                  maxWidth: "8%",
                }}
              />
            </Box>

            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              width="100%"
            ></Box>
          </Hidden>
          <Hidden mdUp>
            <Box display={"flex"} justifyContent="center" width={"100%"}>
              <img
                src={usuario.logo}
                alt="Logo empresa"
                style={{
                  maxWidth: "30%",
                  padding: "1rem",
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                width="100%"
              ></Box>
            </Box>{" "}
          </Hidden>
          <Box display="flex" alignItems=" stretch" width="100%">
            <Box>
              <Button
                variant={info ? "contained" : false ? "outlined" : null}
                disableElevation
                style={{
                  padding: "8px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                color={false ? "error" : "secondary"}
                onClick={() => setseccionActive({ info: true })}
              >
                Datos Generales
              </Button>
              <Button
                variant={cardsSeccion ? "contained" : false ? "outlined" : null}
                disableElevation
                style={{
                  padding: "8px",
                  borderRadius: "20px 20px 0px 0px",
                }}
                color={false ? "error" : "secondary"}
                onClick={() => setseccionActive({ cardsSeccion: true })}
              >
                Categorias
              </Button>
            </Box>
          </Box>
          <Divider />
          {cardsSeccion ? <GridCard /> : null}
        </Grid>
        {info && (
          <>
            <Grid item xs={12} sm={12} md={6} className={classes.paper}>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                flexWrap="wrap"
              >
                {rows
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "subCategoria" ||
                      route.sidInfo.tipo === "subsubCategoria"
                  )
                  .map((rutasubsubosub, index, arraySSCOSC) => {
                    if (
                      rutasubsubosub.sidInfo.tipo === "subsubCategoria" &&
                      rutasubsubosub.sidInfo.mostrar === "SI"
                    ) {
                      const subC = arraySSCOSC.find(
                        (itemarraySSCOSC) =>
                          itemarraySSCOSC.sidInfo.path ===
                            rutasubsubosub.sidInfo.subcategoria &&
                          itemarraySSCOSC.sidInfo.tipo === "subCategoria"
                      );
                      if (!subC) {
                        return null;
                      }

                      return (
                        <Caja
                          key={index}
                          titulo={subC.sidInfo.name}
                          descripcion={rutasubsubosub.sidInfo.descripcion}
                          enlace={
                            subC.sidInfo.layout +
                            subC.sidInfo.categoria +
                            subC.sidInfo.path +
                            rutasubsubosub.sidInfo.path
                          }
                        />
                      );
                    }
                    return null;
                  })}
                {cards
                  .filter((route) => route.sidInfo.tipo === "subCategoria")
                  .map((card, index) => {
                    return (
                      <Caja
                        key={index}
                        titulo={card.sidInfo.name}
                        descripcion={card.sidInfo.descripcion}
                        enlace={
                          card.sidInfo.layout +
                          card.sidInfo.categoria +
                          card.sidInfo.path
                        }
                      />
                    );
                  })}
                {cards
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "categoria" || !route.sidInfo.tipo
                  )
                  .map((card, index) => {
                    return (
                      <Caja
                        key={index}
                        titulo={card.sidInfo.name}
                        descripcion={card.sidInfo.descripcion}
                        enlace={card.sidInfo.layout + card.sidInfo.path}
                      />
                    );
                  })}
              </Box>
            </Grid>{" "}
            {/* {JSON.stringify({ graficas_estadisticas })} */}
            <Grid item xs={12} sm={12} md={6}>
              <Dona
                titulo={"Personal"}
                className={classes.paper}
                dataGrafico={graficas_estadisticas.totalPersonas}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Chart
                titulo={"Multas"}
                className={classes.paper}
                dataGrafico={graficas_estadisticas.totalmultas}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Chart
                titulo={"Turnos Extra"}
                className={classes.paper}
                dataGrafico={graficas_estadisticas.totalturnoextras}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default Dashboard;
