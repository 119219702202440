import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import { quitarTildes } from "../../../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregarItem = (props) => {
  // eslint-disable-next-line

  const { item, setitem, setagregarItemLista } = props;
  const { usuario, socket } = useContext(AuthContext);
  const [itemNuevo, setItemNuevo] = useState({
    nombre: "",
    propiedad: "",
    obligatorio: "NO",
    extension: "PDF",
    fecExpira: "SI",
  });

  const classes = useRowStyles();
   

  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (itemNuevo.nombre === "") {
      return true;
    } else {
      return false;
    }
  };

   

  const handleAgregar = async () => {
    try {
      const docDocumentos = [
        {
          ...itemNuevo,
          propiedad: quitarTildes(itemNuevo.propiedad),
          
        },
        ...item.docDocumentos,
      ];

      socket.emit("client:actualizarData", {
        ...item,
        tabla: "documento",
        docDocumentos,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        docUser: usuario.usuario,
        docEmpresa: usuario.rucempresa,
      });
      setitem({
        ...item,
        docDocumentos,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregarItemLista(false);
                setItemNuevo({
                  nombre: "",
                  propiedad: "",
                  obligatorio: "NO",
                  extension: "PDF",
                  fecExpira: "SI",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();
                setagregarItemLista(false);
                setItemNuevo({
                  nombre: "",
                  propiedad: "",
                  obligatorio: "NO",
                  extension: "PDF",
                  fecExpira: "SI",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}{" "}
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            autoFocus
            id="nombre"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.nombre}
            name="nombre"
            onChange={(e) =>
              setItemNuevo({
                ...itemNuevo,
                [e.target.name]: e.target.value,
                propiedad: e.target.value
                  .replace(/\s+/g, "")
                  .toLocaleLowerCase(),
              })
            }
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="obligatorio"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.obligatorio}
            name="obligatorio"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["SI", "NO"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="extension"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.extension}
            name="extension"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["IMAGEN", "PDF"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="fecExpira"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.fecExpira}
            name="fecExpira"
            onChange={(e) => handleChange(e)}
            helperText="Agregando"
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["SI", "NO"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
