import React, { useReducer, memo } from "react";

import {
  OBTENER,
  CREAR,
  EDITAR,
  ELIMINAR,
  CHANGE_ACTIVO,
  CLEAR_ROWS,
  ADD_ROWS,
  IMPORTAR_ROWS,
  ELIMINAR_SELECCION,
  CHANGE_GUARDADO,
} from "../../types/index";
import clienteAxios from "../../config/axios";
//context
import CambioPuestoContext from "./CambioPuestoContext";
//reducer
import CambioPuestoReducer from "./CambioPuestoReducer";
const InformesState = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "camFecReg",
        label: "Fecha",
        minWidth: 50,
        tipo: "string",
        profundidad: 1,
        campo1: "camFecReg",
        campo2: "",
        campo3: "",
      },
      {
        id: "tregistros",
        label: "T. Registros",
        minWidth: 50,
        tipo: "string",
        profundidad: 1,
        campo1: "tregistros",
        campo2: "",
        campo3: "",
      },
    ],
    ObjectoActivo: {
      camStatus: "",
      camEstado: "EN PROCESO",
      //   camId: 1,
      camTipo: "CAMBIO PUESTO", // interno
      camIdPersona: "",
      camDocuPersona: "",
      camNomPersona: "",
      camFecha: "",
      camMotivo: "",
      camActualPuesto: "",
      camIdCliente: "",
      camDocuCliente: "",
      camNomCliente: "",
      camNuevoPuesto: [
        {
          ubicacion: "",
          puesto: "",
          supervisor: "",
          guardias: "",
          horasservicio: "",
          tipoinstalacion: "",
          vulnerabilidades: "",
          consignas: [],
        },
      ],
      camNuevoTurno: "",
      camUser: "",
      camEmpresa: "",
      camFecReg: "",
      Todos: "",
    },

    pagination: null,
  };

  const [state, dispatch] = useReducer(CambioPuestoReducer, initialState);
  const getData = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    grupo,
    fecha,
    idRegistro,
    notificacion
  ) => {
    const res = await clienteAxios.get(
      `/cambiopuesto/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
        estado ? estado : ""
      }&idRegistro=${idRegistro ? idRegistro : ""}&notificacion=${
        notificacion ? notificacion : ""
      }&grupo=${grupo ? grupo : ""}&fecha=${fecha ? fecha.substr(0, 7) : ""}`
    );

    return res;
  };

  const obtener = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    grupo,
    fecha,
    idRegistro,
    notificacion
  ) => {
    const res = await getData(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      grupo,
      fecha,
      idRegistro,
      notificacion
    );
    dispatch({
      type: OBTENER,
      payload: {
        results: res.data.data.results,
        pagination: res.data.data.pagination,
      },
    });
  };
  const addRows = async (items) => {
    dispatch({
      type: ADD_ROWS,
      payload: items,
    });
  };
  const remplazarRows = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    grupo,
    fecha,
    idRegistro,
    notificacion
  ) => {
    const res = await getData(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      grupo,
      fecha,
      idRegistro,
      notificacion
    );
    dispatch({
      type: OBTENER,
      payload: {
        results: res.data.data.results,
        pagination: res.data.data.pagination,
      },
    });
  };
  const agregar = async (item, funcionAalerta) => {
    try {
      dispatch({
        type: CREAR,
        payload: { item, funcionAalerta },
      });
    } catch (error) {}
  };

  const editar = async (item, funcionAalerta) => {
    try {
      dispatch({
        type: EDITAR,
        payload: { item, funcionAalerta },
      });
    } catch (error) {}
  };

  const eliminar = async (_id, funcionAalerta) => {
    try {
      dispatch({
        type: ELIMINAR,
        payload: { _id, funcionAalerta },
      });
    } catch (error) {}
  };
  const eliminarSeleccion = async (_ids, funcionAalerta) => {
    dispatch({
      type: ELIMINAR_SELECCION,
      payload: { _ids, funcionAalerta },
    });
  };

  const change = (item) => {
    dispatch({
      type: CHANGE_ACTIVO,
      payload: item,
    });
  };
  const changeGuardado = (item) => {
    dispatch({
      type: CHANGE_GUARDADO,
      payload: item,
    });
  };
  const clear = () => {
    dispatch({
      type: CLEAR_ROWS,
    });
  };
  const rowsImport = (rows) => {
    dispatch({
      type: IMPORTAR_ROWS,
      payload: rows,
    });
  };
  return (
    <CambioPuestoContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        ObjectoActivo: state.ObjectoActivo,
        pagination: state.pagination,
        ObjetoGuardado: state.ObjetoGuardado,
        // funciones
        obtener,
        agregar,
        editar,
        eliminar,
        change,
        clear,
        addRows,
        remplazarRows,
        getData,
        rowsImport,
        eliminarSeleccion,
        changeGuardado,
      }}
    >
      {props.children}
    </CambioPuestoContext.Provider>
  );
};

export default memo(InformesState);
