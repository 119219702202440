import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react"; 
import { useSimpleTable } from "../../../../hooks/useSimpleTable"; 
import TablaCabecera from "../../../../components/TablaCabecera";
import { useRowStyles } from "../../../../styles/stylesRows";
import ToolBarTable from "../../../../components/ToolBarTable";

const Vestimentas = (props) => {
  const { trabajoActivo } = props;
  const classes = useRowStyles();
  const {
    pageVestimenta,
    // setPageVestimenta,
    rowsPerPageVestimenta,
    // setRowsPerPageVestimenta,
    handleChangePageVestimenta,
    handleChangeRowsPerPageVestimenta,
  } = useSimpleTable("Vestimenta");
  return (
    <Paper>
      <ToolBarTable
        count={trabajoActivo.vestimentas.length}
        rowsPerPage={rowsPerPageVestimenta}
        page={pageVestimenta}
        handleChangePage={handleChangePageVestimenta}
        handleChangeRowsPerPage={handleChangeRowsPerPageVestimenta}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[]}
        botonesDerechos={[]}
      />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TablaCabecera
              ocultarPrimeraColumna={true}
              columns={[
                {
                  id: "existe",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "existe",
                },
                {
                  id: "id",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "id",
                },
                {
                  id: "nombre",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "nombre",
                },
                {
                  id: "serie",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "serie",
                },
                {
                  id: "tipo",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "tipo",
                },
              ]}
              habilitarOrdenar={false}
            />
          </TableHead>

          <TableBody component={"div"}>
            {trabajoActivo.vestimentas.map((vestimenta, index) => {
              const claseCelda = `${
                index % 2 === 0 ? classes.celda : classes.celdaSecundario
              } `;
              return (
                <TableRow>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {vestimenta.existe ? "SI" : "NO"}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {vestimenta.id}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {vestimenta.nombre}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {vestimenta.serie}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {vestimenta.tipo}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Vestimentas;
