import React, { useState, useContext, useEffect, memo, createRef } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
  Tooltip,
  InputAdornment,
  Autocomplete,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Cancel, CloudUpload, Download } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useModalStyle } from "../../styles/styleModal";
import { objectDefault } from "../data";
import CampoDoc from "../../components/CampoDoc";
function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAnticipos = (props) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  //props
  const {
    estadoRequest,
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    desdeDis,
  } = props;
  const [periodosAfectacion, setperiodosAfectacion] = useState([]);
  function getMonths() {
    let currentDate = new Date();
    let months = [
      currentDate.getFullYear() +
        "-" +
        (currentDate.getMonth() + 1).toString().padStart(2, "0"),
    ];
    for (let i = 0; i < 4; i++) {
      currentDate.setMonth(currentDate.getMonth() + 1);
      months.push(
        currentDate.getFullYear() +
          "-" +
          (currentDate.getMonth() + 1).toString().padStart(2, "0")
      );
    }
    return months;
  }
  function obtenerFechasDescuentos() {
    let fechas = [];
    let fechaActual = new Date();
    fechaActual.setMonth(fechaActual.getMonth());
    for (let i = 0; i < 9; i++) {
      let fecha = new Date(
        fechaActual.getFullYear(),
        fechaActual.getMonth() + i,
        1
      );
      let mes = fecha.getMonth() + 1;
      mes = mes < 10 ? "0" + mes : mes;
      fechas.push(fecha.getFullYear() + "-" + mes);
    }
    return fechas;
  }

  // eslint-disable-next-line

  const [clientesData, setclientesData] = useState([]);
  const [fechasData, setfechasData] = useState([]);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line
  const {
    // vaca_idPersona,
    perDocNumero,
    perNombres,
    vaca_valor,
    vaca_formaPago,
    vaca_periodo,
    vaca_detalle,
    vaca_fechaDesde,
    vaca_comprobante,
    vaca_estado,
    antNombreBono,
    perApellidos,
    vaca_fechaHasta,
    vaca_diaVacacion,
    vaca_cantidadDiasVacaciones,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    perDocNumero: false,
    perNombres: false,
    vaca_valor: false,
    vaca_formaPago: false,
    vaca_periodo: false,
    vaca_detalle: false,
    vaca_fechaDesde: false,
    vaca_comprobante: false,
  });

  useEffect(() => {
    setitem({
      ...ObjectoActivo,
    });

    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);

    setopen(false);
    seteditar(false);
    seterror({
      perDocNumero: false,
      perNombres: false,
      vaca_valor: false,
      vaca_formaPago: false,
      vaca_periodo: false,
      vaca_detalle: false,
      vaca_fechaDesde: false,
      vaca_comprobante: false,
    });
    // setseccionActive({
    //   datosPersonalesSeccion: true,
    //   datosContablesSeccion: false,
    // });

    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);

  const [
    ocultar,
    // setocultar
  ] = useState(false);
  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };

  // eslint-disable-next-line
  const handleCrear = async () => {
    if (
      perDocNumero.trim() === "" ||
      perNombres.trim() === "" ||
      vaca_valor === "" ||
      // vaca_formaPago.trim() === "" ||
      vaca_periodo.trim() === ""
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      return seterror({
        perDocNumero: perDocNumero.trim() === "" ? true : false,
        perNombres: perNombres.trim() === "" ? true : false,
        vaca_valor: vaca_valor === "" ? true : false,
        // vaca_formaPago: vaca_formaPago.trim() === "" ? true : false,
        vaca_periodo: vaca_periodo.trim() === "" ? true : false,
      });
    }

    try {
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "vacacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          vaca_user: usuario.usuario,
          vaca_empresa: usuario.rucempresa,
          // antTipo: estadoRequest,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (
      perDocNumero.trim() === "" ||
      perNombres.trim() === "" ||
      vaca_valor === "" ||
      // vaca_formaPago.trim() === "" ||
      vaca_periodo.trim() === ""
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      return seterror({
        perDocNumero: perDocNumero.trim() === "" ? true : false,
        perNombres: perNombres.trim() === "" ? true : false,
        vaca_valor: vaca_valor === "" ? true : false,
        // vaca_formaPago: vaca_formaPago.trim() === "" ? true : false,
        vaca_periodo: vaca_periodo.trim() === "" ? true : false,
      });
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "vacacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          vaca_user: usuario.usuario,
          vaca_empresa: usuario.rucempresa,
          // antTipo: estadoRequest,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  function generateDateArray(startDateStr) {
    const startDate = new Date(startDateStr);
    const dateArray = [];
    const today = new Date();

    // Comenzar desde 365 días después de la fecha del parámetro
    startDate.setDate(startDate.getDate() + 365);

    while (startDate <= today) {
      const year = startDate.getFullYear();
      const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
      const day = startDate
        .getDate()
        .toString()
        .padStart(2, "0");
      dateArray.push(`${year}-${month}-${day}`);
      startDate.setDate(startDate.getDate() + 365);
    }

    return dateArray.filter((date) => {
      const [year, month, day] = date.split("-");
      return new Date(`${year}-${month}-${day}`) <= today;
    });
  }
  // Exclude months that are greater than the current month return monthArray.filter(date => { const [year, month] = date.split('-'); return new Date(`${year}-${month}-01`) <= today; }); }
  // eslint-disable-next-line
  const [vinculacionesData, setvinculacionesData] = useState([]);
  const getDataVinculaciones = async (id) => {
    try {
      const resvinculaciones = await clienteAxios.get(
        `/fechasvinculacion/${id}`
      );
      console.log(resvinculaciones);

      setvinculacionesData(resvinculaciones.data);
    } catch (error) {}
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={ocultar ? modalStyleOculto : modalStyle}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} ${
                    estadoRequest === "Vacaciones"
                      ? "Vacacion"
                      : estadoRequest === "Bonos"
                      ? "Bono"
                      : "Descuento"
                  }.`}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={vaca_estado === "APROBADA" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          vaca_estado: e.target.checked
                            ? "APROBADA"
                            : "ANULADA",
                        });
                      }}
                      name="antoine"
                      color={vaca_estado === "APROBADA" ? "success" : "error"}
                    />
                  }
                  label="Estado"
                />
              </Box>

              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <>
                  <FormControl
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                  >
                    <Autocomplete
                      disabled={desdeDis}
                      options={clientesData.map((item) => {
                        return (
                          item.perDocNumero +
                          "/+/" +
                          item.perApellidos +
                          "/+/" +
                          item.perNombres +
                          "/+/" +
                          item.perId
                        );
                      })}
                      getOptionLabel={(option) => {
                        return option
                          .replace("/+/", " ")
                          .replace("/+/", " ")
                          .split("/+/")[0];
                      }}
                      id="controlled-demo"
                      value={(
                        perDocNumero +
                        " " +
                        perApellidos +
                        " " +
                        perNombres
                      ).trim()}
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          const options = newValue.split("/+/");

                          const itemF = clientesData.find((item) => {
                            return item.perId === Number(options[3]);
                          });
                          console.log({ itemF });

                          console.log(
                            {
                              perDocNumero: options[0],
                              perNombres: options[1],
                              perApellidos: options[2],
                              vaca_idPersona: options[3],
                            },
                            itemF
                          );

                          try {
                            setitem({
                              ...item,
                              perDocNumero: options[0],
                              perNombres: options[1],
                              perApellidos: options[2],
                              vaca_idPersona: options[3],
                              vaca_fechaIngreso: "",
                              vaca_periodo: "",
                              // vaca_fechaIngreso: itemF?.fecVinculacion || "",
                            });
                            getDataVinculaciones(options[3]);
                            setperiodosAfectacion([]);
                          } catch (error) {
                            return mostrarAlerta("No hay personas", "error");
                          }
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          required
                          fullWidth
                          disabled={desdeDis}
                          label="Persona"
                          error={error.perDocNumero}
                          onChange={async (e) => {
                            if (e.target.value === "") {
                              return mostrarAlerta("Ingrese datos", "error");
                            }
                            try {
                              const res = await clienteAxios.get(
                                `personas/busqueda/GuardiasAdministracionesSupervisores/activo?search=${e.target.value}`
                              );

                              setclientesData(res.data);
                            } catch (error) {
                              setitem({
                                ...item,
                                vaca_idPersona: "",
                                perDocNumero: "",
                                perNombres: "",
                              });
                              setclientesData([]);

                              return mostrarAlerta("No hay clientes", "error");
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>{" "}
                  {/* <TextField
                    disabled
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    label="Fecha Ingreso"
                    value={item.vaca_fechaIngreso?.substr(0, 10)}
                    onChange={(e) => handleChange(e)}
                  /> */}
                  <FormControl
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                  >
                    <Autocomplete
                      options={vinculacionesData.map((item) => {
                        return item.fecVinculacion;
                      })}
                      getOptionLabel={(option) => {
                        return option?.substr(0, 10);
                      }}
                      id="controlled-demo"
                      value={item.vaca_fechaIngreso?.substr(0, 10)}
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          console.log(
                            { newValue },
                            generateDateArray(newValue)
                          );
                          setitem({
                            ...item,
                            vaca_fechaIngreso: newValue,
                            vaca_periodo: "",
                          });
                          setperiodosAfectacion(generateDateArray(newValue));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          required
                          fullWidth
                          label="Fecha Ingreso"
                          error={error.perDocNumero}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>{" "}
                  {/* <TextField
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    id="vaca_formaPago"
                    label="F. Pago"
                    value={vaca_formaPago}
                    name="vaca_formaPago"
                    error={error.vaca_formaPago}
                    onChange={(e) => handleChange(e)}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione.</MenuItem>
                    {["EFECTIVO", "DEPOSITO", "CHEQUE", "TRANSFERENCIA"].map(
                      (item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      }
                    )}
                  </TextField>{" "} */}
                  {estadoRequest === "Descuentos" ? (
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      size="small"
                      label="Periodo de Afectación"
                      name="vaca_periodo"
                      disabled={editar}
                      value={vaca_periodo.split(", ")}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          vaca_periodo: e.target.value
                            .filter((mes) => mes !== "")
                            .join(", "),
                        });
                      }}
                      select
                      error={error.vaca_periodo}
                      MenuProps={MenuProps}
                      SelectProps={{
                        displayEmpty: true,
                        multiple: true,
                        renderValue: (selected) => {
                          return selected
                            .map((value, index) => {
                              return value;
                            })
                            .join(", ");
                        },
                      }}
                    >
                      {obtenerFechasDescuentos().map((item) => {
                        return (
                          <MenuItem
                            style={getStylesMenuItems(
                              item,
                              vaca_periodo.split(", "),
                              theme
                            )}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  ) : (
                    <TextField
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      size="small"
                      label="Periodo de Afectación"
                      name="vaca_periodo"
                      disabled={editar}
                      value={vaca_periodo.split(", ")}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          vaca_periodo: e.target.value,
                        });
                      }}
                      select
                      error={error.vaca_periodo}
                      MenuProps={MenuProps}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      {periodosAfectacion.map((item) => {
                        return (
                          <MenuItem
                            style={getStylesMenuItems(
                              item,
                              vaca_periodo.split(", "),
                              theme
                            )}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                  <TextField
                    type="number"
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    id="vaca_valor"
                    label="Valor"
                    value={vaca_valor}
                    name="vaca_valor"
                    error={error.vaca_valor}
                    onChange={(e) => handleChange(e)}
                  />
                  <TextField
                    type="number"
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    id="vaca_diaVacacion"
                    label="Dia Vacacion"
                    value={vaca_diaVacacion}
                    name="vaca_diaVacacion"
                    error={error.vaca_diaVacacion}
                    onChange={(e) => handleChange(e)}
                  />
                  <TextField
                    type="number"
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formD}
                    size="small"
                    id="vaca_cantidadDiasVacaciones"
                    label="Cantidad Dias Vacaciones"
                    value={vaca_cantidadDiasVacaciones}
                    name="vaca_cantidadDiasVacaciones"
                    error={error.vaca_cantidadDiasVacaciones}
                    onChange={(e) => handleChange(e)}
                  />{" "}
                  <TextField
                    type={"date"}
                    inputProps={{
                      className: clases.inputPadding,
                      min: FechaExacta,
                    }}
                    className={clases.formD}
                    size="small"
                    id="vaca_fechaDesde"
                    label="Fecha - Desde"
                    value={vaca_fechaDesde}
                    name="vaca_fechaDesde"
                    error={error.vaca_fechaDesde}
                    onChange={(e) => handleChange(e)}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  />
                  <TextField
                    type={"date"}
                    inputProps={{
                      className: clases.inputPadding,
                      min: FechaExacta,
                    }}
                    className={clases.formD}
                    size="small"
                    id="vaca_fechaHasta"
                    label="Fecha - Hasta"
                    value={vaca_fechaHasta}
                    name="vaca_fechaHasta"
                    error={error.vaca_fechaHasta}
                    onChange={(e) => handleChange(e)}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  />
                  <>
                    <CampoDoc
                      setcargando={setcargando}
                      label="Documento"
                      propiedad={"vaca_comprobante"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                  </>
                  <TextField
                    multiline
                    rows={3}
                    variant={"outlined"}
                    inputProps={{ className: clases.inputPadding }}
                    className={clases.formAllW}
                    size="small"
                    id="vaca_detalle"
                    label="Detalle"
                    value={vaca_detalle}
                    name="vaca_detalle"
                    error={error.vaca_detalle}
                    onChange={(e) => handleChange(e)}
                  />
                </>
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAnticipos);
