import React, { useState, memo } from "react";

import Draggable from "react-draggable";
// MATERIAL
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  List,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useModalStyle } from "../../styles/styleModal";
import Trabajo from "./Trabajo/Trabajo";
import ListItemTrabajo from "./ListItemTrabajo";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalTrabajo = (props) => {
  //props
  const { setopen, open, item } = props;
  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const [seccionActive, setseccionActive] = useState("");
  const defaultActive = () => {
    setopen(false);
    setseccionActive("");
  };
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Trabajos
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <List
                  component="nav"
                  aria-label="main mailbox folders"
                  sx={{ width: "100%", maxHeight: "30vh", overflow: "scroll" }}
                  dense={true}
                >
                  {item.trabajos.map((trabajo, index) => {
                    return (
                      <ListItemTrabajo
                        index={index}
                        key={index}
                        trabajo={trabajo}
                        item={item}
                        seccionActive={seccionActive}
                        setseccionActive={setseccionActive}
                      />
                    );
                  })}
                </List>
              </Box>
              <Divider />
              <Box>
                <Trabajo seccionActive={seccionActive} item={item} />
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalTrabajo);
