import React, { memo, useState, useEffect } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
// MATERIAL
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField } from "@mui/material";
import clienteAxios from "../../../../config/axios";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const {
    row,
    index,
    campoExport,
    item,
    setitem,
    deshabilitarbotones,
    editarPadre,
  } = props;
  const [itemRow, setitemRow] = useState(row);
  useEffect(() => {
    setitemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      rolpLiquidaciones: item.rolpLiquidaciones.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (itemRow.valor === "" || itemRow.nombre === "") {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = async () => {
    if (editarPadre) {
      await clienteAxios.post("/rolpagodetalle", itemRow);
    }
    setitem({
      ...item,
      rolpLiquidaciones: item.rolpLiquidaciones.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemRow;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este registro?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={setitem}
          index={index}
          itemRow={itemRow}
          row={itemRow}
          item={item}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={true}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                id="nombre"
                disabled
                value={itemRow.nombre}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    nombre: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Editando nombre"
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                id="valor"
                type="number"
                value={itemRow.valor}
                onChange={(e) =>
                  setitemRow({
                    ...itemRow,
                    valor: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText="Editando valor"
              />
            </TableCell>
          </>
        ) : (
          <>
            {" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.nombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.valor}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
