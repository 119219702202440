import React, { useEffect, useState } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import Typography from "@mui/material/Typography";
import clienteAxios from "../../config/axios";
import Cargando from "./Cargando";
const Dropzone = (props) => {
  const { imagenes, setimagenes, fotosItem } = props;
  const [archivosIniciales, setarchivosIniciales] = useState(null);
  useEffect(() => {
    const getFotos = async () => {
      const resFotos = await Promise.all(
        fotosItem.map(async (fotoItem) => {
          try {
            const url = `imagen/0?imagen=${fotoItem.url}`;

            const resImagen = await clienteAxios(url, {
              responseType: "blob",
            });

            const nombre = url.split("/")[url.split("/").length - 1];

            const archivo = new File([resImagen.data], nombre, {
              type: resImagen.data.type,
            });

            setimagenes([archivo]);
            return archivo;
          } catch (error) {
            return "";
          }
        })
      );
      //   const resFotos = ;
      setarchivosIniciales(eliminarDuplicados([...resFotos, ...imagenes]));
    };
    getFotos();
    // eslint-disable-next-line
  }, []);

  if (archivosIniciales === null) {
    return <Cargando titulo="Cargando Imagenes" />;
  }
  function eliminarDuplicados(arr) {
    let unicos = {};
    arr.forEach((obj) => {
      unicos[obj.name] = obj;
    });
    return Object.values(unicos);
  }
  return (
    <>
      <DropzoneArea
        previewText="Imagenes:"
        showPreviews={true}
        filesLimit={4}
        showPreviewsInDropzone={false}
        initialFiles={archivosIniciales}
        acceptedFiles={[
          "image/jpeg",
          "image/png",
          "image/bmp",
          "image/jpg",
          "image/jfif",
        ]}
        dropzoneText={
          <>
            <Typography variant="h4" color="primary">
              Arrastra una imagen o da click aqui.
            </Typography>
          </>
        }
        onChange={(e) => {
          setimagenes(e);
        }}
      />
    </>
  );
};

export default Dropzone;
