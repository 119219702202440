export const validarEmail = (email) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return true;
  } else {
    return false;
  }
};
export const calcularEdad = (fecha) => {
  var hoy = new Date();
  var cumpleanos = new Date(fecha);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }

  return edad;
};

export const tienePermiso = (rol, permisos) => {
  return permisos.some((permiso) => permiso === rol);
};

export const tienePermisoRuta = (rows, ruta, usuarioRol, tipo) => {
  const categorias = rows.filter((item) => {
    return item.sidInfo.tipo === "categoria";
  });
  const subcategorias = rows.filter((item) => {
    return item.sidInfo.tipo === "subCategoria";
  });
  const subsubCategorias = rows.filter((item) => {
    return item.sidInfo.tipo === "subsubCategoria";
  });

  const split = ruta.split("/");

  const alcance = split.length;

  if (alcance === 5) {
    const rutaSSC = split[4];
    const SSC = subsubCategorias.find((subcategoria) => {
      return subcategoria.sidInfo.path === "/" + rutaSSC;
    });

    const tienePermiso = SSC.sidInfo[tipo].some((rol) =>
      usuarioRol.some((rolDelUsuario) => {
        return rolDelUsuario === rol;
      })
    );
    return tienePermiso;
  }
  if (alcance === 4) {
    const rutaSC = split[3];
    const SC = subcategorias.find((subcategoria) => {
      return subcategoria.sidInfo.path === "/" + rutaSC;
    });

    const tienePermiso = SC.sidInfo[tipo].some((rol) =>
      usuarioRol.some((rolDelUsuario) => {
        return rolDelUsuario === rol;
      })
    );
    return tienePermiso;
  } else if (alcance === 3) {
    const rutaC = split[2];
    const C = categorias.find((subcategoria) => {
      return subcategoria.sidInfo.path === "/" + rutaC;
    });

    const tienePermiso = C.sidInfo[tipo].some((rol) => rol === "ADMIN");
    return tienePermiso;
  }
};
function soloNumeros(cadena) {
  return /^\d+$/.test(cadena);
}
export const validarDocumento = (tipoDocumento, documento, mostrarAlerta) => {
  if (
    tipoDocumento === "RUC" &&
    (documento.length !== 13 || !soloNumeros(documento))
  ) {
    mostrarAlerta("El documento RUC, debe tener 13 digitos", "error");
    return true;
  } else if (
    tipoDocumento === "CEDULA" &&
    (documento.length !== 10 || !soloNumeros(documento))
  ) {
    mostrarAlerta("El documento CEDULA, debe tener 10 digitos", "error");
    return true;
  } else if (tipoDocumento === "PASAPORTE" && documento.length < 4) {
    mostrarAlerta(
      "El documento PASAPORTE, debe tener al menos 4 caracteres",
      "error"
    );
    return true;
  }
  return false;
};
// export const validarDocumento = (numdocumento) => {
//   return new Promise((resolve, reject) => {
//     let numero = numdocumento;

//     let suma = 0;
//     let residuo = 0;
//     let pri = false;
//     let pub = false;
//     let nat = false;
//     let numeroProvincias = 24;
//     let modulo = 11;

//     /* Verifico que el campo no contenga letras */
//     let ok = 1;
//     let i = 0;
//     for (i = 0; i < numero.length && ok === 1; i++) {
//       let n = parseInt(numero.charAt(i));
//       if (isNaN(n)) ok = 0;
//     }

//     if (ok === 0) {
//       reject("No puede ingresar caracteres en el número");
//       // return false;
//     }

//     if (numero.length < 10) {
//       reject("El número ingresado no es válido");
//       // return false;
//     }

//     /* Los primeros dos digitos corresponden al codigo de la provincia */
//     let provincia = numero.substr(0, 2);
//     if (provincia < 1 || provincia > numeroProvincias) {
//       reject("El código de la provincia (dos primeros dígitos) es inválido");
//       // return false;
//     }

//     /* Aqui almacenamos los digitos de la cedula en variables. */
//     let d1 = numero.substr(0, 1);
//     let d2 = numero.substr(1, 1);
//     let d3 = numero.substr(2, 1);
//     let d4 = numero.substr(3, 1);
//     let d5 = numero.substr(4, 1);
//     let d6 = numero.substr(5, 1);
//     let d7 = numero.substr(6, 1);
//     let d8 = numero.substr(7, 1);
//     let d9 = numero.substr(8, 1);
//     let d10 = numero.substr(9, 1);
//     let p1, p2, p3, p4, p5, p6, p7, p8, p9;

//     /* El tercer digito es: */
//     /* 9 para sociedades privadas y extranjeros   */
//     /* 6 para sociedades publicas */
//     /* menor que 6 (0,1,2,3,4,5) para personas naturales */

//     if (d3 === 7 || d3 === 8) {
//       reject("El tercer dígito ingresado es inválido");
//       // return false;
//     }

//     /* Solo para personas naturales (modulo 10) */
//     if (d3 < 6) {
//       nat = true;
//       p1 = d1 * 2;
//       if (p1 >= 10) p1 -= 9;
//       p2 = d2 * 1;
//       if (p2 >= 10) p2 -= 9;
//       p3 = d3 * 2;
//       if (p3 >= 10) p3 -= 9;
//       p4 = d4 * 1;
//       if (p4 >= 10) p4 -= 9;
//       p5 = d5 * 2;
//       if (p5 >= 10) p5 -= 9;
//       p6 = d6 * 1;
//       if (p6 >= 10) p6 -= 9;
//       p7 = d7 * 2;
//       if (p7 >= 10) p7 -= 9;
//       p8 = d8 * 1;
//       if (p8 >= 10) p8 -= 9;
//       p9 = d9 * 2;
//       if (p9 >= 10) p9 -= 9;
//       modulo = 10;
//     } else if (d3 === 6) {
//       /* Solo para sociedades publicas (modulo 11) */
//       /* Aqui el digito verficador esta en la posicion 9, en las otras 2 en la pos. 10 */
//       pub = true;
//       p1 = d1 * 3;
//       p2 = d2 * 2;
//       p3 = d3 * 7;
//       p4 = d4 * 6;
//       p5 = d5 * 5;
//       p6 = d6 * 4;
//       p7 = d7 * 3;
//       p8 = d8 * 2;
//       p9 = 0;
//     } else if (d3 === 9) {
//       /* Solo para entidades privadas (modulo 11) */
//       pri = true;
//       p1 = d1 * 4;
//       p2 = d2 * 3;
//       p3 = d3 * 2;
//       p4 = d4 * 7;
//       p5 = d5 * 6;
//       p6 = d6 * 5;
//       p7 = d7 * 4;
//       p8 = d8 * 3;
//       p9 = d9 * 2;
//     }

//     suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
//     residuo = suma % modulo;

//     /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo*/
//     let digitoVerificador = residuo === 0 ? 0 : modulo - residuo;

//     /* ahora comparamos el elemento de la posicion 10 con el dig. ver.*/

//     if (pub === true) {
//       if (String(digitoVerificador) !== d9) {
//         reject("El ruc de la empresa del sector público es incorrecto.");
//         // return false;
//       }

//       /* El ruc de las empresas del sector publico terminan con 0001*/
//       if (numero.substr(9, 4) !== "0001") {
//         reject(
//           "El ruc de la empresa del sector público debe terminar con 0001"
//         );
//         // return false;
//       }
//     } else if (pri === true) {
//       if (String(digitoVerificador) !== d10) {
//         reject("El ruc de la empresa del sector privado es incorrecto.");
//         // return false;
//       }
//       if (numero.substr(10, 3) !== "001") {
//         reject("El ruc de la empresa del sector privado debe terminar con 001");
//         // return false;
//       }
//     } else if (nat === true) {
//       if (String(digitoVerificador) !== d10) {
//         reject("El número de cédula de la persona natural es incorrecto.");
//         // return false;
//       }
//       if (numero.length > 10 && numero.substr(10, 3) !== "001") {
//         reject("El ruc de la persona natural debe terminar con 001");
//         // return false;
//       }
//     }
//     // RESULTADO SI ES CORRECTO
//     // let a;
//     // if (numero.length === 10) a = "CEDULA";
//     // else a = "RUC";
//     resolve(true);
//   });
// };
