import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";
import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

 
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../config/axios";
import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    socket,
    changeObjetoActivoState,
  } = props;

  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "inventario_factura",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        invUser: usuario.usuario,
        invEmpresa: usuario.rucempresa,
        invId: row.invId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    changeObjetoActivoState({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.invNombre}?`}
        contenido={`El inventario_factura ${row.invNombre} será eliminado, no podra ser recuperado`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={true}
          campoExport={campoExport}
          campoEstado="invEstado"
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.invTipo}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.invSerie}
        </TableCell>
        <TableCell size="small" align="left" className={classes.celda}>
          {row.invNombre}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.invCosto1}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {String(row.invStock).substring(0, 16)}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.invIncluyeIva}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.invIva}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.invprecios.length === 0 ? "-" : row.invprecios[0]}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.invProveedor}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.invCategoria}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          onClick={() => {
            const handleGetNameUser = async (documento) => {
              if (!documento) {
                return;
              }
              try {
                const resUser = await clienteAxios.get(
                  `/proveedores/usuario/${documento}`
                );
                mostrarAlerta(resUser.data.data.nombre, "success");
              } catch (error) {
                mostrarAlerta("Hubo un error");
              }
            };
            handleGetNameUser(row.invUser);
          }}
        >
          {row.invUser}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          onClick={() => {
            const handleGetNameUser = async (documento) => {
              if (!documento) {
                return;
              }
              try {
                const resUser = await clienteAxios.get(
                  `/proveedores/usuario/${documento}`
                );
                mostrarAlerta(resUser.data.data.nombre, "success");
              } catch (error) {
                mostrarAlerta("Hubo un error");
              }
            };
            handleGetNameUser(row.user_upd);
          }}
        >
          {row.user_upd}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.invFecUpd).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
