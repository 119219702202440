import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const AsistenciaDialog = ({ setopenDialog, openDialog, row }) => {
  const [seccionActivePersonas, setseccionActivePersonas] = useState({
    guardiasSeccion: true,
    supervisoresSeccion: false,
    administrativosSeccion: false,
  });
  const { guardiasSeccion, supervisoresSeccion, administrativosSeccion } =
    seccionActivePersonas;

  const ListItemPersona = ({ value }) => {
    return (
      <ListItem key={value.perId} disablePadding>
        <ListItemButton>
          <ListItemAvatar>
            <Avatar alt={`Avatar n°${value + 1}`} src={`${value.perFoto}`} />
          </ListItemAvatar>
          <ListItemText
            primary={`${value.perApellidos} ${value.perNombres} - ${
              value.asistencia ? "Asistió " : "Ausente "
            }`}
            primaryTypographyProps={{
              color: value.asistencia ? "success" : "error",
            }}
          />
        </ListItemButton>
      </ListItem>
    );
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xl"
      open={openDialog}
      onClose={() => setopenDialog(false)}
    >
      <DialogTitle>
        <Box sx={{ width: "100%" }}>
          {row.capaGuardias.length !== 0 ? (
            <Button
              variant={
                guardiasSeccion ? "contained" : false ? "outlined" : null
              }
              disableElevation
              style={{ padding: "8px", borderRadius: "10px 10px 0px 0px" }}
              color={false ? "error" : "primary"}
              onClick={() =>
                setseccionActivePersonas({ guardiasSeccion: true })
              }
            >
              guardias
            </Button>
          ) : null}

          {row.capaSupervisores.length !== 0 ? (
            <Button
              variant={
                supervisoresSeccion ? "contained" : false ? "outlined" : null
              }
              disableElevation
              style={{ padding: "8px", borderRadius: "10px 10px 0px 0px" }}
              color={false ? "error" : "primary"}
              onClick={() =>
                setseccionActivePersonas({
                  supervisoresSeccion: true,
                })
              }
            >
              supervisores
            </Button>
          ) : null}

          {row.capaAdministracion.length !== 0 ? (
            <Button
              variant={
                administrativosSeccion ? "contained" : false ? "outlined" : null
              }
              disableElevation
              style={{ padding: "8px", borderRadius: "10px 10px 0px 0px" }}
              color={false ? "error" : "primary"}
              onClick={() =>
                setseccionActivePersonas({
                  administrativosSeccion: true,
                })
              }
            >
              administrativos
            </Button>
          ) : null}
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent dividers>
        {guardiasSeccion ? (
          <>
            {row.capaGuardias
              .sort((a, b) => b.asistencia - a.asistencia)
              .map((value) => (
                <ListItemPersona value={value} key={value.perId} />
              ))}
          </>
        ) : null}
        {supervisoresSeccion ? (
          <>
            {row.capaSupervisores
              .sort((a, b) => b.asistencia - a.asistencia)
              .map((value) => (
                <ListItemPersona value={value} key={value.perId} />
              ))}
          </>
        ) : null}
        {administrativosSeccion ? (
          <>
            {row.capaAdministracion
              .sort((a, b) => b.asistencia - a.asistencia)
              .map((value) => (
                <ListItemPersona value={value} key={value.perId} />
              ))}
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default AsistenciaDialog;
