import React, { memo, useContext } from "react";

import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check,   } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows"; 
import { trimPropsItem } from "../../../../config/const";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setopen, socket } = props;

  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // COPIA DEL REGISTRO PARA EDITAR
  const objectDefault = {
    banNombre: "",
    banCodigo: "",
    banEmpresa: "",
    banUser: "",
    banFecReg: "",
    Todos: "",
  };
  const [item, setitem] = useState(objectDefault);
  // COLUMNAS DE LA TABLA HIJA

  // ELIMINAR EL REGISTRO

  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "banco",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          banUser: usuario.usuario,
          banEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {/* boton */}
        <TableCell
          sx={{ paddingLeft: "5px" }}
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: false,
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        <>
          <TableCell
            sx={{ paddingLeft: "5px" }}
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.banNombre}
              onChange={(e) => {
                setitem({
                  ...item,
                  banNombre: e.target.value,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          </TableCell>
          <TableCell
            sx={{ paddingLeft: "5px" }}
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.banCodigo}
              onChange={(e) => {
                setitem({
                  ...item,
                  banCodigo: e.target.value,
                });
              }}
              size="small"
              helperText="Editando"
              fullWidth
            />
          </TableCell>
        </>
        <TableCell
          sx={{ paddingLeft: "5px" }}
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.banUser}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
