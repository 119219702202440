import { useEffect, useCallback, useState } from "react";
import { io } from "socket.io-client";

export const useSocket = (serverPath) => {
  const [online, setonline] = useState(false);

  const [socket, setsocket] = useState(null);

  const conectarSocket = useCallback(
    (paramsToken) => {
      const token = paramsToken || localStorage.getItem("token");

      const socketTemp = io(serverPath, {
        autoConnect: true,
        forceNew: true,
        transports: ["websocket"],
        query: {
          "x-auth-token": token,
          from: "web",
        },
      });

      setsocket(socketTemp);
    },
    [serverPath]
  );

  const desconectarSocket = useCallback(() => {
    socket?.disconnect();
  }, [socket]);

  useEffect(() => {
    socket?.on("disconnect", () => {
      setonline(false);
    });
  }, [socket]);
  useEffect(() => {
    setonline(socket?.connected);
    socket?.on("connect", () => {
      setonline(true);
    });
  }, [socket]);
  return { socket, online, conectarSocket, desconectarSocket };
};
