import React, { memo, useContext } from "react";

import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import { objectDefault } from "../data";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const { setopen, socket } = props;
  const claseCelda = `${classes.celda}`;

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(objectDefault);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (item.catNombre === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "categoria",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          catUser: usuario.usuario,
          catEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          sx={{ paddingLeft: "5px" }}
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            <TextField
              value={item.catNombre}
              name="catNombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>

          <TableCell fontSize="small" align="center" className={claseCelda}>
            <TextField
              value={item.catTipo}
              name="catTipo"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione Tipo</MenuItem>
              {["BIEN", "SERVICIO", "BIEN-CARNET"].map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}{" "}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>

          <TableCell fontSize="small" align="center" className={claseCelda}>
            <TextField
              value={item.catDetalle}
              name="catDetalle"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>

          <TableCell fontSize="small" align="center" className={claseCelda}>
            {/* {row.catFecReg.replace("T", " ").substr(0, 16)} */}
          </TableCell>
          <TableCell fontSize="small" align="center" className={claseCelda}>
            {usuario.usuario}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
