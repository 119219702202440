export const columns = [
  {
    id: "lugNombre",
    label: "Nombre",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "lugNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "lugFecReg",
    label: "Fec. Reg",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "lugFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "lugUser",
    label: "User",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "lugUser",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  lugNombre: "",
  lugUser: "",
  lugEmpresa: "",
  lugFecReg: "",
  Todos: "",
};
