import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  TextField,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TablePagination,
  TableHead,
  TableBody,
  Popper,
} from "@mui/material";
import Draggable from "react-draggable";
import {
  AddCircle,
  AddCircleOutlineTwoTone,
  Cancel,
  Create,
  Delete,
} from "@mui/icons-material";
// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { Autocomplete } from "@mui/material";
import clienteAxios from "../../../config/axios";
import ToolBarTable from "../../../components/Extra/ToolBarTable";
import TablaCabecera from "../../../components/Extra/TablaCabecera";
import RowAgregarBodega from "./RowsBodega/RowAgregarBodega";
import RowBodega from "./RowsBodega/RowBodega";
import Dropzone from "../../components/Dropzone";
// import CategoriaContext from "../../../../Context/Configuraciones/Categorias/CategoriaContext";
// import SubcategoriaContext from "../../../../Context/Configuraciones/SubCategoria/SubcategoriaContext";
// import ProveedoresContext from "../../../../Context/Compras/Proveedores/ProveedoresContext";

import { replaceCaracteres, trimPropsItem } from "../../../config/const";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import { useRowStyles } from "../../styles/stylesRows";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("md")]: {
      width: "75%",
      marginTop: "2vh",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD3PR: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "30%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);

  //props
  const {
    open,
    setopen,
    editar,
    seteditar,
    setopenSubCategoria,
    setopenCategoria,
    setOpenProveedor,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    seteditarProveedor,
    changeObjetoActivoStateProveedor,
  } = props;

  const [categoriasData, setcategoriasData] = useState(null);
  const [subcategoriasData, setsubcategoriasData] = useState([]);
  const [proveedoresData, setproveedoresData] = useState([]);
  const [bodegaData, setbodegaData] = useState(null);
  const [planDeCuentas, setplanDeCuentas] = useState(null);
  console.log({ ObjectoActivo });
  const [item, setitem] = useState(ObjectoActivo);
  const [agregandoBodega, setagregandoBodega] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload("inventario_factura");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [cargando, setcargando] = useState(false);
  const getPercentageChange = (costAct, pvp1P) => {
    var decreaseValue = Number(costAct) - Number(pvp1P);

    return (-(decreaseValue / Number(costAct)) * 100).toFixed(2);
  };
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const {
    invSerie,
    // invTipo,
    invNombre,
    // invDescripcion,
    invCosto1,
    // invCosto2,
    // invCosto3,
    // invStock,
    invIva,
    invIncluyeIva,
    invEstado,
    invBodegas,
    invprecios,
    invProveedor,
    invCategoria,
    invSubCategoria,
    invUnidad,
    invMarca,
    invSubsidio,
    invPlanCuenta,
    invTipo,
    invDescripcion,
    invFotos,
    invStock,
    invMinimo,
  } = item;
  const [error, seterror] = useState({
    invSerie: false,
    invNombre: false,
    invCosto1: false,
    invIva: false,
    invIncluyeIva: false,
    invEstado: false,
    invprecios: false,
    invProveedor: false,
    invCategoria: false,
    invSubCategoria: false,
    invUnidad: false,
    invMarca: false,
    invSubsidio: false,
    invPlanCuenta: false,
  });

  useEffect(() => {
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [ObjectoActivo]);
  useEffect(() => {
    const getcategoriasData = async () => {
      try {
        const rescategoriasData = await clienteAxios.get(
          `categorias/filtro/0?empresa=${usuario.rucempresa}`
        );

        setcategoriasData(rescategoriasData.data.data);
      } catch (error) {
        setcategoriasData([]);
      }
    };

    if (!categoriasData) {
      getcategoriasData();
    }
    const getplanDeCuentas = async () => {
      try {
        const resplanDeCuentas = await clienteAxios.get(
          `plancuentas/filtro/0?empresa=${usuario.rucempresa}`
        );

        setplanDeCuentas(resplanDeCuentas.data.data);
      } catch (error) {
        setplanDeCuentas([]);
      }
    };

    if (!planDeCuentas) {
      getplanDeCuentas();
    }
    const getbodegaData = async () => {
      try {
        const resbodegaData = await clienteAxios.get(`bodegas/filtro/0?`);

        setbodegaData(resbodegaData.data.data);
      } catch (error) {
        setbodegaData([]);
      }
    };

    if (!bodegaData) {
      getbodegaData();
    }

    // eslint-disable-next-line
  }, []);

  const [ItemOtroLugarProveedor, setItemOtroLugarProveedor] = useState({});
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    itemSocketData: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      console.log({ data });

      setitemSocket({
        tipo: "agregar",
        itemSocketData: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      console.log({ data });

      setitemSocket({
        tipo: "editar",
        itemSocketData: data,
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { itemSocketData, tipo } = itemSocket;

    if (!open) {
      return;
    }

    if (tipo === "") {
      return;
    }

    if (usuario.rucempresa !== itemSocketData.rucempresa) {
      return;
    }

    const funcionAalerta = () => {
      if (itemSocketData.msg === "") {
        return null;
      }
      if (!itemSocketData.msg) {
        return null;
      }
      mostrarAlerta(
        itemSocketData.msg,
        itemSocketData.alert ? itemSocketData.alert : "success"
      );
    };

    if (tipo === "agregar") {
      if (itemSocketData.tabla === "proveedor") {
        if (usuario.usuario !== itemSocketData.cliUser) {
          return;
        }
        setitem({
          ...item,
          invProveedor:
            itemSocketData.cliDocNumero + " " + itemSocketData.cliRazonSocial,
        });
        setItemOtroLugarProveedor(itemSocketData);
      } else if (itemSocketData.tabla === "categoria") {
        setsubcategoriasData([]);
        setcategoriasData([...categoriasData, itemSocketData]);
        setitem({
          ...item,
          invCategoria: itemSocketData.catNombre,
          invTipo: itemSocketData.catTipo,
          invSubCategoria: "",
        });
      } else if (itemSocketData.tabla === "subcategoria") {
        setitem({
          ...item,
          invSubCategoria: itemSocketData.subNombre,
        });
      }
    }

    if (tipo === "editar") {
      if (itemSocketData.tabla === "proveedor") {
        if (usuario.usuario !== itemSocketData.cliUser) {
          return;
        }
        setitem({
          ...item,
          invProveedor:
            itemSocketData.cliDocNumero + " " + itemSocketData.cliRazonSocial,
        });
        setItemOtroLugarProveedor(itemSocketData);
      } else if (itemSocketData.tabla === "categoria") {
        setsubcategoriasData([]);
        setcategoriasData([...categoriasData, itemSocketData]);
        setitem({
          ...item,
          invCategoria: itemSocketData.catNombre,
          invTipo: itemSocketData.catTipo,
          invSubCategoria: "",
        });
      } else if (itemSocketData.tabla === "subcategoria") {
        setitem({
          ...item,
          invSubCategoria: itemSocketData.subNombre,
        });
      }
    }
    funcionAalerta();
    setitemSocket({ tipo: "", itemSocketData: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  const defaultActive = (eliminar) => {
    changeObjetoActivoState({
      invSerie: "0",
      invTipo: "",
      invNombre: "",
      invDescripcion: "",
      invCosto1: "",
      invCosto2: "",
      invCosto3: "",
      invStock: "1",
      invMinimo: "1",
      invIva: "SI",
      invIncluyeIva: "SI",
      invEstado: "ACTIVA",
      invBodegas: [],
      invprecios: [],
      invFotos: [],
      invProveedor: "",
      invCategoria: "",
      invSubCategoria: "",
      invUnidad: "",
      invMarca: "",
      invSubsidio: "0",
      invPlanCuenta: "",
      invEmpresa: "",
      invUser: "admin",
      invFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      invSerie: false,
      invNombre: false,
      invCosto1: false,
      invIva: false,
      invIncluyeIva: false,
      invEstado: false,
      invprecios: false,
      invProveedor: false,
      invCategoria: false,
      invSubCategoria: false,
      invUnidad: false,
      invMarca: false,
      invSubsidio: false,
      invPlanCuenta: false,
    });
    // if (ItemOtroLugarProveedor.turId && eliminar) {
    //   try {
    //     socket.current.emit("client:eliminarData", {
    //       tabla: "turnoextra",
    //       rucempresa: usuario.rucempresa,
    //       rol: usuario.rol,
    //       turUser: usuario.usuario,
    //       turEmpresa: usuario.rucempresa,
    //       turId: ItemOtroLugarProveedor.turId,
    //     });
    //   } catch (error) {}
    // }
    setItemOtroLugarProveedor({});
    setcargando(false);
    setimagenes([]);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  const [seccionActive, setseccionActive] = useState({
    datos: true,
    bodega: false,
    procendencia: false,
    contabilidad: false,
    fotos: false,
  });

  const { datos, bodega, procendencia, contabilidad, fotos } = seccionActive;
  // obteniendo los años

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };

  const handleCrear = async () => {
    // if (invBodegas.length === 0 && invTipo !== "SERVICIO") {
    //   return mostrarAlerta("Ingrese al menos una bodega", "error");
    // }

    if (
      item.invNombre.trim() === "" ||
      item.invCosto1 === 0 ||
      item.invIva.trim() === "" ||
      item.invIncluyeIva.trim() === "" ||
      item.invEstado.trim() === "" ||
      item.invprecios.length === 0 ||
      item.invprecios.some((precio) => precio === "") ||
      item.invCategoria.trim() === "" ||
      item.invSubsidio.trim() === ""
    ) {
      mostrarAlerta("Faltan Datos Obligatorios por Llenar", "error");
      return seterror({
        invNombre: item.invNombre.trim() === "" ? true : false,
        invCosto1: item.invCosto1 === 0 ? true : false,
        invIva: item.invIva.trim() === "" ? true : false,
        invIncluyeIva: item.invIncluyeIva.trim() === "" ? true : false,
        invEstado: item.invEstado.trim() === "" ? true : false,
        invprecios:
          item.invprecios.length === 0 ||
          item.invprecios.some((precio) => precio === "")
            ? true
            : false,
        invCategoria: item.invCategoria.trim() === "" ? true : false,
        invSubsidio: item.invSubsidio.trim() === "" ? true : false,
      });
    }

    try {
      const resDocumentos = await subirImagenes(imagenes, invFotos);
      console.log({ resDocumentos });
      console.log(
        trimPropsItem({
          ...item,
          invFotos: resDocumentos,
          // invStock:
          //   item.invBodegas.length === 0
          //     ? 0
          //     : item.invBodegas
          //         .map((row) => {
          //           return Number(row.stock);
          //         })
          //         .reduce(reducer)
          //         .toFixed(2),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          invUser: usuario.usuario,
          invEmpresa: usuario.rucempresa,
          tabla: "inventario_factura",
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          invFotos: resDocumentos,
          // invStock:
          //   item.invBodegas.length === 0
          //     ? 0
          //     : item.invBodegas
          //         .map((row) => {
          //           return Number(row.stock);
          //         })
          //         .reduce(reducer)
          //         .toFixed(2),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          invUser: usuario.usuario,
          invEmpresa: usuario.rucempresa,
          tabla: "inventario_factura",
        })
      );

      setcargando(false);
    } catch (error) {
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }

    defaultActive();
  };
  const handleEditar = async () => {
    // if (invBodegas.length === 0 && invTipo !== "SERVICIO") {
    //   return mostrarAlerta("Ingrese al menos una bodega", "error");
    // }

    if (
      item.invNombre.trim() === "" ||
      item.invCosto1 === 0 ||
      item.invIva.trim() === "" ||
      item.invIncluyeIva.trim() === "" ||
      item.invEstado.trim() === "" ||
      item.invprecios.length === 0 ||
      item.invprecios.some((precio) => precio === "") ||
      item.invCategoria.trim() === "" ||
      item.invSubsidio.trim() === ""
    ) {
      mostrarAlerta("Faltan Datos Obligatorios por Llenar", "error");
      return seterror({
        invNombre: item.invNombre.trim() === "" ? true : false,
        invCosto1: item.invCosto1 === 0 ? true : false,
        invIva: item.invIva.trim() === "" ? true : false,
        invIncluyeIva: item.invIncluyeIva.trim() === "" ? true : false,
        invEstado: item.invEstado.trim() === "" ? true : false,
        invprecios:
          item.invprecios.length === 0 ||
          item.invprecios.some((precio) => precio === "")
            ? true
            : false,
        invCategoria: item.invCategoria.trim() === "" ? true : false,
        invSubsidio: item.invSubsidio.trim() === "" ? true : false,
      });
    }
    try {
      const resDocumentos = await subirImagenes(imagenes, invFotos);
      console.log(
        trimPropsItem({
          ...item,
          invFotos: resDocumentos,
          // invStock:
          //   item.invBodegas.length === 0
          //     ? 0
          //     : item.invBodegas
          //         .map((row) => {
          //           return Number(row.stock);
          //         })
          //         .reduce(reducer)
          //         .toFixed(2),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          invUser: usuario.usuario,
          invEmpresa: usuario.rucempresa,
          tabla: "inventario_factura",
        })
      );
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          invFotos: resDocumentos,
          // invStock:
          //   item.invBodegas.length === 0
          //     ? 0
          //     : item.invBodegas
          //         .map((row) => {
          //           return Number(row.stock);
          //         })
          //         .reduce(reducer)
          //         .toFixed(2),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user_upd: usuario.usuario,
          invEmpresa: usuario.rucempresa,
          tabla: "inventario_factura",
        })
      );
      setcargando(false);
    } catch (error) {
      console.log({ error });
      setcargando(false);
      return mostrarAlerta("Hubo un error", "error");
    }
    defaultActive();
  };

  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  if (!open) {
    return null;
  }
  if (!categoriasData) {
    return null;
  }
  if (!planDeCuentas) {
    return null;
  }
  if (!bodegaData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Inventario.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={datos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ datos: true })}
                  >
                    Datos principales
                  </Button>
                  <Button
                    variant={procendencia ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ procendencia: true })}
                  >
                    Procedencia
                  </Button>
                  {/* <Button
                    variant={bodega ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ bodega: true })}
                  >
                    Bodega
                  </Button> */}
                  <Button
                    variant={contabilidad ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ contabilidad: true })}
                  >
                    Contabilidad
                  </Button>
                  <Button
                    variant={fotos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ fotos: true })}
                  >
                    Fotos
                  </Button>
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={invEstado === "ACTIVA" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          invEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                        });
                      }}
                      color="secondary"
                      name="invEstado"
                    />
                  }
                  label="Estado"
                />
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                {datos ? (
                  <>
                    <TextField
                      className={clases.formD}
                      id="invSerie"
                      label="Serie"
                      value={invSerie}
                      name="invSerie"
                      error={error.invSerie}
                      onChange={(e) => {
                        if (e.target.value.length > 25) {
                          setitem({
                            ...item,
                            [e.target.name]: e.target.value.slice(0, 25),
                          });
                        } else {
                          setitem({
                            ...item,
                            [e.target.name]: e.target.value,
                          });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <React.Fragment>
                            <Typography
                              color={
                                item.invSerie.length === 25
                                  ? "error"
                                  : "secondary"
                              }
                            >
                              {item.invSerie.length}/25
                            </Typography>
                          </React.Fragment>
                        ),
                      }}
                    />
                    <TextField
                      className={clases.formD}
                      id="invNombre"
                      label="Nombre"
                      value={invNombre}
                      name="invNombre"
                      error={error.invNombre}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD3PR}
                      id="invCosto1"
                      type="number"
                      label="Costo de compra"
                      value={invCosto1}
                      name="invCosto1"
                      error={error.invCosto1}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl
                      component="fieldset"
                      className={clases.formD3PR}
                    >
                      <Box display="flex" alignItems="center">
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{ marginRight: ".5rem", fontWeight: "bold" }}
                        >
                          Lleva IVA
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={invIva}
                          name="invIva"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio color="primary" />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio color="primary" />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <TextField
                      className={clases.formAllW}
                      type="number"
                      label="Descripción"
                      value={invDescripcion}
                      name="invDescripcion"
                      error={error.invDescripcion}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl
                      component="fieldset"
                      className={clases.formD3PR}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{ marginRight: ".5rem", fontWeight: "bold" }}
                          className={error.invIncluyeIva ? clases.error : ""}
                        >
                          Los precios incluyen IVA
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleChange(e)}
                          row
                          aria-label="gender"
                          value={invIncluyeIva}
                          name="invIncluyeIva"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio color="primary" />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio color="primary" />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      className={clases.formD}
                    ></FormControl>
                    <Typography
                      style={{ width: "100%", marginTop: "1rem" }}
                      variant="subtitle1"
                      color="textPrimary"
                      align="left"
                    >
                      Precio de venta al publico
                    </Typography>
                    <FormControl className={clases.formAllW}>
                      <Box display="flex" alignItems="center">
                        <Typography
                          variant="subtitle1"
                          color={error.invprecios ? "error" : "primary"}
                        >
                          Precios:
                        </Typography>
                        <IconButton
                          aria-label=""
                          size="small"
                          onClick={async () => {
                            setitem({
                              ...item,
                              invprecios: [...item.invprecios, "1"],
                            });
                          }}
                        >
                          <AddCircle fontSize="small" color="secondary" />
                        </IconButton>
                      </Box>
                    </FormControl>
                    {invprecios.map((precio, index) => {
                      return (
                        <TextField
                          type="number"
                          key={index}
                          className={clases.correo}
                          id=""
                          value={precio}
                          onChange={(e) => {
                            setitem({
                              ...item,
                              invprecios: invprecios.map((item, indexItem) => {
                                if (indexItem === index) {
                                  return e.target.value;
                                } else {
                                  return item;
                                }
                              }),
                            });
                          }}
                          label={`Precio #${index + 1}`}
                          helperText={`Ganancias de un: %${getPercentageChange(
                            invCosto1,
                            precio
                          )}`}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton
                                  aria-label=""
                                  onClick={() => {
                                    setitem({
                                      ...item,
                                      invprecios: invprecios.filter(
                                        (item, indexItem) => {
                                          return indexItem !== index;
                                        }
                                      ),
                                    });
                                  }}
                                >
                                  <Delete color="error" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    })}
                  </>
                ) : null}

                {procendencia ? (
                  <>
                    <TextField
                      type="number"
                      className={clases.formD}
                      label="Stock"
                      value={invStock}
                      name="invStock"
                      error={error.invStock}
                      onChange={(e) => {
                        console.log(e.target.value);
                        console.log(e.target.value.length);
                        if (e.target.value.length > 16) {
                          setitem({
                            ...item,
                            invStock: e.target.value.slice(0, 16),
                          });
                        } else {
                          handleChange(e);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <React.Fragment>
                            <Typography
                              color={
                                invStock.length === 16 ? "error" : "secondary"
                              }
                            >
                              {invStock.length}/16
                            </Typography>
                          </React.Fragment>
                        ),
                      }}
                    />
                    <TextField
                      type="number"
                      className={clases.formD}
                      label="Minimo"
                      value={invMinimo}
                      name="invMinimo"
                      error={error.invMinimo}
                      onChange={(e) => handleChange(e)}
                    />
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={categoriasData.map((item) => {
                          return item.catNombre + "/+/" + item.catTipo;
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ");
                        }}
                        id="controlled-demo"
                        value={
                          (invCategoria + invTipo).trim() === ""
                            ? ""
                            : invCategoria + " - " + invTipo
                        }
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            setitem({
                              ...item,
                              invCategoria: options[0],
                              invTipo: options[1],
                              invSubCategoria: "",
                            });

                            try {
                              const res = await clienteAxios.get(
                                `/subcategorias/filtro/0?empresa=${usuario.rucempresa}&categoria=${options[0]}`
                              );

                              setsubcategoriasData(res.data.data);
                            } catch (error) {
                              setsubcategoriasData([]);
                            }
                          } else {
                            setitem({
                              ...item,
                              invCategoria: "",
                              invSubCategoria: "",
                            });
                            setsubcategoriasData([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Categoría"
                            fullWidth
                            error={error.invCategoria}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese una categoría",
                                  "error"
                                );
                              }
                              try {
                                // setproveedoresData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    aria-label=""
                                    onClick={async () => {
                                      setopenCategoria(true);
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={subcategoriasData.map((item) => {
                          return item.subNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ");
                        }}
                        id="controlled-demo"
                        value={invSubCategoria}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            setitem({
                              ...item,
                              invSubCategoria: newValue,
                            });
                          } else {
                            setitem({
                              ...item,
                              invSubCategoria: "",
                            });
                            setsubcategoriasData([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Sub Categoría"
                            fullWidth
                            error={error.invSubCategoria}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese una categoría",
                                  "error"
                                );
                              }
                              try {
                                // setproveedoresData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    aria-label=""
                                    onClick={async () => {
                                      setopenSubCategoria(true);
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems=" center"
                      width="100%"
                    >
                      {ItemOtroLugarProveedor.perId ? (
                        <>
                          <Typography
                            variant="body1"
                            color="secondary"
                            fontWeight={"bold"}
                          >
                            Proveedor:
                            <Typography
                              variant="body1"
                              color="primary"
                              component={"span"}
                            >
                              {ItemOtroLugarProveedor.perDocNumero}
                              {ItemOtroLugarProveedor.perNombre}
                            </Typography>
                          </Typography>
                          <IconButton
                            aria-label=""
                            style={{ margin: "0px" }}
                            onClick={() => {
                              changeObjetoActivoStateProveedor(
                                ItemOtroLugarProveedor
                              );
                              seteditarProveedor(true);
                              setOpenProveedor(true);
                            }}
                          >
                            <Create color="secondary" />
                          </IconButton>
                        </>
                      ) : null}
                    </Box>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        PopperComponent={PopperMy}
                        disableClearable={true}
                        options={proveedoresData.map((item) => {
                          return item.cliDocNumero + " " + item.cliRazonSocial;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        value={invProveedor}
                        paperprops
                        onChange={async (event, newValue) => {
                          setitem({ ...item, invProveedor: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Ruc proveedor"
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?search=${e.target.value}`
                                );

                                setproveedoresData(res.data.data);
                              } catch (error) {
                                return mostrarAlerta(
                                  "No hay proveedores",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    aria-label=""
                                    onClick={async () => {
                                      setOpenProveedor(true);
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={proveedoresData.map((item) => {
                          return item.perDocNumero + " " + item.perNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option;
                        }}
                        id="controlled-demo"
                        value={invProveedor}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            // const opciones = newValue.split("/+/");

                            setitem({ ...item, invProveedor: newValue });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Ruc proveedor"
                            fullWidth
                            error={error.invProveedor}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese un Proveedor",
                                  "error"
                                );
                              }
                              try {
                                if (e.target.value.length < 3) {
                                  return;
                                }
                                const res = await clienteAxios.get(
                                  `/proveedores/filtro/0?empresa=${
                                    usuario.rucempresa
                                  }&search=${
                                    e.target.value
                                  }&estado=${"PROVEEDOR"}&tabla=compras`
                                );
                                if (!res.data.data) {
                                  return mostrarAlerta(res.data.msg, "error");
                                }

                                setproveedoresData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    aria-label=""
                                    onClick={async () => {
                                      setOpenProveedor(true);
                                    }}
                                  >
                                    <AddCircleOutlineTwoTone color="primary" />
                                  </IconButton>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl> */}
                    <TextField
                      className={clases.formD}
                      id="invUnidad"
                      label="Unidad"
                      value={invUnidad}
                      name="invUnidad"
                      error={error.invUnidad}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione Unidad</MenuItem>
                      {[
                        "LB",
                        "G",
                        "KG",
                        "OZ",
                        "UNI",
                        "M",
                        "C",
                        "CM",
                        "MM",
                        "LT",
                        "CM3",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      className={clases.formD}
                      id="invMarca"
                      label="Marca"
                      value={invMarca}
                      name="invMarca"
                      error={error.invMarca}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      id="invSubsidio"
                      type="number"
                      label="Subsidio"
                      value={invSubsidio}
                      name="invSubsidio"
                      error={error.invSubsidio}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
                {bodega ? (
                  <Paper className={clases.root}>
                    <ToolBarTable
                      titulo={"Bodegas"}
                      soloTitulo={true}
                      botonAdd={true}
                      deshabilitarbotonAdd={false}
                      funcionAdd={() => {
                        setagregandoBodega(true);

                        // const retenciones = [
                        //   {
                        //     detTipo: "",
                        //     detCodigo: "",
                        //     detBasImponible: "",
                        //     detImpuesto: "",
                        //     detPorcentaje: "",
                        //     detValor: "",
                        //   },
                        //   ...item.retenciones,
                        // ];

                        // setitem({
                        //   ...item,
                        //   retenciones,
                        // });
                      }}
                    />
                    <TableContainer className={clases.container}>
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        id="tabla"
                        size="small"
                      >
                        <caption
                          style={{
                            padding: "0px",
                            paddingRight: "1rem",
                            paddingLeft: "1rem",
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <TablePagination
                              labelRowsPerPage="Filas por pagina"
                              rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                              component="div"
                              count={[].length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            {item.invBodegas.length === 0 ? null : (
                              <>
                                <Typography variant="body1" color="primary">
                                  T. Stock:
                                  <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    component="span"
                                  >
                                    {item.invBodegas
                                      .map((row) => {
                                        return Number(row.stock);
                                      })
                                      .reduce(reducer)
                                      .toFixed(2)}
                                  </Typography>
                                </Typography>
                              </>
                            )}
                          </Box>
                        </caption>
                        {/* <ModalCarnet setopen={setopen} onChangeCarnetActivo={onChangeCarnetActivo}/> */}

                        <TableHead>
                          <TablaCabecera
                            columns={[
                              {
                                id: "nomBodega",
                                label: "Bodega",
                                minWidth: 100,
                                tipo: "string",

                                propiedad: "nomBodega",
                              },
                              {
                                id: "stock",
                                label: "Stock",
                                minWidth: 100,
                                tipo: "string",

                                propiedad: "stock",
                              },
                              {
                                id: "maximo",
                                label: "Maximo",
                                minWidth: 100,
                                tipo: "string",

                                propiedad: "maximo",
                              },
                              {
                                id: "minimo",
                                label: "Minimo",
                                minWidth: 100,
                                tipo: "string",

                                propiedad: "minimo",
                              },
                            ]}
                            habilitarOrdenar={false}
                          />
                        </TableHead>

                        <TableBody>
                          {agregandoBodega ? (
                            <RowAgregarBodega
                              setagregar={setagregandoBodega}
                              setitem={setitem}
                              item={item}
                              bodegaData={bodegaData}
                            />
                          ) : null}
                          {invBodegas.map((row, index) => {
                            return (
                              <RowBodega
                                bodegaData={bodegaData}
                                deshabilitarbotones={false}
                                item={item}
                                setitem={setitem}
                                row={row}
                                index={index}
                                key={index}
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : null}
                {contabilidad ? (
                  <>
                    <FormControl fullWidth className={clases.formD}>
                      <Autocomplete
                        options={planDeCuentas.map((item) => {
                          return item.plancCodigo + "/+/" + item.plancNombre;
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ");
                        }}
                        id="controlled-demo"
                        value={invPlanCuenta}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            setitem({
                              ...item,
                              invPlanCuenta: options[0],
                            });
                          } else {
                            setitem({
                              ...item,
                              invPlanCuenta: "",
                            });
                            setsubcategoriasData([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Cuenta"
                            fullWidth
                            error={error.invPlanCuenta}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese una cuenta",
                                  "error"
                                );
                              }
                              try {
                                // setproveedoresData(res.data.data);
                              } catch (error) {}
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </>
                ) : null}
                {fotos && (
                  <>
                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={invFotos}
                    />
                  </>
                )}
              </Box>
              {/* <Divider /> */}
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
