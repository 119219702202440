import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../../components/ButtonsAction";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  DeleteForever,
  EventNote,
  PictureAsPdf,
  WorkOutline,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import DialogFechas from "./DialogFechas";
import Modal from "./Modal";
import ModalQR from "./ModalQR";
import clienteAxios from "../../../../../../config/axios";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    // tienePermiso,
    // alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    // setopen,
    // seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    // changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // MODAL LEIDOS
  const [openDialog, setopenDialog] = useState(false);
  const [openModalTrabajos, setopenModalTrabajos] = useState(false);
  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [openQr, setopenQr] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        actUser: usuario.usuario,
        actEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        act_asigPerId: rowHijo.act_asigPerId,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
        row={rowHijo}
      />
      <Modal
        setopen={setopenModalTrabajos}
        open={openModalTrabajos}
        item={rowHijo}
      />
      {openDialog ? (
        <DialogFechas
          setopen={setopenDialog}
          open={openDialog}
          rowHijo={rowHijo}
          rowPadre={rowPadre}
        />
      ) : null}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Ver eventos",
              texto: rowHijo.teventos,
              funcion: () => {
                setopenDialog(true);
              },
              disabled: false,
              Icon: EventNote,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Trabajos",
              texto: rowHijo.act_asigTrabajos.length,
              funcion: () => {
                setopenModalTrabajos(true);
              },
              disabled: false,
              Icon: WorkOutline,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/actividad_individual?rucempresa=${usuario.rucempresa}&act_asigId=${rowHijo.act_asigId}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.act_asigEveTipo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.act_asigEveNombre}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.act_asigFecha.substr(0, 10)}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.act_asigEstado}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
