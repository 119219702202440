import React, { memo, useContext } from "react";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";

// MATERIAL
import { Dialog, TableCell, TableRow, TextField } from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
  Image,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import ButtonsAction from "../../../components/ButtonsAction";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { trimPropsItem } from "../../../../config/const";
import ImagenCelda from "../../../components/ImagenCelda";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
import Dropzone from "../../../components/Dropzone";

const RowHijo = (props) => {
  const classes = useRowStyles();
  const { rowVulneravilidad, rowPadre, indexVulneravilidad, socket } = props;
  const claseCelda = `${
    indexVulneravilidad % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [itemHijo, setitemHijo] = useState(rowVulneravilidad);
  const [editar, seteditar] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("EventosActividades");
  const [openDialog, setopenDialog] = useState(false);

  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload(); 
  const botonDisabled = () => {
    if (itemHijo.nombre === "" || itemHijo.minutos === "") {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }
        setimagenes([]);
        setitemHijo(rowVulneravilidad);
        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijo(rowVulneravilidad);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Fotos",
      texto: `${imagenes.length || itemHijo.fotos.length}`,
      funcion: () => {
        setopenDialog(true);
      },
      disabled: cargando,
      Icon: Image,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = async () => {
    const existe = rowPadre.eveVulnerabilidades.some(
      (itemActividad) =>
        itemHijo.nombre === itemActividad.nombre &&
        itemHijo.nombre !== rowVulneravilidad.nombre
    );
    if (existe) {
      return mostrarAlerta("Ya existe esta actividad", "error");
    }
    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, itemHijo.fotos);
      socket.current.emit("client:actualizarData", {
        ...rowPadre,
        tabla: "eventoactividad",
        eveVulnerabilidades: rowPadre.eveVulnerabilidades.map(
          (item, itemIndex) => {
            if (indexVulneravilidad === itemIndex) {
              return trimPropsItem({
                ...itemHijo,
                fotos: resDocumentos,
              });
            } else {
              return item;
            }
          }
        ),
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        eveUser: usuario.usuario,
        eveEmpresa: usuario.rucempresa,
      });
      setcargando(false);
      setimagenes([]);
      seteditar(false);
    } catch (error) {
      mostrarAlerta("Hubo un error al actualizar la vulneravilidad", "error");
    }
  };
  const handleChange = (e) => {
    setitemHijo({
      ...itemHijo,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...rowPadre,
      tabla: "eventoactividad",
      eveVulnerabilidades: rowPadre.eveVulnerabilidades.filter(
        (item, itemIndex) => {
          return indexVulneravilidad !== itemIndex;
        }
      ),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      eveUser: usuario.usuario,
      eveEmpresa: usuario.rucempresa,
    });
    setopenConfirmDialog(false);
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={rowVulneravilidad}
          botones={editar ? botonesEditar : botones}
        />
        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell
              fontSize="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                autoFocus
                value={itemHijo.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <ImagenCelda
              propiedad={rowVulneravilidad.fotos}
              array={true}
              claseCelda={claseCelda}
            />

            <Dialog open={openDialog} onClose={() => setopenDialog(false)}>
              <Dropzone
                setimagenes={(e) => {
                  setmodificoImagenes(true);
                  setimagenes(e);
                }}
                imagenes={imagenes}
                fotosItem={itemHijo.fotos}
              />
            </Dialog>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {rowVulneravilidad.nombre}
            </TableCell>
            <ImagenCelda
              propiedad={rowVulneravilidad.fotos}
              array={true}
              claseCelda={claseCelda}
            />
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowHijo);
