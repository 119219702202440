import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";
import Dropzone from "../../../components/Dropzone";
import { replaceCaracteres } from "../../../../config/const";
import Calendario from "./Calendario";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  FormControlLabel,
  MenuItem,
  ListItem,
  Collapse,
  Avatar,
  Checkbox,
  List,
  ListItemText,
  InputAdornment,
  Button,
} from "@mui/material";
import { Backspace, Cancel, ExpandLess, ExpandMore } from "@mui/icons-material";
import { objectDefault } from "../data";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
import CampoDoc from "../../../components/CampoDoc";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "60%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAvisos = (props) => {
  const [focusedDate, setFocusedDate] = useState();
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    // socket,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [filtrando, setfiltrando] = useState(false);
  const [filtro, setfiltro] = useState("");
  const [clientesDataDefault, setclientesDataDefault] = useState([]);
  const containerRef = React.useRef(null);
  const [openCalendario, setopenCalendario] = useState(true);

  const getClientesData = async (tipo) => {
    setitem({
      ...item,
      aviOpcion: tipo,
      aviDirigido: [],
    });
    if (tipo === "CLIENTE") {
      const res = await clienteAxios.get(
        `/clientes/filtro/0?search&estado=CLIENTES`
      );
      const arrayMap = res.data.data.map((itemRes) => {
        return {
          id: itemRes.cliId,
          docnumero: itemRes.cliDocNumero,
          foto: itemRes.cliFoto,
          nombres: itemRes.cliRazonSocial,
        };
      });
      setclientesData(arrayMap);
      setclientesDataDefault(arrayMap);

      setfiltrando(false);
    } else if (tipo === "GUARDIAS") {
      const res = await clienteAxios.get(
        `/personas/filtro/0?search&estado=GUARDIAS`
      );
      const arrayMap = res.data.data.map((itemRes) => {
        return {
          id: itemRes.perId,
          docnumero: itemRes.perDocNumero,
          nombres: itemRes.perNombres + " " + itemRes.perApellidos,
          foto: itemRes.cliFoto,
        };
      });
      setclientesData(arrayMap);
      setclientesDataDefault(arrayMap);

      setfiltrando(false);
    } else if (tipo === "SUPERVISORES") {
      const res = await clienteAxios.get(
        `/personas/datos-principales/guadias-supervisores-administracion?estado=SUPERVISOR`
      );

      const arrayMap = res.data.data.map((itemRes) => {
        return {
          id: itemRes.perId,
          docnumero: itemRes.perDocNumero,
          nombres: itemRes.perNombres + " " + itemRes.perApellidos,
          foto: itemRes.perFoto,
        };
      });
      setclientesData(arrayMap);
      setclientesDataDefault(arrayMap);

      setfiltrando(false);
    } else if (tipo === "ADMINISTRACION") {
      const res = await clienteAxios.get(
        `/personas/datos-principales/guadias-supervisores-administracion?search=&estado=ADMINISTRACION`
      );

      const arrayMap = res.data.data.map((itemRes) => {
        return {
          id: itemRes.perId,
          docnumero: itemRes.perDocNumero,
          nombres: itemRes.perNombres + " " + itemRes.perApellidos,
          foto: itemRes.perFoto,
        };
      });
      setclientesData(arrayMap);
      setclientesDataDefault(arrayMap);

      setfiltrando(false);
    }
  };
  // eslint-disable-next-line
  const {
    aviDirigido,
    // aviLeidos,
    aviAsunto,
    aviFotos,
    // aviFechasAvisoConsultaDB,
    aviOpcion,
    aviDetalle,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    aviAsunto: false,
    aviDetalle: false,
    aviOpcion: false,
    aviDirigido: false,
    aviFotos: false,
  });

  useEffect(() => {
    getClientesData(ObjectoActivo.aviOpcion);
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({
      aviAsunto: false,
      aviDetalle: false,
      aviOpcion: false,
      aviDirigido: false,
      aviFotos: false,
    });
    setclientesData([]);
    setclientesDataDefault([]);
    setfiltro("");
    setfiltrando(false);
    setimagenes([]);
    setmodificoImagenes(false);
    setcargando(false);
    setseccionActive({
      DatosPrincipales: true,
      DatosArchivos: false,
      DatosFecha: false,
    });
    setFocusedDate(null);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  const [seccionActive, setseccionActive] = useState({
    DatosPrincipales: true,
    DatosArchivos: false,
    DatosFecha: false,
  });
  const { DatosPrincipales, DatosArchivos, DatosFecha } = seccionActive;

  // const validacion = () => {
  //   if (
  //     aviAsunto.trim() === "" ||
  //     aviOpcion.trim() === "" ||
  //     aviDirigido.length === 0 ||
  //     aviFechasAvisoConsultaDB.length === 0
  //   ) {
  //     seterror({
  //       aviAsunto: aviAsunto.trim() === "" ? true : false,
  //       aviOpcion: aviOpcion.trim() === "" ? true : false,
  //       aviDirigido: aviDirigido.length === 0 ? true : false,
  //       aviFechasAvisoConsultaDB:
  //         aviFechasAvisoConsultaDB.length === 0 ? true : false,
  //     });
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // eslint-disable-next-line
  const [openCollapse, setopenCollapse] = useState(false);
  const {
    setmodificoImagenes,
    // subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  // eslint-disable-next-line
  // const handleCrear = async () => {
  //   if (validacion()) {
  //     return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
  //   }
  //   try {
  //     setcargando(true);

  //     const resDocumentos = await subirImagenes(imagenes, aviFotos);

  //     socket.current.emit(
  //       "client:guardarData",
  //       trimPropsItem({
  //         ...item,
  //         aviFotos: resDocumentos,
  //         rucempresa: usuario.rucempresa,
  //         rol: usuario.rol,
  //         aviUser: usuario.usuario,
  //         aviEmpresa: usuario.rucempresa,
  //         tabla: "aviso",
  //       })
  //     );
  //     setcargando(false);
  //     return defaultActive();
  //   } catch (error) {
  //     setcargando(false);

  //     mostrarAlerta("Hubo un error", "error");
  //     return defaultActive();
  //   }
  // };
  // const handleEditar = async () => {
  //   if (validacion()) {
  //     return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
  //   }
  //   try {
  //     setcargando(true);

  //     const resDocumentos = await subirImagenes(imagenes, aviFotos);

  //     socket.current.emit(
  //       "client:actualizarData",
  //       trimPropsItem({
  //         ...item,
  //         //params
  //         aviFotos: resDocumentos,
  //         rucempresa: usuario.rucempresa,
  //         rol: usuario.rol,
  //         aviUser: usuario.usuario,
  //         aviEmpresa: usuario.rucempresa,
  //         tabla: "aviso",
  //       })
  //     );
  //     setcargando(false);
  //     return defaultActive();
  //   } catch (error) {
  //     setcargando(false);

  //     mostrarAlerta("Hubo un error", "error");
  //     return defaultActive();
  //   }
  // };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Comunicado.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  {" "}
                  {/* <FormControlLabel
                  control={
                    <Switch
                      checked={
                        comEstado === "ACTIVO" || comEstado === "ACTIVA"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setitem({
                          ...item,
                          comEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                    />
                  }
                  label="Estado"
                /> */}
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />{" "}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                {" "}
                <Box>
                  <Button
                    variant={DatosPrincipales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={DatosFecha ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.aviFechasAvisoConsultaDB ? "error" : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosFecha: true })}
                  >
                    Fechas
                  </Button>
                  <Button
                    variant={DatosArchivos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosArchivos: true })}
                  >
                    Datos Archivos
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                {" "}
                <Fade
                  direction="up"
                  in={DatosPrincipales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosPrincipales ? (
                      <>
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="aviAsunto"
                          label="Asunto"
                          value={aviAsunto}
                          name="aviAsunto"
                          error={error.aviAsunto}
                          onChange={(e) => handleChange(e)}
                        />{" "}
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="aviOpcion"
                          label="Opcion"
                          value={aviOpcion}
                          name="aviOpcion"
                          error={error.aviOpcion}
                          onChange={async (e) => {
                            await getClientesData(e.target.value);
                          }}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {[
                            "CLIENTE",
                            "GUARDIAS",
                            "SUPERVISORES",
                            "ADMINISTRACION",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          multiline
                          rows={3}
                          variant={"outlined"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="aviDetalle"
                          label="Detalle"
                          value={aviDetalle}
                          name="aviDetalle"
                          error={error.aviDetalle}
                          onChange={(e) => handleChange(e)}
                        />{" "}
                        <Box className={clases.formAllW}>
                          {error.aviDirigido ? (
                            <Typography
                              variant="caption"
                              color="error"
                              fontWeight="bold"
                            >
                              Seleccione un Dirigido.
                            </Typography>
                          ) : null}
                          <ListItem>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    !clientesData.some((itemcliente) => {
                                      //si encuentra iguales es por que existen, se revierten para que sean false y no se cumpla el some de clientes data,
                                      // si da false en redirigido dara true, y terminando e lsome de clientes data
                                      //  y se pondra en  false el checked ya que no existe un registro
                                      const noExiste = !aviDirigido.some(
                                        (itemDirigido) => {
                                          return (
                                            itemcliente.id === itemDirigido.id
                                          );
                                        }
                                      );

                                      // DevuelveTrueNecesitoColocarloFalse

                                      return noExiste;
                                    })
                                  }
                                  onChange={(e) => {
                                    if (
                                      !clientesData.some((itemcliente) => {
                                        const noExiste = !aviDirigido.some(
                                          (itemDirigido) => {
                                            return (
                                              itemcliente.id === itemDirigido.id
                                            );
                                          }
                                        );

                                        // DevuelveTrueNecesitoColocarloFalse

                                        return noExiste;
                                      })
                                    ) {
                                      //   ...item,
                                      //   aviDirigido: [],
                                      // });
                                      setitem({
                                        ...item,
                                        aviDirigido: [],
                                      });
                                    } else {
                                      //   ...item,
                                      //   aviDirigido: clientesData,
                                      // });
                                      setitem({
                                        ...item,
                                        aviDirigido: clientesData,
                                      });
                                    }
                                  }}
                                />
                              }
                            />
                            <ListItemText
                              primary="Todos"
                              style={{ marginRight: "1rem" }}
                            />
                            <Box
                              display={"flex"}
                              width="100%"
                              alignItems="center"
                              height={"100%"}
                            >
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                size="small"
                                label="Filtrar"
                                value={filtro}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment>
                                      {filtrando ? (
                                        <IconButton
                                          aria-label=""
                                          onClick={() => {
                                            setfiltrando(false);
                                            setfiltro("");
                                            setclientesData(
                                              clientesDataDefault
                                            );
                                          }}
                                        >
                                          <Backspace color="error" />
                                        </IconButton>
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    setfiltrando(false);
                                    setfiltro("");
                                    return setclientesData(clientesDataDefault);
                                  }
                                  setfiltrando(true);
                                  setfiltro(e.target.value);
                                  const res = clientesDataDefault.filter(
                                    (itemCliente) =>
                                      itemCliente.nombres
                                        .toLowerCase()
                                        .includes(
                                          e.target.value.toLowerCase()
                                        ) ||
                                      itemCliente.docnumero
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase())
                                  );
                                  setclientesData(res);
                                }}
                              />{" "}
                            </Box>
                            <IconButton
                              aria-label=""
                              onClick={() => setopenCollapse(!openCollapse)}
                            >
                              {openCollapse ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </ListItem>
                          <Divider />
                          <Collapse
                            in={openCollapse}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List dense component="div" disablePadding>
                              {[...clientesData].map((itemCliente) => {
                                return (
                                  <ListItem
                                    style={{ paddingLeft: "1rem" }}
                                    key={itemCliente.id}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={aviDirigido.some(
                                            (itemDirigido) => {
                                              return (
                                                itemDirigido.id ===
                                                itemCliente.id
                                              );
                                            }
                                          )}
                                          onChange={(e) => {
                                            if (
                                              aviDirigido.some(
                                                (itemDirigido) => {
                                                  //   itemDirigido.id ===
                                                  //     itemCliente.id
                                                  // );
                                                  return (
                                                    itemDirigido.id ===
                                                    itemCliente.id
                                                  );
                                                }
                                              )
                                            ) {
                                              //   ...item,
                                              //   aviDirigido: aviDirigido.filter(
                                              //     (itemDirigido) => {
                                              //       return (
                                              //         itemDirigido.id !==
                                              //         itemCliente.id
                                              //       );
                                              //     }
                                              //   ),
                                              // });

                                              setitem({
                                                ...item,
                                                aviDirigido: aviDirigido.filter(
                                                  (itemDirigido) => {
                                                    return (
                                                      itemDirigido.id !==
                                                      itemCliente.id
                                                    );
                                                  }
                                                ),
                                              });
                                            } else {
                                              const nuevoArray = [
                                                ...aviDirigido,
                                                itemCliente,
                                              ];

                                              //   ...item,
                                              //   aviDirigido: nuevoArray,
                                              // });
                                              setitem({
                                                ...item,
                                                aviDirigido: nuevoArray,
                                              });
                                            }
                                          }}
                                        />
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        itemCliente.nombres +
                                        " ** " +
                                        itemCliente.docnumero
                                      }
                                    />
                                    <Avatar
                                      alt={`Avatar: ${itemCliente.nombres}`}
                                      src={itemCliente.cliFoto}
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                        </Box>
                        {/* aviDirigido */}
                        {/* <DropzoneArea
                      open={false}
                      maxFileSize={5000000}
                      dropzoneText={
                        <>
                          <Typography variant="h4" color="primary">
                            Arrastra una imagen o da click aqui.
                          </Typography>{" "}
                          {editar ? (
                            <Typography variant="h5" color="error"> 
                            </Typography>
                          ) : null}
                        </>
                      }
                      acceptedFiles={[
                        "image/jpeg",
                        "image/png",
                        "image/bmp",
                        "image/jpg",
                      ]}
                      onChange={(e) => {
                         
                        setimagenes(e);
                      }}
                    /> */}
                      </>
                    ) : null}
                  </Box>
                </Fade>
                <Fade direction="up" in={DatosFecha} style={{ width: "100%" }}>
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosFecha ? (
                      <>
                        <Calendario
                          propiedad={item.aviFechasAviso}
                          propiedadDB={item.aviFechasAvisoConsultaDB}
                          focusedDate={focusedDate}
                          setFocusedDate={setFocusedDate}
                          item={item}
                          setitem={(e) => {
                            setitem(e);
                          }}
                          TextoPropiedad={"aviFechasAviso"}
                          textoPropiedadDB={"aviFechasAvisoConsultaDB"}
                          openCalendario={openCalendario}
                          setopenCalendario={setopenCalendario}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>
                <Fade
                  direction="up"
                  in={DatosArchivos}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosArchivos ? (
                      <>
                        <CampoDoc
                          cargando={cargando}
                          setcargando={setcargando}
                          propiedad={"aviDocumento"}
                          item={item}
                          setitem={setitem}
                          celda={false}
                        />
                        <CampoDoc
                          label="Documento 2"
                          setcargando={setcargando}
                          propiedad={"aviDocumento2"}
                          item={item}
                          setitem={setitem}
                          celda={false}
                        />

                        <Dropzone
                          imagenes={imagenes}
                          setimagenes={(e) => {
                            setmodificoImagenes(true);
                            setimagenes(e);
                          }}
                          fotosItem={aviFotos}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                {/* <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    (!(item.aviUser === usuario.usuario) && editar) || cargando
                  }
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button> */}
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAvisos);
