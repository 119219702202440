import { createContext, useContext, useState } from "react";

// import { Socket } from "socket.io-client";
import { useEffect } from "react";
import { useSocket } from "../socket/useSocket";
import AuthContext from "../../../Context/Auth/AuthContext";

export const SocketContext = createContext({
  socket: null,
  online: false,
});

export const SocketProvider = ({ children }) => {
  const { socket, online, conectarSocket, desconectarSocket } = useSocket(
    "https://backsafe.neitor.com/"
  );
  const authInfo = useContext(AuthContext);

  // const { status } = useAuthStore();

  // useEffect(() => {
  //   if (authInfo?.autenticado) {

  //   }
  // }, [authInfo?.autenticado]);
  const [traerGruposEffect, settraerGruposEffect] = useState(0);
  useEffect(() => {
    if (!authInfo?.autenticado) {
      desconectarSocket();
    }
  }, [authInfo?.autenticado]);
  useEffect(() => {
    if (authInfo.usuario?.token && authInfo?.autenticado) {
      desconectarSocket();
      conectarSocket(localStorage.getItem("token"));
      settraerGruposEffect(traerGruposEffect + 1);
    }
  }, [authInfo.usuario?.token]);

  useEffect(() => {
    socket?.on("lista-usuarios", (data) => {
      // onCargarUsuarios(data);
    });
  }, [socket]);
  useEffect(() => {
    socket?.on("mensaje-personal", (mensaje) => {
      // onAddMessage(mensaje);
    });
  }, [socket]);
  return (
    <SocketContext.Provider
      value={{
        socket,
        online,
        desconectarSocket,
        conectarSocket,
        traerGruposEffect,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
