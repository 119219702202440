import React, { memo, useContext, useState } from "react";

import { withRouter } from "react-router";
import { tienePermisoRuta } from "../../../../config/validaciones";
import { permisoDenagado } from "../../../../config/const";
import ButtonsActionsDialog from "../ButtonsActionsDialog";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  // PictureAsPdf,
  Delete,
  PictureAsPdf,
} from "@mui/icons-material";
import clienteAxios from "../../../../config/axios";
// import clienteAxios from "../../../../config/axios";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    setopenConfirmDialog,
    funcionEdit,
    ocultarEditar,
    ocultarDelete,
    mostrarEstado,
    campoEstado,
    editar,
    funcCancel,
    funcSubmit,
    botonDisabled,
    setopenModalEmail,
    itemExport,
    setitemExport,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [open, setopen] = useState(false);
  return (
    <>
      <ButtonsActionsDialog
        open={open}
        setopen={setopen}
        setopenConfirmDialog={setopenConfirmDialog}
        row={row}
        setopenModalEmail={setopenModalEmail}
      />
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  disabled={itemExport.padre === ""}
                  className={classes.margin}
                  checked={itemExport.hijos.some((item) => {
                    return item === row[campoExport];
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const itemDeEsteRow = {
                        ...itemExport,
                        hijos: [...itemExport.hijos, row[campoExport]],
                      };
                      setarrayExport(
                        arrayExport.map((itemArrayExport) => {
                          if (itemArrayExport.padre === itemExport.padre) {
                            return itemDeEsteRow;
                          } else {
                            return itemArrayExport;
                          }
                        })
                      );
                      setitemExport(itemDeEsteRow);
                    } else {
                      const quedaVacio =
                        itemExport.hijos.filter(
                          (itemLista) => itemLista !== row[campoExport]
                        ).length === 0;
                      if (quedaVacio) {
                        const res = arrayExport.filter((itemArrayExport) => {
                          return itemArrayExport.padre !== itemExport.padre;
                        });

                        setitemExport({
                          padre: "",
                          hijos: [],
                        });
                        setarrayExport(res);
                      } else {
                        const itemDeEsteRow = {
                          ...itemExport,
                          hijos: itemExport.hijos.filter(
                            (itemLista) => itemLista !== row[campoExport]
                          ),
                        };
                        const res = arrayExport.map((itemArrayExport) => {
                          if (itemArrayExport.padre === itemExport.padre) {
                            return itemDeEsteRow;
                          } else {
                            return itemArrayExport;
                          }
                        });

                        setitemExport(itemDeEsteRow);
                        setarrayExport(res);
                      }
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          ml={1}
          mr={1}
        >
          <>
            {editar ? (
              <>
                <Tooltip title="Cancelar">
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      funcCancel();
                    }}
                  >
                    <Cancel fontSize="small" color="secondary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Confirmar">
                  <IconButton
                    className={classes.margin}
                    disabled={botonDisabled ? botonDisabled() : false}
                    onClick={() => {
                      funcSubmit();
                    }}
                  >
                    <Check
                      fontSize="small"
                      color={
                        botonDisabled
                          ? botonDisabled()
                            ? "inherit"
                            : "secondary"
                          : "secondary"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                {ocultarEditar ? null : (
                  <Tooltip title="Editar">
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        if (
                          !tienePermisoRuta(
                            rows,
                            props.location.pathname,
                            usuario.rol,
                            "editar"
                          )
                        ) {
                          return mostrarAlerta(permisoDenagado, "error");
                        }
                        funcionEdit();
                      }}
                    >
                      <Create color="secondary" />
                    </IconButton>
                  </Tooltip>
                )}
                {/* <Tooltip title="PDF">
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      window.open(
                        `${clienteAxios.defaults.baseURL}reportes/informe?infId=${row.infId}&rucempresa=${row.infEmpresa}`
                      );
                    }}
                  >
                    <PictureAsPdf color="error" />
                  </IconButton>
                </Tooltip> */}

                {ocultarDelete ? null : (
                  <Tooltip title="Eliminar" arrow>
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        setopenConfirmDialog(true);
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </>
          <Tooltip title="PDF">
            <IconButton
              className={classes.margin}
              onClick={() => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/cambiopuestoindividual?camId=${row.camId}&rucempresa=${row.camEmpresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              }}
            >
              <PictureAsPdf color="error" />
            </IconButton>
          </Tooltip>

          {mostrarEstado ? (
            <Tooltip title="Estado">
              <FormControlLabel
                className={classes.margin}
                control={
                  <Checkbox
                    value="ACTIVO"
                    className={classes.margin}
                    checked={
                      row[campoEstado] === "ACTIVA" ||
                      row[campoEstado] === "ACTIVO"
                        ? true
                        : false
                    }
                    color="primary"
                  />
                }
              />
            </Tooltip>
          ) : null}
          <Box display="flex" alignItems={"center"}>
            {/* <Tooltip title="Leidos">
              <IconButton
                aria-label=""
                className={classes.margin}
                onClick={() => setopenDialog(true)}
              >
                <FormatListNumbered fontSize="small" />
              </IconButton>
            </Tooltip> */}
            {/* {row.conLeidos.length === 0 ? null : (
              <Badge
                badgeContent={row.conLeidos.length}
                color="primary"
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                {row.conLeidos.length}
              </Badge>
            )} */}
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
