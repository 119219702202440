import React, { memo, useContext } from "react";

import { Collapse, TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import {
  Cancel,
  DeleteForever,
  Email,
  ExpandLess,
  ExpandMore, 
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import Hijo from "./Hijo/Hijo";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext"; 
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // KEY
    // key,
    index,
    // EDIT Y OPEN
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // DATA
    rowPadre,
    estadoRequest,
    // FUNCIONES DATA
    getDataState,
    changeObjetoActivoState,
    eliminarState,
    // SOCKET
    tabla,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowPadre.editado === true
      ? classes.celdaEditado
      : rowPadre.nuevo === true
      ? classes.celdaNuevo
      : rowPadre.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  // const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [cargandoBotonesConfirmacion, setcargandoBotonesConfirmacion] =
    useState(false);
  const [cargado, setcargado] = useState(false);
  const [expandir, setexpandir] = useState(false);
  // ELIMINAR EL REGISTRO

  const funcionProcesar = async () => {
    try {
      setcargandoBotonesConfirmacion(true);
      const res = await clienteAxios.post("/rolpagos/procesar", {
        rolpPeriodo: rowPadre.rolpPeriodo,
        rolpIdCliente: rowPadre.rolpIdCliente,
        option: estadoRequest === "EN PROCESO" ? "1" : "2",
      });

      setopenConfirmDialog(false);
      mostrarAlerta(res.data.msg, "success");
      eliminarState(rowPadre.rolpId);
      setcargandoBotonesConfirmacion(false);
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  if (rowPadre.eliminado) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿${
          estadoRequest === "EN PROCESO"
            ? "Esta seguro de PROCESAR roles de este cliente:"
            : "Esta seguro que desea volver a ejecutar un rol de este cliente:"
        } ${rowPadre.rolpDocNumCliente + " " + rowPadre.rolpNomCliente}?`}
        botones={[
          {
            tooltip: "Se procesaran los registros",
            texto: "SI",
            funcion: () => {
              funcionProcesar();
            },
            disabled: cargandoBotonesConfirmacion,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: cargandoBotonesConfirmacion,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          agregarCelda={false}
          row={rowPadre}
          botones={[
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setcargado(true);
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: rowPadre.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },

            {
              tooltip: "Enviar Email",
              texto: "",
              funcion: async () => {
                try {
                  const res = await clienteAxios.post(`rolpagos/enviarcorreo`, {
                    option: "masivo",
                    rolpIdPersona: rowPadre.rolpIdPersona,
                    rolpPeriodo: rowPadre.rolpPeriodo,
                    rolpDocNumCliente: rowPadre.rolpDocNumCliente,
                  });
                  mostrarAlerta(res.data.data);
                } catch (error) {
                  mostrarAlerta("Hubo un error");
                }
              },
              disabled: false,
              Icon: Email,
              color: "primary",
              id: 1,
              ocultar: rowPadre.eliminado || estadoRequest !== "PROCESADO",
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },


          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowPadre.rolpPeriodo.substr(0, 7)}
          </TableCell>
 

        </>
      </TableRow>
      <TableRow>
        <TableCell
          className={claseCelda}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
        > 
          <Collapse in={expandir} timeout="auto">
            {cargado ? (
              <Hijo
                // ROW
                rowPadre={rowPadre}
                // OPEN Y EDIT
                setopen={setopen}
                seteditar={seteditar}
                // EXPORT
                campoExport={campoExport}
                arrayExport={arrayExport}
                setarrayExport={setarrayExport}
                // DATA
                expandir={expandir}
                tabla={tabla}
                estadoRequest={estadoRequest}
                // FUNCION STATE
                getDataState={getDataState}
                changeObjetoActivoState={changeObjetoActivoState}
                eliminarState={eliminarState}
                // SOCKET
                socket={socket}
              />
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
