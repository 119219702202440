import { blueGrey, blue } from "@mui/material/colors";

import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    secondary: {
      main: blueGrey[900],
    },
    primary: {
      main: "#0076A7",
      dark: blue[800],
    },
  },
  typography: {
    fontFamily: ["Prompt"],
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

export default theme;
