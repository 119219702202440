import React, { useState, useContext, memo } from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../Context/Alerta/AlertaContext";

// MATERIAL 
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField, 
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import { useModalStyle } from "../styles/styleModal";
import clienteAxios from "../../config/axios";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  //props
  const { item, setitem, open, setopen, endpoint } = props;

  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line
  const {
    // cliId
    // rucempresa
    hoja,
    cantidad,
    ancho,
    alto,
  } = item;
  // eslint-disable-next-line
  const [error, seterror] = useState({});

  const defaultActive = () => {
    setopen(false);
    setitem({
      cliId: "",
      rucempresa: "",
      hoja: "A4",
      cantidad: "1",
      ancho: "95",
      alto: "95",
    });
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  // eslint-disable-next-line
  const handleCrear = async () => {
    if (
      hoja.trim() === "" ||
      cantidad.trim() === "" ||
      ancho.trim() === "" ||
      alto.trim() === ""
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      return seterror({
        hoja: hoja.trim() === "" ? true : false,
        cantidad: cantidad.trim() === "" ? true : false,
        ancho: ancho.trim() === "" ? true : false,
        alto: alto.trim() === "" ? true : false,
      });
    }
    try {
      setcargando(true);
      //jback.neitor.com/api/reportes/qractividad?act_asigId=23&rucempresa=PRUEBA&hoja=A4&cantidad=1&ancho=95&alto=95
      window.open(`${clienteAxios.defaults.baseURL}${endpoint}`);
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

 

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Generar QR.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />{" "}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box> </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                <>
                  <TextField
                    className={clases.formD}
                    size="small"
                    label="Hoja"
                    value={hoja}
                    name="hoja"
                    error={error.hoja}
                    onChange={async (e) => {
                      handleChange(e);
                    }}
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">Seleccione.</MenuItem>
                    {[
                      "A4",
                      "A5",
                      "A6",
                      "A7",
                      "A8",
                      "A9",
                      "Legal",
                      "Letter",
                    ].map((item) => {
                      return <MenuItem value={item}>{item} </MenuItem>;
                    })}
                  </TextField>
                  <TextField
                    type={"number"}
                    className={clases.formD}
                    size="small"
                    label="Cantidad"
                    value={cantidad}
                    name="cantidad"
                    onChange={async (e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    type={"number"}
                    className={clases.formD}
                    size="small"
                    label="Ancho"
                    value={ancho}
                    name="ancho"
                    onChange={async (e) => {
                      handleChange(e);
                    }}
                  />
                  <TextField
                    type={"number"}
                    className={clases.formD}
                    size="small"
                    label="Alto"
                    value={alto}
                    name="alto"
                    onChange={async (e) => {
                      handleChange(e);
                    }}
                  />
                </>
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={cargando}
                  onClick={() => {
                    handleCrear();
                  }}
                >
                  Generar QR
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
