import React, { memo, useContext } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { usePermiso } from "../../hooks/usePermiso";
import CollapseCell from "../../components/CollapseCell";
import { trimPropsItem } from "../../../config/const";
import clienteAxios from "../../../config/axios";
import { saveAs } from "save-as";
import CeldaUser from "../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    tabla,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("GOP");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // CELDA CONTRAIDA
  const [celComprimida, setcelComprimida] = useState({});
  const [openDialogLeidos, setopenDialogLeidos] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        gestUser: usuario.usuario,
        gestEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          gestUser: usuario.usuario + "/+/" + row[campoExport],
          gestEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {openDialogLeidos ? (
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xl"
          open={openDialogLeidos}
          onClose={() => setopenDialogLeidos(false)}
        >
          <DialogTitle> Leidos</DialogTitle>
          <DialogContent dividers>
            {row.conLeidos.map((item) => {
              return `${item.nombres}: ${item.fecha} - ${item.rol.join(" - ")}`;
            })}
          </DialogContent>
        </Dialog>
      ) : null}

      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.gestUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: async () => {
                const res = await clienteAxios.get(
                  `${
                    clienteAxios.defaults.baseURL
                  }reportes/gestionoperativa?gestId=${row.gestId}&rucempresa=${
                    row.gestEmpresa
                  }&usuario=${
                    row.gestUser.split("T")[0]
                  }&nombre=${encodeURIComponent(usuario.nombre)}`,
                  { responseType: "blob" }
                );
                const pdfBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                saveAs(pdfBlob, `${tabla}.pdf`);
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 3,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="lkeft" className={claseCelda}>
            {row.gestCliDocNumero + " " + row.gestCliRazonSocial}
          </TableCell>
          <TableCell size="small" align="lkeft" className={claseCelda}>
            {row.gestFechaInicio}
          </TableCell>
          <TableCell size="small" align="lkeft" className={claseCelda}>
            {row.gestAsunto}
          </TableCell>
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"gestAntecedentes"}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {row.gestFechaDesde}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.gestFechaHasta}
          </TableCell>
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"gestConclusiones"}
          />
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"gestRecomendaciones"}
          />

         
          <CeldaUser user={row.gestUser} claseCelda={claseCelda} />
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.gestFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
