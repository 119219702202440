export const columns = [
  {
    id: "act_asigPerDocApeNom",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "act_asigPerDocApeNom",
    align: "left",
    ocultar: true,
  },
  {
    id: "act_asigFecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "act_asigFecha",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "act_asigEveTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "act_asigEveTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "act_asigEveNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "act_asigEveNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "act_asigFecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "act_asigFecha",
    align: "center",
    ocultar: true,
  },
  {
    id: "act_asigEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "act_asigEstado",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  act_asigId: "",
  act_asigPerId: "",
  act_asigEveId: "",
  act_asigEstado: "",
  act_asigFecha: "",
  act_asigTurno: {},
  act_asigHorario: [],
  act_asigPerPerfil: [],
  act_asigTrabajos: [],
  act_asigFecReg: "",
  act_asigFecUpd: "",
  act_asigEveTipo: "",
  act_asigEveNombre: "",
  act_asigPerApeNom: "",
};
// {
//   act_asigId: 2,
//   actEveId: 0,
//   actEveTipo: "",
//   actEveDatos: {
//     eveId: "",
//     eveTipo: "",
//     eveNombre: "",
//     eveActividades: [],
//     eveFecReg: "",
//     eveFecUpd: "",
//     eveEmpresa: "",
//     eveUser: "",
//     nombre: "",
//   },
//   actEstado: "ACTIVA",
//   actGuardias: {},
//   actSupervisores: {},
//   actAdministracion: {},
//   actFechasActividad: [],
//   actFechasEvento: {},
//   actFechasActividadConsultaDB: [],
//   actObservacion: "",
//   act_asigFecReg: "2022-08-23T18:01:57.000Z",
//   actFecUpd: "2023-05-09T16:57:00.000Z",
//   actIdCliente: "",
//   act_asigPerId: "",
//   actNombreCliente: "",
//   actDirigido: "",
//   actFotosActividad: [],
//   actLeidos: [],
//   actPrioridad: "MEDIA",
//   actDocumento: "",
//   actUser: "admin",
//   actEmpresa: "PRUEBA",
// };
