import React, { memo, useContext, useMemo } from "react";

import {
  Badge,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import {
  AddModerator,
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Person,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import TablaCabecera from "../../components/TablaCabecera";
import ToolBarTable from "../../components/ToolBarTable";
import clienteAxios from "../../../config/axios";
import { objectDefault as objectDefaultPermiso } from "../data";
import { objectDefault as objectDefaultTurno } from "../../TurnosExtras/data";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { FechaExacta } from "../../../config/const";
const RowPersona = ({
  item,
  index,
  ObjectoActivo,
  setObjetoActivo,
  setopen,
  permisos,
  turnos,
  horarioSeleccionado,
}) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } `;

  const { mostrarAlerta } = useContext(AlertaContext);
  const [editar, seteditar] = useState(false);
  const [itemHijoEdit, setitemHijoEdit] = useState(item);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [diasSeleccionados, setdiasSeleccionados] = useState({});
  //onChange
  const handleChange = (e) => {
    setitemHijoEdit({ ...item, [e.target.name]: e.target.value });
  };
  const [expandido, setexpandido] = useState(false);
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (false) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
    } catch (error) {}
  };
  const funcEliminar = () => {
    try {
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        seteditar(true);
        setitemHijoEdit(item);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Ver horario",
      texto: "",
      funcion: () => {
        setexpandido(!expandido);
      },
      disabled: false,
      Icon: expandido ? ExpandLess : ExpandMore,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijoEdit(item);
        // setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const columnasGeneradas = useMemo(() => {
    if (!item?.year_month) {
      return [];
    }
    const anio = item?.year_month.split("-")[0];
    const mes = item?.year_month.split("-")[1];
    const diasDelMes = new Date(anio, mes, 0).getDate();
    const fechas = [];
    for (let dia = 1; dia <= diasDelMes; dia++) {
      const fecha = new Date(anio, mes - 1, dia);
      const fechaString =
        Date.parse(fecha) + new Date().getTimezoneOffset() * 60 * 1000;
      fechas.push(fechaString);
    }

    return fechas.map((item, index) => ({
      id: index + 1,
      label: new Date(item).toLocaleString("es-ES", {
        weekday: "short",
        day: "2-digit",
      }),
      dia: true,
      minWidth: 50,
      tipo: "string",
      orden: 1,
      propiedad: index + 1,
      align: "center",
      ocultar: true,
    }));
  }, [item?.year_month]);
  const [horarioActivo, sethorarioActivo] = useState(null);
  const getHorarioActivo = async (id) => {
    const res = await clienteAxios(`/horarios/${id}`);
    sethorarioActivo(res.data);
  };

  return (
    <>
      {horarioActivo ? (
        <Dialog
          maxWidth="xl"
          open={horarioActivo}
          onClose={() => sethorarioActivo(null)}
        >
          <DialogTitle
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            {horarioActivo.codigo} - {horarioActivo.tipo}
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            Hora : {horarioActivo.hora_inicio} - {horarioActivo.hora_final}
          </DialogContent>
        </Dialog>
      ) : null}
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ConfirmacionDialog
          open={openConfirmDialog}
          setopen={setopenConfirmDialog}
          categoria="warning"
          titulo={`¿Esta seguro de eliminar este registro?`}
          botones={[
            {
              tooltip: "Se eliminaran los registros",
              texto: "SI",
              funcion: () => {
                funcEliminar();
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Cancelar",
              texto: "NO",
              funcion: () => {
                setopenConfirmDialog(false);
              },
              disabled: false,
              Icon: Cancel,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <ButtonsAction
          claseCelda={claseCelda}
          botones={editar ? botonesEditar : botones}
        />
        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                autoFocus
                value={itemHijoEdit.metodo}
                name="metodo"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={itemHijoEdit.porque}
                name="porque"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.year_month}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.razon_social}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.puesto}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.ubicacion}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ margin: 0, padding: 0 }}
          colSpan={21}
          padding="none"
        >
          <Collapse in={expandido} timeout="auto" unmountOnExit>
            <Paper
              // className={claseCelda}
              sx={{
                width: "100%",
                background: (theme) => theme.palette.secondary.dark,
              }}
            >
              <Box sx={{ padding: "0rem 1rem" }}>
                <ToolBarTable
                  ocultarPaginacion={true}
                  botonesIzquierdos={[
                    {
                      tooltip: "Detalles Horario",
                      texto: "Horario",
                      funcion: () => {},
                      disabled: false,
                      Icon: React.Fragment,
                      color: "primary",
                      id: 1,
                      ocultar: false,
                      tipo: "boton",
                      variante: "text",
                      size: "medium",
                      sx: {},
                    },
                  ]}
                  botonesDerechos={[]}
                />
                <TableContainer>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TablaCabecera
                        columns={columnasGeneradas}
                        habilitarOrdenar={false}
                      />
                    </TableHead>

                    <TableBody className={claseCelda} component={"div"}>
                      <TableRow
                        className={classes.root}
                        component={"form"}
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                        sx={{
                          padding: "1rem",
                        }}
                      >
                        <ButtonsAction
                          claseCelda={claseCelda}
                          botones={[
                            {
                              tooltip: permisos
                                ? "Crear Permiso"
                                : "Crear Turno Extra",
                              texto: "",
                              funcion: async () => {
                                if (turnos && horarioSeleccionado.id === 0) {
                                  return mostrarAlerta(
                                    "Seleccione un horario",
                                    "error"
                                  );
                                }
                                if (permisos) {
                                  try {
                                    const res = await clienteAxios.post(
                                      "/horarios_jornada_laboral/dias-habiles-permiso",
                                      {
                                        dias: item.horarios
                                          .filter(
                                            (horarioHijo) =>
                                              diasSeleccionados[horarioHijo.id]
                                          )
                                          .map(({ id }) => id),
                                      }
                                    );
                                    setObjetoActivo({
                                      ...objectDefaultPermiso,
                                      // permiId: 1,
                                      permiIdPersona: item.id_persona,
                                      permiIdDOperativo: item.id_dOperativo,
                                      cedula: item.perDocNumero,
                                      nombres: item.perNombre,
                                      dias: item.horarios.filter(
                                        (horarioHijo) =>
                                          diasSeleccionados[horarioHijo.id] &&
                                          horarioHijo.estado !== "LIBRE"
                                      ),
                                      idCliente: item.id_cliente,
                                      ruc: item.ruc,
                                      razonSocial: item.razon_social,
                                      ubicacion: item.ubicacion,
                                      puesto: item.puesto,
                                    });
                                    setopen(true);
                                  } catch (error) {
                                    console.log({ error });
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                  }
                                }

                                if (turnos) {
                                  try {
                                    await clienteAxios.post(
                                      "/horarios_jornada_laboral/dias-libres",
                                      {
                                        turnIdDOperativo: item.id_dOperativo,
                                        turnIdPersona: item.id_persona,
                                        dias: item.horarios
                                          .filter(
                                            (horarioHijo) =>
                                              diasSeleccionados[horarioHijo.id]
                                          )
                                          .map(
                                            ({ fecha_inicio }) => fecha_inicio
                                          ),
                                      }
                                    );
                                    setObjetoActivo({
                                      ...objectDefaultTurno,
                                      // permiId: 1,
                                      turnIdPersona: item.id_persona,
                                      turnIdDOperativo: item.id_dOperativo,
                                      cedula: item.perDocNumero,
                                      nombres: item.perNombre,
                                      dias: item.horarios.filter(
                                        (horarioHijo) =>
                                          diasSeleccionados[horarioHijo.id] &&
                                          horarioHijo.estado !== "LIBRE"
                                      ),
                                      idCliente: item.id_cliente,
                                      ruc: item.ruc,
                                      razonSocial: item.razon_social,
                                      ubicacion: item.ubicacion,
                                      puesto: item.puesto,
                                      turnIdHorario: horarioSeleccionado.id,
                                    });
                                    setopen(true);
                                  } catch (error) {
                                    console.log({ error });
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                  }
                                }
                              },
                              disabled: false,
                              Icon: permisos ? Person : AddModerator,
                              color: "secondary",
                              id: 1,
                              ocultar: false,
                              tipo: "icono",
                              variante: "contained",
                              size: "small",
                              sx: butonIconTransition,
                              submit: true,
                            },
                          ]}
                        />
                        <>
                          {item.horarios?.map(
                            ({
                              codigo,
                              id,
                              color,
                              id_horario,
                              estado,
                              estatus_proceso,
                              fecha_inicio,
                            }) => {
                              return (
                                <TableCell
                                  key={id}
                                  fontSize="small"
                                  align="center"
                                  padding="none"
                                  className={claseCelda}
                                  sx={{
                                    padding: "0 !important",
                                    margin: "0 !important",
                                    background:
                                      horarioActivo?.id === id_horario
                                        ? horarioActivo?.color + " !important"
                                        : "",
                                  }}
                                  onDoubleClick={() =>
                                    getHorarioActivo(id_horario)
                                  }
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <FormLabel
                                      label={codigo}
                                      sx={{
                                        padding: 0,
                                        margin: 0,
                                        fontWeight: "bold",
                                        fontSize: ".8rem",
                                        color,
                                      }}
                                      labelPlacement="top"
                                    >
                                      {codigo}
                                    </FormLabel>{" "}
                                    <Badge
                                      badgeContent={
                                        estado === "TURNO EXTRA"
                                          ? "T"
                                          : estado === "PERMISO"
                                          ? "P"
                                          : null
                                      }
                                      color={
                                        estatus_proceso === "PENDIENTE"
                                          ? "warning"
                                          : "success"
                                      }
                                      sx={{ mr: "25%" }}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                    ></Badge>
                                    <Checkbox
                                      disabled={
                                        (permisos && codigo === "FF") ||
                                        (turnos && codigo !== "FF") ||
                                        estado === "TURNO EXTRA" ||
                                        estado === "PERMISO" ||
                                        Date.parse(fecha_inicio) <
                                          Date.parse(FechaExacta)
                                        // Date.parse("") > Date.Parse(FechaExacta)
                                      }
                                      sx={{
                                        fontSize: ".2rem",
                                        padding: 0,
                                        margin: 0,
                                      }}
                                      checked={Boolean(diasSeleccionados[id])}
                                      onChange={(e) => {
                                        setdiasSeleccionados((prev) => {
                                          if (e.target.checked) {
                                            return { ...prev, [id]: id };
                                          } else {
                                            delete prev[id];
                                            return { ...prev };
                                          }
                                        });
                                      }}
                                    />
                                  </Box>
                                </TableCell>
                              );
                            }
                          )}
                        </>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default withRouter(memo(RowPersona));
