import { OBTENER, EDITAR, CHANGE_ACTIVO } from "../../types";
import { componentes } from "../../routes";
const reducer = (state, action) => {
  switch (action.type) {
    case OBTENER:
      return {
        ...state,
        rows: action.payload
          .map((categoria) => {
            return {
              ...categoria,
              component: componentes[categoria.sidInfo.component],
            };
          })
          .sort((a, b) => {
            return Number(a.sidInfo.orden) - Number(b.sidInfo.orden);
          }),
      };
    case EDITAR:
      return {
        ...state,
        rows: state.rows.map((row) => {
          if (row.sidId === action.payload.sidId) {
            return {
              ...action.payload,
              component: componentes[action.payload.sidInfo.component],
            };
          } else {
            return row;
          }
        }),
      };

    case CHANGE_ACTIVO:
      return {
        ...state,
        categoriaActiva: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
