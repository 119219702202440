export const columns = [
  {
    id: "catNombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    propiedad: "catNombre",
  },
  {
    id: "catTipo",
    label: "Tipo",
    minWidth: 100,
    tipo: "string",
    propiedad: "catTipo",
  },
  {
    id: "catDetalle",
    label: "Detalle",
    minWidth: 200,
    tipo: "string",

    propiedad: "catDetalle",
  },

  {
    id: "catUser",
    label: "Fec. Reg",
    minWidth: 100,
    tipo: "string",

    propiedad: "catUser",
  },
  {
    id: "catFecReg",
    label: "User",
    minWidth: 100,
    tipo: "string",

    propiedad: "catFecReg",
  },
];

export const objectDefault = {
  // catId: 7,
  catNombre: "",
  catTipo: "",
  catDetalle: "",
  catEstado: "ACTIVA",
  catEmpresa: "",
  catUser: "",
  catFecReg: "",
};
// catNombre: "",
// catDetalle: "",
// catEstado: "",
// catEmpresa: "",
// catUser: "admin",
// Todos: "",
// catId
