import { Check, Create, Delete } from "@mui/icons-material";
import {
  ListItem,
  ListItemText,
  IconButton,
  Box,
  TextField,
} from "@mui/material";
import React from "react";
import { useState } from "react";

const ListaItem = ({
  itemLista,
  index,
  itemEdit,
  setItemEdit,
  checksRespuestas,
}) => {
  const [editar, seteditar] = useState(false);
  const [textoEditar, settextoEditar] = useState(itemLista);
  const handleSubmit = () => {
    if (textoEditar.trim() === "") {
      return null;
    }
    setItemEdit({
      ...itemEdit,
      respuestas: itemEdit.respuestas.map(
        (itemOpcionListaMap, indexOpcionListaMap) => {
          if (indexOpcionListaMap === index) {
            return textoEditar;
          } else {
            return itemOpcionListaMap;
          }
        }
      ),
    });
    seteditar(false);
  };
  const handleDelete = () => {
    setItemEdit({
      ...itemEdit,
      respuestas: itemEdit.respuestas.filter(
        (itemOpcionListaMap, indexOpcionListaMap) => {
          return indexOpcionListaMap !== index;
        }
      ),
    });
  };
  return (
    <ListItem sx={{ padding: 0 }} key={index}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        {editar ? (
          <TextField
            autoFocus
            inputProps={{ style: { textAlign: "center" } }}
            value={textoEditar}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            onChange={(e) => {
              settextoEditar(e.target.value);
            }}
            size="small"
            fullWidth
            sx={{ display: "inline-block" }}
          />
        ) : (
          <ListItemText
            primary={index + 1 + " - " + itemLista}
            sx={{ display: "inline-block" }}
          />
        )}
        <Box>
          {checksRespuestas ? null : editar ? (
            <IconButton
              size="small"
              onClick={() => {
                handleSubmit();
              }}
            >
              <Check color="secondary" fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => {
                seteditar(true);
              }}
            >
              <Create color="secondary" fontSize="small" />
            </IconButton>
          )}

          {editar || checksRespuestas ? null : (
            <IconButton
              size="small"
              onClick={() => {
                handleDelete();
              }}
            >
              <Delete color="error" fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </ListItem>
  );
};

export default ListaItem;
