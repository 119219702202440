import { OpenInBrowser } from "@mui/icons-material";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar, 
} from "@mui/material";
import React from "react";

const Municiones = (props) => {
  const { trabajoActivo } = props;

  return (
    <>
      <ImageList variant="masonry" cols={4} gap={3}>
        {[...trabajoActivo.fotos].map((item) => (
          <ImageListItem key={item.url}>
            <img
              src={`${item.url}?w=200&fit=crop&auto=format`}
              srcSet={`${item.url}?w=200&fit=crop&auto=format&dpr=2 2x`}
              alt={item.nombre}
              loading="lazy"
            />
            <ImageListItemBar
              title={"Abrir en el navegador"}
              actionIcon={
                <IconButton
                  onClick={() => window.open(item.url)}
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.nombre}`}
                >
                  <OpenInBrowser />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};

export default Municiones;
