import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "disDocuCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "disDocuCliente",
    align: "left",
    ocultar: true,
  },
];

export const columnsHijosDevolucion = [
  {
    id: "disPersonas",
    label: "Personas",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disPersonas",
    align: "center",
    ocultar: true,
  },

  {
    id: "disFecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "disEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disEstado",
    align: "center",
    ocultar: true,
  },

  {
    id: "disFechaEnvio",
    label: "Fec. Envio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disFechaEnvio",
    align: "center",
    ocultar: true,
  },

  {
    id: "disUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disUser",
    align: "center",
    ocultar: true,
  },
];

export const columnsHijosPedido = [
  {
    id: "disPersonas",
    label: "Personas",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disPersonas",
    align: "center",
    ocultar: true,
  },

  {
    id: "disFecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "disEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disEstado",
    align: "center",
    ocultar: true,
  },

  {
    id: "disFechaEnvio",
    label: "Fec. Envio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disFechaEnvio",
    align: "center",
    ocultar: true,
  },

  {
    id: "disUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disUser",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijosDistribucion = [
  {
    id: "disPersonas",
    label: "Personas/Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disPersonas",
    align: "center",
    ocultar: true,
  },

  {
    id: "disEntrega",
    label: "Entrega",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disEntrega",
    align: "center",
    ocultar: true,
  },

  {
    id: "disTipoDotacion",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disTipoDotacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "disFecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "disUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "disUser",
    align: "center",
    ocultar: true,
  },
];

export const objectDefaultDevolucion = {
  disPuestoCliente: "",
  disTipoDotacion: "",
  // disId: 192,
  disTipo: "DEVOLUCION",
  disDevolucion: false,
  disIdCliente: "",
  disDocuCliente: "",
  disNombreCliente: "",
  disPersonas: [],
  disSupervisores: [],
  disAdministracion: [],
  disEntrega: "",
  disObservacion: "",
  disEstado: "",
  disFechaEnvio: "",
  disDetalle: "",
  disFechaRecibido: "",
  disPedidos: [
    // {
    //   id: 0,
    //   nombre: "",
    //   cantidad: "",
    //   tipo: "",
    //   serie: "",
    //   idProduct: "",
    //   cantidadDevolucion: "",
    //   estadoDevolucion: "",
    // },
  ],
  disUser: "",
  disEmpresa: "",
  disFecReg: "",
  Todos: "",
};
export const objectDefaultPedido = {
  disTipoDotacion: "",
  disPersonas: [],
  disSupervisores: [],
  disAdministracion: [],
  disTipo: "PEDIDO",
  disDevolucion: false,
  disIdCliente: "",
  disDocuCliente: "",
  disNombreCliente: "",
  disEntrega: "",
  disObservacion: "",
  disEstado: "PENDIENTE",
  disFechaEnvio: "",
  disDetalle: "",
  disDocumento: "",
  disFechaRecibido: "",
  disVestimenta: [
    // {
    //   tipo: "VESTIMENTAS",
    //   nombre: "UNIFORMES",
    //   serie: "JDK332",
    //   cantidad: "2",
    //   valor: "10.00",
    //   estadoEquipo: "NUEVO",
    //   marca: "OVIa",
    //   modelo: "OVIN JEAN",
    //   talla: "40",
    //   color: "NEGRO",
    // },
  ],

  disMuniciones: [],
  disUser: "",
  disEmpresa: "",
  disFecReg: "",
  disUbicacionCliente: "",
  disPuestoCliente: "",
};

export const objectDefaultDistribucion = {
  disTipoDotacion: "PUESTO",
  disPersonas: [],
  disSupervisores: [],
  disAdministracion: [],
  disIdCliente: "",
  disDocuCliente: "",
  disNombreCliente: "",
  disFecha: FechaExacta,
  disEntrega: "DOTACIÓN",
  disObservacion: "",
  disTipo: "DISTRIBUCION",
  disDevolucion: false,
  disVestimenta: [
    // {
    //   tipo: "VESTIMENTAS",
    //   nombre: "UNIFORMES",
    //   serie: "JDK332",
    //   cantidad: "2",
    //   valor: "10.00",
    //   estadoEquipo: "NUEVO",
    //   marca: "OVIa",
    //   modelo: "OVIN JEAN",
    //   talla: "40",
    //   color: "NEGRO",
    // },
  ],
  disArmas: [
    // {
    //   tipo: "ARMAS",
    //   nombre: "PISTOLA",
    //   serie: "POK332",
    //   cantidad: "2",
    //   valor: "10.00",
    //   estadoEquipo: "NUEVO",
    //   marca: "OVIN",
    //   modelo: "OVIN JEAN",
    //   tipoArma: "PISTOLA",
    //   calibre: "CAL 22",
    //   color: "NEGRO",
    // },
  ],
  disMuniciones: [],
  disIdDescuento: "",
  disFecReg: "",
  disUser: "",
  disEmpresa: "",
  disUbicacionCliente: "",
  disPuestoCliente: "",
};

export const columnsVestimenta = [
  {
    id: "tipo",
    align: "center",
    minWidth: 1,
    colSpan: 1,
    label: "Foto",
  },
  {
    id: "tipo",
    align: "center",
    minWidth: 10,
    colSpan: 1,
    label: "Bodega",
  },
  {
    id: "tipo",
    align: "center",
    minWidth: 10,
    colSpan: 1,
    label: "Tipo",
  },
  {
    id: "tipo",
    align: "center",
    minWidth: 150,
    colSpan: 1,
    label: "Nombre / Serie",
  },
  {
    id: "tipo",
    align: "center",
    minWidth: 10,
    colSpan: 1,
    label: "talla",
  },
  {
    id: "tipo",
    align: "center",
    minWidth: 10,
    colSpan: 1,
    label: "color",
  },
  {
    id: "valor",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Valor",
  },
];

export const columnsArma = [
  {
    id: "tipo",
    align: "center",
    minWidth: 10,
    colSpan: 1,
    label: "Foto",
  },
  {
    id: "nombre",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Bodega",
  },
  {
    id: "nombre",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "nombre",
  },

  {
    id: "color",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "color",
  },

  {
    id: "valor",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Valor",
  },
];
export const columnsMunicion = [
  {
    id: "tipo",
    align: "center",
    minWidth: 10,
    colSpan: 1,
    label: "Foto",
  },
  {
    id: "tipo",
    align: "center",
    minWidth: 10,
    colSpan: 1,
    label: "Bodega",
  },
  {
    id: "nombre",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "nombre",
  },

  {
    id: "valor",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "Valor",
  },
];
export const columnsDevolucion = [
  {
    id: "cantidadAnterior",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "C. Anterior",
  },

  {
    id: "cantidadDevolucion",
    align: "center",
    minWidth: 50,
    colSpan: 1,
    label: "C. Devolucion",
  },
];

export const columnsCantidades = [
  {
    id: "estado",
    align: "center",
    minWidth: 200,
    tipo: "string",
    label: "estado",
  },
  {
    id: "disponible",
    align: "center",
    minWidth: 200,
    tipo: "string",
    label: "disponible",
  },
  {
    id: "cantidad",
    align: "center",
    minWidth: 200,
    tipo: "string",
    label: "cantidad",
  },
];
