import { Paper, Table, TableBody, TableHead } from "@mui/material";
import React from "react";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import { AddCircle } from "@mui/icons-material";
import RowAgregarMunicion from "./RowAgregarMunicion";
import RowMunicion from "./RowMunicion";
import { columnsMunicion } from "../../data";

const TablaMunicion = (props) => {
  const { setitem, item, bodegaData, ver, devolucion } = props;
  const { disMuniciones } = item;
  const {
    agregandoMunicion,
    setagregandoMunicion,
    pageMunicion,
    // setPageMunicion,
    rowsPerPageMunicion,
    // setRowsPerPageMunicion,
    handleChangePageMunicion,
    handleChangeRowsPerPageMunicion,
  } = useSimpleTable("Municion");
  return (
    <Paper sx={{ width: "100%" }}>
      <ToolBarTable
        count={disMuniciones.length}
        rowsPerPage={rowsPerPageMunicion}
        page={pageMunicion}
        handleChangePage={handleChangePageMunicion}
        handleChangeRowsPerPage={handleChangeRowsPerPageMunicion}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setagregandoMunicion(true);
            },
            disabled: ver || devolucion,
            Icon: AddCircle,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[]}
      />

      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TablaCabecera columns={columnsMunicion} habilitarOrdenar={false} />
        </TableHead>

        <TableBody component={"div"}>
          {agregandoMunicion ? (
            <RowAgregarMunicion
              bodegaData={bodegaData}
              agregar={agregandoMunicion}
              setagregar={setagregandoMunicion}
              setitem={setitem}
              item={item}
            />
          ) : null}
          {disMuniciones
            .slice(
              pageMunicion * rowsPerPageMunicion,
              pageMunicion * rowsPerPageMunicion + rowsPerPageMunicion
            )
            .map((row, index) => {
              return (
                <RowMunicion
                  bodegaData={bodegaData}
                  ver={ver}
                  devolucion={devolucion}
                  item={item}
                  setitem={setitem}
                  row={row}
                  index={index}
                  key={row.idMapeo}
                />
              );
            })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TablaMunicion;
