import React, { memo, useContext } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  FormatListNumbered,
  PictureAsPdf,
  Restore,
  Work,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import ImagenCelda from "../../../components/ImagenCelda";
import clienteAxios from "../../../../config/axios";
import CollapseCell from "../../../components/CollapseCell";
import DocumentoCelda from "../../../components/DocumentoCelda";
import { trimPropsItem } from "../../../../config/const";
import DialogTrabajos from "./Trabajos/Personas/Trabajos/DialogTrabajos";
import { reducer } from "../../../functions/funciones";
import CeldaUser from "../../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  const trabajosCumplidos =
    [...row.conGuardias, ...row.conSupervisores, ...row.conAdministracion]
      .length === 0
      ? null
      : [...row.conGuardias, ...row.conSupervisores, ...row.conAdministracion]
          .map((personaItem) => {
            return Number(personaItem.trabajos.length === 0 ? 0 : 1);
          })
          .reduce(reducer);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Consignas");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // TRABAJOS
  const [openTrabajos, setopenTrabajos] = useState(false);
  // CELDA CONTRAIDA
  const [celComprimida, setcelComprimida] = useState({});
  const [openDialogLeidos, setopenDialogLeidos] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "consigna",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        conUser: usuario.usuario,
        conEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "consigna",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          conUser: usuario.usuario + "/+/" + row[campoExport],
          conEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <DialogTrabajos open={openTrabajos} setopen={setopenTrabajos} row={row} />
      {openDialogLeidos ? (
        <Dialog
          open={openDialogLeidos}
          onClose={() => setopenDialogLeidos(false)}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <Box>
            <DialogTitle
              id="scroll-dialog-title"
              sx={{ padding: ".5rem" }}
              align="center"
            >
              Leidos
            </DialogTitle>
            <DialogContent dividers sx={{}}>
              {row.conLeidos.map((item, index) => {
                return (
                  <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    mb={1}
                  >
                    <Typography variant="body1" color="primary">
                      {index + 1} - {item.docnumero} {item.nombres}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      {item.fecha} - {item.rol.join(" - ")}
                    </Typography>
                  </DialogContentText>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button fullWidth color="primary" variant="contained">
                OK
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      ) : null}

      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.conUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/consignaindividual?conId=${row.conId}&rucempresa=${row.conEmpresa}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 3,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Trabajos",
              texto:
                trabajosCumplidos +
                "/" +
                [
                  ...row.conGuardias,
                  ...row.conSupervisores,
                  ...row.conAdministracion,
                ].length,
              funcion: () => {
                setopenTrabajos(true);
              },
              disabled: false,
              Icon: Work,
              color: "secondary",
              id: 4,
              ocultar: false,
              tipo: "icono",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Leidos",
              texto: row.conLeidos.length === 0 ? false : row.conLeidos.length,
              funcion: () => {
                setopenDialogLeidos(true);
              },
              disabled: row.conLeidos.length === 0,
              Icon: FormatListNumbered,
              color: "primary",
              id: 4,
              ocultar: false,
              tipo: "icono",
              estado: row.conEstado === "ACTIVO" || row.conEstado === "ACTIVA",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            sx={{
              fontWeight: "bold",
              color:
                row.conEstado === "EN PROCESO"
                  ? "orange"
                  : row.conEstado === "INCUMPLIDO"
                  ? "blue"
                  : row.conEstado === "CUMPLIDO"
                  ? "green"
                  : row.conEstado === "ANULADO"
                  ? "red"
                  : "green",
            }}
          >
            {row.conEstado}
          </TableCell>
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"conNombreCliente"}
          />
          <ImagenCelda
            propiedad={row.conFotosCliente}
            array={true}
            claseCelda={claseCelda}
          />
          <DocumentoCelda
            propiedad={row.conDocumento}
            claseCelda={claseCelda}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {row.conAsunto}
          </TableCell>
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"conDetalle"}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {row.conPrioridad}
          </TableCell>{" "}
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.conFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <CeldaUser user={row.conUser} claseCelda={claseCelda} />
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
