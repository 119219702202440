import React, { memo, useContext, useMemo } from "react";

import {
  Autocomplete,
  FormControl,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import ImagenCelda from "../../../../components/ImagenCelda";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";

const RowPrenda = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    estadoRequest,
    setvinculacionesData,
    rowHijo,
    index,
    key,
    vinculacionesData,
  } = props;
  const { usuario } = useContext(AuthContext);
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } `;
  const [editar, seteditar] = useState(false);
  const [itemHijo, setitemHijo] = useState(rowHijo);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [cargando, setcargando] = useState(false);
  const { mostrarAlerta } = useContext(AlertaContext);
  //onChange
  const handleChange = (e) => {
    setitemHijo({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (itemHijo.fecVinculacion.trim() === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = async () => {
    try {
      console.log({
        ...itemHijo,
      });

      const res = await clienteAxios.post("/fechasvinculacion", {
        ...itemHijo,
      });
      console.log(res);

      setvinculacionesData((prev) =>
        prev.map((pItem) =>
          pItem.fecId === itemHijo.fecId ? res.data.data : pItem
        )
      );
      seteditar(false);
    } catch (error) {
      console.log(error);
    }
  };
  const funcEliminar = async () => {
    try {
      setcargando(true);
      const res = await clienteAxios.delete(
        `/fechasvinculacion/${rowHijo.fecId}`
      );
      console.log({ res });

      setvinculacionesData((prev) =>
        prev.filter((item) => item.fecId !== itemHijo.fecId)
      );
      mostrarAlerta(res?.data?.msg || "Hubo un error", "success");
      setopenConfirmDialog(false);
      setcargando(false);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      setcargando(false);
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog(true);
      },
      disabled: cargando,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        seteditar(true);
        setitemHijo(rowHijo);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijo(rowHijo);
        // setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const ultimaFechaRetiro = useMemo(() => {
    if (vinculacionesData) {
      return vinculacionesData[index + 1]?.fecRetiro;
    }
  }, [vinculacionesData]);
  console.log({ ultimaFechaRetiro }, index, rowHijo.fecUser);
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              funcEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ButtonsAction
        claseCelda={claseCelda}
        botones={editar ? botonesEditar : botones}
      />

      {editar ? (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="date"
              inputProps={{
                min: ultimaFechaRetiro?.split("T")[0],
              }}
              value={itemHijo.fecVinculacion.split("T")[0]}
              name="fecVinculacion"
              onChange={(e) => {
                handleChange(e);
              }}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              disabled
              type="date"
              value={
                itemHijo.fecRetiro ? itemHijo.fecRetiro?.split("T")[0] : ""
              }
              name="fecRetiro"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.fecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            -
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.fecUser}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {usuario.usuario}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.fecVinculacion.split("T")[0]}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.fecRetiro ? rowHijo.fecRetiro.split("T")[0] : "-"}
            {/* {rowHijo.fecRetiro
              ? new Date(rowHijo.fecRetiro).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "-"} */}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.fecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.fecRegUpdate
              ? new Date(rowHijo.fecRegUpdate).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })
              : "-"}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.fecUser}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.fecUserUpdate}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default withRouter(memo(RowPrenda));
