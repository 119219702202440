import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";

import { Cancel, Check } from "@mui/icons-material";

import { useState } from "react";

import AuthContext from "../../../Context/Auth/AuthContext";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { FechaExacta, trimPropsItem } from "../../../config/const";
import CampoDoc from "../../../components/Extra/CampoDoc";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem !important",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, socket } = props;
  const { mostrarAlerta } = useContext(AlertaContext);
  const [cargando, setcargando] = useState(false);
  const [item, setitem] = useState({
    // isoId: 1,
    isoOtros: [],
    isoFecReg: "",
    isoFecUpd: "",
    isoProcedimiento: "",
    isoFechaEmision: FechaExacta,
    isoFechaRevision: FechaExacta,
    isoVersion: "",
    isoResponsable: "",
    isoElaborado: "",
    isoAprobado: "",
    isoEmpresa: "",
    isoUser: "",
    isoDocumento: "",
  });
  const classes = useRowStyles();

  const { usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "iso",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          isoUser: usuario.usuario,
          isoEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  const botonDisabled = () => {
    if (
      item.isoProcedimiento === "" ||
      item.isoFechaEmision === "" ||
      item.isoFechaRevision === "" ||
      item.isoVersion === "" ||
      item.isoResponsable === "" ||
      item.isoElaborado === "" ||
      item.isoAprobado === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  // isoId: 1,
                  isoOtros: [],
                  isoFecReg: "",
                  isoFecUpd: "",
                  isoProcedimiento: "",
                  isoFechaEmision: FechaExacta,
                  isoFechaRevision: FechaExacta,
                  isoVersion: "",
                  isoResponsable: "",
                  isoElaborado: "",
                  isoAprobado: "",
                  isoEmpresa: "",
                  isoUser: "",
                  isoDocumento: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  // isoId: 1,
                  isoOtros: [],
                  isoFecReg: "",
                  isoFecUpd: "",
                  isoProcedimiento: "",
                  isoFechaEmision: FechaExacta,
                  isoFechaRevision: FechaExacta,
                  isoVersion: "",
                  isoResponsable: "",
                  isoElaborado: "",
                  isoAprobado: "",
                  isoEmpresa: "",
                  isoUser: "",
                  isoDocumento: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <CampoDoc
              cargando={cargando}
              setcargando={setcargando}
              propiedad={"isoDocumento"}
              item={item}
              setitem={setitem}
              celda={true}
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.isoProcedimiento}
              name="isoProcedimiento"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="date"
              value={item.isoFechaEmision}
              name="isoFechaEmision"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              type="date"
              value={item.isoFechaRevision}
              name="isoFechaRevision"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.isoVersion}
              name="isoVersion"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.isoResponsable}
              name="isoResponsable"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.isoElaborado}
              name="isoElaborado"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              value={item.isoAprobado}
              name="isoAprobado"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
