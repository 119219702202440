import React, { useState, useEffect, memo } from "react";

import Draggable from "react-draggable";

import ItemLista from "./ListItem/ItemLista";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Collapse,
  ListItem,
  List,
  InputAdornment,
} from "@mui/material";
import { Cancel, ExpandLess, ExpandMore, Backspace } from "@mui/icons-material";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    maxWidth: "80%",
    width: "1200px",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalTrabajos = (props) => {
  const { open, setopen, row } = props;
  const [item, setitem] = useState(row);
  const [supervisoresData, setsupervisoresData] = useState([]);
  const [filtrando, setfiltrando] = useState(false);
  const [filtro, setfiltro] = useState("");
  const [personasDataDefault, setpersonasDataDefault] = useState([]);
  const [openCollapse, setopenCollapse] = useState(true);
  const { actSupervisores, actAsignacion, actDirigido, actAsunto } = item;
  const defaultActive = () => {
    setopen(false);
    setseccionActiveSelect({
      DatosAsignacion: false,
      DatosSupervisores: false,
    });
  };
  useEffect(() => {
    if (open) {
      setsupervisoresData(row.actSupervisores);
      setpersonasDataDefault(row.actAsignacion);
    }
    // eslint-disable-next-line
  }, [open]);

  const defaultArrays = () => {
    setfiltrando(false);
    setfiltro("");
    setitem({
      ...item,
      actSupervisores: supervisoresData.map((itemPersona) => {
        const personaRes = actSupervisores.find(
          (itemAct) => itemAct.id === itemPersona.id
        );
        if (personaRes) {
          return personaRes;
        } else {
          return itemPersona;
        }
      }),
      actAsignacion: personasDataDefault.map((itemPersona) => {
        const personaRes = actAsignacion.find(
          (itemAct) => itemAct.id === itemPersona.id
        );
        if (personaRes) {
          return personaRes;
        } else {
          return itemPersona;
        }
      }),
    });
  };

  const clases = useStyles();

  const [modalStyle] = useState(getModalStyle);

  const [seccionActiveSelect, setseccionActiveSelect] = useState({
    DatosAsignacion: false,
    DatosSupervisores: false,
  });

  const { DatosAsignacion, DatosSupervisores } = seccionActiveSelect;

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Trabajos
                </Typography>

                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
              >
                <>
                  <Box>
                    {actDirigido !== "GUARDIA" &&
                    actAsunto === "RONDAS" ? null : (
                      <Button
                        variant={DatosAsignacion ? "contained" : null}
                        disableElevation
                        style={{ padding: "8px" }}
                        color="secondary"
                        onClick={() => {
                          defaultArrays();
                          setseccionActiveSelect({ DatosAsignacion: true });
                        }}
                      >
                        Guardias
                      </Button>
                    )}
                    {actDirigido !== "SUPERVISOR" &&
                    actAsunto === "RONDAS" ? null : (
                      <Button
                        variant={DatosSupervisores ? "contained" : null}
                        disableElevation
                        style={{ padding: "8px" }}
                        color="secondary"
                        onClick={() => {
                          defaultArrays();
                          setseccionActiveSelect({
                            DatosSupervisores: true,
                          });
                        }}
                      >
                        Supervisores
                      </Button>
                    )}
                  </Box>{" "}
                  <Box className={clases.formAllW}>
                    {DatosAsignacion ? (
                      <>
                        <ListItem>
                          <Box
                            display={"flex"}
                            width="100%"
                            alignItems="center"
                            height={"100%"}
                          >
                            <TextField
                              inputProps={{
                                className: clases.inputPadding,
                              }}
                              className={clases.formD}
                              size="small"
                              label="Filtrar"
                              value={filtro}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    {filtrando ? (
                                      <IconButton
                                        aria-label=""
                                        onClick={() => {
                                          defaultArrays();
                                        }}
                                      >
                                        <Backspace color="error" />
                                      </IconButton>
                                    ) : null}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                if (e.target.value === "") {
                                  setfiltrando(false);
                                  setfiltro("");
                                  return setitem({
                                    ...item,
                                    actAsignacion: personasDataDefault,
                                  });
                                }
                                setfiltrando(true);
                                setfiltro(e.target.value);
                                const res = personasDataDefault.filter(
                                  (itemCliente) =>
                                    itemCliente.nombres
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase()) ||
                                    itemCliente.docnumero
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase())
                                );

                                setitem({
                                  ...item,
                                  actAsignacion: res.map((itemPersona) => {
                                    const personaRes = actAsignacion.find(
                                      (itemAct) => itemAct.id === itemPersona.id
                                    );
                                    if (personaRes) {
                                      return personaRes;
                                    } else {
                                      return itemPersona;
                                    }
                                  }),
                                });
                              }}
                            />{" "}
                          </Box>
                          <IconButton
                            aria-label=""
                            onClick={() => setopenCollapse(!openCollapse)}
                          >
                            {openCollapse ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </ListItem>
                        <Divider />

                        <Collapse
                          in={openCollapse}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List dense component="div" disablePadding>
                            {actAsignacion
                              .sort(
                                (a, b) => a.trabajos.length - b.trabajos.length
                              )
                              .map((itemCliente) => {
                                if (itemCliente.asignado) {
                                  return (
                                    <ItemLista
                                      item={itemCliente}
                                      actAsunto={actAsunto}
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              })}
                          </List>
                        </Collapse>
                      </>
                    ) : null}
                    {DatosSupervisores ? (
                      <>
                        <ListItem>
                          <Box
                            display={"flex"}
                            width="100%"
                            alignItems="center"
                            height={"100%"}
                          >
                            <TextField
                              inputProps={{
                                className: clases.inputPadding,
                              }}
                              className={clases.formD}
                              size="small"
                              label="Filtrar"
                              value={filtro}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    {filtrando ? (
                                      <IconButton
                                        aria-label=""
                                        onClick={() => {
                                          defaultArrays();
                                        }}
                                      >
                                        <Backspace color="error" />
                                      </IconButton>
                                    ) : null}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                if (e.target.value === "") {
                                  setfiltrando(false);
                                  setfiltro("");
                                  return setitem({
                                    ...item,
                                    actSupervisores: supervisoresData,
                                  });
                                }
                                setfiltrando(true);
                                setfiltro(e.target.value);
                                const res = supervisoresData.filter(
                                  (itemCliente) =>
                                    itemCliente.nombres
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase()) ||
                                    itemCliente.docnumero
                                      .toLowerCase()
                                      .includes(e.target.value.toLowerCase())
                                );
                                setitem({
                                  ...item,
                                  actSupervisores: res.map((itemPersona) => {
                                    const personaRes = actAsignacion.find(
                                      (itemAct) => itemAct.id === itemPersona.id
                                    );
                                    if (personaRes) {
                                      return personaRes;
                                    } else {
                                      return itemPersona;
                                    }
                                  }),
                                });
                              }}
                            />{" "}
                          </Box>
                          <IconButton
                            aria-label=""
                            onClick={() => setopenCollapse(!openCollapse)}
                          >
                            {openCollapse ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </ListItem>
                        <Divider />
                        <Collapse
                          in={openCollapse}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List dense component="div" disablePadding>
                            {actSupervisores
                              .sort(
                                (a, b) => a.trabajos.length - b.trabajos.length
                              )
                              .map((itemCliente) => {
                                if (itemCliente.asignado) {
                                  return (
                                    <ItemLista
                                      item={itemCliente}
                                      actAsunto={actAsunto}
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              })}
                          </List>
                        </Collapse>
                      </>
                    ) : null}
                  </Box>
                </>{" "}
              </Box>
              {/* <Divider /> */}
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalTrabajos);
