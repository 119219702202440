import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useRowStyles } from "../../styles/stylesRows";

const Dia = ({ row, index }) => {
  const clases = useRowStyles();
  const claseCelda = `${
    index % 2 === 0 ? clases.celda : clases.celdaSecundario
  }`;
  return (
    <TableRow
      className={clases.root}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      sx={{ cursor: "pointer" }}
    >
      <TableCell align="left" className={claseCelda}>
        {new Date(
          Date.parse(row.fecha_inicio) +
            new Date().getTimezoneOffset() * 60 * 1000
        ).toLocaleString("es-ES", {
          weekday: "short",
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
      </TableCell>
    </TableRow>
  );
};

export default Dia;
