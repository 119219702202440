export const columns = [
  {
    id: "doc",
    label: "Documento",
    minWidth: 125,
    tipo: "string",
    orden: 1,
    propiedad: "doc",
    align: "center",
    ocultar: true,
  },
  {
    id: "razonsocial",
    label: "Razón Social",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "razonsocial",
    align: "left",
    ocultar: true,
  },
];
export const objectDefault = {
  // perId: 1117,
  doc: "",
  razonsocial: "",
  cliEmpresa: [""],
  datosoperativos: [],
  cliFecReg: "",
};
export const itemHijoDefecto = {
  consignas: [],
  color: "#000000",
  codigo: "",
  guardias: "1",
  horasservicio: "4",
  puesto: "",
  supervisor: "1",
  tipoinstalacion: "",
  ubicacion: "",
  coordenadas: {
    longitud: -79.178298,
    latitud: -0.254916,
  },
  vulnerabilidades: "",
};
export const columnasHijo = [
  {
    id: "codigo",
    label: "Código",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "codigo",
    align: "center",
    ocultar: true,
  },
  {
    id: "ubicacion",
    label: "ubicación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "ubicacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "Coordenadas",
    label: "Coordenadas",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "Coordenadas",
    align: "center",
    ocultar: true,
  },

  {
    id: "puesto",
    label: "puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "puesto",
    align: "center",
    ocultar: true,
  },

  {
    id: "supervisor",
    label: "supervisor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "supervisor",
    align: "center",
    ocultar: true,
  },

  {
    id: "guardias",
    label: "guardias",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "guardias",
    align: "center",
    ocultar: true,
  },

  {
    id: "horasservicio",
    label: "H. Servicio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "horasservicio",
    align: "center",
    ocultar: true,
  },

  {
    id: "tipoinstalacion",
    label: "T. Instalación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "tipoinstalacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "color",
    label: "Color",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "color",
    align: "center",
    ocultar: true,
  },
];
