import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Autocomplete,
  FormControl,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import ButtonsAction from "../../../../../components/ButtonsAction";
import { itemHijoDefecto, itemPersonaDefecto } from "../../../data";
import AlertaContext from "../../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../../config/axios";

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const {
    itemPadre,
    setagregandoPersona,
    // socket,
    // guardiasTotales,
    setpersonasData: setpersonasDataPadre,
    personasData: personasDataPadre,
    mes,
    anio,
    estadoPersonas,
  } = props;

  const [itemNuevo, setItemNuevo] = useState(itemPersonaDefecto);
  const [personasData, setpersonasData] = useState([]);
  const botonDisabled = () => {
    if (
      itemNuevo.nombre === ""

      // itemNuevo.tipoinstalacion === "" ||
      // itemNuevo.vulnerabilidades === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregandoPersona(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
        setagregandoPersona(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleAgregar = async () => {
    try {
      const res = await clienteAxios.post("/dOperativo_persona/", {
        user: usuario.usuario,
        id_dOperativo: itemPadre.id,
        id_persona: itemNuevo.id,
        month: mes,
        year: anio,
      });
      setpersonasDataPadre((prev) => [res.data.data[0], ...prev]);
    } catch (error) {
      console.log({ error });
      mostrarAlerta("Hubo un error al agregar persona", "error");
    }
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />

        {/* las demas columnas */}

        <>
          <TableCell size="small" align="center" className={classes.celda}>
            <FormControl fullWidth>
              <Autocomplete
                options={personasData
                  .filter(
                    (itemF) =>
                      !personasDataPadre.some((itemS) => {
                        return itemS.id_persona === itemF.perId;
                      })
                  )
                  .map((item) => {
                    return (
                      item.perDocNumero +
                      "/+/" +
                      item.perApellidos +
                      " " +
                      item.perNombres +
                      "/+/" +
                      item.perId
                    );
                  })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={
                  (itemNuevo.documento + " " + itemNuevo.nombre).trim() === ""
                    ? ""
                    : itemNuevo.documento + " " + itemNuevo.nombre
                }
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    try {
                      setItemNuevo({
                        ...itemNuevo,
                        documento: options[0],
                        nombre: options[1].trim(),
                        id: options[2],
                      });
                    } catch (error) {
                      return mostrarAlerta("No hay personas", "error");
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return mostrarAlerta("Ingrese datos", "error");
                      }
                      try {
                        const res = await clienteAxios.get(
                          `/personas/byPerfil/${estadoPersonas}?search=${e.target.value}`
                        );
                        setpersonasData(res.data.data);
                      } catch (error) {}
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);

// const registroPorPersonaMensual = {
// idDelregistro

//   dia1: 'id del libre'|| 1,
//   dia2
//   ...dia30,
// }
