export const columns = [
  {
    id: "permiFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "permiFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "tregistros ",
    label: "T. Permisos",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros ",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "permitFotos",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "cedula",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "left",
    ocultar: true,
  },
  {
    id: "permiEstado",
    label: "Status",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "permiMotivo",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "permiDetalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "permiFecReg",
    label: "F. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "permiUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // permiId: 1,
  permiIdPersona: 0,
  permiIdDOperativo: 0,
  permiIdMulta: 0,
  permiIdsTurnoExtra: [],
  permiMotivo: "",
  permiEstado: "",
  permiDetalle: "",
  permiStatusDescripcion: "",
  permiDocumento: "",
  permitFotos: [],
  permiUser: "",
  permiEmpresa: "",
  permiFecReg: "",
  permiFecUpd: "",
  cedula: "",
  nombres: "",
  permiIdsJornadaLaboral: [],
  dias: [
    // {
    //   id: 1,
    //   milisegundos: 1704085200000,
    //   fecha_inicio: "2024-01-01",
    //   fecha_final: "2024-01-01",
    //   id_horario: 6,
    //   id_relacion: 1,
    // },
  ],
  idCliente: "",
  ruc: "",
  razonSocial: "",
  ubicacion: "",
  puesto: "",
};

// {
//     permiId: 1,
//   permiIdPersona: "",
//   cedula: "",
//   nombres: "",
//   idCliente: "",
//   ruc: "",
//   razonSocial: "",
//   ausPuesto: [],
//   permiMotivo: "", // [ENFERMEDADES IESS, PERMISO PERSONAL,PATERNIDAD,DEFUNCION FAMILIAR, INJUSTIFICADA]

//   permiDetalle: "",
//   permiDocumento: "", // input file
//   permitFotos: [], // dropzone
//   ausIdMulta: "", // vacio
//   permiUser: "",
//   permiEmpresa: "",
//   permiFecReg: "",
//   ausDiasPermiso: "1",
//   Todos: "",
//   permiEstado: "EN PROCESO",
//   permiStatusDescripcion: "",
// };
