import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  PictureAsPdf,
  QrCode,
  Restore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import ImagenCelda from "../../../../components/ImagenCelda";
import DocumentoCelda from "../../../../components/DocumentoCelda";
import clienteAxios from "../../../../../config/axios";
import { saveAs } from "save-as";
import ModalQR from "../../../../components/ModalQR";
import CeldaUser from "../../../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
    // ESTADO,
    // estadoRequest,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [openQr, setopenQr] = useState(false);
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        iopUser: usuario.usuario,
        iopEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        iopFecReg: rowHijo.iopFecReg.substr(0, 7),
        iopCliId: rowHijo.iopCliId,
        antTipo: rowHijo.antTipo,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          iopUser: usuario.usuario + "/+/" + rowHijo[campoExport],
          iopEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
        endpoint={`reportes/qr_prendasCliente?iopId=${rowHijo.iopId}&rucempresa=${rowHijo.iopEmpresa}&hoja=${itemQr.hoja}&cantidad=${itemQr.cantidad}&ancho=${itemQr.ancho}&alto=${itemQr.alto}&option=act_asignada`}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowHijo.iopUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: async () => {
                const res = await clienteAxios.get(
                  `${
                    clienteAxios.defaults.baseURL
                  }reportes/iop_individual?iopId=${rowHijo.iopId}&rucempresa=${
                    rowHijo.iopEmpresa
                  }&usuario=${
                    rowHijo.iopUser.split("T")[0]
                  }&nombre=${encodeURIComponent(usuario.nombre)}`,
                  { responseType: "blob" }
                );
                const pdfBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                saveAs(pdfBlob, `${tabla}.pdf`);
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Estado",
              texto: "",
              funcion: (e) => {
                // setitem({
                //   ...item,
                //   bodEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                // });
              },
              disabled: true,
              Icon: "",
              color: "primary",
              id: 6,
              ocultar: false,
              tipo: "check",
              estado: rowHijo.iopEstado === "ACTIVA",
              // editar
              //   ? item.bodEstado === "ACTIVO"
              //   : row.bodEstado === "ACTIVO",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              id: 5,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="left" className={claseCelda}>
            {rowHijo.iopCliDocNumero} {rowHijo.iopCliRazonSocial}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.iopAsunto}
          </TableCell>

          <DocumentoCelda
            propiedad={rowHijo.iopDocumento}
            claseCelda={claseCelda}
            Icono={<PictureAsPdf color="error" fontSize="small" />}
          />
          <ImagenCelda
            claseCelda={claseCelda}
            propiedad={rowHijo.iopFotos}
            array={true}
          />
        
          <CeldaUser user={rowHijo.iopUser} claseCelda={claseCelda} />
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.iopFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell> 
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
