import { Box, Button, Divider, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import TablaVestimenta from "../../RowsVestimenta/TablaVestimenta";
import TablaArmas from "../../RowsArmas/TablaArmas";
import TablaMunicion from "../../RowMunicion/TablaMunicion";
import { useModalStyle } from "../../../../styles/styleModal";
import { trimPropsItem } from "../../../../../config/const";
import { useContext } from "react";
import AuthContext from "../../../../../Context/Auth/AuthContext";

const ver = true;
const Devolucion = ({ itemActivo, bodegaData, socket }) => {
  const { usuario } = useContext(AuthContext);
  const clases = useModalStyle();
  const [item, setitem] = useState(itemActivo);
  const { disVestimenta, disArmas, disMuniciones, devEstado, devFecUpd } = item;
  const [subSeccion, setsubSeccion] = useState({
    vestimenta: true,
    armas: false,
    municion: false,
    guardias: false,
    devolucion: false,
    supervisores: false,
    administracion: false,
  });
  const { vestimenta, armas, municion } = subSeccion;
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const onSubmit = () => {
    try {
      const { disArmas, disMuniciones, disVestimenta, ...rest } = item;

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...rest,
          devDisArmas: disArmas,
          devDisMuniciones: disMuniciones,
          devDisVestimenta: disVestimenta,
          tabla: "devolucion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
        })
      );
    } catch (error) {}
  };
  return (
    <>
      <TextField
        className={clases.formD}
        size="small"
        label="Estado"
        value={devEstado}
        name="devEstado"
        onChange={(e) => {
          setitem({ ...item, [e.target.name]: e.target.value });
        }}
        select
        InputLabelProps={{ shrink: true }}
        SelectProps={{ displayEmpty: true }}
      >
        <MenuItem disabled value="">
          Seleccione.
        </MenuItem>
        {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        disabled
        className={clases.formD}
        size="small"
        label="Fec. Upd"
        value={new Date(devFecUpd).toLocaleString("es-ES", {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour: "2-digit",
        })}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" center"
        width="100%"
      >
        <Box display={"flex"} flexWrap={"wrap"}>
          <Button
            size="small"
            variant={vestimenta ? "contained" : null}
            onClick={async () => {
              setsubSeccion({ vestimenta: true });
            }}
            disableElevation
            className={clases.buttonTab}
            color="primary"
          >
            Implementos
          </Button>
          <Button
            size="small"
            variant={armas ? "contained" : null}
            onClick={async () => {
              setsubSeccion({ armas: true });
            }}
            disableElevation
            className={clases.buttonTab}
            color="primary"
          >
            ARMAS
          </Button>
          <Button
            size="small"
            variant={municion ? "contained" : null}
            onClick={async () => {
              setsubSeccion({ municion: true });
            }}
            disableElevation
            className={clases.buttonTab}
            color="primary"
          >
            Munición
          </Button>
          <Button
            size="small"
            variant={"contained"}
            disableFocusRipple={true}
            disableTouchRipple={true}
            disableElevation={true}
            className={clases.buttonTab}
            color="success"
          >
            V. TOTAL: $
            {[...disVestimenta, ...disArmas, ...disMuniciones].length === 0
              ? null
              : [...disVestimenta, ...disArmas, ...disMuniciones]
                  .map((row) => {
                    return (
                      Number(row.valor) *
                      row.cantidades
                        .map(({ cantidad }) => cantidad)
                        .reduce(reducer)
                    );
                  })
                  .reduce(reducer)
                  .toFixed(2)}
          </Button>
        </Box>
        <Box></Box>
      </Box>
      <Divider style={{ margin: "0", width: "100%" }} />
      {vestimenta ? (
        <TablaVestimenta
          ver={ver}
          devolucion={true}
          setitem={setitem}
          item={item}
          bodegaData={bodegaData}
        />
      ) : null}
      {armas ? (
        <TablaArmas
          ver={ver}
          devolucion={true}
          setitem={setitem}
          item={item}
          bodegaData={bodegaData}
        />
      ) : null}
      {municion ? (
        <TablaMunicion
          ver={ver}
          devolucion={true}
          setitem={setitem}
          item={item}
          bodegaData={bodegaData}
        />
      ) : null}
      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
        sx={{ width: "100%", marginTop: "1rem" }}
      >
        GUARDAR
      </Button>
    </>
  );
};

export default Devolucion;
