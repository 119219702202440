import React, { memo, useContext } from "react";

import { useState } from "react";

import AuthContext from "../../../../../Context/Auth/AuthContext";

// MATERIAL
import { TableCell, TableRow, TextField } from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { usePermiso } from "../../../../hooks/usePermiso";
import ButtonsAction from "../../../../components/ButtonsAction";
import { estaEntre, sumarMinutos } from "../../../../functions/funciones";
import { trimPropsItem } from "../../../../../config/const";
import { v4 } from "uuid";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
const RowHijoDeHijo = (props) => {
  const classes = useRowStyles();
  const {
    rowHijoDeHijo,
    indexHijo,
    rowPadre,
    rowHijo,
    indexHijoDeHijo,
    socket,
    horarioError,
    sethorarioError,
    setitemHijo,
    editarPadre,
    esSupervisor,
  } = props;
  const claseCelda = `${
    indexHijoDeHijo % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    horarioError.some((idError) => idError === rowHijoDeHijo.id)
      ? classes.celdaEliminado
      : ""
  }`;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [itemHijoDeHijo, setitemHijoDeHijo] = useState(rowHijoDeHijo);
  const [editar, seteditar] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("EventosActividades");
  const botonDisabled = () => {
    if (itemHijoDeHijo.hora === "") {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: esSupervisor,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }
        setitemHijoDeHijo(rowHijoDeHijo);
        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: esSupervisor,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijoDeHijo(rowHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    const horaInicio = itemHijoDeHijo.hora;
    const horaFinal = sumarMinutos(
      itemHijoDeHijo.hora,
      Number(rowHijo.minutos)
    );
    const itemHijoDeHijoEdit = trimPropsItem({
      ...itemHijoDeHijo,
      id: v4(),
      horaFinal,
    });
    let existe = false;
    let errores = [];
    errores = [];
    // const eveActividades = rowPadre.eveActividades.map((itemHijo, index) => {

    //   return { ...itemHijo, horario: horarioValidado };
    // });
    const eveActividades = rowPadre.eveActividades.map(
      (itemHijo, indexHijoMap) => {
        itemHijo.horario.forEach((horarioVItem) => {
          if (
            (estaEntre(horarioVItem.hora, horarioVItem.horaFinal, horaInicio) ||
              estaEntre(
                horarioVItem.hora,
                horarioVItem.horaFinal,
                horaFinal
              )) &&
            horarioVItem.id !== itemHijoDeHijo.id
          ) {
            existe = true;
            errores = [...errores, horarioVItem.id, itemHijo.id];
          }
        });
        if (indexHijoMap === indexHijo) {
          return {
            ...itemHijo,
            horario: itemHijo.horario.map(
              (itemHijoDeHijoMap, indexitemHijoDeHijoMap) => {
                if (indexitemHijoDeHijoMap === indexHijoDeHijo) {
                  return trimPropsItem(itemHijoDeHijoEdit);
                } else {
                  return itemHijoDeHijoMap;
                }
              }
            ),
          };
        } else {
          return itemHijo;
        }
      }
    );
    if (existe) {
      setitemHijoDeHijo({ ...itemHijoDeHijo, horaFinal });
      sethorarioError(errores);
      return mostrarAlerta("Ya se esta utilizando este horario", "error");
    }
    sethorarioError([]);
    socket.current.emit("client:actualizarData", {
      ...rowPadre,
      tabla: "eventoactividad",
      eveActividades,
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      eveUser: usuario.usuario,
      eveEmpresa: usuario.rucempresa,
    });
    seteditar(false);
  };
  const handleChange = (e) => {
    setitemHijoDeHijo({
      ...itemHijoDeHijo,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...rowPadre,
      tabla: "eventoactividad",
      eveActividades: rowPadre.eveActividades.map((itemHijo, indexHijoMap) => {
        if (indexHijoMap === indexHijo) {
          return {
            ...itemHijo,
            horario: itemHijo.horario.filter(
              (itemHijoDeHijoMap, indexitemHijoDeHijoMap) =>
                indexitemHijoDeHijoMap !== indexHijoDeHijo
            ),
          };
        } else {
          return itemHijo;
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      eveUser: usuario.usuario,
      eveEmpresa: usuario.rucempresa,
    });

    setopenConfirmDialog(false);
  };

  return (
    <React.Fragment>
      {/* <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        funcion={handleEliminar}
      /> */}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={rowHijo}
          botones={editar ? botonesEditar : botones}
        />
        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                type="time"
                autoFocus
                value={itemHijoDeHijo.hora}
                name="hora"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {itemHijoDeHijo.horaFinal}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemHijoDeHijo.observacion}
                name="observacion"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {editarPadre && esSupervisor ? (
                <TextField
                  type="time"
                  value={rowHijoDeHijo.hora}
                  name="hora"
                  onChange={(e) =>
                    setitemHijo((prev) => ({
                      ...prev,
                      horario: prev.horario.map((itemHorario) =>
                        itemHorario.id === rowHijoDeHijo.id
                          ? {
                              ...rowHijoDeHijo,
                              [e.target.name]: e.target.value,
                            }
                          : itemHorario
                      ),
                    }))
                  }
                  size="small"
                  fullWidth
                />
              ) : (
                rowHijoDeHijo.hora
              )}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {editarPadre && esSupervisor ? (
                <TextField
                  type="time"
                  value={rowHijoDeHijo.horaFinal}
                  name="horaFinal"
                  onChange={(e) =>
                    setitemHijo((prev) => ({
                      ...prev,
                      horario: prev.horario.map((itemHorario) =>
                        itemHorario.id === rowHijoDeHijo.id
                          ? {
                              ...rowHijoDeHijo,
                              [e.target.name]: e.target.value,
                            }
                          : itemHorario
                      ),
                    }))
                  }
                  size="small"
                  fullWidth
                />
              ) : (
                rowHijoDeHijo.horaFinal
              )}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {editarPadre && esSupervisor ? (
                <TextField
                  value={rowHijoDeHijo.observacion}
                  name="observacion"
                  onChange={(e) =>
                    setitemHijo((prev) => ({
                      ...prev,
                      horario: prev.horario.map((itemHorario) =>
                        itemHorario.id === rowHijoDeHijo.id
                          ? {
                              ...rowHijoDeHijo,
                              [e.target.name]: e.target.value,
                            }
                          : itemHorario
                      ),
                    }))
                  }
                  size="small"
                  fullWidth
                />
              ) : (
                rowHijoDeHijo.observacion
              )}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowHijoDeHijo);
