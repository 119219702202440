import React, { memo, useContext } from "react";
import { withRouter } from "react-router";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../../Context/Menu/MenuContext";
import { permisoDenagado } from "../../../../../config/const";
import { tienePermisoRuta } from "../../../../../config/validaciones";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,
    setopenConfirmDialog,
    funcionEdit,
    ocultarEditar,
    ocultarDelete,
    editar,
    funcCancel,
    funcSubmit,
    botonDisabled,
    deshabilitarbotones,
    itemRow,
  } = props;
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);

  return (
    <>
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  className={classes.margin}
                  checked={arrayExport.some((row) => {
                    return itemRow[campoExport] === row[campoExport];
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setarrayExport([...arrayExport, row]);
                    } else {
                      setarrayExport(
                        arrayExport.filter(
                          (row) => itemRow[campoExport] !== row[campoExport]
                        )
                      );
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          ml={1}
          mr={1}
        >
          <>
            {editar ? (
              <>
                <Tooltip title="Cancelar">
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      funcCancel();
                    }}
                  >
                    <Cancel fontSize="small" color="secondary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Confirmar">
                  <IconButton
                    className={classes.margin}
                    disabled={botonDisabled ? botonDisabled() : false}
                    onClick={() => {
                      funcSubmit();
                    }}
                  >
                    <Check
                      fontSize="small"
                      color={
                        botonDisabled
                          ? botonDisabled()
                            ? "inherit"
                            : "secondary"
                          : "secondary"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                {" "}
                {ocultarDelete ? null : (
                  <Tooltip title="Eliminar">
                    <IconButton
                      className={classes.margin}
                      disabled={deshabilitarbotones}
                      onClick={() => {
                        if (
                          !tienePermisoRuta(
                            rows,
                            props.location.pathname,
                            usuario.rol,
                            "eliminar"
                          )
                        ) {
                          return mostrarAlerta(permisoDenagado, "error");
                        }
                        setopenConfirmDialog(true);
                      }}
                    >
                      <Delete
                        fontSize="small"
                        color={deshabilitarbotones ? "inherit" : "error"}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {ocultarEditar ? null : (
                  <Tooltip title="Editar">
                    <IconButton
                      className={classes.margin}
                      color={deshabilitarbotones ? "inherit" : "secondary"}
                      disabled={deshabilitarbotones}
                      onClick={() => {
                        if (
                          !tienePermisoRuta(
                            rows,
                            props.location.pathname,
                            usuario.rol,
                            "editar"
                          )
                        ) {
                          return mostrarAlerta(permisoDenagado, "error");
                        }
                        funcionEdit();
                      }}
                    >
                      <Create />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </>
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
