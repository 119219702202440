import { Badge, Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Vestimentas from "./Tabs/Vestimentas";
import Municiones from "./Tabs/Municiones";
import Armas from "./Tabs/Armas";
import Fotos from "./Tabs/Fotos";
import Videos from "./Tabs/Videos";

const Trabajo = (props) => {
  const { seccionActive, item } = props;
  const [trabajoActivo, settrabajoActivo] = useState(null);

  const [subSeccionActive, setsubSeccionActive] = useState({
    armas: false,
    fotos: false,
    municiones: false,
    vestimentas: false,
    videos: false,
  });
  const { armas, fotos, municiones, vestimentas, videos } = subSeccionActive;
  useEffect(() => {
    if (seccionActive === "") {
      return;
    }
    const trabajoF = item.trabajos.find(
      (item, index) => item.titulo + "/+/" + index === seccionActive
    );
    settrabajoActivo(trabajoF);
    setsubSeccionActive({
      armas: false,
      fotos: false,
      municiones: false,
      vestimentas: false,
      videos: false,
    });
    // eslint-disable-next-line
  }, [seccionActive]);

  if (!trabajoActivo) {
    return null;
  }
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box>
          {["armas", "fotos", "municiones", "vestimentas", "videos"].map(
            (tipoSubSeccion) => {
              if (
                !trabajoActivo[tipoSubSeccion] ||
                trabajoActivo[tipoSubSeccion]?.length === 0
              ) {
                return null;
              }
              return (
                <Badge
                  badgeContent={trabajoActivo[tipoSubSeccion].length}
                  color="success"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Button
                    variant={
                      subSeccionActive[tipoSubSeccion] ? "contained" : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() =>
                      setsubSeccionActive({ [tipoSubSeccion]: true })
                    }
                  >
                    {tipoSubSeccion}
                  </Button>
                </Badge>
              );
            }
          )}
        </Box>
      </Box>
      <Divider />
      <Typography variant="body1" color="primary" fontWeight={"bold"}>
        Descripción:{" "}
        <Typography variant="body1" color="secondary" component={"span"}>
          {trabajoActivo.descripcion}
        </Typography>
      </Typography>
      <Divider />
      <Box>
        {vestimentas && <Vestimentas trabajoActivo={trabajoActivo} />}
        {municiones && <Municiones trabajoActivo={trabajoActivo} />}
        {armas && <Armas trabajoActivo={trabajoActivo} />}
        {fotos && <Fotos trabajoActivo={trabajoActivo} />}
        {videos && <Videos trabajoActivo={trabajoActivo} videosB={videos} />}
      </Box>
    </Box>
  );
};

export default Trabajo;
