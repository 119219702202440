import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../Context/Auth/AuthContext";
// MATERIAL
import { TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import ButtonsAction from "../../../../components/ButtonsAction";
import { itemHijoDelHijoDefecto } from "../../../data";
import { estaEntre, sumarMinutos } from "../../../../functions/funciones";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import { v4 } from "uuid";

const RowAgregarHijoDeHijo = (props) => {
  // eslint-disable-next-line
  const {
    indexHijo,
    rowPadre,
    rowHijo,
    setagregandoHorario,
    socket,
    sethorarioError,
  } = props;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [itemHijoDeHijo, setitemHijoDeHijo] = useState(itemHijoDelHijoDefecto);
  const botonDisabled = () => {
    if (itemHijoDeHijo.hora === "") {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregandoHorario(false);
        setitemHijoDeHijo(itemHijoDelHijoDefecto);
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleChange = (e) => {
    setitemHijoDeHijo({
      ...itemHijoDeHijo,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgregar = async () => {
    try {
      const horaInicio = itemHijoDeHijo.hora;
      const horaFinal = sumarMinutos(
        itemHijoDeHijo.hora,
        Number(rowHijo.minutos)
      );
      const itemHijoDeHijoEdit = trimPropsItem({
        ...itemHijoDeHijo,
        id: v4(),
        horaFinal,
      });
      let existe = false;
      let errores = [];
      errores = [];
      // const eveActividades = rowPadre.eveActividades.map((itemHijo, index) => {

      //   return { ...itemHijo, horario: horarioValidado };
      // });
      const eveActividades = rowPadre.eveActividades.map((itemHijo, index) => {
        itemHijo.horario.forEach((horarioVItem) => {
          if (
            estaEntre(horarioVItem.hora, horarioVItem.horaFinal, horaInicio) ||
            estaEntre(horarioVItem.hora, horarioVItem.horaFinal, horaFinal)
          ) {
            existe = true;
            errores = [...errores, horarioVItem.id, itemHijo.id];
          }
        });
        if (index === indexHijo) {
          return {
            ...itemHijo,
            horario: [itemHijoDeHijoEdit, ...itemHijo.horario],
          };
        } else {
          return itemHijo;
        }
      });
      if (existe) {
        setitemHijoDeHijo({ ...itemHijoDeHijo, horaFinal });
        sethorarioError(errores);
        return mostrarAlerta("Ya se esta utilizando este horario", "error");
      }
      sethorarioError([]);
      socket.current.emit("client:actualizarData", {
        ...rowPadre,
        tabla: "eventoactividad",
        eveActividades,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        eveUser: usuario.usuario,
        eveEmpresa: usuario.rucempresa,
      });
      setagregandoHorario(false);
      setitemHijoDeHijo(itemHijoDelHijoDefecto);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />

        {/* las demas columnas */}

        <>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <TextField
              type="time"
              autoFocus
              value={itemHijoDeHijo.hora}
              name="hora"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            {itemHijoDeHijo.horaFinal}
          </TableCell>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <TextField
              value={itemHijoDeHijo.observacion}
              name="observacion"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarHijoDeHijo);
