import React, { memo, useContext } from "react";

import { 
  MenuItem,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../../../components/ButtonsAction";
import AuthContext from "../../../../../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../../../config/const";
import { objectDefault } from "./data";
import clienteAxios from "../../../../../../../config/axios";
import AlertaContext from "../../../../../../../Context/Alerta/AlertaContext";
import { useEffect } from "react";

const RowNuevo = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    estadoRequest,
    setopen,
    socket,
    tiposData,
    // IOP
    fromIOP,
    clienteIOP,
    itemHijo,
  } = props;
  useEffect(() => {
    if (fromIOP) {
      const getPuestos = async () => {
        const res = await clienteAxios.get(
          `/clientes/filtro/0?search=${clienteIOP.cliDocNumero}&estado=CLIENTES`
        );
        const itemF = res.data.data.find(
          (itemCliente) => itemCliente.cliId === Number(clienteIOP.cliId)
        );
        setdatosOperativos(itemF.cliDatosOperativos);
        setclientesData(res.data.data);
      };
      getPuestos();
      setitem({
        ...objectDefault,
        eveCliId: clienteIOP.cliId,
        eveCliDocumento: clienteIOP.cliDocNumero,
        eveCliNombre: clienteIOP.cliRazonSocial,
      });
    } // eslint-disable-next-line
  }, [fromIOP]);

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(objectDefault);
  // DATA
  const [clientesData, setclientesData] = useState([]);
  const [datosOperativos, setdatosOperativos] = useState([]);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.planiNombreActividad === "" ||
      item.planiTipo === "" ||
      item.eveCliDocumento === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          planiIdDOperativo: itemHijo.id,
          planiPerfil: estadoRequest,
          nombre: usuario.nombre,
          tabla: "planificacionactividad",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          planiUser: usuario.usuario,
          planiEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}

        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              value={item.planiTipo}
              name="planiTipo"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {tiposData.map((item) => {
                return (
                  <MenuItem key={item.novId} value={item.novTipo}>
                    {item.novTipo}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              value={item.planiNombreActividad}
              name="planiNombreActividad"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {new Date().toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowNuevo));
