import React, { useContext, useEffect, memo, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import Row from "./Row";
import Cargando from "../../../../components/Extra/Cargando";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  DeleteForever,
  ListRounded,
  PictureAsPdf,
  Refresh,
} from "@mui/icons-material";
import DialogOpciones from "../../../components/DialogOpciones";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import clienteAxios from "../../../../config/axios";
import { saveAs } from "save-as";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { useTableStyles } from "../../../styles/styleTable";
import { usePermiso } from "../../../hooks/usePermiso";
import RowNuevo from "./RowNuevo";
const Tabla = (props) => {
  //clases
  const classes = useTableStyles();
  // props
  const {
    //PAGINACION Y BUSQUEDA
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador,
    //ESTADO
    rows,
    columns,
    pagination,
    getDataState,
    obtenerState,
    addRowsState,
    remplazarRowsState,
    arrayExport,
    setarrayExport,
    socket,
    campoExport,
    estadoRequest,
    tabla,
    //ROW
    open,
    setopen,
  } = props;
  // CONTEXT
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ABRE EL DIALOG DE CONFIRMACION PARA ELIMINAR
  const [openCDEliminar, setopenCDEliminar] = useState(false);
  // DESHABILITA BOTONES DEL DIALOG SI ESTA CARGANDO
  const [cargandoDialogOpciones, setcargandoDialogOpciones] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Actualizaciones");
  // FUNCION PARA ELIMINAR SELECCION
  const handleEliminar = async () => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }

    await setcargando(true);

    await setTimeout(async () => {
      socket.current.emit("client:eliminarDataSeleccionado", {
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla,
        lista: arrayExport,
      });
      setcargandoDialogOpciones(false);
      setopenCDEliminar(false);
      setcargando(false);
    }, 500);
  };
  // FUNCION PARA CAMBIAR PAGINA
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row[campoExport] === item[campoExport];
        });

        return !existe;
      });

      addRowsState(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line
  // FUNCION QUE CAMBIA LOS REGISTROS O FILAS POR PAGINA
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
  };
  // FUNCION QUE ORDENA DESCENDENTE O ASCENDENTEMENTE
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  // REFRES
  const funcionReload = async () => {
    setcargando(true);
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setcargando(false);
  };

  // OBTIENE LA DATA AL CARGAR EL COMPONENTE
  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );

    // eslint-disable-next-line
  }, []);

  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Paper className={classes.root}>
      <ConfirmacionDialog
        open={openCDEliminar}
        setopen={setopenCDEliminar}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar estos registros?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: async () => {
              await handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenCDEliminar(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Exportar",
            funcion: async () => {
              try {
                setcargandoDialogOpciones(true);
                const json = {
                  search,
                  lista: arrayExport.map((itemExport) => itemExport),
                  rucempresa: usuario.rucempresa,
                  rol: usuario.rol,
                  search_avanzado: fullBuscador,
                };

                const res = await clienteAxios.post(
                  `reportes/${tabla}`,
                  {
                    ...json,
                  },
                  { responseType: "blob" }
                );
                const pdfBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                saveAs(pdfBlob, `${tabla}.pdf`);
                setopen(false);
                setcargandoDialogOpciones(false);
              } catch (error) {
                mostrarAlerta("Hubo un error", "error");
                setopen(false);
                setcargandoDialogOpciones(false);
              }
            },
            Icon: PictureAsPdf,
            color: "error",
            id: 1,
            disabled: cargandoDialogOpciones,
          },
          {
            tooltip: "Eliminar",
            funcion: () => {
              setopenCDEliminar(true);
            },
            Icon: DeleteForever,
            color: "error",
            id: 2,
            disabled: cargandoDialogOpciones,
          },
        ]}
        titulo={"Acciones"}
      />
      <ToolBarTable
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ocultarPaginacion={false}
        botonesIzquierdos={[
          {
            tooltip: "Actualizar",
            texto: "Actualizar",
            funcion: () => {
              funcionReload(true);
            },
            disabled: false,
            Icon: Refresh,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "text",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[
          {
            tooltip: "Exportar",
            texto: "",
            funcion: () => {
              setopenDialogOpciones(true);
            },
            disabled: arrayExport.length === 0,
            Icon: ListRounded,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "text",
            size: "medium",
            sx: { margin: 0, padding: 0 },
          },
        ]}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
            
          <TableHead>
            <TablaCabecera
              rows={rows}
              setarrayExport={setarrayExport}
              campoExport={campoExport}
              columns={columns}
              habilitarOrdenar={true}
              //nuevos
              input={input}
              orden={orden}
              funcionOrdenar={funcionOrdenar}
              ocultarPrimeraColumna={false}
            />
          </TableHead>
          <TableBody component={"div"}>
            {open ? <RowNuevo setopen={setopen} socket={socket} /> : null}
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columns.length + 2}
              >
                <Cargando titulo="Cargando..." />
              </TableCell>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <Row
                      key={row[campoExport]}
                      arrayExport={arrayExport}
                      setarrayExport={setarrayExport}
                      row={row}
                      index={index}
                      campoExport={campoExport}
                      socket={socket}
                    />
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(Tabla));
