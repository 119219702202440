import React, { memo, useContext } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Modal,
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  MenuItem,
} from "@mui/material";

import { Cancel } from "@mui/icons-material";
import Draggable from "react-draggable";
import { useModalStyle } from "../styles/styleModal";
import { useState } from "react";
import { useEffect } from "react";
import clienteAxios from "../../config/axios";
import { objectActAsignDefault } from "./data";
import CampoDoc from "../components/CampoDoc";
import Dropzone from "../components/Dropzone";
import useMultimediasUpload from "../hooks/useMultimediasUpload";
import ActividadesTab from "./DatosOperativos/Componentes/RowItems/ActividadesTab/ActividadesTab";
import AlertaContext from "../../Context/Alerta/AlertaContext";

export const ModalAsiActividad = ({
  openModal,
  setopenModal,
  datoOperativo,
  diasSeleccionados,
  anio,
  mes,
  itemPadre,
  setpersonasData,
  estadoPersonas,
}) => {
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const { mostrarAlerta } = useContext(AlertaContext);
  const clases = useModalStyle();
  const [cargando, setcargando] = useState(false);
  const [actividad, setitem] = useState(objectActAsignDefault);
  const handleChange = (e) => {
    setitem((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const {
    acti_prioridad,
    // acti_documento,
    acti_fotos,
    acti_observacion,
    acti_requerimiento,
    // idHorarios,
    // id_planificacion,
    // month,
    // year,
    // id_dOperativo,
  } = actividad;
  const asignarActividad = async (id) => {
    try {
      const resDocumentos = await subirImagenes(imagenes, acti_fotos);

      const res = await clienteAxios.post("/asignacion_actividades", {
        ...actividad,
        acti_fotos: resDocumentos,
        id_planificacion: id, // id de la actividad
        idHorarios: Object.keys(diasSeleccionados), // id de los dias seleccionados
        month: mes,
        year: anio,
        id_dOperativo: datoOperativo.id,
      });
      setopenModal(false);
      setpersonasData(res.data.data);
    } catch (error) {
      console.log({ error });
      mostrarAlerta("Hubo un error", "error");
    }
  };
  const defaultActive = () => {
    setitem(objectActAsignDefault);
    seterror({});
    setimagenes([]);
    setmodificoImagenes(false);
    setopenModal(false);
  };
  const [error, seterror] = useState({});
  const [seccionActive, setseccionActive] = useState("DATOS PRINCIPALES");

  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Asignar Actividad.
                </Typography>

                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      seccionActive === "DATOS PRINCIPALES" ? "contained" : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive("DATOS PRINCIPALES")}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={seccionActive === "ARCHIVOS" ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive("ARCHIVOS")}
                  >
                    ARCHIVOS
                  </Button>
                  <Button
                    variant={
                      seccionActive === "ASIGNACIÓN" ? "contained" : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive("ASIGNACIÓN")}
                  >
                    ASIGNACIÓN
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionActive === "DATOS PRINCIPALES" && (
                  <>
                    <TextField
                      className={clases.formAllW}
                      size="small"
                      id="acti_prioridad"
                      label="Prioridad"
                      value={acti_prioridad}
                      name="acti_prioridad"
                      error={error.acti_prioridad}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["BAJA", "MEDIA", "ALTA"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>

                    <>
                      <FormControl
                        component="fieldset"
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        error={error.acti_requerimiento}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FormLabel
                            component="span"
                            color="secondary"
                            style={{
                              marginRight: ".5rem",
                              fontWeight: "bold",
                            }}
                          >
                            QR
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) =>
                              setitem({
                                ...actividad,
                                acti_requerimiento: {
                                  ...actividad.acti_requerimiento,
                                  [e.target.name]: e.target.value,
                                },
                              })
                            }
                            row
                            aria-label="gender"
                            value={acti_requerimiento.qr}
                            name="qr"
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              // disabled={actEveTipo === "RONDAS"}
                              control={<Radio />}
                              label="NO"
                            />
                          </RadioGroup>
                        </Box>
                      </FormControl>
                      <FormControl
                        component="fieldset"
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        error={error.acti_requerimiento}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FormLabel
                            component="span"
                            color="secondary"
                            style={{
                              marginRight: ".5rem",
                              fontWeight: "bold",
                            }}
                          >
                            Foto
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) =>
                              setitem({
                                ...actividad,
                                acti_requerimiento: {
                                  ...actividad.acti_requerimiento,
                                  [e.target.name]: e.target.value,
                                },
                              })
                            }
                            row
                            aria-label="gender"
                            value={acti_requerimiento.foto}
                            name="foto"
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                          </RadioGroup>
                        </Box>
                      </FormControl>
                      <FormControl
                        component="fieldset"
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        error={error.acti_requerimiento}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FormLabel
                            component="span"
                            color="secondary"
                            style={{
                              marginRight: ".5rem",
                              fontWeight: "bold",
                            }}
                          >
                            Video
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) =>
                              setitem({
                                ...actividad,
                                acti_requerimiento: {
                                  ...actividad.acti_requerimiento,
                                  [e.target.name]: e.target.value,
                                },
                              })
                            }
                            row
                            aria-label="gender"
                            value={acti_requerimiento.video}
                            name="video"
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                          </RadioGroup>
                        </Box>
                      </FormControl>
                      <FormControl
                        component="fieldset"
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        error={error.acti_requerimiento}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <FormLabel
                            component="span"
                            color="secondary"
                            style={{
                              marginRight: ".5rem",
                              fontWeight: "bold",
                            }}
                          >
                            Texto
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) =>
                              setitem({
                                ...actividad,
                                acti_requerimiento: {
                                  ...actividad.acti_requerimiento,
                                  [e.target.name]: e.target.value,
                                },
                              })
                            }
                            row
                            aria-label="gender"
                            value={acti_requerimiento.texto}
                            name="texto"
                          >
                            <FormControlLabel
                              value="SI"
                              control={<Radio />}
                              label="SI"
                            />
                            <FormControlLabel
                              value="NO"
                              control={<Radio />}
                              label="NO"
                            />
                          </RadioGroup>
                        </Box>
                      </FormControl>
                      <TextField
                        className={clases.formAllW}
                        size="small"
                        id="acti_observacion"
                        label="Tarea"
                        value={acti_observacion}
                        name="acti_observacion"
                        error={error.acti_observacion}
                        onChange={(e) => handleChange(e)}
                        multiline
                        rows={3}
                        variant="outlined"
                      />
                    </>
                  </>
                )}
                {seccionActive === "ARCHIVOS" && (
                  <>
                    <CampoDoc
                      setcargando={setcargando}
                      propiedad={"acti_documento"}
                      item={actividad}
                      setitem={setitem}
                      celda={false}
                    />

                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={acti_fotos}
                    />
                  </>
                )}
                {seccionActive === "ASIGNACIÓN" && (
                  <ActividadesTab
                    estadoPersonas={estadoPersonas}
                    datoOperativo={datoOperativo}
                    itemPadre={itemPadre}
                    asignarActividad={asignarActividad}
                  />
                )}
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsiActividad);
