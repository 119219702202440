import React, { useState } from "react";

import { ExpandLess, ExpandMore, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  DialogContentText,
  Typography,
  IconButton,
  Badge,
  Collapse,
  DialogContent,
} from "@mui/material";
import Trabajo from "./Trabajos/Trabajo";
const Persona = (props) => {
  const { persona } = props;
  const [expand, setexpand] = useState(false); 
  return (
    <>
      <DialogContentText
        id="scroll-dialog-description"
        tabIndex={-1}
        mb={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Badge
            badgeContent={persona.trabajos.length}
            color="primary"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <IconButton onClick={() => setexpand((prev) => !prev)} size="small">
              {expand ? (
                <ExpandLess fontSize="small" color="primary" />
              ) : (
                <ExpandMore fontSize="small" color="primary" />
              )}
            </IconButton>
          </Badge>
          <Typography variant="body2" color="primary" sx={{ mr: "2rem" }}>
            <Typography variant="body2" color="secondary" component={"span"}>
              {persona.perDocNumero} {persona.perApellidos} {persona.perNombres}
            </Typography>
          </Typography>
        </Box>
        <Avatar
          src={persona.perFoto}
          sx={{ width: "2.5rem", height: "2.5rem" }}
        >
          <Person />
        </Avatar>
      </DialogContentText>
      <Collapse in={expand}>
        <DialogContent dividers>
          {persona.trabajos.map((itemTrabajo, index) => (
            <Trabajo itemTrabajo={itemTrabajo} key={index} index={index} />
          ))}
        </DialogContent>
      </Collapse>
    </>
  );
};

export default Persona;
