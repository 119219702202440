import React, { memo, useEffect } from "react";

import { useState } from "react";

import ConfirmacionDialog from "../../../../../../components/Extra/ConfirmacionDialog";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
} from "@mui/material";
import { Cancel, Check, Create, Delete } from "@mui/icons-material";
import { quitarTildes } from "../../../../../../config/const";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const { consigna, item, setitem, index } = props;

  const [itemEdit, setItemEdit] = useState({ ...consigna });
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();
   

  const funcSubmit = () => {
    const docDocumentos = item.docDocumentos.map((item, itemIndex) => {
      if (index === itemIndex) {
        return { ...itemEdit, propiedad: quitarTildes(itemEdit.propiedad) };
      } else {
        return item;
      }
    });

    setitem({
      ...item,
      docDocumentos,
    });
    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (itemEdit.nombre === "") {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setItemEdit({ ...consigna });
    // eslint-disable-next-line
  }, [item]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      docDocumentos: item.docDocumentos.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  return (
    <React.Fragment>
      {" "}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {editar ? (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(false);

                    setItemEdit({ ...consigna });
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  color={
                    botonDisabled
                      ? botonDisabled()
                        ? "inherit"
                        : "secondary"
                      : "secondary"
                  }
                  disabled={botonDisabled()}
                  className={classes.margin}
                  onClick={() => {
                    funcSubmit();

                    seteditar(false);
                    setItemEdit({ ...consigna });
                  }}
                >
                  <Check fontSize="small" />
                </IconButton>{" "}
              </>
            ) : (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(true);
                  }}
                >
                  <Create fontSize="small" color={"secondary"} />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <Delete fontSize="small" color={"error"} />
                </IconButton>
              </>
            )}
          </Box>
        </TableCell>

        {/* las demas columnas */}
        {editar ? (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                autoFocus
                id="nombre"
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) =>
                  setItemEdit({
                    ...itemEdit,
                    [e.target.name]: e.target.value,
                    propiedad: e.target.value
                      .replace(/\s+/g, "")
                      .toLocaleLowerCase(),
                  })
                }
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id="obligatorio"
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.obligatorio}
                name="obligatorio"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["SI", "NO"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id="extension"
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.extension}
                name="extension"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["IMAGEN", "PDF"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id="fecExpira"
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.fecExpira}
                name="fecExpira"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["SI", "NO"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.nombre}
            </TableCell>

            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.obligatorio}
            </TableCell>

            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.extension}
            </TableCell>

            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.fecExpira}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
