import React from "react";
import { Calendar } from "react-multi-date-picker";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Divider,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import { ContentPaste, CopyAll } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
 
const useStyles = makeStyles((theme) => ({
  formD: {
    flexGrow: 1,

    width: "40%",
    [theme.breakpoints.down("md")]: {
      padding: "0",

      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      marginTop: ".8rem",
      width: "95%",
    },
  },
  formD4PR: {
    flexGrow: 1,

    width: "24%",
    [theme.breakpoints.down("md")]: {
      padding: "0",

      width: "32%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",

      width: "95%",
    },
  },
  formD3PRSizeSmall: {
    flexGrow: 1,
    width: "15%",
  },
  formAllW: {
    flexGrow: 1,

    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  inputPadding: {
    padding: "0",
    fontSize: ".8rem",
    margin: "0",
  },
}));
const latino = {
  name: "español",
  months: [
    ["Enero", "Ene"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "May"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sep"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"],
  ],
  weekDays: [
    ["Sabado", "Sab"],
    ["Domingo", "Dom"],
    ["Lunes", "Lun"],
    ["Martes", "Mar"],
    ["Miercoles", "Mie"],
    ["Jueves", "Jue"],
    ["Viernes", "Vie"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

const Calendario = (props) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  const clases = useStyles();
  const {
    propiedad,
    propiedadDB,
    focusedDate,
    setFocusedDate,
    item,
    setitem,
    TextoPropiedad,
    textoPropiedadDB, 
  } = props;
  const [seccionActivaDia, setseccionActivaDia] = useState({
    dia: 1,
  });

  const { dia } = seccionActivaDia;
  const [horasDefault, sethorasDefault] = useState({
    horaDesde: "",
    horaHasta: "",
  });
  const [diasSelect, setdiasSelect] = useState([]);
  const [mesesSelect, setmesesSelect] = useState([]);
  const [turno, setturno] = useState("DIA");
  const [añosInput, setañosInput] = useState(new Date().getFullYear());
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [diferenciaTiempo] = useState(
    new Date().getTimezoneOffset() * 60 * 1000
  );
  function getDaysInMonth(month, year, resDiasNumber) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
      const existe = resDiasNumber.some(
        (diaNumber) => diaNumber === new Date(date).getDay()
      );
      if (existe) {
        days.push(Date.parse(date));
      }
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  const parametrizar = () => {
    const mesesNumber = {
      ENERO: 0,
      FEBRERO: 1,
      MARZO: 2,
      ABRIL: 3,
      MAYO: 4,
      JUNIO: 5,
      JULIO: 6,
      AGOSTO: 7,
      SEPTIEMBRE: 8,
      OCTUBRE: 9,
      NOVIEMBRE: 10,
      DICIEMBRE: 11,
    };

    const diasNumber = {
      LUNES: 1,
      MARTES: 2,
      MIERCOLES: 3,
      JUEVES: 4,
      VIERNES: 5,
      SABADO: 6,
      DOMINGO: 0,
    };

    const resDiasNumber = diasSelect.map((item) => {
      return diasNumber[item];
    });

    let days = [];
    mesesSelect.forEach((mesSelect) => {
      days.push(
        ...getDaysInMonth(mesesNumber[mesSelect], añosInput, resDiasNumber)
      );
    });

    setData(days);
  };
  const crearFecha = (date, personalizable) => {
    if (date === "") {
      return "";
    }
    if (!date) {
      return "";
    }

    const resParse = isNaN(date) ? Date.parse(date) : date;
    const DateParseDiff = resParse - diferenciaTiempo;

    if (turno === "NOCHE" && personalizable) {
      const resFecha = new Date(
        DateParseDiff + 60 * 60 * 10 * 10 * 10 * 24
      ).toISOString();

      return resFecha.substring(0, 19);
    }
    const resFecha = new Date(DateParseDiff).toISOString();
    return resFecha.substring(0, 19);
  };
  const setData = (days) => {
    const resDB = days.map((itemFecha, index) => {
      const itemFDB = propiedadDB.find((itemDB) => {
        return (
          itemDB.desde.substr(0, 10) === crearFecha(itemFecha).substr(0, 10)
        );
      });

      return {
        desde: itemFDB
          ? itemFDB.desde
          : horasDefault.horaDesde === ""
          ? crearFecha(itemFecha)
          : crearFecha(itemFecha).substring(0, 11) +
            horasDefault.horaDesde +
            ":00",
        hasta: itemFDB
          ? itemFDB.hasta
          : horasDefault.horaHasta === ""
          ? crearFecha(itemFecha, true)
          : crearFecha(itemFecha, true).substring(0, 11) +
            horasDefault.horaHasta +
            ":00",
      };
    });

    setitem({
      ...item,
      [TextoPropiedad]: days.map((itemE) => {
        return {
          desde: itemE,
          hasta: "",
        };
      }),
      [textoPropiedadDB]: resDB,
    });
  };

  return (
    <>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de reiniciar las fechas?`}
        funcion={() => {
          setitem({
            ...item,
            [TextoPropiedad]: [],
            [textoPropiedadDB]: [],
          });
        }}
      />
      <Box p={2} width="100%">
        <>
          <Box
            style={
              {
                // width: "100%",
              }
            }
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            p={1}
          >
            <TextField
              className={clases.formD4PR}
              size="small"
              label="Día"
              name="dia"
              value={diasSelect}
              onChange={(e) => {
                setdiasSelect(e.target.value);
              }}
              select
              MenuProps={MenuProps}
              SelectProps={{
                displayEmpty: true,
                multiple: true,
                renderValue: (selected) => {
                  return selected
                    .map((value, index) => {
                      return value;
                    })
                    .join(", ");
                },
              }}
            >
              <MenuItem disabled value="">
                Seleccione.
              </MenuItem>
              {[
                "LUNES",
                "MARTES",
                "MIERCOLES",
                "JUEVES",
                "VIERNES",
                "SABADO",
                "DOMINGO",
              ].map((item) => {
                return (
                  <MenuItem
                    style={getStylesMenuItems(item, diasSelect, theme)}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              className={clases.formD4PR}
              size="small"
              label="Meses"
              name="dia"
              value={mesesSelect}
              onChange={(e) => {
                setmesesSelect(e.target.value);
              }}
              select
              MenuProps={MenuProps}
              SelectProps={{
                displayEmpty: true,
                multiple: true,
                renderValue: (selected) => {
                  return selected
                    .map((value, index) => {
                      return value;
                    })
                    .join(", ");
                },
              }}
            >
              <MenuItem disabled value="">
                Seleccione.
              </MenuItem>

              {[
                "ENERO",
                "FEBRERO",
                "MARZO",
                "ABRIL",
                "MAYO",
                "JUNIO",
                "JULIO",
                "AGOSTO",
                "SEPTIEMBRE",
                "OCTUBRE",
                "NOVIEMBRE",
                "DICIEMBRE",
              ].map((item) => {
                return (
                  <MenuItem
                    style={getStylesMenuItems(item, mesesSelect, theme)}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              type="number"
              className={clases.formD4PR}
              size="small"
              label="Año"
              value={añosInput}
              onChange={(e) => {
                const min = new Date().getFullYear();
                const max = new Date().getFullYear() + 2;

                setañosInput(
                  e.target.valueAsNumber < min
                    ? min
                    : e.target.value > max
                    ? max
                    : e.target.valueAsNumber
                );
              }}
            />
            <TextField
              className={clases.formD4PR}
              size="small"
              label="Turno"
              value={turno}
              onChange={(e) => {
                setturno(e.target.value);
              }}
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["DIA", "NOCHE"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
            <Button
              variant="contained"
              color="info"
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "40%",
                  lg: "30%",
                },
                margin: "5px auto",
              }}
              onClick={() => {
                parametrizar();
              }}
            >
              Parametrizar
            </Button>
            {propiedad.length > 0 ? (
              <Button
                variant="contained"
                color="error"
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "40%",
                    lg: "30%",
                  },
                  margin: "5px auto",
                }}
                onClick={() => {
                  setopenConfirmDialog(true);
                }}
              >
                Reiniciar
              </Button>
            ) : null}
          </Box>
          <Box width="100%" justifyContent="space-around" display={"flex"}>
            <Calendar
              Layout="mobile"
              style={{ minWidth: "50rem" }}
              locale={latino}
              weekStartDayIndex={1}
              numberOfMonths={1}
              value={propiedad.map((item) => item.desde)}
              onChange={(e) => {
                setData(e);
              }}
              sort
              onFocusedDateChange={setFocusedDate}
              mapDays={({ date, isSameDate }) => {
                let props = {};

                if (!isSameDate(date, focusedDate)) return;

                props.style = { backgroundColor: "red" };

                return props;
              }}
            />
          </Box>

          <Divider style={{ width: "100%" }} />
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems=" stretch"
              width="100%"
            >
              <Box>
                <Button
                  variant={dia === 1 ? "contained" : null}
                  disableElevation
                  style={{
                    padding: "5px",
                  }}
                  color="secondary"
                  onClick={() => setseccionActivaDia({ dia: 1 })}
                >
                  Lunes
                </Button>
                <Button
                  variant={dia === 2 ? "contained" : null}
                  disableElevation
                  style={{
                    padding: "5px",
                  }}
                  color="secondary"
                  onClick={() => setseccionActivaDia({ dia: 2 })}
                >
                  Martes
                </Button>
                <Button
                  variant={dia === 3 ? "contained" : null}
                  disableElevation
                  style={{
                    padding: "5px",
                  }}
                  color="secondary"
                  onClick={() => setseccionActivaDia({ dia: 3 })}
                >
                  Miercoles
                </Button>
                <Button
                  variant={dia === 4 ? "contained" : null}
                  disableElevation
                  style={{
                    padding: "5px",
                  }}
                  color="secondary"
                  onClick={() => setseccionActivaDia({ dia: 4 })}
                >
                  Jueves
                </Button>
                <Button
                  variant={dia === 5 ? "contained" : null}
                  disableElevation
                  style={{
                    padding: "5px",
                  }}
                  color="secondary"
                  onClick={() => setseccionActivaDia({ dia: 5 })}
                >
                  Viernes
                </Button>
                <Button
                  variant={dia === 6 ? "contained" : null}
                  disableElevation
                  style={{
                    padding: "5px",
                  }}
                  color="secondary"
                  onClick={() => setseccionActivaDia({ dia: 6 })}
                >
                  Sabado
                </Button>
                <Button
                  variant={dia === 0 ? "contained" : null}
                  disableElevation
                  style={{
                    padding: "5px",
                  }}
                  color="secondary"
                  onClick={() => setseccionActivaDia({ dia: 0 })}
                >
                  Domingo
                </Button>
              </Box>
              <Button
                variant={dia === "todos" ? "contained" : null}
                disableElevation
                style={{
                  padding: "5px",
                }}
                color="secondary"
                onClick={() => setseccionActivaDia({ dia: "todos" })}
              >
                Todos
              </Button>
            </Box>
            <Divider style={{ width: "100%" }} />
            <Box
              style={{
                width: "100%",
              }}
              display="flex"
              flexWrap="wrap"
              alignItems="flex-end"
              p={1}
            >
              <Box className={clases.formD} textAlign={"center"}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type={"time"}
                  className={clases.formAllW}
                  size="small"
                  label="Desde Default"
                  value={horasDefault.horaDesde}
                  name="perFecNacimiento"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          disabled={horasDefault.horaDesde === ""}
                          aria-label=""
                          onClick={() => {
                            const res = propiedadDB.map(
                              (itemEdit, indexItemEdit) => {
                                const validacion = new Date(
                                  itemEdit.desde
                                ).getDay();
                                if (validacion !== dia && dia !== "todos") {
                                  return itemEdit;
                                }
                                return {
                                  ...itemEdit,
                                  desde:
                                    itemEdit.desde.substring(0, 11) +
                                    horasDefault.horaDesde +
                                    ":00",
                                };
                              }
                            );

                            setitem({
                              ...item,
                              [textoPropiedadDB]: res,
                            });
                          }}
                        >
                          <CopyAll
                            color={
                              horasDefault.horaDesde === ""
                                ? "disabled"
                                : "primary"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    sethorasDefault({
                      ...horasDefault,
                      horaDesde: e.target.value,
                    });
                  }}
                />
              </Box>
              <Box className={clases.formD} textAlign={"center"}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type={"time"}
                  className={clases.formAllW}
                  size="small"
                  label="Hasta Default"
                  value={horasDefault.horaHasta}
                  name="perFecNacimiento"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          disabled={horasDefault.horaHasta === ""}
                          aria-label=""
                          onClick={() => {
                            const res = propiedadDB.map(
                              (itemEdit, indexItemEdit) => {
                                const validacion = new Date(
                                  itemEdit.desde
                                ).getDay();
                                if (validacion !== dia && dia !== "todos") {
                                  return itemEdit;
                                }
                                return {
                                  ...itemEdit,
                                  hasta:
                                    itemEdit.hasta.substring(0, 11) +
                                    horasDefault.horaHasta +
                                    ":00",
                                };
                              }
                            );

                            setitem({
                              ...item,
                              [textoPropiedadDB]: res,
                            });
                          }}
                        >
                          <CopyAll
                            color={
                              horasDefault.horaHasta === ""
                                ? "disabled"
                                : "primary"
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    sethorasDefault({
                      ...horasDefault,
                      horaHasta: e.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
            <Divider style={{ width: "100%" }} />
            <Box
              style={{
                width: "100%",
                overflow: "scroll",
                maxHeight: "15rem",
              }}
              display="flex"
              flexWrap="wrap"
              alignItems="flex-end"
              p={1}
            >
              {propiedadDB
                .sort((a, b) => {
                  return Date.parse(Number(a)) - Date.parse(Number(b));
                })
                .map((itemFecha, index) => {
                  const fechaRes = new Date(
                    Date.parse(itemFecha.desde.substr(0, 10)) +
                      1000 * 60 * 60 * 24
                  )
                    .toISOString()
                    .substr(0, 10);
                  const validacion = new Date(itemFecha.desde).getDay();
                  if (validacion !== dia && dia !== "todos") {
                    return null;
                  }
                  return (
                    <>
                      <TextField
                        type={"datetime-local"}
                        className={clases.formD}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          className: clases.inputPadding,
                          min: itemFecha.desde.substr(0, 16),
                          max: itemFecha.desde.substr(0, 10) + "T00:00",
                        }}
                        error={
                          crearFecha(focusedDate).substr(0, 10) ===
                          itemFecha.desde.substr(0, 10)
                            ? "focused"
                            : ""
                        }
                        size="small"
                        id="Fecha"
                        label={`Inicio`}
                        value={itemFecha.desde.substr(0, 16)}
                        name="Fecha"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                disabled={horasDefault.horaDesde === ""}
                                aria-label=""
                                onClick={() => {
                                  const res = propiedadDB.map(
                                    (itemEdit, indexItemEdit) => {
                                      if (indexItemEdit === index) {
                                        return {
                                          ...itemEdit,
                                          desde:
                                            itemFecha.desde.substring(0, 11) +
                                            horasDefault.horaDesde +
                                            ":00",
                                        };
                                      } else {
                                        return itemEdit;
                                      }
                                    }
                                  );

                                  setitem({
                                    ...item,
                                    [textoPropiedadDB]: res,
                                  });
                                }}
                              >
                                <ContentPaste
                                  color={
                                    horasDefault.horaDesde === ""
                                      ? "disabled"
                                      : "primary"
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            return;
                          }

                          const res = propiedadDB.map(
                            (itemEdit, indexItemEdit) => {
                              if (indexItemEdit === index) {
                                return {
                                  ...itemEdit,
                                  desde: e.target.value,
                                };
                              } else {
                                return itemEdit;
                              }
                            }
                          );

                          setitem({
                            ...item,
                            [textoPropiedadDB]: res,
                          });
                        }}
                      />
                      <TextField
                        type={"datetime-local"}
                        className={clases.formD}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          className: clases.inputPadding,
                          min: itemFecha.desde.substr(0, 16),
                          max: fechaRes + "T23:59",
                        }}
                        error={
                          crearFecha(focusedDate).substr(0, 10) ===
                          itemFecha.desde.substr(0, 10)
                            ? "focused"
                            : ""
                        }
                        size="small"
                        id="Fecha"
                        label={`Final`}
                        value={itemFecha.hasta.substr(0, 16)}
                        name="Fecha"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton
                                disabled={horasDefault.horaHasta === ""}
                                aria-label=""
                                onClick={() => {
                                  const res = propiedadDB.map(
                                    (itemEdit, indexItemEdit) => {
                                      if (indexItemEdit === index) {
                                        return {
                                          ...itemEdit,
                                          hasta:
                                            itemFecha.hasta.substring(0, 11) +
                                            horasDefault.horaHasta +
                                            ":00",
                                        };
                                      } else {
                                        return itemEdit;
                                      }
                                    }
                                  );

                                  setitem({
                                    ...item,
                                    [textoPropiedadDB]: res,
                                  });
                                }}
                              >
                                <ContentPaste
                                  color={
                                    horasDefault.horaHasta === ""
                                      ? "disabled"
                                      : "primary"
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          const res = propiedadDB.map(
                            (itemEdit, indexItemEdit) => {
                              if (indexItemEdit === index) {
                                return {
                                  ...itemEdit,
                                  hasta:
                                    Date.parse(e.target.value) >
                                    Date.parse(itemFecha.desde.substr(0, 16))
                                      ? e.target.value
                                      : itemFecha.desde.substr(0, 16),
                                };
                              } else {
                                return itemEdit;
                              }
                            }
                          );

                          setitem({
                            ...item,
                            [textoPropiedadDB]: res,
                          });
                        }}
                      />
                    </>
                  );
                })}
            </Box>
          </>
        </>
      </Box>
    </>
  );
};

export default Calendario;
