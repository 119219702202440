import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  IconButton,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { usePermiso } from "../../hooks/usePermiso";
import { FechaExacta, trimPropsItem } from "../../../config/const";
import TablaCabecera from "../../components/TablaCabecera";
import RowItem from "../Componentes/RowItems/RowItem";
import { columnasHijo, objectDefaultHijo } from "../data";
import CampoDoc from "../../components/CampoDoc";
import DocumentoCelda from "../../components/DocumentoCelda";
import clienteAxios from "../../../config/axios";
import ToolBarTable from "../../components/ToolBarTable";
import { hi } from "date-fns/locale";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
    changeObjetoActivoState,
    setopenModal,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Planificacion");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const [cargando, setcargando] = useState(false);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.estClienteNombre === "" ||
      item.estPeriodo === "" ||
      item.estFecInicio === "" ||
      item.estFecFinal === "" ||
      item.estMonto === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "planificacion",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        planUser: usuario.usuario,
        planEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,

          tabla: "planificacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          planUser: usuario.usuario,
          planEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "planificacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          planUser: usuario.usuario + "/+/" + row[campoExport],
          planEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
          planIdEmisor: usuario.id, // login id
          planNombreEmisor: usuario.nombre, // login nombre
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.planUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: !(
                row.planIdReceptor === Number(usuario.id) ||
                row.planIdEmisor === Number(usuario.id) ||
                usuario.rol.some((itemRol) => itemRol === "ADMIN")
              ),
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                // console.log(row.planIdEmisor, usuario.id);
                // if (
                //   row.planIdEmisor !== Number(usuario.id) ||
                //   usuario.rol.some((itemRol) => itemRol === "ADMIN")
                // ) {
                //   return;
                // }
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              // !(
              //   row.planIdReceptor === Number(usuario.id) ||
              //   row.planIdEmisor === Number(usuario.id) ||
              //   usuario.rol.some((itemRol) => itemRol === "ADMIN")
              // )
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled() || cargando,
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/planificacion_individual?planId=${row.planId}&rucempresa=${row.planEmpresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={claseCelda}
            >
              <CampoDoc
                cargando={cargando}
                setcargando={setcargando}
                propiedad={"planDocumentoCabecera"}
                item={item}
                setitem={setitem}
                celda={true}
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.planNombre}
                name="planNombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type={"number"}
                inputProps={{ min: FechaExacta }}
                value={item.planYear}
                name="planYear"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type={"date"}
                inputProps={{ min: FechaExacta }}
                value={item.planFec1}
                name="planFec1"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type={"date"}
                inputProps={{ min: FechaExacta }}
                value={item.planFec2}
                name="planFec2"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.planVersion}
                name="planVersion"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.planUser}
            </TableCell>
          </>
        ) : (
          <>
            <DocumentoCelda
              propiedad={row.planDocumentoCabecera}
              claseCelda={claseCelda}
              Icono={<PictureAsPdf color="error" fontSize="small" />}
            />
            <TableCell size="small" align="center" className={claseCelda}>
              {row.planNombre}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.planYear}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.planFec1}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.planFec2}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.planVersion}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.planUser.split("/+/")[0]}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ToolBarTable
                ocultarPaginacion={true}
                botonesIzquierdos={[
                  {
                    tooltip: "Agregar",
                    texto: "",
                    funcion: () => {
                      changeObjetoActivoState({
                        ...objectDefaultHijo,
                        padre: row,
                      });
                      setopenModal(true);
                    },
                    disabled: false,
                    Icon: AddCircle,
                    color: "primary",
                    id: 1,
                    ocultar: false,
                    tipo: "icono",
                    variante: "text",
                    size: "medium",
                    sx: {},
                  },
                ]}
                botonesDerechos={[]}
              />
              <TableContainer>
                <Table size="small" aria-label="purchases">
                  <TablaCabecera
                    input={{ id: "" }}
                    columns={columnasHijo}
                    habilitarOrdenar={false}
                  />
                  <TableBody component={"div"}>
                    {row.planDatosHijos.map((hijo) => (
                      <RowItem
                        key={hijo.uuid}
                        socket={socket}
                        rowPadre={row}
                        hijo={hijo}
                        changeObjetoActivoState={changeObjetoActivoState}
                        setopenModal={setopenModal}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
