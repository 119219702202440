import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  MenuItem,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { trimPropsItem } from "../../../config/const";
import { objectDefault } from "../data";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { useEffect } from "react";

const RowNuevo = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    estadoRequest,
    setopen,
    socket,
    tiposData,
    // IOP
    fromIOP,
    clienteIOP,
  } = props;
  useEffect(() => {
    if (fromIOP) {
      const getPuestos = async () => {
        const res = await clienteAxios.get(
          `/clientes/filtro/0?search=${clienteIOP.cliDocNumero}&estado=CLIENTES`
        );
        const itemF = res.data.data.find(
          (itemCliente) => itemCliente.cliId === Number(clienteIOP.cliId)
        );
        setdatosOperativos(itemF.cliDatosOperativos);
        setclientesData(res.data.data);
      };
      getPuestos();
      setitem({
        ...objectDefault,
        eveCliId: clienteIOP.cliId,
        eveCliDocumento: clienteIOP.cliDocNumero,
        eveCliNombre: clienteIOP.cliRazonSocial,
      });
    } // eslint-disable-next-line
  }, [fromIOP]);

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(objectDefault);
  // DATA
  const [clientesData, setclientesData] = useState([]);
  const [datosOperativos, setdatosOperativos] = useState([]);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.eveNombre === "" ||
      item.eveTipo === "" ||
      item.eveCliDocumento === "" ||
      (item.eveCliPuesto === "" && estadoRequest !== "SUPERVISOR")
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          evePerfil: estadoRequest,
          nombre: usuario.nombre,
          tabla: "eventoactividad",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          eveUser: usuario.usuario,
          eveEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                PopperComponent={PopperMy}
                disableClearable={true}
                options={clientesData.map((item) => {
                  return (
                    item.cliRazonSocial +
                    "/+/" +
                    item.cliDocNumero +
                    "/+/" +
                    item.cliId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={
                  (item.eveCliNombre + " " + item.eveCliDocumento).trim() === ""
                    ? ""
                    : item.eveCliNombre + " " + item.eveCliDocumento
                }
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    const itemF = clientesData.find(
                      (itemCliente) => itemCliente.cliId === Number(options[2])
                    );
                    setdatosOperativos(itemF.cliDatosOperativos);

                    setitem({
                      ...item,
                      eveCliNombre: options[0],
                      eveCliDocumento: options[1],
                      eveCliId: options[2],
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return setclientesData([]);
                      }
                      try {
                        const res = await clienteAxios.get(
                          `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                        );

                        setclientesData(res.data.data);
                      } catch (error) {
                        setitem({
                          ...item,
                        });
                        setclientesData([]);

                        return mostrarAlerta("No hay clientes", "error");
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          {estadoRequest !== "SUPERVISOR" && (
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={datosOperativos.map((item) => {
                    return item.puesto + "/+/" + item.ubicacion;
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", "- ");
                  }}
                  id="controlled-demo"
                  value={
                    (item.eveCliPuesto + " " + item.eveCliUbicacion).trim() ===
                    ""
                      ? ""
                      : item.eveCliPuesto + " - " + item.eveCliUbicacion
                  }
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const [eveCliPuesto, eveCliUbicacion] = newValue.split(
                        "/+/"
                      );
                      const itemF = datosOperativos.find(
                        (itemDatoO) =>
                          itemDatoO.puesto === eveCliPuesto &&
                          itemDatoO.ubicacion === eveCliUbicacion
                      );
                      setitem({
                        ...item,
                        eveCoordenadas: itemF.coordenadas,
                        eveCliPuesto,
                        eveCliUbicacion,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
          )}
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              value={item.eveTipo}
              name="eveTipo"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {tiposData.map((item) => {
                return (
                  <MenuItem key={item.novId} value={item.novTipo}>
                    {item.novTipo}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              value={item.eveNombre}
              name="eveNombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {new Date().toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowNuevo));
