import React, { useState, useContext, useEffect, memo, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import Dropzone from "../../../components/Extra/Dropzone";
import Draggable from "react-draggable";
import { AddCircle, Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { FechaExacta, trimPropsItem } from "../../../config/const";
import clienteAxios from "../../../config/axios";
import { objectDefaultHijo } from "../data";
import CampoDoc from "../../components/CampoDoc";
import ToolBarTable from "../../components/ToolBarTable";
import { useSimpleTable } from "../../hooks/useSimpleTable";
import TablaCabecera from "../../components/TablaCabecera";
import RowAgregarMetodo from "./Metodos/RowAgregarMetodo";
import RowMetodo from "./Metodos/RowMetodo";
import ImagenCelda from "../../components/ImagenCelda";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
import { v4 } from "uuid";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  // const { tienePermiso, alertaPermiso } = usePermiso("Examenes");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);

  const {
    open,
    setopen,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    estadoRequest,
  } = props;
  const [receptor, setReceptor] = useState(false);
  const [emisor, setEmisor] = useState(false);

  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const {
    setmodificoImagenes: setmodificoImagenesStatus,
    subirImagenes: subirImagenesStatus,
    imagenes: imagenesStatus,
    setimagenes: setimagenesStatus,
  } = useMultimediasUpload();
  // eslint-disable-next-line
  const {
    agregandoMetodo,
    setagregandoMetodo,
    pageMetodo,
    // setPageMetodo,
    rowsPerPageMetodo,
    // setRowsPerPageMetodo,
    handleChangePageMetodo,
    handleChangeRowsPerPageMetodo,
  } = useSimpleTable("Metodo");

  const [seccionActive, setseccionActive] = useState({
    SeccionDatos: true,
    SeccionMetodos: false,
    SeccionAcciones: false,
    SeccionDocumentos: false,
  });
  const {
    SeccionDatos,
    SeccionMetodos,
    SeccionAcciones,
    SeccionDocumentos,
  } = seccionActive;
  // eslint-disable-next-line
  const [item, setitem] = useState(objectDefaultHijo);
  const editando = useMemo(() => Boolean(item.uuid), [item]);
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const [modalStyle] = useState(getModalStyle);
  const {
    // tab DATOS GENERALES
    // planSecuencia,
    planFecha,
    planFechaAtencion,
    planPrioridad,
    planProceso,
    // planIdEmisor,
    // planNombreEmisor,
    // planIdReceptor,
    // planDocumentoReceptor,
    planNombreReceptor,
    // planMotivo,
    planTipo,
    planDetalles,
    // tab METODOS
    planMetodo,
    // { metodo: "", porque: "" }
    // solo permitir 5 registros (las 2 columnas son input)
    // tab ACCIONES
    // planIdCliente, //: "",
    // planNombreCliente, //: "",
    // planDocumentoCliente, //""
    // planAsunto, //: "",
    planDetalleAcciones, //: "", // textarea
    planAccionesFecha, //: "",
    planAccionesStatus, //: "NO PROCESADO", // PROCESADO
    // planAccionesDocumento, //: "",
    planAccionesFotos, //: "",
    // planDocumento, //: "",
    // planGuardias, //: [],
    // planSupervisores, //: [],
    // planAdministracion, //: [],
    // planFechas, //: [],
    // planFechasDB, //: [],
    planFotos, //: [],
  } = item;

  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [receptoresData, setreceptoresData] = useState([]);
  useEffect(() => {
    if (usuario.rol.some((itemRol) => itemRol === "ADMIN")) {
      setEmisor(false);
      return setReceptor(false);
    }
    if (ObjectoActivo.planIdEmisor === Number(usuario.id)) {
      setEmisor(true);
    }
    if (ObjectoActivo.planIdReceptor === Number(usuario.id)) {
      setReceptor(true);
    }
    // eslint-disable-next-line
  }, [ObjectoActivo]);
  useEffect(() => {
    if (open) {
      setitem(ObjectoActivo);
    }
  }, [ObjectoActivo, open]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefaultHijo);
    setopen(false);
    seterror({});
    setcargando(false);

    setReceptor(false);
    setEmisor(false);
    setimagenes([]);
    setimagenesStatus([]);
    setmodificoImagenes(false);
    setmodificoImagenesStatus(false);
  };

  //hook de estilos
  const clases = useStyles();

  const validacion = () => {
    if (false) {
      seterror({});
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, planFotos);

      const resDocumentosAcciones = await subirImagenesStatus(
        imagenesStatus,
        planAccionesFotos
      );

      const { padre: PadreEdit, ...hijoNuevo } = item;
      PadreEdit.planDatosHijos.unshift({
        ...hijoNuevo,
        uuid: v4(),
        planFotos: resDocumentos,
        planAccionesFotos: resDocumentosAcciones,
        planAccionesFecha:
          item.planAccionesFecha.trim() === "" && receptor ? FechaExacta : "",
        planIdEmisor: usuario.id, // login id
        planNombreEmisor: usuario.nombre, // login nombre
      });

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...PadreEdit,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "planificacion",
          planUser: usuario.usuario,
          planEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, planFotos);

      const resDocumentosAcciones = await subirImagenesStatus(
        imagenesStatus,
        planAccionesFotos
      );

      const { padre: PadreEdit, ...hijoNuevo } = item;
      const planDatosHijosEdit = PadreEdit.planDatosHijos.map((planHijo) =>
        planHijo.uuid === hijoNuevo.uuid
          ? {
              ...hijoNuevo,
              planFotos: resDocumentos,
              planAccionesFotos: resDocumentosAcciones,
              planAccionesFecha:
                item.planAccionesFecha.trim() === "" && receptor
                  ? FechaExacta
                  : "",
            }
          : planHijo
      );

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...PadreEdit,
          planDatosHijos: planDatosHijosEdit,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "planificacion",
          planUser: usuario.usuario,
          planEmpresa: usuario.rucempresa,
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  if (!open) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editando ? "Editando" : "Crear"}
                  {" Planificación"} ({estadoRequest})
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      SeccionDatos
                        ? "contained"
                        : error.planSecuencia ||
                          error.planFecha ||
                          error.planFechaAtencion ||
                          error.planProceso ||
                          error.planIdEmisor ||
                          error.planNombreEmisor ||
                          error.planIdReceptor ||
                          error.planDocumentoReceptor ||
                          error.planNombreReceptor ||
                          error.planMotivo ||
                          error.planDetalles
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.planSecuencia ||
                      error.planFecha ||
                      error.planFechaAtencion ||
                      error.planProceso ||
                      error.planIdEmisor ||
                      error.planNombreEmisor ||
                      error.planIdReceptor ||
                      error.planDocumentoReceptor ||
                      error.planNombreReceptor ||
                      error.planMotivo ||
                      error.planDetalles
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ SeccionDatos: true })}
                  >
                    Datos Generales
                  </Button>
                  <Button
                    variant={
                      SeccionDocumentos
                        ? "contained"
                        : error.planDocumento ||
                          error.planFechas ||
                          error.planFotos
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.planDocumento || error.planFechas || error.planFotos
                        ? "error"
                        : "secondary"
                    }
                    onClick={() =>
                      setseccionActive({ SeccionDocumentos: true })
                    }
                  >
                    Documentos
                  </Button>
                  <Button
                    variant={
                      SeccionMetodos
                        ? "contained"
                        : error.planMetodo
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.planMetodo ? "error" : "secondary"}
                    onClick={() => setseccionActive({ SeccionMetodos: true })}
                  >
                    Metodo
                  </Button>
                  <Button
                    variant={
                      SeccionAcciones
                        ? "contained"
                        : error.planNombreCliente ||
                          error.planAsunto ||
                          error.planDetalleAcciones ||
                          error.planDocumento ||
                          error.planGuardias ||
                          error.planSupervisores ||
                          error.planAdministracion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.planNombreCliente ||
                      error.planAsunto ||
                      error.planDetalleAcciones ||
                      error.planGuardias ||
                      error.planSupervisores ||
                      error.planAdministracion
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ SeccionAcciones: true })}
                  >
                    ACCIONES
                  </Button>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                mt={1}
                maxHeight={"60vh"}
                overflow="scroll"
                sx={{ overflowX: "hidden" }}
              >
                {SeccionDatos ? (
                  <>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Fecha"
                      value={planFecha}
                      name="planFecha"
                      error={error.planFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Area"
                      value={planProceso}
                      name="planProceso"
                      error={error.planProceso}
                      onChange={async (e) => {
                        try {
                          const res = await clienteAxios.get(
                            `/personas/filtroporareadepartamento/0?search&estado=${e.target.value}`
                          );

                          setreceptoresData(res.data.data);
                          setitem({
                            ...item,
                            [e.target.name]: e.target.value,
                            planIdReceptor: "",
                            planDocumentoReceptor: "",
                            planNombreReceptor: "",
                          });
                        } catch (error) {}
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      {[
                        "LOGISTICA",
                        "ADMINISTRACION",
                        "GERENTE",
                        "ASISTENTE DE GERENCIA",
                        "JEFE DE OPERACIONES",
                        "COMPRAS PUBLICAS",
                        "TALENTO HUMANO",
                        "CONTADOR",
                        "SECRETARIA",
                        "SERVICIOS VARIOS",
                        "OTROS",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="planIdEmisor"
                      value={planIdEmisor}
                      name="planIdEmisor"
                      error={error.planIdEmisor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="planNombreEmisor"
                      value={planNombreEmisor}
                      name="planNombreEmisor"
                      error={error.planNombreEmisor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        disabled={receptor}
                        options={receptoresData.map((item) => {
                          return (
                            item.perApellidos +
                            "/+/" +
                            item.perNombres +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={planNombreReceptor}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = receptoresData.find(
                              (itemPersona) =>
                                itemPersona.perId === Number(options[2])
                            );
                            setitem({
                              ...item,
                              planIdReceptor: itemF.perId,
                              planDocumentoReceptor: itemF.perDocNumero,
                              planNombreReceptor:
                                itemF.perApellidos + " " + itemF.perNombres,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            disabled={receptor}
                            fullWidth
                            label="Receptor"
                            error={error.planNombreCliente}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="planIdReceptor"
                      value={planIdReceptor}
                      name="planIdReceptor"
                      error={error.planIdReceptor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="planDocumentoReceptor"
                      value={planDocumentoReceptor}
                      name="planDocumentoReceptor"
                      error={error.planDocumentoReceptor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="planNombreReceptor"
                      value={planNombreReceptor}
                      name="planNombreReceptor"
                      error={error.planNombreReceptor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <TextField
                      disabled
                      className={clases.formD}
                      size="small"
                      label="Motivo"
                      value={planTipo}
                      name="planTipo"
                      error={error.planTipo}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      {["RIESGO", "OBJETIVO"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      type="datetime-local"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: new Date(
                          Date.now() -
                            new Date().getTimezoneOffset() * 60 * 1000 +
                            86400000
                        )
                          .toISOString()
                          .split("T")[0],
                      }}
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Fecha de atención"
                      value={planFechaAtencion}
                      name="planFechaAtencion"
                      error={error.planFechaAtencion}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Prioridad"
                      value={planPrioridad}
                      name="planPrioridad"
                      error={error.planPrioridad}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      {["ALTA", "MEDIA", "BAJA"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      disabled={receptor}
                      size="small"
                      label="Detalles"
                      value={planDetalles}
                      name="planDetalles"
                      error={error.planDetalles}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
                {SeccionMetodos ? (
                  <>
                    {error.invProveedor ? (
                      <Typography
                        variant="caption"
                        color="error"
                        fontWeight="bold"
                      >
                        INGRESE AL MENOS UN PROVEEDOR.
                      </Typography>
                    ) : null}
                    <Paper className={clases.root}>
                      <ToolBarTable
                        count={planMetodo.length}
                        rowsPerPage={rowsPerPageMetodo}
                        page={pageMetodo}
                        handleChangePage={handleChangePageMetodo}
                        handleChangeRowsPerPage={handleChangeRowsPerPageMetodo}
                        ocultarPaginacion={false}
                        simplePagination={true}
                        botonesIzquierdos={[
                          {
                            tooltip: "Agregar",
                            texto: "Agregar",
                            funcion: () => {
                              setagregandoMetodo(true);
                            },
                            disabled: planMetodo.length >= 5 || emisor,
                            Icon: AddCircle,
                            color: "primary",
                            id: 1,
                            ocultar: false,
                            tipo: "boton",
                            variante: "contained",
                            size: "medium",
                            sx: {},
                          },
                        ]}
                        botonesDerechos={[]}
                      />

                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                        >
                          <TableHead>
                            <TablaCabecera
                              columns={[
                                {
                                  id: "metodo",
                                  align: "center",
                                  minWidth: 200,
                                  tipo: "string",
                                  label: "Accion",
                                },
                                {
                                  id: "porque",
                                  align: "center",
                                  minWidth: 200,
                                  tipo: "string",
                                  label: "Respuesta",
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody component={"div"}>
                            {agregandoMetodo ? (
                              <RowAgregarMetodo
                                setagregar={setagregandoMetodo}
                                setitemPadre={setitem}
                                itemPadre={item}
                              />
                            ) : null}
                            {planMetodo
                              .slice(
                                pageMetodo * rowsPerPageMetodo,
                                pageMetodo * rowsPerPageMetodo +
                                  rowsPerPageMetodo
                              )
                              .map((itemHijo, index) => {
                                return (
                                  <RowMetodo
                                    setitemPadre={setitem}
                                    itemPadre={item}
                                    itemHijo={itemHijo}
                                    index={index}
                                    key={index}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}
                {SeccionAcciones ? (
                  <>
                    <TextField
                      disabled
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      className={clases.formD}
                      size="small"
                      label="Fec. Respuesta"
                      value={planAccionesFecha}
                      name="planAccionesFecha"
                      error={error.planAccionesFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Estado"
                      value={planAccionesStatus}
                      name="planAccionesStatus"
                      error={error.planAccionesStatus}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      {emisor
                        ? planAccionesStatus === "NO PROCESADO"
                          ? [
                              // "ASIGNADA",
                              "ANULADA",
                              "PROCESADO",
                              "EN PROCESO",
                              "NO PROCESADO",
                            ].map((item, index) => {
                              if (item === "NO PROCESADO") return;
                              return (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              );
                            })
                          : // planAccionesStatus === "ASIGNADA"
                            // ? [
                            //     "ASIGNADA",
                            //     "PROCESADO",
                            //     "ANULADA",
                            //     "NO PROCESADO",
                            //   ].map((item, index) => {
                            //     return (
                            //       <MenuItem value={item} key={index}>
                            //         {item}
                            //       </MenuItem>
                            //     );
                            //   })
                            // :
                            null
                        : planAccionesStatus === "NO PROCESADO"
                        ? [
                            "NO PROCESADO",
                            //  "ASIGNADA",
                            "ANULADA",
                            "PROCESADO",
                            "EN PROCESO",
                          ].map((item, index) => {
                            if (item === "NO PROCESADO") return;
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          })
                        : // planAccionesStatus === "ASIGNADA"
                          // ? ["ASIGNADA", "PROCESADO", "NO PROCESADO"].map(
                          //     (item, index) => {
                          //       return (
                          //         <MenuItem value={item} key={index}>
                          //           {item}
                          //         </MenuItem>
                          //       );
                          //     }
                          //   )
                          // :
                          ["ANULADA", "PROCESADO", "EN PROCESO"].map(
                            (item, index) => {
                              return (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              );
                            }
                          )}
                    </TextField>
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      label="Detalle Acciones"
                      value={planDetalleAcciones}
                      name="planDetalleAcciones"
                      error={error.planDetalleAcciones}
                      onChange={(e) => handleChange(e)}
                    />

                    <CampoDoc
                      disabled={emisor}
                      disabledE={emisor}
                      setcargando={setcargando}
                      propiedad={"planAccionesDocumento"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                    {emisor ? (
                      <>
                        <ImagenCelda
                          propiedad={planAccionesFotos}
                          array={true}
                          claseCelda={clases.formAllW}
                          texto="Imagenes:"
                        />
                      </>
                    ) : (
                      <Dropzone
                        imagenes={imagenesStatus}
                        setimagenes={(e) => {
                          setmodificoImagenesStatus(true);
                          setimagenesStatus(e);
                        }}
                        fotosItem={planAccionesFotos}
                      />
                    )}
                  </>
                ) : null}
                {SeccionDocumentos ? (
                  <>
                    <>
                      <CampoDoc
                        disabled={receptor}
                        disabledE={receptor}
                        setcargando={setcargando}
                        propiedad={"planDocumento"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                      <CampoDoc
                        disabled={receptor}
                        disabledE={receptor}
                        setcargando={setcargando}
                        propiedad={"planDocumento2"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                      {receptor ? (
                        <>
                          <ImagenCelda
                            propiedad={planFotos}
                            array={true}
                            claseCelda={clases.formAllW}
                            texto="Imagenes:"
                          />
                        </>
                      ) : (
                        <Dropzone
                          imagenes={imagenes}
                          setimagenes={(e) => {
                            setmodificoImagenes(true);
                            setimagenes(e);
                          }}
                          fotosItem={planFotos}
                        />
                      )}
                    </>
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                ></Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    disabled={
                      planAccionesStatus === "ANULADA" ||
                      planAccionesStatus === "PROCESADO" ||
                      cargando
                    }
                    className={clases.formD}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (editando) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
