import React, { memo, useContext } from "react";

import { Collapse, TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { usePermiso } from "../../hooks/usePermiso";
import clienteAxios from "../../../config/axios";
import CollapseCell from "../../components/CollapseCell";
import { trimPropsItem } from "../../../config/const";
import Consignas from "../../Personas/Consignas/Consignas";
import CeldaUser from "../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("FOP");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // CELDA CONTRAIDA
  const [celComprimida, setcelComprimida] = useState({});
  // STATE
  const [cargado, setcargado] = useState(false);
  const [expandir, setexpandir] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "fiscalizacion",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        fisUser: usuario.usuario,
        fisEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "fiscalizacion",
          fisEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
          fisUser: usuario.usuario + "/+/" + row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.fisUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 3,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/fop_individual?fisId=${row.fisId}&rucempresa=${row.fisEmpresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setcargado(true);
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "primary",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.fisFechaFiscalizacion.split("T")[0]}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {row.fisCliDocNumero} {row.fisCliRazonSocial}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.fisPuestoServicio}
          </TableCell>
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"fisCumplimientoGuardias"}
          />
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"fisCumplimientoSupervisor"}
          />
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"fisObservacionesCliente"}
          />
          
          <CeldaUser user={row.fisUser} claseCelda={claseCelda} />
        </>
      </TableRow>{" "}
      <TableRow>
        <TableCell
          className={claseCelda}
          sx={{ paddingBottom: 0 }}
          colSpan={21}
        >
          <Collapse in={expandir} timeout="auto">
            {cargado ? (
              <Consignas
                fromFOP={true}
                onlyRead={true}
                // setitemFOP={setitem}
                itemFOP={row}
                socketProp={socket}
              />
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
