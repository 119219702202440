import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import React from "react";
import Cargando from "../../components/Cargando";
import { useState } from "react";
import clienteAxios from "../../../config/axios";
import { useEffect } from "react";

const DialogFechas = ({ open, setopen, rowHijo }) => {
  return (
    <Dialog
      open={open}
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      onClose={() => setopen(false)}
      aria-labelledby={"FECHAS EVENTOS"}
    >
      <DialogTitle textAlign={"center"}>Eventos</DialogTitle>
      <DialogContent dividers sx={{ py: 0 }}>
        {rowHijo.planiActividades.map((item) => (
          <Box my={1} key={item.id}>
            <Box
              textAlign={"center"}
              display={"flex"}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Typography variant="body1" color="secondary" width={"50%"}>
                {item.nombre}
              </Typography>
              <Typography
                textAlign={"center"}
                key={item.id}
                variant="body2"
                color="secondary"
                width={"50%"}
              >
                {rowHijo.planiPerfil === "GUARDIA"
                  ? "Intervalo de minutos"
                  : "Número de revisiones"}
                {": "}
                <Typography color="primary" component={"span"}>
                  {item.minutos}
                </Typography>
              </Typography>
            </Box>
            <Divider>
              <Typography variant="body2" color="secondary">
                Horarios
              </Typography>
            </Divider>
            {item.horario
              .sort((a, b) => {
                const [horaA, minutosA] = a.horaFinal.split(":");
                const [horaB, minutosB] = b.horaFinal.split(":");

                const aTotal = Number(horaA) * 60 + Number(minutosA);
                const bTotal = Number(horaB) * 60 + Number(minutosB);

                return aTotal - bTotal;
              })
              .map((horario) => (
                <Typography
                  key={item.id}
                  variant="body2"
                  color="primary"
                  textAlign={"center"}
                >
                  {horario.hora} - {horario.horaFinal}
                </Typography>
              ))}
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setopen(false)} color="primary">
          LISTO
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFechas;
