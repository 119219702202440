import React, { memo, useContext } from "react";

import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import {
  Cancel,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Refresh,
  SettingsSuggest,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import clienteAxios from "../../../../../config/axios";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import SubHijo from "../../SubHijo/SubHijo";
import Cargando from "../../../../components/Cargando";
import { usePermiso } from "../../../../hooks/usePermiso";
import AuthContext from "../../../../../Context/Auth/AuthContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    getDataState,
    // HOOK DE PERMISO
    // tienePermiso,
    // alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    eliminarState,
    // SOCKET
    socket,
    tabla,
    // ESTADO
    estadoRequest,
  } = props;
  const { tienePermiso, alertaPermiso } = usePermiso("Rolpagos");
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;

  // CONTEXT
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openConfirmDialogEliminar, setopenConfirmDialogEliminar] = useState(
    false
  );
  const [
    openConfirmDialogSincronizar,
    setopenConfirmDialogSincronizar,
  ] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [cargado, setcargado] = useState(false);
  const [cargando, setcargando] = useState(false);
  // ELIMINAR EL REGISTRO
  const [
    cargandoBotonesConfirmacion,
    setcargandoBotonesConfirmacion,
  ] = useState(false);
  const funcionProcesar = async () => {
    try {
      setcargandoBotonesConfirmacion(true);

      const res = await clienteAxios.post("/rolpagos/procesar", {
        rolpPeriodo: rowHijo.rolpPeriodo,
        rolpIdCliente: rowHijo.rolpIdCliente,
        option: estadoRequest === "EN PROCESO" ? "1" : "2",
      });

      setopenConfirmDialog(false);
      mostrarAlerta(res.data.msg, "success");
      eliminarState(rowHijo.rolpId);
      setcargandoBotonesConfirmacion(false);
    } catch (error) {
      console.log({ error });

      setcargandoBotonesConfirmacion(false);
      mostrarAlerta(error?.response?.data?.msg || "Hubo un error", "error");
    }
  };
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        rolpUser: usuario.usuario,
        rolpEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        rolpPeriodo: rowHijo.rolpPeriodo,
        rolpDocNumCliente: rowHijo.rolpDocNumCliente,
        rolpIdCliente: rowHijo.rolpIdCliente,
      });
      setopenConfirmDialogEliminar(false);
    } catch (error) {
      console.log({ error });
    }
  };
  if (rowHijo.eliminado) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialogEliminar}
        setopen={setopenConfirmDialogEliminar}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialogEliminar(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openConfirmDialogSincronizar}
        setopen={() => !cargando && setopenConfirmDialogSincronizar()}
        categoria={"warning"}
        titulo={
          cargando ? (
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="body1" color="initial" component={"span"}>
                Cargando Sincronizacion de rol
                <Cargando />
              </Typography>
            </Box>
          ) : (
            `¿Esta seguro de sincronizar este rol?`
          )
        }
        botones={[
          {
            tooltip: "Se sincronizaran los roles",
            texto: "SI",
            funcion: async () => {
              try {
                setcargando(true);

                const res = await clienteAxios.post("/rolpagos/sincronizar", {
                  periodo: rowPadre.rolpPeriodo,
                  cliente: rowHijo.rolpDocNumCliente,
                });
                mostrarAlerta(res.data.msg, "success");
                setopenConfirmDialogSincronizar(false);
                eliminarState(rowHijo.rolpId);
                setcargando(false);
              } catch (error) {
                setcargando(false);
                mostrarAlerta("Hubo un error", "error");
              }
            },
            disabled: cargando,
            Icon: Refresh,
            color: "success",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialogSincronizar(false);
            },
            disabled: cargando,
            Icon: Cancel,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿${
          estadoRequest === "EN PROCESO"
            ? "Esta seguro de PROCESAR roles de este cliente:"
            : "Esta seguro que desea volver a ejecutar un rol de este cliente:"
        } ${rowHijo.rolpDocNumCliente + " " + rowHijo.rolpNomCliente}?`}
        botones={[
          {
            tooltip: "Se procesaran los registros",
            texto: "SI",
            funcion: () => {
              funcionProcesar();
            },
            disabled: cargandoBotonesConfirmacion,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: cargandoBotonesConfirmacion,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setcargado(true);
                setexpandir(!expandir);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "success",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Procesar",
              texto: "",
              funcion: () => {
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: Refresh,
              color: "primary",
              id: 3,
              ocultar: estadoRequest === "EN PROCESO",
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "Procesar",
              texto: "",
              funcion: () => {
                setopenConfirmDialogSincronizar(true);
              },
              disabled: estadoRequest === "PROCESADO",
              Icon: SettingsSuggest,
              color: "primary",
              id: 1,
              ocultar: estadoRequest === "PROCESADO",
              tipo: "icono",
              variante: "text",
              size: "medium",
              sx: {},
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                // if (!tienePermiso("eliminar")) {
                //   return alertaPermiso("eliminar");
                // }
                setopenConfirmDialogEliminar(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowHijo.eliminado || estadoRequest === "PROCESADO",
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="left" className={claseCelda}>
            {rowHijo.rolpDocNumCliente + " " + rowHijo.rolpNomCliente}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.tguardias}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.tsueldo}
          </TableCell>
        </>
      </TableRow>{" "}
      <TableRow>
        <TableCell
          className={claseCelda}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
        >
          <Collapse in={expandir} timeout="auto">
            {cargado ? (
              <SubHijo
                // ROW
                rowHijo={rowHijo}
                rowPadre={rowPadre}
                // OPEN Y EDIT
                setopen={setopen}
                seteditar={seteditar}
                // EXPORT
                campoExport={campoExport}
                arrayExport={arrayExport}
                setarrayExport={setarrayExport}
                // DATA
                expandir={expandir}
                tabla={tabla}
                estadoRequest={estadoRequest}
                // FUNCION STATE
                getDataState={getDataState}
                changeObjetoActivoState={changeObjetoActivoState}
                // SOCKET
                socket={socket}
              />
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
