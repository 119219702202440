import React, { useState, useContext, useEffect, memo } from "react";
import Draggable from "react-draggable";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  ListItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Avatar,
  ListItemText,
  Collapse,
  List,
  MenuItem,
} from "@mui/material";
import { Cancel, Backspace, ExpandLess, ExpandMore } from "@mui/icons-material";
import { getMonths, navegadorCompatible } from "../../../config/const";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const containerRef = React.useRef(null);
  const { open, setopen, ver, setver, setpagination, setrows } = props;
  const getPersonasData = async (documento) => {
    const res = await clienteAxios.get(
      `/clientes/filtro/0?search=${""}&estado=CLIENTES`
    );

    const arrayMap = res.data.data.map((itemRes) => {
      return {
        perId: itemRes.cliId,
        perDocNumero: itemRes.cliDocNumero,
        perNombres: itemRes.cliNombreComercial,
        perfoto: itemRes.perFoto,
      };
    });
    setitem({
      ...item,
      rolpPersonas: arrayMap,
    });

    setpersonasData(arrayMap);
    setpersonasDataDefault(arrayMap);
    setfiltrando(false);
    return arrayMap;
  };
  useEffect(() => {
    if (open) {
      getPersonasData();
    } // eslint-disable-next-line
  }, [open]);

  const [seccionActive, setseccionActive] = useState({
    datosPrincipales: true,
    ingresos: false,
    egresos: false,
  });
  const { datosPrincipales } = seccionActive;
  const [personasData, setpersonasData] = useState([]);
  const [filtrando, setfiltrando] = useState(false);
  const [filtro, setfiltro] = useState("");
  const [personasDataDefault, setpersonasDataDefault] = useState([]);
  const [openCollapse, setopenCollapse] = useState(true);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { socket, usuario } = useContext(AuthContext);
  //props

  const [item, setitem] = useState({
    rolpPeriodo: "",
    rolpPersonas: [],
  });
  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line
  const {
    // rolpCodigo,
    // rolpIdCliente,
    rolpPeriodo,
    rolpPersonas,
  } = item;

  // eslint-disable-next-line
  const [error, seterror] = useState({
    rolpPeriodo: false,
    rolpIdCliente: false,
    rolpDocNumCliente: false,
    rolpNomCliente: false,
    rolpPersonas: false,
  });

  const defaultActive = () => {
    setitem({
      rolpPeriodo: "",
      rolpPersonas: [],
    });

    setopen(false);

    seterror({
      rolpPeriodo: false,
      rolpIdCliente: false,
      rolpDocNumCliente: false,
      rolpNomCliente: false,
      rolpPersonas: false,
    });
    // setseccionActive({
    //   datosPersonalesSeccion: true,
    //   datosContablesSeccion: false,
    // });
    setver(false);
    setcargando(false);
    setseccionActive({
      datosPrincipales: true,
      ingresos: false,
      egresos: false,
    });

    setpersonasData([]);
    setfiltrando(false);
    setfiltro("");
    setpersonasDataDefault([]);
    setopenCollapse(true);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (rolpPeriodo.trim() === "") {
      return true;
    } else {
      return false;
    }
  };

  // eslint-disable-next-line
  const validacion = () => {};
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Los campos son obligatorios", "error");
    }
    try {
      setcargando(true);

      const res = await clienteAxios.post("/rolpagos", {
        ...item,
        rolpPersonas: rolpPersonas.map((item) => item.perId),
      });

      setrows((prev) => [
        ...res.data.data.results.map((item) => {
          return { ...item, nuevo: true };
        }),
        ...prev,
      ]);
      setpagination((prev) => {
        return {
          ...prev,
          numRows: prev.numRows + res.data.data.results.length,
        };
      });
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta(error.response.data.msg, "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line
  function obtenerMeses() {
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth(); // Los meses en JavaScript van de 0 a 11

    // Calculamos el mes anterior (restando 1 al mes actual)
    const mesAnterior = mesActual === 0 ? 11 : mesActual - 1;

    // Formateamos los meses con el formato deseado
    const mesActualFormateado = `${anio}-${(mesActual + 1)
      .toString()
      .padStart(2, "0")}`;
    const mesAnteriorFormateado = `${anio}-${(mesAnterior + 1)
      .toString()
      .padStart(2, "0")}`;

    // Agregamos los meses al arreglo 'meses'
    const meses = [];
    meses.push(mesActualFormateado);
    meses.push(mesAnteriorFormateado);

    return meses;
  }

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Agregar Rol Pago.
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  {/* <Button
                  variant={datosPrincipales ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ datosPrincipales: true })}
                >
                  Datos Principales
                </Button>
                <Button
                  variant={ingresos ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ ingresos: true })}
                >
                  Ingresos
                </Button>
                <Button
                  variant={egresos ? "contained" : null}
                  disableElevation
                  style={{ padding: "8px" }}
                  color="secondary"
                  onClick={() => setseccionActive({ egresos: true })}
                >
                  Egresos
                </Button> */}
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      conEstado === "ACTIVO" || conEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                {datosPrincipales ? (
                  <Fade
                    direction="up"
                    in={datosPrincipales}
                    style={{ width: "100%" }}
                  >
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                      <>
                        {navegadorCompatible ? (
                          <TextField
                            type="month"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              className: clases.inputPadding,
                              min: new Date(
                                new Date().setMonth(new Date().getMonth() - 1)
                              )
                                .toISOString()
                                .substr(0, 7),
                              max: new Date(
                                new Date().setMonth(new Date().getMonth())
                              )
                                .toISOString()
                                .substr(0, 7),
                            }}
                            className={clases.formD}
                            size="small"
                            id="rolpPeriodo"
                            label="Periodo"
                            value={rolpPeriodo}
                            name="rolpPeriodo"
                            error={error.rolpPeriodo}
                            onChange={(e) => handleChange(e)}
                          />
                        ) : (
                          <TextField
                            inputProps={{
                              className: clases.inputPadding,
                            }}
                            className={clases.formD}
                            size="small"
                            label="Periodo"
                            value={rolpPeriodo}
                            name="rolpPeriodo"
                            error={error.rolpPeriodo}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="" disabled>
                              Seleccione.
                            </MenuItem>
                            {obtenerMeses(true).map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                        )}

                        <Box className={clases.formAllW}>
                          <ListItem>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={
                                    !personasData.some((itemPersona) => {
                                      //si encuentra iguales es por que existen, se revierten para que sean false y no se cumpla el some de clientes data,
                                      // si da false en redirigido dara true, y terminando e lsome de clientes data
                                      //  y se pondra en  false el checked ya que no existe un registro
                                      const noExiste = !rolpPersonas.some(
                                        (itemDirigido) => {
                                          return (
                                            itemPersona.perId ===
                                            itemDirigido.perId
                                          );
                                        }
                                      );
                                      // DevuelveTrueNecesitoColocarloFalse

                                      return noExiste;
                                    })
                                  }
                                  onChange={(e) => {
                                    if (
                                      !personasData.some((itemPersona) => {
                                        const noExiste = !rolpPersonas.some(
                                          (itemDirigido) => {
                                            return (
                                              itemPersona.perId ===
                                              itemDirigido.perId
                                            );
                                          }
                                        );
                                        // DevuelveTrueNecesitoColocarloFalse

                                        return noExiste;
                                      })
                                    ) {
                                      //   ...item,
                                      //   rolpPersonas: [],
                                      // });
                                      setitem({
                                        ...item,
                                        rolpPersonas: [],
                                      });
                                    } else {
                                      //   ...item,
                                      //   rolpPersonas: personasData,
                                      // });
                                      setitem({
                                        ...item,
                                        rolpPersonas: personasData,
                                      });
                                    }
                                  }}
                                />
                              }
                            />
                            <ListItemText
                              primary="Todos"
                              style={{ marginRight: "1rem" }}
                              className={
                                error.rolpPersonas ? clases.error : null
                              }
                            />
                            <Box
                              display={"flex"}
                              width="100%"
                              alignItems="center"
                              height={"100%"}
                            >
                              <TextField
                                inputProps={{ className: clases.inputPadding }}
                                className={clases.formD}
                                size="small"
                                label="Filtrar"
                                value={filtro}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment>
                                      {filtrando ? (
                                        <IconButton
                                          aria-label=""
                                          onClick={() => {
                                            setfiltrando(false);
                                            setfiltro("");
                                            setpersonasData(
                                              personasDataDefault
                                            );
                                          }}
                                        >
                                          <Backspace color="error" />
                                        </IconButton>
                                      ) : null}
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    setfiltrando(false);
                                    setfiltro("");
                                    return setpersonasData(personasDataDefault);
                                  }
                                  setfiltrando(true);
                                  setfiltro(e.target.value);
                                  const res = personasDataDefault.filter(
                                    (itemCliente) =>
                                      itemCliente.perNombres
                                        .toLowerCase()
                                        .includes(
                                          e.target.value.toLowerCase()
                                        ) ||
                                      itemCliente.perDocNumero
                                        .toLowerCase()
                                        .includes(e.target.value.toLowerCase())
                                  );
                                  setpersonasData(res);
                                }}
                              />{" "}
                            </Box>
                            <IconButton
                              aria-label=""
                              onClick={() => setopenCollapse(!openCollapse)}
                            >
                              {openCollapse ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                          </ListItem>
                          <Divider />
                          <Collapse
                            in={openCollapse}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List dense component="div" disablePadding>
                              {[...personasData].map((itemCliente) => {
                                return (
                                  <ListItem
                                    style={{ paddingLeft: "1rem" }}
                                    key={itemCliente.perId}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={rolpPersonas.some(
                                            (itemDirigido) => {
                                              return (
                                                itemDirigido.perId ===
                                                itemCliente.perId
                                              );
                                            }
                                          )}
                                          onChange={(e) => {
                                            if (
                                              rolpPersonas.some(
                                                (itemDirigido) => {
                                                  return (
                                                    itemDirigido.perId ===
                                                    itemCliente.perId
                                                  );
                                                }
                                              )
                                            ) {
                                              //   ...item,
                                              //   rolpPersonas: rolpPersonas.filter(
                                              //     (itemDirigido) => {
                                              //       return (
                                              //         itemDirigido.perId !==
                                              //         itemCliente.perId
                                              //       );
                                              //     }
                                              //   ),
                                              // });
                                              setitem({
                                                ...item,
                                                rolpPersonas: rolpPersonas.filter(
                                                  (itemDirigido) => {
                                                    return (
                                                      itemDirigido.perId !==
                                                      itemCliente.perId
                                                    );
                                                  }
                                                ),
                                              });
                                            } else {
                                              const nuevoArray = [
                                                ...rolpPersonas,
                                                itemCliente,
                                              ];

                                              //   ...item,
                                              //   rolpPersonas: nuevoArray,
                                              // });
                                              setitem({
                                                ...item,
                                                rolpPersonas: nuevoArray,
                                              });
                                            }
                                          }}
                                        />
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        itemCliente.perNombres +
                                        " ** " +
                                        itemCliente.perDocNumero
                                      }
                                    />
                                    <Avatar
                                      alt={`Avatar: ${itemCliente.perNombres}`}
                                      src={itemCliente.perFoto}
                                    />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Collapse>
                        </Box>
                      </>
                    </Box>
                  </Fade>
                ) : null}
              </Box>
              {/* <Divider /> */}
              {ver ? null : (
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    mt={1}
                  >
                    <Button
                      style={{ width: "30%" }}
                      variant="contained"
                      color="primary"
                      disabled={submit() || cargando}
                      onClick={() => {
                        handleCrear();
                      }}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
