import React, { useState, useEffect, useContext } from "react";
import { v4 } from "uuid";
import Turno from "./Turno";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, FormControl, Typography } from "@mui/material";
import {
  AddCircle,
  // CloudDownload,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },

  form25PC: {
    flexGrow: 1,

    width: "23%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "70%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));
const Turnos = (props) => {
  const clases = useStyles();
  const { item, setitem, error, estadoRequest } = props;
  const [cargando, setcargando] = useState(true);
  const { mostrarAlerta } = useContext(AlertaContext);
  useEffect(() => {
    const resTurnos = item.perTurno.map((itemTurno) => {
      if (itemTurno.fechas && itemTurno.fechasConsultaDB && itemTurno.id) {
        return {
          ...itemTurno,
          fechasConsultaDB:
            itemTurno.fechasConsultaDB.length > 0 && itemTurno.salida
              ? []
              : itemTurno.fechasConsultaDB,
        };
      } else {
        return {
          ...itemTurno,
          fechas: [],
          fechasConsultaDB: [],
          id: v4(),
        };
      }
    });
    setitem({
      ...item,
      perTurno: resTurnos,
    });
    setcargando(false);
    // eslint-disable-next-line
  }, []);
  if (cargando) {
    return null;
  }
  return (
    <>
      <FormControl className={clases.formAllW}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="subtitle1"
            color={error.perTurno ? "error" : "primary"}
          >
            Turnos:
          </Typography>
          <IconButton
            aria-label=""
            size="small"
            onClick={async () => {
              const existeVacio = item.perTurno.some((item) => {
                return (
                  item.clienteIngreso === "" ||
                  item.idClienteIngreso === "" ||
                  item.docClienteIngreso === "" ||
                  item.puestoIngreso === "" ||
                  item.clienteSalida === "" ||
                  item.idClienteSalida === "" ||
                  item.docClienteSalida === "" ||
                  item.puestoSalida === "" ||
                  item.fechas.length === 0 ||
                  item.fechasConsultaDB.length === 0
                );
              });

              if (existeVacio) {
                return mostrarAlerta("Complete todos los campos.", "error");
              }
              setitem({
                ...item,
                perTurno: [
                  ...item.perTurno,
                  {
                    clienteIngreso: "",
                    idClienteIngreso: "",
                    docClienteIngreso: "",
                    clienteUbicacionIngreso: "",
                    puestoIngreso: "",
                    clienteSalida: "",
                    idClienteSalida: "",
                    docClienteSalida: "",
                    clienteUbicacionSalida: "",
                    puestoSalida: "",
                    fechas: [],
                    fechasConsultaDB: [],
                    id: v4(),
                  },
                ],
              });
            }}
          >
            <AddCircle fontSize="small" color="secondary" />
          </IconButton>
        </Box>
      </FormControl>
      {item.perTurno.map((turno, index) => {
        return (
          <Turno
            estadoRequest={estadoRequest}
            key={turno.id}
            index={index}
            turno={turno}
            item={item}
            setitem={setitem}
          />
        );
      })}
    </>
  );
};

export default Turnos;
