import React, { useState, useContext, memo } from "react";
import Draggable from "react-draggable";
// eslint-disable-next-line

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

import clienteAxios from "../../../../../../../config/axios";
import AlertaContext from "../../../../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../../../../Context/Auth/AuthContext";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "40%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",

    marginBottom: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  // eslint-disable-next-line
  //props
  const { item, setitem, open, setopen, row } = props;

  const [cargando, setcargando] = useState(false);

  // eslint-disable-next-line
  const {
    // cliId
    // rucempresa
    hoja,
    cantidad,
    ancho,
    alto,
  } = item;
  // eslint-disable-next-line

  const containerRef = React.useRef(null);
  const [error, seterror] = useState({});

  const defaultActive = () => {
    setopen(false);
    setitem({
      cliId: "",
      rucempresa: "",
      hoja: "A4",
      cantidad: "1",
      ancho: "95",
      alto: "95",
    });
    seterror({
      cliId: false,
      rucempresa: false,
      hoja: false,
      cantidad: false,
      ancho: false,
      alto: false,
    });
    setseccionActive({
      DatosGenerales: true,
    });
    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
  });
  // eslint-disable-next-line
  const { DatosGenerales } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (
      hoja.trim() === "" ||
      cantidad.trim() === "" ||
      ancho.trim() === "" ||
      alto.trim() === ""
    ) {
      mostrarAlerta("Faltan datos obligatorios por llenar", "error");
      return seterror({
        hoja: hoja.trim() === "" ? true : false,
        cantidad: cantidad.trim() === "" ? true : false,
        ancho: ancho.trim() === "" ? true : false,
        alto: alto.trim() === "" ? true : false,
      });
    }
    try {
      setcargando(true);
      //jback.neitor.com/api/reportes/qractividad?act_asigId=23&rucempresa=PRUEBA&hoja=A4&cantidad=1&ancho=95&alto=95
      window.open(
        `${clienteAxios.defaults.baseURL}reportes/qractividad?id=${row.planiId}&rucempresa=${usuario.rucempresa}&hoja=${item.hoja}&cantidad=${item.cantidad}&ancho=${item.ancho}&alto=${item.alto}&option=planificacion&option=planificacion_actividad`
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Generar QR.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  {" "}
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />{" "}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box> </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      invEstado === "ACTIVO" || invEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        invEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                <Fade
                  direction="up"
                  in={DatosGenerales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosGenerales ? (
                      <>
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="hoja"
                          label="Hoja"
                          value={hoja}
                          name="hoja"
                          error={error.hoja}
                          onChange={async (e) => {
                            handleChange(e);
                          }}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {[
                            "A4",
                            "A5",
                            "A6",
                            "A7",
                            "A8",
                            "A9",
                            "Legal",
                            "Letter",
                          ].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          type={"number"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="cantidad"
                          label="Cantidad"
                          value={cantidad}
                          name="cantidad"
                          error={error.cantidad}
                          onChange={async (e) => {
                            handleChange(e);
                          }}
                        />
                        <TextField
                          type={"number"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="ancho"
                          label="Ancho"
                          value={ancho}
                          name="ancho"
                          error={error.ancho}
                          onChange={async (e) => {
                            handleChange(e);
                          }}
                        />
                        <TextField
                          type={"number"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="alto"
                          label="Alto"
                          value={alto}
                          name="alto"
                          error={error.alto}
                          onChange={async (e) => {
                            handleChange(e);
                          }}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={submit() || cargando}
                  onClick={() => {
                    handleCrear();
                  }}
                >
                  Generar QR
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
