import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "regDia",
    label: "Fecha",
    minWidth: 50,
    widthExcel: 30,
    tipo: "string",
    profundidad: 1,
    campo1: "regDia",
    campo2: "",
    campo3: "",
  },
];
export const columnsHijos = [
  {
    id: "regDocumento",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDocumento",
    align: "left",
    ocultar: true,
  },

  {
    id: "regHoraRegistroIngreso",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraRegistroIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraIngreso",
    label: "H.INGRESO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraRegistroIngreso",
    label: "H. Registro",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraRegistroIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regStatusIngreso",
    label: "ESTADO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regStatusIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regTiempoIngreso",
    label: "TIEMPO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regTiempoIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraSalida",
    label: "H.SALIDA",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraSalida",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraRegistroSalida",
    label: "H.REGISTRO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraRegistroSalida",
    align: "center",
    ocultar: true,
  },

  {
    id: "regStatusSalida",
    label: "ESTADO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regStatusSalida",
    align: "center",
    ocultar: true,
  },

  {
    id: "regTiempoSalida",
    label: "TIEMPO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regTiempoSalida",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {};
