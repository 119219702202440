import React, { memo, useContext } from "react";

import {
  Autocomplete,
  Box,
  Collapse,
  FormControl,
  IconButton,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  Check,
  Create,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { FechaExacta, trimPropsItem } from "../../../../config/const";
import TablaCabecera from "../../../components/TablaCabecera";
import RowAgregarItem from "../Componentes/RowItems/RowAgregarItem";
import RowItem from "../Componentes/RowItems/RowItem";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../config/axios";
import { columnasHijo } from "../data";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { arrayExport, setarrayExport, row, index, campoExport, socket } =
    props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("EstadoCuentas");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // SI SE AGREGA UN ITEM A LA TABLA HIJA
  const [agregarItemLista, setagregarItemLista] = useState(false);
  // CLIENTES
  const [clientesData, setclientesData] = useState([]);
  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA
   
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.estClienteNombre === "" ||
      item.estPeriodo === "" ||
      item.estFecInicio === "" ||
      item.estFecFinal === "" ||
      item.estMonto === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        
        tabla: "estadocuenta",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        estUser: usuario.usuario,
        estEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          
          tabla: "estadocuenta",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          estUser: usuario.usuario,
          estEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,
          
          tabla: "estadocuenta",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          estUser: usuario.usuario + "/+/" + row[campoExport],
          estEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };
  const PopperMy = function (props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.estUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={clientesData.map((item) => {
                    return (
                      item.cliRazonSocial +
                      "/+/" +
                      item.cliDocNumero +
                      "/+/" +
                      item.cliId
                    );
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ** ").split("/+/")[0];
                  }}
                  value={item.estClienteNombre}
                  input
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");

                      setitem({
                        ...item,
                        estClienteNombre: options[0],
                        estClienteDocu: options[1],
                        estClienteId: options[2],
                      });
                    } else {
                      setitem({
                        ...item,
                        estClienteNombre: "",
                        estClienteDocu: "",
                        estClienteId: "",
                      });
                      setclientesData([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              fontSize: "1px",
                              maxHeight: 48 * 4.5 + 8,
                              width: 5000,
                            },
                          },
                        },
                      }}
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          return mostrarAlerta("Ingrese datos", "error");
                        }
                        try {
                          if (e.target.value.length < 3) {
                            return;
                          }
                          const res = await clienteAxios.get(
                            `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                          );

                          setclientesData(res.data.data);
                        } catch (error) {
                          setitem({
                            ...item,
                            estClienteNombre: "",
                            estClienteDocu: "",
                            estClienteId: "",
                          });
                          setclientesData([]);

                          return mostrarAlerta("No hay clientes", "error");
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                inputProps={{ style: { textAlign: "center" } }}
                value={item.estPeriodo}
                name="estPeriodo"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {[
                  "DIARIO",
                  "SEMISNAL",
                  "MENSUAL",
                  "TRIMESTRAL",
                  "SEMESTRAL",
                  "ANUAL",
                ].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type={"date"}
                inputProps={{ style: { textAlign: "center" }, min: FechaExacta }}
                value={item.estFecInicio.substr(0, 16).replace("T", " ")}
                name="estFecInicio"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type={"date"}
                inputProps={{ style: { textAlign: "center" }, min: FechaExacta }}
                value={item.estFecFinal.substr(0, 16).replace("T", " ")}
                name="estFecFinal"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type="number"
                inputProps={{ style: { textAlign: "center" } }}
                value={item.estMonto}
                name="estMonto"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.estSaldo}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {item.estEstado}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {usuario.usuario}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell size="small" align="left" className={claseCelda}>
              {row.estClienteNombre}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.estPeriodo}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.estFecInicio}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.estFecFinal}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.estMonto}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.estSaldo}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.estEstado}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {row.estUser.split("/+/")[0]}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" component="span">
                  Cuota:
                </Typography>
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  input={{ id: "" }}
                  columns={columnasHijo}
                  habilitarOrdenar={false}
                />
                <TableBody component={"div"}>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      itemPadre={row}
                      setagregarItemLista={setagregarItemLista}
                      socket={socket}
                    />
                  ) : null}
                  {row.estCuotas.map((itemHijo, index) => {
                    return (
                      <RowItem
                        itemHijo={itemHijo}
                        itemPadre={row}
                        index={index}
                        key={index}
                        socket={socket}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
