import React, { useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Divider,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
// eslint-disable-next-line

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },

  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    margin: theme.spacing(0.5, 0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.5, 0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const { open, setopen, objetoActivoModal, setobjetoActivoModal, perfil } =
    props;
  const [item, setitem] = useState(objetoActivoModal);
  useEffect(() => {
    const objetoEncEva = {};
    const error = false;
    const objetoModificado = objetoActivoModal;
    objetoModificado.docPreguntas.forEach((pregunta) => {
      const { tipoCampo, propiedad } = pregunta;
      const orden =
        tipoCampo === "TEXTO"
          ? 1
          : tipoCampo === "NUMERICO"
          ? 2
          : tipoCampo === "LISTA"
          ? 3
          : tipoCampo === "MULTIPLE LISTA"
          ? 4
          : tipoCampo === "PUNTAJE"
          ? 5
          : 6;

      if (tipoCampo === "NUMERICO") {
        objetoEncEva[propiedad] = {
          ...pregunta,
          value: pregunta.value ? pregunta.value : 0,
          orden,
          error,
        };
      } else if (tipoCampo === "MULTIPLE LISTA") {
        objetoEncEva[propiedad] = {
          ...pregunta,
          value: pregunta.value ? pregunta.value : [],
          orden,
          error,
        };
      } else {
        objetoEncEva[propiedad] = {
          ...pregunta,
          value: pregunta.value ? pregunta.value : "",
          orden,
          error,
        };
      }
    });
    objetoModificado.docPreguntas = Object.values(objetoEncEva);

    setitem(objetoModificado);
    // eslint-disable-next-line
  }, [objetoActivoModal]);

  const defaultActive = () => {
    setobjetoActivoModal({
      // encId: 4354,
      // encPerId: 1469,
      // encDocId: 26,
      encOption: "",
      encEstado: "",
      encFecReg: "",
      docTipo: "",
      docDestinatario: "",
      docTitulo: "",
      docFechaEmision: "",
      docFechaFinalizacion: "",
      docObligatorio: "",
      docEstado: "",
      docPreguntas: [],
      docEmpresa: "",
      docUser: "",
    });
    setopen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {perfil}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
              >
                {item.docPreguntas
                  .sort((a, b) => a.orden - b.orden)
                  .map(
                    ({
                      opcionesLista,
                      pregunta,
                      propiedad,
                      tipoCampo,
                      value,
                      error,
                    }) => {
                      if (tipoCampo === "NUMERICO") {
                        return (
                          <TextField
                            type="number"
                            className={clases.formD}
                            disabled
                            size="small"
                            label={pregunta}
                            value={value}
                            name={propiedad}
                            error={error}
                            onChange={(e) => {
                              // handleChange(e);
                            }}
                          />
                        );
                      }
                      if (tipoCampo === "AREA TEXTO") {
                        return (
                          <TextField
                            multiline
                            rows={3}
                            variant="outlined"
                            className={clases.formAllW}
                            disabled
                            size="small"
                            label={pregunta}
                            value={value}
                            name={propiedad}
                            error={error}
                            onChange={(e) => {
                              // handleChange(e);
                            }}
                          />
                        );
                      }
                      if (tipoCampo === "LISTA") {
                        return (
                          <TextField
                            className={clases.formD}
                            disabled
                            size="small"
                            label={pregunta}
                            value={value}
                            name={propiedad}
                            error={error}
                            onChange={(e) => {
                              // handleChange(e);
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {opcionesLista.map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                        );
                      }
                      if (tipoCampo === "MULTIPLE LISTA") {
                        return (
                          <TextField
                            className={clases.formD}
                            disabled
                            size="small"
                            label={pregunta}
                            value={value}
                            name={propiedad}
                            onChange={(e) => {
                              // handleChange(e);
                            }}
                            select
                            error={error}
                            MenuProps={MenuProps}
                            SelectProps={{
                              displayEmpty: true,
                              multiple: true,
                              renderValue: (selected) => {
                                return selected
                                  .map((value, index) => {
                                    return value;
                                  })
                                  .join(", ");
                              },
                            }}
                          >
                            <MenuItem disabled value="">
                              Seleccione.
                            </MenuItem>
                            {opcionesLista.map((item) => {
                              return (
                                <MenuItem
                                  style={getStylesMenuItems(item, value, theme)}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        );
                      }
                      if (tipoCampo === "PUNTAJE") {
                        return (
                          <TextField
                            className={clases.formD}
                            disabled
                            size="small"
                            label={pregunta}
                            value={value}
                            name={propiedad}
                            error={error}
                            onChange={(e) => {
                              // handleChange(e);
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {opcionesLista.map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                        );
                      }
                      return (
                        <TextField
                          className={clases.formD}
                          disabled
                          size="small"
                          label={pregunta}
                          value={value}
                          name={propiedad}
                          error={error}
                          onChange={(e) => {
                            // handleChange(e);
                          }}
                        />
                      );
                    }
                  )}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                  disabled
                ></Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
