export const columns = [
  {
    id: "planiTipo",
    label: "Tipo",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "planiNombreActividad",
    label: "Evento",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiNombreActividad",
    align: "center",
    ocultar: true,
  },
  {
    id: "planiFecReg",
    label: "Fec. Reg.",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "planiUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiUser",
    align: "center",
    ocultar: true,
  },
];
export const columnsSupervisor = [
  {
    id: "planiTipo",
    label: "Tipo",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "planiNombreActividad",
    label: "Evento",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiNombreActividad",
    align: "center",
    ocultar: true,
  },
  {
    id: "planiFecReg",
    label: "Fec. Reg.",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "planiUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "planiUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  id_planificacion: "",
  planiPerfil: "",
  planiTipo: "",
  planiNombreActividad: "",
  planiIdDOperativo: 133,
  planiNameUser: "",
  planiActividades: [],
  planiVulnerabilidades: [],
  planiEmpresa: "",
  planiUser: "",
  planiFecReg: "",
  planiFecUpd: "",
};

export const columnasHijoVulnerabilidad = [
  {
    id: "vulnerabilidad",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "vulnerabilidad",
    align: "center",
    ocultar: true,
  },
  {
    id: "fotos",
    label: "Fotos",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "fotos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijo = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Intervalo en minutos",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijoSupervisor = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "puesto",
    label: "Puesto",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "puesto",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Número de revisiones",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijoRonda = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "ubicacion",
    label: "Ubicación",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "ubicacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Intervalo en minutos",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijoRondaSupervisor = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "puesto",
    label: "Puesto",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "puesto",
    align: "center",
    ocultar: true,
  },
  {
    id: "ubicacion",
    label: "Ubicación",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "ubicacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Número de revisiones",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const itemHijoDefectoVulnerabilidad = {
  fotos: [],
  nombre: "",
};
export const itemHijoDefecto = {
  nombre: "",
  ubicacion: "",
  puesto: "",
  coordenadas: {
    longitud: -79.178298,
    latitud: -0.254916,
  },
  vulnerabilidad: "",
  foto: "",
  minutos: "5",
  horario: [],
};

export const columnasHijoDelHijo = [
  {
    id: "hora",
    label: "H. Inicio",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "hora",
    align: "center",
    ocultar: true,
  },
  {
    id: "horaFinal",
    label: "H. Final",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "horaFinal",
    align: "center",
    ocultar: true,
  },

  {
    id: "observación",
    label: "observación",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "observación",
    align: "center",
    ocultar: true,
  },
];

export const itemHijoDelHijoDefecto = {
  observacion: "",
  hora: "",
  horaFinal: "",
};
