import React, { memo, useContext } from "react";
import { useState } from "react";

import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  // Checkbox,
  // FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Badge,
  // Tooltip,
  // FormControlLabel,
  // Checkbox,
} from "@mui/material";
import { Cancel, Check, List } from "@mui/icons-material";
import Modal from "./Modal";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregar = (props) => {
  const { setagregar, estadoRequest } = props;
  const [openModal, setopenModal] = useState(false);
  const [item, setitem] = useState({
    // docId: 16,
    docCodigo: "",
    docFecReg: "",
    docTipo: "EVALUACION",
    docOption: "EVALUACIONES",
    docDestinatario: "GUARDIA",
    docTitulo: "",
    docFechaEmision: FechaExacta,
    docFechaFinalizacion: FechaExacta,
    docObligatorio: "SI",
    docEstado: "ACTIVA",
    docNotIns: "NO",
    docPreguntas: [
      // {
      //   pregunta: "Que tipo de tarjetas de crédito tiene?",
      //   propiedad: "",
      //   tipoCampo: "multiple",
      //   opcionesLista: [
      //     "Visa",
      //     "Mastercard",
      //     "American Express",
      //     "Discover",
      //     "Diners Club",
      //   ],
      // },
    ],
    docPersonas: [],
    docCliDocNumero: "",
    docCliRazonSocial: "",
    docCliId: "",
    docEmpresa: "",
    docUser: "",
    docAlcance: "SELECCION",
  });

  const classes = useRowStyles();

  const { socket, usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "evaluacion",
          docOption: estadoRequest,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          docUser: usuario.usuario,
          docEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (item.docAlcance === "SELECCION") {
      if (
        item.docDestinatario.trim() === "" ||
        item.docTitulo.trim() === "" ||
        item.docFechaEmision.trim() === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        item.docDestinatario.trim() === "" ||
        item.docTitulo.trim() === "" ||
        item.docFechaEmision.trim() === ""
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <Modal
          open={openModal}
          setopen={setopenModal}
          propiedad={"docPersonas"}
          item={item}
          setitem={setitem}
          editar={item.docPersonas.length !== 0 && item.docCliDocNumero !== ""}
        />
        <TableCell> </TableCell> {/* boton */}
        <TableCell>
          <Box display="flex" width="100%" alignItems="center">
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  // docId: 16,
                  docCodigo: "",
                  docFecReg: "",
                  docTipo: "EVALUACION",
                  docOption: "EVALUACIONES",
                  docDestinatario: "GUARDIA",
                  docTitulo: "",
                  docFechaEmision: FechaExacta,
                  docFechaFinalizacion: FechaExacta,
                  docObligatorio: "SI",
                  docEstado: "ACTIVA",
                  docNotIns: "NO",
                  docPreguntas: [
                    // {
                    //   pregunta: "Que tipo de tarjetas de crédito tiene?",
                    //   propiedad: "",
                    //   tipoCampo: "multiple",
                    //   opcionesLista: [
                    //     "Visa",
                    //     "Mastercard",
                    //     "American Express",
                    //     "Discover",
                    //     "Diners Club",
                    //   ],
                    // },
                  ],
                  docPersonas: [],
                  docCliDocNumero: "",
                  docCliRazonSocial: "",
                  docCliId: "",
                  docEmpresa: "",
                  docUser: "",
                  docAlcance: "SELECCION",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  // docId: 16,
                  docCodigo: "",
                  docFecReg: "",
                  docTipo: "EVALUACION",
                  docOption: "EVALUACIONES",
                  docDestinatario: "GUARDIA",
                  docTitulo: "",
                  docFechaEmision: FechaExacta,
                  docFechaFinalizacion: FechaExacta,
                  docObligatorio: "SI",
                  docEstado: "ACTIVA",
                  docNotIns: "NO",
                  docPreguntas: [
                    // {
                    //   pregunta: "Que tipo de tarjetas de crédito tiene?",
                    //   propiedad: "",
                    //   tipoCampo: "multiple",
                    //   opcionesLista: [
                    //     "Visa",
                    //     "Mastercard",
                    //     "American Express",
                    //     "Discover",
                    //     "Diners Club",
                    //   ],
                    // },
                  ],
                  docPersonas: [],
                  docCliDocNumero: "",
                  docCliRazonSocial: "",
                  docCliId: "",
                  docEmpresa: "",
                  docUser: "",
                  docAlcance: "SELECCION",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
            {item.docAlcance === "SELECCION" ? (
              <Badge badgeContent={item.docPersonas.length} color="primary">
                <IconButton
                  onClick={() => {
                    setopenModal(true);
                  }}
                >
                  <List />
                </IconButton>
              </Badge>
            ) : null}
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            inputProps={{ style: { textAlign: "center" } }}
            value={item.docDestinatario}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                docDestinatario: res,
                docPersonas: [],
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="" disabled>
              Seleccione.
            </MenuItem>
            {[
              "GUARDIA",
              "SUPERVISOR",
              "ADMINISTRACION",
              "CLIENTE",
              "PROVEEDOR",
            ].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            disabled
            inputProps={{ style: { textAlign: "center" } }}
            value={item.docAlcance}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                docAlcance: res,
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="" disabled>
              Seleccione.
            </MenuItem>
            {["GLOBAL", "SELECCION"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={item.docTitulo}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                docTitulo: res,
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            type="date"
            value={item.docFechaEmision}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                docFechaEmision: res,
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            type="date"
            value={item.docFechaFinalizacion}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                docFechaFinalizacion: res,
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
          />
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            disabled
            inputProps={{ style: { textAlign: "center" } }}
            value={item.docNotIns}
            onChange={(e) => {
              setitem({
                ...item,
                docNotIns: e.target.value,
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="" disabled>
              Seleccione.
            </MenuItem>
            {["SI", "NO"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            inputProps={{ style: { textAlign: "center" } }}
            value={item.docObligatorio}
            onChange={(e) => {
              setitem({
                ...item,
                docObligatorio: e.target.value,
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="" disabled>
              Seleccione.
            </MenuItem>
            {["SI", "NO"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docFecReg}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docUser}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
