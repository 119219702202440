import React, { memo, useState, useContext } from "react";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { setagregar, setitem, item } = props;
  const [clientesData, setclientesData] = useState([]);
  const [itemRow, setItemRow] = useState({
    nombre: "",
    valor: "",
  });
  const botonDisabled = () => {
    if (itemRow.nombre === "" || itemRow.valor === "") {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
   

  const handleAgregar = async () => {
    try {
      const perBonos = [
        {
          ...itemRow,
        },
        ...item.perBonos,
      ];

      setitem({
        ...item,
        perBonos,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  nombre: "",
                  valor: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  nombre: "",
                  valor: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          {" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                disableClearable={true}
                options={clientesData.map((item) => {
                  return item.apoAporte;
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ");
                }}
                id="controlled-demo"
                value={itemRow.nombre}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    setItemRow({ ...itemRow, nombre: options[0] });
                  } else {
                    setItemRow({ ...itemRow, nombre: "" });
                    setclientesData([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return mostrarAlerta("Ingrese datos", "error");
                      }
                      try {
                        const resIngreso = await clienteAxios.get(
                          `/aportes/filtro/0?tipo=INGRESO&search=${e.target.value}`
                        );

                        setclientesData(resIngreso.data.data);
                      } catch (error) {
                        setItemRow({ ...itemRow, nombre: "" });
                        setclientesData([]);
                        return mostrarAlerta("No hay registros", "error");
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              id=""
              type={"number"}
              value={itemRow.valor}
              onChange={(e) =>
                setItemRow({
                  ...itemRow,
                  valor: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
