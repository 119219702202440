import React, { memo, useContext } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../../components/ButtonsAction";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
import { Cancel, Create, DeleteForever, Restore } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../../config/const";
import CeldaUser from "../../../../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
    // STATE
    estadoRequest,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // DIALOG FECHAS
  const [openDialogFechas, setopenDialogFechas] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        preUser: usuario.usuario,
        preEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        prePeriodo: rowHijo.prePeriodo,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          preUser: usuario.usuario + "/+/" + rowHijo[campoExport],
          preEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {openDialogFechas ? (
        <Dialog
          open={openDialogFechas}
          onClose={() => setopenDialogFechas(false)}
          aria-labelledby={"FECHAS"}
        >
          <DialogTitle fontWeight={"bold"}>Fechas</DialogTitle>
          <>
            <DialogContent dividers>
              <DialogContentText>
                <Box
                  display="flex"
                  justifyContent={"space-around"}
                  width={"100%"}
                >
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={"bold"}
                  >
                    Desde
                  </Typography>
                  <Typography
                    variant="body1"
                    color="initial"
                    fontWeight={"bold"}
                  >
                    Hasta
                  </Typography>
                </Box>
              </DialogContentText>
              {rowHijo.ausFechasConsultaDB
                ? rowHijo.ausFechasConsultaDB
                    .sort((a, b) => {
                      const aTotal = Date.parse(a.desde);
                      const bTotal = Date.parse(b.desde);

                      return aTotal - bTotal;
                    })
                    .map((fecha, key) => (
                      <DialogContentText key={key}>
                        <Box
                          display="flex"
                          justifyContent={"space-around"}
                          width={"100%"}
                        >
                          <Typography variant="body1" color="initial">
                            {fecha.desde.replace("T", " ")}
                          </Typography>
                          <Box ml={1} mr={1}>
                            -
                          </Box>
                          <Typography variant="body1" color="initial">
                            {fecha.hasta.replace("T", " ")}
                          </Typography>
                        </Box>
                      </DialogContentText>
                    ))
                : null}
            </DialogContent>
          </>
          <DialogActions>
            <Button onClick={() => setopenDialogFechas(false)} color="primary">
              LISTO
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowHijo.preUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          {estadoRequest === "P. NORMAL" ? (
            <>
              {" "}
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.preTipo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.prePeriodo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.preCedula}
              </TableCell>
              <TableCell size="small" align="left" className={claseCelda}>
                {rowHijo.preNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.preDias}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                ${rowHijo.preValor}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                ${rowHijo.prePatronal}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                ${rowHijo.preIndividual}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(rowHijo.preFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>
              <CeldaUser user={rowHijo.preUser} claseCelda={claseCelda} />
            </>
          ) : (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.preTipo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.prePeriodo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {rowHijo.preCedula}
              </TableCell>
              <TableCell size="small" align="left" className={claseCelda}>
                {rowHijo.preNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                ${rowHijo.preValor}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(rowHijo.preFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>

              <CeldaUser user={rowHijo.preUser} claseCelda={claseCelda} />
            </>
          )}
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
