import React, { memo } from "react";

//CSS
import "./Cargando.css";
// MATERIAL
import { Typography, Box } from "@mui/material";
const Spinner = ({ titulo }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      width="100%"
      pt={5}
      mt={2}
    >
      <Box
        sx={(theme) => ({
          ":before": {background: theme.palette.secondary.main},
        })}
        className="sk-chase"
      >
        <Box
          sx={(theme) => ({
            ":before": {background: theme.palette.secondary.main},
          })}
          className="sk-chase-dot"
        ></Box>
        <Box
          sx={(theme) => ({
            ":before": {background: theme.palette.secondary.main},
          })}
          className="sk-chase-dot"
        ></Box>
        <Box
          sx={(theme) => ({
            ":before": {background: theme.palette.secondary.main},
          })}
          className="sk-chase-dot"
        ></Box>
        <Box
          sx={(theme) => ({
            ":before": {background: theme.palette.secondary.main},
          })}
          className="sk-chase-dot"
        ></Box>
        <Box
          sx={(theme) => ({
            ":before": {background: theme.palette.secondary.main},
          })}
          className="sk-chase-dot"
        ></Box>
        <Box
          sx={(theme) => ({
            ":before": {background: theme.palette.secondary.main},
          })}
          className="sk-chase-dot"
        ></Box>
      </Box>
      <br />
      <br />
      <Typography variant="h5" color="initial">
        {titulo}
      </Typography>
    </Box>
  );
};

export default memo(Spinner);
