import clienteAxios from "./axios";

const axiosHeader = (empresaheader, usuarioheader, rolheader) => {
  if (empresaheader && usuarioheader && rolheader) {
    clienteAxios.defaults.headers.common["empresaheader"] = empresaheader;
    clienteAxios.defaults.headers.common["usuarioheader"] = usuarioheader;
    clienteAxios.defaults.headers.common["rolheader"] = rolheader;
    clienteAxios.defaults.headers.common["from"] = "web";
  } else {
    delete clienteAxios.defaults.headers.common["empresaheader"];
    delete clienteAxios.defaults.headers.common["usuarioheader"];
    delete clienteAxios.defaults.headers.common["rolheader"];
  }
};

export default axiosHeader;
