export const columns = [
  {
    id: "subNomCategoria",
    label: "Categoria",
    minWidth: 200,
    tipo: "string",

    propiedad: "subNomCategoria",
  },
  {
    id: "subNombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    propiedad: "subNombre",
  },
  {
    id: "subDetalle",
    label: "Detalle",
    minWidth: 100,
    tipo: "string",
    propiedad: "subDetalle",
  },
  {
    id: "subFecReg",
    label: "Fec. Reg",
    minWidth: 100,
    tipo: "string",

    propiedad: "subFecReg",
  },
  {
    id: "subUser",
    label: "User",
    minWidth: 100,
    tipo: "string",

    propiedad: "subUser",
  },

];

export const objectDefault = {
  subNombre: "",
  subDetalle: "",
  subNomCategoria: "",
  subEstado: "ACTIVA",
  subEmpresa: "",
  subUser: "",
};
