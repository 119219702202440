import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "mejFecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejFecha",
    align: "center",
    ocultar: true,
  },
  {
    id: "mejAccionesStatus",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejAccionesStatus",
    align: "center",
    ocultar: true,
  },

  {
    id: "mejProceso",
    label: "Area",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejProceso",
    align: "center",
    ocultar: true,
  },

  {
    id: "mejNombreReceptor",
    label: "Receptor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejNombreReceptor",
    align: "center",
    ocultar: true,
  },
  {
    id: "mejNombreEmisor",
    label: "Emisor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejNombreEmisor",
    align: "center",
    ocultar: true,
  },
  {
    id: "mejNombreEmisor",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejNombreEmisor",
    align: "center",
    ocultar: true,
  },
  {
    id: "mejDetalles",
    label: "Detalles",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejDetalles",
    align: "center",
    ocultar: true,
  },
  {
    id: "mejUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "mejUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  mejMotivo: "CORRECTIVO",
  mejSecuencia: "", // yo lleno en el backend
  mejFecha: FechaExacta, // Date
  mejFechaAtencion: new Date(
    Date.now() - new Date().getTimezoneOffset() * 60 * 1000 + 86400000
  )
    .toISOString()
    .split("T")[0],
  mejProceso: "ADMINISTRACION",
  mejPrioridad: "MEDIA",
  mejAccionesFecha: "",
  mejAccionesStatus: "NO PROCESADO", // PROCESADO
  mejAccionesDocumento: "",
  mejAccionesFotos: [],
  mejIdEmisor: "", // login id
  mejNombreEmisor: "", // login nombre
  mejIdReceptor: "", // Endpoint
  mejDocumentoReceptor: "", //  Endpoint
  mejNombreReceptor: "", // Endpoint
  mejDetalles: "", // textarea
  // tab METODOS
  mejMetodo: [
    // { metodo: "", porque: "" }
  ], // solo permitir 5 registros (las 2 columnas son input)
  // tab ACCIONES
  mejIdCliente: "",
  mejNombreCliente: "",
  mejDocumentoCliente: "",
  mejAsunto: "",
  mejDetalleAcciones: "", // textarea
  mejDocumento: "",
  mejGuardias: [],
  mejSupervisores: [],
  mejAdministracion: [],
  mejFechas: [],
  mejFechasDB: [],
  mejFotos: [],
  mejTipo: "",
  // OTROS
  mejUser: "admin",
  mejEmpresa: "PRUEBA",
  mejFecReg: "2023-04-21T22:38:31.000Z",
  mejFecUpd: "2023-04-27T21:09:43.000Z",
};
export const itemHijoDefecto = {
  metodo: "",
  porque: "",
};
