import React, { useContext, useEffect, useMemo, useState } from "react";

import { alpha } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  Autocomplete,
  Popper,
  Collapse,
  Button,
  Divider,
} from "@mui/material";
import { AddModerator } from "@mui/icons-material";
import { useModalStyle } from "../../styles/styleModal";
import { objectDefault } from "../../TurnosExtras/data";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../config/axios";
import AuthContext from "../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../config/const";
import Dia from "./Dia";
const headCells = [
  {
    id: "Dia",
    numeric: false,
    disablePadding: true,
    label: "Día",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    disabled,
  } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={disabled}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({
  numSelected,
  setopenCollapse,
  openCollapse,
  itemPermiso,
}) {
  const { mostrarAlerta } = useContext(AlertaContext);
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {numSelected === 1 ? "Seleccionado" : " Seleccionados"}{" "}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Dias Permiso{" "}
          {itemPermiso.permiMotivo === "" && (
            <Typography variant="body1" color="error" component={"span"}>
              Seleccione el motivo del permiso
            </Typography>
          )}
        </Typography>
      )}

      {numSelected > 0 || openCollapse ? (
        <Tooltip
          title={openCollapse ? "Ocultar Formulario" : "Generar turno extra"}
        >
          <IconButton
            onClick={() => {
              if (itemPermiso.permiMotivo === "") {
                return mostrarAlerta(
                  "Seleccione el motivo del permiso",
                  "error"
                );
              }
              setopenCollapse(!openCollapse);
            }}
            color={openCollapse ? "error" : "primary"}
          >
            <AddModerator />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
}

export default function TableDias({ dias, itemPermiso, socket, turnos }) {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const disabled = useMemo(
    () =>
      // SI TODOS TIENEN TURNO DARA TRUE, ES DECIR SE DESHABILITARÁ
      dias.every((dia) => {
        let tieneTurno = false;

        //*DEVOLVER true si existe un dia que no se encuentre en turnos
        turnos.forEach((turno) => {
          const existeEnEsteTurno = turno.dias.some(
            (turnoDia) => turnoDia.id === dia.id
          );
          if (existeEnEsteTurno) {
            tieneTurno = true;
          }
        });
        return tieneTurno;
      }),
    [turnos]
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dias
        .filter(
          (dia) =>
            !turnos.some((turno) =>
              turno.dias.some((diaTurno) => diaTurno.id === dia.id)
            )
        )
        .map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dias.length) : 0;
  const clases = useModalStyle();

  const [personasData, setpersonasData] = useState([]);
  const PopperMy = function(props) {
    return <Popper {...props} placement="bottom-start" />;
  };
  const [openCollapse, setopenCollapse] = useState(false);
  const [item, setitem] = useState(objectDefault);
  const {
    // turnId: 1,
    turnIdPersona,
    turnIdDOperativo, //DE HORARIOS
    turnIdPermiso,
    turnIdMulta,
    turnMotivo,
    turnEstado,
    turnAutorizado,
    turnDetalle,
    turnStatusDescripcion,
    // turnUser,
    // turnEmpresa,
    // turnFecReg,
    // turnFecUpd,
    cedula,
    nombres,
    idCliente,
    ruc,
    razonSocial,
    ubicacion, //DE HORARIOS
    puesto, //DE HORARIOS
    turnIdsJornadaLaboral,
    // dias,
  } = item;
  useEffect(() => {
    const getData = async () => {
      const resJefeOperaciones = await clienteAxios(
        "/personas/filtroporareadepartamento/0?search=&estado=JEFE DE OPERACIONES"
      );
      if (resJefeOperaciones.data.data.length === 0) {
      } else {
        setitem({
          ...item,
          turnAutorizado:
            resJefeOperaciones.data.data[0].perNombres +
            " " +
            resJefeOperaciones.data.data[0].perApellidos,
        });
      }
    };

    if (item.turnAutorizado === "") {
      getData();
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Box className={clases.container}>
          <>
            <Divider className={clases.formAllW}>
              <Typography variant="body1" color="secondary" fontWeight={"bold"}>
                Generando Turno Extra
              </Typography>
            </Divider>
            {/* PERSONAS */}
            <FormControl className={clases.formAllW}>
              <Autocomplete
                disableClearable={true}
                options={personasData.map((item) => {
                  return (
                    item.perDocNumero +
                    "/+/" +
                    item.perApellidos +
                    " " +
                    item.perNombres +
                    "/+/" +
                    item.perId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                value={
                  (cedula + " " + nombres).trim() === ""
                    ? ""
                    : cedula + " " + nombres
                }
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    try {
                      setitem({
                        ...item,
                        cedula: options[0],
                        nombres: options[1].trim(),
                        turnIdPersona: options[2],
                      });
                    } catch (error) {
                      console.log({ error });
                      return mostrarAlerta("No hay personas", "error");
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    label="Persona"
                    //   error={error.cedula}
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        setpersonasData([]);
                        return mostrarAlerta("Ingrese datos", "error");
                      }
                      try {
                        const res = await clienteAxios.get(
                          `/personas/filtro/0?search=${e.target.value}`
                        );

                        setpersonasData(res.data.data);
                      } catch (error) {
                        console.log({ error });
                        setitem({
                          ...item,
                          cedula: "",
                          nombres: "",
                          turnIdPersona: "",
                        });
                        setpersonasData([]);

                        return mostrarAlerta("No hay personas", "error");
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>

            {/* PUESTOS NUEVOS */}

            <Autocomplete
              className={clases.formD}
              PopperComponent={PopperMy}
              disableClearable={true}
              options={[
                "EVENTO ESPECIAL",
                "ENFERMEDAD IESS",
                "PERMISO PERSONAL",
                "PERMISO MEDICO",
                "PATERNIDAD",
                "DEFUNCION FAMILIAR",
                "INJUSTIFICADA",
              ].map((item) => item)}
              getOptionLabel={(option) => {
                return option;
              }}
              id="controlled-demo"
              value={itemPermiso.permiMotivo}
              paperprops
              disabled
              onChange={async (event, newValue) => {
                if (newValue) {
                  setitem({ ...item, turnMotivo: newValue });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Motivo"
                  fullWidth
                  // error={error.turnMotivo}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />

            <TextField
              className={clases.formD}
              size="small"
              label="Autorizado por"
              value={turnAutorizado}
              name="turnAutorizado"
              // error={error.turnAutorizado}
              onChange={(e) =>
                setitem({ ...item, [e.target.name]: e.target.value })
              }
            />
            <TextField
              multiline
              rows={3}
              variant="outlined"
              className={clases.formAllW}
              size="small"
              label="Detalle"
              value={turnDetalle}
              name="turnDetalle"
              // error={error.turnDetalle}
              onChange={(e) =>
                setitem({ ...item, [e.target.name]: e.target.value })
              }
            />
            <Button
              className={clases.formD}
              variant="contained"
              color="secondary"
              disabled={
                item.cedula === "" ||
                item.turnAutorizado === "" ||
                selected.length === 0
              }
              onClick={async () => {
                try {
                  const res = await clienteAxios.post(
                    "/horarios_jornada_laboral/dias-libres",
                    {
                      turnIdDOperativo: itemPermiso.permiIdDOperativo,
                      turnIdPersona,
                      dias: dias
                        .filter((dia) =>
                          selected.some(
                            (itemSelected) => itemSelected === dia.id
                          )
                        )
                        .map(({ fecha_inicio }) => fecha_inicio),
                    }
                  );

                  socket.current.emit(
                    "client:guardarData",
                    trimPropsItem({
                      ...item,
                      turnIdDOperativo: itemPermiso.permiIdDOperativo,
                      turnIdPersonaReemplazada: itemPermiso.permiIdPersona,
                      turnIdsJornadaLaboral: selected,
                      ruc: itemPermiso.ruc,
                      razonSocial: itemPermiso.razonSocial,
                      ubicacion: itemPermiso.ubicacion,
                      puesto: itemPermiso.puesto,
                      turnMotivo: itemPermiso.permiMotivo,
                      // turDiasTurno: turFechasConsultaDB.length,
                      rucempresa: usuario.rucempresa,
                      rol: usuario.rol,
                      turnUser: usuario.usuario,
                      turnEmpresa: usuario.rucempresa,
                      tabla: "turno_extra",
                    })
                  );
                  setitem({
                    ...objectDefault,
                    turnAutorizado: item.turnAutorizado,
                  });
                  setSelected([]);
                } catch (error) {
                  console.log({ error });
                  mostrarAlerta(error?.response?.data?.msg, "error");
                }
              }}
            >
              GUARDAR TURNO EXTRA{" "}
              {(item.cedula === "" ||
                item.turnAutorizado === "" ||
                selected.length === 0) &&
                "(Llene el formulario)"}
            </Button>
          </>
        </Box>
      </Collapse>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          setopenCollapse={setopenCollapse}
          openCollapse={openCollapse}
          itemPermiso={itemPermiso}
        />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={"small"}>
            <EnhancedTableHead
              disabled={disabled}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={
                dias.filter(
                  (dia) =>
                    !turnos.some((turno) =>
                      turno.dias.some((diaTurno) => diaTurno.id === dia.id)
                    )
                ).length
              }
            />
            <TableBody>
              {dias.map((row, index) => {
                return (
                  <Dia
                    index={index}
                    row={row}
                    isSelected={isSelected}
                    handleClick={handleClick}
                    turnos={turnos}
                  />
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dias.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
