// COLUMNAS IMPLEMENTARIOS
export const columnsImplementarios = [
  {
    id: "invTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "invSerie",
    label: "Serie",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "invNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "invMarca",
    label: "Marca",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invMarca",
    align: "center",
    ocultar: true,
  },
  {
    id: "invModelo",
    label: "Modelo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invModelo",
    align: "center",
    ocultar: true,
  },
  {
    id: "invStock",
    label: "Stock",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invStock",
    align: "center",
    ocultar: true,
  },
  {
    id: "invTalla",
    label: "Talla",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invTalla",
    align: "center",
    ocultar: true,
  },

  {
    id: "invFecReg",
    label: "FecReg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "invUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invUser",
    align: "center",
    ocultar: true,
  },
];
// COLUMNAS ARMAS
export const columnsArmas = [
  {
    id: "invSerie",
    label: "Serie",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "invNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "invMarca",
    label: "Marca",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invMarca",
    align: "center",
    ocultar: true,
  },
  {
    id: "invModelo",
    label: "Modelo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invModelo",
    align: "center",
    ocultar: true,
  },
  {
    id: "invStock",
    label: "Stock",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invStock",
    align: "center",
    ocultar: true,
  },
  {
    id: "invTipoArma",
    label: "TipoArma",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invTipoArma",
    align: "center",
    ocultar: true,
  },

  {
    id: "invFecReg",
    label: "FecReg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "invUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invUser",
    align: "center",
    ocultar: true,
  },
];

// COLUMNAS MUNICIONES
export const columnsMuniciones = [
  {
    id: "invSerie",
    label: "Serie",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "invNombre",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "invMarca",
    label: "Marca",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "invStock",
    label: "Stock",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },

  {
    id: "invFecReg",
    label: "FecReg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },
  {
    id: "invUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "invSerie",
    align: "center",
    ocultar: true,
  },
];

export const objetoActivo = {
  //   invId: 1,
  invObservacion: "",
  invMaterial: "",
  invPrecioUnitario: "1",
  invTipo: "VESTIMENTAS", // DE ACUERDO AL TAB
  invSerie: "0", // text
  invNombre: "", // TEXTAREA
  invProductoEstado: "NUEVO", // SELECT => NUEVO,USADO
  invMarca: "",
  invModelo: "",
  invStock: "",
  invDisponible: "",
  invTalla: "40.00", // SELECT => 36,38,40,42,44,46
  invClase: "", // NO
  invTipoArma: "", // NO
  invCalibre: "", // NO
  invColor: "", // NO
  invUso: "", // NO
  invStatus: "", // NO
  invProveedor: [
    // TAB (PROVEEDOR) => TABLA
    // {
    //   id: "12",
    //   numDocumento: "0302626288001",
    //   nombres: "Hugo",
    //   numFactura: "32-32-3-32323",
    //   cantidad: "10",
    //   fecFactura: "2022-03-02",
    // },
  ],
  invBodega: [],
  invFotos: [],
  invBodId: "",
  invNomBodega: "",
  invComprobantePdf: "", // TAB (DOCUMENTOS)
  invPermisoPdf: "", // NO
  invFecValidacion: "", // NO
  invFecCaducidad: "", // DATE
  invEstado: "ACTIVA", // SWITCH
  invEmpresa: "",
  invUser: "",
  invFecReg: "",
  invDocumento: "",
  invTotalesStock: [
    {
      estado: "NUEVO",
      cantidad: 0,
    },
    {
      estado: "USADO",
      cantidad: 0,
    },
    {
      estado: "OPERABLE",
      cantidad: 0,
    },
    {
      estado: "DEFECTUOSO",
      cantidad: 0,
    },
  ],
};
