import React, { memo } from "react";

import { IconButton, InputAdornment, TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import { Cancel, Check, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";

const itemHijoDefecto = {
  institucion: "",
  usuario: "",
  clave: "",
  observacion: "",
};
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setagregar, setitemPadre, itemPadre } = props;

  const [itemHijo, setitemHijo] = useState(itemHijoDefecto);
  const [verPass, setverPass] = useState(false);
  //onChange
  const handleChange = (e) => {
    setitemHijo({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      itemHijo.institucion.trim() === "" ||
      itemHijo.usuario.trim() === "" ||
      itemHijo.clave.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      setitemPadre({
        ...itemPadre,
        empClaves: [trimPropsItem(itemHijo), ...itemPadre.empClaves],
      });
      setitemHijo(itemHijoDefecto);
      setagregar(false);
    } catch (error) {}
  };
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ButtonsAction
        botones={[
          {
            tooltip: "Cancelar",
            texto: "",
            funcion: () => {
              setagregar(false);
              setitemHijo(itemHijoDefecto);
              // setItemEdit(itemHijo);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
          },
          {
            tooltip: "Guardar",
            texto: "",
            funcion: () => {
              funcSubmit();
            },
            disabled: botonDisabled(),
            Icon: Check,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
            submit: true,
          },
        ]}
      />
      <>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            autoFocus
            value={itemHijo.institucion}
            name="institucion"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            value={itemHijo.usuario}
            name="usuario"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            type={verPass ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {verPass ? (
                    <IconButton onClick={() => setverPass(!verPass)}>
                      <Visibility color="secondary" />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setverPass(!verPass)}>
                      <VisibilityOff color="secondary" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            value={itemHijo.clave}
            name="clave"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            value={itemHijo.observacion}
            name="observacion"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
      </>
    </TableRow>
  );
};

export default withRouter(memo(Row));
