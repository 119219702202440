import React, { useContext, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// CONTEXT
import AuthContext from "../Context/Auth/AuthContext.js";
import AlertaContext from "../Context/Alerta/AlertaContext.js";
import MenuContext from "../Context/Menu/MenuContext.js";
import DocumentosContext from "../Context/Configuraciones/Documentos/DocumentosContext";
import themeF from "../config/themeUser";
// COMPONENTES
import Footer from "../components/Footer/Footer.js";
import Notification from "../firebaseNotifications/Notification";
import Cargando from "../components/Extra/Cargando.js";
// MATERIAL
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Drawer,
  Box,
  Toolbar,
  // CssBaseline,
  Alert,
} from "@mui/material";
// import guardiaPng from "../assets/img/guardiaPng.png";
import Sidebar from "./Componentes/Sidebar/Sidebar";
import NavbarDesktop from "./Componentes/Navbars/NavbarDesktop.js";
import NavbarMobile from "./Componentes/Navbars/NavbarMobile.js";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../config/theme.js";
import InformesContext from "../Context/Informes/InformesContext.js";
import ChatPage from "../views/Chat/ChatPage.js";

// MATERIAL
// import { Alert } from "@mui/material";

// import { AddCircle } from "@mui/icons-material";
const drawerWidth = 240;

const AppBarDesktop = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerDesktop = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MiniDrawer(props) {
  const { window: windowProps } = props;

  const [open, setOpen] = React.useState(false);

  const [alertaNavegador, setalertaNavegador] = useState(false);
  const [buscador, setbuscador] = useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { usuario, socket } = useContext(AuthContext);
  const { obtenerCategorias, rows } = useContext(MenuContext);

  const {
    agregar: agregarDocumento,
    editar: editarDocumento,
    eliminar: eliminarDocumento,
    eliminarSeleccion: eliminarSeleccionDocumento,
  } = useContext(DocumentosContext);
  const {
    agregar: agregarInforme,
    editar: editarInforme,
    eliminar: eliminarInforme,
    eliminarSeleccion: eliminarSeleccionInforme,
  } = useContext(InformesContext);

  const { alerta, mostrarAlerta } = useContext(AlertaContext);
  const empresaheader = localStorage.getItem("empresaheader");
  const usuarioheader = localStorage.getItem("usuarioheader");
  const rolheader = localStorage.getItem("rolheader");
  const [itemSocket, setitemSocket] = useState({
    data: {},
    tipo: "",
  });

  useEffect(() => {
    socket.on("server:guardadoExitoso", (data) => {
      setitemSocket({ data, tipo: "agregar" });
    });

    socket.on("server:actualizadoExitoso", (data) => {
      setitemSocket({ data, tipo: "editar" });
    });
    socket.on("server:eliminadoExitoso", (data) => {
      setitemSocket({ data, tipo: "eliminar" });
    });
    socket.on("server:eliminadoExitosoSeleccionado", (data) => {
      setitemSocket({ data, tipo: "eliminarSeleccionado" });
    });

    socket.on("server:existeRegistro", (data) => {
      mostrarAlerta(data.msg, data.alert ? data.alert : "error");
    });

    socket.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    socket.on("server:notificacion", (data) => {
      mostrarAlerta(data.msg, "success");
    });

    socket.on("server:idRegistro", (data) => {
      window.open(`${data.ruta}`, "_blank");
    });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (itemSocket.tipo === "") {
      return;
    }
    const { data, tipo } = itemSocket;
    if (usuario.rucempresa !== data.rucempresa) {
      return;
    }
    // AGREGAR
    if (tipo === "agregar") {
      const funcionAalerta = () => {
        if (data.msg === "") {
          return;
        }
        if (!data.msg) {
          return;
        }
        mostrarAlerta(data.msg, data.alert ? data.alert : "success");
      };

      if (data.tabla === "documento" || data.tabla === "evaluacion") {
        agregarDocumento(data, funcionAalerta);
      }
      if (data.tabla === "informe") {
        agregarInforme(data, funcionAalerta);
      }
    }
    // EDITAR
    if (tipo === "editar") {
      const funcionAalerta = () => {
        if (data.msg === "") {
          return;
        }
        if (!data.msg) {
          return;
        }
        mostrarAlerta(data.msg, data.alert ? data.alert : "success");
      };
      if (data.tabla === "documento" || data.tabla === "evaluacion") {
        editarDocumento(data, funcionAalerta);
      }
      if (data.tabla === "informe") {
        editarInforme(data, funcionAalerta);
      }
    }
    // ELIMINAR
    if (tipo === "eliminar") {
      const funcionAalerta = () => {
        if (data.msg === "") {
          return;
        }
        if (!data.msg) {
          return;
        }
        mostrarAlerta(data.msg, data.alert ? data.alert : "success");
      };
      if (data.tabla === "documento" || data.tabla === "evaluacion") {
        eliminarDocumento(data.docId, funcionAalerta);
      }
      if (data.tabla === "informe") {
        eliminarInforme(data.infId, funcionAalerta);
      }
    }
    // ELIMINAR SELECCIONADO
    if (tipo === "eliminarSeleccionado") {
      const funcionAalerta = () => {
        if (data.msg === "") {
          return null;
        }
        if (!data.msg) {
          return null;
        }
        mostrarAlerta(data.msg, data.alert ? data.alert : "success");
      };
      if (data.tabla === "documento" || data.tabla === "evaluacion") {
        eliminarSeleccionDocumento(data.lista, funcionAalerta);
      }
      if (data.tabla === "informe") {
        eliminarSeleccionInforme(data.lista, funcionAalerta);
      }
    }
    // DEFAULT
    setitemSocket({
      data: {},
      tipo: "",
    });
    // eslint-disable-next-line
  }, [itemSocket]);
  useEffect(() => {
    if (!rows) {
      obtenerCategorias();
    }
    const navegadorAgente = window.navigator.userAgent;

    if (
      navegadorAgente.indexOf("Chrome") > -1 ||
      navegadorAgente.indexOf("Brave") > -1
    ) {
    } else {
      setalertaNavegador(true);
    }
    // eslint-disable-next-line
  }, [rows, empresaheader, usuarioheader, rolheader, usuario, rows, socket]);

  // ADMIN Y SIDEBAR
  const bloquearFuncion = (item) => {
    if (item.sidInfo.bloquearAreaDepartamento === "SI") {
      const bloquear = item.sidInfo.areasDepartamento.some(
        (itemArea) => itemArea === usuario.areadepartamento
      );

      if (bloquear) {
        return false;
      } else {
        return true;
      }
    }
  };
  const container =
    windowProps !== undefined ? () => windowProps().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!empresaheader) {
    return <Cargando titulo="CARGANDO HEADERS" />;
  }
  if (!usuarioheader) {
    return <Cargando titulo="CARGANDO HEADERS" />;
  }
  if (!rolheader) {
    return <Cargando titulo="CARGANDO HEADERS" />;
  }
  // initialize and destroy the PerfectScrollbar plugin
  if (!usuario) return <Cargando titulo="CARGANDO USUARIO" />;
  if (!rows) return <Cargando titulo="CARGANDO MENU" />;
  if (!socket) return <Cargando titulo="Creando conexion en tiempo real" />;

  return (
    <>
      <ThemeProvider theme={usuario ? themeF(usuario) : theme}>
        {alertaNavegador ? (
          <Box display="flex" justifyContent={"center"}>
            <Alert
              style={{ position: "fixed", zIndex: "8086" }}
              severity={"error"}
              variant="filled"
              onClose={() => {
                setalertaNavegador(false);
              }}
            >
              Para obtener una mejor experiencia de uso, utilizar el navegador
              Chrome o Brave.
            </Alert>
          </Box>
        ) : null}
        {alerta ? (
          <Box display="flex" justifyContent="center">
            <Alert
              className="animacionAlerta"
              style={{ position: "fixed", zIndex: "8086" }}
              severity={alerta.categoria}
              variant="filled"
            >
              {alerta.msg}
            </Alert>
          </Box>
        ) : null}
        <Box sx={{ display: "flex" }}>
          {/* <CssBaseline /> */}
          <AppBar
            position="fixed"
            sx={{
              display: { xs: "block", sm: "none" },
            }}
          >
            <NavbarMobile
              buscador={buscador}
              setbuscador={setbuscador}
              handleDrawerToggle={handleDrawerToggle}
              open={open}
            />
          </AppBar>
          <AppBarDesktop
            position="fixed"
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <NavbarDesktop
              buscador={buscador}
              setbuscador={setbuscador}
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
              open={open}
            />
          </AppBarDesktop>
          <Box
            component="nav"
            sx={{ flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              <Sidebar
                buscador={buscador}
                setbuscador={setbuscador}
                open={true}
                mobile={true}
                handleDrawerToggle={handleDrawerToggle}
              />
            </Drawer>
            <DrawerDesktop
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
              }}
              open={open}
            >
              <Toolbar />
              <Sidebar
                buscador={buscador}
                setbuscador={setbuscador}
                open={open}
              />
            </DrawerDesktop>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1, 
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              minHeight: "90vh",
              overflow: "scroll",
            }}
          >
            <Box
              sx={{
                minHeight: "90vh",
              }}
            >
              <Toolbar />
              {/* <Box sx={{}}> */}
              <Switch>
                {rows
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "subCategoria" ||
                      route.sidInfo.tipo === "subsubCategoria"
                  )
                  .map((rutasubsubosub, key, arraySSCOSC) => {
                    if (rutasubsubosub.sidInfo.tipo === "subsubCategoria") {
                      const subC = arraySSCOSC.find(
                        (itemarraySSCOSC) =>
                          itemarraySSCOSC.sidInfo.path ===
                          rutasubsubosub.sidInfo.subcategoria
                      );

                      if (bloquearFuncion(rutasubsubosub)) {
                        return null;
                      }

                      if (!subC) {
                        return null;
                      }

                      return (
                        <Route
                          path={
                            subC.sidInfo.layout +
                            subC.sidInfo.categoria +
                            subC.sidInfo.path +
                            rutasubsubosub.sidInfo.path
                          }
                          component={rutasubsubosub.component}
                          key={key}
                        />
                      );
                    }
                    return null;
                  })}
                {rows
                  .filter((route) => route.sidInfo.tipo === "subCategoria")
                  .map((prop, key) => {
                    if (bloquearFuncion(prop)) {
                      return null;
                    }
                    return (
                      <Route
                        path={
                          prop.sidInfo.layout +
                          prop.sidInfo.categoria +
                          prop.sidInfo.path
                        }
                        component={prop.component}
                        key={key}
                      />
                    );
                  })}
                {rows
                  .filter(
                    (route) =>
                      route.sidInfo.tipo === "categoria" || !route.sidInfo.tipo
                  )
                  .map((prop, key) => {
                    if (bloquearFuncion(prop)) {
                      return null;
                    }
                    return (
                      <Route
                        path={prop.sidInfo.layout + prop.sidInfo.path}
                        component={prop.component}
                        key={key}
                      />
                    );
                  })}
                <Route path={"/Seguridad/Chat/*"} component={ChatPage} />
                <Redirect from="/Seguridad" to="/Seguridad/Dashboard" />
              </Switch>
            </Box>
            <Footer />
            <Notification />
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
