import {
  OBTENER,
  CREAR,
  EDITAR,
  ELIMINAR,
  CHANGE_ACTIVO,
  CLEAR_ROWS,
  ADD_ROWS,
  REMPLAZAR_ROWS,
  IMPORTAR_ROWS,
  ELIMINAR_SELECCION,
  CHANGE_GUARDADO,
} from "../../types/index";

const reducer = (state, action) => {
  switch (action.type) {
    case OBTENER:
      return {
        ...state,
        rows: action.payload.results,
        pagination: action.payload.pagination,
      };
    case REMPLAZAR_ROWS:
      return {
        ...state,
        rows: action.payload.results,
        pagination: action.payload.pagination,
      };
    case CREAR:
      if (state.rows === null) {
        return { ...state };
      } else {
        action.payload.funcionAalerta();
        delete action.payload.item.msg;
        delete action.payload.item.tabla;
        delete action.payload.item.rucempresa;
        const newRows = [action.payload.item, ...state.rows];
        return {
          ...state,
          rows: newRows,
          pagination: {
            ...state.pagination,
            numRows: state.pagination.numRows + 1,
          },
        };
      }

    case EDITAR:
      if (state.rows === null) {
        return { ...state };
      } else {
        action.payload.funcionAalerta();
        return {
          ...state,
          rows: state.rows.map((row) => {
            if (row.camId === action.payload.item.camId) {
              delete action.payload.item.msg;
              delete action.payload.item.tabla;
              delete action.payload.item.rucempresa;
              return action.payload.item;
            } else {
              return row;
            }
          }),
        };
      }

    case ELIMINAR:
      if (state.rows === null) {
        return { ...state };
      } else {
        action.payload.funcionAalerta();
        return {
          ...state,
          rows: state.rows.filter((row) => {
            return row.camId !== action.payload._id;
          }),
          pagination: {
            ...state.pagination,
            numRows: state.pagination.numRows - 1,
          },
        };
      }
    case ELIMINAR_SELECCION:
      if (state.rows === null) {
        return { ...state };
      } else {
        action.payload.funcionAalerta();
        return {
          ...state,
          rows: state.rows.filter((row) => {
            return !action.payload._ids.some((item) => {
              return item === row.camId;
            });
          }),
          pagination: {
            ...state.pagination,
            numRows: state.pagination.numRows - action.payload._ids.length,
          },
        };
      }

    case CHANGE_ACTIVO:
      return {
        ...state,
        ObjectoActivo: action.payload,
      };
    case CHANGE_GUARDADO:
      return {
        ...state,
        ObjetoGuardado: action.payload,
      };
    case CLEAR_ROWS:
      return {
        ...state,
        rows: null,
      };
    case ADD_ROWS:
      return {
        ...state,
        rows: [...state.rows, ...action.payload],
      };
    case IMPORTAR_ROWS:
      return {
        ...state,
        rows: action.payload.res.data.data.results,
        pagination: action.payload.res.data.data.pagination,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
