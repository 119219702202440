import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Box,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Cancel, Check, Map } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import ButtonsAction from "../../../../components/ButtonsAction";
import { itemHijoDefecto } from "../../data";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import DialogMap from "../../../../components/DialogMap";

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { itemPadre, setagregandoDato, socket, guardiasTotales } = props;
  const [openMap, setopenMap] = useState(false);
  const { usuario } = useContext(AuthContext);

  const [itemNuevo, setItemNuevo] = useState(itemHijoDefecto);
  const botonDisabled = () => {
    if (
      itemNuevo.codigo === "" ||
      itemNuevo.guardias === "" ||
      itemNuevo.horasservicio === "" ||
      itemNuevo.puesto === "" ||
      itemNuevo.supervisor === "" ||
      itemNuevo.ubicacion === ""
      // itemNuevo.tipoinstalacion === "" ||
      // itemNuevo.vulnerabilidades === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregandoDato(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
        setagregandoDato(false);
        setItemNuevo(itemHijoDefecto);
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgregar = async () => {
    try {
      const datosoperativos = [
        trimPropsItem({ ...itemNuevo, user: usuario.usuario }),
        ...itemPadre.datosoperativos,
      ];
      socket.current.emit("client:actualizarData", {
        ...itemPadre,
        tabla: "datosoperativos",
        datosoperativos,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        planUser: usuario.usuario,
        planEmpresa: usuario.rucempresa,
        datosoperativosOld: itemPadre.datosoperativos,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <DialogMap
        open={openMap}
        setopen={setopenMap}
        setitem={setItemNuevo}
        item={itemNuevo}
        editar={false}
        propiedad={itemNuevo.coordenadas}
        nombrePropiedad={"coordenadas"}
        solover={false}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />

        {/* las demas columnas */}

        <>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              name="codigo"
              value={itemNuevo.codigo}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              name="ubicacion"
              value={itemNuevo.ubicacion}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              disabled
              value={
                itemNuevo.coordenadas.latitud +
                " " +
                itemNuevo.coordenadas.longitud
              }
              size="small"
              fullWidth
              InputProps={{
                endAdornment: (
                  <Box htmlFor="contained-button-documento" fullWidth>
                    <InputAdornment>
                      <Tooltip title="Mostrar Mapa" arrow>
                        <Box>
                          <IconButton
                            aria-label=""
                            onClick={() => setopenMap(true)}
                          >
                            <Map color="secondary" />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </InputAdornment>
                  </Box>
                ),
              }}
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              name="puesto"
              placeholder="Nombre"
              value={itemNuevo.puesto}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              InputProps={{
                inputProps: { min: 0 },
              }}
              name="supervisor"
              type="number"
              value={itemNuevo.supervisor}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              InputProps={{
                inputProps: { min: 0 },
              }}
              type="number"
              value={itemNuevo.guardias}
              onChange={(e) => {
                if (Number(e.target.value) < 1) {
                  setItemNuevo({
                    ...itemNuevo,
                    guardias: 1,
                  });
                } else {
                  if (
                    Number(e.target.value) + Number(guardiasTotales) >
                    itemPadre.perGuardias
                  ) {
                    return mostrarAlerta(
                      `Su maximo de guardias es de ${itemPadre.perGuardias}`,
                      "error"
                    );
                  } else {
                    setItemNuevo({
                      ...itemNuevo,
                      guardias: e.target.value,
                    });
                  }
                }
              }}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              value={itemNuevo.horasservicio}
              name="horasservicio"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
              type="number"
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              name="tipoinstalacion"
              value={itemNuevo.tipoinstalacion}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              type="color"
              name="color"
              value={itemNuevo.color}
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
