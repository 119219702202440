import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../Context/Auth/AuthContext";

// MATERIAL
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import {
  AddModerator,
  Cancel,
  Check,
  Create,
  Delete,
  ExpandLess,
  ExpandMore,
  Map,
  PersonAdd,
  QrCode,
  Timelapse,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { usePermiso } from "../../../../hooks/usePermiso";
import ButtonsAction from "../../../../components/ButtonsAction";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import ModalQR from "./ModalQR";
import DialogMap from "../../../../components/DialogMap";
import { useSimpleTable } from "../../../../hooks/useSimpleTable";
import clienteAxios from "../../../../../config/axios";
import HorariosTab from "./HorariosTab";
import ActividadesTab from "./ActividadesTab/ActividadesTab";
import ModalHorarios from "../../../ModalHorarios";
import ModalAsiActividad from "../../../ModalAsiActividad";
const RowAgregar = (props) => {
  const classes = useRowStyles();
  const {
    datoOperativo,
    itemPadre,
    index,
    socket,
    guardiasTotales,
    horario,
  } = props;

  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [itemdatoOperativo, setitemdatoOperativo] = useState({
    ...datoOperativo,
  });
  const [editar, seteditar] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("DatosOperativos");
  const [openMap, setopenMap] = useState(false);
  const [anio, setanio] = useState(new Date().getFullYear());
  const [mes, setmes] = useState(new Date().getMonth() + 1);
  const [columnasGeneradas, setcolumnasGeneradas] = useState([]);
  const [diasSeleccionados, setdiasSeleccionados] = useState({});
  //*EXPANDIBLE
  const [personasData, setpersonasData] = useState(null);
  const [expandir, setexpandir] = useState(false);
  const [cargando, setcargando] = useState(false);
  const {
    agregandoPersona,
    setagregandoPersona,
    pagePersona,
    // setPagePersona,
    rowsPerPagePersona,
    // setRowsPerPagePersona,
    // handleChangePagePersona,
    // handleChangeRowsPerPagePersona,
  } = useSimpleTable("Persona");
  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [openQr, setopenQr] = useState(false);
  const botonDisabled = () => {
    if (
      itemdatoOperativo.codigo === "" ||
      itemdatoOperativo.guardias === "" ||
      itemdatoOperativo.horasservicio === "" ||
      itemdatoOperativo.puesto === "" ||
      itemdatoOperativo.supervisor === "" ||
      itemdatoOperativo.ubicacion === ""
      // itemEdit.tipoinstalacion === "" ||
      // itemEdit.vulnerabilidades === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }
        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }
        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemdatoOperativo(datoOperativo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "datosoperativos",
      datosoperativos: itemPadre.datosoperativos.map((item, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...itemdatoOperativo,
            color:
              !itemdatoOperativo.color || itemdatoOperativo.color === ""
                ? "#000000"
                : itemdatoOperativo.color,
          };
        } else {
          return {
            ...item,
            color:
              !item.color || itemdatoOperativo.color === ""
                ? "#000000"
                : item.color,
          };
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
      datosoperativosOld: itemPadre.datosoperativos,
    });
    setopen(false);

    seteditar(false);
  };
  const handleChange = (e) => {
    setitemdatoOperativo({
      ...itemdatoOperativo,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "datosoperativos",
      datosoperativos: itemPadre.datosoperativos.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
      datosoperativosOld: itemPadre.datosoperativos,
    });
  };
  useEffect(() => {
    setitemdatoOperativo({ ...datoOperativo });
    // eslint-disable-next-line
  }, [itemPadre]);
  const getPersonasByMonthYear = async (anio, mes) => {
    setcargando(true);
    try {
      function obtenerDiasDelMes(anio, mes) {
        const diasDelMes = new Date(anio, mes, 0).getDate();
        const fechas = [];
        for (let dia = 1; dia <= diasDelMes; dia++) {
          const fecha = new Date(anio, mes - 1, dia);
          const fechaString =
            Date.parse(fecha) + new Date().getTimezoneOffset() * 60 * 1000;
          fechas.push(fechaString);
        }
        return fechas;
      }

      const res = await clienteAxios.post(
        "/horarios_jornada_laboral/byMonthYear",
        {
          month: mes,
          year: anio,
          id_dOperativo: datoOperativo.id,
          idpersonas: [],
        }
      );
      const dias = obtenerDiasDelMes(
        anio, //year
        mes //month
      );
      setcolumnasGeneradas(dias);
      setpersonasData(res.data.data);
      setcargando(false);
      setdiasSeleccionados({});
    } catch (error) {
      console.log({ error });
      setdiasSeleccionados({});
      setpersonasData([]);
      setcargando(false);
      mostrarAlerta("Hubo un error al generar horarios", "error");
    }
  };
  const [diasSelect, setdiasSelect] = useState([]);
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [horarioActivo, sethorarioActivo] = useState(null);
  const getHorarioActivo = async (id) => {
    const res = await clienteAxios(`/horarios/${id}`);
    sethorarioActivo(res.data);
  };
  const [estadoPersonas, setEstadoPersonas] = useState("GUARDIA");
  const [planificacionData, setplanificacionData] = useState(null);
  const [open, setopen] = useState(false);
  const [openAsiAct, setopenAsiAct] = useState(false);

  const [horarios, sethorarios] = useState(null);
  const getHorarios = async (ids) => {
    try {
      if (datoOperativo.id_horarios.length === 0) {
        return sethorarios([]);
      }
      const res = await clienteAxios.get(
        `/horarios/${datoOperativo.id_horarios}?varios=true`
      );
      sethorarios(res.data);
    } catch (error) {
      sethorarios([]);
    }
  };
  useEffect(() => {
    if (expandir && horarios?.length !== datoOperativo.id_horarios.length) {
      getHorarios();
    }
  }, [datoOperativo, expandir]);
  const asignarActividad = async (id) => {
    try {
      const res = await clienteAxios.post("/asignacion_actividades", {
        id_planificacion: id, // id de la actividad
        idHorarios: Object.keys(diasSeleccionados), // id de los dias seleccionados
        month: mes,
        year: anio,
        id_dOperativo: datoOperativo.id,
      });
      setopenAsiAct(false);
      setpersonasData(res.data.data);
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
    }
  };
  return (
    <React.Fragment>
      {open && (
        <ModalHorarios
          openModal={open}
          setopenModal={(e) => {
            setopen(e);
            setitemdatoOperativo(datoOperativo);
          }}
          itemdatoOperativo={itemdatoOperativo}
          setitemdatoOperativo={setitemdatoOperativo}
          funcSubmit={funcSubmit}
        />
      )}
      {setopenAsiAct && (
        <ModalAsiActividad
          estadoPersonas={estadoPersonas}
          openModal={openAsiAct}
          setopenModal={setopenAsiAct}
          datoOperativo={datoOperativo}
          diasSeleccionados={diasSeleccionados}
          anio={anio}
          mes={mes}
          itemPadre={itemPadre}
          setpersonasData={setpersonasData}
        />
      )}
      {horarioActivo ? (
        <Dialog
          maxWidth="xl"
          open={horarioActivo}
          onClose={() => sethorarioActivo(null)}
        >
          <DialogTitle
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            {horarioActivo.codigo} - {horarioActivo.tipo}
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            Hora : {horarioActivo.hora_inicio} - {horarioActivo.hora_final}
          </DialogContent>
        </Dialog>
      ) : null}
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
        row={itemPadre}
        rowHijo={datoOperativo}
      />
      <DialogMap
        open={openMap}
        setopen={setopenMap}
        setitem={setitemdatoOperativo}
        item={itemdatoOperativo}
        editar={true}
        propiedad={itemdatoOperativo.coordenadas}
        nombrePropiedad={"coordenadas"}
        solover={!editar}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={datoOperativo}
          botones={[
            ...(editar ? botonesEditar : botones),
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              id: 5,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Agregar persona",
              texto: "",
              funcion: async () => {
                if (!personasData) {
                  getPersonasByMonthYear(anio, mes);
                }
                setagregandoPersona(true);
                setexpandir(true);
              },
              disabled: false,
              Icon: PersonAdd,
              color: "primary",
              id: 5,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Administrar horarios de puesto",
              texto: datoOperativo.id_horarios.length,
              funcion: async () => {
                setopen(true);
              },
              disabled: false,
              Icon: AddModerator,
              color: "primary",
              id: 5,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Expandir",
              texto: "",
              funcion: async () => {
                if (!personasData) {
                  getPersonasByMonthYear(anio, mes);
                }
                setexpandir(!expandir);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 5,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                name="codigo"
                value={itemdatoOperativo.codigo}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemdatoOperativo.ubicacion}
                name="ubicacion"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                placeholder="Nombre"
                value={itemdatoOperativo.puesto}
                name="puesto"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                disabled
                value={
                  itemdatoOperativo.coordenadas.latitud +
                  " " +
                  itemdatoOperativo.coordenadas.longitud
                }
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Box htmlFor="contained-button-documento" fullWidth>
                      <InputAdornment>
                        <Tooltip title="Mostrar Mapa" arrow>
                          <Box>
                            <IconButton
                              aria-label=""
                              onClick={() => setopenMap(true)}
                            >
                              <Map color="secondary" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </InputAdornment>
                    </Box>
                  ),
                }}
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                InputProps={{
                  inputProps: { min: 0 },
                }}
                type="number"
                value={itemdatoOperativo.supervisor}
                name="supervisor"
                onChange={(e) => {
                  setitemdatoOperativo({
                    ...itemdatoOperativo,
                    supervisor: Number(e.target.value) < 1 ? 1 : e.target.value,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                InputProps={{
                  inputProps: { min: 0 },
                }}
                type="number"
                value={itemdatoOperativo.guardias}
                onChange={(e) => {
                  if (Number(e.target.value) < 1) {
                    setitemdatoOperativo({
                      ...itemdatoOperativo,
                      guardias: 1,
                    });
                  } else {
                    if (
                      Number(e.target.value) +
                        Number(guardiasTotales) -
                        Number(datoOperativo.guardias) >
                      itemPadre.perGuardias
                    ) {
                      return mostrarAlerta(
                        `Su maximo de guardias es de ${itemPadre.perGuardias}`,
                        "error"
                      );
                    } else {
                      setitemdatoOperativo({
                        ...itemdatoOperativo,
                        guardias: e.target.value,
                      });
                    }
                  }
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemdatoOperativo.horasservicio}
                name="horasservicio"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
                type="number"
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemdatoOperativo.tipoinstalacion}
                name="tipoinstalacion"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                type="color"
                name="color"
                value={itemdatoOperativo.color}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
              {itemdatoOperativo.color}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.codigo}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.ubicacion}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.puesto}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <IconButton aria-label="" onClick={() => setopenMap(true)}>
                <Map color="secondary" />
              </IconButton>
              {datoOperativo.coordenadas
                ? datoOperativo.coordenadas.latitud +
                  " " +
                  datoOperativo.coordenadas.longitud
                : null}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.supervisor}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.guardias}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.horasservicio}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.tipoinstalacion}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {datoOperativo.color}
            </TableCell>
          </>
        )}
        {/* {editar ? (
          <>
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
               
              align="center"
              className={claseCelda}
            >
              <TextField
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
    
          </>
        )} */}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, padding: 0, margin: 0 }}
          colSpan={21}
        >
          {horarios && (
            <Collapse in={expandir} timeout="auto" unmountOnExit>
              <Box my={1}>
                <Button
                  variant={
                    estadoPersonas === "GUARDIA" ? "contained" : "outlined"
                  }
                  sx={{ borderRadius: ".5rem .5rem 0 0" }}
                  disableElevation
                  size="small"
                  onClick={() => {
                    setEstadoPersonas("GUARDIA");
                    setdiasSeleccionados({});
                  }}
                >
                  GUARDIA
                </Button>
                <Button
                  variant={
                    estadoPersonas === "SUPERVISOR" ? "contained" : "outlined"
                  }
                  sx={{ borderRadius: ".5rem .5rem 0 0" }}
                  disableElevation
                  size="small"
                  onClick={() => {
                    setEstadoPersonas("SUPERVISOR");
                    setdiasSeleccionados({});
                  }}
                >
                  SUPERVISOR
                </Button>
                <Button
                  variant={
                    estadoPersonas === "ADMINISTRACION"
                      ? "contained"
                      : "outlined"
                  }
                  sx={{ borderRadius: ".5rem .5rem 0 0" }}
                  disableElevation
                  size="small"
                  onClick={() => {
                    setEstadoPersonas("ADMINISTRACION");
                    setdiasSeleccionados({});
                  }}
                >
                  ADMINISTRACION
                </Button>

                <Divider sx={{ width: "100%" }} />
                {personasData && (
                  <HorariosTab
                    horarios={horarios}
                    agregandoPersona={agregandoPersona}
                    anio={anio}
                    cargando={cargando}
                    columnasGeneradas={columnasGeneradas}
                    diasSeleccionados={diasSeleccionados}
                    diasSelect={diasSelect}
                    getHorarioActivo={getHorarioActivo}
                    guardiasTotales={guardiasTotales}
                    horario={horario}
                    horarioActivo={horarioActivo}
                    datoOperativo={datoOperativo}
                    itemPadre={itemPadre}
                    mes={mes}
                    mostrarAlerta={mostrarAlerta}
                    pagePersona={pagePersona}
                    personasData={personasData}
                    rowsPerPagePersona={rowsPerPagePersona}
                    setanio={setanio}
                    setcargando={setcargando}
                    setdiasSeleccionados={setdiasSeleccionados}
                    setmes={setmes}
                    setpersonasData={setpersonasData}
                    socket={socket}
                    getPersonasByMonthYear={getPersonasByMonthYear}
                    setdiasSelect={setdiasSelect}
                    setagregandoPersona={setagregandoPersona}
                    estadoPersonas={estadoPersonas}
                    setopenAsiAct={setopenAsiAct}
                  />
                )}
              </Box>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
