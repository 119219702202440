import React, { memo, useContext } from "react";

// MATERIAL
import {
  Box,
  TableCell,
  TableRow,
  Checkbox,
  FormLabel,
  Badge,
} from "@mui/material";
import { useRowStyles } from "../../../../styles/stylesRows";

const RowAgregar = (props) => {
  const classes = useRowStyles();
  const {
    itemPersona,
    index,
    sethorarioActivo,
    horarioActivo,
    getHorarioActivo,
    actividad,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {/* las demas columnas */}
        <>
          <TableCell fontSize="small" align="left" className={claseCelda}>
            {itemPersona.perDocNumero} - {itemPersona.perNombre}
          </TableCell>
          {itemPersona.horarios?.map(
            ({
              codigo,
              id,
              color,
              id_horario,
              estado,
              estatus_proceso,
              actividad_asignada,
            }) => (
              <TableCell
                fontSize="small"
                align="center"
                padding="none"
                className={claseCelda}
                sx={{
                  padding: "0 !important",
                  margin: "0 !important",
                  background:
                    horarioActivo?.id === id_horario
                      ? horarioActivo?.color + " !important"
                      : "",
                }}
                onDoubleClick={() => getHorarioActivo(id_horario)}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <FormLabel
                    label={codigo}
                    sx={{
                      padding: 0,
                      margin: 0,
                      fontWeight: "bold",
                      fontSize: ".8rem",
                      color,
                      fontSize: ".7rem",
                    }}
                    labelPlacement="top"
                  >
                    {codigo}
                  </FormLabel>

                  <Badge
                    badgeContent={
                      actividad.id_horarios.some(
                        (idHorario) => idHorario === id
                      )
                        ? "A"
                        : null
                    }
                    color={"success"}
                    sx={{ ml: "20%", mt: "-.3rem", fontSize: ".6rem" }}
                    componentsProps={{
                      badge: {
                        style: {
                          fontSize: "0.6rem",
                          fontWeight: "bold",
                        },
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  ></Badge>
                </Box>
              </TableCell>
            )
          )}
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
