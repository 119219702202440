import { FechaExacta } from "../../../config/const";

export const columns = [
  {
    id: "tipo",
    label: "Tipo",
    minWidth: 100,
    tipo: "string",
    propiedad: "tipo",
  },
  {
    id: "para",
    label: "Para",
    minWidth: 200,
    tipo: "string",

    propiedad: "para",
  },
  {
    id: "detalle",
    label: "Detalle",
    minWidth: 100,
    tipo: "string",

    propiedad: "detalle",
  },
  {
    id: "fecha",
    label: "Fecha",
    minWidth: 100,
    tipo: "string",

    propiedad: "fecha",
  },
];

export const objectDefault = {
  // id: 2,
  fecha: FechaExacta,
  tipo: "MANTENIMIENTO",
  para: "WEB",
  detalle: "",
};
