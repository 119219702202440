import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "capaFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaFecReg",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "capaNombreCapacitador",
    label: "Capacitador",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaNombreCapacitador",
    align: "center",
    ocultar: true,
  },
  {
    id: "capaTitulo",
    label: "Titulo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaTitulo",
    align: "center",
    ocultar: true,
  },
  {
    id: "capaFecDesde",
    label: "F. Desde",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaFecDesde",
    align: "center",
    ocultar: true,
  },
  {
    id: "capaFecHasta",
    label: "F. Hasta",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaFecHasta",
    align: "center",
    ocultar: true,
  },
  {
    id: "capaFecReg",
    label: "F. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "capaUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "capaUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // capaId: 1,
  capaEncId: 0,
  capaEncTitulo: "",
  capaTipoDoc: "ENCUESTAS",
  capaDetalles: "",
  capaGuardias: [],
  capaSupervisores: [],
  capaAdministracion: [],
  capaFecUpd: "",
  capaFecReg: "",
  capaIdCliente: "",
  capaNombreCliente: "",
  capaDocumentoCliente: "",
  capaTipoCapacitador: "SUPERVISOR",
  capaIdCapacitador: "",
  capaNombreCapacitador: "",
  capaTitulo: "",
  capaPrioridad: "MEDIA",
  capaFecDesde: FechaExacta,
  capaFecHasta: FechaExacta,
  capaEstado: "ACTIVA",
  capaDocumento: "",
  capaFotos: [],
  capaUser: "",
  capaEmpresa: "",
};
