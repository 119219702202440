import React, { memo, useContext } from "react";

import {
  Box,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { usePermiso } from "../../hooks/usePermiso";
import { trimPropsItem } from "../../../config/const";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Horarios");
  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  //onChange

  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.hora_final.trim() === "" ||
      item.hora_final.trim() === "" ||
      item.tipo.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "horario",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        user: usuario.usuario,
        // bodEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,

          tabla: "horario",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          // bodEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "horario",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario + "/+/" + row[campoExport],
          estEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };
  const obtenerHoraLimite = (hora, horaLimite) => {
    // Convertir las horas a minutos
    const horaEnMinutos = hora.split(":")[0] * 60 + hora.split(":")[1];
    const horaLimiteEnMinutos =
      horaLimite.split(":")[0] * 60 + horaLimite.split(":")[1];

    // Comprobar si la hora es posterior a la hora límite
    if (Number(horaEnMinutos) > Number(horaLimiteEnMinutos)) {
      return horaLimite;
    } else {
      return hora;
    }
  };
  const obtenerHoraLimiteinv = (hora, horaLimite) => {
    // Convertir las horas a minutos
    const horaEnMinutos = hora.split(":")[0] * 60 + hora.split(":")[1];
    const horaLimiteEnMinutos =
      horaLimite.split(":")[0] * 60 + horaLimite.split(":")[1];

    // Comprobar si la hora es posterior a la hora límite
    if (Number(horaEnMinutos) < Number(horaLimiteEnMinutos)) {
      return horaLimite;
    } else {
      return hora;
    }
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={(e) => {
            setarrayExport(e);
          }}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 1,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: row.tipo === "LIBRE" || row.tipo === "24",
              Icon: Create,
              color: "secondary",
              id: 3,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled:
                row.apoTipo === "" ||
                row.apoAporte === "" ||
                row.apoValidacion === "true",
              Icon: DeleteForever,
              color: "error",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 4,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 5,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Estado",
              texto: "",
              funcion: (e) => {
                setitem({
                  ...item,
                  bodEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                });
              },
              disabled: !editar,
              Icon: "",
              color: "primary",
              id: 6,
              ocultar: true,
              tipo: "check",
              estado: editar
                ? item.bodEstado === "ACTIVO"
                : row.bodEstado === "ACTIVO",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        {editar ? (
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.codigo}
                name="codigo"
                onChange={(e) =>
                  setitem({
                    ...item,
                    codigo:
                      (item.tipo === "LIBRE"
                        ? "FF"
                        : item.tipo === "24"
                        ? "24"
                        : item.tipo === "DIA"
                        ? "D"
                        : "N") +
                      e.target.value.substring(
                        item.tipo === "LIBRE" || item.tipo === "24" ? 2 : 1,
                        4
                      ),
                  })
                }
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Typography
                        color={item.codigo.length === 4 ? "error" : "secondary"}
                      >
                        {item.codigo.length}/4
                      </Typography>
                    </React.Fragment>
                  ),
                }}
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.tipo}
                name="tipo"
                onChange={(e) =>
                  setitem({
                    ...item,
                    tipo: e.target.value,
                    hora_inicio:
                      e.target.value === "LIBRE" || e.target.value === "24"
                        ? "00:00"
                        : e.target.value === "DIA"
                        ? "06:00"
                        : "18:00",
                    hora_final:
                      e.target.value === "LIBRE" || e.target.value === "24"
                        ? "00:00"
                        : e.target.value === "DIA"
                        ? "18:00"
                        : "06:00",
                    codigo:
                      e.target.value === "LIBRE"
                        ? "FF"
                        : e.target.value === "24"
                        ? "24"
                        : e.target.value === "DIA"
                        ? "D"
                        : "N",
                  })
                }
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="" disabled>
                  Seleccione.
                </MenuItem>
                {["NOCHE", "DIA", "LIBRE", "24"].map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type="time"
                value={item.hora_inicio}
                name="hora_inicio"
                onChange={(e) => {
                  setitem({
                    ...item,
                    hora_inicio:
                      item.tipo === "DIA"
                        ? obtenerHoraLimite(item.hora_final, e.target.value)
                        : obtenerHoraLimiteinv(item.hora_final, e.target.value),
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type="time"
                value={item.hora_final}
                name="hora_final"
                onChange={(e) => {
                  setitem({
                    ...item,
                    hora_final:
                      item.tipo === "DIA"
                        ? obtenerHoraLimiteinv(item.hora_inicio, e.target.value)
                        : obtenerHoraLimite(item.hora_inicio, e.target.value),
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type="color"
                value={item.color}
                name="color"
                onChange={(e) => {
                  setitem({
                    ...item,
                    color: e.target.value,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.codigo}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.tipo}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.tipo === "LIBRE" || row.tipo === "24"
                ? "-"
                : row.hora_inicio}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.tipo === "LIBRE" || row.tipo === "24" ? "-" : row.hora_final}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <Box
                sx={{ background: row.color }}
                width={"100%"}
                height={"100%"}
              >
                {row.color}
              </Box>
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
