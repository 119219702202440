import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";

// eslint-disable-next-line

import AlertaContext from "../../../Context/Alerta/AlertaContext";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete } from "@mui/material";
import CambioPuestoContext from "../../../Context/CambioPuesto/CambioPuestoContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

function getModalStyleOculto() {
  return {
    borderRadius: "15px",
    opacity: ".2",
  };
}
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.25),
    margin: theme.spacing(0),
    fontSize: ".9rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const {
    // eslint-disable-next-line

    ObjectoActivo,
    change,
  } = useContext(CambioPuestoContext);

  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { socket, usuario } = useContext(AuthContext);
  //props
  const { open, setopen, editar, seteditar } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  const [puestosNuevos, setpuestosNuevos] = useState([]);
  const containerRef = React.useRef(null);

  // eslint-disable-next-line
  const {
    // infIdDirigido,
    // nomIdPersona,

    // camIdPersona,
    camDocuPersona,
    camNomPersona,
    camFecha,
    camMotivo,
    camActualPuesto,
    // camIdCliente,
    camDocuCliente,
    camNomCliente,
    camNuevoPuesto,
    camEstado,
    camStatus,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({
    camIdPersona: false,
    camDocuPersona: false,
    camNomPersona: false,
    camFecha: false,
    camMotivo: false,
    camActualPuesto: false,
    camIdCliente: false,
    camDocuCliente: false,
    camNomCliente: false,
    camNuevoPuesto: false,
    camNuevoTurno: false,
  });

  useEffect(() => {
    setitem(ObjectoActivo);
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    change({
      camStatus: "",
      camEstado: "EN PROCESO",
      //   camId: 1,
      camTipo: "CAMBIO PUESTO", // interno
      camIdPersona: "",
      camDocuPersona: "",
      camNomPersona: "",
      camFecha: "",
      camMotivo: "",
      camActualPuesto: "",
      camIdCliente: "",
      camDocuCliente: "",
      camNomCliente: "",
      camNuevoPuesto: [
        {
          ubicacion: "",
          puesto: "",
          supervisor: "",
          guardias: "",
          horasservicio: "",
          tipoinstalacion: "",
          vulnerabilidades: "",
          consignas: [],
        },
      ],
      camNuevoTurno: "",
      camUser: "",
      camEmpresa: "",
      camFecReg: "",
      Todos: "",
    });
    setopen(false);
    seteditar(false);
    seterror({
      camIdPersona: false,
      camDocuPersona: false,
      camNomPersona: false,
      camFecha: false,
      camMotivo: false,
      camActualPuesto: false,
      camIdCliente: false,
      camDocuCliente: false,
      camNomCliente: false,
      camNuevoPuesto: false,
      camNuevoTurno: false,
    });
    setseccionActive({
      DatosGenerales: true,
    });

    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyleOculto] = useState(getModalStyleOculto);
  const [modalStyle] = useState(getModalStyle);
   
  const [
    ocultar,
    // setocultar
  ] = useState(false);
  const validacion = () => {
    if (
      camDocuPersona.trim() === "" ||
      camMotivo.trim() === "" ||
      camActualPuesto.trim() === "" ||
      camDocuCliente.trim() === "" ||
      camFecha.trim() === ""
    ) {
      seterror({
        camDocuPersona: camDocuPersona.trim() === "" ? true : false,
        camMotivo: camMotivo.trim() === "" ? true : false,
        camActualPuesto: camActualPuesto.trim() === "" ? true : false,
        camDocuCliente: camDocuCliente.trim() === "" ? true : false,
        camFecha: camFecha.trim() === "" ? true : false,
      });
      return true;
    } else {
      return false;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    EstatusSeccion: false,
  });
  // eslint-disable-next-line
  const { DatosGenerales, EstatusSeccion } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      socket.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,

          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          camUser: usuario.usuario,
          camEmpresa: usuario.rucempresa,
          tabla: "cambiopuesto",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      socket.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,

          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          // camUser: usuario.usuario,
          camEmpresa: usuario.rucempresa,
          tabla: "cambiopuesto",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
 

  // eslint-disable-next-line

   
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={ocultar ? modalStyleOculto : modalStyle}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Cambio Puesto.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />{" "}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" center"
                width="100%"
              >
                <Box>
                  <Button
                    variant={DatosGenerales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Principales
                  </Button>

                  {editar ? (
                    <Button
                      variant={EstatusSeccion ? "contained" : null}
                      disableElevation
                      style={{ padding: "8px" }}
                      color="secondary"
                      onClick={() => setseccionActive({ EstatusSeccion: true })}
                    >
                      Status
                    </Button>
                  ) : null}
                </Box>

                {/* <FormControlLabel
                control={
                  <Switch
                    checked={camEstado === "APROBADO" ? true : false}
                    onChange={(e) => {
                      setitem({
                        ...item,
                        camEstado: e.target.checked ? "APROBADO" : "PENDIENTE",
                      });
                    }}
                    name="camEstado"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                {" "}
                <Fade
                  direction="up"
                  in={DatosGenerales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosGenerales ? (
                      <>
                        {/* {editar ? (
                          <TextField
                            disabled={camEstado === "RECIBIDO"}
                            inputProps={{ className: clases.inputPadding }}
                            className={clases.formD}
                            size="small"
                            id="camEstado"
                            label="Estado"
                            value={camEstado}
                            name="camEstado"
                            error={error.camEstado}
                            onChange={(e) => handleChange(e)}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="">Seleccione.</MenuItem>
                            {["APROBADO", "PENDIENTE", "ANULADO"].map(
                              (item) => {
                                return (
                                  <MenuItem value={item}>{item} </MenuItem>
                                );
                              }
                            )}
                          </TextField>
                        ) : null} */}
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            options={personasData.map((item) => {
                              return (
                                item.perDocNumero +
                                "/+/" +
                                item.perApellidos +
                                " " +
                                item.perNombres +
                                "/+/" +
                                item.perId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={
                              (camDocuPersona + " " + camNomPersona).trim() ===
                              ""
                                ? ""
                                : camDocuPersona + " " + camNomPersona
                            }
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                try {
                                  const itemF = personasData.find(
                                    (itemPersona) => {
                                      return (
                                        itemPersona.perId === Number(options[2])
                                      );
                                    }
                                  );

                                  setitem({
                                    ...item,
                                    camDocuPersona: options[0],
                                    camNomPersona: options[1].trim(),
                                    camIdPersona: options[2],
                                    camActualPuesto:
                                      itemF.perNombreCliente +
                                      " " +
                                      itemF.perDocuCliente +
                                      " - " +
                                      itemF.perIdCliente,
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                fullWidth
                                label="Persona"
                                error={error.camDocuPersona}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/personas/filtro/0?search=${e.target.value}`
                                    );

                                    setpersonasData(res.data.data);
                                  } catch (error) {}
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        {/* PUESTOS ACTUALES */}
                        <TextField
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          label="Puesto Actual"
                          value={camActualPuesto}
                          name="camActualPuesto"
                          error={error.camActualPuesto}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* CLIENTES */}
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            disableClearable={true}
                            options={clientesData.map((item) => {
                              return (
                                item.cliRazonSocial +
                                "/+/" +
                                item.cliDocNumero +
                                "/+/" +
                                item.cliId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={
                              (camNomCliente + " " + camDocuCliente).trim() ===
                              ""
                                ? ""
                                : camNomCliente + " " + camDocuCliente
                            }
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                try {
                                  const itemF = clientesData.find(
                                    (itemPersona) => {
                                      return (
                                        itemPersona.cliId === Number(options[2])
                                      );
                                    }
                                  );

                                  setpuestosNuevos(itemF.cliDatosOperativos);
                                  setitem({
                                    ...item,
                                    camIdCliente: options[2],
                                    camDocuCliente: options[1],
                                    camNomCliente: options[0],
                                    camNuevoPuesto: [
                                      {
                                        ubicacion: "",
                                        puesto: "",
                                        supervisor: "",
                                        guardias: "",
                                        horasservicio: "",
                                        tipoinstalacion: "",
                                        vulnerabilidades: "",
                                        consignas: [],
                                      },
                                    ],
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              } else {
                                setitem({
                                  ...item,
                                  camIdCliente: "",
                                  camDocuCliente: "",
                                  camNomCliente: "",
                                  camNuevoPuesto: [
                                    {
                                      ubicacion: "",
                                      puesto: "",
                                      supervisor: "",
                                      guardias: "",
                                      horasservicio: "",
                                      tipoinstalacion: "",
                                      vulnerabilidades: "",
                                      consignas: [],
                                    },
                                  ],
                                });
                                setclientesData([]);
                                setpuestosNuevos([]);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                disabled={editar}
                                required
                                fullWidth
                                label="Nuevo Cliente"
                                error={error.camDocuCliente}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                    );

                                    setclientesData(res.data.data);
                                  } catch (error) {
                                    setitem({
                                      ...item,
                                      camIdCliente: "",
                                      camDocuCliente: "",
                                      camNomCliente: "",
                                      camNuevoPuesto: [
                                        {
                                          ubicacion: "",
                                          puesto: "",
                                          supervisor: "",
                                          guardias: "",
                                          horasservicio: "",
                                          tipoinstalacion: "",
                                          vulnerabilidades: "",
                                          consignas: [],
                                        },
                                      ],
                                    });
                                    setclientesData([]);

                                    return mostrarAlerta(
                                      "No hay clientes",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        {/* PUESTOS NUEVOS */}{" "}
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            options={[
                              ...new Set(
                                puestosNuevos.map((item) => {
                                  return item.ubicacion;
                                })
                              ),
                            ]}
                            getOptionLabel={(option) => {
                              return option;
                            }}
                            id="controlled-demo"
                            value={camNuevoPuesto[0].ubicacion}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                setitem({
                                  ...item,
                                  camNuevoPuesto: [
                                    {
                                      ubicacion: newValue,
                                      puesto: "",
                                      supervisor: "",
                                      guardias: "",
                                      horasservicio: "",
                                      tipoinstalacion: "",
                                      vulnerabilidades: "",
                                      consignas: [],
                                    },
                                  ],
                                });
                              } else {
                                setitem({
                                  ...item,
                                  camNuevoPuesto: [
                                    {
                                      ubicacion: "",
                                      puesto: "",
                                      supervisor: "",
                                      guardias: "",
                                      horasservicio: "",
                                      tipoinstalacion: "",
                                      vulnerabilidades: "",
                                      consignas: [],
                                    },
                                  ],
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                fullWidth
                                label="Nueva Ubicación"
                                error={error.camNuevoPuesto}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            options={puestosNuevos
                              .filter(
                                (item) =>
                                  item.ubicacion === camNuevoPuesto[0].ubicacion
                              )
                              .map((item) => {
                                return item.puesto;
                              })}
                            getOptionLabel={(option) => {
                              return option
                                .replace("/+/", " ** ")
                                .split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={camNuevoPuesto[0].puesto}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const itemF = puestosNuevos.find(
                                  (itemPuesto) =>
                                    itemPuesto.puesto === newValue &&
                                    itemPuesto.ubicacion ===
                                      camNuevoPuesto[0].ubicacion
                                );

                                setitem({
                                  ...item,
                                  camNuevoPuesto: [itemF],
                                });
                              } else {
                                setitem({
                                  ...item,
                                  camNuevoPuesto: [
                                    {
                                      ubicacion: camNuevoPuesto[0].ubicacion,
                                      puesto: "",
                                      supervisor: "",
                                      guardias: "",
                                      horasservicio: "",
                                      tipoinstalacion: "",
                                      vulnerabilidades: "",
                                      consignas: [],
                                    },
                                  ],
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                fullWidth
                                label="Nuevo Puesto"
                                error={error.camNuevoPuesto}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <TextField
                          type={"date"}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            className: clases.inputPadding,
                            min: FechaExacta,
                          }}
                          className={clases.formD}
                          size="small"
                          id="camFecha"
                          label="Fec. Cambio de puesto"
                          value={camFecha}
                          name="camFecha"
                          error={error.camFecha}
                          onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          multiline
                          rows={3}
                          variant="outlined"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="camMotivo"
                          label="Motivo"
                          value={camMotivo}
                          name="camMotivo"
                          error={error.camMotivo}
                          onChange={(e) => handleChange(e)}
                        />
                        {/* <TextField
                        inputProps={{ className: clases.inputPadding }}
                        className={clases.formD}
                        size="small"
                        id="camNuevoTurno"
                        label="NuevoTurno"
                        value={camNuevoTurno}
                        name="camNuevoTurno"
                        error={error.camNuevoTurno}
                        onChange={(e) => handleChange(e)}
                      /> */}
                      </>
                    ) : null}
                  </Box>
                </Fade>{" "}
                <Fade
                  direction="up"
                  in={EstatusSeccion}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {EstatusSeccion ? (
                      <>
                        <Box
                          className={clases.formAllW}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mt={2}
                        >
                          <Divider style={{ width: "42%" }} />
                          <Typography
                            style={{ width: "100%" }}
                            variant="button"
                            color="primary"
                            align="center"
                            component={"span"}
                          >
                            Verificación
                          </Typography>
                          <Divider style={{ width: "42%" }} />
                        </Box>
                        <TextField
                          disabled={ObjectoActivo.camEstado === "APROBADO"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="camEstado"
                          label="Estado"
                          value={camEstado}
                          name="camEstado"
                          error={error.camEstado}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem disabled value="">
                            Seleccione.
                          </MenuItem>
                          {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          disabled={ObjectoActivo.camEstado === "APROBADO"}
                          multiline
                          rows={3}
                          variant="outlined"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formAllW}
                          size="small"
                          id="camStatus"
                          label="Status"
                          value={camStatus}
                          name="camStatus"
                          error={error.camStatus}
                          onChange={(e) => handleChange(e)}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    cargando ||
                    ((ObjectoActivo.camEstado === "ANULADA" ||
                      ObjectoActivo.camEstado === "APROBADO") &&
                      editar)
                  }
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
