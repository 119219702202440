import { Check, Create, Delete } from "@mui/icons-material";
import {
  ListItem,
  ListItemText,
  IconButton,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const ListaItem = ({
  itemLista,
  index,
  itemEdit,
  setItemEdit,
  checksRespuestas,
}) => {
  useEffect(() => {
    settextoEditar(textoEditar);
    // eslint-disable-next-line
  }, [itemLista]);

  const [editar, seteditar] = useState(false);
  const [textoEditar, settextoEditar] = useState(itemLista);
  const handleSubmit = () => {
    if (textoEditar.trim() === "") {
      return null;
    }
    setItemEdit({
      ...itemEdit,
      opcionesLista: itemEdit.opcionesLista.map(
        (itemOpcionListaMap, indexOpcionListaMap) => {
          if (indexOpcionListaMap === index) {
            return textoEditar;
          } else {
            return itemOpcionListaMap;
          }
        }
      ),
      respuestas: itemEdit.respuestas.map((itemOpcionListaMap) => {
        if (itemOpcionListaMap === itemLista) {
          return textoEditar;
        } else {
          return itemOpcionListaMap;
        }
      }),
    });
    seteditar(false);
  };
  const handleDelete = () => {
    setItemEdit({
      ...itemEdit,
      opcionesLista: itemEdit.opcionesLista.filter(
        (itemOpcionListaMap, indexOpcionListaMap) => {
          return indexOpcionListaMap !== index;
        }
      ),
      respuestas: itemEdit.respuestas.filter(
        (itemOpcionListaMap, indexOpcionListaMap) => {
          return itemOpcionListaMap !== itemLista;
        }
      ),
    });
  };
  return (
    <ListItem sx={{ padding: 0 }} key={index}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        {editar ? (
          <TextField
            autoFocus
            inputProps={{ style: { textAlign: "center" } }}
            value={textoEditar}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            onChange={(e) => {
              settextoEditar(e.target.value);
            }}
            size="small"
            fullWidth
            sx={{ display: "inline-block" }}
          />
        ) : (
          <ListItemText
            primary={index + 1 + " - " + itemLista}
            sx={{ display: "inline-block" }}
          />
        )}
        <Box>
          {!editar && checksRespuestas ? (
            <FormControlLabel
              sx={{ margin: "0px", padding: "2px" }}
              control={
                <Checkbox
                  value="ACTIVO"
                  sx={{ margin: "0px", padding: "2px" }}
                  onChange={(e) => {
                    if (itemEdit.tipoCampo === "LISTA") {
                      if (e.target.checked) {
                        setItemEdit({
                          ...itemEdit,
                          respuestas: [itemLista],
                        });
                      } else {
                        setItemEdit({
                          ...itemEdit,
                          respuestas: [],
                        });
                      }
                    } else {
                      if (e.target.checked) {
                        setItemEdit({
                          ...itemEdit,
                          respuestas: [...itemEdit.respuestas, itemLista],
                        });
                      } else {
                        setItemEdit({
                          ...itemEdit,
                          respuestas: itemEdit.respuestas.filter(
                            (itemRespuestaFilter, indexOpcionListaMap) => {
                              return itemRespuestaFilter !== itemLista;
                            }
                          ),
                        });
                      }
                    }
                  }}
                  checked={itemEdit.respuestas.some(
                    (itemRespuesta) => itemRespuesta === itemLista
                  )}
                  color="secondary"
                />
              }
            />
          ) : null}
          {editar ? (
            <IconButton
              size="small"
              onClick={() => {
                handleSubmit();
              }}
            >
              <Check color="secondary" fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => {
                seteditar(true);
              }}
            >
              <Create color="secondary" fontSize="small" />
            </IconButton>
          )}
          {editar ? null : (
            <IconButton
              size="small"
              onClick={() => {
                handleDelete();
              }}
            >
              <Delete color="error" fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </ListItem>
  );
};

export default ListaItem;
