import {
  Badge,
  Box,
  Button,
  Dialog, 
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import ListPersonas from "../ListPersonas";
import { reducer } from "../../../../../../functions/funciones";

const DialogTrabajos = (props) => {
  const { open, setopen, row } = props;
  const [seccion, setseccion] = useState({
    guardiaSeccion: true,
    supervisorSeccion: false,
    administracionSeccion: false,
  });
  const { guardiaSeccion, supervisorSeccion, administracionSeccion } = seccion;

  return (
    <Dialog
      open={open}
      onClose={() => setopen(false)}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Box>
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ padding: ".5rem" }}
          align="center"
        >
          Trabajos
        </DialogTitle>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box display={"flex"} flexWrap={"wrap"}>
            <Badge
              badgeContent={
                row.conGuardias.length === 0
                  ? null
                  : row.conGuardias
                      .map((personaItem) => {
                        return Number(
                          personaItem.trabajos.length === 0 ? 0 : 1
                        );
                      })
                      .reduce(reducer) +
                    "/" +
                    row.conGuardias.length
              }
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Button
                variant={guardiaSeccion ? "contained" : "outlined"}
                onClick={() => setseccion({ guardiaSeccion: true })}
                disableElevation
                color="primary"
                sx={{ flexGrow: 1, borderRadius: "15px 15px 0 0" }}
              >
                GUARDIA
              </Button>
            </Badge>
            <Badge
              badgeContent={
                row.conSupervisores.length === 0
                  ? null
                  : row.conSupervisores
                      .map((personaItem) => {
                        return Number(
                          personaItem.trabajos.length === 0 ? 0 : 1
                        );
                      })
                      .reduce(reducer) +
                    "/" +
                    row.conSupervisores.length
              }
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Button
                variant={supervisorSeccion ? "contained" : "outlined"}
                onClick={() => setseccion({ supervisorSeccion: true })}
                disableElevation
                color="primary"
                sx={{ flexGrow: 1, borderRadius: "15px 15px 0 0" }}
              >
                SUPERVISOR
              </Button>
            </Badge>{" "}
            <Badge
              badgeContent={
                row.conAdministracion.length === 0
                  ? null
                  : row.conAdministracion
                      .map((personaItem) => {
                        return Number(
                          personaItem.trabajos.length === 0 ? 0 : 1
                        );
                      })
                      .reduce(reducer) +
                    "/" +
                    row.conAdministracion.length
              }
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Button
                variant={administracionSeccion ? "contained" : "outlined"}
                onClick={() => setseccion({ administracionSeccion: true })}
                disableElevation
                color="primary"
                sx={{ flexGrow: 1, borderRadius: "15px 15px 0 0" }}
              >
                ADMINISTRACION
              </Button>
            </Badge>
          </Box>
          <Box display={"flex"} flexWrap={"wrap"}></Box>
        </Box>
        <DialogContent dividers>
          {guardiaSeccion && <ListPersonas personas={row.conGuardias} />}
          {supervisorSeccion && <ListPersonas personas={row.conSupervisores} />}
          {administracionSeccion && (
            <ListPersonas personas={row.conAdministracion} />
          )}
        </DialogContent>
 
      </Box>
    </Dialog>
  );
};

export default DialogTrabajos;
