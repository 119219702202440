import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  TextField,
  Divider,
  Typography,
  IconButton,
  useTheme,
  MenuItem,
  Collapse,
  lighten,
} from "@mui/material";

import {
  Backspace,
  ExpandLess,
  ExpandMore,
  FindInPage,
} from "@mui/icons-material";
import CamposBusqueda from "./CamposBusqueda";

const useStyles = makeStyles((theme) => ({
  paper: {
    background: `linear-gradient(to left, ${lighten(
      theme.palette.secondary.main,
      0.95
    )} 0%, ${lighten(theme.palette.secondary.main, 0.85)} 100%)`,

    padding: theme.spacing(1),
    borderRadius: "7px 7px 0px 0px",
  },
  margin: {
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "0",
      width: "45%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));

const ModalB = (props) => {
  const {
    titulo,
    fullBuscador,
    arrayProps: arrayColumnas,
    handleChange,
    funcionB,
    buscando,
    funcionDefault,
  } = props;
  const [expandido, setexpandido] = useState(false);
  const [arrayProps] = useState(arrayColumnas);
  const [arrayOcultarProps, setarrayOcultarProps] = useState([]);

  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  return (
    <div className={clases.paper}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        style={{ cursor: "-webkit-grab" }}
      >
        <Typography
          fontWeight={"900"}
          variant="button"
          color="initial"
          align="center"
        >
          {titulo}
        </Typography>
        <Box display="flex" alignItems="center">
          {buscando ? (
            <IconButton
              aria-label=""
              color="secondary"
              autoFocus
              onKeyPress={(e) => {}}
              onClick={async () => {
                await funcionDefault();
              }}
            >
              <Backspace color="error" />
            </IconButton>
          ) : null}{" "}
          <IconButton
            aria-label=""
            disabled={!expandido}
            style={{ margin: "0px", padding: ".3rem" }}
            onClick={() => {
              funcionB();
            }}
          >
            <FindInPage
              fontSize="large"
              color={expandido ? "secondary" : "inherit"}
            />
          </IconButton>
          <IconButton
            aria-label=""
            style={{ margin: "0px", padding: ".3rem" }}
            onClick={() => {
              setexpandido((ref) => !ref);
            }}
          >
            {expandido ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </IconButton>
        </Box>
      </Box>

      <Divider />

      <Collapse in={expandido} timeout="auto" unmountOnExit>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems=" stretch"
          width="100%"
        >
          <Box></Box>
        </Box>
        <Box display="flex" flexWrap="wrap" alignItems="flex-end" mb={2} p={1}>
          <TextField
            variant="outlined"
            className={clases.formAllW}
            size="small"
            label="Propiedad"
            value={arrayOcultarProps}
            onChange={(e) => {
              setarrayOcultarProps(e.target.value);
            }}
            select
            MenuProps={MenuProps}
            // InputLabelProps={{ shrink: true }}
            SelectProps={{
              displayEmpty: true,
              multiple: true,
              renderValue: (selected) => {
                return selected
                  .map((value, index) => {
                    return value;
                  })
                  .join(" -- ");
              },
            }}
          >
            <MenuItem disabled value="">
              Seleccione.
            </MenuItem>
            {arrayProps
              .sort((a, b) => a.orden - b.orden)
              .map((item) => {
                if (item.ocultar) {
                  return null;
                }
                return (
                  <MenuItem
                    style={getStylesMenuItems(
                      item.label,
                      arrayOcultarProps,
                      theme
                    )}
                    value={item.label}
                  >
                    {item.label}
                  </MenuItem>
                );
              })}
          </TextField>
          {arrayProps
            .sort((a, b) => a.orden - b.orden)
            .map((campoObjectItem) => {
              const existe = arrayOcultarProps.some(
                (item) => item === campoObjectItem.label
              );
              if (campoObjectItem.ocultar) {
                return null;
              }
              if (!existe) {
                return null;
              }
              return (
                <CamposBusqueda
                  campoObjectItem={campoObjectItem}
                  handleChange={handleChange}
                  fullBuscador={fullBuscador}
                />
              );
            })}
          {/* <TextField
      InputLabelProps={{ shrink: true }}
      type="date"
      className={clases.formD}
      id="ccFecReg1"
      label="Fecha Registro"
      value={fullBuscador.ccFecReg1}
      name="ccFecReg1"
      onChange={(e) => handleChange(e)}
    />
    <TextField
      InputLabelProps={{ shrink: true }}
      type="date"
      className={clases.formD}
      id="ccFecReg2"
      label="Fecha Registro"
      value={fullBuscador.ccFecReg2}
      name="ccFecReg2"
      onChange={(e) => handleChange(e)}
    /> */}
        </Box>
        {/* <Divider /> */}
      </Collapse>
    </div>
  );
};

export default ModalB;
