import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import Calendario from "../../../../../components/Calendario";
import { useState } from "react";
import TabsFechasPersonas from "../../TabsFechasPersonas";
import clienteAxios from "../../../../../../config/axios";
import { Cancel } from "@mui/icons-material";

const DialogCalendario = (props) => {
  const { rowHijo, openDialogCalendario, setopenDialogCalendario } = props;
  const [focusedDate, setfocusedDate] = useState("");
  const [supervisoresData, setsupervisoresData] = useState(null);
  const [administracionData, setadministracionData] = useState(null);
  const [guardiasData, setguardiasData] = useState([]);
  const setitemsPersonas = (guardias) => {
    return guardias.map((itemGuardia) => {
      return {
        perFoto: itemGuardia.perFoto,
        perApellidos: itemGuardia.perApellidos,
        perNombres: itemGuardia.perNombres,
        perDocNumero: itemGuardia.perDocNumero,
        perId: itemGuardia.perId,
        perPerfil: itemGuardia.perPerfil,
        perTurnos: itemGuardia.perTurno.map(
          (itemTurno) => itemTurno.fechasConsultaDB
        ),
        trabajos: [],
        leido: false,
      };
    });
  };
  useEffect(() => {
    const getresguardiasData = async () => {
      try {
        // const itemF = await clienteAxios.get(
        //   `/clientes/filtro/0?search=${ObjectoActivo.actNombreCliente}&estado=CLIENTES`
        // );
        const res = await clienteAxios.get(
          `/personas/listado/:codigo?docnumero=${rowHijo.actNombreCliente}`
        );
        setguardiasData(setitemsPersonas(res.data.data));
      } catch (error) {
        setguardiasData([]);
      }
    };

    getresguardiasData();
    const getDataadministracionData = async () => {
      const resadministracionData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=ADMINISTRACION"
      );

      setadministracionData(setitemsPersonas(resadministracionData.data.data));
    };
    if (!administracionData) {
      getDataadministracionData();
    }
    const getDatasupervisoresData = async () => {
      const ressupervisoresData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=SUPERVISORES"
      );

      setsupervisoresData(
        setitemsPersonas(ressupervisoresData.data.data, true)
      );
    };
    if (!supervisoresData) {
      getDatasupervisoresData();
    }
    // eslint-disable-next-line
  }, []);
  if (!supervisoresData || !administracionData || !guardiasData) {
    return null;
  }
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      maxWidth="xl"
      open={openDialogCalendario}
      onClose={() => setopenDialogCalendario(false)}
    >
      <Box display="flex" justifyContent={"space-between"}>
        <DialogTitle> Calendario</DialogTitle>
        <IconButton
          sx={{ p: "1rem 1rem" }}
          color="error"
          onClick={() => setopenDialogCalendario(false)}
        >
          <Cancel />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Calendario
          readOnly={true}
          propiedad={rowHijo.actFechasActividad}
          propiedadDB={rowHijo.actFechasActividadConsultaDB}
          focusedDate={focusedDate}
          setFocusedDate={(e, prev) => {
            setfocusedDate(
              new Date(e - new Date().getTimezoneOffset() * 60 * 1000)
                .toISOString()
                .substring(0, 10)
            );
          }}
          item={rowHijo}
          setitem={(e) => {}}
          TextoPropiedad={"actFechasActividad"}
          textoPropiedadDB={"actFechasActividadConsultaDB"}
          validarFechaAnterior={true}
          // openCalendario={true}
          // setopenCalendario={setopenCalendario}
        />

        <TabsFechasPersonas
          focusedDate={focusedDate}
          evento={rowHijo.actEveDatos}
          fechasEventosGuardias={rowHijo.actGuardias}
          setfechasEventosGuardias={() => {}}
          fechasEventosSupervisores={rowHijo.actSupervisores}
          setfechasEventosSupervisores={() => {}}
          fechasEventosAdministracion={rowHijo.actAdministracion}
          setfechasEventosAdministracion={() => {}}
          guardiasData={guardiasData}
          supervisoresData={supervisoresData}
          administracionData={administracionData}
          actIdCliente={rowHijo.actIdCliente}
          readOnly={true}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DialogCalendario;
