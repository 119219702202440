import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";

import Draggable from "react-draggable";

import { AddCircle, Cancel, Delete } from "@mui/icons-material";
// eslint-disable-next-line

import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
// import clienteAxios from "../../../../../config/axios";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "50%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "90%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(10),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  bold: {
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: "1.5rem",
  },
}));

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  //props

  const { open, setopen, row, estadoRequest } = props;
  const [item, setitem] = useState({
    option: "reenviarCorreo",
    tipo: "",
    id: row.distri_Id,
    // rucempresa: row.//disEmpresa,
    correos: [],
  });
  const [cargando, setcargando] = useState(false);

  const { correos } = item;
  // eslint-disable-next-line

  useEffect(() => {
    if (open === true) {
      let correos = [];
      row.distri_Personas.forEach((itemPersona) => {
        if (itemPersona.correos) {
          correos = [...correos, ...itemPersona.correos];
        }
      });
      if (row.disSupervisores) {
        row.disSupervisores.forEach((itemPersona) => {
          if (itemPersona.correos) {
            correos = [...correos, ...itemPersona.correos];
          }
        });
      }

      setitem({
        ...item,
        correos: correos,
        tipo: estadoRequest === "DISTRIBUCION" ? "Distribuciones" : "Pedido",
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    setitem({
      option: "reenviarCorreo",
      tipo: "",
      id: row.distri_Id,
      // rucempresa: row.//disEmpresa,
      correos: [],
    });
    setopen(false);

    setcargando(false);
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  }; // eslint-disable-next-line

  const handleCrear = async () => {
    if (false) {
    }
    try {
      setcargando(true);

      return mostrarAlerta("Sin servicio de correos.", "error");
      // const res = await clienteAxios.post("/correos", item);
      // setcargando(false);
      // mostrarAlerta(res.data.msg, "success");
      // return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                style={{ cursor: "-webkit-grab" }}
              >
                <Typography
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {estadoRequest === "DISTRIBUCION" ? "Distribuciones" : "Pedido"}
                  # {row.distri_Id}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box></Box>

                {/* <FormControlLabel
                control={
                  <Switch
                    checked={estado === "ACTIVO" ? true : false}
                    onChange={(e) => {
                       
                      setitem({
                        ...item,
                        estado: e.target.checked ? "ACTIVO" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                borderRadius="0px 50px 50px 0px"
                p={1}
              >
                <FormControl className={clases.formD}>
                  <Typography variant="subtitle1" color="primary">
                    Persona:{" "}
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      component="span"
                    >
                      {row.distri_DocuCliente} {row.distri_NombreCliente}
                    </Typography>
                  </Typography>
                </FormControl>
                <FormControl className={clases.formD}>
                  <Typography variant="subtitle1" color="primary">
                    Asunto:{" "}
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      component="span"
                    >
                      {row.distri_Observacion}
                    </Typography>
                  </Typography>
                </FormControl>
                <FormControl className={clases.formD}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" color="primary">
                      Correos:
                    </Typography>
                    <IconButton
                      aria-label=""
                      size="small"
                      onClick={() => {
                        const newCorreos = item.correos;
                        newCorreos.push("");
                        setitem({
                          ...item,
                          correos: newCorreos,
                        });
                      }}
                    >
                      <AddCircle fontSize="small" color="secondary" />
                    </IconButton>
                  </Box>
                </FormControl>
                {correos.map((correo, index) => {
                  return (
                    <TextField
                      className={clases.correo}
                      id=""
                      value={correos[index]}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          correos: correos.map((correoItem, indexItem) => {
                            if (indexItem === index) {
                              return e.target.value;
                            } else {
                              return correoItem;
                            }
                          }),
                        });
                      }}
                      type="email"
                      label={`Correo #${index + 1}`}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton
                              aria-label=""
                              onClick={() => {
                                setitem({
                                  ...item,
                                  correos: correos.filter((item, indexItem) => {
                                    return indexItem !== index;
                                  }),
                                });
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  );
                })}
              </Box>

              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={submit() || cargando}
                    onClick={() => {
                      handleCrear();
                    }}
                  >
                    Enviar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
