import React, { memo, useContext } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  FormatListNumbered,
  PictureAsPdf,
  QuestionAnswer,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import ImagenCelda from "../../../components/ImagenCelda";
import clienteAxios from "../../../../config/axios";
import CollapseCell from "../../../components/CollapseCell";
import DocumentoCelda from "../../../components/DocumentoCelda";
import { trimPropsItem } from "../../../../config/const";
import Respuestas from "./Respuestas/Respuestas";
import CeldaUser from "../../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Avisos");
  const [openRespuestas, setopenRespuestas] = useState(false);
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // CELDA CONTRAIDA
  const [celComprimida, setcelComprimida] = useState({});
  const [openDialogLeidos, setopenDialogLeidos] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "aviso",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        aviUser: usuario.usuario,
        conEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "aviso",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          aviUser: usuario.usuario + "/+/" + row[campoExport],
          conEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {openDialogLeidos ? (
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xl"
          open={openDialogLeidos}
          onClose={() => setopenDialogLeidos(false)}
        >
          <DialogTitle> Leidos</DialogTitle>
          <DialogContent dividers>
            {row.aviLeidos.map((item) => {
              return `${item.nombres}: ${item.fecha} - ${item.rol.join(" - ")}`;
            })}
          </DialogContent>
        </Dialog>
      ) : null}

      <Respuestas
        open={openRespuestas}
        setopen={setopenRespuestas}
        row={row}
        socket={socket}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.aviUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "Eliminar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 3,
              ocultar: row.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/comunicadoindividual?aviId=${row.aviId}&rucempresa=${row.aviEmpresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Leidos",
              texto: row.aviLeidos.length === 0 ? false : row.aviLeidos.length,
              funcion: () => {
                setopenDialogLeidos(true);
              },
              disabled: false,
              Icon: FormatListNumbered,
              color: "primary",
              id: 4,
              ocultar: false,
              tipo: "icono",
              estado: row.aviEstado === "ACTIVO" || row.aviEstado === "ACTIVA",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Respuestas",
              texto: row.aviDirigido.filter((dirigido) => dirigido.respuesta)
                .length,
              funcion: () => {
                setopenRespuestas(true);
              },
              disabled: false,
              Icon: QuestionAnswer,
              color: "secondary",
              id: 4,
              ocultar: false,
              tipo: "icono",
              estado: "",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          {/* <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"conNombreCliente"}
          /> */}
          {/* <ImagenCelda
            propiedad={row.conFotosCliente}
            array={true}
            claseCelda={claseCelda}
          /> */}
          {/* <DocumentoCelda
            propiedad={row.conDocumento}
            claseCelda={claseCelda}
          /> */}
          <ImagenCelda
            propiedad={row.aviFotos}
            array={true}
            claseCelda={claseCelda}
          />
          <DocumentoCelda
            propiedad={row.aviDocumento}
            claseCelda={claseCelda}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {row.aviAsunto}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {row.aviOpcion}
          </TableCell>
          <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={row}
            propiedad={"aviDetalle"}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(row.aviFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>

          <CeldaUser user={row.aviUser} claseCelda={claseCelda} />
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
