export const columns = [
  {
    id: "ruc",
    label: "Nombre",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "ruc",
    align: "center",
    ocultar: true,
  },
  {
    id: "fecha_inicio",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fecha_inicio",
    align: "center",
    ocultar: true,
  },
  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "planiTipo",
    label: "Tipo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planiTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "planiNombreActividad",
    label: "Nombre Actividad",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "planiNombreActividad",
    align: "center",
    ocultar: true,
  },

  {
    id: "fecha_inicio",
    label: "F. Inicio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fecha_inicio",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  acti_id: 0,
  id_planificacion: 0,
  acti_estado: "ACTIVA",
  acti_prioridad: "BAJA",
  acti_documento: "",
  acti_fotos: [],
  acti_observacion: "Este es la observacion de la actividad",
  acti_requerimiento: {
    qr: "SI",
    foto: "NO",
    video: "NO",
    texto: "SI",
  },
  acti_user: "",
  acti_empresa: "",
  acti_FecReg: "",
  acti_FecUpd: "",
  id_cliente: 0,
  ruc: "",
  razon_social: "",
  ubicacion: "",
  puesto: "",
  planiTipo: "",
  planiNombreActividad: "",
  planiPerfil: "",
};
