import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";

import { trimPropsItem } from "../../../../config/const";
import AuthContext from "../../../../Context/Auth/AuthContext";

export default function Respuestas({ open, setopen, row }) {
  const { usuario, socket } = useContext(AuthContext);
  const handleClose = () => {
    setopen(false);
  };
  const [respuesta, setrespuesta] = React.useState("");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
      const myRes = row.infGuardias.find(
        (dirigido) => Number(dirigido.id) === usuario.id
      )?.respuesta;
      setrespuesta(myRes ? myRes : "");
    }
    // eslint-disable-next-line
  }, [open]);
  const handleSubmit = (e) => {
    e.preventDefault();

    socket.emit(
      "client:actualizarData",
      trimPropsItem({
        infId: row.infId, //idRegistro
        tabla: "respuestainforme", //default
        rucempresa: usuario.rucempresa, //login
        rol: usuario.rol, //login
        infIdPersona: usuario.id, //login
        respuestaTexto: respuesta, //input
        docnumero: usuario.nombre, //login
        nombres: usuario.nombre, //login
      })
    );
    setopen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Box
        sx={{ padding: ".5rem" }}
        component={"form"}
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <DialogTitle id="scroll-dialog-title">Respuestas</DialogTitle>

        <TextField
          fullWidth
          label="Pon aqui tu respuesta"
          variant="outlined"
          value={respuesta}
          onChange={(e) => setrespuesta(e.target.value)}
        />
        <DialogContent dividers sx={{ marginTop: "1rem" }}>
          {row.infGuardias.map((item, index) => {
            if (!item.respuesta) {
              return null;
            }
            return (
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
                mb={2}
              >
                <Typography variant="body1" color="primary">
                  {index + 1} - {item.docnumero} {item.nombres}
                </Typography>
                <Typography variant="body2" color="secondary">
                  {item.respuesta} -
                  {item.fecha
                    ? ` ${new Date(item.fecha).toLocaleString("es-ES", {
                        weekday: "short",
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour: "2-digit",
                      })}`
                    : " No hay fecha"}
                </Typography>
              </DialogContentText>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button fullWidth type="submit" color="primary" variant="contained">
            GUARDAR RESPUESTA
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
