import React from "react";
import VideosComponent from "../../../../components/Videos";
import { Box } from "@mui/material";

const Videos = (props) => {
  const { trabajoActivo } = props;

  return (
    <Box margin={"0 auto"} display={"flex"} justifyContent={"center"}>
      <VideosComponent videos={trabajoActivo.videos} />
    </Box>
  );
};

export default Videos;
