import React, { memo, useContext } from "react";

import {
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { trimPropsItem } from "../../../config/const";
import { objectDefault } from "../data";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const { setopen, socket } = props;
  const claseCelda = `${classes.celda}`;

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(objectDefault);
  //onChange
 
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.hora_final.trim() === "" ||
      item.hora_final.trim() === "" ||
      item.tipo.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "horario",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          // bodEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  const obtenerHoraLimite = (hora, horaLimite) => {
    // Convertir las horas a minutos
    const horaEnMinutos = hora.split(":")[0] * 60 + hora.split(":")[1];
    const horaLimiteEnMinutos =
      horaLimite.split(":")[0] * 60 + horaLimite.split(":")[1];

    // Comprobar si la hora es posterior a la hora límite
    if (Number(horaEnMinutos) > Number(horaLimiteEnMinutos)) {
      return horaLimite;
    } else {
      return hora;
    }
  };
  const obtenerHoraLimiteinv = (hora, horaLimite) => {
    // Convertir las horas a minutos
    const horaEnMinutos = hora.split(":")[0] * 60 + hora.split(":")[1];
    const horaLimiteEnMinutos =
      horaLimite.split(":")[0] * 60 + horaLimite.split(":")[1];

    // Comprobar si la hora es posterior a la hora límite
    if (Number(horaEnMinutos) < Number(horaLimiteEnMinutos)) {
      return horaLimite;
    } else {
      return hora;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          sx={{ paddingLeft: "5px" }}
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.codigo}
                name="codigo"
                onChange={(e) =>
                  setitem({
                    ...item,
                    codigo:
                      (item.tipo === "LIBRE"
                        ? "FF"
                        : item.tipo === "24"
                        ? "24"
                        : item.tipo === "DIA"
                        ? "D"
                        : "N") +
                      e.target.value.substring(
                        item.tipo === "LIBRE" || item.tipo === "24" ? 2 : 1,
                        4
                      ),
                  })
                }
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Typography
                        color={item.codigo.length === 4 ? "error" : "secondary"}
                      >
                        {item.codigo.length}/4
                      </Typography>
                    </React.Fragment>
                  ),
                }}
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                value={item.tipo}
                name="tipo"
                onChange={(e) =>
                  setitem({
                    ...item,
                    tipo: e.target.value,
                    hora_inicio:
                      e.target.value === "LIBRE" || e.target.value === "24"
                        ? "00:00"
                        : e.target.value === "DIA"
                        ? "06:00"
                        : "18:00",
                    hora_final:
                      e.target.value === "LIBRE" || e.target.value === "24"
                        ? "00:00"
                        : e.target.value === "DIA"
                        ? "18:00"
                        : "06:00",
                    codigo:
                      e.target.value === "LIBRE"
                        ? "FF"
                        : e.target.value === "24"
                        ? "24"
                        : e.target.value === "DIA"
                        ? "D"
                        : "N",
                  })
                }
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="" disabled>
                  Seleccione.
                </MenuItem>
                {["NOCHE", "DIA", "LIBRE", "24"].map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type="time"
                value={item.hora_inicio}
                name="hora_inicio"
                onChange={(e) => {
                  setitem({
                    ...item,
                    hora_inicio:
                      item.tipo === "DIA"
                        ? obtenerHoraLimite(item.hora_final, e.target.value)
                        : obtenerHoraLimiteinv(item.hora_final, e.target.value),
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type="time"
                value={item.hora_final}
                name="hora_final"
                onChange={(e) => {
                  setitem({
                    ...item,
                    hora_final:
                      item.tipo === "DIA"
                        ? obtenerHoraLimiteinv(item.hora_inicio, e.target.value)
                        : obtenerHoraLimite(item.hora_inicio, e.target.value),
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                type="color"
                value={item.color}
                name="color"
                onChange={(e) => {
                  setitem({
                    ...item,
                    color: e.target.value,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
