import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import ImagenCelda from "../../../../components/ImagenCelda";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  EventNote,
  ExpandLess,
  ExpandMore,
  PictureAsPdf,
  Restore,
  WorkOutline,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import clienteAxios from "../../../../../config/axios";
import CeldaUser from "../../../../components/CeldaUser";
import { useSimpleTable } from "../../../../hooks/useSimpleTable";
import TablaCabecera from "../../../../components/TablaCabecera";
import RowPersona from "./RowPersona";
import ModalTrabajo from "../../ModalTrabajo";
import DialogFechas from "../../DialogFechas";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const {
    agregandoPersona,
    setagregandoPersona,
    pagePersona,
    // setPagePersona,
    rowsPerPagePersona,
    // setRowsPerPagePersona,
    // handleChangePagePersona,
    // handleChangeRowsPerPagePersona,
  } = useSimpleTable("Persona");
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [columnasGeneradas, setcolumnasGeneradas] = useState(null);
  const [personas, setPersonas] = useState(null);
  const [expandir, setexpandir] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        acti_user: usuario.usuario,
        acti_empresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        acti_FecReg: rowHijo.acti_FecReg,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          acti_user: usuario.usuario + "/+/" + rowHijo[campoExport],
          acti_empresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  const [horarioActivo, sethorarioActivo] = useState(null);
  const getHorarioActivo = async (id) => {
    const res = await clienteAxios(`/horarios/${id}`);
    sethorarioActivo(res.data);
  };
  const [openDialog, setopenDialog] = useState(false);
  const [openModalTrabajos, setopenModalTrabajos] = useState(false);
  return (
    <React.Fragment>
      <ModalTrabajo
        setopen={setopenModalTrabajos}
        open={openModalTrabajos}
        item={rowHijo}
      />
      {openDialog ? (
        <DialogFechas
          setopen={setopenDialog}
          open={openDialog}
          rowHijo={rowHijo}
          rowPadre={rowPadre}
        />
      ) : null}
      {horarioActivo ? (
        <Dialog
          maxWidth="xl"
          open={horarioActivo}
          onClose={() => sethorarioActivo(null)}
        >
          <DialogTitle
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            {horarioActivo.codigo} - {horarioActivo.tipo}
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              background: horarioActivo.color,
              color: (theme) => {
                return theme.palette.getContrastText(horarioActivo.color);
              },
            }}
          >
            Hora : {horarioActivo.hora_inicio} - {horarioActivo.hora_final}
          </DialogContent>
        </Dialog>
      ) : null}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: false,
              // disabled: usuario.usuario !== rowHijo.acti_user.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/renuncia_individual?perId=${rowHijo.perId}&rucempresa=${rowHijo.acti_empresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowHijo.eliminado || true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver eventos",
              texto: rowHijo.teventos,
              funcion: () => {
                setopenDialog(true);
              },
              disabled: false,
              Icon: EventNote,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Trabajos",
              texto: rowHijo.trabajos.length,
              funcion: () => {
                setopenModalTrabajos(true);
              },
              disabled: false,
              Icon: WorkOutline,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.planiTipo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.planiNombreActividad}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.fecha_inicio).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
