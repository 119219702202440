import React from "react";

// MATERIAL
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableContainer,
  // TableFooter,
  TableHead,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  CalendarToday,
  Cancel,
  ChangeCircleOutlined,
  DateRange,
  DeleteForever,
  Exposure,
  Refresh,
  Work,
} from "@mui/icons-material";
import TablaCabecera from "../../../../components/TablaCabecera";
import { columnasPersona } from "../../data";
import RowAgregarPersona from "./Persona/RowAgregarPersona";
import clienteAxios from "../../../../../config/axios";
import RowPersona from "./Persona/RowPersona";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { FechaExacta } from "../../../../../config/const";
const HorariosTab = ({
  agregandoPersona,
  anio,
  cargando,
  columnasGeneradas,
  diasSeleccionados,
  diasSelect,
  getHorarioActivo,
  guardiasTotales,

  horarioActivo,
  datoOperativo,
  itemPadre,
  mes,
  mostrarAlerta,
  pagePersona,
  personasData,
  rowsPerPagePersona,
  setanio,
  setcargando,
  setdiasSeleccionados,
  setmes,
  setpersonasData,
  socket,
  getPersonasByMonthYear,
  setdiasSelect,
  setagregandoPersona,
  horarios,
  estadoPersonas,
  setopenAsiAct,
}) => {
  const [openConfirmDialogActividad, setopenConfirmDialogActividad] = useState(
    false
  );
  const [minMax, setminMax] = useState({ min: 1, max: 15 });
  const [alterParams, setalterParams] = useState(false);
  const [horario, sethorario] = useState(null);
  const theme = useTheme();
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  return (
    <Paper>
      {/* <ToolBarTable
    count={0}
    rowsPerPage={rowsPerPagePersona}
    page={pagePersona}
    handleChangePage={handleChangePagePersona}
    handleChangeRowsPerPage={handleChangeRowsPerPagePersona}
    ocultarPaginacion={false}
    simplePagination={true}
    botonesIzquierdos={[
      {
        tooltip: "Agregar",
        texto: "Agregar",
        funcion: () => {
          
          setagregandoPersona(true);
          setexpandir(true);
        },
        disabled: false,
        Icon: AddCircle,
        color: "primary",
        id: 1,
        ocultar: false,
        tipo: "boton",
        variante: "contained",
        size: "medium",
        sx: {},
      },
    ]}
    botonesDerechos={[]}
  /> */}
      <TableContainer>
        <Box
          sx={{
            paddingTop: ".3rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tooltip title="Asignar nuevos horarios">
            <IconButton
              disabled={!horario || Object.keys(diasSeleccionados).length === 0}
              color="secondary"
              onClick={async () => {
                try {
                  setcargando(true);
                  const horarioActivo = await clienteAxios(
                    `/horarios/${horario}`
                  );

                  const dosPersonasTurnoNoche = columnasGeneradas.some(
                    (dia) => {
                      let diasPersona = [];
                      personasData.forEach(({ horarios }) => {
                        horarios.forEach((horario) => {
                          if (
                            horario.fecha_inicio ===
                            new Date(dia).toISOString().substring(0, 10)
                          ) {
                            const seleccionado = Object.keys(
                              diasSeleccionados
                            ).some((item) => {
                              return Number(item) === Number(horario.id);
                            });
                            if (seleccionado) {
                              diasPersona.push(
                                horarioActivo.data.codigo.substring(0, 1)
                              );
                            } else {
                              diasPersona.push(horario.codigo.substring(0, 1));
                            }
                          }
                        });
                      });
                      const res = diasPersona.filter(
                        (diaPersona) => diaPersona === "N"
                      );
                      return res.length > 1;
                    }
                  );
                  if (dosPersonasTurnoNoche) {
                    return mostrarAlerta(
                      "No se pueden asignar 2 personas o mas de turno NOCHE en el mismo puesto",
                      "error"
                    );
                  }
                  const res = await clienteAxios.put(
                    "horarios_jornada_laboral/0",
                    {
                      ids_jornada_laboral: diasSeleccionados,
                      new_id_horario: horario,
                      month: mes,
                      year: anio,
                      id_dOperativo: datoOperativo.id,
                      idpersonas: personasData.map((item) => item.id_persona),
                    }
                  );
                  setpersonasData(res.data.data);
                  setcargando(false);
                  setdiasSeleccionados({});
                } catch (error) {
                  console.log({ error });
                  setcargando(false);
                  mostrarAlerta(error?.response?.data?.msg, "error");
                }
              }}
            >
              <ChangeCircleOutlined />
            </IconButton>
          </Tooltip>
          <TextField
            disabled={cargando}
            value={horario || ""}
            onChange={(e) => {
              sethorario(e.target.value);
            }}
            label="Horario"
            size="small"
            variant="outlined"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            InputProps={{
              startAdornment: <InputAdornment></InputAdornment>,
            }}
          >
            <MenuItem value={""}>Seleccione un horario </MenuItem>
            {horarios.map(
              ({
                codigo,
                color,
                fec_reg,
                hora_final,
                hora_inicio,
                id,
                tipo,
                user,
              }) => {
                return (
                  <MenuItem key={id} value={id} sx={{ color }}>
                    {`${tipo}: ${codigo} ${hora_inicio} - ${hora_final}`}
                  </MenuItem>
                );
              }
            )}
          </TextField>
          <TextField
            disabled={cargando}
            value={mes}
            onChange={(e) => {
              setmes(e.target.value);
              getPersonasByMonthYear(anio, e.target.value);
            }}
            label="Mes"
            size="small"
            variant="outlined"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            InputProps={{
              startAdornment: <InputAdornment></InputAdornment>,
            }}
          >
            <MenuItem value={1}>Enero </MenuItem>
            <MenuItem value={2}>Febrero </MenuItem>
            <MenuItem value={3}>Marzo </MenuItem>
            <MenuItem value={4}>Abril </MenuItem>
            <MenuItem value={5}>Mayo</MenuItem>
            <MenuItem value={6}>Junio </MenuItem>
            <MenuItem value={7}>Julio</MenuItem>
            <MenuItem value={8}>Agosto </MenuItem>
            <MenuItem value={9}>Septiembre </MenuItem>
            <MenuItem value={10}>Octubre</MenuItem>
            <MenuItem value={11}>Noviembre </MenuItem>
            <MenuItem value={12}>Diciembre </MenuItem>
          </TextField>
          <TextField
            disabled={cargando}
            value={anio}
            onChange={(e) => {
              setanio(e.target.value);
              getPersonasByMonthYear(e.target.value, mes);
              setminMax({ min: 1, max: 15 });
            }}
            label="Año"
            size="small"
            variant="outlined"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value={new Date().getFullYear() - 1}>
              {new Date().getFullYear() - 1}
            </MenuItem>
            <MenuItem value={new Date().getFullYear()}>
              {new Date().getFullYear()}
            </MenuItem>
            <MenuItem value={new Date().getFullYear() + 1}>
              {new Date().getFullYear() + 1}
            </MenuItem>
          </TextField>
          {alterParams ? (
            <>
              <TextField
                size="small"
                type="number"
                variant="outlined"
                label="Min"
                value={minMax.min}
                onChange={(e) => {
                  const diasEnMes = new Date(anio, mes, 0).getDate();
                  setminMax((prev) => ({
                    ...prev,
                    min: Math.max(
                      1,
                      Math.min(diasEnMes, e.target.valueAsNumber)
                    ),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <Tooltip title="Quitar selección">
                        <IconButton
                          disabled={cargando}
                          onClick={() => {
                            setalterParams(!alterParams);
                          }}
                          color={alterParams ? "error" : "secondary"}
                        >
                          <Exposure />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                size="small"
                type="number"
                variant="outlined"
                label="Max"
                value={minMax.max}
                onChange={(e) => {
                  // El día 0 representa el último día del mes anterior
                  const diasEnMes = new Date(anio, mes, 0).getDate();
                  setminMax((prev) => ({
                    ...prev,
                    max: Math.max(
                      1,
                      Math.min(diasEnMes, e.target.valueAsNumber)
                    ),
                  }));
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <Tooltip title="Quitar selección">
                        <IconButton
                          disabled={cargando}
                          onClick={() => {
                            setalterParams(!alterParams);
                          }}
                          color={alterParams ? "error" : "secondary"}
                        >
                          <Exposure />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          ) : (
            <TextField
              size="small"
              variant="outlined"
              label="Día"
              value={diasSelect}
              onChange={(e) => {
                setdiasSelect(e.target.value);
              }}
              select
              SelectProps={{
                displayEmpty: true,
                multiple: true,
                renderValue: (selected) => {
                  return selected
                    .map((value, index) => {
                      return value;
                    })
                    .join(", ");
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Tooltip title="Vaciar parametros">
                      <IconButton
                        disabled={cargando}
                        onClick={() => {
                          setdiasSelect([]);
                        }}
                        color="error"
                      >
                        <DateRange />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Quitar selección">
                      <IconButton
                        disabled={cargando}
                        onClick={() => {
                          setdiasSeleccionados({});
                        }}
                        color="error"
                      >
                        <CalendarToday />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Quitar selección">
                      <IconButton
                        disabled={cargando}
                        onClick={() => {
                          setalterParams(!alterParams);
                        }}
                        color={alterParams ? "error" : "secondary"}
                      >
                        <Exposure />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem disabled value="">
                Seleccione.
              </MenuItem>
              {[
                "LUNES",
                "MARTES",
                "MIÉRCOLES",
                "JUEVES",
                "VIERNES",
                "SÁBADO",
                "DOMINGO",
              ].map((item) => {
                return (
                  <MenuItem
                    style={getStylesMenuItems(item, diasSelect, theme)}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
          <Tooltip title="Actualizar">
            <IconButton
              color="primary"
              onClick={() => {
                getPersonasByMonthYear(anio, mes);
                setminMax({ min: 1, max: 15 });
              }}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip title="Asignar actividad">
            <IconButton
              color={
                Object.keys(diasSeleccionados).length === 0
                  ? "error"
                  : "secondary"
              }
              disabled={Object.keys(diasSeleccionados).length === 0}
              onClick={() => {
                let dias = [];
                personasData.forEach((persona) => {
                  const res = persona.horarios.filter(
                    (horarioHijo) => diasSeleccionados[horarioHijo.id]
                  );
                  dias = [...dias, ...res];
                });
                const diasNoAptos = dias.some((dia) => {
                  return (
                    dia.codigo === "FF" ||
                    dia.estado === "PERMISO" ||
                    Date.parse(dia.fecha_inicio) < Date.parse(FechaExacta)
                  );
                });
                if (diasNoAptos) {
                  return setopenConfirmDialogActividad(true);
                }

                setopenAsiAct(true);
              }}
            >
              <Work />
            </IconButton>
          </Tooltip>
        </Box>
        <ConfirmacionDialog
          open={openConfirmDialogActividad}
          setopen={setopenConfirmDialogActividad}
          categoria="warning"
          titulo={`Algunos dias seleccionados no son seleccionables para actividades, ¿desea quitarlos de la selección?`}
          botones={[
            {
              tooltip: "Se filtraran los registros",
              texto: "SI",
              funcion: () => {
                let dias = [];
                personasData.forEach((persona) => {
                  const res = persona.horarios.filter(
                    (horarioHijo) => diasSeleccionados[horarioHijo.id]
                  );
                  dias = [...dias, ...res];
                });
                const diasAptos = dias
                  .filter((dia) => {
                    return (
                      dia.codigo !== "FF" &&
                      dia.estado !== "PERMISO" &&
                      Date.parse(dia.fecha_inicio) >= Date.parse(FechaExacta)
                    );
                  })
                  .map((dia) => [dia.id, dia.id]);
                setdiasSeleccionados(Object.fromEntries(diasAptos));
                setopenConfirmDialogActividad(false);
              },
              disabled: false,

              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Cancelar",
              texto: "NO",
              funcion: () => {
                setopenConfirmDialogActividad(false);
              },
              disabled: false,
              Icon: Cancel,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TablaCabecera
              columns={[
                ...columnasPersona,
                ...columnasGeneradas.map((item, index) => {
                  return {
                    id: index + 1,
                    label: new Date(item).toLocaleString("es-ES", {
                      weekday: "short",
                      day: "2-digit",
                    }),
                    dia: true,
                    minWidth: 50,
                    tipo: "string",
                    orden: 1,
                    propiedad: index + 1,
                    align: "center",
                    ocultar: true,
                  };
                }),
              ]}
              habilitarOrdenar={false}
            />
          </TableHead>

          <TableBody component={"div"}>
            {agregandoPersona ? (
              <RowAgregarPersona
                personasData={personasData}
                setpersonasData={setpersonasData}
                itemPadre={datoOperativo}
                setagregandoPersona={setagregandoPersona}
                socket={socket}
                mes={mes}
                anio={anio}
                estadoPersonas={estadoPersonas}
              />
            ) : null}
            {personasData
              .slice(
                pagePersona * rowsPerPagePersona,
                pagePersona * rowsPerPagePersona + rowsPerPagePersona
              )
              .map((itemPersona, index) => {
                return (
                  <RowPersona
                    minMax={minMax}
                    alterParams={alterParams}
                    setpersonasData={setpersonasData}
                    itemPersona={itemPersona}
                    itemPadre={datoOperativo}
                    itemPadrePadre={itemPadre}
                    index={index}
                    key={itemPersona.id}
                    socket={socket}
                    guardiasTotales={guardiasTotales}
                    horario={horario}
                    setdiasSeleccionados={setdiasSeleccionados}
                    diasSeleccionados={diasSeleccionados}
                    diasSelect={diasSelect}
                    mes={mes}
                    anio={anio}
                    getHorarioActivo={getHorarioActivo}
                    horarioActivo={horarioActivo}
                    estadoPersonas={estadoPersonas}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HorariosTab;
