import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  Box,
  Collapse,
  IconButton,
  ImageList,
  ImageListItem,
  Tooltip,
} from "@mui/material";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import { trimPropsItem } from "../../../../../config/const";
import { useState } from "react";
import CampoDoc from "../../../../components/CampoDoc";
import { useModalStyle } from "../../../../styles/styleModal";
import useMultimediasUpload from "../../../../hooks/useMultimediasUpload";

import Dropzone from "../../../../components/Dropzone";
import { Download, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useMemo } from "react";
export default function Respuestas({ open, setopen, row, socket }) {
  const { usuario } = useContext(AuthContext);
  const clases = useModalStyle();
  const defaultF = () => {
    setdocumento1("");
    setdocumento2("");
    setimagenes([]);
  };
  const handleClose = () => {
    setopen(false);
    defaultF();
  };
  const [respuesta, setrespuesta] = React.useState("");
  const [documento1, setdocumento1] = useState("");
  const [documento2, setdocumento2] = useState("");
  const [fotosItem, setfotosItem] = useState(null);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const [expand, setExpand] = useState(false);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }

      const myRes = row.aviDirigido.find(
        (dirigido) => Number(dirigido.id) === usuario.id
      );
      setrespuesta(myRes?.respuesta ? myRes.respuesta : "");
      setdocumento1(myRes?.documento1 ? myRes.documento1 : "");
      setdocumento2(myRes?.documento2 ? myRes.documento2 : "");
      setfotosItem(myRes?.fotos ? myRes.fotos : []);
    }
    // eslint-disable-next-line
  }, [open]);
  const deshabilitado = useMemo(
    () =>
      !row.aviDirigido.some((dirigido) => {
        return Number(dirigido.id) === usuario.id;
      }),
    // eslint-disable-next-line
    [row]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();

    const imagenesSubidas = await subirImagenes(imagenes, fotosItem);
    const aviDirigido = row.aviDirigido.map((dirigido) => {
      if (Number(dirigido.id) === usuario.id) {
        return {
          ...dirigido,
          respuesta,
          documento1,
          documento2,
          fotos: imagenesSubidas,
          fecha: new Date(),
        };
      } else {
        return dirigido;
      }
    });

    socket.current.emit(
      "client:actualizarData",
      trimPropsItem({
        ...row,
        aviDirigido,
        //params
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla: "aviso",
      })
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ width: "100%" }}
          component={"form"}
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <DialogTitle id="scroll-dialog-title" className={clases.formAllW}>
            <Box
              sx={{ width: "100%" }}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              Respuestas:
              {!deshabilitado && (
                <Button onClick={() => setExpand(!expand)}>
                  Mi respuesta: {expand ? <ExpandLess /> : <ExpandMore />}
                </Button>
              )}
            </Box>
          </DialogTitle>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxHeight: "60vh",
              overflow: "scroll",
              overflowX: "hidden",
              alignItems: "end",
            }}
          >
            <Collapse in={expand && !deshabilitado} sx={{ overflow: "hidden" }}>
              <Box className={clases.container} overflow={"hidden"}>
                <CampoDoc
                  disabled={deshabilitado}
                  label="Documento 1"
                  setcargando={() => {}}
                  propiedad={"documento1"}
                  item={{ documento1 }}
                  setitem={(e) => {
                    setdocumento1(e.documento1);
                  }}
                  celda={false}
                />
                <CampoDoc
                  disabled={deshabilitado}
                  label="Documento 2"
                  setcargando={() => {}}
                  propiedad={"documento2"}
                  item={{ documento2 }}
                  setitem={(e) => {
                    setdocumento2(e.documento2);
                  }}
                  celda={false}
                />
                <TextField
                  fullWidth
                  label="Pon aqui tu respuesta"
                  variant="outlined"
                  value={respuesta}
                  onChange={(e) => setrespuesta(e.target.value)}
                  disabled={deshabilitado}
                />
                {fotosItem === null ? null : (
                  <Dropzone
                    imagenes={imagenes}
                    setimagenes={(e) => {
                      setmodificoImagenes(true);
                      if (deshabilitado) {
                        return;
                      }
                      setimagenes(e);
                    }}
                    fotosItem={fotosItem}
                  />
                )}
              </Box>
            </Collapse>
            <DialogContent sx={{ p: 1 }} dividers>
              {row.aviDirigido.map((item, index) => {
                if (!item.respuesta) {
                  return null;
                }
                return (
                  <>
                    <Box
                      display={"fkex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        mb={2}
                      >
                        <Typography variant="body1" color="primary">
                          {index + 1} - {item.docnumero} {item.nombres}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                          {item.respuesta} -
                          {item.fecha
                            ? ` ${new Date(item.fecha).toLocaleString("es-ES", {
                                weekday: "short",
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour: "2-digit",
                              })}`
                            : " No hay fecha"}
                        </Typography>
                      </DialogContentText>
                      <Box>
                        <Tooltip title="Documento 1">
                          <IconButton
                            disabled={!item.documento1}
                            color="secondary"
                            onClick={() => window.open(item.documento1)}
                          >
                            <Download fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Documento 2">
                          <IconButton
                            disabled={!item.documento2}
                            color="secondary"
                            onClick={() => window.open(item.documento2)}
                          >
                            <Download fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    <ImageList
                      sx={{ width: 500, maxHeight: 180 }}
                      cols={3}
                      rowHeight={180}
                    >
                      {item.fotos?.map((item) => (
                        <ImageListItem key={item.url}>
                          <img
                            srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </>
                );
              })}
            </DialogContent>
          </Box>
          {!deshabilitado && (
            <DialogActions sx={{ width: "95%" }}>
              <Button fullWidth type="submit" variant="contained">
                GUARDAR RESPUESTA
              </Button>
            </DialogActions>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
