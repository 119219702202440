import React, { useContext, useRef, useState } from "react";
import { useSimpleTable } from "../../../../../hooks/useSimpleTable";
import Cargando from "../../../../../components/Cargando";
import { useEffect } from "react";
import clienteAxios from "../../../../../../config/axios";
import ToolBarTable from "../../../../../components/ToolBarTable";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import TablaCabecera from "../../../../../components/TablaCabecera";
import { AddCircle, ContactEmergency, Person4 } from "@mui/icons-material";
import RowNuevo from "./Planificacion/RowNuevo";
import { columns, columnsSupervisor } from "./Planificacion/data";
import Row from "./Planificacion/Row";
import AlertaContext from "../../../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
import { useCrud } from "../../../../../hooks/useCrud";
import { io } from "socket.io-client";
import { socketUrl } from "../../../../../../config/const";
import Botones from "../../../../../components/Botones";

const ActividadesTab = ({
  datoOperativo,
  // socketProp,
  itemPadre,
  asignarActividad,
  estadoPersonas,
}) => {
  const {
    agregandoPlanificacion,
    setagregandoPlanificacion,
    pagePlanificacion,
    // setPagePlanificacion,
    rowsPerPagePlanificacion,
    // setRowsPerPagePlanificacion,
    handleChangePagePlanificacion,
    handleChangeRowsPerPagePlanificacion,
  } = useSimpleTable("Planificacion");
  const [planificacionData, setplanificacionData] = useState(null);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const [estadoRequest, setestadoRequest] = useState("SUPERVISOR");
  const [campoExport] = useState("id_planificacion");
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(
    planificacionData,
    setplanificacionData,
    campoExport,
    "planiUser",
    usuario
  );
  const getPLanificacionData = async (estado) => {
    const res = await clienteAxios.get(
      `planificacion_actividades/listarByOption?option=DOperativo&valor=${datoOperativo.id}&perfil=${estado}`
    );
    setplanificacionData(res.data.data);
  };

  const [tiposData, settiposData] = useState(null);
  // OBTIENE LA DATA AL CARGAR EL COMPONENTE
  useEffect(() => {
    //eslint-disable-next-line
    const obteneNovedades = async () => {
      try {
        const resNovedades = await clienteAxios.get(
          "/novedades/filtro/0?search&origen=ACTIVIDAD"
        );

        settiposData(resNovedades.data.data);
      } catch (error) {
        settiposData([]);
      }
    };
    if (!tiposData) {
      obteneNovedades();
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!planificacionData) {
      getPLanificacionData(
        estadoPersonas === "GUARDIA" ? "GUARDIA" : "SUPERVISOR"
      );
      setestadoRequest(estadoPersonas === "GUARDIA" ? "GUARDIA" : "SUPERVISOR");
    }
    // eslint-disable-next-line
  }, []);
  const [tabla] = useState("planificacionactividad");

  // ITEM DEL SOCKET SE UTILIZA COMO REFERENCIA
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  // REFERENCIA DEL SOCKET
  const socket = useRef();
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // CUANDO CAMBIA EL OBJETO DEL SOCKET SE ESCUCHA AQUI Y SE DECIDE QUE HACER CON EL
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!planificacionData) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    if (datoOperativo.id !== item.planiIdDOperativo) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  //si no hay usuarios //rows no cargar el componente
  if (!planificacionData) {
    return <Cargando titulo={"Cargando Planifición"} />;
  }
  if (!tiposData) {
    return <Cargando titulo="Cargando" />;
  }
  return (
    <>
      <Botones
        botones={[
          {
            tooltip: "GUARDIA",
            texto: "Guardia",
            funcion: () => {
              getPLanificacionData("GUARDIA");
              setestadoRequest("GUARDIA");
            },
            disabled: false,
            Icon: Person4,
            color: estadoRequest === "GUARDIA" ? "success" : "inherit",
            id: 1,
            ocultar: estadoPersonas !== "GUARDIA",
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {
              borderRadius: "10px 10px 0px 0px",
            },
            disableElevation: true,
          },
          {
            tooltip: "SUPERVISOR",
            texto: "Supervisor",
            funcion: () => {
              getPLanificacionData("SUPERVISOR");
              setestadoRequest("SUPERVISOR");
            },
            disabled: false,
            Icon: ContactEmergency,
            color: estadoRequest === "SUPERVISOR" ? "success" : "inherit",
            id: 1,
            ocultar: estadoPersonas !== "SUPERVISOR",
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {
              borderRadius: "10px 10px 0px 0px",
            },
            disableElevation: true,
          },
        ]}
      />
      <Paper sx={{ width: "100%" }}>
        <ToolBarTable
          count={planificacionData.length}
          rowsPerPage={rowsPerPagePlanificacion}
          page={pagePlanificacion}
          handleChangePage={handleChangePagePlanificacion}
          handleChangeRowsPerPage={handleChangeRowsPerPagePlanificacion}
          ocultarPaginacion={false}
          simplePagination={true}
          botonesIzquierdos={[
            {
              tooltip: "Agregar",
              texto: "Agregar",
              funcion: () => {
                setagregandoPlanificacion(true);
              },
              disabled: false,
              Icon: AddCircle,
              color: "primary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
          botonesDerechos={[]}
        />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TablaCabecera
                columns={
                  estadoRequest === "SUPERVISOR" ? columnsSupervisor : columns
                }
                habilitarOrdenar={false}
              />
            </TableHead>

            <TableBody component={"div"}>
              {agregandoPlanificacion ? (
                <RowNuevo
                  estadoRequest={estadoRequest}
                  setopen={setagregandoPlanificacion}
                  socket={socket}
                  tiposData={tiposData}
                  itemHijo={datoOperativo}
                />
              ) : null}
              {planificacionData
                .slice(
                  pagePlanificacion * rowsPerPagePlanificacion,
                  pagePlanificacion * rowsPerPagePlanificacion +
                    rowsPerPagePlanificacion
                )
                .map((planificacion, index) => {
                  return (
                    <Row
                      //   arrayExport={arrayExport}
                      //   fromIOP={fromIOP}
                      //   setarrayExport={setarrayExport}
                      // IOP
                      campoExport={campoExport}
                      estadoRequest={estadoRequest}
                      index={index}
                      key={planificacion[campoExport]}
                      planificacion={planificacion}
                      socket={socket}
                      tiposData={tiposData}
                      datoOperativo={datoOperativo}
                      itemCliente={itemPadre}
                      asignarActividad={asignarActividad}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ActividadesTab;
