import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";
import RowItem from "./RowItem/RowItem";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import { tienePermisoRuta } from "../../../config/validaciones";

import { permisoDenagado } from "../../../config/const";
import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import MenuContext from "../../../Context/Menu/MenuContext";
import EncuestasEvaluacionesContext from "../../../Context/EncuestasEvaluaciones/EncuestasEvaluacionesContext";
import Cargando from "../../../components/Extra/Cargando";
import ToolBarTable from "../../../components/Extra/ToolBarTable";
import TablaCabecera from "../../../components/Extra/TablaCabecera";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Backspace, FindInPage } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },

  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    tabla,
    itemSocket,
    setitemSocket,
    perfil,
    subEstado,
    // MODAL
    setopenModal,
    setobjetoActivoModal,
  } = props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    socket,
    usuario,
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const { remplazarRows, getData } = useContext(EncuestasEvaluacionesContext);
  const classes = useRowStyles();

  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.emit("client:eliminarData", {
        tabla: "registro",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        regUser: usuario.usuario,
        regEmpresa: usuario.rucempresa,
        encId: row.encId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    // seteditar(true);
  };

  const funcSubmit = () => {
    try {
      // socket.emit("client:actualizarData", {
      //   ...item,
      //   tabla: "reguenta",
      //   rucempresa: usuario.rucempresa,
      //   rol: usuario.rol,
      //   regUser: usuario.usuario,
      //   regEmpresa: usuario.rucempresa,
      // });
      seteditar(false);
    } catch (error) {}
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  // TABlA
  //pagina actual
  const [page, setPage] = React.useState(0);
 
  const [buscando, setbuscando] = useState(false);
  const [pagination, setpagination] = useState({});
  const [expandido, setexpandido] = useState(false);
  const [personasData, setpersonasData] = useState([]);
  const grupo = "hijo";
  const persona = row.encDocId;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "docEstado",
    label: "ID",
    minWidth: 150,
    tipo: "string",
    profundidad: 1,
    campo1: "docEstado",
    campo2: "",
    campo3: "",
  });
  const [orden, setorden] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    disFecReg1: "",
    disFecReg2: "",
    prePeriodo1: "",
    prePeriodo2: "",
  });

  const [columns] = useState([
    {
      id: "nombres",
      label: "Nombres",
      minWidth: 50,
      widthExcel: 15,
      tipo: "string",
      profundidad: 1,
      campo1: "nombres",
      campo2: "",
      campo3: "",
    },
    {
      id: "encPerId",
      label: "Codigo",
      minWidth: 50,
      widthExcel: 15,
      tipo: "string",
      profundidad: 1,
      campo1: "encPerId",
      campo2: "",
      campo3: "",
    },
  ]);
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > personasData.length) {
      setcargando(true);
      const res = await getData(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        perfil,
        grupo,
        persona,
        subEstado
      );
      const data = res.data.data.results;

      setcargando(false);

      const resultado = data.filter((row) => {
        const existe = personasData.some((item, index) => {
          return row.encId === item.encId;
        });

        return !existe;
      });

      setpersonasData([...resultado, ...personasData]);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (page === 0) {
      if (event.target.value > personasData.length) {
        setcargando(true);
        const res = await getData(
          event.target.value,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          perfil,
          grupo,
          persona,
          subEstado
        );
        const data = res.data.data.results;
        const pagination = res.data.data.pagination;
        setcargando(false);
        setpersonasData(data);
        setpagination(pagination);
      }
    }
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    const res = await getData(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      perfil,
      grupo,
      persona,
      subEstado
    );
    const data = res.data.data.results;
    const pagination = res.data.data.pagination;
    setcargando(false);
    setpersonasData(data);
    setpagination(pagination);

    setPage(0);
    setinput(column);
    setorden(!orden);
  };
  const funcionReload = async () => {
    setRowsPerPage(10);
    setPage(0);
    setcargando(true);
    const res = await getData(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      perfil,
      grupo,
      persona,
      subEstado
    );
    const data = res.data.data.results;
    const pagination = res.data.data.pagination;
    setcargando(false);
    setpersonasData(data);
    setpagination(pagination);
    return data;
  };

  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      disFecReg1: "",
      disFecReg2: "",
      prePeriodo1: "",
      prePeriodo2: "",
    });

    const res = await getData(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify({
        disFecReg1: "",
        disFecReg2: "",
      }),
      rucempresa,
      perfil,
      grupo,
      persona,
      subEstado
    );
    const data = res.data.data.results;
    const pagination = res.data.data.pagination;
    setcargando(false);
    setpersonasData(data);
    setpagination(pagination);
    setPage(0);
    setsearch("");
    setbuscando(false);

    // setopenMB(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (search.trim() === "") {
      return mostrarAlerta("ingrese una busqueda", "error");
    }
    setbuscando(false);
    setcargando(true);

    const res = await getData(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      perfil,
      grupo,
      persona,
      subEstado
    );
    const data = res.data.data.results;
    const pagination = res.data.data.pagination;
    setcargando(false);
    setpersonasData(data);
    setpagination(pagination);
    setPage(0);
    setbuscando(true);
  };

  useEffect(() => {
    if (!itemSocket) {
      return;
    }
    const { tipo, item } = itemSocket;

    if (personasData.length === 0) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      if (item.tabla === tabla) {
        if (row.encDocId === item.encFecReg.substr(0, 7)) {
          setpersonasData([item, ...personasData]);
        }
      }
    }
    if (tipo === "editar") {
      const res = personasData.map((itemPersonaData) => {
        if (itemPersonaData.encId === item.encId) {
          return item;
        } else {
          return itemPersonaData;
        }
      });

      setpersonasData(res);
    }
    if (tipo === "eliminar") {
      if (item.tabla === tabla) {
        const res = personasData.filter((itemPersonaData) => {
          return itemPersonaData.encId !== item.encId;
        });

        setpersonasData(res);
        funcionAalerta();
      }
    }
    if (tipo === "eliminarSeleccion") {
      const res = personasData.filter((row) => {
        return !item.hijos.some((item) => {
          return item === row.encId;
        });
      });

      setpersonasData(res);
      setpagination({
        ...pagination,
        numRows: pagination.numRows - item.hijos.length,
      });
    }
    funcionAalerta();
    setitemSocket(null);
    // eslint-disable-next-line
  }, [itemSocket]);
  const [itemExport, setitemExport] = useState({
    padre: "",
    hijos: [],
  });

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.regNombres}?`}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={true}
          ocultarDelete={false}
          campoExport={campoExport}
          campoEstado="regEstado"
          editar={editar}
          funcSubmit={funcSubmit}
          setexpandido={setexpandido}
          expandido={expandido}
          funcionReload={funcionReload}
          personasData={personasData}
          itemExport={itemExport}
          setitemExport={setitemExport}
          subEstado={subEstado}
          perfil={perfil}
        />
        <TableCell
         sx={{ paddingLeft: "5px" }}
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.docTitulo}
        </TableCell>
        <TableCell
         sx={{ paddingLeft: "5px" }}
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.docFechaEmision}
        </TableCell>
        <TableCell
         sx={{ paddingLeft: "5px" }}
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.docFechaFinalizacion}
        </TableCell>
        <TableCell
         sx={{ paddingLeft: "5px" }}
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.tregistros}
        </TableCell>
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandido} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" gutterBottom component="span">
                  {perfil}
                </Typography>
                <form onSubmit={handleSubmit} className={classes.formAllW}>
                  <TextField
                    inputProps={{ className: classes.inputPadding }}
                    id=""
                    label={"Buscar"}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    value={search}
                    onChange={(e) => setsearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <Box display="flex" justifyContent="space-between">
                          {buscando ? (
                            <IconButton
                              aria-label=""
                              color="secondary"
                              onClick={async () => {
                                await funcionDefault();
                              }}
                            >
                              <Backspace color="error" />
                            </IconButton>
                          ) : null}
                          <IconButton
                            aria-label=""
                            color="secondary"
                            type="submit"
                          >
                            <FindInPage />
                          </IconButton>
                        </Box>
                      ),
                      labelWidth: "600",
                    }}
                  />
                </form>
                {/* <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    change({
                      ...ObjectoActivo,
                      encFecReg.substr(0, 7): row.encDocId,
                    });
                    setopen(true);
                  }}
                >
                  <AddCircle />
                </IconButton> */}
              </Box>
              <ToolBarTable
                search={search}
                titulo={"Asistencias"}
                columns={columns}
                expandible={false}
                campoExport={campoExport}
                tabla={tabla}
                funcionReload={funcionReload}
                pagination={pagination}
                rowsPerPage={rowsPerPage}
                rows={personasData}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                fullBuscador={fullBuscador}
                exportarExcel={true}
              />
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  remplazarRows={remplazarRows}
                  search={search}
                  input={input}
                  setinput={setinput}
                  orden={orden}
                  setorden={setorden}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  setPage={setPage}
                  setcargando={setcargando}
                  rows={personasData}
                  // setarrayExport={setarrayExport}
                  columns={columns}
                  habilitarOrdenar={true}
                  funcionOrdenar={funcionOrdenar}
                  agregarColumna={true}
                />
                <TableBody>
                  {cargando ? (
                    <TableRow>
                      <TableCell colSpan={columns.length + 2} align="center">
                        <Cargando titulo="Cargando Personas..." />
                      </TableCell>
                    </TableRow>
                  ) : (
                    personasData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((rowHijo) => {
                        return (
                          <RowItem
                            rowPadre={row}
                            campoExport={campoExport}
                            seteditar={seteditar}
                            arrayExport={arrayExport}
                            setarrayExport={setarrayExport}
                            rowHijo={rowHijo}
                            index={index}
                            // setopen={setopen}
                            key={rowHijo.encId}
                            itemExport={itemExport}
                            setitemExport={setitemExport}
                            perfil={perfil}
                            setopenModal={setopenModal}
                            setobjetoActivoModal={setobjetoActivoModal}
                          />
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
