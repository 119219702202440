import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";
import MuiImageSlider from "mui-image-slider";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import ConfirmacionDialog from "../../Extra/ConfirmacionDialog";
import AuthContext from "../../../Context/Auth/AuthContext";
import InformesContext from "../../../Context/Informes/InformesContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Collapse,
  Typography,
  TextField,
  Table,
  TableBody,
} from "@mui/material";
import { Backspace, FindInPage } from "@mui/icons-material";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import ToolBarTable from "./ToolBarTable";
import Cargando from "../../Extra/Cargando";
import ItemHijo from "./ItemHijo/ItemHijo";
import TablaCabecera from "../../Extra/TablaCabecera";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    estadoRequest,
    itemSocketHijo,
    setitemSocketHijo,
    tabla,
    parametrosUtilizadosHijos,
    setparametrosUtilizadosHijos,
    idRegistro,
    notificacion,
    tipo,
  } = props;
  const [Openimagen, setOpenimagen] = useState(false);
  const {
    socket,
    usuario,
    usuario: { rucempresa },
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { change, getData, eliminar, remplazarRows } =
    useContext(InformesContext);

  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.emit("client:eliminarData", {
        tabla: "informe",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        perUser: usuario.usuario,
        perEmpresa: usuario.rucempresa,
        infId: row.infId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    change({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openDialog, setopenDialog] = useState(false);
  //pagina actual
  const [page, setPage] = React.useState(0);
 
  const [columnsHijos] = useState([
    {
      id: "infNomDirigido",
      label: "Persona",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infNomDirigido",
      campo2: "",
      campo3: "",
    },
    {
      id: "infFotos",
      label: "Fotos",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infFotos",
      campo2: "",
      campo3: "",
    },   {
      id: "infVideo",
      label: "Video",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infVideo",
      campo2: "",
      campo3: "",
    },
    {
      id: "infAsunto",
      label: "Asunto",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infAsunto",
      campo2: "",
      campo3: "",
    },
    {
      id: "infFechaSuceso",
      label: "Fec. Suceso",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infFechaSuceso",
      campo2: "",
      campo3: "",
    },
    {
      id: "infLugar",
      label: "Lugar",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infLugar",
      campo2: "",
      campo3: "",
    },
    {
      id: "infPerjudicado",
      label: "Implicado",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infPerjudicado",
      campo2: "",
      campo3: "",
    },
    {
      id: "infFecReg",
      label: "Fec. Reg",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infFecReg",
      campo2: "",
      campo3: "",
    },
    {
      id: "infUser",
      label: "User",
      minWidth: 50,
      tipo: "string",
      profundidad: 1,
      campo1: "infUser",
      campo2: "",
      campo3: "",
    },
  ]);
  const [itemExport, setitemExport] = useState({
    padre: "",
    hijos: [],
  });

  const [buscando, setbuscando] = useState(false);
  const [pagination, setpagination] = useState({});
  const [expandido, setexpandido] = useState(false);
  const [personasData, setpersonasData] = useState([]);
  const grupo = "hijo";

  const periodo = row.infFecReg.substr(0, 7);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setsearch] = useState("");
  const [cargando, setcargando] = useState(false);
  const [input, setinput] = useState({
    id: "infId",
    label: "ID",
    minWidth: 150,
    tipo: "string",
    profundidad: 1,
    campo1: "infId",
    campo2: "",
    campo3: "",
  });
  const [orden, setorden] = useState(false);
  const [fullBuscador, setfullBuscador] = useState({
    disFecReg1: "",
    disFecReg2: "",
    prePeriodo1: "",
    prePeriodo2: "",
  });

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > personasData.length) {
      setcargando(true);
      const res = await getData(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        grupo,

        periodo
      );
      const data = res.data.data.results;

      setcargando(false);

      const resultado = data.filter((row) => {
        const existe = personasData.some((item, index) => {
          return row.infId === item.infId;
        });

        return !existe;
      });

      setpersonasData([...resultado, ...personasData]);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (page === 0) {
      if (event.target.value > personasData.length) {
        setcargando(true);
        const res = await getData(
          event.target.value,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          estadoRequest,
          grupo,

          periodo
        );
        const data = res.data.data.results;
        const pagination = res.data.data.pagination;
        setcargando(false);
        setpersonasData(data);
        setpagination(pagination);
      }
    }
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    const res = await getData(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      grupo,

      periodo
    );
    const data = res.data.data.results;
    const pagination = res.data.data.pagination;
    setcargando(false);
    setpersonasData(data);
    setpagination(pagination);

    setPage(0);
    setinput(column);
    setorden(!orden);
  };

  const funcionReload = async (primeraConsulta) => {
    if (
      primeraConsulta &&
      tipo === estadoRequest &&
      parametrosUtilizadosHijos === false
    ) {
      if (notificacion) {
        setparametrosUtilizadosHijos(true);
        setRowsPerPage(10);
        setPage(0);
        setcargando(true);
        const res = await getData(
          10,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          estadoRequest,
          grupo,
          periodo,
          idRegistro,
          notificacion
        );
        const data = res.data.data.results;
        const pagination = res.data.data.pagination;
        setcargando(false);
        setpersonasData(data);
        setpagination(pagination);
        return data;
      } else {
        // CONSULTA SIN NOTIFICACION
        setRowsPerPage(10);
        setPage(0);
        setcargando(true);
        const res = await getData(
          10,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          estadoRequest,
          grupo,
          periodo,
          "",
          ""
        );
        const data = res.data.data.results;
        const pagination = res.data.data.pagination;
        setcargando(false);
        setpersonasData(data);
        setpagination(pagination);
        return data;
      }
    } else {
      // CONSULTA SIN NOTIFICACION
      setRowsPerPage(10);
      setPage(0);
      setcargando(true);
      const res = await getData(
        10,
        0,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        grupo,
        periodo,
        "",
        ""
      );
      const data = res.data.data.results;
      const pagination = res.data.data.pagination;
      setcargando(false);
      setpersonasData(data);
      setpagination(pagination);
      return data;
    }
  };
  // const funcionReload = async () => {
  //   setRowsPerPage(10);
  //   setPage(0);
  //   setcargando(true);
  //   const res = await getData(
  //     10,
  //     0,
  //     search,
  //     input.id,
  //     orden,
  //     JSON.stringify(fullBuscador),
  //     rucempresa,
  //     estadoRequest,
  //     grupo,
  //     periodo
  //   );
  //   const data = res.data.data.results;
  //   const pagination = res.data.data.pagination;
  //   setcargando(false);
  //   setpersonasData(data);
  //   setpagination(pagination);
  //   return data;
  // };

  const funcionDefault = async () => {
    setcargando(true);
    setfullBuscador({
      disFecReg1: "",
      disFecReg2: "",
      prePeriodo1: "",
      prePeriodo2: "",
    });

    const res = await getData(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify({
        disFecReg1: "",
        disFecReg2: "",
      }),
      rucempresa,
      estadoRequest,
      grupo,

      periodo
    );
    const data = res.data.data.results;
    const pagination = res.data.data.pagination;
    setcargando(false);
    setpersonasData(data);
    setpagination(pagination);
    setPage(0);
    setsearch("");
    setbuscando(false);

    // setopenMB(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (search.trim() === "") {
      return mostrarAlerta("ingrese una busqueda", "error");
    }
    setbuscando(false);
    setcargando(true);

    const res = await getData(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      grupo,

      periodo
    );
    const data = res.data.data.results;
    const pagination = res.data.data.pagination;
    setcargando(false);
    setpersonasData(data);
    setpagination(pagination);
    setPage(0);
    setbuscando(true);
  };
  useEffect(() => {
    const { item, tipo } = itemSocketHijo;

    if (tipo === "") {
      return null;
    }

    const esPadre = row.infFecReg.substr(0, 7) === item.infFecReg.substr(0, 7);

    if (!esPadre) {
      return;
    }

    if (tipo === "agregar") {
      setpersonasData([item, ...personasData]);
    } else if (tipo === "editar") {
      const res = personasData.map((itemPersona) => {
        if (itemPersona.infId === item.infId) {
          return item;
        } else {
          return itemPersona;
        }
      });

      setpersonasData(res);
    } else if (tipo === "eliminar") {
      const res = personasData.filter(
        (itemPersona) => itemPersona.infId !== item.infId
      );

      const funcionAalerta = () => {
        mostrarAlerta(item.msg, item.alert ? item.alert : "success");
      };
      if (res.length === 0 && personasData.length !== 0) {
        eliminar(row.infId, funcionAalerta);
      }

      setpersonasData(res);
    } else if (tipo === "eliminarSeleccion") {
      const res = personasData.filter((row) => {
        const existe = !item.lista.some((item) => {
          return item === row.infId;
        });

        return existe;
      });

      const funcionAalerta = () => {
        mostrarAlerta(item.msg, item.alert ? item.alert : "success");
      };
      if (res.length === 0 && personasData.length !== 0) {
        eliminar(row.infId, funcionAalerta);
      }
      setpersonasData(res);
    }
    setitemSocketHijo({
      tipo: "",
      item: {},
    });
    // eslint-disable-next-line
  }, [itemSocketHijo]);
  return (
    <React.Fragment>
      {" "}
      <Dialog
        open={Openimagen}
        onClose={() => setOpenimagen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        {row.infFotos.length === 0 ? null : (
          <MuiImageSlider
            images={row.infFotos.map((item) => item.url)}
            fitToImageHeight={true}
          />
        )}
      </Dialog>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.infNomDirigido}?`}
        _id={row._id}
        funcion={handleEliminar}
      />{" "}
      {openDialog ? (
        <Dialog
        maxWidth={"md"}
          open={openDialog}
          onClose={() => setopenDialog(false)}
        >
          <DialogTitle> Leidos</DialogTitle>
          <DialogContent dividers>
            {row.conLeidos.map((item) => {
              return `${item.nombres}: ${item.fecha} - ${item.rol.join(" - ")}`;
            })}
          </DialogContent>
        </Dialog>
      ) : null}
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="conEstado"
          setopenDialog={setopenDialog}
          setexpandido={setexpandido}
          expandido={expandido}
          personasData={personasData}
          funcionReload={funcionReload}
          itemExport={itemExport}
          setitemExport={setitemExport}
        />{" "}
        <TableCell
         sx={{ paddingLeft: "5px" }}
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.infFecReg.substring(0, 7)}
        </TableCell>
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandido} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1" gutterBottom component="span">
                  Personas
                </Typography>
                {/* <TextField
                  inputProps={{ className: classes.inputPadding }}
                  className={classes.formD}
                  size="small"
                  label="Busqueda"
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                /> */}
                <form onSubmit={handleSubmit} className={classes.formAllW}>
                  <TextField
                    inputProps={{ className: classes.inputPadding }}
                    id=""
                    label={"Buscar"}
                    variant="outlined"
                    fullWidth
                    color="primary"
                    value={search}
                    onChange={(e) => setsearch(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <Box display="flex" justifyContent="space-between">
                          {buscando ? (
                            <IconButton
                              aria-label=""
                              color="secondary"
                              onClick={async () => {
                                await funcionDefault();
                              }}
                            >
                              <Backspace color="error" />
                            </IconButton>
                          ) : null}
                          <IconButton
                            aria-label=""
                            color="secondary"
                            type="submit"
                          >
                            <FindInPage />
                          </IconButton>
                        </Box>
                      ),
                      labelWidth: "600",
                    }}
                  />
                </form>
                {/* <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    change({
                      ...ObjectoActivo,
                  
                      rolpNomCliente: row.rolpNomCliente,
                      rolpIdCliente: row.rolpIdCliente,
                      infFecReg: row.infFecReg,
                    });
                    setopen(true);
                  }}
                >
                  <AddCircle />
                </IconButton> */}
              </Box>
              <ToolBarTable
                search={search}
                titulo={"Rol De Pago"}
                infFecReg={row.infFecReg}
                columns={[]}
                expandible={false}
                campoExport={campoExport}
                tabla={tabla}
                funcionReload={() => funcionReload(true)}
                pagination={pagination}
                rowsPerPage={rowsPerPage}
                rows={personasData}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                fullBuscador={fullBuscador}
                exportarExcel={true}
                deshabilitarReload={estadoRequest === "PROCESADO"}
              />
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  remplazarRows={remplazarRows}
                  search={search}
                  input={input}
                  setinput={setinput}
                  orden={orden}
                  setorden={setorden}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  setPage={setPage}
                  setcargando={setcargando}
                  rows={personasData}
                  // setarrayExport={setarrayExport}
                  columns={columnsHijos}
                  habilitarOrdenar={true}
                  funcionOrdenar={funcionOrdenar}
                  agregarColumna={true}
                />
                <TableBody>
                  {cargando ? (
                    <TableRow>
                      <TableCell
                        colSpan={columnsHijos.length + 2}
                        align="center"
                      >
                        <Cargando titulo="Cargando Personas..." />
                      </TableCell>
                    </TableRow>
                  ) : (
                    personasData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((personaData, index) => {
                        return (
                          <ItemHijo
                            padre={row}
                            row={personaData}
                            key={personaData.infId}
                            estadoRequest={estadoRequest}
                            campoExport={campoExport}
                            rows={personasData}
                            seteditar={seteditar}
                            arrayExport={arrayExport}
                            setarrayExport={setarrayExport}
                            itemExport={itemExport}
                            setitemExport={setitemExport}
                            index={index}
                            setopen={setopen}
                          />
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
