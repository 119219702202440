import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  BackupTable,
  Cancel,
  Create,
  DeleteForever,
  Email,
  ListRounded,
  PictureAsPdf,
  QrCode,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import ImagenCelda from "../../../components/ImagenCelda";
import ModalQR from "./ModalQR";
import ModalEmail from "../../../components/ModalEmail";
import DialogOpciones from "../../../components/DialogOpciones";
import clienteAxios from "../../../../config/axios";

import { saveAs } from "save-as";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
  } = props;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Clientes");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [cargandoDialogOpciones, setcargandoDialogOpciones] = useState(false);
  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [openQr, setopenQr] = useState(false);
  // EMAIL
  const [openModalEmail, setopenModalEmail] = useState(false);
  // Dialog opciones
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "cliente",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        perUser: usuario.usuario,
        perEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState(row);
  };

  return (
    <React.Fragment>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Datos",
            funcion: () => {
              window.open(
                `${clienteAxios.defaults.baseURL}reportes/infocliente?cliId=${row.cliId}&rucempresa=${row.cliEmpresa}`
              );
            },
            Icon: PictureAsPdf,
            color: "avatar",
            id: 2,
            disabled: cargandoDialogOpciones,
          },
          {
            tooltip: "Excel",
            funcion: async () => {
              try {
                setcargandoDialogOpciones(true);

                const res = await clienteAxios.post(
                  `/reportes/excel/infoempresa`,
                  {
                    rucempresa: usuario.rucempresa,
                    cliId: row.cliId,
                  },
                  { responseType: "blob" }
                );
                const excelBlob = new Blob([res.data], {
                  type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                saveAs(excelBlob, `${"Info Empresa"}.xlsx`);
                setopen(false);
                setcargandoDialogOpciones(false);
              } catch (error) {
                mostrarAlerta("Hubo un error", "error");
                setopen(false);
                setcargandoDialogOpciones(false);
              }
            },
            Icon: BackupTable,
            color: "success",
            id: 1,
            disabled: cargandoDialogOpciones,
          },
        ]}
        titulo={"Acciones"}
      />
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
      />
      <ModalEmail
        open={openModalEmail}
        setopen={setopenModalEmail}
        id={row.cliId}
        documento={`Cliente: #${row.cliId}`}
        json={{
          option: "reenviarCorreo",
          tipo: "Cliente",
        }}
        emailDefecto={row.perEmail}
        campos={[
          {
            label: "Persona",
            propiedad: row.cliNombreComercial,
          },
        ]}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setitemQr({
                  ...itemQr,
                  cliId: row.cliId,
                  rucempresa: row.cliEmpresa,
                });
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Correo",
              texto: "",
              funcion: () => {
                setopenModalEmail(true);
              },
              disabled: false,
              Icon: Email,
              color: "primary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Mas acciones",
              texto: "",
              funcion: () => {
                setopenDialogOpciones(true);
              },
              disabled: false,
              Icon: ListRounded,
              color: "primary",
              id: 4,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Estado",
              texto: "",
              funcion: () => {
                setopenDialogOpciones(true);
              },
              disabled: false,
              Icon: ListRounded,
              color: "primary",
              id: 5,
              ocultar: false,
              tipo: "check",
              estado: row.perEstado === "ACTIVO" || row.perEstado === "ACTIVA",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        <>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.cliId}
          </TableCell>{" "}
          <ImagenCelda propiedad={row.perLogo} />
          <TableCell size="small" align="left" className={classes.celda}>
            {row.cliDocNumero}
          </TableCell>
          <TableCell size="small" align="left" className={classes.celda}>
            {row.cliRazonSocial}
          </TableCell>{" "}
          <TableCell size="small" align="left" className={classes.celda}>
            {row.cliNombreComercial}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.cliTelefono.join(" - ")}
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {row.cliUser}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
