import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "fisFechaFiscalizacion",
    label: "F. Fiscalizacion",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fisFechaFiscalizacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "fisCliDocNumero",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fisCliDocNumero",
    align: "left",
    ocultar: true,
  },
  {
    id: "fisPuestoServicio",
    label: "Puesto Servicio",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fisPuestoServicio",
    align: "center",
    ocultar: true,
  },
  {
    id: "fisCumplimientoGuardias",
    label: "Cumplimiento Guardias",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fisCumplimientoGuardias",
    align: "center",
    ocultar: true,
  },
  {
    id: "fisCumplimientoSupervisor",
    label: "Cumplimiento Supervisor",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fisCumplimientoSupervisor",
    align: "center",
    ocultar: true,
  },
  {
    id: "fisObservacionesCliente",
    label: "Observaciones Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fisObservacionesCliente",
    align: "center",
    ocultar: true,
  },

  {
    id: "fisUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "fisUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // : 1,
  fisCliId: "",
  fisFechaFiscalizacion: FechaExacta,
  fisPuestoServicio: "",
  fisCumplimientoGuardias: "",
  fisCumplimientoSupervisor: "",
  fisObservacionesCliente: "",
  fisNoConformidades: [],
  fisGuardias: [],
  fisUser: "",
  fisEmpresa: "",
  fisFecReg: "",
  fisCliDocNumero: "",
  fisCliRazonSocial: "",
};
