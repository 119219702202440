import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../../Context/Auth/AuthContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Badge,
} from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { quitarTildes } from "../../../../../../config/const";
import ConfirmacionDialog from "../../../../../../components/Extra/ConfirmacionDialog";
import AlertaContext from "../../../../../../Context/Alerta/AlertaContext";
import ListaItemPreguntas from "./ListaItemPreguntas";
import ListaItemRespuestas from "./ListaItemRespuestas";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { consigna, item, setitem, index } = props;
  const { usuario, socket } = useContext(AuthContext);
  const [itemEdit, setItemEdit] = useState({
    ...consigna,
  });
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();

  const funcSubmit = () => {
    const docPreguntas = item.docPreguntas.map((item, itemIndex) => {
      if (index === itemIndex) {
        return { ...itemEdit, propiedad: quitarTildes(itemEdit.propiedad) };
      } else {
        return item;
      }
    });
    socket.emit("client:actualizarData", {
      ...item,
      tabla: "evaluacion",
      docPreguntas,
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      docUser: usuario.usuario,
      docEmpresa: usuario.rucempresa,
    });
    setitem({
      ...item,
      docPreguntas,
    });
    seteditar(false);
  };
  // const handleChange = (e) => {
  //   setItemEdit({
  //     ...itemEdit,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const botonDisabled = () => {
    if (
      itemEdit.pregunta.trim() === "" ||
      itemEdit.tipoCampo.trim() === "" ||
      ((itemEdit.tipoCampo === "LISTA" ||
        itemEdit.tipoCampo === "MULTIPLE LISTA" ||
        itemEdit.tipoCampo === "PUNTAJE") &&
        itemEdit.opcionesLista.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setItemEdit({ ...consigna });
    // eslint-disable-next-line
  }, [item]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [itemListaAgregar, setitemListaAgregar] = useState("");
  const [expandirPreguntas, setexpandirPreguntas] = useState(false);
  const [itemPreguntasAgregar, setitemPreguntasAgregar] = useState("");
  const handleEliminar = async (_id) => {
    socket.emit("client:actualizarData", {
      ...item,
      tabla: "evaluacion",
      docPreguntas: item.docPreguntas.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      docUser: usuario.usuario,
      docEmpresa: usuario.rucempresa,
    });
    setitem({
      ...item,
      docPreguntas: item.docPreguntas.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const [expandirLista, setexpandirLista] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
      />
      {editar ? (
        <TableRow className={classes.root}>
          <TableCell>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(false);

                    setItemEdit({ ...consigna });
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  color={
                    botonDisabled
                      ? botonDisabled()
                        ? "inherit"
                        : "secondary"
                      : "secondary"
                  }
                  disabled={botonDisabled()}
                  className={classes.margin}
                  onClick={() => {
                    funcSubmit();

                    seteditar(false);
                    setItemEdit({ ...consigna });
                  }}
                >
                  <Check fontSize="small" />
                </IconButton>
              </>
            </Box>
          </TableCell>

          {/* las demas columnas */}
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id="pregunta"
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.pregunta}
                name="pregunta"
                onChange={(e) =>
                  setItemEdit({
                    ...itemEdit,
                    [e.target.name]: e.target.value,
                    propiedad: e.target.value
                      .replace(/\s+/g, "_")
                      .toLocaleLowerCase(),
                  })
                }
                helperText="Agregando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                id="tipoCampo"
                inputProps={{ style: { textAlign: "center" } }}
                value={itemEdit.tipoCampo}
                name="tipoCampo"
                onChange={(e) => {
                  setItemEdit({
                    ...itemEdit,
                    tipoCampo: e.target.value,
                    opcionesLista:
                      e.target.value === "PUNTAJE"
                        ? [
                            "NADA SATISFECHO (1)",
                            "POCO SATISFECHO (2)",
                            "NEUTRAL (3)",
                            "MUY SATISFECHO (4)",
                            "TOTALMENTE SATISFECHO (5)",
                          ]
                        : itemEdit.opcionesLista,
                    respuestas: [],
                  });
                }}
                helperText="Agregando"
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {[
                  "TEXTO",
                  "NUMERICO",
                  "AREA TEXTO",
                  "LISTA",
                  "MULTIPLE LISTA",
                  "PUNTAJE",
                ].map((item, index) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {itemEdit.tipoCampo === "MULTIPLE LISTA" ||
              itemEdit.tipoCampo === "LISTA" ||
              itemEdit.tipoCampo === "PUNTAJE" ? (
                <>
                  <Badge
                    badgeContent={itemEdit.opcionesLista.length}
                    color="success"
                  >
                    <IconButton
                      aria-label=""
                      onClick={() => setexpandirLista(!expandirLista)}
                      size="small"
                    >
                      {expandirLista ? (
                        <ExpandLess color="primary" fontSize="small" />
                      ) : (
                        <ExpandMore color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  </Badge>
                  <Collapse
                    in={expandirLista}
                    timeout="auto"
                    unmountOnExit
                    sx={{ maxHeight: "10rem", overflow: "scroll" }}
                  >
                    <TextField
                      autoFocus
                      inputProps={{ style: { textAlign: "center" } }}
                      value={itemListaAgregar}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (
                            itemEdit.opcionesLista.some(
                              (itemLista) =>
                                itemLista.toLowerCase().trim() ===
                                itemListaAgregar.toLowerCase().trim()
                            )
                          ) {
                            return mostrarAlerta(
                              "Ya existe un item en la lista identico."
                            );
                          }
                          if (itemListaAgregar.trim() === "") {
                            return;
                          }
                          setItemEdit({
                            ...itemEdit,
                            opcionesLista: [
                              itemListaAgregar.trim(),
                              ...itemEdit.opcionesLista,
                            ],
                          });
                          setitemListaAgregar("");
                        }
                      }}
                      onChange={(e) => {
                        setitemListaAgregar(e.target.value);
                      }}
                      size="small"
                      helperText="Pulse 'Enter' para agregar"
                      fullWidth
                    />
                    <List dense={true} sx={{ padding: 0 }}>
                      {itemEdit.opcionesLista.map((itemLista, index) => {
                        return (
                          <ListaItemPreguntas
                            key={index}
                            itemLista={itemLista}
                            index={index}
                            itemEdit={itemEdit}
                            setItemEdit={setItemEdit}
                            checksRespuestas={
                              itemEdit.tipoCampo === "LISTA" ||
                              itemEdit.tipoCampo === "MULTIPLE LISTA" ||
                              itemEdit.tipoCampo === "PUNTAJE"
                            }
                          />
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              ) : (
                "- "
              )}
            </TableCell>{" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <>
                <Badge
                  badgeContent={itemEdit.respuestas.length}
                  color="success"
                >
                  <IconButton
                    aria-label=""
                    onClick={() => setexpandirPreguntas(!expandirPreguntas)}
                    size="small"
                  >
                    {expandirPreguntas ? (
                      <ExpandLess color="primary" fontSize="small" />
                    ) : (
                      <ExpandMore color="primary" fontSize="small" />
                    )}
                  </IconButton>
                </Badge>
                <Collapse
                  in={expandirPreguntas}
                  timeout="auto"
                  unmountOnExit
                  sx={{ maxHeight: "10rem", overflow: "scroll" }}
                >
                  {itemEdit.tipoCampo === "LISTA" ||
                  itemEdit.tipoCampo === "MULTIPLE LISTA" ||
                  itemEdit.tipoCampo === "PUNTAJE" ? null : (
                    <TextField
                      autoFocus
                      inputProps={{ style: { textAlign: "center" } }}
                      value={itemPreguntasAgregar}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (itemPreguntasAgregar.trim() === "") {
                            return;
                          }
                          setItemEdit({
                            ...itemEdit,
                            respuestas: [
                              itemPreguntasAgregar,
                              ...itemEdit.respuestas,
                            ],
                          });
                          setitemPreguntasAgregar("");
                        }
                      }}
                      onChange={(e) => {
                        setitemPreguntasAgregar(e.target.value);
                      }}
                      size="small"
                      helperText="Pulse 'Enter' para agregar"
                      fullWidth
                    />
                  )}
                  <List dense={true} sx={{ padding: 0 }}>
                    {itemEdit.respuestas.map((itemLista, index) => {
                      return (
                        <ListaItemRespuestas
                          key={itemLista + index}
                          itemLista={itemLista}
                          index={index}
                          itemEdit={itemEdit}
                          setItemEdit={setItemEdit}
                          checksRespuestas={
                            itemEdit.tipoCampo === "LISTA" ||
                            itemEdit.tipoCampo === "MULTIPLE LISTA" ||
                            itemEdit.tipoCampo === "PUNTAJE"
                          }
                        />
                      );
                    })}
                  </List>
                </Collapse>
              </>
            </TableCell>
          </>
        </TableRow>
      ) : (
        <TableRow className={classes.root}>
          <TableCell>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(true);
                  }}
                >
                  <Create fontSize="small" color={"secondary"} />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <Delete fontSize="small" color={"error"} />
                </IconButton>
              </>
            </Box>
          </TableCell>

          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.pregunta}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.tipoCampo}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.tipoCampo === "MULTIPLE LISTA" ||
              consigna.tipoCampo === "LISTA" ||
              consigna.tipoCampo === "PUNTAJE" ? (
                <>
                  <Badge
                    badgeContent={consigna.opcionesLista.length}
                    color="success"
                  >
                    <IconButton
                      aria-label=""
                      onClick={() => setexpandirLista(!expandirLista)}
                      size="small"
                    >
                      {expandirLista ? (
                        <ExpandLess color="primary" fontSize="small" />
                      ) : (
                        <ExpandMore color="primary" fontSize="small" />
                      )}
                    </IconButton>
                  </Badge>
                  <Collapse
                    in={expandirLista}
                    timeout="auto"
                    unmountOnExit
                    sx={{ maxHeight: "10rem", overflow: "scroll" }}
                  >
                    <List dense={true} sx={{ padding: 0 }}>
                      {consigna.opcionesLista.map((itemLista, index) => {
                        return (
                          <ListItem sx={{ padding: 0 }} key={index}>
                            <ListItemText
                              primary={index + 1 + " - " + itemLista}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </>
              ) : (
                "-"
              )}
            </TableCell>{" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <>
                <Badge
                  badgeContent={consigna.respuestas.length}
                  color="success"
                >
                  <IconButton
                    aria-label=""
                    onClick={() => setexpandirPreguntas(!expandirPreguntas)}
                    size="small"
                  >
                    {expandirPreguntas ? (
                      <ExpandLess color="primary" fontSize="small" />
                    ) : (
                      <ExpandMore color="primary" fontSize="small" />
                    )}
                  </IconButton>
                </Badge>
                <Collapse
                  in={expandirPreguntas}
                  timeout="auto"
                  unmountOnExit
                  sx={{ maxHeight: "10rem", overflow: "scroll" }}
                >
                  <List dense={true} sx={{ padding: 0 }}>
                    {consigna.respuestas.map((itemLista, index) => {
                      return (
                        <ListItem sx={{ padding: 0 }} key={index}>
                          <ListItemText
                            primary={index + 1 + " - " + itemLista}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            </TableCell>
          </>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default memo(RowAgregar);
