import { Checkbox, TableCell, TableRow } from "@mui/material";
import React, { useMemo } from "react";

const Dia = ({ row, isSelected, handleClick, turnos, index }) => {
  const disabled = useMemo(
    () => turnos.some((turno) => turno.dias.some((dia) => dia.id === row.id)),
     // eslint-disable-next-line 
    [turnos]
  );
  const isItemSelected = isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;
  return (
    <TableRow
      hover={!disabled}
      onClick={(event) => {
        if (disabled) return;
        handleClick(event, row.id);
      }}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
      sx={{ cursor: disabled ? "default" : "pointer" }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          disabled={disabled}
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>

      <TableCell align="left" padding="none">
        {new Date(
          Date.parse(row.fecha_inicio) +
            new Date().getTimezoneOffset() * 60 * 1000
        ).toLocaleString("es-ES", {
          weekday: "short",
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
      </TableCell>
    </TableRow>
  );
};

export default Dia;
