import React, { useReducer, memo } from "react";

import {
  OBTENER,
  CREAR,
  EDITAR,
  ELIMINAR,
  CHANGE_ACTIVO,
  CLEAR_ROWS,
  ADD_ROWS,
  IMPORTAR_ROWS,
  ELIMINAR_SELECCION,
  CHANGE_GUARDADO,
} from "../../types/index";
import clienteAxios from "../../config/axios";

//context
import RegistroContext from "./RegistroContext";
//reducer
import PersonasReducer from "./RegistroReducer";
const RegistroReducer = (props) => {
  const initialState = {
    rows: null,
    columns: [
      {
        id: "regFecReg",
        label: "Fecha",
        minWidth: 50,
        widthExcel: 30,
        tipo: "string",
        profundidad: 1,
        campo1: "regFecReg",
        campo2: "",
        campo3: "",
      },
    ],
    ObjectoActivo: {
      regCodigo: "",
      regNombres: "",
      regPuesto: "",
      regCoordenadas: {
        latitud: "",
        longitud: "",
      },
      regDispositivo: "",
      regEstado: "",
      regUser: "",
      regFecReg: "",
      Todos: "",
    },

    pagination: null,
  };

  const [state, dispatch] = useReducer(PersonasReducer, initialState);
  const getData = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil,
    grupo,
    persona,
    subEstado
  ) => {
    const res = await clienteAxios.get(
      `/registros/?cantidad=${cantidad}&page=${page}&search=${
        search ? search : ""
      }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
        datos ? datos : ""
      }&rucempresa=${rucempresa ? rucempresa : ""}&grupo=${
        grupo ? grupo : ""
      }&persona=${persona ? persona : ""}&perfil=${
        perfil ? perfil : ""
      }&subEstado=${subEstado ? subEstado : ""}`
    );

    return res;
  };

  const obtener = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil,
    grupo,
    persona,
    subEstado
  ) => {
    const res = await getData(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      perfil,
      grupo,
      persona,
      subEstado
    );
    dispatch({
      type: OBTENER,
      payload: {
        results: res.data.data.results,
        pagination: res.data.data.pagination,
      },
    });
  };
  const addRows = async (items) => {
    dispatch({
      type: ADD_ROWS,
      payload: items,
    });
  };
  const remplazarRows = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil,
    grupo,
    persona,
    subEstado
  ) => {
    const res = await getData(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      perfil,
      grupo,
      persona,
      subEstado
    );
    dispatch({
      type: OBTENER,
      payload: {
        results: res.data.data.results,
        pagination: res.data.data.pagination,
      },
    });
  };
  const agregar = async (item, funcionAalerta) => {
    try {
      dispatch({
        type: CREAR,
        payload: { item, funcionAalerta },
      });
    } catch (error) {}
  };

  const editar = async (item, funcionAalerta) => {
    try {
      dispatch({
        type: EDITAR,
        payload: { item, funcionAalerta },
      });
    } catch (error) {}
  };

  const eliminar = async (_id, funcionAalerta) => {
    try {
      dispatch({
        type: ELIMINAR,
        payload: { _id, funcionAalerta },
      });
    } catch (error) {}
  };
  const eliminarSeleccion = async (_ids, funcionAalerta) => {
    dispatch({
      type: ELIMINAR_SELECCION,
      payload: { _ids, funcionAalerta },
    });
  };

  const change = (item) => {
    dispatch({
      type: CHANGE_ACTIVO,
      payload: item,
    });
  };
  const changeGuardado = (item) => {
    dispatch({
      type: CHANGE_GUARDADO,
      payload: item,
    });
  };
  const clear = () => {
    dispatch({
      type: CLEAR_ROWS,
    });
  };
  const rowsImport = (rows) => {
    dispatch({
      type: IMPORTAR_ROWS,
      payload: rows,
    });
  };
  return (
    <RegistroContext.Provider
      value={{
        //state
        rows: state.rows,
        columns: state.columns,
        ObjectoActivo: state.ObjectoActivo,
        pagination: state.pagination,
        // funciones
        obtener,
        agregar,
        editar,
        eliminar,
        change,
        clear,
        addRows,
        remplazarRows,
        getData,
        rowsImport,
        eliminarSeleccion,
        changeGuardado,
      }}
    >
      {props.children}
    </RegistroContext.Provider>
  );
};

export default memo(RegistroReducer);
