export const columns = [
  {
    id: "eveCliDocumento",
    label: "Cliente",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "eveCliDocumento",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveCliPuesto",
    label: "Puesto",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveCliPuesto",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveTipo",
    label: "Tipo",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveNombre",
    label: "Evento",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveFecReg",
    label: "Fec. Reg.",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveUser",
    align: "center",
    ocultar: true,
  },
];
export const columnsSupervisor = [
  {
    id: "eveCliDocumento",
    label: "Cliente",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "eveCliDocumento",
    align: "center",
    ocultar: true,
  },

  {
    id: "eveTipo",
    label: "Tipo",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveNombre",
    label: "Evento",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveFecReg",
    label: "Fec. Reg.",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "eveUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "eveUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  Todos: "",
  eveActividades: [],
  eveVulnerabilidades: [
    // {
    //   fotos: [],
    //   vulnerabilidad: "",
    // },
  ],
  eveEmpresa: "",
  eveNombre: "",
  eveTipo: "",
  eveUser: "",
  eveCliId: "",
  eveCliDocumento: "",
  eveCliNombre: "",
  eveCliUbicacion: "",
  eveCliPuesto: "",
  eveCoordenadas: {
    longitud: -79.178298,
    latitud: -0.254916,
  },
};

export const columnasHijoVulnerabilidad = [
  {
    id: "vulnerabilidad",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "vulnerabilidad",
    align: "center",
    ocultar: true,
  },
  {
    id: "fotos",
    label: "Fotos",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "fotos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijo = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Intervalo en minutos",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijoSupervisor = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "puesto",
    label: "Puesto",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "puesto",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Número de revisiones",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijoRonda = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "ubicacion",
    label: "Ubicación",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "ubicacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Intervalo en minutos",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const columnasHijoRondaSupervisor = [
  {
    id: "nombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "nombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "puesto",
    label: "Puesto",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "puesto",
    align: "center",
    ocultar: true,
  },
  {
    id: "ubicacion",
    label: "Ubicación",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "ubicacion",
    align: "center",
    ocultar: true,
  },

  {
    id: "minutos",
    label: "Número de revisiones",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "minutos",
    align: "center",
    ocultar: true,
  },
];
export const itemHijoDefectoVulnerabilidad = {
  fotos: [],
  nombre: "",
};
export const itemHijoDefecto = {
  nombre: "",
  ubicacion: "",
  puesto: "",
  coordenadas: {
    longitud: -79.178298,
    latitud: -0.254916,
  },
  vulnerabilidad: "",
  foto: "",
  minutos: "5",
  horario: [],
};

export const columnasHijoDelHijo = [
  {
    id: "hora",
    label: "H. Inicio",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "hora",
    align: "center",
    ocultar: true,
  },
  {
    id: "horaFinal",
    label: "H. Final",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "horaFinal",
    align: "center",
    ocultar: true,
  },

  {
    id: "observación",
    label: "observación",
    minWidth: 100,
    tipo: "string",
    orden: 1,
    propiedad: "observación",
    align: "center",
    ocultar: true,
  },
];

export const itemHijoDelHijoDefecto = {
  observacion: "",
  hora: "",
  horaFinal: "",
};
