import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../Context/Auth/AuthContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import {
  Cancel,
  Check,
  Create,
  Delete, 
  PictureAsPdf,
} from "@mui/icons-material";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import CampoDoc from "../../../../components/Extra/CampoDoc";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  celda: {
    fontSize: ".7rem",
  },
}));

const RowAgregar = (props) => {
  // eslint-disable-next-line
  const [cargando, setcargando] = useState(false);
  const { consigna, item, setitem, index, socket } = props;
  const { usuario } = useContext(AuthContext);
  const [itemEdit, setItemEdit] = useState({ ...consigna });
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();

  const funcSubmit = () => {
    socket.current.emit("client:actualizarData", {
      ...item,
      tabla: "iso",
      isoOtros: item.isoOtros.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemEdit;
        } else {
          return item;
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      isoUser: `${item.isoUser.split(" ** ")[0]} ** ${usuario.usuario}`,
      isoEmpresa: usuario.rucempresa,
    });
    setitem({
      ...item,
      isoOtros: item.isoOtros.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemEdit;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (
      itemEdit.fechaRevision === "" ||
      itemEdit.version === "" ||
      itemEdit.controlCambios === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setItemEdit({ ...consigna });
    // eslint-disable-next-line
  }, [item]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = async (_id) => {
    socket.current.emit("client:actualizarData", {
      ...item,
      tabla: "iso",
      isoOtros: item.isoOtros.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      isoUser: `${item.isoUser.split(" ** ")[0]} ** ${usuario.usuario}`,
      isoEmpresa: usuario.rucempresa,
    });
    setitem({
      ...item,
      isoOtros: item.isoOtros.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  return (
    <React.Fragment>
      {" "}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Item?"
        _id={index}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {editar ? (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(false);

                    setItemEdit({ ...consigna });
                  }}
                >
                  <Cancel fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  color={
                    botonDisabled
                      ? botonDisabled()
                        ? "inherit"
                        : "secondary"
                      : "secondary"
                  }
                  disabled={botonDisabled()}
                  className={classes.margin}
                  onClick={() => {
                    funcSubmit();

                    seteditar(false);
                    setItemEdit({ ...consigna });
                  }}
                >
                  <Check fontSize="small" />
                </IconButton>{" "}
              </>
            ) : (
              <>
                {" "}
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    seteditar(true);
                  }}
                >
                  <Create fontSize="small" color={"secondary"} />
                </IconButton>
                <IconButton
                  className={classes.margin}
                  onClick={() => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <Delete fontSize="small" color={"error"} />
                </IconButton>
              </>
            )}
          </Box>
        </TableCell>

        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <CampoDoc
                cargando={cargando}
                setcargando={setcargando}
                propiedad={"documento"}
                item={itemEdit}
                setitem={setItemEdit}
                celda={true}
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                disabled
                value={itemEdit.procedimiento}
                name="procedimiento"
                onChange={(e) => handleChange(e)}
                helperText="Editando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                disabled
                type="date"
                value={itemEdit.fechaEmision}
                name="fechaEmision"
                onChange={(e) => handleChange(e)}
                helperText="Editando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                autoFocus
                type="date"
                value={itemEdit.fechaRevision}
                name="fechaRevision"
                onChange={(e) => handleChange(e)}
                helperText="Editando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemEdit.version}
                name="version"
                onChange={(e) => handleChange(e)}
                helperText="Editando"
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={itemEdit.controlCambios}
                name="controlCambios"
                onChange={(e) => handleChange(e)}
                helperText="Editando"
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.documento === "" ? null : (
                <IconButton
                  aria-label=""
                  onClick={() => window.open(consigna.documento)}
                >
                  <PictureAsPdf color="error" />
                </IconButton>
              )}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.procedimiento}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.fechaEmision}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.fechaRevision}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.version}
            </TableCell>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {consigna.controlCambios}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
