import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../components/BarraTablas";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { socketUrl } from "../../config/const";
import ModalB from "../components/ModalB";
import DialogOpciones from "../components/DialogOpciones";
import { Add } from "@mui/icons-material";
import { usePermiso } from "../hooks/usePermiso";
import {
  columnsImplementarios,
  columnsArmas,
  columnsMuniciones,
  objetoActivo,
} from "./data";
import { useCrud } from "../hooks/useCrud";
const Base = (props) => {
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);

  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivo, setObjetoActivo] = useState(objetoActivo);
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  // FUNCIONES STATE
  // FUNCION QUE OBTIENE LA DATA DE LA TABLA
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    tipo,
    subEstado
  ) => {
    try {
      const res = await clienteAxios.get(
        `/inventario/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}&estado=${
          tipo ? tipo : ""
        }&subEstado=${subEstado ? subEstado : ""}`
      );

      return res;
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    tipo,
    subEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      tipo,
      subEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // AGREGA MAS REGISTROS AL STATE
  const addRowsState = async (items) => {
    setrows([...rows, ...items]);
  };
  // REMPLAZA LA CONSULTA ACTUAL POR UNA NUEVA CON OTROS PARAMETROS
  const remplazarRowsState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    tipo,
    subEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      tipo,
      subEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };

  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
  };

  // FIN FUNCIONES STATE
  // FIN DATA STATE

  // FUNCIONES SOCKET
  // NOMBRE DE LA TABLA
  const [tabla] = useState("inventario");
  // ITEM DEL SOCKET SE UTILIZA COMO REFERENCIA
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  // REFERENCIA DEL SOCKET
  const socket = useRef();
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // CUANDO CAMBIA EL OBJETO DEL SOCKET SE ESCUCHA AQUI Y SE DECIDE QUE HACER CON EL
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Inventario");
  const [estadoRequest, setestadoRequest] = useState("VESTIMENTAS");
  const [subEstado, setSubEstado] = useState("NUEVO");
  // COLUMNAS
  const [columns, setcolumns] = useState(columnsImplementarios);
  useEffect(() => {
    if (estadoRequest === "VESTIMENTAS" || estadoRequest === "UTILITARIOS") {
      setcolumns(columnsImplementarios);
    } else if (estadoRequest === "ARMAS") {
      setcolumns(columnsArmas);
    } else if (estadoRequest === "MUNICIONES") {
      setcolumns(columnsMuniciones);
    }
  }, [estadoRequest]);

  // ABRIR MODAL
  const [open, setopen] = useState(false);
  // BOOLEANO DE SI SE ESTA BUSCANDO O NO
  const [buscando, setbuscando] = useState(false);
  // SI SE ESTA EDITANDO O NO
  const [editar, seteditar] = useState(false);
  // STRING DE BUSQUEDA
  const [search, setsearch] = useState("");
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // CAMPO POR EL CUAL SE FILTRAN LOS REGISTROS PARA EXPORTAR
  const [campoExport] = useState("invId");
  // HOOK DEL CRUD
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(rows, setrows, campoExport, "invUser", usuario);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
    label: "ID",
    minWidth: 150,
    tipo: "string",
    propiedad: campoExport,
  });
  // ARRAY DE EXPORTACION
  const [arrayExport, setarrayExport] = useState([]);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // CAMPOS DE BUSQUEDA EXPANDIDOS
  const [openMB, setopenMB] = useState(true);
  // OBJETO BUSCADOR
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());

  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // EVENTO QUE CAMBIA LA DATA DEL OBJETO BUSCADOR
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  // FUNCION DE BUSQUEDA AVANZADA
  const funcionB = async () => {
    setcargando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };
  // FUNCION DE BUSQUEDA RAPIDA
  const handleSearch = async () => {
    setarrayExport([]);
    setcargando(true);
    setbuscando(true);
    await remplazarRowsState(
      rowsPerPage,
      0,
      search,
      input.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado
    );

    setPage(0);
    setcargando(false);
  };
  // FUNCION QUE PONE LA DATA DE POR DEFECTO O HACE UN REFRESH
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await remplazarRowsState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest,
      subEstado
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  const handleOpen = () => {
    const objetoVestimenta = {
      //   invId: 1,
      invObservacion: "",
      invMaterial: "",
      invPrecioUnitario: "1",
      invTipo: "VESTIMENTAS", // DE ACUERDO AL TAB
      invSerie: "0", // text
      invNombre: "", // TEXTAREA
      invProductoEstado: "NUEVO", // SELECT => NUEVO,USADO
      invMarca: "",
      invModelo: "",
      invStock: "",
      invDisponible: "",
      invTalla: "40.00", // SELECT => 36,38,40,42,44,46
      invClase: "", // NO
      invTipoArma: "", // NO
      invCalibre: "", // NO
      invColor: "", // NO
      invUso: "", // NO
      invStatus: "", // NO
      invProveedor: [
        // TAB (PROVEEDOR) => TABLA
        // {
        //   id: "12",
        //   numDocumento: "0302626288001",
        //   nombres: "Hugo",
        //   numFactura: "32-32-3-32323",
        //   cantidad: "10",
        //   fecFactura: "2022-03-02",
        // },
      ],
      invFotos: [],
      invBodega: [],
      invBodId: "",
      invNomBodega: "",
      invComprobantePdf: "", // TAB (DOCUMENTOS)
      invPermisoPdf: "", // NO
      invFecValidacion: "", // NO
      invFecCaducidad: "", // DATE
      invEstado: "ACTIVA", // SWITCH
      invEmpresa: "",
      invUser: "",
      invFecReg: "",
      invDocumento: "",
      invTotalesStock: [
        {
          estado: "NUEVO",
          cantidad: 0,
        },
        {
          estado: "USADO",
          cantidad: 0,
        },
        {
          estado: "OPERABLE",
          cantidad: 0,
        },
        {
          estado: "DEFECTUOSO",
          cantidad: 0,
        },
      ],
    };
    const objetoUtilitarios = {
      //   invId: 1,
      invObservacion: "",
      invMaterial: "",
      invPrecioUnitario: "1",
      invTipo: "UTILITARIOS", // DE ACUERDO AL TAB
      invSerie: "0", // text
      invNombre: "", // TEXTAREA
      invProductoEstado: "NUEVO", // SELECT => NUEVO,USADO
      invMarca: "",
      invModelo: "",
      invStock: "",
      invDisponible: "",
      invTalla: "40.00", // SELECT => 36,38,40,42,44,46
      invClase: "", // NO
      invTipoArma: "", // NO
      invCalibre: "", // NO
      invColor: "", // NO
      invUso: "", // NO
      invStatus: "", // NO
      invProveedor: [
        // TAB (PROVEEDOR) => TABLA
        // {
        //   id: "12",
        //   numDocumento: "0302626288001",
        //   nombres: "Hugo",
        //   numFactura: "32-32-3-32323",
        //   cantidad: "10",
        //   fecFactura: "2022-03-02",
        // },
      ],
      invFotos: [],
      invBodega: [],
      invBodId: "",
      invNomBodega: "",
      invComprobantePdf: "", // TAB (DOCUMENTOS)
      invPermisoPdf: "", // NO
      invFecValidacion: "", // NO
      invFecCaducidad: "", // DATE
      invEstado: "ACTIVA", // SWITCH
      invEmpresa: "",
      invUser: "",
      invFecReg: "",
      invDocumento: "",
      invTotalesStock: [
        {
          estado: "NUEVO",
          cantidad: 0,
        },
        {
          estado: "USADO",
          cantidad: 0,
        },
        {
          estado: "OPERABLE",
          cantidad: 0,
        },
        {
          estado: "DEFECTUOSO",
          cantidad: 0,
        },
      ],
    };
    const objetoArmas = {
      invMaterial: "",
      invPrecioUnitario: "1.00",
      invTipo: "ARMAS", // DE ACUERDO AL TAB
      invSerie: "0",
      invNombre: "",
      invProductoEstado: "NUEVO",
      invMarca: "", // TEXT
      invModelo: "", // TEXT
      invStock: "",
      invDisponible: "",
      invTalla: "", // NO
      invClase: "LETAL", // SELECT => LETAL,NO LETAL
      invTipoArma: "PISTOLA", // SELECT => REVOLVER,PISTOLA,ESCOPETA,FUSIL
      invCalibre: "CAL 22", // SELECT => .38,9MM,.32/CAL 12,CAL 16,CAL 22
      invColor: "", // TEXT
      invUso: "FIJA", // SELECT => FIJA,MOVIL
      invStatus: "APTA", // SELECT => APTA,DESCOMISADA,PERDIDA
      invProveedor: [
        // TAB (PROVEEDOR) => TABLA
        // {
        //   id: "12",
        //   numDocumento: "0302626288001",
        //   nombres: "Hugo",
        //   numFactura: "32-32-3-32323",
        //   cantidad: "10",
        //   fecFactura: "2022-03-02",
        // },
      ],
      invBodega: [],
      invBodId: "",
      invNomBodega: "",
      invFotos: [],
      invComprobantePdf: "", // TAB (DOCUMENTOS)
      invPermisoPdf: "", // TAB (DOCUMENTOS)
      invFecValidacion: "", // DATE
      invFecCaducidad: "", // DATE
      invEstado: "ACTIVA", // SWITCH
      invEmpresa: "",
      invUser: "",
      invFecReg: "",
      invDocumento: "",
      invTotalesStock: [
        {
          estado: "NUEVO",
          cantidad: 0,
        },
        {
          estado: "USADO",
          cantidad: 0,
        },
        {
          estado: "OPERABLE",
          cantidad: 0,
        },
        {
          estado: "DEFECTUOSO",
          cantidad: 0,
        },
      ],
    };
    const objetoMuniciones = {
      invTipo: "MUNICIONES",
      invSerie: "0",
      invNombre: "", // disabled => serie, calibre, clase, material
      invPrecioUnitario: "1.00",
      invProductoEstado: "OPERABLE",
      invMarca: "", // imput
      invModelo: "", // interno
      invStock: 0, // interno
      invDisponible: 0, // interno
      invTalla: "", // interno
      invClase: "LETAL", // select => FEOGEO, LETAL
      invMaterial: "PLOMO", // select => PLOMO, ACERO
      invTipoArma: "", // interno
      invCalibre: ".38", // select => .38,9MM, .32/ CAL 12, CAL 16, CAL 22, CAL 20
      invColor: "", // interno
      invUso: "", // interno
      invStatus: "", // interno
      invProveedor: [],
      invBodega: [],
      invBodId: "",
      invNomBodega: "",
      invFotos: [],
      invComprobantePdf: "", // input
      invPermisoPdf: "", // interno
      invFecValidacion: "", // interno
      invFecCaducidad: "", // input
      invEstado: "ACTIVA", // interno
      invEmpresa: "", // interno
      invUser: "", // interno
      invDocumento: "",
      invTotalesStock: [
        {
          estado: "NUEVO",
          cantidad: 0,
        },
        {
          estado: "USADO",
          cantidad: 0,
        },
        {
          estado: "OPERABLE",
          cantidad: 0,
        },
        {
          estado: "DEFECTUOSO",
          cantidad: 0,
        },
      ],
    };
    if (estadoRequest === "ARMAS") {
      changeObjetoActivoState(objetoArmas);
    } else if (estadoRequest === "VESTIMENTAS") {
      changeObjetoActivoState(objetoVestimenta);
    } else if (estadoRequest === "UTILITARIOS") {
      changeObjetoActivoState(objetoUtilitarios);
    } else if (estadoRequest === "MUNICIONES") {
      changeObjetoActivoState(objetoMuniciones);
    }
    setopen(true);
  };

  return (
    <Box>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Agregar",
            funcion: () => {
              handleOpen();
            },
            Icon: Add,
            color: "primary",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              handleOpen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 1,
            ocultar: true,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              if (!tienePermiso("añadir")) {
                return alertaPermiso("añadir");
              }
              handleOpen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada (${estadoRequest} - ${subEstado})`}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          {[
            { estado: "VESTIMENTAS", label: "VESTIMENTAS" },
            { estado: "ARMAS", label: "ARMAS" },
            { estado: "MUNICIONES", label: "MUNICIONES" },
            { estado: "UTILITARIOS", label: "EQUIPOS" },
          ].map((itemEstado) => (
            <Button
              key={itemEstado.estado}
              size="small"
              variant={estadoRequest === itemEstado.estado ? "contained" : null}
              onClick={async () => {
                setcargando(true);
                await remplazarRowsState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  itemEstado.estado,
                  subEstado
                );

                setPage(0);
                setestadoRequest(itemEstado.estado);
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              {itemEstado.label}
            </Button>
          ))}
        </Box>
        <Box>
          {["NUEVO", "USADO", "OPERABLE", "BUEN ESTADO", "DEFECTUOSO"].map(
            (itemSubEstado) => (
              <Button
                size="small"
                variant={subEstado === itemSubEstado ? "contained" : null}
                onClick={async () => {
                  setcargando(true);
                  await remplazarRowsState(
                    rowsPerPage,
                    0,
                    "",
                    input.id,
                    orden,
                    JSON.stringify(fullBuscador),
                    rucempresa,
                    estadoRequest,
                    itemSubEstado
                  );
                  setfullBuscador({
                    invFecReg1: "",
                    invFecReg2: "",
                  });
                  setPage(0);
                  setSubEstado(itemSubEstado);
                  setcargando(false);
                }}
                disableElevation
                style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
                color="secondary"
              >
                {itemSubEstado}
              </Button>
            )
          )}
        </Box>
      </Box>
      <Divider />
      <Tabla
        //PAGINACION Y BUSQUEDA
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setopen={setopen}
        search={search}
        input={input}
        orden={orden}
        setinput={setinput}
        setorden={setorden}
        cargando={cargando}
        setcargando={setcargando}
        fullBuscador={fullBuscador}
        //ESTADO
        rows={rows}
        columns={columns}
        pagination={pagination}
        getDataState={getDataState}
        obtenerState={obtenerState}
        addRowsState={addRowsState}
        remplazarRowsState={remplazarRowsState}
        changeObjetoActivoState={changeObjetoActivoState}
        seteditar={seteditar}
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        socket={socket}
        campoExport={campoExport}
        estadoRequest={estadoRequest}
        subEstado={subEstado}
        tabla={tabla}
      />
      <Modal
        editar={editar}
        seteditar={seteditar}
        setopen={setopen}
        open={open}
        ObjectoActivo={ObjectoActivo}
        changeObjetoActivoState={changeObjetoActivoState}
        socket={socket}
        estadoRequest={estadoRequest}
      />
    </Box>
  );
};

export default memo(Base);
