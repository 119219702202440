import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";

// import ConfirmacionDialog from "../../../Dialog/ConfirmacionDialog"
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import ConfirmacionDialog from "../../../Extra/ConfirmacionDialog";
import AuthContext from "../../../../Context/Auth/AuthContext";
import CambioPuestoContext from "../../../../Context/CambioPuesto/CambioPuestoContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    itemExport,
    setitemExport,
  } = props;
  const { socket, usuario } = useContext(AuthContext);
  const { change } = useContext(CambioPuestoContext);

  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.emit("client:eliminarData", {
        tabla: "cambiopuesto",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        camUser: usuario.usuario,
        camEmpresa: usuario.rucempresa,
        camId: row.camId,
        camFecReg: row.camFecReg,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    change({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      {" "}
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${
          row.camDocuPersona + " " + row.camNomPersona
        }`}
        _id={row._id}
        funcion={handleEliminar}
      />{" "}
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={true}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="conEstado"
          itemExport={itemExport}
          setitemExport={setitemExport}
        />

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.camDocuPersona + " " + row.camNomPersona}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          style={{
            color:
              row.camEstado === "APROBADO"
                ? "green"
                : row.camEstado === "EN PROCESO"
                ? "orange"
                : row.camEstado === "ANULADA"
                ? "red"
                : "red",
            fontWeight: "bold",
          }}
        >
 
          {row.camEstado}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.camFecha}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.camMotivo}
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.camUser}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
