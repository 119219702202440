import React, { memo, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { DeleteForever, Error, Store } from "@mui/icons-material";
import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  ListItemButton,
} from "@mui/material";
import { tienePermisoRuta } from "../../../config/validaciones";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { permisoDenagado } from "../../../config/const";
import AuthContext from "../../../Context/Auth/AuthContext";
import MenuContext from "../../../Context/Menu/MenuContext";
import { withRouter } from "react-router-dom";
import {
  IntegrationInstructions,
  PublishedWithChanges,
} from "@mui/icons-material";
import { serverphp } from "../../../config/const";
import { ModalRetenciones } from "./ModalRetenciones";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ExportDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    setopen,
    setopenConfirmDialog,
    row,
    // setopenModalEmail,
    setopenModal,
    changeObjetoActivoState,
  } = props;

  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const [openModalRetencion, setopenModalRetencion] = useState(false);
  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <ModalRetenciones
        open={openModalRetencion}
        setopen={setopenModalRetencion}
        row={row}
      />
      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Acciones.</DialogTitle>
        <List>
          <ListItem
            button
            disabled={row.venEstado === "AUTORIZADO"}
            onClick={() => {
              if (
                !tienePermisoRuta(
                  rows,
                  props.location.pathname,
                  usuario.rol,
                  "eliminar"
                )
              ) {
                return mostrarAlerta(permisoDenagado, "error");
              }
              setopenConfirmDialog(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <DeleteForever />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Eliminar" />
          </ListItem>{" "}
          <ListItem
            button
            disabled={!(row.venEstado === "AUTORIZADO")}
            onClick={() => {
              // Abres la nueva ventas

              window.open(
                `${serverphp}/reportes/xml.php?letra=${row.venTipoDocumento}&empresa=${usuario.rucempresa}&codigo=${row.venAutorizacion}`,
                "_blank"
              );
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <IntegrationInstructions />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="XML" />
          </ListItem>{" "}
          {/* <ListItem
            button
            disabled={
              !(
                row.venEstado === "AUTORIZADO" ||
                row.venEstado === "ANULADA" ||
                row.venEstado === "ACTIVA" ||
                row.venEstado === "SIN AUTORIZAR"
              )
            }
            onClick={() => {
              setopenModalEmail(true);
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Email />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Correo" />
          </ListItem> */}
          <ListItem
            button
            disabled={
              !(row.venEstado === "AUTORIZADO" && row.venTipoDocumento === "F")
            }
            onClick={() => {
              setopenModal(true);
              changeObjetoActivoState({
                ...row,
                venOption: "4",
                venEstado: "GENERAR XML",
                venTipoDocumento: "NC",
                venNumFacturaAnterior: row.venNumFactura,
                fechaSustentoFactura: row.venFechaFactura,
                venProductosAntiguos: row.venProductos,
                deshabilitarCampos: true,
                venFechaFactura: new Date(
                  Date.now() - new Date().getTimezoneOffset() * 60 * 1000
                )
                  .toISOString()
                  .substring(0, 10),
              });
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PublishedWithChanges />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Nota Crédito" />
          </ListItem>
          <ListItem
            button
            disabled={!(row.venErrorAutorizacion === "SI")}
            onClick={() => {
              window.open(
                `${serverphp}/procesosSRI/errores/${row.venEmpresa}/${row.venTipoDocumento}${row.venAutorizacion}.txt `,
                "_blank"
              );
            }}
          >
            <ListItemAvatar>
              <Avatar className={classes.delete}>
                <Error />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Error" />
          </ListItem>
          <ListItemButton
            disabled={
              Object.keys(JSON.parse(row.venOtros || "{}")).length === 0
            }
            onClick={() => setopenModalRetencion(true)}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Store />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Retención" />
          </ListItemButton>
        </List>
      </Box>
    </Dialog>
  );
};

export default memo(withRouter(ExportDialog));
