import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../../Dialog/ConfirmacionDialog";
import MuiImageSlider from "mui-image-slider";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import ConfirmacionDialog from "../../../Extra/ConfirmacionDialog";
import AuthContext from "../../../../Context/Auth/AuthContext";
import InformesContext from "../../../../Context/Informes/InformesContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import Videos from "../../../Extra/Videos";
import CeldaUser from "../../../../views/components/CeldaUser";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    seteditar,
    arrayExport,
    setarrayExport,
    row,
    index,
    setopen,
    campoExport,
    itemExport,
    setitemExport,
  } = props;

  const { socket, usuario } = useContext(AuthContext);
  const { change } = useContext(InformesContext);

  const classes = useRowStyles();

  const handleEliminar = async (_id) => {
    try {
      socket.emit("client:eliminarData", {
        tabla: "informe",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        perUser: usuario.usuario,
        perEmpresa: usuario.rucempresa,
        infId: row.infId,
        infFecReg: row.infFecReg,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    change({
      ...row,
    });
    setopen(true);
    seteditar(true);
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  const [openDialog, setopenDialog] = useState(false);

  const [Openimagen, setOpenimagen] = useState(false);
  const [OpenimagenVideo, setOpenimagenVideo] = useState(false);
  return (
    <React.Fragment>
      <Dialog
        open={OpenimagenVideo}
        onClose={() => setOpenimagenVideo(false)}
        aria-labelledby="draggable-dialog-title"
        maxWidth={"md"}
      >
        {row.infFotos.length === 0 ? null : <Videos videos={row.infVideo} />}
      </Dialog>
      <Dialog
        open={Openimagen}
        onClose={() => setOpenimagen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        {row.infVideo.length === 0 ? null : (
          <MuiImageSlider
            images={row.infFotos.map((item) => item.url)}
            fitToImageHeight={true}
          />
        )}
      </Dialog>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.infNomDirigido}?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      {openDialog ? (
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xl"
          open={openDialog}
          onClose={() => setopenDialog(false)}
        >
          <DialogTitle> Leidos</DialogTitle>
          <DialogContent dividers>
            {row.conLeidos.map((item) => {
              return `${item.nombres}: ${item.fecha} - ${item.rol.join(" - ")}`;
            })}
          </DialogContent>
        </Dialog>
      ) : null}
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="conEstado"
          setopenDialog={setopenDialog}
          itemExport={itemExport}
          setitemExport={setitemExport}
          socket={socket}
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.infNomDirigido}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.infFotos.length === 0 ? (
            "No hay imagenes"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding="none"
              alignItems="center"
            >
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setOpenimagen(true)}
                className={classes.margin}
              >
                <Visibility size="small" color="secondary" />
              </IconButton>
            </Box>
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.infVideo.length === 0 ? (
            "No hay videos"
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding="none"
              alignItems="center"
            >
              <IconButton
                aria-label=""
                size="small"
                onClick={() => setOpenimagenVideo(true)}
                className={classes.margin}
              >
                <Visibility size="small" color="secondary" />
              </IconButton>
            </Box>
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.infAsunto}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.infFechaSuceso).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.infLugar}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.infPerjudicado}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.infFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>

        <CeldaUser user={row.infUser} claseCelda={classes.celda} />
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
