import React, { memo, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Badge,
} from "@mui/material";

import { useState } from "react";

// import ConfirmacionDialog from "../../Dialog/ConfirmacionDialog";

import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../config/validaciones";
import MenuContext from "../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../config/const";

import RowItem from "./RowItem";
import { AddCircle } from "@mui/icons-material";
import RowAgregarItem from "./RowAgregarItem";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import TablaCabecera from "../../../../components/Extra/TablaCabecera";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    bancos,
    socket,
    alertaPermiso,
    tienePermiso,
  } = props;
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row, enviarCorreo: false });
  const [editar, seteditar] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [agregar, setagregar] = useState(false);
  useEffect(() => {
    setarrayExport(
      arrayExport.map((itemExp) => {
        if (itemExp.ccId === item.ccId) {
          return item;
        } else {
          return itemExp;
        }
      })
    );
    // eslint-disable-next-line
  }, [item.ccPagos]);
  const handleEliminar = async (_id) => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "categoria",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        ccUser: usuario.usuario,
        ccEmpresa: usuario.rucempresa,
        ccId: row.ccId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem({ ...row });
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "categoria",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          ccUser: usuario.usuario,
          ccEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.catNombre === "" ||
      item.catDetalle === "" ||
      item.catEmpresa === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setitem({ ...row, enviarCorreo: false });
  }, [row]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${row.porCodigo}?`}
        contenido={`El Código ${row.porCodigo} será eliminado, no podrá ser recuperado.`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={true}
          ocultarDelete={true}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="enviarCorreo"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          item={item}
          setitem={setitem}
          expandible={true}
          expandir={expandir}
          setexpandir={setexpandir}
        />
        <TableCell
          padding="none"
          size="small"
          align="left"
          className={classes.celda}
        >
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Badge
              badgeContent={item.ccPagos.length}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            ></Badge>
            {`${row.ccRucCliente} ${row.ccNomCliente}`}
          </Box>
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.ciudad}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.sector}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.ccFactura}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.ccFechaFactura.substr(0, 10)}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.ccProcedencia}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
          style={{
            color:
              row.ccEstado === "PENDIENTE"
                ? //rojo tomate
                  "#ffa726"
                : row.ccEstado === "CANCELADO"
                ? //verde
                  "green"
                : row.ccEstado === "ANULADA"
                ? "#f44336"
                : "#ffa726",
            fontWeight: "bold",
          }}
        >
          {row.ccEstado}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {row.ccFechaAbono.substr(0, 10)}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.ccValorFactura}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.ccValorRetencion}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.ccValorAPagar}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.ccAbono}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          ${row.ccSaldo}
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.ccFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  size="small"
                  disabled={
                    item.ccEstado === "CANCELADO" || item.ccEstado === "ANULADA"
                  }
                  color={
                    item.ccEstado === "CANCELADO" || item.ccEstado === "ANULADA"
                      ? "inherit"
                      : "primary"
                  }
                  aria-label=""
                  onClick={() => {
                    if (!tienePermiso("añadir")) {
                      return alertaPermiso("añadir");
                    }

                    setagregar(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
                <Typography variant="body1" gutterBottom component="span">
                  Pagos
                </Typography>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "ccComprobante",
                      align: "center",
                      minWidth: 25,
                      colSpan: 1,
                      label: "Comprobante",
                    },
                    {
                      id: "cpTipo",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Tipo",
                    },
                    {
                      id: "cpNumero",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Número",
                    },
                    {
                      id: "cpBanco",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Banco",
                    },
                    {
                      id: "cpDeposito",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Deposito",
                    },
                    {
                      id: "cpFechaAbono",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Fecha Abono",
                    },
                    {
                      id: "cpValor",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Valor",
                    },
                    {
                      id: "cpDetalle",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Detalle",
                    },
                    {
                      id: "cpUsuario",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Usuario",
                    },
                  ]}
                />
                <TableBody>
                  {agregar ? (
                    <RowAgregarItem
                      socket={socket}
                      bancos={bancos}
                      item={item}
                      setitem={setitem}
                      setagregar={setagregar}
                      tienePermiso={tienePermiso}
                      alertaPermiso={alertaPermiso}
                    />
                  ) : null}
                  {item.ccPagos.map((ccPago, index) => {
                    return (
                      <RowItem
                        socket={socket}
                        ccPago={ccPago}
                        bancos={bancos}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                        tienePermiso={tienePermiso}
                        alertaPermiso={alertaPermiso}
                      />
                    );
                  })}
                  <TableRow>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        style={{ fontSize: ".8rem" }}
                      >
                        $
                        {item.ccPagos.length === 0
                          ? null
                          : item.ccPagos
                              .map((row) => {
                                return Number(row.ccValor);
                              })
                              .reduce(reducer)
                              .toFixed(2)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                    <TableCell
                      padding="none"
                      size="small"
                      align="center"
                      className={classes.celda}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
