import {
  Autocomplete,
  Box,
  Collapse,
  Divider,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useModalStyle } from "../../styles/styleModal";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../config/axios";
import { useSimpleTable } from "../../hooks/useSimpleTable";
import ToolBarTable from "../../components/ToolBarTable";
import { AddCircle } from "@mui/icons-material";
import TablaCabecera from "../../components/TablaCabecera";
import RowPersona from "./RowPersona";
import Cargando from "../../components/Cargando";
import { useEffect } from "react";

const AgregarCollapse = ({
  ObjectoActivo,
  setObjetoActivo,
  expandirAgregar,
  setopen,
  permisos,
  turnos,
}) => {
  const clases = useModalStyle();
  const { mostrarAlerta } = useContext(AlertaContext);

  const [personasData, setpersonasData] = useState([]);
  const [personaData, setPersonaData] = useState({
    id: "",
    nombres: "",
    documento: "",
  });
  const [personasHorarios, setpersonasHorarios] = useState([]);

  const {
    // agregandoPersona,
    setagregandoPersona,
    pagePersona,
    // setPagePersona,
    rowsPerPagePersona,
    // setRowsPerPagePersona,
    handleChangePagePersona,
    handleChangeRowsPerPagePersona,
  } = useSimpleTable("Persona");
  const [horarios, sethorarios] = useState(null);
  const getHorarios = async () => {
    const resHorarios = await clienteAxios("horarios/notListFF/0");
    sethorarios(resHorarios.data);
  };
  useEffect(() => {
    getHorarios();
  }, []);

  const [horarioSeleccionado, sethorarioSeleccionado] = useState({
    id: 0,
    codigo: "",
    tipo: "",
    hora_inicio: "",
    hora_final: "",
    color: "",
  });
  if (!horarios) {
    return <Cargando titulo={"cargando"} />;
  }
  return (
    <Collapse in={expandirAgregar} timeout="auto" unmountOnExit>
      <Box className={clases.container}>
        <Divider className={clases.formAllW}>
          <Typography variant="body1" color="primary">
            Agregando {turnos && "turno"} {permisos && "Permisos"} - Horarios
          </Typography>
        </Divider>
        <FormControl className={clases.formD}>
          <Autocomplete
            disableClearable={true}
            options={personasData.map((item) => {
              return (
                item.perDocNumero +
                "/+/" +
                item.perApellidos +
                "/+/" +
                item.perNombres +
                "/+/" +
                item.perId
              );
            })}
            getOptionLabel={(option) => {
              return option
                .replace("/+/", " ")
                .replace("/+/", " ")
                .split("/+/")[0];
            }}
            id="controlled-demo"
            value={personaData.documento + " " + personaData.nombres}
            onChange={async (event, newValue) => {
              if (newValue) {
                try {
                  const options = newValue.split("/+/");

                  const itemF = personasData.find(
                    (itemPersona) => itemPersona.perId === Number(options[3])
                  );

                  setPersonaData({
                    ...personaData,
                    documento: itemF.perDocNumero,
                    id: itemF.perId,
                    nombres: itemF.perApellidos + " " + itemF.perNombres,
                  });
                  const res = await clienteAxios(
                    `/horarios_jornada_laboral/byIdPersona/${itemF.perId}`
                  );
                  setpersonasHorarios(res.data.data);
                } catch (error) {
                  mostrarAlerta("Hubo un error al traer horarios", "error");
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                required
                fullWidth
                onChange={async (e) => {
                  if (e.target.value === "") {
                    return mostrarAlerta("Ingrese datos", "error");
                  }
                  try {
                    const res = await clienteAxios.get(
                      `/personas/filtro/0?search=${e.target.value}`
                    );

                    setpersonasData(res.data.data);
                  } catch (error) {
                    return mostrarAlerta("No hay clientes", "error");
                  }
                }}
                label="Persona"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>{" "}
        {turnos && (
          <FormControl className={clases.formD}>
            <Autocomplete
              disableClearable={true}
              options={horarios.map(
                ({ id, codigo, tipo, hora_inicio, hora_final, color }) => {
                  return (
                    tipo +
                    "/+/" +
                    codigo +
                    "/+/" +
                    hora_inicio +
                    "/+/" +
                    hora_final +
                    "/+/" +
                    color
                  );
                }
              )}
              getOptionLabel={(option) => {
                return option
                  .replace("/+/", ": ")
                  .replace("/+/", " ")
                  .replace("/+/", " - ")
                  .split("/+/")[0];
              }}
              id="controlled-demo"
              value={
                horarioSeleccionado.tipo +
                ": " +
                horarioSeleccionado.codigo +
                " " +
                horarioSeleccionado.hora_inicio +
                " - " +
                horarioSeleccionado.hora_final
              }
              onChange={async (event, newValue) => {
                if (newValue) {
                  const options = newValue.split("/+/");
                  const horarioF = horarios.find(
                    ({ codigo }) => codigo === options[1]
                  );
                  sethorarioSeleccionado(horarioF);
                  try {
                  } catch (error) {
                    mostrarAlerta("Hubo un error al traer horarios", "error");
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  required
                  fullWidth
                  label="Horario"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        )}
      </Box>
      <Paper className={clases.root}>
        <ToolBarTable
          count={personasHorarios.length}
          rowsPerPage={rowsPerPagePersona}
          page={pagePersona}
          handleChangePage={handleChangePagePersona}
          handleChangeRowsPerPage={handleChangeRowsPerPagePersona}
          ocultarPaginacion={false}
          simplePagination={true}
          botonesIzquierdos={[
            {
              tooltip: "Agregar",
              texto: "Agregar",
              funcion: () => {
                setagregandoPersona(true);
              },
              disabled: false,
              Icon: AddCircle,
              color: "primary",
              id: 1,
              ocultar: true,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
          botonesDerechos={[]}
        />

        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TablaCabecera
                columns={[
                  {
                    id: "año - mes",
                    align: "center",
                    minWidth: 100,
                    tipo: "string",
                    label: "año - mes",
                  },

                  {
                    id: "Cliente",
                    align: "center",
                    minWidth: 100,
                    tipo: "string",
                    label: "Cliente",
                  },
                  {
                    id: "puesto",
                    align: "center",
                    minWidth: 100,
                    tipo: "string",
                    label: "puesto",
                  },
                  {
                    id: "ubicación",
                    align: "center",
                    minWidth: 100,
                    tipo: "string",
                    label: "ubicación",
                  },
                ]}
                habilitarOrdenar={false}
              />
            </TableHead>

            <TableBody component={"div"}>
              {/* {agregandoPersona ? (
                <RowAgregarPersona
                  setagregar={setagregandoPersona}
                  setitemPadre={setitem}
                  itemPadre={item}
                />
              ) : null} */}
              {personasHorarios
                .slice(
                  pagePersona * rowsPerPagePersona,
                  pagePersona * rowsPerPagePersona + rowsPerPagePersona
                )
                .map((item, index) => {
                  return (
                    <RowPersona
                      item={item}
                      index={index}
                      key={index}
                      ObjectoActivo={ObjectoActivo}
                      setObjetoActivo={setObjetoActivo}
                      permisos={permisos}
                      setopen={setopen}
                      turnos={turnos}
                      horarioSeleccionado={horarioSeleccionado}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Divider sx={{ marginTop: "2rem", width: "100%" }}>
        <Typography variant="body1" color="secondary">
          {permisos && "Permisos"} {turnos && "Turnos"}
        </Typography>
      </Divider>
    </Collapse>
  );
};

export default AgregarCollapse;
