import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";

import Draggable from "react-draggable";
import { Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { useTheme } from "@emotion/react";
// eslint-disable-next-line

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },

  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    margin: theme.spacing(0.5, 0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.5, 0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  const theme = useTheme();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStylesMenuItems(name, permisos, theme) {
    return {
      fontWeight:
        permisos.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
      backgroundColor: permisos.indexOf(name) === -1 ? "white" : "#31b2f7",
    };
  }
  const { mostrarAlerta } = useContext(AlertaContext);
  const clases = useStyles();
  //definiendo los estilos del modal
  const [modalStyle] = useState(getModalStyle);
  const { open, setopen } = props;
  const [encuestas, setencuestas] = useState(null);
  const [evaluaciones, setevaluaciones] = useState(null);
  const [dataLocal, setdataLocal] = useState({});
  const [secctionEncEvaActive, setsecctionEncEvaActive] = useState({
    encuestasSeccion: true,
    evaluacionesSeccion: false,
  });
  const [subcategoriaSeccionencuesta, setsubcategoriaSeccionencuesta] =
    useState(null);
  const [subcategoriaSeccionevaluacion, setsubcategoriaSeccionevaluacion] =
    useState(null);
  const { encuestasSeccion, evaluacionesSeccion } = secctionEncEvaActive;
  useEffect(() => {
    const getData = async () => {
      try {
        const resEncEva = await clienteAxios(
          "/encuestas_evaluaciones/filtro/0"
        );

        const filterEncuestas = resEncEva.data.data.filter(
          (itemRes) => itemRes.docTipo !== "EVALUACION"
        );
        const filterEvaluaciones = resEncEva.data.data.filter(
          (itemRes) => itemRes.docTipo === "EVALUACION"
        );
        const objeto = {};
        resEncEva.data.data.forEach((itemRes) => {
          const objetoEncEva = {};
          const error = false;

          itemRes.docPreguntas.forEach((pregunta) => {
            const { tipoCampo, propiedad } = pregunta;
            const orden =
              tipoCampo === "TEXTO"
                ? 1
                : tipoCampo === "NUMERICO"
                ? 2
                : tipoCampo === "LISTA"
                ? 3
                : tipoCampo === "MULTIPLE LISTA"
                ? 4
                : tipoCampo === "PUNTAJE"
                ? 5
                : 6;

            if (tipoCampo === "NUMERICO") {
              objetoEncEva[propiedad] = {
                ...pregunta,
                value: pregunta.value ? pregunta.value : 0,
                orden,
                error,
              };
            } else if (tipoCampo === "MULTIPLE LISTA") {
              objetoEncEva[propiedad] = {
                ...pregunta,
                value: pregunta.value ? pregunta.value : [],
                orden,
                error,
              };
            } else {
              objetoEncEva[propiedad] = {
                ...pregunta,
                value: pregunta.value ? pregunta.value : "",
                orden,
                error,
              };
            }
          });

          objeto[itemRes.docTitulo] = {
            encId: itemRes.encId,
            objetoEncEva,
            finalizado: itemRes.docEstado === "PROCESADO",

            docEstado: itemRes.docEstado,
          };
        });

        setdataLocal(objeto);
        setencuestas(filterEncuestas);
        setevaluaciones(filterEvaluaciones);
      } catch (error) {
        mostrarAlerta("Es posible que no tenga encuestas o evaluaciones");
        setencuestas([]);
        setevaluaciones([]);
      }
    };
    if (open && !encuestas) {
      getData();
    }
    // eslint-disable-next-line
  }, [open]);
  const handleGuardar = async () => {
    if (encuestasSeccion && subcategoriaSeccionencuesta) {
      const evaluacionF = evaluaciones.find(
        (itemF) => dataLocal[subcategoriaSeccionencuesta].encId === itemF.encId
      );

      let preguntasError = false;
      const objetoErrorFind = {};
      Object.entries(
        dataLocal[subcategoriaSeccionencuesta].objetoEncEva
      ).forEach((preguntaEntrie) => {
        if (preguntaEntrie[1].tipoCampo === "MULTIPLE LISTA") {
          const errorPregunta = preguntaEntrie[1].value.length === 0;
          if (!preguntasError) {
            preguntasError = errorPregunta;
          }
          objetoErrorFind[preguntaEntrie[0]] = {
            ...preguntaEntrie[1],
            error: errorPregunta,
          };
        } else {
          const errorPregunta = preguntaEntrie[1].value.trim() === "";
          if (!preguntasError) {
            preguntasError = errorPregunta;
          }
          objetoErrorFind[preguntaEntrie[0]] = {
            ...preguntaEntrie[1],
            error: errorPregunta,
          };
        }
      });

      evaluacionF.docPreguntas = objetoErrorFind;
      if (preguntasError) {
        setdataLocal({
          ...dataLocal,
          [subcategoriaSeccionencuesta]: {
            ...dataLocal[subcategoriaSeccionencuesta],
            objetoEncEva: objetoErrorFind,
            finalizado: false,
          },
        });
      } else {
        try {
          const evaluacionConArray = {
            ...evaluacionF,
            docEstado: "PROCESADO",
            docPreguntas: Object.values(evaluacionF.docPreguntas),
          };

          const res = await clienteAxios.put(
            "/encuestas_evaluaciones/0",
            evaluacionConArray
          );

          mostrarAlerta(res.data.msg, "success");
          setdataLocal({
            ...dataLocal,
            [subcategoriaSeccionencuesta]: {
              ...dataLocal[subcategoriaSeccionencuesta],
              objetoEncEva: objetoErrorFind,
              finalizado: true,
            },
          });
        } catch (error) {}
      }
    }
    if (evaluacionesSeccion && subcategoriaSeccionevaluacion) {
      const evaluacionF = evaluaciones.find(
        (itemF) =>
          dataLocal[subcategoriaSeccionevaluacion].encId === itemF.encId
      );

      let preguntasError = false;
      const objetoErrorFind = {};
      Object.entries(
        dataLocal[subcategoriaSeccionevaluacion].objetoEncEva
      ).forEach((preguntaEntrie) => {
        if (preguntaEntrie[1].tipoCampo === "MULTIPLE LISTA") {
          const errorPregunta = preguntaEntrie[1].value.length === 0;
          if (!preguntasError) {
            preguntasError = errorPregunta;
          }
          objetoErrorFind[preguntaEntrie[0]] = {
            ...preguntaEntrie[1],
            error: errorPregunta,
          };
        } else {
          const errorPregunta = preguntaEntrie[1].value.trim() === "";
          if (!preguntasError) {
            preguntasError = errorPregunta;
          }
          objetoErrorFind[preguntaEntrie[0]] = {
            ...preguntaEntrie[1],
            error: errorPregunta,
          };
        }
      });

      evaluacionF.docPreguntas = objetoErrorFind;
      if (preguntasError) {
        setdataLocal({
          ...dataLocal,
          [subcategoriaSeccionevaluacion]: {
            ...dataLocal[subcategoriaSeccionevaluacion],
            objetoEncEva: objetoErrorFind,
            finalizado: false,
          },
        });
      } else {
        try {
          const evaluacionConArray = {
            ...evaluacionF,
            docEstado: "PROCESADO",
            docPreguntas: Object.values(evaluacionF.docPreguntas),
          };

          const res = await clienteAxios.put(
            "/encuestas_evaluaciones/0",
            evaluacionConArray
          );

          mostrarAlerta(res.data.msg, "success");
          setdataLocal({
            ...dataLocal,
            [subcategoriaSeccionevaluacion]: {
              ...dataLocal[subcategoriaSeccionevaluacion],
              objetoEncEva: objetoErrorFind,
              finalizado: true,
            },
          });
        } catch (error) {}
      }
    }
  };
  const defaultActive = () => {
    setopen(false);
  };
  const handleChange = (e) => {
    if (encuestasSeccion && subcategoriaSeccionencuesta) {
      setdataLocal({
        ...dataLocal,
        [subcategoriaSeccionencuesta]: {
          ...dataLocal[subcategoriaSeccionencuesta],
          objetoEncEva: {
            ...dataLocal[subcategoriaSeccionencuesta].objetoEncEva,
            [e.target.name]: {
              ...dataLocal[subcategoriaSeccionencuesta].objetoEncEva[
                e.target.name
              ],
              value: e.target.value,
            },
          },
        },
      });
    }

    if (evaluacionesSeccion && subcategoriaSeccionevaluacion) {
      setdataLocal({
        ...dataLocal,
        [subcategoriaSeccionevaluacion]: {
          ...dataLocal[subcategoriaSeccionevaluacion],
          objetoEncEva: {
            ...dataLocal[subcategoriaSeccionevaluacion].objetoEncEva,
            [e.target.name]: {
              ...dataLocal[subcategoriaSeccionevaluacion].objetoEncEva[
                e.target.name
              ],
              value: e.target.value,
            },
          },
        },
      });
    }
  };

  if (!open) {
    return null;
  }

  if (!encuestas) {
    return null;
  }
  if (!evaluaciones) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Encuestas
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={encuestasSeccion ? "contained" : null}
                    disableElevation
                    color={"primary"}
                    style={{
                      padding: ".5rem",
                      borderRadius: "15px 15px  0px 0px",
                    }}
                    onClick={() =>
                      setsecctionEncEvaActive({ encuestasSeccion: true })
                    }
                  >
                    encuestas
                  </Button>
                  <Button
                    variant={evaluacionesSeccion ? "contained" : null}
                    disableElevation
                    color={"primary"}
                    style={{
                      padding: ".5rem",
                      borderRadius: "15px 15px  0px 0px",
                    }}
                    onClick={() =>
                      setsecctionEncEvaActive({ evaluacionesSeccion: true })
                    }
                  >
                    evaluaciones
                  </Button>
                </Box>
              </Box>
              <Divider />
              <Box
                mt={0.5}
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  {encuestasSeccion
                    ? encuestas.map((item) => {
                        return (
                          <Button
                            variant={
                              item.docTitulo === subcategoriaSeccionencuesta
                                ? "contained"
                                : null
                            }
                            disableElevation
                            color={"secondary"}
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            onClick={() =>
                              setsubcategoriaSeccionencuesta(item.docTitulo)
                            }
                          >
                            {item.docTitulo}
                          </Button>
                        );
                      })
                    : null}
                  {evaluacionesSeccion
                    ? evaluaciones.map((item) => {
                        return (
                          <Button
                            variant={
                              item.docTitulo === subcategoriaSeccionevaluacion
                                ? "contained"
                                : null
                            }
                            disableElevation
                            color={"secondary"}
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            onClick={() =>
                              setsubcategoriaSeccionevaluacion(item.docTitulo)
                            }
                          >
                            {item.docTitulo}
                          </Button>
                        );
                      })
                    : null}
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                mt={1}
              >
                {encuestasSeccion && subcategoriaSeccionencuesta
                  ? Object.values(
                      dataLocal[subcategoriaSeccionencuesta].objetoEncEva
                    )
                      .sort((a, b) => a.orden - b.orden)
                      .map(
                        ({
                          opcionesLista,
                          pregunta,
                          propiedad,
                          tipoCampo,
                          value,
                        }) => {
                          if (tipoCampo === "NUMERICO") {
                            return (
                              <TextField
                                type="number"
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                // error={error.perTipoSangre}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            );
                          }
                          if (tipoCampo === "AREA TEXTO") {
                            return (
                              <TextField
                                multiline
                                rows={3}
                                variant="outlined"
                                className={clases.formAllW}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                // error={error.perTipoSangre}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            );
                          }
                          if (tipoCampo === "LISTA") {
                            return (
                              <TextField
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                // error={error.perTipoSangre}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                select
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ displayEmpty: true }}
                              >
                                <MenuItem value="">Seleccione.</MenuItem>
                                {opcionesLista.map((item) => {
                                  return (
                                    <MenuItem value={item}>{item} </MenuItem>
                                  );
                                })}
                              </TextField>
                            );
                          }
                          if (tipoCampo === "MULTIPLE LISTA") {
                            return (
                              <TextField
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                onChange={(e) => {
                                  // setdataLocal(e.target.value);
                                }}
                                select
                                MenuProps={MenuProps}
                                SelectProps={{
                                  displayEmpty: true,
                                  multiple: true,
                                  renderValue: (selected) => {
                                    return selected
                                      .map((value, index) => {
                                        return value;
                                      })
                                      .join(", ");
                                  },
                                }}
                              >
                                <MenuItem disabled value="">
                                  Seleccione.
                                </MenuItem>
                                {opcionesLista.map((item) => {
                                  return (
                                    <MenuItem
                                      style={getStylesMenuItems(
                                        item,
                                        value,
                                        theme
                                      )}
                                      value={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            );
                          }
                          if (tipoCampo === "PUNTAJE") {
                            return (
                              <TextField
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                // error={error.perTipoSangre}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                select
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ displayEmpty: true }}
                              >
                                <MenuItem value="">Seleccione.</MenuItem>
                                {opcionesLista.map((item) => {
                                  return (
                                    <MenuItem value={item}>{item} </MenuItem>
                                  );
                                })}
                              </TextField>
                            );
                          }
                          return (
                            <TextField
                              className={clases.formD}
                              size="small"
                              label={pregunta}
                              value={value}
                              name={propiedad}
                              // error={error.perTipoSangre}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          );
                        }
                      )
                  : null}

                {evaluacionesSeccion && subcategoriaSeccionevaluacion
                  ? Object.values(
                      dataLocal[subcategoriaSeccionevaluacion].objetoEncEva
                    )
                      .sort((a, b) => a.orden - b.orden)
                      .map(
                        ({
                          opcionesLista,
                          pregunta,
                          propiedad,
                          tipoCampo,
                          value,
                          error,
                        }) => {
                          if (tipoCampo === "NUMERICO") {
                            return (
                              <TextField
                                type="number"
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                error={error}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            );
                          }
                          if (tipoCampo === "AREA TEXTO") {
                            return (
                              <TextField
                                multiline
                                rows={3}
                                variant="outlined"
                                className={clases.formAllW}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                error={error}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            );
                          }
                          if (tipoCampo === "LISTA") {
                            return (
                              <TextField
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                error={error}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                select
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ displayEmpty: true }}
                              >
                                <MenuItem value="">Seleccione.</MenuItem>
                                {opcionesLista.map((item) => {
                                  return (
                                    <MenuItem value={item}>{item} </MenuItem>
                                  );
                                })}
                              </TextField>
                            );
                          }
                          if (tipoCampo === "MULTIPLE LISTA") {
                            return (
                              <TextField
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                select
                                error={error}
                                MenuProps={MenuProps}
                                SelectProps={{
                                  displayEmpty: true,
                                  multiple: true,
                                  renderValue: (selected) => {
                                    return selected
                                      .map((value, index) => {
                                        return value;
                                      })
                                      .join(", ");
                                  },
                                }}
                              >
                                <MenuItem disabled value="">
                                  Seleccione.
                                </MenuItem>
                                {opcionesLista.map((item) => {
                                  return (
                                    <MenuItem
                                      style={getStylesMenuItems(
                                        item,
                                        value,
                                        theme
                                      )}
                                      value={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                            );
                          }
                          if (tipoCampo === "PUNTAJE") {
                            return (
                              <TextField
                                className={clases.formD}
                                size="small"
                                label={pregunta}
                                value={value}
                                name={propiedad}
                                error={error}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                select
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ displayEmpty: true }}
                              >
                                <MenuItem value="">Seleccione.</MenuItem>
                                {opcionesLista.map((item) => {
                                  return (
                                    <MenuItem value={item}>{item} </MenuItem>
                                  );
                                })}
                              </TextField>
                            );
                          }
                          return (
                            <TextField
                              className={clases.formD}
                              size="small"
                              label={pregunta}
                              value={value}
                              name={propiedad}
                              error={error}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          );
                        }
                      )
                  : null}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  {encuestasSeccion && subcategoriaSeccionencuesta ? (
                    <Button
                      className={clases.formD}
                      variant="contained"
                      color="primary"
                      disabled={
                        dataLocal[subcategoriaSeccionencuesta].finalizado ||
                        dataLocal[subcategoriaSeccionencuesta].docEstado ===
                          "PROCESADO"
                      }
                      onClick={() => {
                        handleGuardar();
                      }}
                    >
                      {dataLocal[subcategoriaSeccionencuesta].finalizado
                        ? "El formulario ya fue enviado"
                        : "Guardar"}
                    </Button>
                  ) : null}
                  {evaluacionesSeccion && subcategoriaSeccionevaluacion ? (
                    <Button
                      className={clases.formD}
                      variant="contained"
                      color="primary"
                      disabled={
                        dataLocal[subcategoriaSeccionevaluacion].finalizado ||
                        dataLocal[subcategoriaSeccionevaluacion].docEstado ===
                          "PROCESADO"
                      }
                      onClick={() => {
                        handleGuardar();
                      }}
                    >
                      {dataLocal[subcategoriaSeccionevaluacion].docEstado} -
                      {dataLocal[subcategoriaSeccionevaluacion].finalizado
                        ? "El formulario ya fue enviado"
                        : "Guardar"}
                    </Button>
                  ) : null}
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
