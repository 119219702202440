import { FechaExacta } from "../../config/const";

export const columns = [
  {
    id: "nomFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "nomDocuPersona",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomFotos",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomFotos",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomMotivo",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomMotivo",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomDetalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDetalle",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomEstado",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomFecha",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomFecha",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomUser",
    align: "center",
    ocultar: true,
  },
];

let date = new Date();
date.setDate(date.getDate() + 15);
date = [date.toISOString().substring(0, 10)];

export const objectDefault = {
  //   nomId: 11,
  // nomTipo: "AVISO SALIDA", // interno
  nomIdPersona: "",
  nomDocuPersona: "",
  nomNomPersona: "",
  nomMotivo: "RENUNCIA",
  nomDetalle: "",
  nomEstado: "EN PROCESO",
  nomFecha: FechaExacta,
  nomFotos: [],
  nomVideos: [],
  nomFechas: date,
  nomUser: "",
  nomEmpresa: "",
  nomFecReg: "",
  Todos: "",
  nomStatusDescripcion: "",
  nomPerfil: "GUARDIA", // val del select, defecto GUARDIA
  nomTipo: "TERMINACION", // val del select, defecto TERMINACION
  nomPerId: 0, // id del personal registrado defecto 0
  nomArchivo1: "",
  nomArchivo2: "", 
  nomArchivoSicosep: "",
  nomCv: "",
};
