import React, { memo, useState, useContext } from "react";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Autocomplete,
} from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { setagregar, setitem, item } = props;
  const [clientesData, setclientesData] = useState([]);
  const [datosOperativosData, setdatosOperativosData] = useState([]);
  const [itemRow, setItemRow] = useState({
    ruccliente: "",
    razonsocial: "",
    ubicacion: "",
    puesto: "",
  });
  const botonDisabled = () => {
    if (
      itemRow.ruccliente === "" ||
      itemRow.razonsocial === "" ||
      itemRow.ubicacion === "" ||
      itemRow.puesto === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
   

  const handleAgregar = async () => {
    try {
      const perPuestoServicio = [
        {
          ...itemRow,
        },
        ...item.perPuestoServicio,
      ];

      setitem({
        ...item,
        perPuestoServicio,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  ruccliente: "",
                  razonsocial: "",
                  ubicacion: "",
                  puesto: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  ruccliente: "",
                  razonsocial: "",
                  ubicacion: "",
                  puesto: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <>
          {" "}
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <FormControl fullWidth>
              <Autocomplete
                disableClearable={true}
                options={clientesData.map((item) => {
                  return item.cliDocNumero + "/+/" + item.cliRazonSocial;
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ");
                }}
                id="controlled-demo"
                value={itemRow.ruccliente}
                paperprops
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const options = newValue.split("/+/");

                    setItemRow({
                      ...itemRow,
                      ruccliente: options[0],
                      razonsocial: options[1],
                      ubicacion: "",
                      puesto: "",
                    });

                    const clienteF = clientesData.find(
                      (clienteA) => clienteA.cliDocNumero === options[0]
                    );

                    setdatosOperativosData(clienteF.cliDatosOperativos);
                  } else {
                    setItemRow({
                      ruccliente: "",
                      razonsocial: "",
                      ubicacion: "",
                      puesto: "",
                    });
                    setclientesData([]);
                    setdatosOperativosData([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      if (e.target.value === "") {
                        return mostrarAlerta("Ingrese datos", "error");
                      }
                      try {
                        if (e.target.value.length < 3) {
                          return;
                        }
                        const res = await clienteAxios.get(
                          `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                        );

                        setclientesData(res.data.data);
                      } catch (error) {
                        setItemRow({
                          ruccliente: "",
                          razonsocial: "",
                          ubicacion: "",
                          puesto: "",
                        });
                        setclientesData([]);
                        setdatosOperativosData([]);
                        return mostrarAlerta("No hay clientes", "error");
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              id=""
              disabled
              value={itemRow.razonsocial}
              onChange={(e) =>
                setItemRow({
                  ...itemRow,
                  razonsocial: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              id=""
              value={itemRow.ubicacion}
              onChange={(e) =>
                setItemRow({
                  ...itemRow,
                  ubicacion: e.target.value,
                })
              }
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {[
                ...new Set(
                  datosOperativosData.map((itemDato) =>
                    JSON.stringify({ ubicacion: itemDato.ubicacion })
                  )
                ),
              ]
                .map((item) => JSON.parse(item))
                .map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.ubicacion}>
                      {item.ubicacion}{" "}
                    </MenuItem>
                  );
                })}
            </TextField>
          </TableCell>
          <TableCell
            padding="none"
            size="small"
            align="center"
            className={classes.celda}
          >
            <TextField
              id=""
              value={itemRow.puesto}
              onChange={(e) =>
                setItemRow({
                  ...itemRow,
                  puesto: e.target.value,
                })
              }
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {datosOperativosData.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.puesto}>
                    {item.puesto}{" "}
                  </MenuItem>
                );
              })}
            </TextField>
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
