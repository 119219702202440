import React, { useState, useContext, useEffect, memo } from "react";
import Draggable from "react-draggable";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import { Autocomplete, Popper } from "@mui/material";
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { objectDefault } from "../data";
import { useModalStyle } from "../../styles/styleModal";
import TableDias from "./TableDias";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    desdeAusencia,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [personasData, setpersonasData] = useState([]);

  const PopperMy = function(props) {
    return <Popper {...props} placement="bottom-start" />;
  };
  // eslint-disable-next-line
  const {
    // turnId: 1,
    // turnIdPersona,
    // turnIdDOperativo, //DE HORARIOS
    // turnIdPermiso,
    // turnIdMulta,
    turnMotivo,
    turnEstado,
    turnAutorizado,
    turnDetalle,
    turnStatusDescripcion,
    // turnUser,
    // turnEmpresa,
    // turnFecReg,
    // turnFecUpd,
    cedula,
    nombres,
    // idCliente,
    ruc,
    razonSocial,
    // ubicacion, //DE HORARIOS
    // puesto, //DE HORARIOS
    // turnIdsJornadaLaboral,
    dias,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});

  useEffect(() => {
    setitem({
      ...ObjectoActivo,
      turnAutorizado:
        ObjectoActivo.turnAutorizado === ""
          ? item.turnAutorizado
          : ObjectoActivo.turnAutorizado,
    });

    // eslint-disable-next-line
  }, [open]);

  const defaultActive = () => {
    changeObjetoActivoState({
      ...objectDefault,
      turnAutorizado: item.turnAutorizado,
    });
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosPrincipales: true,
      Apelacion: false,
    });
    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
  // const compararFechas = (obj1, obj2) => {
  //   const desde1 = new Date(obj1.desde);
  //   const hasta1 = new Date(obj1.hasta);
  //   const desde2 = new Date(obj2.desde);
  //   const hasta2 = new Date(obj2.hasta);

  //   if (desde1 < hasta2 && hasta1 > desde2) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const validacion = () => {
    if (turnMotivo === "") {
      seterror({ turnMotivo: turnMotivo === "" });
      return true;
    } else {
      return false;
    }
  };
  const [seccionActive, setseccionActive] = useState({
    DatosPrincipales: true,
    DatosFechas: false,
    Apelacion: false,
  });
  // eslint-disable-next-line
  const { DatosPrincipales, DatosFechas, Apelacion } = seccionActive;
  // eslint-disable-next-line
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          // turDiasTurno: turFechasConsultaDB.length,
          turnIdsJornadaLaboral: dias.map((itemDia) => itemDia.id),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          turnUser: usuario.usuario,
          turnEmpresa: usuario.rucempresa,
          tabla: "turno_extra",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          // turDiasTurno: turFechasConsultaDB.length,
          turnIdsJornadaLaboral: dias.map((itemDia) => itemDia.id),
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          turnUser: usuario.usuario,
          turnEmpresa: usuario.rucempresa,
          tabla: "turno_extra",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    const getData = async () => {
      const resJefeOperaciones = await clienteAxios(
        "/personas/filtroporareadepartamento/0?search=&estado=JEFE DE OPERACIONES"
      );
      if (resJefeOperaciones.data.data.length === 0) {
      } else {
        setitem({
          ...item,
          turnAutorizado:
            resJefeOperaciones.data.data[0].perNombres +
            " " +
            resJefeOperaciones.data.data[0].perApellidos,
        });
      }
    };

    if (item.turnAutorizado === "") {
      getData();
    }
  }, [open]);
  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handleTurnoExtra">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handleTurnoExtra"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Turno Extra.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={DatosPrincipales ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color={
                      error.cedula ||
                      error.turnMotivo ||
                      error.ruc ||
                      error.turPuesto
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ DatosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={DatosFechas ? "contained" : "outlined"}
                    disableElevation
                    className={clases.buttonTab}
                    color={error.turFechasConsultaDB ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosFechas: true })}
                  >
                    Fechas
                  </Button>
                  {editar ? (
                    <Button
                      variant={Apelacion ? "contained" : "outlined"}
                      disableElevation
                      className={clases.buttonTab}
                      color="secondary"
                      onClick={() => setseccionActive({ Apelacion: true })}
                    >
                      Status
                    </Button>
                  ) : null}
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      conEstado === "ACTIVO" || conEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box className={clases.container}>
                {DatosPrincipales ? (
                  <>
                    {/* PERSONAS */}
                    <FormControl className={clases.formAllW}>
                      <Autocomplete
                        disableClearable={true}
                        options={personasData.map((item) => {
                          return (
                            item.perDocNumero +
                            "/+/" +
                            item.perApellidos +
                            " " +
                            item.perNombres +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={
                          (cedula + " " + nombres).trim() === ""
                            ? ""
                            : cedula + " " + nombres
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              const itemF = personasData.find((itemPersona) => {
                                return itemPersona.perId === Number(options[2]);
                              });

                              setitem({
                                ...item,
                                cedula: options[0],
                                nombres: options[1].trim(),
                                turnIdPersona: options[2],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Persona"
                            error={error.cedula}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                setpersonasData([]);
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/personas/filtro/0?search=${e.target.value}`
                                );

                                setpersonasData(res.data.data);
                              } catch (error) {
                                setitem({
                                  ...item,
                                  cedula: "",
                                  nombres: "",
                                  turnIdPersona: "",
                                  camActualPuesto: [
                                    {
                                      ruccliente: "",
                                      razonsocial: "",
                                      ubicacion: "",
                                      puesto: "",
                                    },
                                  ],
                                });
                                setpersonasData([]);

                                return mostrarAlerta(
                                  "No hay personas",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* CLIENTES */}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={
                          (razonSocial + " " + ruc).trim() === ""
                            ? ""
                            : razonSocial + " " + ruc
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              const itemF = clientesData.find((itemPersona) => {
                                return itemPersona.cliId === Number(options[2]);
                              });

                              setitem({
                                ...item,
                                idCliente: options[2],
                                ruc: options[1],
                                razonSocial: options[0],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Lugar de Destino Cliente"
                            error={error.ruc}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {
                                setitem({
                                  ...item,
                                  idCliente: "",
                                  ruc: "",
                                  razonSocial: "",
                                  turPuesto: [
                                    {
                                      ubicacion: "",
                                      puesto: "",
                                      id: "",
                                    },
                                  ],
                                });
                                setclientesData([]);

                                return mostrarAlerta(
                                  "No hay clientes",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* PUESTOS NUEVOS */}

                    <Autocomplete
                      disabled={editar}
                      className={clases.formD}
                      PopperComponent={PopperMy}
                      disableClearable={true}
                      options={
                        desdeAusencia
                          ? [
                              "EVENTO ESPECIAL",
                              "ENFERMEDAD IESS",
                              "PERMISO PERSONAL",
                              "PERMISO MEDICO",
                              "PATERNIDAD",
                              "DEFUNCION FAMILIAR",
                              "INJUSTIFICADA",
                            ].map((item) => item)
                          : ["EVENTO ESPECIAL"].map((item) => item)
                      }
                      getOptionLabel={(option) => {
                        return option;
                      }}
                      id="controlled-demo"
                      value={turnMotivo}
                      paperprops
                      onChange={async (event, newValue) => {
                        if (newValue) {
                          setitem({ ...item, turnMotivo: newValue });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Motivo"
                          fullWidth
                          error={error.turnMotivo}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Autorizado por"
                      value={turnAutorizado}
                      name="turnAutorizado"
                      error={error.turnAutorizado}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      label="Detalle"
                      value={turnDetalle}
                      name="turnDetalle"
                      error={error.turnDetalle}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}

                {DatosFechas ? (
                  <>
                    <TableDias dias={dias} />
                  </>
                ) : null}

                {Apelacion ? (
                  <>
                    <Box
                      className={clases.formAllW}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Divider style={{ width: "42%" }} />
                      <Typography
                        style={{ width: "100%" }}
                        variant="button"
                        color="primary"
                        align="center"
                        component={"span"}
                      >
                        Verificación
                      </Typography>
                      <Divider style={{ width: "42%" }} />
                    </Box>
                    <TextField
                      className={clases.formAllW}
                      size="small"
                      id="turnEstado"
                      label="Estado"
                      value={turnEstado}
                      name="turnEstado"
                      error={error.turnEstado}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["EN PROCESO", "APROBADO", "ANULADA"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      id="turnStatusDescripcion"
                      label="Status"
                      value={turnStatusDescripcion}
                      name="turnStatusDescripcion"
                      error={error.turnStatusDescripcion}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    cargando ||
                    ((ObjectoActivo.turnEstado === "ANULADA" ||
                      ObjectoActivo.turnEstado === "APROBADO") &&
                      editar &&
                      !usuario.rol.includes("ADMIN"))
                  }
                  onClick={() => {
                    if (editar) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
