import React, { memo, useContext } from "react";

import { Collapse, TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import Hijo from "./Hijo/Hijo";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // KEY
    // key,
    index,
    // EDIT Y OPEN
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // DATA
    rowPadre,
    estadoRequest,
    // FUNCIONES DATA
    getDataState,
    changeObjetoActivoState,
    // SOCKET
    tabla,
    socket,
    idRegistro,
    notificacion,
    parametrosUtilizados,
    setparametrosUtilizados,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowPadre.editado === true
      ? classes.celdaEditado
      : rowPadre.nuevo === true
      ? classes.celdaNuevo
      : rowPadre.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [cargado, setcargado] = useState(false);
  const [expandir, setexpandir] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        actaUser: usuario.usuario,
        actaEmpresa: usuario.rucempresa,
        [campoExport]: rowPadre[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          agregarCelda={false}
          row={rowPadre}
          botones={[
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setcargado(true);
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowPadre.actaTipo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowPadre.actaFecReg.substr(0, 7)}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowPadre.tregistros}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell
          className={claseCelda}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
        >
          <Collapse in={expandir} timeout="auto">
            {cargado ? (
              <Hijo
                idRegistro={idRegistro}
                notificacion={notificacion}
                // ROW
                rowPadre={rowPadre}
                // OPEN Y EDIT
                setopen={setopen}
                seteditar={seteditar}
                // EXPORT
                campoExport={campoExport}
                arrayExport={arrayExport}
                setarrayExport={setarrayExport}
                // DATA
                expandir={expandir}
                tabla={tabla}
                estadoRequest={estadoRequest}
                // FUNCION STATE
                getDataState={getDataState}
                changeObjetoActivoState={changeObjetoActivoState}
                // SOCKET
                socket={socket}
                parametrosUtilizados={parametrosUtilizados}
                setparametrosUtilizados={setparametrosUtilizados}
              />
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
