import React, { useContext, useEffect, useState } from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { Exposure } from "@mui/icons-material";
import AlertaContext from "../../Context/Alerta/AlertaContext";

const useStyles = makeStyles((theme) => ({
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));

const CamposBusqueda = (props) => {
  const { campoObjectItem, handleChange, fullBuscador } = props;
  const clases = useStyles();
  const [expandirCampo, setexpandirCampo] = useState(false);
  const [data, setdata] = useState(null);
  const [dataDinamica, setdataDinamica] = useState([]);
  const { mostrarAlerta } = useContext(AlertaContext);
  useEffect(() => {
    if (campoObjectItem.variante === "autocompleteDataDefault" && !data) {
      const getData = async () => {
        const res = await campoObjectItem.getData(data);

        setdata(res.data.data);
      };
      getData();
    }
    // eslint-disable-next-line
  }, []);

  if (campoObjectItem.variante === "autocompleteDinamico") {
    return (
      <>
        {" "}
        <Box className={clases.formAllW}></Box>
        <FormControl className={clases.formD}>
          <Autocomplete
            options={dataDinamica.map((item) => {
              return campoObjectItem.propiedadesMostrar === 1
                ? item[campoObjectItem.campoData1]
                : item[campoObjectItem.campoData1] +
                    "/+/" +
                    item[campoObjectItem.campoData2];
            })}
            getOptionLabel={(option) => {
              return campoObjectItem.propiedadesMostrar === 1
                ? option
                : option.replace("/+/", " ");
            }}
            id="controlled-demo"
            value={fullBuscador[`${campoObjectItem.propiedad}`]}
            name={`${[campoObjectItem.propiedad]}`}
            paperprops
            onChange={async (event, newValue) => {
              if (newValue === "") {
                return;
              }
              handleChange({
                target: {
                  name: `${[campoObjectItem.propiedad]}`,
                  value: newValue.split("/+/")[0],
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                required
                onChange={async (e) => {
                  if (e.target.value === "") {
                    return mostrarAlerta("Ingrese un documento", "error");
                  }
                  try {
                    handleChange({
                      target: {
                        name: `${[campoObjectItem.propiedad]}`,
                        value: e.target.value,
                      },
                    });
                    const res = await campoObjectItem.getData(e.target.value);

                    if (!res.data.data) {
                      return mostrarAlerta(res.data.msg, "error");
                    }

                    setdataDinamica(res.data.data);
                  } catch (error) {}
                }}
                label={`${campoObjectItem.label}`}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton
                  //       size="small"

                  //       aria-label=""
                  //       onClick={async () => {
                  //         // seteditarProveedor(false);
                  //         setOpenProveedor(true);
                  //       }}
                  //     >
                  //       <AddCircleOutlineTwoTone color="primary" />
                  //     </IconButton>
                  //     {params.InputProps.endAdornment}
                  //   </React.Fragment>
                  // ),
                }}
              />
            )}
          />
        </FormControl>
      </>
    );
  }
  if (campoObjectItem.variante === "autocompleteComun") {
    return (
      <>
        <Box className={clases.formAllW}></Box>
        <FormControl className={clases.formD}>
          <Autocomplete
            options={campoObjectItem.data.map((item) => {
              return item;
            })}
            getOptionLabel={(option) => {
              return option;
            }}
            id="controlled-demo"
            value={fullBuscador[`${campoObjectItem.propiedad}`]}
            paperprops
            onChange={async (event, newValue) => {
              handleChange({
                target: {
                  name: `${[campoObjectItem.propiedad]}`,
                  value: newValue,
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                required
                label={`${campoObjectItem.label}`}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton
                  //       size="small"

                  //       aria-label=""
                  //       onClick={async () => {
                  //         // seteditarProveedor(false);
                  //         setOpenProveedor(true);
                  //       }}
                  //     >
                  //       <AddCircleOutlineTwoTone color="primary" />
                  //     </IconButton>
                  //     {params.InputProps.endAdornment}
                  //   </React.Fragment>
                  // ),
                }}
              />
            )}
          />
        </FormControl>
      </>
    );
  }
  if (campoObjectItem.variante === "autocompleteDataDefault" && data) {
    return (
      <>
        <Box className={clases.formAllW}></Box>
        <FormControl className={clases.formD}>
          <Autocomplete
            options={data.map((item) => {
              return campoObjectItem.propiedadesMostrar === 1
                ? item[campoObjectItem.campoData1]
                : item[campoObjectItem.campoData1] +
                    "/+/" +
                    item[campoObjectItem.campoData2];
            })}
            getOptionLabel={(option) => {
              return option;
            }}
            id="controlled-demo"
            value={fullBuscador[`${campoObjectItem.propiedad}`]}
            name={`${[campoObjectItem.propiedad]}`}
            paperprops
            onChange={async (event, newValue) => {
              handleChange({
                target: {
                  name: `${[campoObjectItem.propiedad]}`,
                  value: newValue,
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                required
                label={`${campoObjectItem.label}`}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton
                  //       size="small"

                  //       aria-label=""
                  //       onClick={async () => {
                  //         // seteditarProveedor(false);
                  //         setOpenProveedor(true);
                  //       }}
                  //     >
                  //       <AddCircleOutlineTwoTone color="primary" />
                  //     </IconButton>
                  //     {params.InputProps.endAdornment}
                  //   </React.Fragment>
                  // ),
                }}
              />
            )}
          />
        </FormControl>
      </>
    );
  }
  return (
    <>
      <Box className={clases.formAllW}></Box>
      {campoObjectItem.tipo === "fecha" ? (
        <>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            className={clases.formD}
            label={`${campoObjectItem.label} ${expandirCampo ? "- Desde" : ""}`}
            value={fullBuscador[`${campoObjectItem.propiedad}1`]}
            name={`${[campoObjectItem.propiedad]}1`}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {!expandirCampo ? (
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(true);
                      }}
                    >
                      <Exposure color="primary" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />{" "}
          {expandirCampo ? (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="date"
              className={clases.formD}
              label={`${campoObjectItem.label} - Hasta`}
              value={fullBuscador[`${campoObjectItem.propiedad}2`]}
              name={`${[campoObjectItem.propiedad]}2`}
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(false);
                      }}
                    >
                      <Exposure color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
        </>
      ) : campoObjectItem.tipo === "string" ? (
        <>
          <TextField
            className={clases.formD}
            label={`${campoObjectItem.label}`}
            value={fullBuscador[`${campoObjectItem.propiedad}`]}
            name={`${[campoObjectItem.propiedad]}`}
            onChange={(e) => handleChange(e)}
          />
        </>
      ) : campoObjectItem.tipo === "numero" ? (
        <>
          <TextField
            type={"number"}
            className={clases.formD}
            label={`${campoObjectItem.label} ${expandirCampo ? "- Desde" : ""}`}
            value={fullBuscador[`${campoObjectItem.propiedad}1`]}
            name={`${[campoObjectItem.propiedad]}1`}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {!expandirCampo ? (
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(true);
                      }}
                    >
                      <Exposure color="primary" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />{" "}
          {expandirCampo ? (
            <TextField
              type={"number"}
              className={clases.formD}
              label={`${campoObjectItem.label} - Hasta`}
              value={fullBuscador[`${campoObjectItem.propiedad}2`]}
              name={`${[campoObjectItem.propiedad]}2`}
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(false);
                      }}
                    >
                      <Exposure color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
        </>
      ) : campoObjectItem.tipo === "hora" ? (
        <>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="time"
            className={clases.formD}
            label={`${campoObjectItem.label} ${expandirCampo ? "- Desde" : ""}`}
            value={fullBuscador[`${campoObjectItem.propiedad}1`]}
            name={`${[campoObjectItem.propiedad]}1`}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {!expandirCampo ? (
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(true);
                      }}
                    >
                      <Exposure color="primary" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />{" "}
          {expandirCampo ? (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="time"
              className={clases.formD}
              label={`${campoObjectItem.label} - Hasta`}
              value={fullBuscador[`${campoObjectItem.propiedad}2`]}
              name={`${[campoObjectItem.propiedad]}2`}
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(false);
                      }}
                    >
                      <Exposure color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default CamposBusqueda;
