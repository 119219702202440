export const columns = [
  {
    id: "iopCliId",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "iopCliId",
    align: "center",
    ocultar: true,
  },

  {
    id: "iopFecReg",
    label: "Periodo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "iopFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "tregistros",
    label: "T. Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "iopCliDocNumero",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "iopCliDocNumero",
    align: "left",
    ocultar: true,
  },
  {
    id: "iopAsunto",
    label: "Asunto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "iopAsunto",
    align: "center",
    ocultar: true,
  },
  {
    id: "iopDocumento",
    label: "Documento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "iopDocumento",
    align: "center",
    ocultar: true,
  },
  {
    id: "iopFotos",
    label: "Fotos",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "iopFotos",
    align: "center",
    ocultar: true,
  },
  {
    id: "iopUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "iopUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "iopFecReg",
    label: "FecReg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "iopFecReg",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // iopId: 1,
  iopCliId: "",
  iopCliDocNumero: "",
  iopCliRazonSocial: "",
  iopAsunto: "INFORME INICIAL DE OPERACIONES",
  iopDocumento: "",
  iopEmpresa: "",
  iopUser: "",
  iopFecReg: "",
  iopFecUpd: "",
  iopPrendas: [],
  iopVulnerabilidades: [],
  iopFotos: [],
  iopEstado: "ACTIVA",
};

export const itemPrendaDefault = {
  id: "",
  cantidad: "1",
  item: "",
  detalle: "",
};

export const itemVulnerabilidadDefault = {
  id: "",
  puesto: "",
  vulnerabilidad: "",
  foto: "",
};
