import React, { memo, useContext } from "react";

import { Collapse, TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import Hijo from "./Hijo/Hijo";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // KEY
    // key,
    index,
    // EDIT Y OPEN
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // DATA
    rowPadre,
    estadoRequest,
    subEstado,
    // FUNCIONES DATA
    getDataState,
    changeObjetoActivoState,
    // eliminarState,
    // SOCKET
    tabla,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowPadre.editado === true
      ? classes.celdaEditado
      : rowPadre.nuevo === true
      ? classes.celdaNuevo
      : rowPadre.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  // const { usuario } = useContext(AuthContext);
  // eslint-disable-next-line 
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [cargado, setcargado] = useState(false);
  const [expandir, setexpandir] = useState(false);
  // ELIMINAR EL REGISTRO

  if (rowPadre.eliminado) {
    return null;
  }
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          agregarCelda={false}
          row={rowPadre}
          botones={[
            {
              tooltip: "Expandir",
              texto: "",
              funcion: () => {
                setcargado(true);
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: rowPadre.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowPadre.regFecReg.substr(0, 7)}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowPadre.tregistros}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell
          className={claseCelda}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={21}
        >
          <Collapse in={expandir} timeout="auto">
            {cargado ? (
              <Hijo
                // ROW
                rowPadre={rowPadre}
                // OPEN Y EDIT
                setopen={setopen}
                seteditar={seteditar}
                // EXPORT
                campoExport={campoExport}
                arrayExport={arrayExport}
                setarrayExport={setarrayExport}
                // DATA
                expandir={expandir}
                tabla={tabla}
                estadoRequest={estadoRequest}
                subEstado={subEstado}
                // FUNCION STATE
                getDataState={getDataState}
                changeObjetoActivoState={changeObjetoActivoState}
                // SOCKET
                socket={socket}
              />
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
