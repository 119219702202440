import React from "react";
import { butonIconTransition, useRowStyles } from "../styles/stylesRows";
import { IconButton, TableCell } from "@mui/material";
import { Download } from "@mui/icons-material";

const DocumentoCelda = ({ propiedad, claseCelda, Icono }) => {
  const classes = useRowStyles();

  return (
    <TableCell
      sx={{ paddingLeft: "5px" }}
      padding="none"
      size="small"
      align="center"
      className={claseCelda ? claseCelda : classes.celda}
    >
      {propiedad === "" || !propiedad ? (
        "No hay documento"
      ) : (
        <IconButton
          sx={butonIconTransition}
          onClick={() => window.open(propiedad)}
        >
          {Icono ? Icono : <Download fontSize="small" color="primary" />}
        </IconButton>
      )}
    </TableCell>
  );
};

export default DocumentoCelda;
