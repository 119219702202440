import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";

import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import clienteAxios from "../../../../config/axios";

import { replaceCaracteres, trimPropsItem } from "../../../../config/const";
import Dropzone from "../../../components/Dropzone";
// MATERIAL
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  // MenuItem,
  FormControl,
  FormControlLabel,
  Switch,
  Button,
  Autocomplete,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Cancel, CollectionsBookmark } from "@mui/icons-material";
import { objectDefault } from "../data";
import CampoDoc from "../../../components/CampoDoc";
import TabsFechasPersonas from "./TabsFechasPersonas";
import { useModalStyle } from "../../../styles/styleModal";
import Icon from "react-multi-date-picker/components/icon";
import { Calendar } from "react-multi-date-picker";
import { compararFechasPorDia } from "../../../functions/funciones";
import Actividad from "./Actividad";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
import Calendario from "./Calendario";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  const [focusedDate, setFocusedDate] = useState("");

  const { mostrarAlerta } = useContext(AlertaContext);

  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();

  const [disData, setdisData] = useState([]);
  const [disId, setdisId] = useState("");
  const containerRef = React.useRef(null);

  const {
    // actId, //: 2,
    // actEveId, //: 0,
    actEveTipo, //: "",
    actEveDatos, //: {},
    actEstado, //: "ACTIVA",
    // actGuardias, //: [],
    // actSupervisores, //: [],
    // actAdministracion, //: [],
    // actFechasEvento,
    actFechasActividad, //: [],
    actFechasActividadConsultaDB, //: [],
    actObservacion, //: "",
    // actFecReg, //: "2022-08-23T18:01:57.000Z",
    // actFecUpd, //: "2023-05-09T16:57:00.000Z",
    actIdCliente, //: "",
    // actDocCliente, //: "",
    // actNombreCliente, //: "",
    // actDirigido, //: "",
    actFotosActividad, //: [],
    // actLeidos, //: [],
    actPrioridad, //: "MEDIA",
    // actDocumento, //: "",
    // actUser, //: "admin",
    // actEmpresa, //: "PRUEBA",
    actNombreCliente,
    actRequerimientosActividad,
  } = item;
  const [fechasEventosGuardias, setfechasEventosGuardias] = useState({});
  const [fechasEventosSupervisores, setfechasEventosSupervisores] = useState(
    {}
  );
  const [
    fechasEventosAdministracion,
    setfechasEventosAdministracion,
  ] = useState({});
  const [error, seterror] = useState({});
  const [tiposData, settiposData] = useState(null);
  const [eventosData, seteventosData] = useState([]);
  const [diasSinPersonas, setdiasSinPersonas] = useState([]);

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const [seccionActive, setseccionActive] = useState({
    DatosGenerales: true,
    DatosArchivos: false,
    DatosOtros: false,
  });

  const { DatosGenerales, DatosArchivos, DatosOtros } = seccionActive;
  const [openDialogFechas, setopenDialogFechas] = useState(false);
  // const [openMap, setopenMap] = useState(false);
  const [supervisoresData, setsupervisoresData] = useState(null);
  const [administracionData, setadministracionData] = useState(null);
  const [guardiasData, setguardiasData] = useState([]);
  const setitemsPersonas = (guardias) => {
    return guardias.map((itemGuardia) => {
      return {
        perFoto: itemGuardia.perFoto,
        perApellidos: itemGuardia.perApellidos,
        perNombres: itemGuardia.perNombres,
        perDocNumero: itemGuardia.perDocNumero,
        perId: itemGuardia.perId,
        perPerfil: itemGuardia.perPerfil,
        perTurnos: itemGuardia.perTurno.map(
          (itemTurno) => itemTurno.fechasConsultaDB
        ),
        trabajos: [],
        leido: false,
      };
    });
  };
  useEffect(() => {
    if (open && ObjectoActivo.actNombreCliente !== "") {
      const getresguardiasData = async () => {
        try {
          // const itemF = await clienteAxios.get(
          //   `/clientes/filtro/0?search=${ObjectoActivo.actNombreCliente}&estado=CLIENTES`
          // );
          const res = await clienteAxios.get(
            `/personas/listado/:codigo?docnumero=${ObjectoActivo.actNombreCliente}`
          );
          setguardiasData(setitemsPersonas(res.data.data));
        } catch (error) {
          setguardiasData([]);
        }
      };

      getresguardiasData();
    }
    setfechasEventosGuardias(ObjectoActivo.actGuardias);
    setfechasEventosSupervisores(ObjectoActivo.actSupervisores);
    setfechasEventosAdministracion(ObjectoActivo.actAdministracion);
    setitem(ObjectoActivo);
  }, [ObjectoActivo, open]);
  useEffect(() => {
    const obteneNovedades = async () => {
      try {
        const resNovedades = await clienteAxios.get(
          "/novedades/filtro/0?search&origen=ACTIVIDAD"
        );

        settiposData(resNovedades.data.data);
      } catch (error) {
        settiposData([]);
      }
    };
    if (!tiposData) {
      obteneNovedades();
    }
    const getDataadministracionData = async () => {
      const resadministracionData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=ADMINISTRACION"
      );

      setadministracionData(setitemsPersonas(resadministracionData.data.data));
    };
    if (!administracionData) {
      getDataadministracionData();
    }
    const getDatasupervisoresData = async () => {
      const ressupervisoresData = await clienteAxios.get(
        "/personas/filtro/0?search&estado=SUPERVISORES"
      );

      setsupervisoresData(
        setitemsPersonas(ressupervisoresData.data.data, true)
      );
    };
    if (!supervisoresData) {
      getDatasupervisoresData();
    }
    // eslint-disable-next-line
  }, []);
  const defaultActive = async () => {
    setFocusedDate("");
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setseccionActive({
      DatosGenerales: true,
      DatosArchivos: false,
      DatosOtros: false,
    });
    setcargando(false);
    setimagenes([]);
    setmodificoImagenes(false);
    setguardiasData([]);
    setfechasEventosGuardias({});
    setfechasEventosSupervisores({});
    setfechasEventosAdministracion({});
    setdiasSinPersonas([]);
  };

  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  const validacion = (guardias, supervisores, administracion) => {
    if (
      (guardias.length === 0 &&
        supervisores.length === 0 &&
        administracion.length === 0) ||
      ((actEveTipo === "INVENTARIO INTERNO" ||
        actEveTipo === "INVENTARIO EXTERNO") &&
        disId === "") ||
      actObservacion.trim() === ""
    ) {
      seterror({
        guardias:
          guardias.length === 0 &&
          supervisores.length === 0 &&
          administracion.length === 0,
        supervisores:
          guardias.length === 0 &&
          supervisores.length === 0 &&
          administracion.length === 0,
        administracion:
          guardias.length === 0 &&
          supervisores.length === 0 &&
          administracion.length === 0,
        actFechasActividadConsultaDB:
          actFechasActividadConsultaDB.length === 0 ? true : false,
        disId:
          (actEveTipo === "INVENTARIO INTERNO" ||
            actEveTipo === "INVENTARIO EXTERNO") &&
          disId === "",
        actObservacion: actObservacion.trim() === "",
      });
      return true;
    } else {
      return false;
    }
  };
  const formatoPersonas = (fechasEventoPersonas, guardias) => {
    let personas = [];
    Object.entries(fechasEventoPersonas).forEach((itemPersonas) => {
      const horariosEvento = actEveDatos.eveActividades.flatMap(
        (actividadItem) => {
          return actividadItem.horario.map((horarioItem) => {
            return {
              nombreEvento: actividadItem.nombre,
              observacion: horarioItem.observacion,
              hora: horarioItem.hora,
              horaFinal: horarioItem.horaFinal,
              eliminado: horarioItem.eliminado,
              id: horarioItem.id,
            };
          });
        }
      );
      itemPersonas[1].forEach((itemPersona) => {
        const persona = {
          perId: itemPersona.perId,
          perPerfil: itemPersona.perPerfil,
          perTurnos: itemPersona.perTurnos,
          fecha: itemPersonas[0],
          eveId: actEveDatos.eveId,
          cliId: actIdCliente,
          eveTipo: actEveDatos.eveTipo,
          eveCliPuesto: actEveDatos.eveCliPuesto,
          horariosEvento,
          disId,
        };
        personas.push(persona);
      });
    });
    return personas;
  };
  const handleCrear = async () => {
    const guardias = formatoPersonas(fechasEventosGuardias);

    const supervisores = formatoPersonas(fechasEventosSupervisores);

    const administracion = formatoPersonas(fechasEventosAdministracion);

    if (validacion(guardias, supervisores, administracion)) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, actFotosActividad);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          actGuardias: fechasEventosGuardias,
          actSupervisores: fechasEventosSupervisores,
          actAdministracion: fechasEventosAdministracion,
          actFotosActividad: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actUser: usuario.usuario,
          actEmpresa: usuario.rucempresa,
          tabla: "actividad",
          personasAsignadas: [...guardias, ...supervisores, ...administracion],
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      console.log({ error });
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, actFotosActividad);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          actFotosActividad: resDocumentos,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actUser: usuario.usuario,
          actEmpresa: usuario.rucempresa,
          tabla: "actividad",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  if (!open) {
    return null;
  }
  if (!supervisoresData) {
    return null;
  }
  if (!tiposData) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} Actividad.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={DatosGenerales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Generales
                  </Button>
                  <Button
                    variant={
                      DatosOtros
                        ? "contained"
                        : error.actObservacion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.actObservacion ? "error" : "secondary"}
                    onClick={() => setseccionActive({ DatosOtros: true })}
                  >
                    Tarea
                  </Button>

                  <Button
                    variant={DatosArchivos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosArchivos: true })}
                  >
                    Datos Archivos
                  </Button>
                </Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        actEstado === "ACTIVO" || actEstado === "ACTIVA"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setitem({
                          ...item,
                          actEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                        });
                      }}
                      name="antoine"
                      color="secondary"
                    />
                  }
                  label="Estado"
                />
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                borderRadius="0px 50px 50px 0px"
                p={1}
                ref={containerRef}
              >
                <Fade
                  direction="up"
                  in={DatosGenerales}
                  style={{ width: "100%" }}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                    {DatosGenerales ? (
                      <>
                        <TextField
                          className={clases.formD}
                          size="small"
                          id="actPrioridad"
                          label="Prioridad"
                          value={actPrioridad}
                          name="actPrioridad"
                          error={error.actPrioridad}
                          onChange={(e) => handleChange(e)}
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {["BAJA", "MEDIA", "ALTA"].map((item) => {
                            return <MenuItem value={item}>{item} </MenuItem>;
                          })}
                        </TextField>
                        <TextField
                          className={clases.formD}
                          value={actEveTipo}
                          name="actEveTipo"
                          label="Tipo Evento"
                          onChange={async (e) => {
                            setitem((prev) => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                              actEveDatos: {
                                Todos: "",
                                eveActividades: [],
                                eveEmpresa: "",
                                eveNombre: "",
                                eveTipo: "",
                                eveUser: "",
                                eveCliId: "",
                                eveCliDocumento: "",
                                eveCliNombre: "",
                                eveCliUbicacion: "",
                                eveCliPuesto: "",
                                evePerfil: "",
                              },
                              actIdCliente: "",
                              actDocCliente: "",
                              actNombreCliente: "",
                              actRequerimientosActividad: {
                                ...item.actRequerimientosActividad,
                                qr:
                                  e.target.value === "RONDAS"
                                    ? "SI"
                                    : item.actRequerimientosActividad.qr,
                              },
                            }));
                            try {
                              const res = await clienteAxios.get(
                                `/eventoactividades/filtro/0?tipo=${e.target.value}`
                              );
                              seteventosData(res.data.data);
                            } catch (error) {
                              setitem({
                                ...item,
                              });
                              seteventosData([]);

                              return mostrarAlerta("No hay eventos", "error");
                            }
                          }}
                          size="small"
                          fullWidth
                          select
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="">Seleccione.</MenuItem>
                          {tiposData.map((item) => {
                            return (
                              <MenuItem key={item.novId} value={item.novTipo}>
                                {item.novTipo}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        {openDialogFechas ? (
                          <Dialog
                            fullWidth={true}
                            maxWidth="md"
                            open={openDialogFechas}
                            onClose={() => setopenDialogFechas(false)}
                            aria-labelledby={"FECHAS"}
                          >
                            <DialogTitle fontWeight={"bold"}>
                              <Box
                                display="flex"
                                justifyContent={"space-between"}
                                width={"100%"}
                              >
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  fontWeight={"bold"}
                                >
                                  {actEveDatos.eveNombre +
                                    " - " +
                                    actEveDatos.eveCliUbicacion +
                                    " - " +
                                    actEveDatos.eveCliPuesto}
                                </Typography>
                                <Box ml={1} mr={1}></Box>
                                <Typography
                                  variant="body1"
                                  color="initial"
                                  fontWeight={"bold"}
                                >
                                  {actEveDatos.eveCliDocumento +
                                    " " +
                                    actEveDatos.eveCliNombre}
                                </Typography>
                              </Box>
                            </DialogTitle>
                            <>
                              {/* <DialogContent dividers>
                                <DialogContentText>
                                  <Box
                                    display="flex"
                                    justifyContent={"space-around"}
                                    width={"100%"}
                                  >
                                    <Typography
                                      variant="body1"
                                      color="initial"
                                      fontWeight={"bold"}
                                    >
                                      Desde
                                    </Typography>
                                    <Box ml={1} mr={1}></Box>
                                    <Typography
                                      variant="body1"
                                      color="initial"
                                      fontWeight={"bold"}
                                    >
                                      Hasta
                                    </Typography>
                                  </Box>
                                </DialogContentText>
                              </DialogContent>  */}
                              {actEveDatos.eveActividades.map(
                                (eveActividad) => (
                                  <Actividad
                                    setitem={setitem}
                                    eveActividad={eveActividad}
                                    actEveDatos={actEveDatos}
                                  />
                                )
                              )}
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Divider
                                  sx={{ maxWidth: "40%", width: "33%" }}
                                />
                                <Typography
                                  variant="subtitle1"
                                  color="error"
                                  display={"inline-block"}
                                >
                                  Eliminadas
                                </Typography>
                                <Divider
                                  sx={{ maxWidth: "40%", width: "33%" }}
                                />
                              </Box>
                              {actEveDatos.eveActividadesEliminadas &&
                                actEveDatos.eveActividadesEliminadas.map(
                                  (eveActividad) => (
                                    <Actividad
                                      eliminado
                                      setitem={setitem}
                                      eveActividad={eveActividad}
                                      actEveDatos={actEveDatos}
                                    />
                                  )
                                )}
                            </>
                            <DialogActions>
                              <Button
                                onClick={() => setopenDialogFechas(false)}
                                color="primary"
                              >
                                LISTO
                              </Button>
                            </DialogActions>
                          </Dialog>
                        ) : null}
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            disabled={actEveTipo === ""}
                            disableClearable={true}
                            options={eventosData.map((item) => {
                              return (
                                item.eveNombre +
                                "/+/" +
                                item.eveCliNombre +
                                "/+/" +
                                item.eveCliPuesto +
                                "/+/" +
                                item.evePerfil +
                                "/+/" +
                                item.eveId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option
                                .replace("/+/", " - ")
                                .replace("/+/", " - ")
                                .replace("/+/", " - ")
                                .split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={
                              (
                                actEveDatos.eveNombre +
                                " " +
                                actNombreCliente +
                                " " +
                                actEveDatos.eveCliPuesto +
                                " " +
                                actEveDatos.evePerfil
                              ).trim() === ""
                                ? ""
                                : actEveDatos.eveNombre +
                                  " - " +
                                  actNombreCliente +
                                  " - " +
                                  actEveDatos.eveCliPuesto +
                                  " - " +
                                  actEveDatos.evePerfil
                            }
                            paperprops
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                try {
                                  const itemF = eventosData.find(
                                    (itemEvento) =>
                                      itemEvento.eveId ===
                                      Number(newValue.split("/+/")[4])
                                  );
                                  if (!itemF) {
                                    return;
                                  }
                                  if (itemF.evePerfil === "SUPERVISOR") {
                                    setitem({
                                      ...item,
                                      actEveDatos: itemF,
                                      actIdCliente: itemF.eveCliId,
                                      actDocCliente: itemF.eveCliDocumento,
                                      actNombreCliente: itemF.eveCliNombre,
                                    });
                                  } else {
                                    const res = await clienteAxios.get(
                                      `/personas/byClienteUbicacionPuesto/0?idCliente=${itemF.eveCliId}&ubicacion=${itemF.eveCliUbicacion}&puesto=${itemF.eveCliPuesto}`
                                    );
                                    setguardiasData(
                                      setitemsPersonas(res.data.data)
                                    );
                                    const resDis = await clienteAxios(
                                      `distribucion/bycliente/0?idcliente=${itemF.eveCliId}&puesto=${itemF.eveCliPuesto}`
                                    );
                                    setdisData(resDis.data.data);
                                    setitem({
                                      ...item,
                                      actEveDatos: itemF,
                                      actIdCliente: itemF.eveCliId,
                                      actDocCliente: itemF.eveCliDocumento,
                                      actNombreCliente: itemF.eveCliNombre,
                                    });
                                  }
                                } catch (error) {
                                  mostrarAlerta(
                                    error?.response?.data?.msg,
                                    "error"
                                  );
                                  console.log({ error });
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                required
                                fullWidth
                                error={error.eveNombre}
                                label="Evento"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      <IconButton
                                        aria-label="DETALLES EVENTO"
                                        disabled={actEveDatos.eveNombre === ""}
                                        onClick={() => {
                                          setopenDialogFechas(true);
                                        }}
                                      >
                                        <CollectionsBookmark
                                          color={
                                            actEveDatos.eveNombre === ""
                                              ? "inherit"
                                              : "secondary"
                                          }
                                        />
                                      </IconButton>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>

                        {actEveDatos.evePerfil === "SUPERVISOR" && (
                          <FormControl className={clases.formD}>
                            <Autocomplete
                              disabled={actEveTipo === ""}
                              disableClearable={true}
                              options={Array(
                                ...new Set(
                                  actEveDatos.eveActividades.map((item) => {
                                    return item.puesto + "/+/" + item.ubicacion;
                                  })
                                )
                              )}
                              getOptionLabel={(option) => {
                                return option.replace("/+/", " - ");
                              }}
                              id="controlled-demo"
                              value={
                                (
                                  actEveDatos.eveCliPuesto +
                                  " " +
                                  actEveDatos.eveCliUbicacion
                                ).trim() === ""
                                  ? ""
                                  : actEveDatos.eveCliPuesto +
                                    " - " +
                                    actEveDatos.eveCliUbicacion
                              }
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  try {
                                    const [puesto, ubicacion] = newValue.split(
                                      "/+/"
                                    );
                                    const res = await clienteAxios.get(
                                      `/personas/byClienteUbicacionPuesto/0?idCliente=${actEveDatos.eveCliId}&ubicacion=${ubicacion}&puesto=${puesto}`
                                    );
                                    setguardiasData(
                                      setitemsPersonas(res.data.data)
                                    );
                                    const resDis = await clienteAxios(
                                      `distribucion/bycliente/0?idcliente=${actEveDatos.eveCliId}&puesto=${puesto}`
                                    );
                                    setdisData(resDis.data.data);
                                    setitem({
                                      ...item,
                                      actEveDatos: {
                                        ...actEveDatos,
                                        eveCliPuesto: puesto,
                                        eveCliUbicacion: ubicacion,
                                      },
                                    });
                                  } catch (error) {
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                    console.log({ error });
                                  }
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  required
                                  fullWidth
                                  error={error.eveNombre}
                                  label="Puesto - Ubicación"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment></React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        )}

                        {(actEveTipo === "INVENTARIO INTERNO" ||
                          actEveTipo === "INVENTARIO EXTERNO") && (
                          <FormControl className={clases.formD}>
                            <Autocomplete
                              disableClearable={true}
                              options={disData.map((item) => {
                                return item.disId;
                              })}
                              getOptionLabel={(option) => {
                                return option;
                              }}
                              id="controlled-demo"
                              value={disId}
                              paperprops
                              onChange={async (event, newValue) => {
                                if (newValue) {
                                  setdisId(newValue);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  required
                                  fullWidth
                                  error={error.disId}
                                  label="Seleccione Distribución"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        )}
                        {actEveDatos.eveNombre === "" ? (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            className={clases.formAllW}
                          >
                            <Typography
                              variant="overline"
                              color="textPrimary"
                              align="center"
                            >
                              Seleccione un evento para mostrar el calendario
                            </Typography>
                            <Icon>
                              <Calendar />
                            </Icon>
                          </Box>
                        ) : (
                          <Box
                            className={clases.formAllW}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography
                              maxWidth={"25%"}
                              variant="body1"
                              fontSize={".7rem"}
                              color="secondary"
                              textAlign={"center"}
                            >
                              Seleccione cada una de las fechas para verificar
                              personal disponible
                            </Typography>
                            <Calendario
                              calendarios={2}
                              diasSinPersonas={diasSinPersonas}
                              readOnly={editar}
                              propiedad={actFechasActividad}
                              propiedadDB={actFechasActividadConsultaDB}
                              focusedDate={focusedDate}
                              setFocusedDate={(e, prev) => {
                                const anterior = new Date(
                                  e - new Date().getTimezoneOffset() * 60 * 1000
                                );

                                if (
                                  compararFechasPorDia(anterior, Date.now())
                                ) {
                                  return;
                                }
                                const keye = e
                                  ? new Date(
                                      e -
                                        new Date().getTimezoneOffset() *
                                          60 *
                                          1000
                                    )
                                      .toISOString()
                                      .substring(0, 10)
                                  : "";

                                if (
                                  !fechasEventosGuardias[keye] &&
                                  keye !== ""
                                ) {
                                  setfechasEventosGuardias({
                                    ...fechasEventosGuardias,
                                    [keye]: [],
                                  });
                                  setfechasEventosSupervisores({
                                    ...fechasEventosSupervisores,
                                    [keye]: [],
                                  });
                                  setfechasEventosAdministracion({
                                    ...fechasEventosAdministracion,
                                    [keye]: [],
                                  });
                                }
                                // else {
                                //   if (prev) {
                                //     const prevDate = new Date(
                                //       prev -
                                //         new Date().getTimezoneOffset() * 60 * 1000
                                //     )
                                //       .toISOString()
                                //       .substring(0, 10);

                                //     setfechasEventosGuardias((prevObject) => {
                                //       delete prevObject[prevDate];
                                //       return prevObject;
                                //     });
                                //     setfechasEventosSupervisores((prevObject) => {
                                //       delete prevObject[prevDate];
                                //       return prevObject;
                                //     });
                                //     setfechasEventosAdministracion((prevObject) => {
                                //       delete prevObject[prevDate];
                                //       return prevObject;
                                //     });
                                //   }
                                // }
                                setFocusedDate(keye);
                              }}
                              item={item}
                              setitem={(e) => {
                                try {
                                  setfechasEventosGuardias((prev) => {
                                    Object.keys(prev).forEach((fechaKey) => {
                                      const existe = e.actFechasActividadConsultaDB.find(
                                        (fechaAct) =>
                                          fechaAct.desde.substring(0, 10) ===
                                          fechaKey
                                      );
                                      if (!existe) {
                                        delete prev[fechaKey];
                                      } else {
                                        setFocusedDate("");
                                      }
                                    });
                                    const dias = Object.keys({ ...prev });
                                    const res = dias.map((element) => {
                                      const objeto = {};
                                      const itemG = Object.entries({
                                        ...prev,
                                      }).find((item) => {
                                        return item[0] === element;
                                      });
                                      objeto[itemG[0]] = [];

                                      objeto[itemG[0]] = [
                                        ...objeto[itemG[0]],
                                        ...itemG[1],
                                      ];

                                      const itemA = Object.entries(
                                        fechasEventosAdministracion
                                      ).find((item) => {
                                        return item[0] === element;
                                      });

                                      objeto[itemA[0]] = [
                                        ...objeto[itemA[0]],
                                        ...itemA[1],
                                      ];
                                      const itemS = Object.entries(
                                        fechasEventosSupervisores
                                      ).find((item) => {
                                        return item[0] === element;
                                      });

                                      objeto[itemS[0]] = [
                                        ...objeto[itemS[0]],
                                        ...itemS[1],
                                      ];
                                      return objeto;
                                    });
                                    const res2 = res
                                      .filter((objeto) => {
                                        return (
                                          Object.values(objeto)[0].length === 0
                                        );
                                      })
                                      .map((objeto) => Object.keys(objeto)[0]);
                                    setdiasSinPersonas(res2);
                                    return { ...prev };
                                  });
                                  setfechasEventosSupervisores((prev) => {
                                    Object.keys(prev).forEach((fechaKey) => {
                                      const existe = e.actFechasActividadConsultaDB.find(
                                        (fechaAct) =>
                                          fechaAct.desde.substring(0, 10) ===
                                          fechaKey
                                      );
                                      if (!existe) {
                                        delete prev[fechaKey];
                                      } else {
                                        setFocusedDate("");
                                      }
                                    });
                                    return { ...prev };
                                  });
                                  setfechasEventosAdministracion((prev) => {
                                    Object.keys(prev).forEach((fechaKey) => {
                                      const existe = e.actFechasActividadConsultaDB.find(
                                        (fechaAct) =>
                                          fechaAct.desde.substring(0, 10) ===
                                          fechaKey
                                      );
                                      if (!existe) {
                                        delete prev[fechaKey];
                                      } else {
                                        setFocusedDate("");
                                      }
                                    });
                                    return { ...prev };
                                  });
                                  setitem(e);
                                } catch (error) {
                                  console.log({ error });
                                }
                              }}
                              TextoPropiedad={"actFechasActividad"}
                              textoPropiedadDB={"actFechasActividadConsultaDB"}
                              validarFechaAnterior={true}
                              // openCalendario={true}
                              // setopenCalendario={setopenCalendario}
                            />
                          </Box>
                        )}
                        {(error.guardias ||
                          error.supervisores ||
                          error.administracion) && (
                          <Typography variant="body1" color="error">
                            Seleccione al menos una persona en alguna fecha
                          </Typography>
                        )}
                        <TabsFechasPersonas
                          readOnly={editar}
                          focusedDate={focusedDate}
                          evento={actEveDatos}
                          fechasEventosGuardias={fechasEventosGuardias}
                          setfechasEventosGuardias={setfechasEventosGuardias}
                          fechasEventosSupervisores={fechasEventosSupervisores}
                          setfechasEventosSupervisores={
                            setfechasEventosSupervisores
                          }
                          fechasEventosAdministracion={
                            fechasEventosAdministracion
                          }
                          setfechasEventosAdministracion={
                            setfechasEventosAdministracion
                          }
                          guardiasData={guardiasData}
                          supervisoresData={supervisoresData}
                          administracionData={administracionData}
                          actIdCliente={actIdCliente}
                        />
                      </>
                    ) : null}
                  </Box>
                </Fade>

                {DatosOtros ? (
                  <>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.actRequerimientosActividad}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="secondary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          QR
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) =>
                            setitem({
                              ...item,
                              actRequerimientosActividad: {
                                ...item.actRequerimientosActividad,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                          row
                          aria-label="gender"
                          value={actRequerimientosActividad.qr}
                          name="qr"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            disabled={actEveTipo === "RONDAS"}
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.actRequerimientosActividad}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="secondary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Foto
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) =>
                            setitem({
                              ...item,
                              actRequerimientosActividad: {
                                ...item.actRequerimientosActividad,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                          row
                          aria-label="gender"
                          value={actRequerimientosActividad.foto}
                          name="foto"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.actRequerimientosActividad}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="secondary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Video
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) =>
                            setitem({
                              ...item,
                              actRequerimientosActividad: {
                                ...item.actRequerimientosActividad,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                          row
                          aria-label="gender"
                          value={actRequerimientosActividad.video}
                          name="video"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                      error={error.actRequerimientosActividad}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="secondary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Texto
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) =>
                            setitem({
                              ...item,
                              actRequerimientosActividad: {
                                ...item.actRequerimientosActividad,
                                [e.target.name]: e.target.value,
                              },
                            })
                          }
                          row
                          aria-label="gender"
                          value={actRequerimientosActividad.texto}
                          name="texto"
                        >
                          <FormControlLabel
                            value="SI"
                            control={<Radio />}
                            label="SI"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    <TextField
                      className={clases.formAllW}
                      size="small"
                      id="actObservacion"
                      label="Tarea"
                      value={actObservacion}
                      name="actObservacion"
                      error={error.actObservacion}
                      onChange={(e) => handleChange(e)}
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                  </>
                ) : null}

                {/* DATOS ARCHIVOS */}
                {DatosArchivos ? (
                  <>
                    <>
                      <CampoDoc
                        setcargando={setcargando}
                        propiedad={"actDocumento"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                    </>
                    <Dropzone
                      imagenes={imagenes}
                      setimagenes={(e) => {
                        setmodificoImagenes(true);
                        setimagenes(e);
                      }}
                      fotosItem={actFotosActividad}
                    />
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={cargando || editar}
                  onClick={() => {
                    const dias = Object.keys(fechasEventosGuardias);
                    const res = dias.map((element) => {
                      const objeto = {};
                      const itemG = Object.entries(fechasEventosGuardias).find(
                        (item) => {
                          return item[0] === element;
                        }
                      );
                      objeto[itemG[0]] = [];

                      objeto[itemG[0]] = [...objeto[itemG[0]], ...itemG[1]];

                      const itemA = Object.entries(
                        fechasEventosAdministracion
                      ).find((item) => {
                        return item[0] === element;
                      });

                      objeto[itemA[0]] = [...objeto[itemA[0]], ...itemA[1]];
                      const itemS = Object.entries(
                        fechasEventosSupervisores
                      ).find((item) => {
                        return item[0] === element;
                      });

                      objeto[itemS[0]] = [...objeto[itemS[0]], ...itemS[1]];
                      return objeto;
                    });
                    const res2 = res
                      .filter((objeto) => {
                        return Object.values(objeto)[0].length === 0;
                      })
                      .map((objeto) => Object.keys(objeto)[0]);
                    setdiasSinPersonas(res2);
                    if (res2.length > 0) {
                      mostrarAlerta(
                        `Las fechas ${res2} no tienen personas asignadas`,
                        "warning"
                      );
                    } else {
                      setdiasSinPersonas([]);
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
