import React, { memo } from "react";

// MATERIAL
import { TableCell, TableRow } from "@mui/material";
import {
  Cancel,
  Create,
  Delete,
  DeleteForever,
  PictureAsPdf,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import ButtonsAction from "../../../components/ButtonsAction";
import DocumentoCelda from "../../../components/DocumentoCelda";
import { useContext } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { useState } from "react";
import { trimPropsItem } from "../../../../config/const";
const RowAgregar = (props) => {
  const classes = useRowStyles();
  const { usuario } = useContext(AuthContext);
  const {
    rowPadre,
    hijo,
    changeObjetoActivoState,
    setopenModal,
    socket,
  } = props;
  const claseCelda = `${classes.celda}`;

  const { tienePermiso, alertaPermiso } = usePermiso("Planificacion");
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const botones = [
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }
        changeObjetoActivoState({ padre: rowPadre, ...hijo });
        setopenModal(true);
      },
      disabled: !(
        hijo.planIdReceptor === Number(usuario.id) ||
        hijo.planIdEmisor === Number(usuario.id) ||
        usuario.rol.some((itemRol) => itemRol === "ADMIN")
      ),
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }
        setopenConfirmDialog(true);
      },
      disabled: !(
        hijo.planIdReceptor === Number(usuario.id) ||
        hijo.planIdEmisor === Number(usuario.id) ||
        usuario.rol.some((itemRol) => itemRol === "ADMIN")
      ),
      Icon: Delete,
      color: "error",
      id: 3,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              const planDatosHijosEdit = rowPadre.planDatosHijos.filter(
                (planHijo) => planHijo.uuid !== hijo.uuid
              );

              socket.current.emit(
                "client:actualizarData",
                trimPropsItem({
                  ...rowPadre,
                  planDatosHijos: planDatosHijosEdit,
                  rucempresa: usuario.rucempresa,
                  rol: usuario.rol,
                  tabla: "planificacion",
                  planUser: usuario.usuario,
                  planEmpresa: usuario.rucempresa,
                })
              );
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction claseCelda={claseCelda} botones={botones} />
        {/* las demas columnas */}
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {hijo.planFecha}
          </TableCell>{" "}
          <DocumentoCelda
            propiedad={hijo.planDocumento}
            claseCelda={claseCelda}
            Icono={<PictureAsPdf color="error" fontSize="small" />}
          />
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            style={{
              color:
                hijo.planAccionesStatus === "PROCESADO"
                  ? "green"
                  : hijo.planAccionesStatus === "NO PROCESADO"
                  ? "orange"
                  : hijo.planAccionesStatus === "ANULADA"
                  ? "red"
                  : "red",
              fontWeight: "bold",
            }}
          >
            {hijo.planAccionesStatus}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {hijo.planProceso}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {hijo.planDocumentoReceptor} - {hijo.planNombreReceptor}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {hijo.planNombreEmisor}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {hijo.planMotivo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {hijo.planDetalles}
          </TableCell>
        </>
        {/* {editar ? (
          <>
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
               
              align="center"
              className={claseCelda}
            >
              <TextField
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
    
          </>
        )} */}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
