import React, { memo, useEffect } from "react";

import { useState } from "react";
import { withRouter } from "react-router";

import ButtonsAction from "./ButtonsAction";

import { trimPropsItem } from "../../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField, MenuItem } from "@mui/material";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const { row, index, campoExport, item, setitem, deshabilitarbotones } = props;
  const [itemRow, setitemRow] = useState(row);
  const [expandir, setexpandir] = useState(false); 
  useEffect(() => {
    setitemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      cliDatosOperativos: item.cliDatosOperativos.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (itemRow.puesto === "") {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      cliDatosOperativos: item.cliDatosOperativos.map((item, itemIndex) => {
        if (index === itemIndex) {
          return trimPropsItem(itemRow);
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar este Dato Operativo?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={setitem}
          index={index}
          itemRow={itemRow}
          row={itemRow}
          item={item}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={true}
          ocultarDelete={true}
          mostrarEstado={false}
          mostrarExpandible={false}
          funcExpandir={() => {
            setexpandir(!expandir);
          }}
          expandir={expandir}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />
        <TableCell size="small" align="center" className={classes.celda}>
          {editar ? (
            <TextField
              id=""
              value={itemRow.ubicacion}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  ubicacion: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            row.ubicacion
          )}
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {editar ? (
            <TextField
              id=""
              value={itemRow.puesto}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  puesto: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            row.puesto
          )}
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {editar ? (
            <TextField
              type="number"
              id=""
              value={itemRow.supervisor}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  supervisor: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            row.supervisor
          )}
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {editar ? (
            <TextField
              type={"number"}
              id=""
              value={itemRow.guardias}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  guardias: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            row.guardias
          )}
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {editar ? (
            <TextField
              id=""
              value={itemRow.horasservicio}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  horasservicio: e.target.value,
                })
              }
              size="small"
              fullWidth
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">Seleccione.</MenuItem>
              {["24", "12", "8", "4"].map((item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              })}
            </TextField>
          ) : (
            row.horasservicio
          )}
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          {editar ? (
            <TextField
              id=""
              multiline
              rows={2}
              value={itemRow.tipoinstalacion}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  tipoinstalacion: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          ) : (
            row.tipoinstalacion
          )}
        </TableCell>
      </TableRow>{" "}
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" gutterBottom component="span">
                  Consignas
                </Typography>{" "}
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarConsigna(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              {'itemRow.consignas'}
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "tarea",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "tarea",
                    },
                    {
                      id: "observacion",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "observacion",
                    },
                    {
                      id: "desde",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "desde",
                    },
                    {
                      id: "hasta",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "hasta",
                    },
                  ]}
                />
                <TableBody>
                  {agregarConsigna ? (
                    <RowAgregarItem
                      item={itemRow}
                      setitem={setitemRow}
                      setagregarConsigna={setagregarConsigna}
                    />
                  ) : null}
                  {itemRow.consignas.map((consigna, index) => {
                    return (
                      <RowItem
                        consigna={consigna}
                        item={itemRow}
                        setitem={setitemRow}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>{" "} */}
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
