import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import {
  FechaExacta,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";

// MATERIAL
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Button,
  TextField,
  Autocomplete,
  FormControl,
  Tooltip,
  MenuItem,
} from "@mui/material";
import {
  objectDefaultDistribucion,
  objectDefaultDevolucion,
  objectDefaultPedido,
} from "../data";
import { useModalStyle } from "../../styles/styleModal";
import { AddCircle, Cancel, Create, Delete } from "@mui/icons-material";
import clienteAxios from "../../../config/axios";
import ListPersonas from "../../components/ListPersonas";
import AuthContext from "../../../Context/Auth/AuthContext";
import TablaVestimenta from "./RowsVestimenta/TablaVestimenta";
import TablaArmas from "./RowsArmas/TablaArmas";
import TablaMunicion from "./RowMunicion/TablaMunicion";
import CampoDoc from "../../components/CampoDoc";
import { tienePropiedadVerdadera } from "../../functions/objetos/validacionDePropiedades";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalDistribucion = (props) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //hook de estilos
  const clases = useModalStyle();
  const [modalStyle] = useState(getModalStyle);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    ver,
    setver,
    estadoRequest,
    seteditarAnticipo,
    setopenAnticipo,
    ObjectoActivoAnticipo,
    changeObjetoActivoStateAnticipo,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // disPuestoCliente,
    disTipoDotacion,
    // disId: 192,
    // disTipo,
    // disIdCliente,
    disDocuCliente,
    disNombreCliente,
    disPersonas,
    disSupervisores,
    disAdministracion,
    disEntrega,
    disObservacion,
    disEstado,
    disFechaEnvio,
    disDetalle,
    disFechaRecibido,
    // disPedidos,
    // disUser,
    // disEmpresa,
    // disFecReg,
    // Todos,
    // disDocumento,
    disFecha,
    disVestimenta,
    disArmas,
    disMuniciones,
    disUbicacionCliente,
    disPuestoCliente,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);
  const [puestosData, setpuestosData] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  const [supervisoresData, setsupervisoresData] = useState(null);
  const [administracionData, setadministracionData] = useState(null);
  const [bodegaData, setbodegaData] = useState(null);

  const [subSeccion, setsubSeccion] = useState({
    vestimenta: true,
    armas: false,
    municion: false,
    guardias: false,
    devolucion: false,
    supervisores: false,
    administracion: false,
  });
  const {
    vestimenta,
    armas,
    municion,
    guardias,
    // devolucion,
    supervisores,
    administracion,
  } = subSeccion;
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  const setitemsPersonas = (guardias) => {
    return guardias.map((itemGuardia) => {
      return {
        perEmail: itemGuardia.perEmail,
        perFoto: itemGuardia.perFoto,
        perApellidos: itemGuardia.perApellidos,
        perNombres: itemGuardia.perNombres,
        perDocNumero: itemGuardia.perDocNumero,
        perId: itemGuardia.perId,
      };
    });
  };
  const defaultActive = (eliminar) => {
    if (estadoRequest === "PEDIDO") {
      changeObjetoActivoState(objectDefaultPedido);
    } else if (estadoRequest === "DISTRIBUCION") {
      changeObjetoActivoState(objectDefaultDistribucion);
    } else if (estadoRequest === "DEVOLUCION") {
      changeObjetoActivoState(objectDefaultDevolucion);
    }
    setopen(false);
    seteditar(false);
    setver(false);
    seterror({});
    setcargando(false);
    setclientesData([]);
    setpuestosData([]);
    setpersonasData([]);
    if (ItemsOtroLugar.length > 0 && eliminar && !editar) {
      try {
        socket.current.emit("client:eliminarDataSeleccionado", {
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "anticipo",
          lista: { sinPadre: ItemsOtroLugar.map((item) => item.antId) },
        });
      } catch (error) {}
    }
    setItemsOtroLugar([]);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //definiendo los estilos del modal
  const validacion = (sinDescuento) => {
    if (estadoRequest === "DISTRIBUCION") {
      if (
        disDocuCliente.trim() === "" ||
        disFecha.trim() === "" ||
        disEntrega.trim() === "" ||
        (disPersonas.length === 0 &&
          disSupervisores.length === 0 &&
          disAdministracion.length === 0) ||
        (disVestimenta.length === 0 &&
          disArmas.length === 0 &&
          disMuniciones.length === 0) ||
        disPuestoCliente.trim() === "" ||
        (disEntrega === "DESCUENTO" &&
          ItemsOtroLugar.length === 0 &&
          !sinDescuento)
      ) {
        const error = {
          disDocuCliente: disDocuCliente.trim() === "" ? true : false,
          disFecha: disFecha.trim() === "" ? true : false,
          disEntrega:
            disEntrega.trim() === "" ||
            (disEntrega === "DESCUENTO" &&
              ItemsOtroLugar.length === 0 &&
              !sinDescuento)
              ? true
              : false,
          disPersonas:
            disPersonas.length === 0 &&
            disSupervisores.length === 0 &&
            disAdministracion.length === 0
              ? true
              : false,
          disVestimenta:
            disVestimenta.length === 0 &&
            disArmas.length === 0 &&
            disMuniciones.length === 0
              ? true
              : false,

          disMuniciones:
            disVestimenta.length === 0 &&
            disArmas.length === 0 &&
            disMuniciones.length === 0
              ? true
              : false,
          disArmas:
            disVestimenta.length === 0 &&
            disArmas.length === 0 &&
            disMuniciones.length === 0
              ? true
              : false,
          disPuestoCliente: disPuestoCliente.trim() === "",
        };
        seterror(error);
        return true;
      } else {
        return false;
      }
    }
    if (estadoRequest === "PEDIDO") {
      const errorValidacion = {};

      errorValidacion.disEntrega = disEntrega.trim() === "";
      errorValidacion.disDocuCliente = disDocuCliente.trim() === "";
      errorValidacion.disPersonas =
        disPersonas.length === 0 &&
        disSupervisores.length === 0 &&
        disAdministracion.length === 0;

      errorValidacion.disVestimenta =
        disVestimenta.length === 0 && disMuniciones.length === 0;

      errorValidacion.disMuniciones =
        disVestimenta.length === 0 && disMuniciones.length === 0;
      errorValidacion.disPuestoCliente = disPuestoCliente.trim() === "";
      const tieneError = tienePropiedadVerdadera(errorValidacion);
      if (tieneError) {
        mostrarAlerta("Faltan campos obligatorios", "error");
        seterror(errorValidacion);
      }

      return tieneError;
    }
    return true;
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: estadoRequest === "DISTRIBUCION" ? "distribucion" : "pedido",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          disUser: usuario.usuario,
          disEmpresa: usuario.rucempresa,
          disIdDescuento: ItemsOtroLugar.map((itemTurno) => itemTurno.antId),
        })
      );
      return defaultActive();
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }

    try {
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: estadoRequest === "DISTRIBUCION" ? "distribucion" : "pedido",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          disUser: usuario.usuario,
          disEmpresa: usuario.rucempresa,
          disIdDescuento: ItemsOtroLugar.map((itemTurno) => itemTurno.antId),
          disVestimenta: item.disVestimenta.map((itemTabla) => ({
            ...itemTabla,
            cantidades: itemTabla.cantidades.map((cantidad) => ({
              ...cantidad,
              cantidadAnterior: cantidad.cantidadRestante,
            })),
          })),
          disArmas: item.disArmas.map((itemTabla) => ({
            ...itemTabla,
            cantidades: itemTabla.cantidades.map((cantidad) => ({
              ...cantidad,
              cantidadAnterior: cantidad.cantidadRestante,
            })),
          })),
          disMuniciones: item.disMuniciones.map((itemTabla) => ({
            ...itemTabla,
            cantidades: itemTabla.cantidades.map((cantidad) => ({
              ...cantidad,
              cantidadAnterior: cantidad.cantidadRestante,
            })),
          })),
        })
      );
      return defaultActive();
    } catch (error) {
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  useEffect(() => {
    const getDataadministracionData = async () => {
      const resadministracionData = await clienteAxios.get(
        "/personas/datos-principales/guadias-supervisores-administracion?search=&estado=ADMINISTRACION"
      );

      setadministracionData(setitemsPersonas(resadministracionData.data.data));
    };
    if (!administracionData) {
      getDataadministracionData();
    }
    const getDatasupervisoresData = async () => {
      const ressupervisoresData = await clienteAxios.get(
        "/personas/datos-principales/guadias-supervisores-administracion?estado=SUPERVISOR"
      );

      setsupervisoresData(
        setitemsPersonas(ressupervisoresData.data.data, true)
      );
    };
    if (!supervisoresData) {
      getDatasupervisoresData();
    }
    const getbodegaData = async () => {
      const resbodegaData = await clienteAxios.get(
        `bodegas/filtro/0?empresa=${usuario.rucempresa}`
      );

      setbodegaData(resbodegaData.data.data);
    };

    if (!bodegaData) {
      getbodegaData();
    }
    if (open && ObjectoActivo.disDocuCliente !== "") {
      const getresguardiasData = async () => {
        try {
          // const itemF = await clienteAxios.get(
          //   `/clientes/filtro/0?search=${ObjectoActivo.disDocuCliente}&estado=CLIENTES`
          // );
          const res = await clienteAxios.get(
            `/personas/listado/:codigo?docnumero=${ObjectoActivo.disDocuCliente}`
          );
          setpersonasData(setitemsPersonas(res.data.data));
        } catch (error) {
          setpersonasData([]);
        }
      };

      getresguardiasData();
      const getItemsOtroLugar = async () => {
        try {
          // const itemF = await clienteAxios.get(
          //   `/clientes/filtro/0?search=${ObjectoActivo.disDocuCliente}&estado=CLIENTES`
          // );
          const resItemsOtroLugar = await clienteAxios.get(
            `anticipos/byid?lista=${ObjectoActivo.disIdDescuento.join(",")}`
          );
          setItemsOtroLugar(resItemsOtroLugar.data.data);
        } catch (error) {
          setItemsOtroLugar([]);
        }
      };

      getItemsOtroLugar();
    }
    setitem({
      ...ObjectoActivo,
      disDevolucion: ObjectoActivo.disTipo === "DEVOLUCION",
    });

    // eslint-disable-next-line
  }, [open, ObjectoActivo]);
  const [ItemsOtroLugar, setItemsOtroLugar] = useState([]);
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });

  useEffect(() => {
    socket.current.on("server:guardadoExitoso", (data) => {
      setitemSocket({
        tipo: "agregar",
        item: data,
      });
      socket.current.on("server:actualizadoExitoso", (data) => {
        setitemSocket({
          tipo: "editar",
          item: data,
        });
      });
    });

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const { item, tipo } = itemSocket;
    if (!open) {
      return;
    }
    if (tipo === "") {
      return;
    }
    if (usuario.usuario !== item.antUser) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== "anticipo") {
      return;
    }
    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    if (tipo === "agregar") {
      setItemsOtroLugar(item.result);
    }
    if (tipo === "editar") {
      setItemsOtroLugar(
        ItemsOtroLugar.map((itemOtroLugar) => {
          if (itemOtroLugar.antId === item.antId) {
            return item;
          } else {
            return itemOtroLugar;
          }
        })
      );
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);
  if (!open) {
    return null;
  }
  if (!supervisoresData) {
    return null;
  }
  if (!administracionData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#modalDistribucion">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="modalDistribucion"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {ver ? "Ver" : editar ? "Editar" : "Agregar"} {item.disTipo}.
                </Typography>
                {editar && item.disTipo === "DISTRIBUCION" && (
                  <Typography variant="body2" color="error" component={"span"}>
                    No se puede EDITAR, tiene que eliminar y generar uno nuevo.
                  </Typography>
                )}
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive(true);
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" center"
                width="100%"
              >
                <Box></Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {estadoRequest === "DISTRIBUCION" ? (
                  <>
                    {ItemsOtroLugar.map((itemOtroLugar) => {
                      return (
                        <Box
                          display={"flex"}
                          justifyContent="space-between"
                          alignItems={"center"}
                          className={clases.formD}
                        >
                          <Typography
                            variant="body2"
                            color="secondary"
                            fontWeight={"bold"}
                          >
                            {itemOtroLugar.antPeriodo} {" - "}
                            <Typography
                              variant="body2"
                              color="primary"
                              component={"span"}
                            >
                              {itemOtroLugar.antNomPersona}
                              {" - "}
                              {itemOtroLugar.antValor}
                            </Typography>
                          </Typography>
                          <Box display={"flex"} alignItems="center">
                            {" "}
                            <IconButton
                              aria-label=""
                              style={{ margin: "0px" }}
                              onClick={() => {
                                changeObjetoActivoStateAnticipo(itemOtroLugar);
                                seteditarAnticipo(true);
                                setopenAnticipo(true);
                              }}
                            >
                              <Create color="secondary" />
                            </IconButton>
                            <IconButton
                              disabled={editar}
                              aria-label=""
                              style={{ margin: "0px" }}
                              onClick={() => {
                                socket.current.emit("client:eliminarData", {
                                  nombre: usuario.nombre,
                                  rucempresa: usuario.rucempresa,
                                  rol: usuario.rol,
                                  antUser: usuario.usuario,
                                  antEmpresa: usuario.rucempresa,
                                  antId: itemOtroLugar["antId"],
                                  antPeriodo: itemOtroLugar.antPeriodo,
                                  antDocuPersona: itemOtroLugar.antDocuPersona,
                                  antTipo: itemOtroLugar.antTipo,
                                  tabla: "anticipo",
                                });
                                setItemsOtroLugar(
                                  ItemsOtroLugar.filter(
                                    (itemEliminar) =>
                                      itemOtroLugar.antId !== itemEliminar.antId
                                  )
                                );
                              }}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    })}
                    <Box display="flex" width="100%"></Box>{" "}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disabled={ver || item.disDevolucion}
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={(disNombreCliente + " " + disDocuCliente).trim()}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              const res = await clienteAxios.get(
                                `/personas/listado/:codigo?docnumero=${options[1]}`
                              );
                              const itemF = clientesData.find(
                                (itemCliente) =>
                                  itemCliente.cliId === Number(options[2])
                              );

                              setpuestosData(itemF.cliDatosOperativos);

                              if (res.data.data.length === 0) {
                                mostrarAlerta(
                                  "Este cliente no tiene guardias",
                                  "error"
                                );
                                seterror({ ...error, disPersonas: true });
                              }

                              setpersonasData(setitemsPersonas(res.data.data));
                              setitem({
                                ...item,
                                disIdCliente: options[2],
                                disDocuCliente: options[1],
                                disNombreCliente: options[0],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            required
                            fullWidth
                            label="Cliente"
                            error={error.disDocuCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {
                                return mostrarAlerta(
                                  "No hay clientes",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type={"date"}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      disabled={ver || item.disDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Fecha"
                      value={disFecha}
                      name="disFecha"
                      error={error.disFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled={ver || item.disDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Entrega"
                      value={disEntrega}
                      name="disEntrega"
                      error={error.disEntrega}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                          disPersonas:
                            e.target.value === "DESCUENTO" ? [] : disPersonas,
                          disSupervisores:
                            e.target.value === "DESCUENTO"
                              ? []
                              : disSupervisores,
                          disAdministracion:
                            e.target.value === "DESCUENTO"
                              ? []
                              : disAdministracion,
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      InputProps={{
                        endAdornment: (
                          <React.Fragment>
                            {editar || disEntrega === "DOTACIÓN" ? null : (
                              <Tooltip title="Agregar Descuento Extra">
                                <IconButton
                                  // disabled={
                                  //   validacion() || ItemsOtroLugar.antId
                                  // }
                                  size="small"
                                  onClick={async () => {
                                    try {
                                      // const res = await clienteAxios.get(
                                      //   `/clientes/filtro/0?search=${docuCliente}&estado=CLIENTES`
                                      // );
                                      // if (!res.data.data[0]) {
                                      //   return mostrarAlerta(
                                      //     "Esta persona no tiene cliente",
                                      //     "error"
                                      //   );
                                      // }

                                      // const itemF = res.data.data[0];
                                      if (validacion(true)) {
                                        return mostrarAlerta(
                                          "Necesitas llenar campos para crear un DESCUENTO",
                                          "error"
                                        );
                                      }
                                      const guardia = disPersonas[0];
                                      const supervisor = disSupervisores[0];

                                      changeObjetoActivoStateAnticipo({
                                        ...ObjectoActivoAnticipo,
                                        antIdPersona: guardia
                                          ? guardia.perId
                                          : supervisor.perId,
                                        antDocuPersona: guardia
                                          ? guardia.perDocNumero
                                          : supervisor.perDocNumero,
                                        antNomPersona: guardia
                                          ? guardia.perApellidos +
                                            " " +
                                            guardia.perNombres
                                          : supervisor.perApellidos +
                                            " " +
                                            supervisor.perNombres,
                                        antValor:
                                          [
                                            ...disVestimenta,
                                            ...disArmas,
                                            ...disMuniciones,
                                          ].length === 0
                                            ? null
                                            : [
                                                ...disVestimenta,
                                                ...disArmas,
                                                ...disMuniciones,
                                              ]
                                                .map((row) => {
                                                  return Number(row.valor);
                                                })
                                                .reduce(reducer)
                                                .toFixed(2),
                                        antDetalle: [
                                          ...disVestimenta,
                                          ...disArmas,
                                          ...disMuniciones,
                                        ]
                                          .map(
                                            (itemInventario) =>
                                              itemInventario.nombre
                                          )
                                          .join(", "),
                                      });

                                      setopenAnticipo(true);
                                    } catch (error) {
                                      return mostrarAlerta(
                                        "Hubo un error",
                                        "error"
                                      );
                                    }
                                  }}
                                >
                                  <AddCircle
                                    fontSize="small"
                                    color={"secondary"}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </React.Fragment>
                        ),
                      }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["DOTACIÓN", "DESCUENTO"].map((item, key) => {
                        return (
                          <MenuItem value={item} key={key}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      disabled={ver || item.disDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Tipo"
                      value={disTipoDotacion}
                      name="disTipoDotacion"
                      error={error.disTipoDotacion}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["PUESTO", "PERSONAL"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disabled={ver || item.disDevolucion}
                        disableClearable={true}
                        options={puestosData.map((item) => {
                          return item.ubicacion + "/+/" + item.puesto;
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ");
                        }}
                        value={
                          `${
                            disUbicacionCliente ? disUbicacionCliente : ""
                          } ${disPuestoCliente}`.trim() === ""
                            ? ""
                            : `${
                                disUbicacionCliente ? disUbicacionCliente : ""
                              } - ${disPuestoCliente}`.trim()
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const [
                                disUbicacionCliente,
                                disPuestoCliente,
                              ] = newValue.split("/+/");

                              setitem({
                                ...item,
                                disUbicacionCliente,
                                disPuestoCliente,
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            label="Puesto"
                            error={error.disPuestoCliente}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      multiline
                      disabled={ver || item.disDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Observación"
                      value={disObservacion}
                      name="disObservacion"
                      error={error.disObservacion}
                      onChange={(e) => handleChange(e)}
                    />
                    <>
                      <Box mt={2} width="100%">
                        {error.disVestimenta ||
                        error.disArmas ||
                        error.disMuniciones ? (
                          <Typography
                            variant="caption"
                            color="error"
                            fontWeight="bold"
                          >
                            INGRESE AL MENOS UN REGISTRO DE: IMPLEMENTOS, ARMAS
                            O MUNICIÓN.
                          </Typography>
                        ) : null}
                      </Box>
                      <Box mt={1} width="100%">
                        {error.disPersonas ? (
                          <Typography
                            variant="caption"
                            color="error"
                            fontWeight="bold"
                          >
                            {disEntrega === "DESCUENTO"
                              ? "SELECCIONE SOLO A UN GUARDIA, SUPERVISOR O ADMINISTRACION"
                              : "SELECCIONE AL MENOS A UN GUARDIA, SUPERVISOR O ADMINISTRACION"}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems=" center"
                        width="100%"
                      >
                        <Box>
                          <Button
                            size="small"
                            variant={vestimenta ? "contained" : null}
                            onClick={async () => {
                              setsubSeccion({ vestimenta: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="primary"
                          >
                            Implementos
                          </Button>
                          {disEntrega === "DESCUENTO" ? null : (
                            <Button
                              size="small"
                              variant={armas ? "contained" : null}
                              onClick={async () => {
                                setsubSeccion({ armas: true });
                              }}
                              disableElevation
                              style={{
                                padding: ".5rem",
                                borderRadius: "15px 15px  0px 0px",
                              }}
                              color="primary"
                            >
                              ARMAS
                            </Button>
                          )}
                          <Button
                            size="small"
                            variant={municion ? "contained" : null}
                            onClick={async () => {
                              setsubSeccion({ municion: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="primary"
                          >
                            Munición
                          </Button>
                          <Button
                            size="small"
                            variant={"contained"}
                            disableFocusRipple={true}
                            disableTouchRipple={true}
                            disableElevation={true}
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="success"
                          >
                            V. TOTAL: $
                            {[...disVestimenta, ...disArmas, ...disMuniciones]
                              .length === 0
                              ? null
                              : [
                                  ...disVestimenta,
                                  ...disArmas,
                                  ...disMuniciones,
                                ]
                                  .map((row) => {
                                    return (
                                      Number(row.valor) *
                                      row.cantidades
                                        .map(({ cantidad }) => cantidad)
                                        .reduce(reducer)
                                    );
                                  })
                                  .reduce(reducer)
                                  .toFixed(2)}
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            DatosPrincipales
                            size="small"
                            variant={
                              guardias
                                ? "contained"
                                : error.disPersonas
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ guardias: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={error.disPersonas ? "error" : "secondary"}
                          >
                            Guardias
                          </Button>
                          <Button
                            size="small"
                            variant={
                              supervisores
                                ? "contained"
                                : error.disSupervisores
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ supervisores: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={
                              error.disSupervisores ? "error" : "secondary"
                            }
                          >
                            Supervisores
                          </Button>
                          <Button
                            size="small"
                            variant={
                              administracion
                                ? "contained"
                                : error.disAdministracion
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ administracion: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={
                              error.disAdministracion ? "error" : "secondary"
                            }
                          >
                            Administracion
                          </Button>
                        </Box>
                      </Box>
                      <Divider style={{ margin: "0", width: "100%" }} />
                      {vestimenta ? (
                        <TablaVestimenta
                          ver={ver}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                        />
                      ) : null}
                      {armas ? (
                        <TablaArmas
                          ver={ver}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                        />
                      ) : null}
                      {municion ? (
                        <TablaMunicion
                          ver={ver}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                        />
                      ) : null}
                      {guardias ? (
                        <ListPersonas
                          readOnly={ver || editar || item.disDevolucion}
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (disEntrega === "DESCUENTO") {
                              if (
                                e.disPersonas.length === 2 ||
                                e.disSupervisores.length === 1 ||
                                e.disAdministracion.length === 1
                              ) {
                                seterror({
                                  ...error,
                                  disPersonas: disPersonas.length === 1,
                                  disSupervisores: disSupervisores.length === 1,
                                  disAdministracion:
                                    disAdministracion.length === 1,
                                });
                                return mostrarAlerta(
                                  "Maximo una persona para DESCUENTOS",
                                  "error"
                                );
                              }
                            }
                            setitem(e);
                          }}
                          data={personasData}
                          propiedad={disPersonas}
                          propiedadString={"disPersonas"}
                        />
                      ) : null}
                      {supervisores ? (
                        <ListPersonas
                          readOnly={ver || editar || item.disDevolucion}
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (disEntrega === "DESCUENTO") {
                              if (
                                e.disPersonas.length === 1 ||
                                e.disSupervisores.length === 2 ||
                                e.disAdministracion.length === 1
                              ) {
                                seterror({
                                  ...error,
                                  disPersonas: disPersonas.length === 1,
                                  disSupervisores: disSupervisores.length === 1,
                                  disAdministracion:
                                    disAdministracion.length === 1,
                                });
                                return mostrarAlerta(
                                  "Maximo una persona para DESCUENTOS",
                                  "error"
                                );
                              }
                            }
                            setitem(e);
                          }}
                          data={supervisoresData}
                          propiedad={disSupervisores}
                          propiedadString={"disSupervisores"}
                        />
                      ) : null}
                      {administracion ? (
                        <ListPersonas
                          readOnly={ver || editar || item.disDevolucion}
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (disEntrega === "DESCUENTO") {
                              if (
                                e.disPersonas.length === 1 ||
                                e.disSupervisores.length === 1 ||
                                e.disAdministracion.length === 2
                              ) {
                                seterror({
                                  ...error,
                                  disPersonas: disPersonas.length === 1,
                                  disSupervisores: disSupervisores.length === 1,
                                  disAdministracion:
                                    disAdministracion.length === 1,
                                });
                                return mostrarAlerta(
                                  "Maximo una persona para DESCUENTOS",
                                  "error"
                                );
                              }
                            }
                            setitem(e);
                          }}
                          data={administracionData}
                          propiedad={disAdministracion}
                          propiedadString={"disAdministracion"}
                        />
                      ) : null}
                    </>
                  </>
                ) : null}
                {estadoRequest === "PEDIDO" ? (
                  <>
                    <TextField
                      disabled={
                        ObjectoActivo.disEstado === "RECIBIDO" ||
                        ObjectoActivo.disEstado === "ANULADO" ||
                        ObjectoActivo.disEstado === "ENVIADO" ||
                        !editar ||
                        item.disDevolucion ||
                        ver
                      }
                      className={clases.formD}
                      size="small"
                      label="Estado"
                      value={disEstado}
                      name="disEstado"
                      error={error.disEstado}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem disabled value="">
                        Seleccione.
                      </MenuItem>
                      {["PENDIENTE", "APROBADO", "ANULADA"].map((item) => {
                        return (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <TextField
                      disabled={
                        disEstado === "RECIBIDO" ||
                        !editar ||
                        item.disDevolucion ||
                        ver
                      }
                      InputLabelProps={{ shrink: true }}
                      type="datetime-local"
                      className={clases.formD}
                      size="small"
                      label="Fecha Envio"
                      value={disFechaEnvio}
                      name="disFechaEnvio"
                      error={error.disFechaEnvio}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled={disEstado === "RECIBIDO" || !editar || ver}
                      className={clases.formD}
                      size="small"
                      label="Detalle"
                      value={disDetalle}
                      name="disDetalle"
                      error={error.disDetalle}
                      onChange={(e) => handleChange(e)}
                    />
                    <CampoDoc
                      disabled={ver || item.disDevolucion}
                      setcargando={setcargando}
                      propiedad={"disDocumento"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        value={(disNombreCliente + " " + disDocuCliente).trim()}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");

                            try {
                              const res = await clienteAxios.get(
                                `/personas/listado/:codigo?docnumero=${options[1]}`
                              );
                              if (res.data.data.length === 0) {
                                mostrarAlerta(
                                  "Este cliente no tiene guardias",
                                  "error"
                                );
                                seterror({ ...error, disPersonas: true });
                              }

                              setpersonasData(setitemsPersonas(res.data.data));
                              const itemF = clientesData.find(
                                (itemCliente) =>
                                  itemCliente.cliId === Number(options[2])
                              );

                              setpuestosData(itemF.cliDatosOperativos);
                              setitem({
                                ...item,
                                disIdCliente: options[2],
                                disDocuCliente: options[1],
                                disNombreCliente: options[0],
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          } else {
                            setitem({
                              ...item,
                              disIdCliente: "",
                              disDocuCliente: "",
                              disNombreCliente: "",
                            });
                            setclientesData([]);
                            setpersonasData([]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            disabled={editar || ver || item.disDevolucion}
                            required
                            fullWidth
                            label="Cliente"
                            error={error.disDocuCliente}
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {
                                setitem({
                                  ...item,
                                  disIdCliente: "",
                                  disDocuCliente: "",
                                  disNombreCliente: "",
                                });
                                setclientesData([]);
                                setpersonasData([]);
                                return mostrarAlerta(
                                  "No hay clientes",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>{" "}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disabled={editar || ver || item.disDevolucion}
                        disableClearable={true}
                        options={puestosData.map((item) => {
                          return item.ubicacion + "/+/" + item.puesto;
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ");
                        }}
                        value={
                          `${
                            disUbicacionCliente ? disUbicacionCliente : ""
                          } ${disPuestoCliente}`.trim() === ""
                            ? ""
                            : `${
                                disUbicacionCliente ? disUbicacionCliente : ""
                              } - ${disPuestoCliente}`.trim()
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const [
                                disUbicacionCliente,
                                disPuestoCliente,
                              ] = newValue.split("/+/");

                              setitem({
                                ...item,
                                disUbicacionCliente,
                                disPuestoCliente,
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            label="Puesto"
                            error={error.disPuestoCliente}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      disabled={editar || ver || item.disDevolucion}
                      className={clases.formD}
                      size="small"
                      label="Entrega"
                      value={disEntrega}
                      name="disEntrega"
                      error={error.disEntrega}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          [e.target.name]: e.target.value,
                          disPersonas:
                            e.target.value === "DESCUENTO" ? [] : disPersonas,
                          disSupervisores:
                            e.target.value === "DESCUENTO"
                              ? []
                              : disSupervisores,
                          disAdministracion:
                            e.target.value === "DESCUENTO"
                              ? []
                              : disAdministracion,
                        });
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione.</MenuItem>
                      {["DOTACIÓN", "DESCUENTO"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      disabled={true}
                      InputLabelProps={{ shrink: true }}
                      type="datetime-local"
                      className={clases.formD}
                      size="small"
                      label="Fecha Recibido"
                      value={disFechaRecibido}
                      name="disFechaRecibido"
                      error={error.disFechaRecibido}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      disabled={editar || ver || item.disDevolucion}
                      multiline
                      className={clases.formD}
                      size="small"
                      label="Observación"
                      value={disObservacion}
                      name="disObservacion"
                      error={error.disObservacion}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <>
                      <Box mt={2} width="100%">
                        {error.disVestimenta ||
                        error.disArmas ||
                        error.disMuniciones ? (
                          <Typography
                            variant="caption"
                            color="error"
                            fontWeight="bold"
                          >
                            INGRESE AL MENOS UN REGISTRO DE: IMPLEMENTOS, ARMAS
                            O MUNICIÓN.
                          </Typography>
                        ) : null}
                      </Box>
                      <Box mt={1} width="100%">
                        {error.disPersonas ? (
                          <Typography
                            variant="caption"
                            color="error"
                            fontWeight="bold"
                          >
                            SELECCIONE SOLO A UN GUARDIA, SUPERVISOR O
                            ADMINISTRACION
                          </Typography>
                        ) : null}
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems=" center"
                        width="100%"
                      >
                        <Box>
                          <Button
                            size="small"
                            variant={vestimenta ? "contained" : null}
                            onClick={async () => {
                              setsubSeccion({ vestimenta: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="primary"
                          >
                            Implementos
                          </Button>

                          <Button
                            size="small"
                            variant={municion ? "contained" : null}
                            onClick={async () => {
                              setsubSeccion({ municion: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="primary"
                          >
                            Munición
                          </Button>
                          <Button
                            size="small"
                            variant={"contained"}
                            disableFocusRipple={true}
                            disableTouchRipple={true}
                            disableElevation={true}
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color="success"
                          >
                            V. TOTAL: $
                            {[...disVestimenta, ...disMuniciones].length === 0
                              ? null
                              : [...disVestimenta, ...disMuniciones]
                                  .map((row) => {
                                    return (
                                      Number(row.valor) *
                                      row.cantidades
                                        .map(({ cantidad }) => cantidad)
                                        .reduce(reducer)
                                    );
                                  })
                                  .reduce(reducer)
                                  .toFixed(2)}
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            DatosPrincipales
                            size="small"
                            variant={
                              guardias
                                ? "contained"
                                : error.disPersonas
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ guardias: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={error.disPersonas ? "error" : "secondary"}
                          >
                            Guardias
                          </Button>
                          <Button
                            size="small"
                            variant={
                              supervisores
                                ? "contained"
                                : error.disSupervisores
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ supervisores: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={
                              error.disSupervisores ? "error" : "secondary"
                            }
                          >
                            Supervisores
                          </Button>
                          <Button
                            size="small"
                            variant={
                              administracion
                                ? "contained"
                                : error.disAdministracion
                                ? "outlined"
                                : null
                            }
                            onClick={async () => {
                              setsubSeccion({ administracion: true });
                            }}
                            disableElevation
                            style={{
                              padding: ".5rem",
                              borderRadius: "15px 15px  0px 0px",
                            }}
                            color={
                              error.disAdministracion ? "error" : "secondary"
                            }
                          >
                            Administracion
                          </Button>
                        </Box>
                      </Box>
                      <Divider style={{ margin: "0", width: "100%" }} />
                      {vestimenta ? (
                        <TablaVestimenta
                          ver={ver || editar}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                        />
                      ) : null}

                      {municion ? (
                        <TablaMunicion
                          ver={ver || editar}
                          devolucion={item.disDevolucion}
                          setitem={setitem}
                          item={item}
                          bodegaData={bodegaData}
                        />
                      ) : null}
                      {guardias ? (
                        <ListPersonas
                          readOnly={ver || editar || item.disDevolucion}
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (
                              e.disPersonas.length === 2 ||
                              e.disSupervisores.length === 1 ||
                              e.disAdministracion.length === 1
                            ) {
                              seterror({
                                ...error,
                                disPersonas: disPersonas.length === 1,
                                disSupervisores: disSupervisores.length === 1,
                                disAdministracion:
                                  disAdministracion.length === 1,
                              });
                              return mostrarAlerta(
                                "Maximo una persona",
                                "error"
                              );
                            }
                            setitem(e);
                          }}
                          data={personasData}
                          propiedad={disPersonas}
                          propiedadString={"disPersonas"}
                        />
                      ) : null}
                      {supervisores ? (
                        <ListPersonas
                          readOnly={ver || editar || item.disDevolucion}
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (
                              e.disPersonas.length === 1 ||
                              e.disSupervisores.length === 2 ||
                              e.disAdministracion.length === 1
                            ) {
                              seterror({
                                ...error,
                                disPersonas: disPersonas.length === 1,
                                disSupervisores: disSupervisores.length === 1,
                                disAdministracion:
                                  disAdministracion.length === 1,
                              });
                              return mostrarAlerta(
                                "Maximo una persona",
                                "error"
                              );
                            }

                            setitem(e);
                          }}
                          data={supervisoresData}
                          propiedad={disSupervisores}
                          propiedadString={"disSupervisores"}
                        />
                      ) : null}
                      {administracion ? (
                        <ListPersonas
                          readOnly={ver || editar || item.disDevolucion}
                          error={error}
                          item={item}
                          setitem={(e) => {
                            if (
                              e.disPersonas.length === 1 ||
                              e.disSupervisores.length === 1 ||
                              e.disAdministracion.length === 2
                            ) {
                              seterror({
                                ...error,
                                disPersonas: disPersonas.length === 1,
                                disSupervisores: disSupervisores.length === 1,
                                disAdministracion:
                                  disAdministracion.length === 1,
                              });
                              return mostrarAlerta(
                                "Maximo una persona",
                                "error"
                              );
                            }

                            setitem(e);
                          }}
                          data={administracionData}
                          propiedad={disAdministracion}
                          propiedadString={"disAdministracion"}
                        />
                      ) : null}
                    </>
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
                <Button
                  style={{ width: "30%" }}
                  variant="contained"
                  color="primary"
                  disabled={
                    cargando ||
                    (editar && estadoRequest === "DISTRIBUCION") ||
                    ver
                  }
                  onClick={() => {
                    if (editar || item.disDevolucion) {
                      handleEditar();
                    } else {
                      handleCrear();
                    }
                  }}
                >
                  Guardar
                </Button>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalDistribucion);
