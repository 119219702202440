import React, { memo, useContext } from "react";

import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Check,
  Create,
  DeleteForever,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { usePermiso } from "../../../hooks/usePermiso";
import { trimPropsItem } from "../../../../config/const";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    socket,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // EDITAR
  const [editar, seteditar] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("PlanDeCuentas");
  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState({ ...row });
  // COLUMNAS DE LA TABLA HIJA

  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (item.plancNombre === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "plancuenta",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        plancFecReg: usuario.usuario,
        plancEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,

          tabla: "plancuenta",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          plancFecReg: usuario.usuario,
          plancEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,

          tabla: "plancuenta",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          plancFecReg: usuario.usuario + "/+/" + row[campoExport],
          estEmpresa: usuario.rucempresa,
          [campoExport]: row[campoExport],
        })
      );

      seteditar(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVA
  const funcionEdit = () => {
    setitem({ ...row });
    seteditar(true);
  };

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        plancuenta="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar(true);
              },
              disabled: usuario.usuario !== row.plancFecReg.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: editar || row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                seteditar(false);
                setitem({ ...row });
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar Edición",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: !editar,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
            {
              tooltip: "Estado",
              texto: "",
              funcion: (e) => {
                setitem({
                  ...item,
                  plancEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                });
              },
              disabled: !editar,
              Icon: "",
              color: "primary",
              id: 6,
              ocultar: row.eliminado,
              tipo: "check",
              estado: editar
                ? item.plancEstado === "ACTIVA"
                : row.plancEstado === "ACTIVA",
              variante: "",
              size: "small",
              sx: { padding: "0px" },
            },
          ]}
        />

        {editar ? (
          <>
          
          
          
          
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={item.plancCodigo}
                name="plancCodigo"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={item.plancNombre}
                name="plancNombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={item.plancTipo}
                name="plancTipo"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione Tipo</MenuItem>
                {["AGRUPACIÓN", "MOVIMIENTO"].map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </TextField>
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.plancFecReg.replace("T", " ").substr(0, 16)}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {usuario.usuario}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.plancCodigo}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.plancNombre}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.plancTipo}
            </TableCell>

            <TableCell fontSize="small" align="center" className={claseCelda}>
              {new Date(row.plancFecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              {row.plancUser.split("/+/")[0]}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
