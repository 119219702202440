import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../../../../../Context/Alerta/AlertaContext";
// MATERIAL
import {
  Autocomplete,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Popper,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";

import { Cancel, Check, Map } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../../../../config/const";
import ButtonsAction from "../../../../../../../components/ButtonsAction";
import { itemHijoDefecto } from "../data";
import { v4 } from "uuid";
import DialogMap from "../../../../../../../components/DialogMap";
import CampoDoc from "../../../../../../../components/CampoDoc";

const RowAgregarHijo = (props) => {
  // eslint-disable-next-line
  const {
    // datosOperativos,
    estadoRequest,
    fromIOP,
    planificacion,
    setagregandoActividad,
    socket,
    itemCliente,
  } = props;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [itemHijo, setitemHijo] = useState({
    ...itemHijoDefecto,
    coordenadas: planificacion.coordenadas,
    minutos: estadoRequest === "SUPERVISOR" ? "1" : itemHijoDefecto.minutos,
  });
  const [cargando, setcargando] = useState(false);
  const [openMap, setopenMap] = useState(false);
  const botonDisabled = () => {
    if (
      itemHijo.nombre === "" ||
      itemHijo.minutos === "" ||
      (itemHijo.puesto === "" && estadoRequest === "SUPERVISOR")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregandoActividad(false);
        setitemHijo({
          ...itemHijoDefecto,
          coordenadas: planificacion.coordenadas,
        });
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
      },
      disabled: botonDisabled() || cargando,
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleChange = (e) => {
    setitemHijo({
      ...itemHijo,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgregar = async () => {
    try {
      const existe = planificacion.planiActividades.some(
        (itemActividad) => itemHijo.nombre === itemActividad.nombre
      );
      if (existe) {
        return mostrarAlerta("Ya existe esta actividad", "error");
      }
      const planiActividades = [
        trimPropsItem({ ...itemHijo, id: v4() }),
        ...planificacion.planiActividades,
      ];

      socket.current.emit("client:actualizarData", {
        ...planificacion,
        tabla: "planificacionactividad",
        planiActividades,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        planiUser: usuario.usuario,
        planiEmpresa: usuario.rucempresa,
      });
      setagregandoActividad(false);
      setitemHijo({
        ...itemHijoDefecto,
        coordenadas: planificacion.coordenadas,
      });
    } catch (error) {}
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };

  return (
    <React.Fragment>
      <DialogMap
        open={openMap}
        setopen={setopenMap}
        setitem={setitemHijo}
        item={itemHijo}
        editar={true}
        propiedad={itemHijo.coordenadas}
        nombrePropiedad={"coordenadas"}
        solover={false}
      />
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />

        {/* las demas columnas */}

        <>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <TextField
              autoFocus
              value={itemHijo.nombre}
              name="nombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          {estadoRequest === "SUPERVISOR" && (
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  PopperComponent={PopperMy}
                  disableClearable={true}
                  options={itemCliente.datosoperativos.map((item) => {
                    return item.puesto + "/+/" + item.ubicacion;
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", "- ");
                  }}
                  id="controlled-demo"
                  value={
                    (itemHijo.puesto + " " + itemHijo.ubicacion).trim() === ""
                      ? ""
                      : itemHijo.puesto + " - " + itemHijo.ubicacion
                  }
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const [puesto, ubicacion] = newValue.split("/+/");
                      const itemF = itemCliente.datosoperativos.find(
                        (itemDatoO) =>
                          itemDatoO.puesto === puesto &&
                          itemDatoO.ubicacion === ubicacion
                      );

                      setitemHijo({
                        ...itemHijo,
                        coordenadas: itemF.coordenadas,
                        puesto,
                        ubicacion,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
          )}
          {planificacion.planiTipo === "RONDAS" ? (
            <TableCell
              fontSize="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                disabled
                value={
                  itemHijo.coordenadas?.latitud +
                  " " +
                  itemHijo.coordenadas?.longitud
                }
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Box htmlFor="contained-button-documento" fullWidth>
                      <InputAdornment>
                        <Tooltip title="Mostrar Mapa" arrow>
                          <Box>
                            <IconButton
                              aria-label=""
                              onClick={() => setopenMap(true)}
                            >
                              <Map color="secondary" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </InputAdornment>
                    </Box>
                  ),
                }}
              />
            </TableCell>
          ) : null}
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <TextField
              type="number"
              value={itemHijo.minutos}
              name="minutos"
              onChange={(e) => {
                setitemHijo({
                  ...itemHijo,
                  minutos: Math.max(
                    e.target.value,
                    estadoRequest === "SUPERVISOR" ? 1 : 5
                  ),
                });
              }}
              size="small"
              fullWidth
            />
          </TableCell>
          {fromIOP && (
            <>
              <TableCell size="small" align="center" className={classes.celda}>
                <TextField
                  multiline
                  value={itemHijo.vulnerabilidad}
                  name="vulnerabilidad"
                  onChange={(e) => handleChange(e)}
                  size="small"
                  fullWidth
                />
              </TableCell>
              <CampoDoc
                extension={"IMAGEN"}
                claseCelda={classes.celda}
                cargando={cargando}
                setcargando={setcargando}
                propiedad={"foto"}
                item={itemHijo}
                setitem={setitemHijo}
                celda={true}
              />
            </>
          )}
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarHijo);
