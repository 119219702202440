 

export const columns = [
  {
    id: "ausFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "ausFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "tpermisos",
    label: "T. Permisos",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tpermisos",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "ausFotos",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "ausDocuPersona",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "left",
    ocultar: true,
  },

  {
    id: "ausEstado",
    label: "Status",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "ausMotivo",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "ausFechaDesde",
    label: "F. Desde",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "ausFechaHasta",
    label: "F. Hasta",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "ausDetalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "ausFecReg",
    label: "F. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },

  {
    id: "ausUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  //   ausId: 1,
  ausIdPersona: "",
  ausDocuPersona: "",
  ausNomPersona: "",
  ausPerIdCliente: "",
  ausPerDocuCliente: "",
  ausPerNombreCliente: "",
  ausPuesto: [],
  ausMotivo: "", // [ENFERMEDADES IESS, PERMISO PERSONAL,PATERNIDAD,DEFUNCION FAMILIAR, INJUSTIFICADA]

  ausFechas: [],
  ausFechasConsultaDB: [],
  ausDetalle: "",
  ausDocumento: "", // input file
  ausFotos: [], // dropzone
  ausIdMulta: "", // vacio
  ausUser: "",
  ausEmpresa: "",
  ausFecReg: "",
  ausDiasPermiso: "1",
  Todos: "",
  ausEstado: "EN PROCESO",
  ausStatusDescripcion: "", 
};
