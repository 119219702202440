import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

// import ConfirmacionDialog from "../../../Dialog/ConfirmacionDialog";

import ConfirmacionDialog from "../../../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../../../config/validaciones";
import MenuContext from "../../../../../Context/Menu/MenuContext";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../../../config/const";
import TablaCabecera from "../../../../../components/Extra/TablaCabecera";
import RowItem from "./RowItemEdit/RowItemEvaluaciones";
import RowAgregarItem from "./RowItemEdit/RowAgregarItemEvaluaciones";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  Collapse,
  Box,
  Typography,
  IconButton,
  Table,
  TableBody,
  MenuItem,
} from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import Modal from "./Modal";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    estadoRequest,
  } = props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { socket, usuario } = useContext(AuthContext);
  const [expandir, setexpandir] = useState(false);
  const [agregarItemLista, setagregarItemLista] = useState(false);
  const classes = useRowStyles();
  const [item, setitem] = useState({ ...row });
  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.emit(
        "client:eliminarData",
        trimPropsItem({
          tabla: "evaluacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          docUser: usuario.usuario,
          docEmpresa: usuario.rucempresa,
          docId: row.docId,
        })
      );
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem({ ...row });
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "evaluacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          novUser: usuario.usuario,
          novEmpresa: usuario.rucempresa,
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.docDestinatario.trim() === "" ||
      item.docTitulo.trim() === "" ||
      item.docFechaEmision.trim() === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setitem(row);
  }, [row]);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openConfirmDialogEditar, setopenConfirmDialogEditar] = useState(false);
  const [openModal, setopenModal] = useState(false);
  return (
    <React.Fragment>
      <Modal
        open={openModal}
        setopen={setopenModal}
        propiedad={"docPersonas"}
        item={item}
        setitem={setitem}
        editar={true}
        disabled={!editar}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        funcion={handleEliminar}
      />{" "}
      <ConfirmacionDialog
        open={openConfirmDialogEditar}
        setopen={setopenConfirmDialogEditar}
        titulo={`¿Quiere notificar las evaluaciones a todas la personas?`}
        funcion={() => {
          funcSubmit();
        }}
        funcionNo={() => {
          funcSubmit();
        }}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={true}
          expandir={expandir}
          setexpandir={setexpandir}
          mostrarEspandir={true}
          campoExport={campoExport}
          campoEstado="docEstado"
          editar={editar}
          funcCancel={funcCancel}
          funcSubmit={() => {
            if (item.docEstado === "ACTIVA" && item.docNotIns === "SI") {
              setopenConfirmDialogEditar(true);
            } else {
              funcSubmit();
            }
          }}
          botonDisabled={botonDisabled}
          item={item}
          setitem={setitem}
          seleccionPersonas={true}
          setopenModal={setopenModal}
        />
        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                inputProps={{ style: { textAlign: "center" } }}
                value={item.docDestinatario}
                onChange={(e) => {
                  setitem({
                    ...item,
                    docDestinatario: e.target.value,
                    docPersonas: [],
                  });
                }}
                size="small"
                helperText="Agregando"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {[
                  "GUARDIA",
                  "SUPERVISOR",
                  "ADMINISTRACION",
                  "CLIENTE",
                  "PROVEEDOR",
                ].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                inputProps={{ style: { textAlign: "center" } }}
                value={item.docAlcance}
                onChange={(e) => {
                  setitem({
                    ...item,
                    docAlcance: e.target.value,
                  });
                }}
                size="small"
                helperText="Agregando"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="">Seleccione.</MenuItem>
                {["GLOBAL", "SELECCION"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.docTitulo}
                onChange={(e) => {
                  setitem({
                    ...item,
                    docTitulo: e.target.value,
                  });
                }}
                size="small"
                helperText="Agregando"
                fullWidth
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="date"
                value={item.docFechaEmision}
                onChange={(e) => {
                  setitem({
                    ...item,
                    docFechaEmision: e.target.value,
                  });
                }}
                size="small"
                helperText="Agregando"
                fullWidth
              />
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="date"
                value={item.docFechaFinalizacion}
                onChange={(e) => {
                  setitem({
                    ...item,
                    docFechaFinalizacion: e.target.value,
                  });
                }}
                size="small"
                helperText="Agregando"
                fullWidth
              />
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                inputProps={{ style: { textAlign: "center" } }}
                value={item.docNotIns}
                onChange={(e) => {
                  setitem({
                    ...item,
                    docNotIns: e.target.value,
                  });
                }}
                size="small"
                helperText="Agregando"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="" disabled>
                  Seleccione.
                </MenuItem>
                {["SI", "NO"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                inputProps={{ style: { textAlign: "center" } }}
                value={item.docObligatorio}
                onChange={(e) => {
                  setitem({
                    ...item,
                    docObligatorio: e.target.value,
                  });
                }}
                size="small"
                helperText="Agregando"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
              >
                <MenuItem value="" disabled>
                  Seleccione.
                </MenuItem>
                {["SI", "NO"].map((item) => {
                  return <MenuItem value={item}>{item} </MenuItem>;
                })}
              </TextField>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.docDestinatario}
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.docAlcance}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.docTitulo}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.docFechaEmision}
            </TableCell>{" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.docFechaFinalizacion}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.docNotIns}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {item.docObligatorio}
            </TableCell>
          </>
        )}

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {new Date(row.docFecReg).toLocaleString("es-ES", {
            weekday: "short",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour: "2-digit",
          })}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docUser}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
          
                alignItems="center"
              >
                <Typography variant="body1" gutterBottom component="span">
                  Preguntas
                </Typography>
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "pregunta",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "pregunta",
                    },

                    {
                      id: "tipoCampo",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "tipo Campo",
                    },
                    {
                      id: "opcionesLista",
                      align: "center",
                      minWidth: 300,
                      colSpan: 1,
                      label: "opciones Lista",
                    },
                    {
                      id: "respuestas",
                      align: "center",
                      minWidth: 300,
                      colSpan: 1,
                      label: "Respuestas",
                    },
                  ]}
                />
                <TableBody>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      estadoRequest={estadoRequest}
                      item={item}
                      setitem={setitem}
                      setagregarItemLista={setagregarItemLista}
                
                    />
                  ) : null}
                  {item.docPreguntas.map((consigna, index) => {
                    return (
                      <RowItem
                        estadoRequest={estadoRequest}
                        consigna={{
                          ...consigna,
                          respuestas: consigna.respuestas
                            ? consigna.respuestas
                            : [],
                        }}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
