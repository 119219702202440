import React, { memo } from "react";
import { useState } from "react"; 
import { trimPropsItem } from "../../../../../config/const";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField, 
  MenuItem,
} from "@mui/material";
import {
  Cancel,
  Check, 
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material"; 
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { setagregar, setitem, item } = props;
  const [expandir, setexpandir] = useState(false); 
  const [itemDatoOperativo, setitemDatoOperativo] = useState({
    ubicacion: "",
    puesto: "",
    supervisor: "",
    guardias: "",
    horasservicio: "",
    tipoinstalacion: "",

    consignas: [],
  });
  const botonDisabled = () => {
    if (itemDatoOperativo.puesto === "") {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();

  const handleAgregar = async () => {
    try {
      const cliDatosOperativos = [
        trimPropsItem(itemDatoOperativo),
        ...item.cliDatosOperativos,
      ];

      setitem({
        ...item,
        cliDatosOperativos,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitemDatoOperativo({
                  ubicacion: "",
                  puesto: "",
                  supervisor: "",
                  guardias: "",
                  horasservicio: "",
                  tipoinstalacion: "",

                  consignas: [],
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitemDatoOperativo({
                  ubicacion: "",
                  puesto: "",
                  supervisor: "",
                  guardias: "",
                  horasservicio: "",
                  tipoinstalacion: "",

                  consignas: [],
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <IconButton
              className={classes.margin}
              onClick={() => {
                setexpandir(!expandir);
              }}
            >
              {expandir ? (
                <ExpandLess fontSize="small" color="secondary" />
              ) : (
                <ExpandMore fontSize="small" color="secondary" />
              )}
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={itemDatoOperativo.ubicacion}
            onChange={(e) =>
              setitemDatoOperativo({
                ...itemDatoOperativo,
                ubicacion: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={itemDatoOperativo.puesto}
            onChange={(e) =>
              setitemDatoOperativo({
                ...itemDatoOperativo,
                puesto: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            InputProps={{
              inputProps: { min: 0 },
            }}
            type="number"
            value={itemDatoOperativo.supervisor}
            onChange={(e) =>
              setitemDatoOperativo({
                ...itemDatoOperativo,
                supervisor: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            InputProps={{
              inputProps: { min: 0 },
            }}
            type="number"
            value={itemDatoOperativo.guardias}
            onChange={(e) =>
              setitemDatoOperativo({
                ...itemDatoOperativo,
                guardias: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            value={itemDatoOperativo.horasservicio}
            onChange={(e) =>
              setitemDatoOperativo({
                ...itemDatoOperativo,
                horasservicio: e.target.value,
              })
            }
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["24", "12", "8", "4"].map((item) => {
              return <MenuItem value={item}>{item} </MenuItem>;
            })}
          </TextField>
        </TableCell>

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id=""
            multiline
            rows={2}
            value={itemDatoOperativo.tipoinstalacion}
            onChange={(e) =>
              setitemDatoOperativo({
                ...itemDatoOperativo,
                tipoinstalacion: e.target.value,
              })
            }
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
     
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
