import React, { useEffect, useState } from "react";
import { Doughnut } from "@reactchartjs/react-chart.js";
import { Typography } from "@mui/material";
import Cargando from "../components/Cargando";
import { reducer } from "../functions/funciones";

const DoughnutChart = ({ titulo, dataGrafico }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  function sliceArray(array, index) {
    return array.slice(0, index);
  }
  const backgroundColorData = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
  ];
  const borderColorData = [
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(75, 192, 192, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];
  const [data, setdata] = useState(null);
  const [total] = useState(
    Object.values(dataGrafico).length === 0
      ? null
      : Object.values(dataGrafico)
          .map((numero) => {
            return Number(numero);
          })
          .reduce(reducer)
          .toFixed(0)
  );
  useEffect(() => {
    const labels = Object.entries(dataGrafico).map((item) => {
      return `${capitalizeFirstLetter(item[0].replace("total_", ""))}: ${
        Math.round((item[1] / total) * 100 * 100) / 100
      }%`;
    });
    const data = Object.values(dataGrafico).map((item) => item);
    const backgroundColor = sliceArray(
      backgroundColorData,
      Object.keys(dataGrafico).length
    );
    const borderColor = sliceArray(
      borderColorData,
      Object.keys(dataGrafico).length
    );
    setdata({
      labels,
      datasets: [
        {
          label: "Personas",
          data,
          backgroundColor,
          borderColor,
          borderWidth: 1,
        },
      ],
    });
    // eslint-disable-next-line
  }, []);
  if (!data) {
    return <Cargando titulo="Cargando Personal" />;
  }
  return (
    <>
      <div className="header">
        <Typography variant="h5" align="center" color="secondary">
          {titulo}:{" "}
          <Typography variant="h5" color="primary" component={"span"}>
            {Object.values(dataGrafico).length === 0
              ? null
              : Object.values(dataGrafico)
                  .map((numero) => {
                    return Number(numero);
                  })
                  .reduce(reducer)
                  .toFixed(0)}
          </Typography>
        </Typography>
      </div>
      <Doughnut data={data} height={100} />
    </>
  );
};

export default DoughnutChart;
