import React, { useState, useContext, useEffect, memo } from "react";
// MODULOS
import Draggable from "react-draggable";

// FUNCIONES
import { replaceCaracteres, trimPropsItem } from "../../../config/const";

// AXIOS
import clienteAxios from "../../../config/axios";
// CONTEXT
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
// MATERIAL
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  FormControl,
  Autocomplete,
  FormControlLabel,
  Switch,
  InputAdornment,
  LinearProgress,
  MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Check, Close, Search } from "@mui/icons-material";

import { useModalStyle } from "../../styles/styleModal";

import { objectDefault } from "../data";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import CamposAcumulables from "../../components/Campos/CamposAcumulables";
import { validarEmail } from "../../../config/validaciones";
import CamposAcumulablesCustom from "./CamposAcumulablesCustom";
import Bitacora from "../../Bitacora/Bitacora";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalResidentes = (props) => {
  //props
  const {
    // estadoRequest,
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const [item, setitem] = useState(ObjectoActivo);
  const {
    // resId,
    // resCliId,
    resCliDocumento,
    resCliNombre,
    resCliUbicacion,
    resCliPuesto,
    resCedula,
    resNombres,
    resTelefono,
    resDepartamento,
    resObservacion,
    resCorreo,
    resEstado,
    // resUser,
    // resEmpresa,
    // resFecReg,
    // resFecUpd,
    resTipoResidente,
    resNombrePropietario,
    resTelefonoPropietario,
    resCorreoPropietario,
    resCedulaPropietario,
    resApellidos,
    resApellidoPropietario,
  } = item;
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  //  STATE
  const [clientesData, setclientesData] = useState([]);
  const [puestosData, setpuestosData] = useState([]);

  const [cargandoDoc, setcargandoDoc] = useState(false);
  const [openDialogClose, setopenDialogClose] = useState(false);
  const [modificado, setmodificado] = useState(false);
  const esGuardia = usuario.rol.includes("GUARDIA");
  // SECCION
  const seccionDefault = {
    seccionDatosPrincipales: true,
    seccionVisitantes: false,
  };
  const [seccionActive, setseccionActive] = useState(seccionDefault);
  const { seccionDatosPrincipales, seccionVisitantes } = seccionActive;
  // DE SIEMPRE
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const clases = useModalStyle();
  const [modalStyle] = useState(getModalStyle);

  useEffect(() => {
    if (open) {
      setmodificado(true);
    }
    // eslint-disable-next-line
  }, [item]);
  useEffect(() => {
    setitem(ObjectoActivo);
    setTimeout(() => {
      setmodificado(false);
    }, 500);

    // eslint-disable-next-line
  }, [ObjectoActivo]);

  const defaultActive = (args) => {
    changeObjetoActivoState(objectDefault);
    setitem(objectDefault);

    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
    setseccionActive(seccionDefault);
    setopenDialogClose(false);
    setmodificado(false);
  };
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };
  function tienePropiedadVerdadera(objeto) {
    return Object.values(objeto).some((valor) => valor === true);
  }
  const validacion = () => {
    const errorValidacion = {};

    // !quitarCamposPorRol && bitResCedula.trim() === "";
    errorValidacion.resCliDocumento =
      !esGuardia && resCliDocumento.trim() === "";
    errorValidacion.resCliUbicacion =
      !esGuardia && resCliUbicacion.trim() === "";
    errorValidacion.resCedulaPropietario = resCedulaPropietario.trim() === "";
    errorValidacion.resNombrePropietario = resNombrePropietario.trim() === "";
    errorValidacion.resTelefonoPropietario =
      resTelefonoPropietario.length === 0;
    errorValidacion.resCedula =
      resCedula === "" && item.resTipoResidente === "ARRENDATARIO";
    errorValidacion.resCorreoPropietario =
      resCorreoPropietario.some((correo) => validarEmail(correo)) ||
      resCorreoPropietario.length === 0;
    if (tienePropiedadVerdadera(errorValidacion)) {
      mostrarAlerta("Faltan campos obligatorios", "error");
      seterror(errorValidacion);
    }

    return tienePropiedadVerdadera(errorValidacion);
  };
  const handleCrear = async () => {
    try {
      if (validacion()) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "residente",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          resUser: usuario.usuario,
          resEmpresa: usuario.rucempresa,
          regId: esGuardia ? usuario.regId : 0,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      if (validacion()) {
        return mostrarAlerta("Faltan campos obligatorios", "error");
      }
      setcargando(true);
      console.log(
        trimPropsItem({
          ...item,
          tabla: "residente",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          resUser: usuario.usuario,
          resEmpresa: usuario.rucempresa,
        })
      );
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "residente",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          resUser: usuario.usuario,
          resEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive({ eliminarForm: true });
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleGurdar = () => {
    if (editar) {
      handleEditar();
    } else {
      handleCrear();
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <ConfirmacionDialog
          open={openDialogClose}
          setopen={() => setopenDialogClose(false)}
          titulo={"Desea mantener los cambios?"}
          categoria="warning"
          botones={[
            {
              tooltip: "Mantener datos",
              texto: "SI",
              funcion: () => {
                defaultActive({ eliminarForm: false });
              },
              disabled: false,
              Icon: Check,
              color: "secondary",
              id: 1,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: { marginRight: ".3rem" },
            },
            {
              tooltip: "Vaciar datos",
              texto: "NO",
              funcion: () => {
                defaultActive({ eliminarForm: true });
              },
              disabled: false,
              Icon: Close,
              color: "error",
              id: 2,
              ocultar: false,
              tipo: "boton",
              variante: "contained",
              size: "medium",
              sx: {},
            },
          ]}
        />
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} Residentes.`}
                  {/* <Tooltip title={"Guardar"} followCursor placement="top-start">
                    <IconButton
                      onClick={() => {
                        handleGurdar();
                      }}
                    >
                      <Save color="secondary" />
                    </IconButton>
                  </Tooltip> */}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={() => {
                      defaultActive();
                      // setopenDialogClose(true);
                    }}
                  >
                    <Close color={modificado ? "error" : "primary"} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display={"flex"} flexWrap={"wrap"}>
                  <Button
                    variant={
                      seccionDatosPrincipales
                        ? "contained"
                        : error.resCliDocumento ||
                          error.resCliUbicacion ||
                          error.resCedula ||
                          error.resNombres ||
                          error.resTelefono ||
                          error.resDepartamento
                        ? "outlined"
                        : "null"
                    }
                    color={
                      error.resCliDocumento ||
                      error.resCliUbicacion ||
                      error.resCedula ||
                      error.resNombres ||
                      error.resTelefono ||
                      error.resDepartamento
                        ? "error"
                        : "primary"
                    }
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionDatosPrincipales: true })
                    }
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={
                      seccionVisitantes
                        ? "contained"
                        : false
                        ? "outlined"
                        : "null"
                    }
                    color={false ? "error" : "primary"}
                    disableElevation
                    className={clases.buttonTab}
                    size="small"
                    onClick={() =>
                      setseccionActive({ seccionVisitantes: true })
                    }
                  >
                    Visitantes
                  </Button>
                </Box>
                {/* <FormControlLabel
                  control={
                    <Switch
                      checked={resEstado === "ACTIVO" ? true : false}
                      onChange={(e) => {
                        setitem({
                          ...item,
                          resEstado: e.target.checked ? "ACTIVO" : "INACTIVO",
                        });
                      }}
                      color="primary"
                    />
                  }
                  label="Estado"
                /> */}
              </Box>
              <Divider />
              <Box className={clases.container}>
                {seccionDatosPrincipales && (
                  <>
                    {esGuardia ? null : (
                      <>
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            options={clientesData.map((item) => {
                              return (
                                item.cliDocNumero +
                                "/+/" +
                                item.cliRazonSocial +
                                "/+/" +
                                item.cliId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={(
                              resCliNombre +
                              " " +
                              resCliDocumento
                            ).trim()}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const [
                                  resCliDocumento,
                                  resCliNombre,
                                  resCliId,
                                ] = newValue.split("/+/");
                                const itemF = clientesData.find(
                                  (itemCliente) =>
                                    itemCliente.cliId === Number(resCliId)
                                );
                                setpuestosData(itemF.cliDatosOperativos);
                                setitem({
                                  ...item,
                                  resCliDocumento,
                                  resCliNombre,
                                  resCliId,
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Cliente"
                                error={error.resCliDocumento}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    const res = await clienteAxios.get(
                                      `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                    );

                                    setclientesData(res.data.data);
                                  } catch (error) {
                                    return mostrarAlerta(
                                      "Hubo un error",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl className={clases.formD}>
                          <Autocomplete
                            disableClearable={true}
                            options={puestosData.map((item) => {
                              return item.ubicacion + "/+/" + item.puesto;
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " - ");
                            }}
                            value={
                              `${resCliUbicacion} ${resCliPuesto}`.trim() === ""
                                ? ""
                                : `${resCliUbicacion} - ${resCliPuesto}`.trim()
                            }
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                try {
                                  const [
                                    resCliUbicacion,
                                    resCliPuesto,
                                  ] = newValue.split("/+/");

                                  setitem({
                                    ...item,
                                    resCliUbicacion,
                                    resCliPuesto,
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="Puesto"
                                error={error.resCliUbicacion}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </>
                    )}
                    <TextField
                      label="Tipo Residente"
                      error={error.resTipoResidente}
                      value={resTipoResidente}
                      name="resTipoResidente"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="">Seleccione</MenuItem>
                      {["PROPIETARIO", "ARRENDATARIO"].map((item, index) => (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      ))}
                    </TextField>
                    {resTipoResidente === "ARRENDATARIO" && (
                      <>
                        <Divider className={clases.formAllW}>
                          Arrendatario
                        </Divider>
                        <FormControl
                          component="fieldset"
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.form33}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormLabel
                              component="span"
                              color="primary"
                              style={{
                                marginRight: ".5rem",
                                fontWeight: "bold",
                              }}
                            >
                              Tipo Documento
                            </FormLabel>
                            <RadioGroup
                              onChange={(e) =>
                                setitem({
                                  ...item,
                                  resPerId: 0,
                                  resCedula: "",
                                  resNombres: "",
                                  resTelefono: [],
                                  resApellidos: "",
                                  resCorreo: [], // input
                                  resDepartamento: [],
                                  resTipoDocu: e.target.value,
                                })
                              }
                              row
                              aria-label="gender"
                              value={item.resTipoDocu}
                              name="resTipoDocu"
                            >
                              <FormControlLabel
                                value="CEDULA"
                                control={<Radio />}
                                label="CEDULA"
                              />
                              <FormControlLabel
                                value="PASAPORTE"
                                control={<Radio />}
                                label="PASAPORTE"
                              />
                            </RadioGroup>
                          </Box>
                        </FormControl>{" "}
                        <TextField
                          label="Documento Arrendatario - Presione 'Enter' para buscar"
                          error={error.resCedula}
                          value={resCedula}
                          name="resCedula"
                          onChange={(e) => {
                            setitem({
                              ...item,
                              resCedula: e.target.value.substring(0, 10),
                            });
                          }}
                          className={clases.formD}
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <Typography
                                  color={
                                    resCedula?.length === 10
                                      ? "error"
                                      : "secondary"
                                  }
                                >
                                  {resCedula?.length || 0} / 10
                                </Typography>
                                <IconButton
                                  aria-label=""
                                  onClick={async (e) => {
                                    if (resCedula === "") {
                                      return mostrarAlerta(
                                        "Ingrese un documento",
                                        "error"
                                      );
                                    }
                                    try {
                                      setcargando(true);

                                      const res = await clienteAxios.get(
                                        `personas/searchByCedulaOfRuc/0?search=${resCedula}&perfil=RESIDENTE&tipoDocumento=${item.resTipoDocu}`
                                      );
                                      if (res?.data) {
                                        //                                        //PROPIETARIO
                                        // resPerIdPropietario: 0,
                                        // resCedulaPropietario: "",
                                        // resNombrePropietario: "", // string
                                        // resTelefonoPropietario: [], // string
                                        // resApellidoPropietario: "",
                                        // resCorreoPropietario: "",
                                        // //ARRENDATARIO
                                        // resCedula: "",
                                        // resNombres: "",
                                        // resApellidos: "",
                                        // resTelefono: [],
                                        // resCorreo: [], // input
                                        // resDepartamento: [],
                                        setitem({
                                          ...item,
                                          resPerId: res.data.perId || 0,
                                          resCedula:
                                            res.data.perDocNumero || "",
                                          resNombres: res.data.perNombres || "",
                                          resApellidos:
                                            res.data.perApellidos || "",
                                          resTelefono:
                                            res.data.perTelefono || "",
                                          resCorreo: res.data.perEmail || [],
                                        });
                                      }
                                      setcargando(false);
                                    } catch (error) {
                                      console.log({ error });
                                      setcargando(false);
                                      mostrarAlerta(
                                        error?.response?.data?.msg,
                                        "error"
                                      );
                                    }
                                  }}
                                >
                                  <Search color="secondary" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onKeyDown={async (e) => {
                            if (e.key !== "Enter") {
                              return;
                            }
                            if (e.target.value === "") {
                              return;
                            }
                            try {
                              setcargando(true);

                              const res = await clienteAxios.get(
                                `personas/searchByCedulaOfRuc/0?search=${resCedula}&perfil=RESIDENTE&tipoDocumento=${item.resTipoDocu}`
                              );
                              if (res?.data) {
                                ////PROPIETARIO
                                // resPerIdPropietario: 0,
                                // resCedulaPropietario: "",
                                // resNombrePropietario: "", // string
                                // resTelefonoPropietario: [], // string
                                // resApellidoPropietario: "",
                                // resCorreoPropietario: "",
                                // //ARRENDATARIO
                                // resCedula: "",
                                // resNombres: "",
                                // resApellidos: "",
                                // resTelefono: [],
                                // resCorreo: [], // input
                                // resDepartamento: [],
                                console.log({ res });
                                setitem({
                                  ...item,
                                  resPerId: res.data.perId || 0,
                                  resCedula: res.data.perDocNumero || "",
                                  resNombres: res.data.perNombres || "",
                                  resApellidos: res.data.perApellidos || "",
                                  resTelefono: res.data.perTelefono || "",
                                  resCorreo: res.data.perEmail || [],
                                });
                              }
                              setcargando(false);
                            } catch (error) {
                              console.log({ error });
                              setcargando(false);
                              mostrarAlerta(
                                error?.response?.data?.msg,
                                "error"
                              );
                            }
                          }}
                        />
                        <TextField
                          InputLabelProps={{ shrink: resApellidos }}
                          label="Apellido Arrendatario"
                          error={error.resApellidos}
                          value={resApellidos}
                          name="resApellidos"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                        />
                        <TextField
                          InputLabelProps={{ shrink: resNombres }}
                          label="Nombre Arrendatario"
                          error={error.resNombres}
                          value={resNombres}
                          name="resNombres"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                        />
                        {/* <TextField
                          label=
                          error={error.resTelefonoPropietario}
                          value={resTelefonoPropietario}
                          name="resTelefonoPropietario"
                          onChange={(e) => handleChange(e)}
                          className={clases.formD}
                          size="small"
                        />  */}
                        <CamposAcumulables
                          error={error}
                          propiedadTexto={"resTelefono"}
                          item={item}
                          setitem={setitem}
                          tipoCampos="telefono"
                          etiqueta={"Teléfono Arrendatario"}
                          etiquetaCampo={"Teléfono"}
                        />
                        <CamposAcumulables
                          error={error}
                          propiedadTexto={"resCorreo"}
                          item={item}
                          setitem={setitem}
                          tipoCampos="text"
                          etiqueta={"Correo Arrendatario"}
                          etiquetaCampo={"Correo"}
                        />
                      </>
                    )}
                    {/* 
                    <TextField
                      label="Cédula"
                      error={error.resCedula}
                      value={resCedula}
                      name="resCedula"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    /> */}
                    <Divider className={clases.formAllW}>Propietario</Divider>
                    <FormControl
                      component="fieldset"
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.form33}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          component="span"
                          color="primary"
                          style={{
                            marginRight: ".5rem",
                            fontWeight: "bold",
                          }}
                        >
                          Tipo Documento
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) =>
                            setitem({
                              ...item,
                              resPerIdPropietario: 0,
                              resCedulaPropietario: "",
                              resNombrePropietario: "", // string
                              resTelefonoPropietario: [], // string
                              resCorreoPropietario: [],
                              resApellidoPropietario: "",
                              resTipoDocuPropietario: e.target.value,
                            })
                          }
                          row
                          aria-label="gender"
                          value={item.resTipoDocuPropietario}
                          name="resTipoDocuPropietario"
                        >
                          <FormControlLabel
                            value="CEDULA"
                            control={<Radio />}
                            label="CEDULA"
                          />
                          <FormControlLabel
                            value="PASAPORTE"
                            control={<Radio />}
                            label="PASAPORTE"
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>{" "}
                    <Box
                      className={clases.formD}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <TextField
                        fullWidth
                        // disabled={editar}
                        size="small"
                        label={
                          "Doc. Propietario - Presione 'Enter' para buscar"
                        }
                        error={error.resCedulaPropietario}
                        value={item.resCedulaPropietario}
                        onChange={(e) => {
                          setitem({
                            ...item,
                            resCedulaPropietario: e.target.value.substring(
                              0,
                              10
                            ),
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <Typography
                                color={
                                  resCedulaPropietario.length === 10
                                    ? "error"
                                    : "secondary"
                                }
                              >
                                {resCedulaPropietario.length} / 10
                              </Typography>
                              <IconButton
                                aria-label=""
                                onClick={async (e) => {
                                  if (item.resCedulaPropietario === "") {
                                    return mostrarAlerta(
                                      "Ingrese un documento",
                                      "error"
                                    );
                                  }
                                  try {
                                    setcargandoDoc(true);

                                    const res = await clienteAxios.get(
                                      `/personas/searchByCedulaOfRuc/0?search=${item.resCedulaPropietario}&perfil=PROPIETARIO&tipoDocumento=${item.resTipoDocuPropietario}`
                                    );
                                    ////PROPIETARIO
                                    // resPerIdPropietario: 0,
                                    // resCedulaPropietario: "",
                                    // resNombrePropietario: "", // string
                                    // resTelefonoPropietario: [], // string
                                    // resApellidoPropietario: "",
                                    // resCorreoPropietario: "",
                                    setitem({
                                      ...item,
                                      resPerIdPropietario: res.data.perId || 0,
                                      resCedulaPropietario:
                                        res.data?.perDocNumero || "", //ENDPOINT
                                      resNombrePropietario:
                                        res.data?.perNombres || "", //ENDPOINT
                                      resApellidoPropietario:
                                        res.data?.perApellidos || "", //ENDPOINT
                                      resTelefonoPropietario:
                                        res.data?.perTelefono || [], //ENDPOINT
                                      resCorreoPropietario:
                                        res.data?.perEmail || [], //ENDPOINT
                                      // resDepartamento:
                                      //   res.data?.perAreaDepartamento, //ENDPOINT?
                                    });

                                    setcargandoDoc(false);
                                  } catch (error) {
                                    console.log({ error });
                                    setcargandoDoc(false);
                                    mostrarAlerta(
                                      error?.response?.data?.msg,
                                      "error"
                                    );
                                    setitem({
                                      ...item,
                                    });
                                  }
                                }}
                              >
                                <Search color="secondary" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onKeyDown={async (e) => {
                          if (e.key !== "Enter") {
                            return;
                          }
                          if (e.target.value === "") {
                            return mostrarAlerta(
                              "Ingrese un documento",
                              "error"
                            );
                          }
                          try {
                            setcargandoDoc(true);

                            const res = await clienteAxios.get(
                              `/personas/searchByCedulaOfRuc/0?search=${item.resCedulaPropietario}&perfil=PROPIETARIO&tipoDocumento=${item.resTipoDocuPropietario}`
                            );
                            ////PROPIETARIO
                            // resPerIdPropietario: 0,
                            // resCedulaPropietario: "",
                            // resNombrePropietario: "", // string
                            // resTelefonoPropietario: [], // string
                            // resApellidoPropietario: "",
                            // resCorreoPropietario: "",
                            setitem({
                              ...item,
                              resPerIdPropietario: res.data.perId || 0,
                              resCedulaPropietario:
                                res.data?.perDocNumero || "", //ENDPOINT
                              resNombrePropietario: res.data?.perNombres || "", //ENDPOINT
                              resApellidoPropietario:
                                res.data?.perApellidos || "", //ENDPOINT
                              resTelefonoPropietario:
                                res.data?.perTelefono || [], //ENDPOINT
                              resCorreoPropietario: res.data?.perEmail || [], //ENDPOINT
                              // resDepartamento:
                              //   res.data?.perAreaDepartamento, //ENDPOINT?
                            });

                            setcargandoDoc(false);
                          } catch (error) {
                            console.log({ error });
                            setcargandoDoc(false);
                            mostrarAlerta(error?.response?.data?.msg, "error");
                            setitem({
                              ...item,
                            });
                          }
                        }}
                      />
                      {cargandoDoc && (
                        <LinearProgress
                          color="primary"
                          fourColor
                          variant="query"
                        />
                      )}
                    </Box>
                    {/* <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        options={residentesData.map((item) => {
                          return item.ubicacion + "/+/" + item.puesto;
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " - ");
                        }}
                        value={
                          `${resCedula} ${resNombres}`.trim() === ""
                            ? ""
                            : `${resCedula} - ${resNombres}`.trim()
                        }
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            try {
                              const [resCedula, resNombres] = newValue.split(
                                "/+/"
                              );

                              setitem({
                                ...item,
                                resCedula,
                                resNombres,
                              });
                            } catch (error) {
                              return mostrarAlerta("No hay personas", "error");
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            label="Residente"
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta(
                                  "Ingrese datos",
                                  "warning"
                                );
                              }
                            }}
                            error={error.resCedula}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl> */}
                    <TextField
                      label={"Apellido Propietario"}
                      error={error.resApellidoPropietario}
                      value={resApellidoPropietario}
                      name="resApellidoPropietario"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <TextField
                      label={"Nombre Propietario"}
                      error={error.resNombrePropietario}
                      value={resNombrePropietario}
                      name="resNombrePropietario"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                    <CamposAcumulables
                      error={error}
                      propiedadTexto={"resCorreoPropietario"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="text"
                      etiqueta={"Correos"}
                      etiquetaCampo={"Correo"}
                    />
                    <CamposAcumulables
                      error={error}
                      propiedadTexto={"resTelefonoPropietario"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="telefono"
                      etiqueta={"Teléfonos"}
                      etiquetaCampo={"Teléfono"}
                    />
                    <CamposAcumulablesCustom
                      error={error}
                      propiedadTexto={"resDepartamento"}
                      item={item}
                      setitem={setitem}
                      tipoCampos="text"
                      etiqueta={"Propiedad"}
                      etiquetaCampo={"Area Departamento"}
                    />
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={3}
                      variant="outlined"
                      label="Observación"
                      error={error.resObservacion}
                      value={resObservacion}
                      name="resObservacion"
                      onChange={(e) => handleChange(e)}
                      className={clases.formD}
                      size="small"
                    />
                  </>
                )}
                {seccionVisitantes && (
                  <>
                    <Bitacora from_residente />
                  </>
                )}
              </Box>
              <Divider />
              <Box
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    onClick={() => {
                      handleGurdar();
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalResidentes);
