import React, { memo } from "react";
import { useState } from "react";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));
const RowAgregarArma = (props) => {
  const { setagregar, setitem, item } = props;
  const [itemRow, setItemRow] = useState({
    valor: "",
    nombre: "",
  });
  const botonDisabled = () => {
    if (itemRow.valor === "" || itemRow.nombre === "") {
      return true;
    } else {
      return false;
    }
  };

  const classes = useRowStyles();
   

  const handleAgregar = async () => {
    try {
      const rolpLiquidaciones = [
        {
          ...itemRow,
        },
        ...item.rolpLiquidaciones,
      ];

      setitem({
        ...item,
        rolpLiquidaciones,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setItemRow({
                  valor: "",
                  nombre: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              className={classes.margin}
              disabled={botonDisabled()}
              color={botonDisabled() ? "secondary" : "inherit"}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setItemRow({
                  valor: "",
                  nombre: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="nombre"
            value={itemRow.nombre}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                nombre: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando nombre"
          />
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="valor"
            type="number"
            value={itemRow.valor}
            onChange={(e) =>
              setItemRow({
                ...itemRow,
                valor: e.target.value,
              })
            }
            size="small"
            fullWidth
            helperText="Agregando valor"
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarArma);
