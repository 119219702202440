import React, { memo, useContext } from "react";

import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import {
  AddCircle,
  Cancel,
  DeleteForever,
  ExpandLess,
  ExpandMore,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import TablaCabecera from "../../../components/TablaCabecera";
import RowAgregarItem from "../Componentes/RowItems/RowAgregarItem";
import RowItem from "../Componentes/RowItems/RowItem";
import { columnasHijo } from "../data";
import { useEffect } from "react";
import { reducer } from "../../../functions/funciones";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import ToolBarTable from "../../../components/ToolBarTable";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    arrayExport,
    setarrayExport,
    clienteDatoOpertaivo,
    index,
    campoExport,
    socket,
    horario,
    activo,
    setactivo,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    clienteDatoOpertaivo.editado === true
      ? classes.celdaEditado
      : clienteDatoOpertaivo.nuevo === true
      ? classes.celdaNuevo
      : clienteDatoOpertaivo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  const {
    agregandoDato,
    setagregandoDato,
    pageDato,
    // setPageDato,
    rowsPerPageDato,
    // setRowsPerPageDato,
    handleChangePageDato,
    handleChangeRowsPerPageDato,
  } = useSimpleTable("Dato");

  // CONTEXT
  const { usuario } = useContext(AuthContext);

  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // EXPANDIR
  const [expandir, setexpandir] = useState(false);
  // COPIA DEL REGISTRO PARA EDITAR
  const [itemclienteDatoOpertaivo, setitemclienteDatoOpertaivo] = useState({
    ...clienteDatoOpertaivo,
  });
  // GUARDIAS TOTALES
  const [guardiasTotales, setguardiasTotales] = useState(
    itemclienteDatoOpertaivo.datosoperativos.length === 0
      ? null
      : itemclienteDatoOpertaivo.datosoperativos
          .map((row) => {
            return Number(row.guardias);
          })
          .reduce(reducer)
          .toFixed(0)
  );

  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "datosoperativos",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        estUser: usuario.usuario,
        estEmpresa: usuario.rucempresa,
        [campoExport]: clienteDatoOpertaivo[campoExport],
        datosoperativosOld: clienteDatoOpertaivo.datosoperativos,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };

  useEffect(() => {
    setitemclienteDatoOpertaivo(clienteDatoOpertaivo);
    setguardiasTotales(
      clienteDatoOpertaivo.datosoperativos.length === 0
        ? null
        : clienteDatoOpertaivo.datosoperativos
            .map((row) => {
              return Number(row.guardias);
            })
            .reduce(reducer)
            .toFixed(0)
    );
    // eslint-disable-next-line
  }, [clienteDatoOpertaivo]);
  if (activo !== clienteDatoOpertaivo[campoExport] && activo !== "") {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow
        className={classes.root}
        selected={index % 2 === 0}
        component={"form"}
      >
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          // campoExport={campoExport}
          row={clienteDatoOpertaivo}
          botones={[
            {
              tooltip: "Expandir",
              texto: clienteDatoOpertaivo.datosoperativos.length,
              funcion: () => {
                setexpandir((prev) => !prev);
              },
              disabled: false,
              Icon: expandir ? ExpandLess : ExpandMore,
              color: "secondary",
              id: 3,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip:
                activo === clienteDatoOpertaivo[campoExport]
                  ? "Mostrar los demas registros"
                  : "Ocultar los demas registros",
              texto: "",
              funcion: () => {
                activo === clienteDatoOpertaivo[campoExport]
                  ? setactivo("")
                  : setactivo(clienteDatoOpertaivo[campoExport]);
              },
              disabled: false,
              Icon:
                activo === clienteDatoOpertaivo[campoExport]
                  ? VisibilityOff
                  : Visibility,
              color:
                activo === clienteDatoOpertaivo[campoExport]
                  ? "error"
                  : "primary",
              id: 4,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {clienteDatoOpertaivo.doc}
          </TableCell>
          <TableCell size="small" align="left" className={claseCelda}>
            {clienteDatoOpertaivo.razonsocial}
          </TableCell>
        </>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, padding: 0, margin: 0 }}
          colSpan={21}
        >
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Paper>
                <ToolBarTable
                  count={clienteDatoOpertaivo.datosoperativos.length}
                  rowsPerPage={rowsPerPageDato}
                  page={pageDato}
                  handleChangePage={handleChangePageDato}
                  handleChangeRowsPerPage={handleChangeRowsPerPageDato}
                  ocultarPaginacion={false}
                  simplePagination={true}
                  botonesIzquierdos={[
                    {
                      tooltip: "Agregar",
                      texto: "Agregar",
                      funcion: () => {
                        setagregandoDato(true);
                      },
                      disabled:
                        Number(guardiasTotales) >=
                        clienteDatoOpertaivo.perGuardias,
                      Icon: AddCircle,
                      color: "primary",
                      id: 1,
                      ocultar: true,
                      tipo: "boton",
                      variante: "contained",
                      size: "medium",
                      sx: {},
                    },
                  ]}
                  botonesDerechos={[]}
                />

                <TableContainer>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TablaCabecera
                        columns={columnasHijo}
                        habilitarOrdenar={false}
                      />
                    </TableHead>
                    <TableBody component={"div"}>
                      {agregandoDato ? (
                        <RowAgregarItem
                          itemPadre={clienteDatoOpertaivo}
                          setagregandoDato={setagregandoDato}
                          socket={socket}
                          guardiasTotales={guardiasTotales}
                        />
                      ) : null}
                      {clienteDatoOpertaivo.datosoperativos
                        .slice(
                          pageDato * rowsPerPageDato,
                          pageDato * rowsPerPageDato + rowsPerPageDato
                        )
                        .map((datoOperativo, index) => {
                          return (
                            <RowItem
                              horario={horario}
                              datoOperativo={datoOperativo}
                              itemPadre={clienteDatoOpertaivo}
                              index={index}
                              key={index}
                              socket={socket}
                              guardiasTotales={guardiasTotales}
                            />
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        >
                          {guardiasTotales} / {clienteDatoOpertaivo.perGuardias}
                        </TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                        <TableCell
                          size="small"
                          align="center"
                          className={classes.celdaFooter}
                        ></TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
