import React, { memo, useContext } from "react";

import { IconButton, TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../../components/ConfirmacionDialog";
import CeldaUser from "../../../../../components/CeldaUser";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../../components/ButtonsAction";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  Download,
  PictureAsPdf,
  QuestionAnswer,
  Restore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../../config/const";
import clienteAxios from "../../../../../../config/axios";
import Respuestas from "../../Respuestas/Respuestas";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // DIALOG FECHAS
  const [openRespuestas, setopenRespuestas] = useState(false);
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        actaUser: usuario.usuario,
        actaEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        actaFecReg: rowHijo.actaFecReg,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actaUser: usuario.usuario + "/+/" + rowHijo[campoExport],
          actaEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <Respuestas
        open={openRespuestas}
        setopen={setopenRespuestas}
        row={rowHijo}
        socket={socket}
      />

      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowHijo.actaUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: true,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                let fechaUTC = new Date(rowHijo.actaFechaMaxRespuesta);
                let fechaLocal = new Date(
                  fechaUTC.getTime() - fechaUTC.getTimezoneOffset() * 60000
                );
                let fechaFormateada = fechaLocal
                  .toISOString()
                  .slice(0, 19)
                  .replace("T", " ");

                setopen(true);
                seteditar(true);
                changeObjetoActivoState({
                  ...rowHijo,
                  actaEstado: rowHijo.actaEstado || "EN PROCESO",
                  actaFechaMaxRespuesta: fechaFormateada,
                  // rowHijo.estado === "ANULADO" ||
                  // rowHijo.estado === "PROCESADO" ||
                  // rowHijo.estado === "EN PROCESO" ||
                  // rowHijo.estado === "INCUMPLIDA"
                  //   ? rowHijo.estado
                  //   : "EN PROCESO",
                });
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Respuestas",
              texto: rowHijo.actaGuardias.filter(
                (dirigido) => dirigido.respuesta
              ).length,
              funcion: () => {
                setopenRespuestas(true);
              },
              disabled: false,
              Icon: QuestionAnswer,
              color: "secondary",
              id: 4,
              ocultar: false,
              tipo: "icono",
              estado: "",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/acta_entrega_recepcion?actaId=${rowHijo.actaId}&rucempresa=${usuario.rucempresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 5,
              ocultar: false,
              tipo: "icono",
              estado: "",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <TableCell
            sx={{ paddingLeft: "5px" }}
            padding="none"
            size="small"
            align="center"
            className={claseCelda}
          >
            {rowHijo.actaDocumento1 === "" || !rowHijo.actaDocumento1 ? (
              "-"
            ) : (
              <IconButton
                sx={butonIconTransition}
                onClick={() => window.open(rowHijo.actaDocumento1)}
              >
                <Download fontSize="small" color="primary" />
              </IconButton>
            )}
            {rowHijo.actaDocumento2 === "" || !rowHijo.actaDocumento2 ? (
              "-"
            ) : (
              <IconButton
                sx={butonIconTransition}
                onClick={() => window.open(rowHijo.actaDocumento2)}
              >
                <Download fontSize="small" color="primary" />
              </IconButton>
            )}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.actaSecuencia}
          </TableCell>
          <TableCell
            size="small"
            sx={{
              color:
                rowHijo.actaEstado === "ANULADO" ||
                rowHijo.actaEstado === "INCUMPLIDA"
                  ? "red"
                  : rowHijo.actaEstado === "PROCESADO"
                  ? "green"
                  : "orange",
              fontWeight: "bold",
            }}
            align="center"
            className={claseCelda}
          >
            {rowHijo.actaEstado}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.actaAsunto}
          </TableCell>
          <TableCell
            size="small"
            sx={{
              color:
                rowHijo.actaPrioridad === "ALTA"
                  ? "blue"
                  : rowHijo.actaPrioridad === "MEDIA"
                  ? "green"
                  : "orange",
              fontWeight: "bold",
            }}
            align="center"
            className={claseCelda}
          >
            {rowHijo.actaPrioridad}
          </TableCell>

          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.actaFecha).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.actaLugar}
          </TableCell>
          {/* <CollapseCell
            claseCelda={claseCelda}
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            rowHijo={rowHijo}
            propiedad={"actaObservacion"}  
          /> */}
          <CeldaUser user={rowHijo.actaUser} claseCelda={claseCelda} />
          <TableCell size="small" align="center" className={claseCelda}>
            {new Date(rowHijo.actaFecReg).toLocaleString("es-ES", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
