import React, { useState } from "react";

import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteForeverTwoTone, Exposure } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "40%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "40%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
}));

const CamposBusqueda = (props) => {
  const {
    campoObjectItem,
    handleChange,
    fullBuscador,
    setarrayOcultarProps,
    arrayOcultarProps,
    setfullBuscador,
  } = props;
  const clases = useStyles();
  const [expandirCampo, setexpandirCampo] = useState(false);
  return (
    <>
      <Box className={clases.formAllW}></Box>
      {campoObjectItem.tipo === "fecha" ? (
        <>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            className={clases.formD}
            label={`${campoObjectItem.label} ${expandirCampo ? "- Desde" : ""}`}
            value={fullBuscador[`${campoObjectItem.propiedad}1`]}
            name={`${[campoObjectItem.propiedad]}1`}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {!expandirCampo ? (
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(true);
                      }}
                    >
                      <Exposure color="primary" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
          {expandirCampo ? (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="date"
              className={clases.formD}
              label={`${campoObjectItem.label} - Hasta`}
              value={fullBuscador[`${campoObjectItem.propiedad}2`]}
              name={`${[campoObjectItem.propiedad]}2`}
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(false);
                        setfullBuscador({
                          ...fullBuscador,
                          [`${campoObjectItem.propiedad}2`]: "",
                        });
                      }}
                    >
                      <Exposure color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
          <IconButton
            color="warning"
            onClick={() => {
              const res = arrayOcultarProps.filter(
                (item) => item !== campoObjectItem.label
              );

              setarrayOcultarProps(res);
              setfullBuscador({
                ...fullBuscador,
                [`${campoObjectItem.propiedad}1`]: "",
                [`${campoObjectItem.propiedad}2`]: "",
              });
            }}
          >
            <DeleteForeverTwoTone />
          </IconButton>
        </>
      ) : campoObjectItem.tipo === "fecha - mes" ? (
        <>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="month"
            className={clases.formD}
            label={`${campoObjectItem.label} ${expandirCampo ? "- Desde" : ""}`}
            value={fullBuscador[`${campoObjectItem.propiedad}1`]}
            name={`${[campoObjectItem.propiedad]}1`}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {!expandirCampo ? (
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(true);
                      }}
                    >
                      <Exposure color="primary" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />{" "}
          {expandirCampo ? (
            <TextField
              InputLabelProps={{ shrink: true }}
              type="month"
              className={clases.formD}
              label={`${campoObjectItem.label} - Hasta`}
              value={fullBuscador[`${campoObjectItem.propiedad}2`]}
              name={`${[campoObjectItem.propiedad]}2`}
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(false);
                        setfullBuscador({
                          ...fullBuscador,
                          [`${campoObjectItem.propiedad}2`]: "",
                        });
                      }}
                    >
                      <Exposure color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
          <IconButton
            color="warning"
            onClick={() => {
              const res = arrayOcultarProps.filter(
                (item) => item !== campoObjectItem.label
              );

              setarrayOcultarProps(res);
              setfullBuscador({
                ...fullBuscador,
                [`${campoObjectItem.propiedad}1`]: "",
                [`${campoObjectItem.propiedad}2`]: "",
              });
            }}
          >
            <DeleteForeverTwoTone />
          </IconButton>
        </>
      ) : campoObjectItem.tipo === "string" ? (
        <>
          <TextField
            className={clases.formD}
            label={`${campoObjectItem.label}`}
            value={fullBuscador[`${campoObjectItem.propiedad}`]}
            name={`${[campoObjectItem.propiedad]}`}
            onChange={(e) => handleChange(e)}
          />
          <IconButton
            color="warning"
            onClick={() => {
              const res = arrayOcultarProps.filter(
                (item) => item !== campoObjectItem.label
              );

              setarrayOcultarProps(res);
              setfullBuscador({
                ...fullBuscador,
                [`${campoObjectItem.propiedad}`]: "",
              });
            }}
          >
            <DeleteForeverTwoTone />
          </IconButton>
        </>
      ) : campoObjectItem.tipo === "numero" ? (
        <>
          <TextField
            className={clases.formD}
            label={`${campoObjectItem.label} ${expandirCampo ? "- Desde" : ""}`}
            value={fullBuscador[`${campoObjectItem.propiedad}1`]}
            name={`${[campoObjectItem.propiedad]}1`}
            onChange={(e) => handleChange(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  {!expandirCampo ? (
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(true);
                      }}
                    >
                      <Exposure color="primary" />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />{" "}
          {expandirCampo ? (
            <TextField
              className={clases.formD}
              label={`${campoObjectItem.label} - Hasta`}
              value={fullBuscador[`${campoObjectItem.propiedad}2`]}
              name={`${[campoObjectItem.propiedad]}2`}
              onChange={(e) => handleChange(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      aria-label=""
                      onClick={() => {
                        setexpandirCampo(false);
                        setfullBuscador({
                          ...fullBuscador,
                          [`${campoObjectItem.propiedad}2`]: "",
                        });
                      }}
                    >
                      <Exposure color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
          <IconButton
            color="warning"
            onClick={() => {
              const res = arrayOcultarProps.filter(
                (item) => item !== campoObjectItem.label
              );

              setarrayOcultarProps(res);
              setfullBuscador({
                ...fullBuscador,
                [`${campoObjectItem.propiedad}1`]: "",
                [`${campoObjectItem.propiedad}2`]: "",
              });
            }}
          >
            <DeleteForeverTwoTone />
          </IconButton>
        </>
      ) : null}
    </>
  );
};

export default CamposBusqueda;
