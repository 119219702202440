export const columns = [
  {
    id: "fpagoNombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    propiedad: "fpagoNombre",
  },
  {
    id: "fpagoDescripcion",
    label: "Descripcion",
    minWidth: 200,
    tipo: "string",

    propiedad: "fpagoDescripcion",
  },
  {
    id: "fpagoCodigo",
    label: "Código",
    minWidth: 100,
    tipo: "string",

    propiedad: "fpagoCodigo",
  },
  {
    id: "fpagoPorcentaje",
    label: "Porcentaje",
    minWidth: 100,
    tipo: "string",

    propiedad: "fpagoPorcentaje",
  },
  {
    id: "fpagoFecReg",
    label: "Fec. Reg",
    minWidth: 100,
    tipo: "string",

    propiedad: "fpagoFecReg",
  },
  {
    id: "fpagoUser",
    label: "User",
    minWidth: 100,
    tipo: "string",

    propiedad: "fpagoUser",
  },
];

export const objectDefault = {
  // fpagoId: 7,
  fpagoNombre: "",
  fpagoDescripcion: "",
  fpagoCodigo: 0,
  fpagoPorcentaje: 0,
  fpagoEmpresa: "",
  fpagoUser: "",
  fpagoFecReg: "",
};
