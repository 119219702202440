import React, { useState } from "react";

// MATERIAL
import {
  Box,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { Map } from "@mui/icons-material";
import DialogMap from "../../components/DialogMap"; 
const ListItemTrabajo = (props) => {
  const { index, trabajo, item, seccionActive, setseccionActive } = props;
  const [openMap, setopenMap] = useState(false);
  return (
    <>
      {item.act_asigEveTipo === "RONDAS" && (
        <DialogMap
          open={openMap}
          setopen={setopenMap}
          setitem={() => {}}
          item={trabajo}
          editar={true}
          propiedad={trabajo.coordenadasGps}
          nombrePropiedad={"coordenadasGps"}
          solover={true}
        />
      )}
      <ListItem
        sx={{ width: "100%", padding: "0 1rem" }}
        selected={seccionActive === trabajo.titulo + "/+/" + index}
        onClick={() => setseccionActive(trabajo.titulo + "/+/" + index)}
        dense
      >
        <Box
          display={"flex"}
          sx={{ width: "100%" }}
          justifyContent={"space-between"}
        >
          <Box
            alignItems={"center"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <ListItemIcon>
              <Typography
                variant="body1"
                color={
                  seccionActive === trabajo.titulo + "/+/" + index
                    ? "secondary"
                    : "primary"
                }
                fontWeight="bold"
              >
                {index + 1}
              </Typography>
            </ListItemIcon>
            <ListItemText
              primary={trabajo.titulo}
              secondary={
                item.act_asigEveTipo === "RONDAS" &&
                `${trabajo.distancia} metros`
              }
              primaryTypographyProps={{
                component: "span",
                fontSize: ".85rem",
              }}
              secondaryTypographyProps={{
                component: "span",
                fontSize: ".85rem",
              }}
            />
          </Box>
          <Box
            alignItems={"center"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            {item.act_asigEveTipo === "RONDAS" && (
              <IconButton onClick={() => setopenMap(true)} color="secondary">
                <Map />
              </IconButton>
            )}

            <ListItemText
              primary={trabajo.fecha.substr(0, 19).replace("T", " ")}
              primaryTypographyProps={{
                component: "span",
                fontSize: ".85rem",
              }}
            />
          </Box>
        </Box>
      </ListItem>
    </>
  );
};

export default ListItemTrabajo;
