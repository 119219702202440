import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import { tienePermisoRuta } from "../../../../config/validaciones";

import { permisoDenagado } from "../../../../config/const";
import ConfirmacionDialog from "../../../../components/Extra/ConfirmacionDialog";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../../Context/Auth/AuthContext";
import MenuContext from "../../../../Context/Menu/MenuContext"; 

// MATERIAL
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow } from "@mui/material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },

  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    rowHijo,
    index,
    campoExport,
    itemExport,
    setitemExport,
    rowPadre,
    perfil,
    setopenModal,
    setobjetoActivoModal,
  } = props;
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const { socket, usuario } = useContext(AuthContext);
  const classes = useRowStyles();

  const [editar, seteditar] = useState(false);
  const handleEliminar = async (_id) => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "eliminar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    try {
      socket.emit("client:eliminarData", {
        tabla: "registro",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        regUser: usuario.usuario,
        regEmpresa: usuario.rucempresa,
        encId: rowHijo.encId,
      });
    } catch (error) {}
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    // seteditar(true);
  };

  const funcSubmit = () => {
    try {
      // socket.emit("client:actualizarData", {
      //   ...rowHijo,
      //   tabla: "reguenta",
      //   rucempresa: usuario.rucempresa,
      //   rol: usuario.rol,
      //   regUser: usuario.usuario,
      //   regEmpresa: usuario.rucempresa,
      // });
      seteditar(false);
    } catch (error) {}
  };

  useEffect(() => {}, []);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const openModal = () => {
    setopenModal(true); 
    setobjetoActivoModal(rowHijo);
  };
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro: ${rowHijo.regNombres}?`}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={rowHijo}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={true}
          ocultarDelete={true}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="regEstado"
          editar={editar}
          funcSubmit={funcSubmit}
          itemExport={itemExport}
          setitemExport={setitemExport}
          rowPadre={rowPadre}
          perfil={perfil}
          openModal={openModal}
        />

        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {rowHijo.nombres}
        </TableCell>
       
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {rowHijo.encPerId}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
