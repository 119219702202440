import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import ToolBarTable from "../../../../components/ToolBarTable";
import { useSimpleTable } from "../../../../hooks/useSimpleTable"; 
import TablaCabecera from "../../../../components/TablaCabecera";
import { useRowStyles } from "../../../../styles/stylesRows";

const Armas = (props) => {
  const { trabajoActivo } = props;
  const classes = useRowStyles();
  const {
    pageArma,
    // setPageArma,
    rowsPerPageArma,
    // setRowsPerPageArma,
    handleChangePageArma,
    handleChangeRowsPerPageArma,
  } = useSimpleTable("Arma");
  return (
    <Paper>
      <ToolBarTable
        count={trabajoActivo.armas.length}
        rowsPerPage={rowsPerPageArma}
        page={pageArma}
        handleChangePage={handleChangePageArma}
        handleChangeRowsPerPage={handleChangeRowsPerPageArma}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[]}
        botonesDerechos={[]}
      />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TablaCabecera
              ocultarPrimeraColumna={true}
              columns={[
                {
                  id: "existe",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "existe",
                },
                {
                  id: "id",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "id",
                },
                {
                  id: "nombre",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "nombre",
                },
                {
                  id: "serie",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "serie",
                },
                {
                  id: "tipo",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "tipo",
                },
              ]}
              habilitarOrdenar={false}
            />
          </TableHead>

          <TableBody component={"div"}>
            {trabajoActivo.armas.map((arma, index) => {
              const claseCelda = `${
                index % 2 === 0 ? classes.celda : classes.celdaSecundario
              } `;
              return (
                <TableRow>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {arma.existe ? "SI" : "NO"}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {arma.id}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {arma.nombre}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {arma.serie}
                  </TableCell>
                  <TableCell size="small" align="center" className={claseCelda}>
                    {arma.tipo}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Armas;
