export const columns = [
  {
    id: "regFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "tregistros",
    label: "Total",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "regDocumento",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDocumento",
    align: "left",
    ocultar: true,
  },

  {
    id: "regHoraRegistroIngreso",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraRegistroIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraIngreso",
    label: "H.INGRESO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraRegistroIngreso",
    label: "H. Registro",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraRegistroIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regStatusIngreso",
    label: "ESTADO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regStatusIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regTiempoIngreso",
    label: "TIEMPO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regTiempoIngreso",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regDatosTurno",
    label: "Puesto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regDatosTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraSalida",
    label: "H.SALIDA",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraSalida",
    align: "center",
    ocultar: true,
  },

  {
    id: "regHoraRegistroSalida",
    label: "H.REGISTRO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regHoraRegistroSalida",
    align: "center",
    ocultar: true,
  },

  {
    id: "regStatusSalida",
    label: "ESTADO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regStatusSalida",
    align: "center",
    ocultar: true,
  },

  {
    id: "regTiempoSalida",
    label: "TIEMPO",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "regTiempoSalida",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  regId: "",
  regCodigo: "",
  regNombres: "",
  regPuesto: "",
  regCoordenadas: {
    latitud: "",
    longitud: "",
  },
  regDispositivo: "",
  regEstado: "",
  regUser: "",
  regFecReg: "",
  Todos: "",
};
// {
//   regId: 2,
//   actEveId: 0,
//   actEveTipo: "",
//   actEveDatos: {
//     eveId: "",
//     eveTipo: "",
//     eveNombre: "",
//     eveActividades: [],
//     eveFecReg: "",
//     eveFecUpd: "",
//     eveEmpresa: "",
//     eveUser: "",
//     nombre: "",
//   },
//   actEstado: "ACTIVA",
//   actGuardias: {},
//   actSupervisores: {},
//   actAdministracion: {},
//   actFechasActividad: [],
//   actFechasEvento: {},
//   actFechasActividadConsultaDB: [],
//   actObservacion: "",
//   regFecReg: "2022-08-23T18:01:57.000Z",
//   actFecUpd: "2023-05-09T16:57:00.000Z",
//   actIdCliente: "",
//   act_asigPerId: "",
//   actNombreCliente: "",
//   actDirigido: "",
//   actFotosActividad: [],
//   actLeidos: [],
//   actPrioridad: "MEDIA",
//   actDocumento: "",
//   actUser: "admin",
//   actEmpresa: "PRUEBA",
// };
