import React from "react";
import { useRowStyles } from "../../../styles/stylesRows";
import {
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { useSimpleTable } from "../../../hooks/useSimpleTable";
import Row from "./Row";
import { columnsCantidades } from "../../data";

const Cantidades = ({ expandido, item, setitem, ver, devolucion }) => {
  const clases = useRowStyles();
  const {
    // agregandoStock,
    // setagregandoStock,
    pageStock,
    // setPageStock,
    rowsPerPageStock,
    // setRowsPerPageStock,
    handleChangePageStock,
    handleChangeRowsPerPageStock,
  } = useSimpleTable("Stock");
  return (
    <TableRow>
      <TableCell
        className={clases.celda}
        style={{ paddingBottom: 0, paddingTop: 0 }}
        colSpan={21}
      >
        <Collapse in={expandido} timeout="auto">
          <Paper sx={{ width: "100%" }}>
            <ToolBarTable
              count={item.cantidades.length}
              rowsPerPage={rowsPerPageStock}
              page={pageStock}
              handleChangePage={handleChangePageStock}
              handleChangeRowsPerPage={handleChangeRowsPerPageStock}
              ocultarPaginacion={false}
              simplePagination={true}
              botonesIzquierdos={[]}
              botonesDerechos={[]}
            />

            <TableContainer sx={{ width: "100%" }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TablaCabecera
                    columns={
                      devolucion
                        ? [
                            {
                              id: "cantidadDevolucion",
                              align: "center",
                              minWidth: 50,
                              colSpan: 1,
                              label: "C. Devolucion",
                            },
                            {
                              id: "estado",
                              align: "center",
                              minWidth: 200,
                              tipo: "string",
                              label: "estado",
                            },
                            {
                              id: "disponible",
                              align: "center",
                              minWidth: 200,
                              tipo: "string",
                              label: "disponible",
                            },
                            {
                              id: "cantidad",
                              align: "center",
                              minWidth: 200,
                              tipo: "string",
                              label: "cantidad",
                            },
                            {
                              id: "cantidadAnterior",
                              align: "center",
                              minWidth: 50,
                              colSpan: 1,
                              label: "C. Anterior",
                            },
                          ]
                        : columnsCantidades
                    }
                    habilitarOrdenar={false}
                  />
                </TableHead>

                <TableBody component={"div"}>
                  {/* {agregandoStock ? (
                            <RowAgregarStock
                              setagregar={setagregandoStock}
                              setitemPadre={setitem}
                              itemPadre={item}
                            />
                          ) : null} */}

                  {item.cantidades
                    .slice(
                      pageStock * rowsPerPageStock,
                      pageStock * rowsPerPageStock + rowsPerPageStock
                    )
                    .map((itemHijo, index) => {
                      return (
                        <Row
                          devolucion={devolucion}
                          setitemPadre={setitem}
                          itemPadre={item}
                          itemHijo={itemHijo}
                          index={index}
                          key={index}
                          ver={ver}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default Cantidades;
