import React, { memo } from "react";
// MATERIAL
import { makeStyles } from "@mui/styles";
import { TableRow, TableCell, Button } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
  padding: {
    margin: "0px",
    padding: "0px 10px 0px 10px",
  },
}));
const TablaCabecera = (props) => {
  const classes = useRowStyles();
  const {
    // rows,
    // setarrayExport,
    columns,

    habilitarOrdenar,
    //nuevo
    input,
    orden,
    funcionOrdenar,
    agregarColumna,
    // campoExport,
  } = props;
  return (
    <TableRow>
      {agregarColumna ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        ></TableCell>
      ) : null}
      <TableCell
        size="small"
        padding="checkbox"
        style={{
          borderRight: "1px solid #ccc",
          maxWidth: "1rem",
        }}
      ></TableCell>

      <TableCell
        size="small"
        padding="checkbox"
        style={{
          borderRight: "1px solid #ccc",
          maxWidth: "1rem",
        }}
      ></TableCell>
      {habilitarOrdenar
        ? columns.map((column, index) => {
            return (
              <TableCell
                key={index}
                align={column.align}
                size="small"
                style={{
                  minWidth: column.minWidth,
                }}
                padding="none"
                colSpan={column.colSpan ? column.colSpan : 1}
              >
                <Button
                  size="small"
                  style={{
                    fontSize: ".7rem",
                    fontWeight: "bold",
                  }}
                  variant={input.id === column.id ? "contained" : "text"}
                  color="secondary"
                  onClick={async () => {
                    if (column.tipo === "") {
                      return;
                    }
                    await funcionOrdenar(column);
                  }}
                  fullWidth
                  startIcon={
                    input.id === column.id ? (
                      orden ? (
                        <KeyboardArrowDown color="primary" />
                      ) : (
                        <KeyboardArrowUp color="primary" />
                      )
                    ) : null
                  }
                  endIcon={
                    input.id === column.id ? (
                      orden ? (
                        <KeyboardArrowDown color="primary" />
                      ) : (
                        <KeyboardArrowUp color="primary" />
                      )
                    ) : null
                  }
                >
                  {column.label}
                </Button>
              </TableCell>
            );
          })
        : columns.map((column, index) => {
            return (
              <TableCell
                key={index}
                align={"center"}
                size="small"
                style={{
                  minWidth: column.minWidth,
                }}
                className={classes.padding}
                colSpan={column.colSpan ? column.colSpan : 1}
              >
                <Button
                  size="small"
                  style={{
                    padding: "4px",
                    fontSize: ".7rem",
                    fontWeight: "bold",
                  }}
                  variant={"text"}
                  color="primary"
                  fullWidth
                >
                  {column.label}
                </Button>
              </TableCell>
            );
          })}
    </TableRow>
  );
};

export default memo(TablaCabecera);
