import React, { memo } from "react";

import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setitemPadre, itemPadre, itemHijo, index } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } `;
  const [editar, seteditar] = useState(false);
  const [itemHijoEdit, setitemHijoEdit] = useState(itemHijo);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  //onChange
  const handleChange = (e) => {
    setitemHijoEdit({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (itemHijo.metodo.trim() === "" || itemHijo.porque.trim() === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      setitemPadre({
        ...itemPadre,
        mejMetodo: itemPadre.mejMetodo.map((item, indexItem) => {
          if (indexItem === index) {
            return trimPropsItem(itemHijoEdit);
          } else {
            return item;
          }
        }),
      });

      seteditar(false);
    } catch (error) {}
  };
  const funcEliminar = () => {
    try {
      setitemPadre({
        ...itemPadre,
        mejMetodo: itemPadre.mejMetodo.filter(
          (item, indexItem) => indexItem !== index
        ),
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog(true);
      },
      disabled: false,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        seteditar(true);
        setitemHijoEdit(itemHijo);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijoEdit(itemHijo);
        // setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              funcEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ButtonsAction
        claseCelda={claseCelda}
        botones={editar ? botonesEditar : botones}
      />
      {editar ? (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              autoFocus
              value={itemHijoEdit.metodo}
              name="metodo"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={itemHijoEdit.porque}
              name="porque"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.metodo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.porque}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default withRouter(memo(Row));
