export const columns = [
  {
    id: "turFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "fecha",
    orden: 1,
    propiedad: "turFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: "tregistros",
    label: "Registros",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tregistros",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "turDocuPersona",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turDocuPersona",
    align: "left",
    ocultar: true,
  },

  {
    id: "turDocuCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turDocuCliente",
    align: "left",
    ocultar: true,
  },

  {
    id: "turEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turEstado",
    align: "center",
    ocultar: true,
  },

  {
    id: "turMotivo",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turMotivo",
    align: "center",
    ocultar: true,
  },

  {
    id: "turFechaDesde",
    label: "F. Desde",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turFechaDesde",
    align: "center",
    ocultar: true,
  },

  {
    id: "turFechaHasta",
    label: "F. Hasta",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turFechaHasta",
    align: "center",
    ocultar: true,
  },

  {
    id: "turDiasTurno",
    label: "Dias Turno",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turDiasTurno",
    align: "center",
    ocultar: true,
  },

  {
    id: "turStatusDescripcion",
    label: "Descripción",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turStatusDescripcion",
    align: "center",
    ocultar: true,
  },

  {
    id: "turUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "turUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  //   turId: 2,
  turIdPersona: "",
  turDocuPersona: "",
  turNomPersona: "",
  turIdCliente: "",
  turDocuCliente: "",
  turNomCliente: "",
  turPuesto: [
    {
      ubicacion: "",
      puesto: "",
      supervisor: "",
      guardias: "",
      horasservicio: "",
      tipoinstalacion: "",
      vulnerabilidades: "",
      consignas: [],
      id: "",
    },
  ],
  turMotivo: "EVENTO ESPECIAL", // select=> FALTA INJUSTIFICADA, PERMISO MEDICO, PATERNIDAD, EVENTO ESPECIAL
  turAutorizado: "", // input
  turFechas: [],
  turFechasConsultaDB: [],
  turDiasTurno: "1",
  turDetalle: "", // textarea
  turUser: "",
  turEmpresa: "",
  turFecReg: "",
  turEstado: "EN PROCESO",
  turStatusDescripcion: "",
};
