import React, { useState, memo, useContext } from "react";
import Draggable from "react-draggable";
import clienteAxios from "../../../../../config/axios";

import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useModalStyle } from "../../../../styles/styleModal";
import { useEffect } from "react";
import Devolucion from "./Devolucion";
import AuthContext from "../../../../../Context/Auth/AuthContext";

const ModalAsistencia = ({ open, setopen, item, socket }) => {
  const clases = useModalStyle();
  const { usuario } = useContext(AuthContext);
  const [items, setitems] = useState([]);
  const [itemActivo, setitemActivo] = useState(null);
  const [bodegaData, setbodegaData] = useState(null);
  const [seccionActive, setseccionActive] = useState("");
  const defaultActive = () => {
    setopen(false);
    // setitem({});
  };

  useEffect(() => {
    const getDevoluciones = async () => {
      const res = await clienteAxios.get(
        `devoluciones/byIds/0?ids=${item.disIdDevoluciones.join(",")}`
      );
      setitems(res.data.data);
    };
    getDevoluciones();
    const getbodegaData = async () => {
      const resbodegaData = await clienteAxios.get(
        `bodegas/filtro/0?empresa=${usuario.rucempresa}`
      );

      setbodegaData(resbodegaData.data.data);
    };

    if (!bodegaData) {
      getbodegaData();
    }
    // eslint-disable-next-line
  }, []);

  if (!open) {
    return null;
  }
  if (!bodegaData) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div
              style={{
                borderRadius: "15px",
                position: "absolute",
                overflow: "scroll",
                maxHeight: "95vh",
              }}
              className={clases.paper}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Devoluciones
                </Typography>

                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
              >
                <Box display={"flex"} flexWrap={"wrap"}>
                  {items.map((item, index) => (
                    <Button
                      key={item.devId}
                      className={clases.buttonTab}
                      variant={
                        seccionActive === item.devId
                          ? "contained"
                          : false
                          ? "outlined"
                          : "outlined"
                      }
                      color={
                        false
                          ? "error"
                          : item.devEstado === "EN PROCESO"
                          ? "warning"
                          : item.devEstado === "APROBADO"
                          ? "success"
                          : item.devEstado === "ANULADA"
                          ? "error"
                          : "secondary"
                      }
                      disableElevation
                      size="small"
                      onClick={() => {
                        const {
                          devDisArmas,
                          devDisMuniciones,
                          devDisVestimenta,
                          ...rest
                        } = item;

                        setitemActivo({
                          ...rest,
                          disArmas: item.devDisArmas,
                          disMuniciones: item.devDisMuniciones,
                          disVestimenta: item.devDisVestimenta,
                        });
                        setseccionActive(item.devId);
                      }}
                    >
                      Devolución {index + 1}
                    </Button>
                  ))}
                </Box>
              </Box>
              <Divider />
              <Box className={clases.container}>
                {items.map((item) => {
                  if (item.devId !== itemActivo?.devId) {
                    return null;
                  }
                  const {
                    devDisArmas,
                    devDisMuniciones,
                    devDisVestimenta,
                    ...rest
                  } = item;
                  const itemModify = {
                    ...rest,
                    disArmas: item.devDisArmas,
                    disMuniciones: item.devDisMuniciones,
                    disVestimenta: item.devDisVestimenta,
                  };
                  return (
                    <Devolucion
                      socket={socket}
                      itemActivo={itemModify}
                      bodegaData={bodegaData}
                    />
                  );
                })}
              </Box>
              {/* <Divider /> */}
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
