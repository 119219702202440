export const columns = [
  {
    id: "codigo",
    label: "codigo",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "codigo",
    align: "center",
    ocultar: true,
  },
  {
    id: "tipo",
    label: "tipo",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "tipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "hora_inicio",
    label: "hora inicio",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "hora_inicio",
    align: "center",
    ocultar: true,
  },
  {
    id: "hora_final",
    label: "hora final",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "hora_final",
    align: "center",
    ocultar: true,
  },
  {
    id: "color",
    label: "color",
    minWidth: 150,
    tipo: "string",
    orden: 1,
    propiedad: "color",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  // id: 1,
  codigo: "D",
  color: "#cccccc",
  // month: 12,
  // year: 2023,
  tipo: "DIA",
  hora_inicio: "06:00",
  hora_final: "18:00",
  user: "admin",
  fec_reg: "2023-12-15T16:00:45.000Z",
};

export const objectActAsignDefault = {
  id_planificacion: 0,
  acti_prioridad: "BAJA",
  acti_documento: "",
  acti_fotos: [],
  acti_observacion: "",
  acti_requerimiento: { qr: "NO", foto: "NO", video: "NO", texto: "NO" },
  idHorarios: [],
  month: 0,
  year: 0,
  id_dOperativo: 0,
};
