import React, { memo, useState, useContext, useEffect } from "react";

import { Box } from "@mui/material";

import BarraTablas from "../../components/BarraTablas/BarraTablas";

import Tabla from "./Componentes/Tabla";
import Modal from "./Componentes/Modal";
import MenuContext from "../../Context/Menu/MenuContext";
import clienteAxios from "../../config/axios";

const Categorias = () => {
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);

  const [filtro, setfiltro] = useState("");
  const [rows, setrows] = useState([]);
  const handleSearch = () => {};
  const getData = async () => {
    const res = await clienteAxios.get("/sidebar/para/web");
    console.log({ res });

    setrows(
      res.data.sort((a, b) => {
        return Number(a.sidInfo.orden) - Number(b.sidInfo.orden);
      })
    );
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box>
      <BarraTablas
        ocultarAgregar={true}
        ocultarOpciones={true}
        ocultarBuscar={true}
        filtro={filtro}
        setfiltro={setfiltro}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={setopen}
        open={open}
      />
      <Tabla setopen={setopen} rows={rows} buscando={buscando} />
      <Modal setopen={setopen} open={open} />
    </Box>
  );
};

export default memo(Categorias);
