import React from "react";
// MODULOS
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
// MATERIAL
import { Box } from "@mui/material";
import { compararFechasPorDia } from "../../../functions/funciones";

const latino = {
  name: "español",
  months: [
    ["Enero", "Ene"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "May"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sep"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"],
  ],
  weekDays: [
    ["Sabado", "Sab"],
    ["Domingo", "Dom"],
    ["Lunes", "Lun"],
    ["Martes", "Mar"],
    ["Miercoles", "Mie"],
    ["Jueves", "Jue"],
    ["Viernes", "Vie"],
  ],
  digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
  meridiems: [
    ["AM", "am"],
    ["PM", "pm"],
  ],
};

const Calendario = (props) => {
  const {
    propiedad,
    propiedadDB,
    // focusedDate,
    setFocusedDate,
    item,
    setitem,
    TextoPropiedad,
    textoPropiedadDB,
    frecuencia,
    calendarios,
    validarFechaAnterior,
    readOnly,
    diasSinPersonas,
  } = props;

  const crearFecha = (date) => {
    const fecha = new Date(date).toString();
    const options = fecha.split(" ");

    const meses = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    return `${options[3]}-${meses[options[1]]}-${options[2]}T${
      options[4]
    }.857Z`;
  };
  return (
    <>
      <Box width="100%" justifyContent="center" display={"flex"}>
        <Calendar
          readOnly={readOnly}
          locale={latino}
          numberOfMonths={calendarios ? calendarios : 2}
          value={propiedad.map((item) => item.desde)}
          onChange={(e, a) => {
            const resArray = e.filter((itemFecha) => {
              if (!validarFechaAnterior) {
                return true;
              }
              const anterior = new Date(
                itemFecha - new Date().getTimezoneOffset() * 60 * 1000
              );
              return !compararFechasPorDia(anterior, Date.now());
            });
            if (frecuencia) {
              const resDB = resArray.map((itemFecha, index) => {
                const itemFDB = propiedadDB.find((itemDB) => {
                  return (
                    itemDB.hasta.substr(0, 10) ===
                    crearFecha(itemFecha).substr(0, 10)
                  );
                });

                return {
                  desde: crearFecha(itemFecha),
                  hasta: itemFDB ? itemFDB.hasta : "",
                  frecuencia: "1",
                };
              });

              setitem({
                ...item,
                [TextoPropiedad]: resArray.map((itemE) => {
                  return {
                    desde: itemE,
                    hasta: itemE,
                    frecuencia: "1",
                  };
                }),
                [textoPropiedadDB]: resDB,
              });
            } else {
              const resDB = resArray.map((itemFecha, index) => {
                const itemFDB = propiedadDB.find((itemDB) => {
                  return (
                    itemDB.hasta.substr(0, 10) ===
                    crearFecha(itemFecha).substr(0, 10)
                  );
                });

                return {
                  desde: crearFecha(itemFecha),
                  hasta: itemFDB ? itemFDB.hasta : "",
                };
              });

              setitem({
                ...item,
                [TextoPropiedad]: resArray.map((itemE) => {
                  return {
                    desde: new Date(itemE).getTime(),
                    hasta: new Date(itemE).getTime(),
                  };
                }),
                [textoPropiedadDB]: resDB,
              });
            }
          }}
          sort
          onFocusedDateChange={(e, a) => setFocusedDate(e, a)}
          mapDays={({ date }) => {
            let props = {};

       
            if (
              diasSinPersonas.some(
                (item) =>
                  item ===
                  new Date(date - new Date().getTimezoneOffset() * 60 * 1000)
                    .toISOString()
                    .substring(0, 10)
              )
            ) {
              props.style = { backgroundColor: "orange" };
            }

            return props;
          }}
          plugins={[
            <DatePanel
              onClickDate={(e) => {
                if (readOnly) {
                  setFocusedDate(e);
                }
              }}
              removeButton={!readOnly}
              position="left"
              markFocused
              aria-readonly="true"
              focusedClassName="bg-red"
              header={readOnly ? "Seleccione para ver detalles" : "Fechas"}
              translate="yes"
              sort="date"
            />,
            // <TimePicker locale={latino} position="left" translate="yes" />,
          ]}
        />
      </Box>
    </>
  );
};

export default Calendario;
