import { lighten } from "@material-ui/core";
import { Box, Theme, Typography, styled } from "@mui/material";

export const TypographyChatActive = styled(Typography)(({ theme }) => ({
  fontSize: "2.5rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
}));
export const BoxChat = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",

  ".ListItem": {
    justifyContent: "flex-end",
  },
  ".BoxMessage": {
    maxWidth: "60%",
    borderRadius: theme.spacing(2),
    margin: `${theme.spacing(1)} ${theme.spacing(0)}`,
  },
  ".bg_BoxMessage": {
    backgroundColor: lighten(theme.palette.primary.light, 0.1),
    color: theme.palette.primary.contrastText,
  },
  ".bg_BoxMessage2": {
    backgroundColor: lighten(theme.palette.secondary.main, 0.1),
    color: theme.palette.secondary.contrastText,
  },

  ".Avatar": {
    margin: `0 ${theme.spacing(1)}`,
  },
  ".ListItemText": {
    padding: `0 ${theme.spacing(1)}`,
  },
}));
