import { CloudUpload, DeleteForever, Download } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useContext } from "react";
import { useState, createRef } from "react";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import { useModalStyle } from "../styles/styleModal";

const CampoDoc = (props) => {
  const clases = useModalStyle();
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    setcargando,
    propiedad,
    item,
    setitem,
    celda,
    claseCelda,
    disabled,
    disabledE,
    extension,
    label = "Documento",
    error,
    carpeta = "documentos",
  } = props;
  //documento
  const [documentoState, setdocumentoState] = useState("");
  const refArchivoDocumentoState = createRef();
  const focusInputDocumentoState = () => {
    refArchivoDocumentoState.current.click();
  };
  const onChangeArchivoDocumentoState = async (e) => {
    if (!e.target.files[0]) {
      return setdocumentoState("");
    }
    const maxFileSize = 4 * 1024 * 1024;

    if (extension === "IMAGEN") {
      if (
        !["png", "jpg", "jpeg"].some(
          (item) => item === e.target.files[0].type.split("/")[1]
        )
      ) {
        return mostrarAlerta("Solo archivos png, jpg o jpeg", "error");
      }
    } else {
      if (
        ![
          "pdf",
          "vnd.openxmlformats-officedocument.presentationml.presentation",
          "vnd.openxmlformats-officedocument.wordprocessingml.document",
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].some((item) => item === e.target.files[0].type.split("/")[1])
      ) {
        return mostrarAlerta(
          "El archivo debe ser PDF, EXCEL, WORD O POWERPOINT",
          "error"
        );
      }
    }
    if (e.target.files[0].size > maxFileSize) {
      mostrarAlerta("El peso del archivo sobrepasa el limite de 4MB", "error");
    }
    try {
      setcargando(true);
      const formDataLogos = new FormData();
      let arrayEliminar = [item[propiedad]];

      console.log(e.target.files);

      await formDataLogos.append("archivo", e.target.files[0]);
      formDataLogos.append("carpeta", carpeta);

      const resDocumentos = await clienteAxios.post(
        "/subir_eliminar_archivo/subir",
        formDataLogos
      );

      console.log(arrayEliminar.filter((itemE) => itemE !== ""));

      await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
        urls: arrayEliminar.filter((itemE) => itemE !== ""),
      });
      console.log("a");
      console.log({
        ...item,
        [propiedad]: resDocumentos.data.url,
      });
      setitem({
        ...item,
        [propiedad]: resDocumentos.data.url,
      });

      setdocumentoState(e.target.files[0]);
      console.log("a");
      setcargando(false);
    } catch (error) {
      console.log({ error });

      setcargando(false);
      setdocumentoState("");
      return mostrarAlerta("Hubo un error", "error");
    }
    // setdocumentoState(e.target.files[0]);
  };

  return (
    <>
      <input
        className={clases.ocultar}
        type="file"
        name="myImage"
        onChange={(e) => onChangeArchivoDocumentoState(e)}
        ref={refArchivoDocumentoState}
        accept={
          extension === "IMAGEN"
            ? "image/*"
            : ".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx"
        }
      />
      <TextField
        size="small"
        disabled={disabled}
        InputLabelProps={{
          shrink:
            documentoState !== "" || item[propiedad] !== "" ? true : false,
        }}
        className={celda ? (claseCelda ? claseCelda : {}) : clases.formD}
        error={error ? error[propiedad] : false}
        InputProps={{
          endAdornment: (
            <Box htmlFor="contained-button-documento" fullWidth>
              <InputAdornment>
                <Tooltip title={`Subir Archivo: ${label}`} arrow>
                  <Box>
                    <IconButton
                      disabled={disabled}
                      onClick={focusInputDocumentoState}
                    >
                      <CloudUpload color={disabled ? "inherit" : "secondary"} />
                    </IconButton>
                  </Box>
                </Tooltip>
                {item[propiedad] === "" || !item[propiedad] ? null : (
                  <Tooltip title={`Descargar Archivo: ${label}`} arrow>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={async () => {
                          try {
                            setcargando(false);
                            setitem({
                              ...item,
                              [propiedad]: "",
                            });
                            setdocumentoState("");

                            await clienteAxios.post(
                              "/subir_eliminar_archivo/eliminar",
                              { urls: [item[propiedad]] }
                            );
                            setcargando(false);
                          } catch (error) {}
                        }}
                        disabled={disabledE}
                      >
                        <DeleteForever color={disabled ? "inherit" : "error"} />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
                {item[propiedad] === "" || !item[propiedad] ? null : (
                  <Tooltip title={`Descargar Archivo: ${label}`} arrow>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={async () => {
                          window.open(item[propiedad]);
                        }}
                      >
                        <Download />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}
              </InputAdornment>
            </Box>
          ),
        }}
        fullWidth
        label={celda ? "" : label}
        value={
          item[propiedad] && !documentoState.name
            ? item[propiedad].split("/")[item[propiedad].split("/").length - 1]
            : documentoState.name
            ? documentoState.name
            : ""
        }
        name={propiedad}
      />
    </>
  );
};

export default CampoDoc;
