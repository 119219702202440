import React, { memo, useContext, useState } from "react";
// MODULOS
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { saveAs } from "file-saver";
// COMPONENTES
import ExportDialog from "./ExportDialog";
//CSS
import "./GlobalCssSlider.css";
// AXIOS
import clienteAxios from "../../config/axios";
// CONTEXT
import AuthContext from "../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  TablePagination,
  lighten,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { AddCircle, ListRounded, Refresh } from "@mui/icons-material";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  ocultar: {
    display: "none",
  },
  "MuiTablePagination-toolbar": {
    padding: "none",
  },
}));
const ToolBarTable = (props) => {
  const classes = useToolbarStyles();
  const {
    titulo,
    arrayExport,
    columns,
    expandible,
    search,
    campoExport,
    soloTitulo,
    botonAdd,
    funcionAdd,
    tabla,
    funcionReload,
    pagination,
    rowsPerPage,
    rows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    deshabilitarbotonAdd,
    fullBuscador,
    otraFuncionExport,
    estadoRequest,
    otroBotonEnElToolbarTable,
    exportarExcel,
    ocultarDelete,
    textoActualizar,
    ocultarExportarPdf,
    deshabilitarReload,
    otraFuncionExport2,
  } = props;
  const [tipo, settipo] = useState("seleccion");
  const [open, setopen] = useState(false);
  const {
    usuario: { rucempresa, usuario, nombre },
  } = useContext(AuthContext);
  const funcPdf = async () => {
    const json = {
      usuario,
      nombre,
      rucempresa,
      tipo,
      search,
      lista: arrayExport.map((item) => item[campoExport]),
      search_avanzado: fullBuscador,
      estado: estadoRequest,
    };

    const res = await clienteAxios.post(
      `/reportes/${tabla}`,
      {
        ...json,
      },
      { responseType: "blob" }
    );

    const pdfBlob = new Blob([res.data], { type: "application/pdf" });
    saveAs(pdfBlob, `${titulo}.pdf`);
    setopen(false);
  };
  const funcExcel = async () => {
    const json = {
      rucempresa,
      tipo,
      search,
      perfil: estadoRequest,
      lista: arrayExport.map((item) => item[campoExport]),
      datos: fullBuscador,
      columns,
    };

    const res = await clienteAxios.post(`reportes/excel/${tabla}`, json, {
      responseType: "blob",
    });
    const pdfBlob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(pdfBlob, `${titulo}.xlsx`);

    setopen(false);
  };

  return (
    <>
      {" "}
      {soloTitulo ? (
        <Box
          display="flex"
          justifyContent="space-between"
          className={clsx(classes.root, {
            [classes.highlight]: false,
          })}
          alignItems="center"
        >
          <Button
            style={{ padding: ".0rem" }}
            variant="text"
            color="inherit"
            onClick={async () => {
              if (funcionReload) {
                funcionReload();
              }
            }}
          >
            {titulo}
          </Button>

          {botonAdd ? (
            <IconButton
              aria-label=""
              disabled={deshabilitarbotonAdd}
              color="secondary"
              style={{ padding: ".5rem" }}
              onClick={() => funcionAdd()}
              size="small"
            >
              <AddCircle fontSize="medium" />{" "}
            </IconButton>
          ) : null}
        </Box>
      ) : (
        <>
          <ExportDialog
            ocultarDelete={ocultarDelete}
            tipo={tipo}
            settipo={settipo}
            open={open}
            setopen={setopen}
            funcPdf={funcPdf}
            funcExcel={funcExcel}
            arrayExport={arrayExport}
            columns={columns}
            titulo={titulo}
            expandible={expandible}
            campoExport={campoExport}
            tabla={tabla}
            otraFuncionExport={otraFuncionExport}
            otraFuncionExport2={otraFuncionExport2}
            exportarExcel={exportarExcel}
            ocultarExportarPdf={ocultarExportarPdf}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            className={clsx(classes.root, {
              [classes.highlight]: false,
            })}
            alignItems="center"
          >
            <Button
              style={{ padding: ".0rem" }}
              variant="text"
              disabled={deshabilitarReload}
              onClick={async () => {
                if (funcionReload) {
                  funcionReload();
                }
              }}
              startIcon={<Refresh />}
            >
              {textoActualizar ? textoActualizar : "ACTUALIZAR"}
            </Button>
            <Box display="flex" justifyContent="space-around">
              <TablePagination
                labelRowsPerPage="Filas"
                rowsPerPageOptions={[
                  10,
                  20,
                  50,
                  100,
                  { label: "Todos", value: pagination.numRows },
                ]}
                component="div"
                count={pagination.numRows}
                rowsPerPage={rowsPerPage}
                className={"MuiTablePagination-toolbar"}
                backIconButtonProps={{
                  style: { margin: 0, padding: 0 },
                  size: "small",
                  maxHeight: "25px",
                }}
                nextIconButtonProps={{
                  disabled: pagination.numRows < rows.length,
                  style: { margin: 0, padding: 0 },
                  size: "small",
                  maxHeight: "25px",
                }}
                SelectProps={{
                  style: { margin: 0, padding: 0 },
                  size: "small",
                  inputProps: { style: { margin: 0, padding: 0 } },
                  maxHeight: "25px",
                }}
                nextIconButtonText="Siguiente página"
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {arrayExport ? (
                <Tooltip title="Exportar">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    disabled={arrayExport.length === 0}
                    onClick={() => setopen(true)}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <ListRounded />
                  </IconButton>
                </Tooltip>
              ) : null}
              {otroBotonEnElToolbarTable ? (
                <Tooltip title={otroBotonEnElToolbarTable.Tooltip}>
                  <IconButton
                    disabled={otroBotonEnElToolbarTable.disabled}
                    aria-label="delete"
                    onClick={() => otroBotonEnElToolbarTable.funcion()}
                    style={{ margin: 0, padding: 0 }}
                  >
                    {otroBotonEnElToolbarTable.icono}
                  </IconButton>
                </Tooltip>
              ) : null}
            </Box>
          </Box>
        </>
      )}
      <Divider />
    </>
  );
};
export default memo(withRouter(ToolBarTable));
