import React, { memo } from "react";

import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import { Cancel, Check } from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { itemPrendaDefault } from "../../../data";
import { trimPropsItem } from "../../../../../config/const";
import { v4 } from "uuid";

const RowAgregarPrenda = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setagregar, setitemPadre, itemPadre } = props;

  const [itemHijo, setitemHijo] = useState(itemPrendaDefault);
  //onChange
  const handleChange = (e) => {
    setitemHijo({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      itemHijo.cantidad.trim() === "0" ||
      itemHijo.item.trim() === "" ||
      itemHijo.detalle.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      setitemPadre({
        ...itemPadre,
        iopPrendas: [
          trimPropsItem({ ...itemHijo, id: v4() }),
          ...itemPadre.iopPrendas,
        ],
      });
      setitemHijo(itemPrendaDefault);
      setagregar(false);
    } catch (error) {}
  };
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ButtonsAction
        botones={[
          {
            tooltip: "Cancelar",
            texto: "",
            funcion: () => {
              setagregar(false);
              setitemHijo(itemPrendaDefault);
              // setItemEdit(itemHijo);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
          },
          {
            tooltip: "Guardar",
            texto: "",
            funcion: () => {
              funcSubmit();
            },
            disabled: botonDisabled(),
            Icon: Check,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
            submit: true,
          },
        ]}
      />
      <>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            autoFocus
            value={itemHijo.item}
            name="item"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            type="number"
            value={itemHijo.cantidad}
            name="cantidad"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            value={itemHijo.detalle}
            name="detalle"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
      </>
    </TableRow>
  );
};

export default withRouter(memo(RowAgregarPrenda));
