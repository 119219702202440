import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";

import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";

import { replaceCaracteres, trimPropsItem } from "../../../config/const";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  TextField,
  // MenuItem,
  FormControl,
  Button,
  Autocomplete,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { objectDefault } from "../data";
import { useModalStyle } from "../../styles/styleModal";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const ModalAsistencia = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props
  const {
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [clientesData, setclientesData] = useState([]);

  // eslint-disable-next-line
  const {
    // gestCliId,
    gestCliDocNumero,
    gestCliRazonSocial,
    gestFechaInicio,
    gestAsunto,
    gestAntecedentes,
    gestFechaDesde,
    gestFechaHasta,
    gestConclusiones,
    gestRecomendaciones,
    // gestUser,
    // gestEmpresa,
    // gestFecReg,
    // gestFecUpd,
  } = item;
  // eslint-disable-next-line

  const [error, seterror] = useState({});

  useEffect(() => {
    // if (open && ObjectoActivo.gestCliDocNumero !== "") {
    //   const getresguardiasData = async () => {
    //     try {
    //       // const itemF = await clienteAxios.get(
    //       //   `/clientes/filtro/0?search=${ObjectoActivo.gestCliDocNumero}&estado=CLIENTES`
    //       // );
    //       const res = await clienteAxios.get(
    //         `/personas/listado/:codigo?docnumero=${ObjectoActivo.gestCliDocNumero}`
    //       );
    //       setguardiasData(setitemsPersonas(res.data.data));
    //     } catch (error) {
    //       setguardiasData([]);
    //     }
    //   };

    //   getresguardiasData();
    // }

    setitem(ObjectoActivo);

    // eslint-disable-next-line
  }, [ObjectoActivo, open]);

  const defaultActive = async () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seteditar(false);
    seterror({});
    setcargando(false);
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useModalStyle();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);
   

  // const [seccionActive, setseccionActive] = useState({});

  const validacion = () => {
    if (
      gestCliDocNumero.trim() === "" ||
      gestFechaInicio.trim() === "" ||
      gestAsunto.trim() === "" ||
      gestFechaDesde.trim() === "" ||
      gestFechaHasta.trim() === ""
    ) {
      seterror({
        gestCliDocNumero: gestCliDocNumero.trim() === "",
        gestFechaInicio: gestFechaInicio.trim() === "",
        gestAsunto: gestAsunto.trim() === "",
        gestFechaDesde: gestFechaDesde.trim() === "",
        gestFechaHasta: gestFechaHasta.trim() === "",
      });
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          gestUser: usuario.usuario,
          gestEmpresa: usuario.rucempresa,
          tabla: "gestionoperativa",
        })
      );

      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);

      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan datos obligatorios por llenar", "error");
    }
    try {
      setcargando(true);
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          gestUser: usuario.usuario,
          gestEmpresa: usuario.rucempresa,
          tabla: "gestionoperativa",
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
 

  // eslint-disable-next-line

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  id="handle"
                  style={{ cursor: "-webkit-grab" }}
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {editar ? "Editar" : "Agregar"} GOP.
                </Typography>
                <Typography
                  variant="h5"
                  color="initial"
                  align="center"
                ></Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  {/* <Button
                    variant={DatosGenerales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ DatosGenerales: true })}
                  >
                    Datos Generales
                  </Button> */}
                </Box>
              </Box>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (editar) {
                    handleEditar();
                  } else {
                    handleCrear();
                  }
                }}
              >
                <Box className={clases.container}>
                  <>
                    <FormControl
                      inputProps={{ className: clases.inputPadding }}
                      className={clases.formD}
                    >
                      <Autocomplete
                        disabled={editar}
                        disableClearable={true}
                        options={clientesData.map((item) => {
                          return (
                            item.cliRazonSocial +
                            "/+/" +
                            item.cliDocNumero +
                            "/+/" +
                            item.cliId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={(
                          gestCliDocNumero +
                          " " +
                          gestCliRazonSocial
                        ).trim()}
                        paperprops
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const [
                              gestCliRazonSocial,
                              gestCliDocNumero,
                              gestCliId,
                            ] = newValue.split("/+/");

                            setitem({
                              ...item,
                              gestCliRazonSocial,
                              gestCliDocNumero,
                              gestCliId,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            error={error.gestCliDocNumero}
                            label="Cliente"
                            onChange={async (e) => {
                              if (e.target.value === "") {
                                return mostrarAlerta("Ingrese datos", "error");
                              }
                              try {
                                const res = await clienteAxios.get(
                                  `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                );

                                setclientesData(res.data.data);
                              } catch (error) {
                                setitem({
                                  ...item,
                                });
                                setclientesData([]);

                                return mostrarAlerta(
                                  "No hay clientes",
                                  "error"
                                );
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      size="small"
                      label="F. Inicio"
                      value={gestFechaInicio}
                      name="gestFechaInicio"
                      error={error.gestFechaInicio}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formAllW}
                      size="small"
                      label="Asunto"
                      value={gestAsunto}
                      name="gestAsunto"
                      error={error.gestAsunto}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      label="Antecedentes"
                      value={gestAntecedentes}
                      name="gestAntecedentes"
                      error={error.gestAntecedentes}
                      onChange={(e) => handleChange(e)}
                    />
                    <Box
                      width={"100%"}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Divider style={{ width: "20%" }} />
                      <Typography
                        style={{ width: "100%" }}
                        variant="button"
                        color="secondary"
                        align="center"
                        component={"span"}
                        fontSize={".75rem"}
                      >
                        Plazo de ejecución del servicio
                      </Typography>
                      <Divider style={{ width: "20%" }} />
                    </Box>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      size="small"
                      label="F. Desde"
                      value={gestFechaDesde}
                      name="gestFechaDesde"
                      error={error.gestFechaDesde}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      className={clases.formD}
                      size="small"
                      label="F. Hasta"
                      value={gestFechaHasta}
                      name="gestFechaHasta"
                      error={error.gestFechaHasta}
                      onChange={(e) => handleChange(e)}
                    />
                    <Divider className={clases.formAllW} />
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      label="Conclusiones"
                      value={gestConclusiones}
                      name="gestConclusiones"
                      error={error.gestConclusiones}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      label="Recomendaciones"
                      value={gestRecomendaciones}
                      name="gestRecomendaciones"
                      error={error.gestRecomendaciones}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                </Box>

                {/* <Divider /> */}
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                >
                  <Button
                    style={{ width: "30%" }}
                    variant="contained"
                    color="primary"
                    disabled={cargando}
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Box>
              </form>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
