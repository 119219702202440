import React, { memo, useState, useContext, useEffect, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../components/BarraTablas";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import Modal from "./Componentes/Modal";
import io from "socket.io-client";
import { socketUrl } from "../../config/const";
import ModalB from "../components/ModalB";
import DialogOpciones from "../components/DialogOpciones";
import { Add } from "@mui/icons-material";
import { usePermiso } from "../hooks/usePermiso";
import { useCrud } from "../hooks/useCrud";
// COLUMNAS

import { columns, objectDefault } from "./data";

const ActividadesCabecera = ({ fromIOP, socketProp, clienteIOP }) => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);
  // ABRIR MODAL
  const [open, setopen] = useState(false);
  // BOOLEANO DE SI SE ESTA BUSCANDO O NO
  const [buscando, setbuscando] = useState(false);
  // SI SE ESTA EDITANDO O NO
  const [editar, seteditar] = useState(false);
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // OBJETO ACTIVO O PLANTILLA
  const [ObjectoActivo, setObjetoActivo] = useState(objectDefault);
  // NOMBRE DE LA TABLA
  const [tabla] = useState("avisosalida");
  // ESTADO O TAB
  const [estadoRequest, setestadoRequest] = useState("GUARDIA");
  const [subEstado, setSubEstado] = useState("PROCESO");
  // STRING DE BUSQUEDA
  const [search, setsearch] = useState("");
  // CAMPO POR EL CUAL SE FILTRAN LOS REGISTROS PARA EXPORTAR
  const [campoExport] = useState("id_acti_asig_per");
  // ARRAY DE EXPORTACION
  const [arrayExport, setarrayExport] = useState({});
  // CAMPOS DE BUSQUEDA EXPANDIDOS
  const [openMB, setopenMB] = useState(true);
  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columns.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  // OBJETO BUSCADOR
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
  });

  // ITEM DEL SOCKET SE UTILIZA COMO REFERENCIA
  const [itemSocket, setitemSocket] = useState({
    tipo: "",
    item: {},
  });
  // HOOK PARA FUNCIONES DEL CRUD
  const { agregarState, editarState } = useCrud(
    rows,
    setrows,
    campoExport,
    "acti_user",
    usuario
  );
  // REFERENCIA DEL SOCKET
  const socket = useRef();
  // USEFFECT QUE ESCUCHUA LOS EVENTOS DEL SOCKET
  useEffect(() => {
    // Creates a WebSocket connection
    socket.current = io(socketUrl);
    socket.current.on("server:guardadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "agregar",
        item: data,
      });
    });
    socket.current.on("server:actualizadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }

      setitemSocket({
        tipo: "editar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitoso", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminar",
        item: data,
      });
    });
    socket.current.on("server:eliminadoExitosoSeleccionado", (data) => {
      if (data.tabla !== tabla) {
        return;
      }
      setitemSocket({
        tipo: "eliminarSeleccion",
        item: data,
      });
    });

    socket.current.on("server:error", (data) => {
      mostrarAlerta(
        data.msg ? data.msg : "Hubo un error",
        data.alert ? data.alert : "error"
      );
    });
    return () => {
      socket.current.disconnect();
    };
    // Destroys the socket reference
    // when the connection is closed
    // eslint-disable-next-line
  }, []);
  // CUANDO CAMBIA EL OBJETO DEL SOCKET SE ESCUCHA AQUI Y SE DECIDE QUE HACER CON EL
  useEffect(() => {
    const { item, tipo } = itemSocket;

    if (tipo === "") {
      return;
    }
    if (!rows) {
      return;
    }
    if (usuario.rucempresa !== item.rucempresa) {
      return;
    }
    if (item.tabla !== tabla) {
      return;
    }

    const funcionAalerta = () => {
      if (item.msg === "") {
        return null;
      }
      if (!item.msg) {
        return null;
      }
      mostrarAlerta(item.msg, item.alert ? item.alert : "success");
    };

    const padre =
      tipo !== "eliminarSeleccion" &&
      rows.find(
        (itemPadre) =>
          itemPadre.fecha_inicio.substr(0, 7) === item.fecha_inicio.substr(0, 7)
      );

    if (tipo === "agregar") {
      if (padre) {
        editarState({ ...padre, tregistros: padre.tregistros + 1, itemSocket });
      } else {
        agregarState(item);
      }
    }
    if (tipo === "editar") {
      if (padre) {
        editarState({ ...padre, itemSocket });
      }
    }
    if (tipo === "eliminar") {
      if (padre) {
        editarState({ ...padre, itemSocket });
      }
    }
    if (tipo === "eliminarSeleccion") {
      setrows(
        rows.map((itemR) => {
          if (item.lista[itemR[campoExport]]) {
            return {
              ...itemR,
              itemSocket: {
                tipo,
                item: { lista: item.lista[itemR[campoExport]] },
              },
            };
          } else {
            return itemR;
          }
        })
      );
    }
    funcionAalerta();
    setitemSocket({ tipo: "", item: {} });
    // eslint-disable-next-line
  }, [itemSocket]);

  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("ActividadesCabecera");

  // EVENTO QUE CAMBIA LA DATA DEL OBJETO BUSCADOR
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
  };
  // FUNCION QUE OBTIENE LA DATA DE LA TABLA
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    perfil,
    estado,
    grupo,
    fecha,
    id_persona
  ) => {
    try {
      const res = await clienteAxios.get(
        `/cumplimiento_actividades/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${
          orden ? orden : ""
        }&datos=${""}&rucempresa=${
          rucempresa ? rucempresa : ""
        }&perfil=${perfil || ""}&estado=${estado ? estado : ""}&grupo=${
          grupo ? grupo : ""
        }&fecha=${fecha ? fecha : ""}&id_persona=${id_persona || ""}`
      );
      // &fromIOP=${
      // fromIOP ? fromIOP : ""
      // }&idCliente=${clienteIOP ? clienteIOP.cliId : ""}
      return res;
    } catch (error) {
      console.log({ error });
      mostrarAlerta("Hubo un error");
    }
  };
  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    subEstado,
    grupo,
    gruopP1,
    gruopP2
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      subEstado,
      grupo,
      gruopP1,
      gruopP2
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // FUNCION DE BUSQUEDA AVANZADA
  const funcionB = async () => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "padre"
      // gruopP1
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  // FUNCION PARA CAMBIAR PAGINA
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        subEstado,
        "padre"
        // gruopP1
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row[campoExport] === item[campoExport];
        });

        return !existe;
      });

      setrows([...rows, ...resultado]);
    }
    setPage(newPage);
  };

  // eslint-disable-next-line
  // FUNCION QUE CAMBIA LOS REGISTROS O FILAS POR PAGINA
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "padre"
      // gruopP1
    );
  };
  // FUNCION QUE ORDENA DESCENDENTE O ASCENDENTEMENTE
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "padre"
      // gruopP1
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  // REFRES SEARCH SIN ALTERAR
  const funcionReload = async () => {
    setcargando(true);
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "padre"
      // gruopP1
    );
    setcargando(false);
  };

  // FUNCION QUE PONE LA DATA DE POR DEFECTO O HACE UN REFRESH SEARCH POR DEFECTO
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await obtenerState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest,
      subEstado,
      "padre"
      // gruopP1
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Agregar",
            funcion: () => {
              setopen(true);
            },
            Icon: Add,
            color: "primary",
            id: 1,
            disabled: false,
          },
        ]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar Cédula y Nombres"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={fromIOP}
        botones={[
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 1,
            ocultar: true,
            tipo: "icono",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Agregar",
            texto: "Agregar",
            funcion: () => {
              if (!tienePermiso("añadir")) {
                return alertaPermiso("añadir");
              }
              setopen(true);
            },
            disabled: false,
            Icon: Add,
            color: "primary",
            id: 2,
            ocultar: fromIOP,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      {/* <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={"Busqueda avanzada (Actividad Cabecera)"}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      /> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          {["GUARDIA", "SUPERVISOR", "ADMINISTRACION"].map((itemEstado) => (
            <Button
              size="small"
              variant={estadoRequest === itemEstado ? "contained" : null}
              disabled={cargando}
              onClick={async () => {
                setcargando(true);
                await obtenerState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify({
                    nomFecReg1: "",
                    nomFecReg2: "",
                  }),
                  rucempresa,
                  itemEstado,
                  subEstado,
                  "padre"
                );

                setPage(0);
                setestadoRequest(itemEstado);
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              {itemEstado}
            </Button>
          ))}
        </Box>
        <Box>
          {["FINALIZADA", "INCUMPLIDA", "PROCESO"].map((itemSubEstado) => (
            <Button
              size="small"
              variant={subEstado === itemSubEstado ? "contained" : null}
              disabled={cargando}
              onClick={async () => {
                setcargando(true);
                await obtenerState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify({
                    nomFecReg1: "",
                    nomFecReg2: "",
                  }),
                  rucempresa,
                  estadoRequest,
                  itemSubEstado,
                  "padre"
                );

                setPage(0);
                setSubEstado(itemSubEstado);
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="secondary"
            >
              {itemSubEstado}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider />
      <Tabla
        // PAGINAS Y PAGINACION
        page={page}
        rowsPerPage={rowsPerPage}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        // OPEN Y EDIT
        setopen={setopen}
        seteditar={seteditar}
        // ORDEN
        input={input}
        orden={orden}
        // BUSCAR
        search={search}
        fullBuscador={fullBuscador}
        // EXPORTAR
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        // CARGANDO
        cargando={cargando}
        setcargando={setcargando}
        // DATA
        rows={rows}
        columns={columns}
        estadoRequest={estadoRequest}
        subEstado={subEstado}
        // FUNCIONES DATA
        getDataState={getDataState}
        obtenerState={obtenerState}
        changeObjetoActivoState={changeObjetoActivoState}
        funcionOrdenar={funcionOrdenar}
        funcionReload={funcionReload}
        // SOCKET
        tabla={tabla}
        socket={socket}
      />
      {socket.current && (
        <Modal
          editar={editar}
          seteditar={seteditar}
          setopen={setopen}
          open={open}
          ObjectoActivo={ObjectoActivo}
          changeObjetoActivoState={changeObjetoActivoState}
          socket={socket}
        />
      )}
    </Box>
  );
};

export default memo(ActividadesCabecera);
