import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  MenuItem,
  Popper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import { objectDefault } from "../data";
import clienteAxios from "../../../../config/axios";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    setopen,
    socket,
    stocksData,
    desdeProductos,
    producto,
    id_compra,
  } = props;
  const claseCelda = `${classes.celda}`;
  console.log(desdeProductos);
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(
    desdeProductos
      ? {
          ...objectDefault,
          prodTipo: producto.prodTipo,
          prodId: producto.prodId,
          prodNombre: producto.prodNombre,
          id_compra: id_compra,
        }
      : objectDefault
  );

  const [productosData, setProductosData] = useState([]);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (item.bodeNombre === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      console.log(
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "stocks",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "stocks",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          user: usuario.usuario,
          cod_empresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  const PopperMy = function(props) {
    return (
      <Popper {...props} style={useRowStyles.popper} placement="bottom-start" />
    );
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          sx={{ paddingLeft: "5px" }}
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          {/* <TableCell size="small" align="center" className={claseCelda}>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                disabled
                PopperComponent={PopperMy}
                options={productosData.map((item) => {
                  return (
                    item.prodTipo +
                    "/+/" +
                    item.prodNombre +
                    "/+/" +
                    item.prodId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={
                  (item.prodTipo + " " + item.prodNombre).trim() === ""
                    ? ""
                    : item.prodTipo + " " + item.prodNombre
                }
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const [prodTipo, prodNombre, prodId] = newValue.split(
                      "/+/"
                    );
                    if (!newValue) return;
                    setitem((prev) => ({
                      ...prev,
                      prodTipo,
                      prodNombre,
                      prodId,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    required
                    fullWidth
                    onChange={async (e) => {
                      try {
                        const res = await clienteAxios.get(
                          `productos/getBySearch/serie_tipo_nombre?search=${e.target.value}`
                        );
                        console.log({ res });
                        setProductosData(res.data);
                      } catch (error) {}
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell> */}
          <TextField
            value={item.condicion_producto}
            name="condicion_producto"
            onChange={(e) =>
              setitem({
                ...item,
                [e.target.name]: e.target.value,
              })
            }
            size="small"
            fullWidth
            select
            SelectProps={{
              displayEmpty: true, 
            }}
          >
            {["NUEVO", "USADO", "OPERABLE", "BUEN ESTADO", "DEFECTUOSO"].map(
              (item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              }
            )}
          </TextField>
          <TableCell size="small" align="center" className={claseCelda}>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                PopperComponent={PopperMy}
                options={stocksData.map((item) => {
                  return item.bodeNombre + "/+/" + item.bodeId;
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={item.bodeNombre.trim() === "" ? "" : item.bodeNombre}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const [bodeNombre, bodeId] = newValue.split("/+/");
                    if (!newValue) return;
                    setitem((prev) => ({
                      ...prev,
                      bodeNombre,
                      bodeId,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" required fullWidth />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={item.stock_total_por_bodega}
              name="stock_total_por_bodega"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(1, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
          {/* <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={item.maximo}
              name="maximo"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(0, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              type="number"
              value={item.minimo}
              name="minimo"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(0, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>{" "} */}
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              disabled
              value={item.stock_disponible}
              name="stock_disponible"
              onChange={(e) =>
                setitem({
                  ...item,
                  [e.target.name]: Math.max(0, e.target.value),
                })
              }
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
          ></TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
          ></TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
