import React, { memo, useContext } from "react";
import TablaCabecera from "../../../../../components/Extra/TablaCabecera";

import { useState } from "react";
import RowItem from "./RowItem/RowItemDocumentos";
import RowAgregarItem from "./RowItem/RowAgregarItemDocumentos";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import { replaceCaracteres, trimPropsItem } from "../../../../../config/const";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  // Checkbox,
  // FormControlLabel,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Collapse,
  Typography,
  TableBody,
  Table,
  MenuItem,
} from "@mui/material";
import {
  Cancel,
  AddCircle,
  Check,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregar = (props) => {
  const { setagregar } = props;
  const [agregarItemLista, setagregarItemLista] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const [item, setitem] = useState({
    docCodigo: "",
    docTipo: "",
    docDocumentos: [
      // {
      //   nombre: "Datos personales",
      //   propiedad: "datospersonales",
      //   obligatorio: "SI",
      //   extension: "pdf",
      //   fecExpira: "SI",
      // },
    ],
    docEmpresa: "",
    docUser: "",
    docFecReg: "",
    Todos: "",
  });

  const classes = useRowStyles();
   

  const { socket, usuario } = useContext(AuthContext);
  const handleAgregar = async () => {
    try {
      socket.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          tabla: "documento",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          docUser: usuario.usuario,
          docEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.novOrigen === "" ||
      item.novTipo === "" ||
      item.novPorcentaje === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell></TableCell> {/* boton */}
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregar(false);
                setitem({
                  docCodigo: "",
                  docTipo: "",
                  docDocumentos: [
                    // {
                    //   nombre: "Datos personales",
                    //   propiedad: "datospersonales",
                    //   obligatorio: "SI",
                    //   extension: "pdf",
                    //   fecExpira: "SI",
                    // },
                  ],
                  docEmpresa: "",
                  docUser: "",
                  docFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();

                setagregar(false);
                setitem({
                  docCodigo: "",
                  docTipo: "",
                  docDocumentos: [
                    // {
                    //   nombre: "Datos personales",
                    //   propiedad: "datospersonales",
                    //   obligatorio: "SI",
                    //   extension: "pdf",
                    //   fecExpira: "SI",
                    // },
                  ],
                  docEmpresa: "",
                  docUser: "",
                  docFecReg: "",
                  Todos: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
            <IconButton
              className={classes.margin}
              onClick={() => {
                setexpandir(!expandir);
              }}
            >
              {expandir ? (
                <ExpandLess fontSize="small" color="secondary" />
              ) : (
                <ExpandMore fontSize="small" color="secondary" />
              )}
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="docTipo"
            inputProps={{ style: { textAlign: "center" } }}
            value={item.docTipo}
            onChange={(e) => {
              const res = replaceCaracteres(e.target.value);
              setitem({
                ...item,
                docTipo: res,
              });
            }}
            size="small"
            helperText="Agregando"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {["CLIENTE", "GUARDIA", "SUPERVISOR", "ADMINISTRACION","EMPRESA"].map(
              (item) => {
                return <MenuItem value={item}>{item} </MenuItem>;
              }
            )}
          </TextField>
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docCodigo}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docFecReg}
        </TableCell>{" "}
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {item.docUser}
        </TableCell>
      </TableRow>{" "}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box
                display="flex"
          
                alignItems="center"
              >
                <Typography variant="body1" gutterBottom component="span">
                  Propiedades
                </Typography>{" "}
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "nombre",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Nombre",
                    },

                    {
                      id: "obligatorio",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Obligatorio",
                    },
                    {
                      id: "extension",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Extensión",
                    },
                    {
                      id: "fecExpira",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "Fec. Expira",
                    },
                  ]}
                />
                <TableBody>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      item={item}
                      setitem={setitem}
                      setagregarItemLista={setagregarItemLista}
                    />
                  ) : null}
                  {item.docDocumentos.map((consigna, index) => {
                    return (
                      <RowItem
                        consigna={consigna}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
