import clienteAxios from "../../../config/axios";

const fileUpload = async (file) => {
  try {
    const formData = new FormData();
    await formData.append("archivo", file);
    formData.append("carpeta", "documentos");
    const res = await clienteAxios.post(
      "/subir_eliminar_archivo/subir",
      formData
    );
    return { url: res?.data?.url || "" };
  } catch (error) {
    return "";
  }
};
export const uploadImagesHelper = async (imagenes, fotosSubidas) => {
  const fileUploadPromises = [];
  for (const file of imagenes) {
    fileUploadPromises.push(fileUpload(file));
  }

  const photosUrls = await Promise.all(fileUploadPromises);
  if (fotosSubidas.length > 0) {
    await clienteAxios.post("/subir_eliminar_archivo/eliminar", {
      urls: fotosSubidas.map((item) => item.url).filter((itemE) => itemE !== ""),
    });
  }

  return { data: { urls: photosUrls } };
};
