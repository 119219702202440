import { FechaExacta } from "../../../config/const";

export const columns = [
  {
    id: "nomFecReg",
    label: "Fecha",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomFecReg",
    align: "center",
    ocultar: true,
  },

  {
    id: " tmultas",
    label: "T. Multas",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "tmultas",
    align: "center",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "nomDocuPer",
    label: "Persona",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "left",
    ocultar: true,
  },
  {
    id: "nomFotos",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomPorcentaje",
    label: "Porcentaje",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomDetalle",
    label: "Motivo",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomObservacion",
    label: "Observación",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomEstado",
    label: "Estado",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomEstado",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomVinculacionCliente",
    label: "Cliente.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomVinculacionCliente",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomFecReg",
    label: "Fec. Reg.",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
  {
    id: "nomUser",
    label: "Multado por",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "nomDocuPersona",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  nomCodigo: "",
  nomOrigen: "",
  nomTipo: "",
  nomPorcentaje: "",
  nomSueldo: "",
  nomDescuento: "",
  nomDetalle: "",
  nomObservacion: "",
  nomFotos: [],
  nomVideo: [],
  nomCompartido: [],
  nomPuesto: [
    // {
    //   ruccliente: "0891763786001",
    //   razonsocial: "COMITÉ PRO MEJORAS URBANIZACIÓN TECNIPETROL",
    //   ubicacion: "ESMERALDAS",
    //   puesto: "TECNIPETROL",
    // },
  ],
  nomEstado: "EN PROCESO",
  nomAnulacion: "",
  nomCiudad: "",
  nomFecha: FechaExacta,
  nomIdPer: "",
  nomDocuPer: "",
  nomNombrePer: "",
  nomApelacionTexto: "",
  nomApelacionFotos: [],
  nomCorreo: [],
  nomApelacionFecha: "",
  nomApelacionTextoAceptada: "",
  nomApelacionFechaAceptada: "",
  nomApelacionUserAceptada: "",
  nomMostrarEnInforme: "",
  nomUser: "",
  nomEmpresa: "",
  nomFecReg: "",
  Todos: "",
};
