import React, { useContext, useState, useEffect, memo } from "react";

import { withRouter } from "react-router-dom"; // import Spinner from "../../Spinner/Spinner";
import Row from "./Documentos/RowDocumentos";
import Cargando from "../../../../components/Extra/Cargando";
import ToolBarTable from "../../../../components/Extra/ToolBarTable";
import TablaCabecera from "../../../../components/Extra/TablaCabecera";
import RowAgregarDocumentos from "./Documentos/RowAgregarDocumentos";
import RowAgregarEvaluaciones from "./Evaluaciones/RowAgregarEvaluaciones";
import RowEvaluaciones from "./Evaluaciones/RowEvaluaciones";
import AuthContext from "../../../../Context/Auth/AuthContext";
import DocumentosContext from "../../../../Context/Configuraciones/Documentos/DocumentosContext";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 800,
  },
});
const TablaAsignaturas = (props) => {
  const {
    agregar,
    setagregar,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    rows,
    search,
    input,
    orden,
    setinput,
    setorden,
    cargando,
    setcargando,
    fullBuscador,
    estadoRequest,
  } = props;

  const {
    columns: columnsDocs,
    obtener,
    pagination,
    addRows,
    remplazarRows,
    getData,
  } = useContext(DocumentosContext);
  const columns =
    estadoRequest === "DOCUMENTOS"
      ? columnsDocs
      : [
          {
            id: "docDestinatario",
            label: "Destinatario",
            minWidth: 150,
            tipo: "string",
            campo1: "docDestinatario",
          },
          {
            id: "docAlcance",
            label: "Alcance",
            minWidth: 150,
            tipo: "string",
            campo1: "docAlcance",
          },
          {
            id: "docTitulo",
            label: "Titulo",
            minWidth: 150,
            tipo: "string",
            campo1: "docTitulo",
          },

          {
            id: "docFechaEmision",
            label: "F. Emision",
            minWidth: 150,
            tipo: "string",
            campo1: "docFechaEmision",
          },
          {
            id: "docFechaFinalizacion",
            label: "F. Finalizacion",
            minWidth: 150,
            tipo: "string",
            campo1: "docFechaFinalizacion",
          },
          {
            id: "docNotIns",
            label: "Notificacion",
            minWidth: 150,
            tipo: "string",
            campo1: "docNotIns",
          },
          {
            id: "docObligatorio",
            label: "Obligatorio",
            minWidth: 150,
            tipo: "string",
            campo1: "docObligatorio",
          },

          {
            id: "docFecReg",
            label: "FecReg",
            minWidth: 150,
            tipo: "string",
            campo1: "docFecReg",
          },

          {
            id: "docUser",
            label: "User",
            minWidth: 150,
            tipo: "string",
            campo1: "docUser",
          },
        ];
  const {
    usuario: { rucempresa },
  } = useContext(AuthContext);
  //columnas y rows de la tabla

  // eslint-disable-next-line
  const clases = useStyles();
  // eslint-disable-next-line

  // eslint-disable-next-line
  // eslint-disable-next-line
  // eslint-disable-next-line

  const [arrayExport, setarrayExport] = useState([]);
  const [campoExport] = useState("docId");
  //clases
  const classes = useStyles();

  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getData(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest
      );

      const data = res.data.data.results;
      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row.docId === item.docId;
        });

        return !existe;
      });

      addRows(resultado);
    }

    setPage(newPage);
  };

  // eslint-disable-next-line

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (page === 0) {
      if (event.target.value > rows.length) {
        obtener(
          event.target.value,
          0,
          search,
          input.id,
          orden,
          JSON.stringify(fullBuscador),
          rucempresa,
          estadoRequest
        );
      }
    }
  };
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await remplazarRows(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  const funcionReload = async () => {
    try {
      setcargando(true);
      setRowsPerPage(10);
      setPage(0);
      await obtener(
        10,
        0,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest
      );
      setcargando(false);
    } catch (error) {}
  };

  useEffect(() => {
    //eslint-disable-next-line

    obtener(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest
    );
    // eslint-disable-next-line
  }, []);
  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Paper className={classes.root}>
      <ToolBarTable
        search={search}
        titulo={"Documentos"}
        arrayExport={arrayExport}
        columns={columns}
        expandible={false}
        campoExport={campoExport}
        tabla="documento"
        funcionReload={funcionReload}
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
          <TableHead>
            <TablaCabecera
              remplazarRows={remplazarRows}
              estadoRequest={estadoRequest}
              search={search}
              input={input}
              setinput={setinput}
              orden={orden}
              setorden={setorden}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setcargando={setcargando}
              rows={rows}
              setarrayExport={setarrayExport}
              columns={columns}
              habilitarOrdenar={true}
              funcionOrdenar={funcionOrdenar}
            />
          </TableHead>
          <TableBody>
            {agregar && estadoRequest === "DOCUMENTOS" ? (
              <RowAgregarDocumentos setagregar={setagregar} />
            ) : null}
            {estadoRequest === "DOCUMENTOS" ? (
              cargando ? (
                <TableCell
                  size="small"
                  align="center"
                  colSpan={columns.length + 2}
                >
                  <Cargando titulo="Cargando cargando" />
                </TableCell>
              ) : (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    if (row.docOption !== "DOCUMENTOS") {
                      return null;
                    }
                    return (
                      <Row
                        arrayExport={arrayExport}
                        setarrayExport={setarrayExport}
                        row={row}
                        index={index}
                        campoExport={campoExport}
                        key={row.docId}
                      />
                    );
                  })
              )
            ) : null}
            {agregar &&
            (estadoRequest === "EVALUACIONES" ||
              estadoRequest === "ENCUESTAS") ? (
              <RowAgregarEvaluaciones
                setagregar={setagregar}
                estadoRequest={estadoRequest}
              />
            ) : null}
            {estadoRequest === "EVALUACIONES" ||
            estadoRequest === "ENCUESTAS" ? (
              cargando ? (
                <TableCell
                  size="small"
                  align="center"
                  colSpan={columns.length + 2}
                >
                  <Cargando titulo="Cargando cargando" />
                </TableCell>
              ) : (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    if (row.docOption !== estadoRequest) {
                      return null;
                    }

                    return (
                      <RowEvaluaciones
                        estadoRequest={estadoRequest}
                        arrayExport={arrayExport}
                        setarrayExport={setarrayExport}
                        row={row}
                        index={index}
                        campoExport={campoExport}
                        key={row.docId}
                      />
                    );
                  })
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(TablaAsignaturas));
