import React, { memo, useContext, useState } from "react";

// COMPONENTES
import Cargando from "./Cargando";
//CSS
import "../../components/Extra/GlobalCssSlider.css";

// CONTEXT
import AuthContext from "../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { DeleteForever, PictureAsPdf, MenuBook } from "@mui/icons-material";
import ConfirmacionDialog from "./ConfirmacionDialog";
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  bg: {
    backgroundColor: theme.palette.primary.dark,
  },
}));
const ExportDialog = (props) => {
  const classes = useStyles();
  const { socket, usuario } = useContext(AuthContext);
  const {
    open,
    setopen,
    funcPdf,
    funcExcel,
    arrayExport,
    tipo,
    settipo,
    campoExport,
    tabla,
    otraFuncionExport,
    exportarExcel,
    ocultarDelete,
    ocultarExportarPdf,
    otraFuncionExport2,
  } = props;
  const [cargando, setcargando] = useState(false);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const handleEliminar = async () => {
    await setcargando(true);

    await setTimeout(async () => {
      const lista = arrayExport.map((item) => {
        return item[campoExport];
      });

      socket.emit("client:eliminarDataSeleccionado", {
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla,
        lista,
      });
      setcargando(false);
      setopen(false);
    }, 500);
  }; 
  return (
    <Dialog
      onClose={() => setopen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este/estos registros?`}
        funcion={handleEliminar}
      />
      <Box padding={2}>
        <DialogTitle id="simple-dialog-title">Opciones.</DialogTitle>
        <TextField
          id=""
          label=""
          fullWidth
          value={tipo}
          onChange={(e) => {
            settipo(e.target.value);
          }}
          select
        >
          {[
            { value: "seleccion", label: "Seleccion" },
            { value: "todos", label: "Todos" },
          ].map((item, index) => {
            return (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            );
          })}
        </TextField>
        {cargando ? (
          <Cargando titulo="Cargando." />
        ) : (
          <>
            <List>
              {ocultarExportarPdf ? null : (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    setcargando(true);
                    await funcPdf();
                    setcargando(false);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.delete}>
                      <PictureAsPdf />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Exportar" />
                </ListItem>
              )}
              {exportarExcel ? (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    await funcExcel();
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <MenuBook />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Excel" />
                </ListItem>
              ) : null}
              {ocultarDelete ? null : (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    setopenConfirmDialog(true);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.delete}>
                      <DeleteForever />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Eliminar" />
                </ListItem>
              )}

              {otraFuncionExport ? (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    await setcargando(true);

                    await setTimeout(async () => {
                      otraFuncionExport.funcion(tipo);
                      setcargando(false);
                      setopen(false);
                    }, 500);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.bg}>
                      {otraFuncionExport.icono}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={otraFuncionExport.titulo} />
                </ListItem>
              ) : null}
              {otraFuncionExport2 ? (
                <ListItem
                  button
                  disabled={cargando}
                  onClick={async () => {
                    await setcargando(true);

                    await setTimeout(async () => {
                      otraFuncionExport2.funcion();
                      setcargando(false);
                      setopen(false);
                    }, 500);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.delete}>
                      {otraFuncionExport2.icono}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={otraFuncionExport2.titulo} />
                </ListItem>
              ) : null}
            </List>

            {/* <ExcelFile
              filename={titulo}
              element={
                <ListItem button onClick={(e) => {}}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <GridOn />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Excel" />
                </ListItem>
              }
            >
              <ExcelSheet
                dataSet={[
                  {
                    columns: columns.map((c, index) => {
                      return {
                        title: index > 1 && expandible ? "" : c.label,
                        style: {
                          font: { sz: "18", bold: true },
                          alignment: {
                            horizontal: "center",
                            vertical: "bottom",
                          },
                        },
                        width: { wpx: c.minWidth },
                      };
                    }),
                    data: expandible
                      ? arrayExport.flatMap((r) => {
                          return [
                            columns.map((c) => {
                              const { campo1, profundidad } = c;
                              if (profundidad === 1) {
                                return {
                                  value: r[campo1],
                                  style: {
                                    font: { color: { rgb: "ffffff" } },
                                    fill: {
                                      patternType: "solid",
                                      fgColor: { rgb: "3461eb" },
                                    },
                                  },
                                };
                              } else {
                                return {};
                              }
                            }),
                            [
                              {
                                value: "",
                                style: { font: { sz: "14" } },
                              },
                              ...columnsA.map((c) => {
                                const { label } = c;

                                return {
                                  value: label,
                                  style: {
                                    font: { color: { rgb: "ffffff" } },
                                    alignment: { horizontal: "center" },
                                    fill: {
                                      patternType: "solid",
                                      fgColor: { rgb: "000000" },
                                    },
                                  },
                                };
                              }),
                            ],
                            ...r.distributivos.flatMap((d, index) => {
                              return [
                                [
                                  {
                                    value: index + 1,
                                    style: { font: { sz: "14" } },
                                  },
                                  ...columnsA.map((ca) => {
                                    const { campo } = ca;
                                    return {
                                      value: d[campo] ? String(d[campo]) : "",
                                      style: { font: { sz: "14" } },
                                    };
                                  }),
                                ],
                              ];
                            }),
                          ];
                        })
                      : arrayExport.map((r) => {
                          return columns.map((c) => {
                            const {
                              campo1,
                              campo2,
                              campo3,
                              campo4,
                              profundidad,
                            } = c;
                            if (profundidad === 2) {
                              return {
                                value: r[campo1][campo2],
                                style: { font: { sz: "14" } },
                              };
                            } else if (profundidad === 3) {
                              return {
                                value: r[campo1][campo2][campo3],
                                style: { font: { sz: "14" } },
                              };
                            } else if (profundidad === 4) {
                              return {
                                value: r[campo1][campo2][campo3][campo4],
                                style: { font: { sz: "14" } },
                              };
                            } else {
                              return {
                                value: r[campo1],
                                style: { font: { sz: "14" } },
                              };
                            }
                          });
                        }),
                  },
                ]}
                name={titulo}
              />
            </ExcelFile> */}
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default memo(ExportDialog);
