import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
// MATERIAL
import { Dialog, TableCell, TableRow, TextField } from "@mui/material";

import { Cancel, Check, Image } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import ButtonsAction from "../../../components/ButtonsAction";
import { itemHijoDefectoVulnerabilidad } from "../../data";
import { v4 } from "uuid";
import useMultimediasUpload from "../../../hooks/useMultimediasUpload";
import Dropzone from "../../../../components/Extra/Dropzone";

const RowAgregarHijo = (props) => {
  // eslint-disable-next-line
  const { rowPadre, setagregandoVulneravilidad, socket } = props;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [itemHijo, setitemHijo] = useState({
    ...itemHijoDefectoVulnerabilidad,
  });
  const [openDialog, setopenDialog] = useState(false);
  const [cargando, setcargando] = useState(false);

  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const botonDisabled = () => {
    if (itemHijo.nombre === "") {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        setagregandoVulneravilidad(false);
        setitemHijo({
          ...itemHijoDefectoVulnerabilidad,
        });
      },
      disabled: false,
      Icon: Cancel,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        handleAgregar();
      },
      disabled: botonDisabled() || cargando,
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Fotos",
      texto: itemHijo.fotos.length,
      funcion: () => {
        setopenDialog(true);
      },
      disabled: cargando,
      Icon: Image,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];

  const classes = useRowStyles();

  const handleChange = (e) => {
    setitemHijo({
      ...itemHijo,
      [e.target.name]: e.target.value,
    });
  };

  const handleAgregar = async () => {
    try {
      const existe = rowPadre.eveVulnerabilidades.some(
        (itemActividad) => itemHijo.nombre === itemActividad.nombre
      );
      if (existe) {
        return mostrarAlerta("Ya existe esta actividad", "error");
      }
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, itemHijo.fotos);
      const eveVulnerabilidades = [
        trimPropsItem({ ...itemHijo, id: v4(), fotos: resDocumentos }),
        ...rowPadre.eveVulnerabilidades,
      ];

      socket.current.emit("client:actualizarData", {
        ...rowPadre,
        tabla: "eventoactividad",
        eveVulnerabilidades,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        eveUser: usuario.usuario,
        eveEmpresa: usuario.rucempresa,
      });
      setagregandoVulneravilidad(false);
      setitemHijo({ ...itemHijoDefectoVulnerabilidad });
      setcargando(false);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction botones={botones} />

        {/* las demas columnas */}

        <>
          <TableCell fontSize="small" align="center" className={classes.celda}>
            <TextField
              autoFocus
              value={itemHijo.nombre}
              name="nombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell
            fontSize="small"
            align="center"
            className={classes.celda}
          ></TableCell>

          <Dialog open={openDialog} onClose={() => setopenDialog(false)}>
            <Dropzone
              setimagenes={(e) => {
                setmodificoImagenes(true);
                setimagenes(e);
              }}
              imagenes={imagenes}
              fotosItem={itemHijo.fotos}
            />
          </Dialog>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarHijo);
