import { CollectionsBookmark } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import ActividadLIstPersona from "./ActividadLIstPersona";

const ListPersonaItem = (props) => {
  const { value, item, setitem, propiedad, propiedadString, checked, labelId } =
    props;
  const [openDialog, setopenDialog] = useState(false);
  return (
    <>
      {openDialog ? (
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={openDialog}
          onClose={() => setopenDialog(false)}
          aria-labelledby={"FECHAS"}
        >
          <DialogTitle fontWeight={"bold"}>
            <Box display="flex" justifyContent={"space-between"} width={"100%"}>
              <Typography variant="body1" color="initial" fontWeight={"bold"}>
                {value.eventoPersona.act_asigEveNombre}
              </Typography>
              <Box ml={1} mr={1}></Box>
              <Typography
                variant="body1"
                color="initial"
                fontWeight={"bold"}
              ></Typography>
            </Box>
          </DialogTitle>
          <>
            {value.eventoPersona.act_asigEveActividades.map((eveActividad) => (
              <ActividadLIstPersona
                eveActividad={eveActividad}
                eventoPersona={value.eventoPersona}
              />
            ))}
          </>
          <DialogActions>
            <Button onClick={() => setopenDialog(false)} color="primary">
              LISTO
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <ListItem
        key={value.perId}
        secondaryAction={
          <Checkbox
            disabled={value.disabled}
            edge="end"
            onChange={(e) => {
              if (e.target.checked) {
                setitem({
                  ...item,
                  [propiedadString]: [...propiedad, value],
                });
              } else {
                setitem({
                  ...item,
                  [propiedadString]: propiedad.filter(
                    (itemFisguardia) => itemFisguardia.perId !== value.perId
                  ),
                });
              }
            }}
            checked={checked}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        }
        disablePadding
      >
        <ListItemButton>
          <ListItemAvatar>
            <Avatar alt={`Avatar n°${value + 1}`} src={`${value.perFoto}`} />
          </ListItemAvatar>
          <ListItemText
            perId={labelId}
            primary={`${value.perDocNumero} ${value.perApellidos} ${value.perNombres}`}
            primaryTypographyProps={{
              color:
                value.causa === "evento"
                  ? "error"
                  : value.causa === "horario"
                  ? "primary"
                  : "secondary",
            }}
          />
          {value.causa === "evento" ? (
            <IconButton
              aria-label=""
              onClick={() => {
                setopenDialog(true);
              }}
            >
              <CollectionsBookmark color="secondary" />{" "}
            </IconButton>
          ) : null}
          {/* <IconButton aria-label="" onClick={() => {}}>
            <Person color="secondary" />{" "}
          </IconButton> */}
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default ListPersonaItem;
