import React, { memo } from "react";

import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { itemHijoDefecto } from "../../data";
import { trimPropsItem } from "../../../../config/const";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setagregar, setitemPadre, itemPadre } = props;

  const [itemHijo, setitemHijo] = useState(itemHijoDefecto);
  //onChange
  const handleChange = (e) => {
    setitemHijo({ ...itemHijo, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (itemHijo.metodo.trim() === "" || itemHijo.porque.trim() === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      setitemPadre({
        ...itemPadre,
        mejMetodo: [trimPropsItem(itemHijo), ...itemPadre.mejMetodo],
      });
      setitemHijo(itemHijoDefecto);
      setagregar(false);
    } catch (error) {}
  };
  return (
    <TableRow
      className={classes.root}
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ButtonsAction
        botones={[
          {
            tooltip: "Cancelar",
            texto: "",
            funcion: () => {
              setagregar(false);
              setitemHijo(itemHijoDefecto);
              // setItemEdit(itemHijo);
            },
            disabled: false,
            Icon: Cancel,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
          },
          {
            tooltip: "Guardar",
            texto: "",
            funcion: () => {
              funcSubmit();
            },
            disabled: botonDisabled(),
            Icon: Check,
            color: "success",
            id: 2,
            ocultar: false,
            tipo: "icono",
            variante: "contained",
            size: "small",
            sx: butonIconTransition,
            submit: true,
          },
        ]}
      />
      <>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            autoFocus
            value={itemHijo.metodo}
            name="metodo"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell size="small" align="center" className={classes.celda}>
          <TextField
            value={itemHijo.porque}
            name="porque"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
      </>
    </TableRow>
  );
};

export default withRouter(memo(Row));
