import React, { memo } from "react";

import { makeStyles } from "@mui/styles";

import { withRouter } from "react-router-dom";
import { Box, TablePagination, lighten, Divider } from "@mui/material";
import Botones from "./Botones";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  gradient: {
    background: `linear-gradient(to left, ${lighten(
      theme.palette.secondary.main,
      0.6
    )} 0%, ${lighten(theme.palette.secondary.main, 0.7)} 100%)`,
  },
  title: {
    flex: "1 1 100%",
  },
  ocultar: {
    display: "none",
  },
}));
const ToolBarTable = (props) => {
  const classes = useToolbarStyles();
  const {
    count,
    pagination,
    rowsPerPage,
    rows,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    ocultarPaginacion,
    simplePagination,
    botonesIzquierdos,
    botonesDerechos,
  } = props;
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        className={classes.gradient} sx={{width:"100%"}}
      >
        <Box
          display="flex"
          alignItems={"center"} 
          sx={{ width: "100%" }}
        >
          <Botones botones={botonesIzquierdos} />
        </Box>
        <Box display="flex" justifyContent="flex-end"           sx={{ width: "100%" }}>
          {ocultarPaginacion ? null : simplePagination ? (
            <TablePagination
              labelRowsPerPage="Filas por pagina"
              rowsPerPageOptions={[10, 20, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              nextIconButtonText="Siguiente página"
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <TablePagination
              labelRowsPerPage="Filas"
              rowsPerPageOptions={[
                2,
                10,
                20,
                50,
                100,
                { label: "Todos", value: pagination.numRows },
              ]}
              component="div"
              count={pagination.numRows}
              rowsPerPage={rowsPerPage}
              backIconButtonProps={{
                style: { margin: 0, padding: 0 },
                size: "small",
                maxHeight: "25px",
              }}
              nextIconButtonProps={{
                disabled: pagination.numRows < rows.length,
                style: { margin: 0, padding: 0 },
                size: "small",
                maxHeight: "25px",
              }}
              SelectProps={{
                style: { margin: 0, padding: 0 },
                size: "small",
                inputProps: { style: { margin: 0, padding: 0 } },
                maxHeight: "25px",
              }}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}

          <Botones botones={botonesDerechos} />
        </Box>
      </Box>
      <Divider />
    </>
  );
};
export default memo(withRouter(ToolBarTable));
