import React, { memo } from "react";

import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import {
  Cancel,
  Check,
  Create,
  Delete,
  DeleteForever,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import ConfirmacionDialog from "../../../components/ConfirmacionDialog";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const { setitemPadre, itemPadre, itemHijo, index, ver, devolucion } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } `;
  const [editar, seteditar] = useState(false);
  const [itemHijoEdit, setitemHijoEdit] = useState(itemHijo);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  //onChange
  // const handleChange = (e) => {
  //   setitemHijoEdit({ ...itemHijo, [e.target.name]: e.target.value });
  // };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (itemHijo.estado.trim() === "" || itemHijo.cantidad === "") {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      setitemPadre({
        ...itemPadre,
        cantidades: itemPadre.cantidades.map((item, indexItem) => {
          if (indexItem === index) {
            return trimPropsItem(itemHijoEdit);
          } else {
            return item;
          }
        }),
      });

      seteditar(false);
    } catch (error) {}
  };
  const funcEliminar = () => {
    try {
      setitemPadre({
        ...itemPadre,
        cantidades: itemPadre.cantidades.filter(
          (item, indexItem) => indexItem !== index
        ),
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const botones = [
    {
      tooltip: "Eliminar",
      texto: "",
      funcion: () => {
        setopenConfirmDialog(true);
      },
      disabled: ver,
      Icon: Delete,
      color: "error",
      id: 1,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        seteditar(true);
        setitemHijoEdit(itemHijo);
      },
      disabled: ver,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setitemHijoEdit(itemHijo);
        // setItemEdit(itemHijo);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: { ...butonIconTransition, padding: "0" },
    },
    {
      tooltip: "Guardar",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  return (
    <TableRow
      component={"form"}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              funcEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ButtonsAction
        claseCelda={claseCelda}
        botones={editar ? botonesEditar : botones}
      />
      {editar ? (
        <>
          {" "}
          {devolucion && (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                <TextField
                  autoFocus={devolucion}
                  type="number"
                  value={itemHijoEdit.cantidadDevolucion}
                  name="cantidadDevolucion"
                  onChange={(e) => {
                    const value = Math.max(
                      Math.min(itemHijo.cantidadAnterior, e.target.value),
                      0
                    );
                    setitemHijoEdit((prev) => ({
                      ...prev,
                      cantidadDevolucion: value,
                      cantidadRestante: prev.cantidadAnterior - value,
                    }));
                  }}
                  size="small"
                  fullWidth
                />
              </TableCell>
            </>
          )}
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijoEdit.estado}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijoEdit.max}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              autoFocus={!devolucion}
              type="number"
              value={
                devolucion
                  ? itemHijoEdit.cantidadRestante
                  : itemHijoEdit.cantidad
              }
              disabled={devolucion}
              name="cantidad"
              onChange={(e) =>
                setitemHijoEdit((prev) => ({
                  ...prev,
                  cantidad: Math.max(Math.min(itemHijo.max, e.target.value), 0),
                }))
              }
              size="small"
              fullWidth
            />
          </TableCell>
          {devolucion && (
            <TableCell size="small" align="center" className={claseCelda}>
              <TextField
                disabled
                type="number"
                value={itemHijoEdit.cantidadAnterior}
                name="cantidadAnterior"
                size="small"
                fullWidth
              />
            </TableCell>
          )}
        </>
      ) : (
        <>
          {devolucion && (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                {itemHijo.cantidadDevolucion}
              </TableCell>
            </>
          )}
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.estado}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {itemHijo.max}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {devolucion ? itemHijo.cantidadRestante : itemHijo.cantidad}
          </TableCell>
          {devolucion && (
            <TableCell size="small" align="center" className={claseCelda}>
              {itemHijo.cantidadAnterior}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
};

export default withRouter(memo(Row));
