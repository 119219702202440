export const columns = [
  {
    id: "actDocCliente",
    label: "Cliente",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actDocCliente",
    align: "left",
    ocultar: true,
  },
];
export const columnsHijos = [
  {
    id: "actFotosActividad",
    label: "Fotos",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "actFotosActividad",
    align: "center",
    ocultar: true,
  },
  {
    id: "actEveTipo",
    label: "TIPO EVENTO",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "actEveTipo",
    align: "center",
    ocultar: true,
  },
  {
    id: "nombreEvento",
    label: "Nombre",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "",
    align: "center",
    ocultar: true,
  },

  {
    id: "actUser",
    label: "FECHAS",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "actUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "actUser",
    label: "GUARDIA",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "actUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "actUser",
    label: "SUPERVISOR",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "actUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "actUser",
    label: "ADMINISTRACION",
    minWidth: 25,
    tipo: "",
    orden: 1,
    propiedad: "actUser",
    align: "center",
    ocultar: true,
  },
  {
    id: "actObservacion",
    label: "TAREA",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actObservacion",
    align: "center",
    ocultar: true,
  },
  {
    id: "actPrioridad",
    label: "PRIORIDAD",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actPrioridad",
    align: "center",
    ocultar: true,
  },

  {
    id: "actUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "actUser",
    align: "center",
    ocultar: true,
  },
];
export const objectDefault = {
  actId: 2,
  actEveId: 0,
  actEveTipo: "",
  actEveDatos: {
    Todos: "",
    eveActividades: [],
    eveEmpresa: "",
    eveNombre: "",
    eveTipo: "",
    eveUser: "",
    eveCliId: "",
    eveCliDocumento: "",
    eveCliNombre: "",
    eveCliUbicacion: "",
    eveCliPuesto: "",
    evePerfil: "",
  },
  actEstado: "ACTIVA",
  actGuardias: {},
  actSupervisores: {},
  actAdministracion: {},
  actFechasActividad: [],
  actFechasEvento: {},
  actFechasActividadConsultaDB: [],
  actObservacion: "",
  actFecReg: "2022-08-23T18:01:57.000Z",
  actFecUpd: "2023-05-09T16:57:00.000Z",
  actIdCliente: "",
  actDocCliente: "",
  actNombreCliente: "",
  actDirigido: "",
  actFotosActividad: [],
  actLeidos: [],
  actPrioridad: "MEDIA",
  actDocumento: "",
  actUser: "admin",
  actEmpresa: "PRUEBA",
  actRequerimientosActividad: {
    qr: "SI",
    foto: "SI",
    video: "NO",
    texto: "SI",
  },
};
