import React, { memo, useContext } from "react";

import { TableCell, TableRow, Typography } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../components/ButtonsAction";
import AuthContext from "../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  Email,
  PictureAsPdf,
  QrCode,
  Restore,
  Visibility,
  WatchLater,
  RestorePage,
  CopyAll,
  Menu,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../config/const";
import clienteAxios from "../../../../../config/axios";
import ModalQR from "./ModalQR";
import ModalEmail from "./ModalEmail";
import CeldaUser from "../../../../components/CeldaUser";
import ModalDevolucion from "./ModalDevolucion";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    setver,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // ESTADO
    estadoRequest,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
    // FROM OTHER SITE,
    fromIOP,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE DEVOLUCIONES
  const [openDialogDev, setopenDialogDev] = useState(false);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // DIALOG FECHAS
  const [TextExpandible, setTextExpandible] = useState({});
  const [openModalEmail, setopenModalEmail] = useState(false);
  // QR
  const [itemQr, setitemQr] = useState({
    invld: "",
    rucempresa: "",
    hoja: "A4",
    cantidad: "1",
    ancho: "95",
    alto: "95",
  });
  const [openQr, setopenQr] = useState(false);
  // BOTONES

  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        distri_User: usuario.usuario,
        //disEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        distri_FecReg: rowHijo.distri_FecReg,
        distri_DocuCliente: rowHijo.distri_DocuCliente,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          distri_User: usuario.usuario + "/+/" + rowHijo[campoExport],
          //disEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  const [expandButtons, setexpandButtons] = useState(false);
  return (
    <React.Fragment>
      <ModalEmail
        open={openModalEmail}
        setopen={setopenModalEmail}
        row={rowHijo}
        estadoRequest={estadoRequest}
      />
      <ModalQR
        item={itemQr}
        setitem={setitemQr}
        open={openQr}
        setopen={setopenQr}
      />
      {openDialogDev && (
        <ModalDevolucion
          open={openDialogDev}
          setopen={setopenDialogDev}
          item={rowHijo}
          socket={socket}
        />
      )}

      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: expandButtons ? "Ocultar acciones" : "Ver acciones",
              texto: "",
              funcion: () => {
                setexpandButtons(!expandButtons);
              },
              disabled: false,
              Icon: Menu,
              color: "primary",
              id: 1,
              ocultar: rowHijo.eliminado || fromIOP,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              // disabled: usuario.usuario !== rowHijo.distri_User.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !rowHijo.eliminado || !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: rowHijo.eliminado || fromIOP || !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado || !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Copiar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                setopen(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: CopyAll,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado || !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                // window.open(
                //   `${clienteAxios.defaults.baseURL}reportes/${
                //     estadoRequest === "DISTRIBUCION"
                //       ? "dotaciondescuento"
                //       : estadoRequest === "PEDIDO"
                //       ? "pedido"
                //       : estadoRequest === "DEVOLUCION"
                //       ? "devolucion"
                //       : ""
                //   }?distri_Id=${rowHijo.distri_Id}&rucempresa=${
                //     rowHijo.disEmpresa
                //   }&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                // );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 2,
              ocultar: rowHijo.eliminado || !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver",
              texto: "",
              funcion: () => {
                changeObjetoActivoState({
                  ...rowHijo,
                });
                setopen(true);
                seteditar(true);
                setver(true);
              },
              disabled: false,
              Icon: Visibility,
              color: "secondary",
              id: 2,
              ocultar: rowHijo.eliminado || !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Correo",
              texto: "",
              funcion: () => {
                setopenModalEmail(true);
              },
              disabled: false,
              Icon: Email,
              color: "secondary",
              id: 2,
              ocultar:
                rowHijo.eliminado ||
                !(
                  estadoRequest === "DISTRIBUCION" || estadoRequest === "PEDIDO"
                ) ||
                !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setitemQr({
                  ...itemQr,
                  distri_Id: rowHijo.distri_Id,
                  // rucempresa: rowHijo.//disEmpresa,
                });
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              ocultar:
                estadoRequest !== "DISTRIBUCION" ||
                rowHijo.eliminado ||
                !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Realizar Devolución",
              texto: "",
              funcion: () => {
                // disArmas
                // disMuniciones
                const noSePuedeHacerDevoluciondisVestimenta = !rowHijo.disVestimenta.some(
                  (vestimentaItem) =>
                    vestimentaItem.cantidades.some(
                      (cantidad) => cantidad.cantidadRestante !== 0
                    )
                );

                const noSePuedeHacerDevoluciondisArmas = !rowHijo.disArmas.some(
                  (vestimentaItem) =>
                    vestimentaItem.cantidades.some(
                      (cantidad) => cantidad.cantidadRestante !== 0
                    )
                );
                const noSePuedeHacerDevoluciondisMuniciones = !rowHijo.disMuniciones.some(
                  (vestimentaItem) =>
                    vestimentaItem.cantidades.some(
                      (cantidad) => cantidad.cantidadRestante !== 0
                    )
                );
                if (
                  noSePuedeHacerDevoluciondisVestimenta &&
                  noSePuedeHacerDevoluciondisArmas &&
                  noSePuedeHacerDevoluciondisMuniciones
                ) {
                  return mostrarAlerta(
                    "No hay articulos por devolver",
                    "error"
                  );
                }

                changeObjetoActivoState({
                  ...rowHijo,
                  distri_Tipo: "DEVOLUCION",
                  distri_EstadoDevolucion: true,
                  disVestimenta: rowHijo.disVestimenta.map((itemTabla) => ({
                    ...itemTabla,
                    cantidades: itemTabla.cantidades.map((cantidad) => ({
                      ...cantidad,
                      cantidadRestante:
                        cantidad.cantidadRestante ||
                        cantidad.cantidadRestante === 0
                          ? cantidad.cantidadRestante
                          : cantidad.cantidad,
                      cantidadAnterior:
                        cantidad.cantidadAnterior ||
                        cantidad.cantidadAnterior === 0
                          ? cantidad.cantidadAnterior
                          : cantidad.cantidad,
                      cantidadDevolucion:
                        cantidad.cantidadDevolucion ||
                        cantidad.cantidadDevolucion === 0
                          ? cantidad.cantidadDevolucion
                          : 0,
                    })),
                  })),
                  disArmas: rowHijo.disArmas.map((itemTabla) => ({
                    ...itemTabla,
                    cantidades: itemTabla.cantidades.map((cantidad) => ({
                      ...cantidad,
                      cantidadRestante:
                        cantidad.cantidadRestante ||
                        cantidad.cantidadRestante === 0
                          ? cantidad.cantidadRestante
                          : cantidad.cantidad,
                      cantidadAnterior:
                        cantidad.cantidadAnterior ||
                        cantidad.cantidadAnterior === 0
                          ? cantidad.cantidadAnterior
                          : cantidad.cantidad,
                      cantidadDevolucion:
                        cantidad.cantidadDevolucion ||
                        cantidad.cantidadDevolucion === 0
                          ? cantidad.cantidadDevolucion
                          : 0,
                    })),
                  })),
                  disMuniciones: rowHijo.disMuniciones.map((itemTabla) => ({
                    ...itemTabla,
                    cantidades: itemTabla.cantidades.map((cantidad) => ({
                      ...cantidad,
                      cantidadRestante:
                        cantidad.cantidadRestante ||
                        cantidad.cantidadRestante === 0
                          ? cantidad.cantidadRestante
                          : cantidad.cantidad,
                      cantidadAnterior:
                        cantidad.cantidadAnterior ||
                        cantidad.cantidadAnterior === 0
                          ? cantidad.cantidadAnterior
                          : cantidad.cantidad,
                      cantidadDevolucion:
                        cantidad.cantidadDevolucion ||
                        cantidad.cantidadDevolucion === 0
                          ? cantidad.cantidadDevolucion
                          : 0,
                    })),
                  })),
                });
                setopen(true);
              },
              disabled: rowHijo.distri_Entrega !== "DOTACIÓN",
              Icon: RestorePage,
              color: "secondary",
              ocultar:
                estadoRequest !== "DISTRIBUCION" ||
                rowHijo.eliminado ||
                !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Realizar Devolución",
              texto: "",
              funcion: () => {
                const noSePuedeHacerDevoluciondisVestimenta = !rowHijo.disVestimenta.some(
                  (vestimentaItem) =>
                    vestimentaItem.cantidades.some(
                      (cantidad) => cantidad.cantidadRestante !== 0
                    )
                );

                const noSePuedeHacerDevoluciondisArmas = !rowHijo.disArmas.some(
                  (vestimentaItem) =>
                    vestimentaItem.cantidades.some(
                      (cantidad) => cantidad.cantidadRestante !== 0
                    )
                );
                const noSePuedeHacerDevoluciondisMuniciones = !rowHijo.disMuniciones.some(
                  (vestimentaItem) =>
                    vestimentaItem.cantidades.some(
                      (cantidad) => cantidad.cantidadRestante !== 0
                    )
                );
                if (
                  noSePuedeHacerDevoluciondisVestimenta &&
                  noSePuedeHacerDevoluciondisArmas &&
                  noSePuedeHacerDevoluciondisMuniciones
                ) {
                  return mostrarAlerta(
                    "No hay articulos por devolver",
                    "error"
                  );
                }
                changeObjetoActivoState({
                  ...rowHijo,
                  distri_Tipo: "DEVOLUCION",
                  distri_EstadoDevolucion: true,
                  disVestimenta: rowHijo.disVestimenta.map((itemTabla) => ({
                    ...itemTabla,
                    cantidades: itemTabla.cantidades.map((cantidad) => ({
                      ...cantidad,
                      cantidadRestante:
                        cantidad.cantidadRestante ||
                        cantidad.cantidadRestante === 0
                          ? cantidad.cantidadRestante
                          : cantidad.cantidad,
                      cantidadAnterior:
                        cantidad.cantidadAnterior ||
                        cantidad.cantidadAnterior === 0
                          ? cantidad.cantidadAnterior
                          : cantidad.cantidad,
                      cantidadDevolucion:
                        cantidad.cantidadDevolucion ||
                        cantidad.cantidadDevolucion === 0
                          ? cantidad.cantidadDevolucion
                          : 0,
                    })),
                  })),
                  disArmas: rowHijo.disArmas.map((itemTabla) => ({
                    ...itemTabla,
                    cantidades: itemTabla.cantidades.map((cantidad) => ({
                      ...cantidad,
                      cantidadRestante:
                        cantidad.cantidadRestante ||
                        cantidad.cantidadRestante === 0
                          ? cantidad.cantidadRestante
                          : cantidad.cantidad,
                      cantidadAnterior:
                        cantidad.cantidadAnterior ||
                        cantidad.cantidadAnterior === 0
                          ? cantidad.cantidadAnterior
                          : cantidad.cantidad,
                      cantidadDevolucion:
                        cantidad.cantidadDevolucion ||
                        cantidad.cantidadDevolucion === 0
                          ? cantidad.cantidadDevolucion
                          : 0,
                    })),
                  })),
                  disMuniciones: rowHijo.disMuniciones.map((itemTabla) => ({
                    ...itemTabla,
                    cantidades: itemTabla.cantidades.map((cantidad) => ({
                      ...cantidad,
                      cantidadRestante:
                        cantidad.cantidadRestante ||
                        cantidad.cantidadRestante === 0
                          ? cantidad.cantidadRestante
                          : cantidad.cantidad,
                      cantidadAnterior:
                        cantidad.cantidadAnterior ||
                        cantidad.cantidadAnterior === 0
                          ? cantidad.cantidadAnterior
                          : cantidad.cantidad,
                      cantidadDevolucion:
                        cantidad.cantidadDevolucion ||
                        cantidad.cantidadDevolucion === 0
                          ? cantidad.cantidadDevolucion
                          : 0,
                    })),
                  })),
                });
                setopen(true);
              },
              disabled: !(
                rowHijo.distri_Entrega === "DOTACIÓN" &&
                rowHijo.disEstado === "PENDIENTE"
              ),
              Icon: RestorePage,
              color: "secondary",
              ocultar:
                estadoRequest !== "PEDIDO" ||
                rowHijo.eliminado ||
                !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Ver Devolución",
              // texto: rowHijo.disIdDevoluciones.length,
              funcion: () => {
                setopenDialogDev(true);
              },
              // disabled: rowHijo.disIdDevoluciones.length === 0,
              Icon: WatchLater,
              color: "secondary",
              ocultar: rowHijo.eliminado || !expandButtons,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />
        {estadoRequest === "DISTRIBUCION" ? (
          <>
            {/* <TableCell
              size="small"
              align="center"
              className={claseCelda}
              onDoubleClick={() => {
                setTextExpandible({
                  ...TextExpandible,
                  distri_Personas: !TextExpandible["distri_Personas"],
                });
              }}
              sx={{ cursor: "pointer" }}
            >
              {rowHijo.distri_TipoDotacion === "PUESTO"
                ? rowHijo.distri_PuestoCliente
                : TextExpandible["distri_Personas"]
                ? [...rowHijo.distri_Personas, ...rowHijo.disSupervisores].map(
                    (itemPersona, index) => {
                      if (itemPersona.docnumero) {
                        return (
                          <Typography
                            color="initial"
                            sx={{
                              lineHeight: 1,
                              fontSize: ".7rem",
                              marginBottom: ".5rem",
                            }}
                          >
                            {index + 1 + " - "}
                            {itemPersona.nombres + " " + itemPersona.docnumero}
                          </Typography>
                        );
                      } else {
                        return (
                          <Typography
                            color="initial"
                            sx={{
                              lineHeight: 1,
                              fontSize: ".7rem",
                              marginBottom: ".5rem",
                            }}
                          >
                            {index + 1 + " - "}
                            {itemPersona.perDocNumero +
                              " " +
                              itemPersona.perNombres +
                              " " +
                              itemPersona.perApellidos}
                          </Typography>
                        );
                      }
                    }
                  )
                : `${[...rowHijo.distri_Personas, ...rowHijo.disSupervisores]
                    .map((itemPersona) => {
                      if (itemPersona.docnumero) {
                        return (
                          itemPersona.nombres + " " + itemPersona.docnumero
                        );
                      } else {
                        return (
                          itemPersona.perApellidos +
                          " " +
                          itemPersona.perNombres +
                          " " +
                          itemPersona.perDocNumero
                        );
                      }
                    })
                    .join(" - ")
                    .substring(0, 30)}...`}
            </TableCell> */}
            <TableCell size="small" align="center" className={claseCelda}>
              {rowHijo.distri_Entrega}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {rowHijo.distri_TipoDotacion}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(rowHijo.distri_FecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>

            {/* <CeldaUser user={rowHijo.distri_User} claseCelda={claseCelda} /> */}
          </>
        ) : null}
        {estadoRequest === "PEDIDO" ? (
          <>
            {/* <TableCell
              size="small"
              align="center"
              className={claseCelda}
              onDoubleClick={() => {
                setTextExpandible({
                  ...TextExpandible,
                  distri_Personas: !TextExpandible["distri_Personas"],
                });
              }}
              sx={{ cursor: "pointer" }}
            >
              {rowHijo.distri_TipoDotacion === "PUESTO"
                ? rowHijo.distri_PuestoCliente
                : TextExpandible["distri_Personas"]
                ? rowHijo.distri_Personas.map((itemPersona, index) => {
                    if (itemPersona.docnumero) {
                      return (
                        <Typography
                          color="initial"
                          sx={{
                            lineHeight: 1,
                            fontSize: ".7rem",
                            marginBottom: ".5rem",
                          }}
                        >
                          {index + 1 + " - "}
                          {itemPersona.nombres + " " + itemPersona.docnumero}
                        </Typography>
                      );
                    } else {
                      return (
                        <Typography
                          color="initial"
                          sx={{
                            lineHeight: 1,
                            fontSize: ".7rem",
                            marginBottom: ".5rem",
                          }}
                        >
                          {index + 1 + " - "}
                          {itemPersona.perDocNumero +
                            " " +
                            itemPersona.perNombres +
                            " " +
                            itemPersona.perApellidos}
                        </Typography>
                      );
                    }
                  })
                : rowHijo.distri_Personas
                    .map((itemPersona) => {
                      if (itemPersona.docnumero) {
                        return (
                          itemPersona.nombres + " " + itemPersona.docnumero
                        );
                      } else {
                        return (
                          itemPersona.perApellidos +
                          " " +
                          itemPersona.perNombres +
                          " " +
                          itemPersona.perDocNumero
                        );
                      }
                    })
                    .join(" - ")
                    .substring(0, 30)}
            </TableCell> */}
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(rowHijo.distri_FecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            <TableCell
              size="small"
              align="center"
              className={claseCelda}
              onClick={() => {
                seteditar(true);
                setopen(true);
                changeObjetoActivoState({
                  ...rowHijo,
                });
              }}
              style={{
                color:
                  rowHijo.disEstado === "PENDIENTE"
                    ? "orange"
                    : rowHijo.disEstado === "ENVIADO"
                    ? "blue"
                    : rowHijo.disEstado === "RECIBIDO"
                    ? "green"
                    : rowHijo.disEstado === "ANULADO"
                    ? "red"
                    : "green",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {rowHijo.disEstado}
            </TableCell>
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(rowHijo.disFechaEnvio).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            {/* <CeldaUser user={rowHijo.distri_User} claseCelda={claseCelda} /> */}
          </>
        ) : null}
        {estadoRequest === "DEVOLUCION" ? (
          <>
            {/* <TableCell
              size="small"
              align="center"
              className={claseCelda}
              onDoubleClick={() => {
                setTextExpandible({
                  ...TextExpandible,
                  distri_Personas: !TextExpandible["distri_Personas"],
                });
              }}
            >
              {rowHijo.distri_TipoDotacion === "PUESTO"
                ? rowHijo.distri_PuestoCliente
                : TextExpandible["distri_Personas"]
                ? [...rowHijo.distri_Personas, ...rowHijo.disSupervisores].map(
                    (itemPersona, index) => {
                      if (itemPersona.docnumero) {
                        return (
                          <Typography
                            color="initial"
                            sx={{
                              lineHeight: 1,
                              fontSize: ".7rem",
                              marginBottom: ".5rem",
                            }}
                          >
                            {index + 1 + " - "}
                            {itemPersona.nombres + " " + itemPersona.docnumero}
                          </Typography>
                        );
                      } else {
                        return (
                          <Typography
                            color="initial"
                            sx={{
                              lineHeight: 1,
                              fontSize: ".7rem",
                              marginBottom: ".5rem",
                            }}
                          >
                            {index + 1 + " - "}
                            {itemPersona.perDocNumero +
                              " " +
                              itemPersona.perNombres +
                              " " +
                              itemPersona.perApellidos}
                          </Typography>
                        );
                      }
                    }
                  )
                : `${[...rowHijo.distri_Personas, ...rowHijo.disSupervisores]
                    .map((itemPersona) => {
                      if (itemPersona.docnumero) {
                        return (
                          itemPersona.nombres + " " + itemPersona.docnumero
                        );
                      } else {
                        return (
                          itemPersona.perApellidos +
                          " " +
                          itemPersona.perNombres +
                          " " +
                          itemPersona.perDocNumero
                        );
                      }
                    })
                    .join(" - ")
                    .substring(0, 30)}...`}
            </TableCell> */}
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(rowHijo.distri_FecReg).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            <TableCell
              size="small"
              align="center"
              className={claseCelda}
              onClick={() => {
                setopen(true);
              }}
              style={{
                color:
                  rowHijo.disEstado === "PENDIENTE"
                    ? "orange"
                    : rowHijo.disEstado === "ENVIADO"
                    ? "blue"
                    : rowHijo.disEstado === "RECIBIDO"
                    ? "green"
                    : rowHijo.disEstado === "ANULADO"
                    ? "red"
                    : "green",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {rowHijo.disEstado}
            </TableCell>{" "}
            <TableCell size="small" align="center" className={claseCelda}>
              {new Date(rowHijo.disFechaEnvio).toLocaleString("es-ES", {
                weekday: "short",
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour: "2-digit",
              })}
            </TableCell>
            {/* <CeldaUser user={rowHijo.distri_User} claseCelda={claseCelda} /> */}
          </>
        ) : null}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
