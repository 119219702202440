import React, { memo, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";
 
import ButtonsAction from "./ButtonsAction";

// MATERIAL
import { makeStyles } from "@mui/styles";
import { TableCell, TableRow, TextField } from "@mui/material";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const { row, index, campoExport, item, setitem, deshabilitarbotones } = props;
  const [itemRow, setitemRow] = useState(row);
  useEffect(() => {
    setitemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      cliArmasAsignadas: item.cliArmasAsignadas.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setitemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (
      itemRow.nomBodega === "" ||
      itemRow.stock === "" ||
      itemRow.maximo === "" ||
      itemRow.minimo === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      cliArmasAsignadas: item.cliArmasAsignadas.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemRow;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo="¿Esta seguro que quiere eliminar esta Bodega?"
        _id={itemRow._id}
        funcion={handleEliminar}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={setitem}
          index={index}
          itemRow={itemRow}
          row={itemRow}
          item={item}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.nombre}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  nombre: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Agregando"
            />
          ) : (
            row.nombre
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.serie}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  serie: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Agregando"
            />
          ) : (
            row.serie
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.marca}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  marca: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Agregando"
            />
          ) : (
            row.marca
          )}
        </TableCell>
        <TableCell
          padding="none"
          size="small"
          align="center"
          className={classes.celda}
        >
          {editar ? (
            <TextField
              id=""
              value={itemRow.numpermiso}
              onChange={(e) =>
                setitemRow({
                  ...itemRow,
                  numpermiso: e.target.value,
                })
              }
              size="small"
              fullWidth
              helperText="Agregando"
            />
          ) : (
            row.numpermiso
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
