import React, { memo, useState, useContext, useEffect } from "react";

import { Box } from "@mui/material";

import BarraTablas from "../../components/BarraTablas/BarraTablas";

import Tabla from "../../components/Tablas/Permisos/Tabla";
import Modal from "../../components/Tablas/Permisos/Modal";
import MenuContext from "../../Context/Menu/MenuContext";

const Categorias = () => {
  const [open, setopen] = useState(false);
  const [buscando, setbuscando] = useState(false);
  const [buscados, setbuscados] = useState([]);
  const [filtro, setfiltro] = useState("");
  const { rows } = useContext(MenuContext);
  useEffect(() => {
    if (rows && buscando) {
      const _ids = buscados.map((item) => item._id);

      const res = rows.filter((item) => {
        return _ids.indexOf(item._id) >= 0;
      });
      setbuscados(res);
    } // eslint-disable-next-line
  }, [rows]);
  const handleSearch = () => {
    setbuscados([
      ...rows.filter((row) => {
        return row.nombre.toLowerCase().includes(filtro.toLowerCase());
      }),
    ]);
  };
  return (
    <Box>
      <BarraTablas
        ocultarAgregar={true}
        filtro={filtro}
        setfiltro={setfiltro}
        funcion={handleSearch}
        buscando={buscando}
        setbuscando={setbuscando}
        setopen={setopen}
        open={open}
      />
      <Tabla
        setopen={setopen}
        rows={rows}
        buscando={buscando}
        buscados={buscados}
      />
      <Modal setopen={setopen} open={open} />
    </Box>
  );
};

export default memo(Categorias);
