import React, { memo, useContext, useEffect } from "react";

import { useState } from "react";

import AuthContext from "../../../../../../Context/Auth/AuthContext";

// MATERIAL
import {
  Box,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Checkbox,
  FormLabel,
  Badge,
  Typography,
  Zoom,
} from "@mui/material";
import {
  AddModerator,
  Cancel,
  Check,
  Clear,
  Create,
  Delete,
  DeleteForever,
  FilterList,
  Map,
  Person,
  Timelapse,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import { usePermiso } from "../../../../../hooks/usePermiso";
import ButtonsAction from "../../../../../components/ButtonsAction";
import AlertaContext from "../../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../../config/axios";
import Modal from "../../../../../Permisos/Componentes/Modal";
import ModalTurnoExtra from "../../../../../TurnosExtras/Componentes/Modal";
import { objectDefault as objectDefaultPermiso } from "../../../../../Permisos/data";
import { objectDefault as objectDefaultTurnoExtra } from "../../../../../TurnosExtras/data";
import ConfirmacionDialog from "../../../../../components/ConfirmacionDialog";
import { FechaExacta } from "../../../../../../config/const";
import Botones from "../../../../../components/Botones";
const RowAgregar = (props) => {
  const classes = useRowStyles();
  const {
    itemPersona,
    itemPadre,
    itemPadrePadre,
    index,
    socket,
    guardiasTotales,
    setpersonasData,
    horario,
    setdiasSeleccionados,
    diasSeleccionados,
    diasSelect,
    mes,
    anio,
    getHorarioActivo,
    horarioActivo,
    minMax,
    alterParams,
    estadoPersonas,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  }`;
  const { usuario } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [openConfirmDialogFF, setopenConfirmDialogFF] = useState(false);
  const [openConfirmDialogNOFF, setopenConfirmDialogNOFF] = useState(false);
  const [itemEdit, setItemEdit] = useState({ ...itemPersona });
  const [editar, seteditar] = useState(false);
  const { tienePermiso, alertaPermiso } = usePermiso("DatosOperativos");
  // const [openMap, setopenMap] = useState(false);

  //*EXPANDIBLE

  const botonDisabled = () => {
    if (
      itemEdit.perNombre === ""

      // itemEdit.tipoinstalacion === "" ||
      // itemEdit.vulnerabilidades === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const botones = [
    {
      tooltip: "Editar",
      texto: "",
      funcion: () => {
        if (!tienePermiso("editar")) {
          return alertaPermiso("editar");
        }

        seteditar(true);
      },
      disabled: false,
      Icon: Create,
      color: "secondary",
      id: 2,
      ocultar: true,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Parametrizar Dias",
      texto: "",
      funcion: () => {
        desparametrizar();
        if (alterParams) {
          const diasEnElMes = new Date(anio, mes, 0).getDate();
          const diasSeleccionados = diasSelect.map((dia) => dia.toUpperCase());
          const idsDeDiasSeleccionados = {};
          for (let i = 1; i <= diasEnElMes; i++) {
            const fecha = new Date(anio, mes - 1, i);
            if (
              minMax.min <= fecha.getDate() &&
              minMax.max >= fecha.getDate()
            ) {
              const objeto = itemPersona.horarios.find((o) => {
                if (
                  o.id === 34 &&
                  new Date(
                    Date.parse(o.fecha_inicio) -
                      -new Date().getTimezoneOffset() * 60 * 1000
                  ).getDate() === i
                ) {
                }
                if (
                  o.id === 35 &&
                  new Date(
                    Date.parse(o.fecha_inicio) -
                      -new Date().getTimezoneOffset() * 60 * 1000
                  ).getDate() === i
                ) {
                }
                return (
                  new Date(
                    Date.parse(o.fecha_inicio) -
                      -new Date().getTimezoneOffset() * 60 * 1000
                  ).getDate() === i
                );
              });
              if (objeto) {
                idsDeDiasSeleccionados[objeto.id] = objeto.id;
              }
            }
          }
          setdiasSeleccionados((prev) => ({
            ...prev,
            ...idsDeDiasSeleccionados,
          }));
        } else {
          parametrizar();
        }
      },
      disabled: false,
      Icon: FilterList,
      color: "secondary",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Desparametrizar",
      texto: "",
      funcion: () => {
        desparametrizar();
      },
      disabled: false,
      Icon: Clear,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
  ];
  const botonesEditar = [
    {
      tooltip: "Cancelar",
      texto: "",
      funcion: () => {
        seteditar(false);
        setItemEdit(itemPersona);
      },
      disabled: false,
      Icon: Cancel,
      color: "error",
      id: 1,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
    },
    {
      tooltip: "Guardar Edición",
      texto: "",
      funcion: () => {
        funcSubmit();
      },
      disabled: botonDisabled(),
      Icon: Check,
      color: "success",
      id: 2,
      ocultar: false,
      tipo: "icono",
      variante: "contained",
      size: "small",
      sx: butonIconTransition,
      submit: true,
    },
  ];
  const funcSubmit = () => {
    socket.current.emit("client:actualizarData", {
      ...itemPadre,
      tabla: "datosoperativos",
      datosoperativos: itemPadre.datosoperativos.map((item, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...itemEdit,
            color:
              !itemEdit.color || itemEdit.color === ""
                ? "#000000"
                : itemEdit.color,
          };
        } else {
          return {
            ...item,
            color:
              !item.color || itemEdit.color === "" ? "#000000" : item.color,
          };
        }
      }),
      rucempresa: usuario.rucempresa,
      rol: usuario.rol,
      planUser: usuario.usuario,
      planEmpresa: usuario.rucempresa,
      datosoperativosOld: itemPadre.datosoperativos,
    });

    seteditar(false);
  };
  const handleChange = (e) => {
    setItemEdit({
      ...itemEdit,
      [e.target.name]: e.target.value,
    });
  };

  const handleEliminar = async (_id) => {
    try {
      const res = await clienteAxios.post(
        `/dOperativo_persona/delete/${itemPersona.id}`,
        {
          id_dOperativo: itemPadre.id,
          id_persona: itemPersona.id_persona,
          month: mes,
          year: anio,
        }
      );
      setpersonasData((prev) =>
        prev.filter((itemF) => itemF.id !== itemPersona.id)
      );
      mostrarAlerta(res?.data?.msg, "success");
    } catch (error) {
      console.log({ error });
      mostrarAlerta("Hubo un error al eliminar el registro", "error");
    }
  };
  useEffect(() => {
    setItemEdit({ ...itemPersona });
    // eslint-disable-next-line
  }, [itemPadre]);
  function parametrizar() {
    const diasEnElMes = new Date(anio, mes, 0).getDate();
    const diasSeleccionados = diasSelect.map((dia) => dia.toUpperCase());
    const idsDeDiasSeleccionados = {};
    for (let i = 1; i <= diasEnElMes; i++) {
      const fecha = new Date(anio, mes - 1, i);
      const diaDeLaSemana = fecha
        .toLocaleDateString("es-ES", { weekday: "long" })
        .toUpperCase();
      if (diasSeleccionados.includes(diaDeLaSemana)) {
        const objeto = itemPersona.horarios.find((o) => {
          if (
            o.id === 34 &&
            new Date(
              Date.parse(o.fecha_inicio) -
                -new Date().getTimezoneOffset() * 60 * 1000
            ).getDate() === i
          ) {
          }
          if (
            o.id === 35 &&
            new Date(
              Date.parse(o.fecha_inicio) -
                -new Date().getTimezoneOffset() * 60 * 1000
            ).getDate() === i
          ) {
          }
          return (
            new Date(
              Date.parse(o.fecha_inicio) -
                -new Date().getTimezoneOffset() * 60 * 1000
            ).getDate() === i
          );
        });
        if (objeto) {
          idsDeDiasSeleccionados[objeto.id] = objeto.id;
        }
      }
    }
    setdiasSeleccionados((prev) => ({ ...prev, ...idsDeDiasSeleccionados }));
  }
  function desparametrizar() {
    setdiasSeleccionados((prev) => {
      itemPersona.horarios.forEach((o) => {
        delete prev[o.id];
      });
      return {
        ...prev,
      };
    });
  }
  const [ObjectoActivoPermiso, setObjectoActivoPermiso] = useState(
    objectDefaultPermiso
  );
  const [openPermiso, setopenPermiso] = useState(false);

  const [ObjectoActivoTurnoExtra, setObjectoActivoTurnoExtra] = useState(
    objectDefaultTurnoExtra
  );
  const [openTurnoExtra, setopenTurnoExtra] = useState(false);
  if (estadoPersonas !== itemPersona.perfil) {
    return null;
  }
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openConfirmDialogFF}
        setopen={setopenConfirmDialogFF}
        categoria="warning"
        titulo={`Algunos dias seleccionados no son seleccionables para permisos, ¿desea quitarlos de la selección?`}
        botones={[
          {
            tooltip: "Se filtraran los registros",
            texto: "SI",
            funcion: () => {
              const res = Object.fromEntries(
                Object.entries(diasSeleccionados).filter((item) => {
                  const pasa = itemPersona.horarios.find(
                    (horario) =>
                      horario.id === item[1] &&
                      horario.codigo !== "FF" &&
                      horario.estado !== "TURNO EXTRA" &&
                      horario.estado !== "PERMISO" &&
                      Date.parse(horario.fecha_inicio) >=
                        Date.parse(FechaExacta)
                  );
                  return pasa;
                })
              );

              setdiasSeleccionados(res);
              setopenConfirmDialogFF(false);
            },
            disabled: false,

            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialogFF(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <ConfirmacionDialog
        open={openConfirmDialogNOFF}
        setopen={setopenConfirmDialogNOFF}
        categoria="warning"
        titulo={`Algunos dias seleccionados no son seleccionables para turnos extra, ¿desea quitarlos de la selección?`}
        botones={[
          {
            tooltip: "Se filtraran los registros",
            texto: "SI",
            funcion: () => {
              const res = Object.fromEntries(
                Object.entries(diasSeleccionados).filter((item) => {
                  const pasa = itemPersona.horarios.find(
                    (horario) =>
                      horario.id === item[1] &&
                      horario.codigo === "FF" &&
                      horario.estado !== "TURNO EXTRA" &&
                      horario.estado !== "PERMISO" &&
                      Date.parse(horario.fecha_inicio) >=
                        Date.parse(FechaExacta)
                  );
                  return pasa;
                })
              );

              setdiasSeleccionados(res);
              setopenConfirmDialogNOFF(false);
            },
            disabled: false,

            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialogNOFF(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      {openPermiso && (
        <Modal
          editar={false}
          seteditar={() => {}}
          setopen={setopenPermiso}
          open={openPermiso}
          ObjectoActivo={ObjectoActivoPermiso}
          changeObjetoActivoState={setObjectoActivoPermiso}
          socket={socket}
        />
      )}
      {openTurnoExtra && (
        <ModalTurnoExtra
          editar={false}
          seteditar={() => {}}
          setopen={setopenTurnoExtra}
          open={openTurnoExtra}
          ObjectoActivo={ObjectoActivoTurnoExtra}
          changeObjetoActivoState={setObjectoActivoTurnoExtra}
          socket={socket}
        />
      )}
      <TableRow
        className={classes.root}
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <ButtonsAction
          claseCelda={claseCelda}
          row={itemPersona}
          botones={[...(editar ? botonesEditar : botones)]}
        />
        {/* las demas columnas */}
        {editar ? (
          <>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                name="codigo"
                value={itemEdit.codigo}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemEdit.ubicacion}
                name="ubicacion"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                disabled
                value={
                  itemEdit.coordenadas.latitud +
                  " " +
                  itemEdit.coordenadas.longitud
                }
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <Box htmlFor="contained-button-documento" fullWidth>
                      <InputAdornment>
                        <Tooltip title="Mostrar Mapa" arrow>
                          <Box>
                            <IconButton
                              aria-label=""
                              // onClick={() => setopenMap(true)}
                            >
                              <Map color="secondary" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </InputAdornment>
                    </Box>
                  ),
                }}
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                placeholder="Nombre"
                value={itemEdit.puesto}
                name="puesto"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                InputProps={{
                  inputProps: { min: 0 },
                }}
                type="number"
                value={itemEdit.supervisor}
                name="supervisor"
                onChange={(e) => {
                  setItemEdit({
                    ...itemEdit,
                    supervisor: Number(e.target.value) < 1 ? 1 : e.target.value,
                  });
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                InputProps={{
                  inputProps: { min: 0 },
                }}
                type="number"
                value={itemEdit.guardias}
                onChange={(e) => {
                  if (Number(e.target.value) < 1) {
                    setItemEdit({
                      ...itemEdit,
                      guardias: 1,
                    });
                  } else {
                    if (
                      Number(e.target.value) +
                        Number(guardiasTotales) -
                        Number(itemPersona.guardias) >
                      itemPadre.perGuardias
                    ) {
                      return mostrarAlerta(
                        `Su maximo de guardias es de ${itemPadre.perGuardias}`,
                        "error"
                      );
                    } else {
                      setItemEdit({
                        ...itemEdit,
                        guardias: e.target.value,
                      });
                    }
                  }
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemEdit.horasservicio}
                name="horasservicio"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
                type="number"
              />
            </TableCell>
            <TableCell fontSize="small" align="center" className={claseCelda}>
              <TextField
                value={itemEdit.tipoinstalacion}
                name="tipoinstalacion"
                onChange={(e) => {
                  handleChange(e);
                }}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell size="small" align="center" className={classes.celda}>
              <TextField
                type="color"
                name="color"
                value={itemEdit.color}
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
              {itemEdit.color}
            </TableCell>
          </>
        ) : (
          <>
            <TableCell fontSize="small" align="left" className={claseCelda}>
              <Tooltip
                arrow
                sx={{ padding: "0" }}
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({ background: theme.palette.primary.dark }),
                  },
                }}
                TransitionComponent={Zoom}
                title={
                  <Botones
                    botones={[
                      {
                        tooltip: "Eliminar",
                        texto: "",
                        funcion: () => {
                          if (!tienePermiso("eliminar")) {
                            return alertaPermiso("eliminar");
                          }

                          setopenConfirmDialog(true);
                        },
                        disabled: false,
                        Icon: Delete,
                        color: "error",
                        id: 1,
                        ocultar: false,
                        tipo: "icono",
                        variante: "contained",
                        size: "medium",
                        sx: butonIconTransition,
                      },
                      {
                        tooltip: "Permiso",
                        texto: "",
                        funcion: async () => {
                          try {
                            const dias = itemPersona.horarios.filter(
                              (horarioHijo) => diasSeleccionados[horarioHijo.id]
                            );

                            const hayDiaFF = dias.some((dia) => {
                              return (
                                dia.codigo === "FF" ||
                                dia.estado === "TURNO EXTRA" ||
                                dia.estado === "PERMISO" ||
                                Date.parse(dia.fecha_inicio) <
                                  Date.parse(FechaExacta)
                              );
                            });
                            if (hayDiaFF) {
                              return setopenConfirmDialogFF(true);
                            }
                            await clienteAxios.post(
                              "/horarios_jornada_laboral/dias-habiles-permiso",
                              {
                                dias: itemPersona.horarios
                                  .filter(
                                    (horarioHijo) =>
                                      diasSeleccionados[horarioHijo.id]
                                  )
                                  .map(({ id }) => id),
                              }
                            );

                            setObjectoActivoPermiso({
                              ...objectDefaultPermiso,
                              // permiId: 1,
                              permiIdPersona: itemPersona.id_persona,
                              permiIdDOperativo: itemPadre.id,
                              cedula: itemPersona.perDocNumero,
                              nombres: itemPersona.perNombre,
                              dias,
                              idCliente: itemPadrePadre.perId,
                              ruc: itemPadrePadre.doc,
                              razonSocial: itemPadrePadre.razonsocial,
                              ubicacion: itemPadre.ubicacion,
                              puesto: itemPadre.puesto,
                            });
                            setopenPermiso(true);
                          } catch (error) {
                            console.log({ error });
                            mostrarAlerta(error?.response?.data?.msg, "error");
                          }
                        },
                        disabled: false,
                        Icon: Person,
                        color: "secondary",
                        id: 1,
                        ocultar: false,
                        tipo: "icono",
                        variante: "contained",
                        size: "medium",
                        sx: butonIconTransition,
                      },
                      {
                        tooltip: "Generar Turno Extra",
                        texto: "",
                        funcion: async () => {
                          try {
                            if (!horario) {
                              return mostrarAlerta(
                                "Seleccione un horario",
                                "error"
                              );
                            }
                            const dias = itemPersona.horarios.filter(
                              (horarioHijo) => diasSeleccionados[horarioHijo.id]
                            );

                            const hayDiaNOFF = dias.some((dia) => {
                              return (
                                dia.codigo !== "FF" ||
                                dia.estado === "TURNO EXTRA" ||
                                dia.estado === "PERMISO" ||
                                Date.parse(dia.fecha_inicio) <
                                  Date.parse(FechaExacta)
                              );
                            });
                            if (hayDiaNOFF) {
                              return setopenConfirmDialogNOFF(true);
                            }
                            await clienteAxios.post(
                              "/horarios_jornada_laboral/dias-libres",
                              {
                                turnIdDOperativo: itemPadre.id_dOperativo,
                                turnIdPersona: itemPersona.id_persona,
                                dias: itemPersona.horarios
                                  .filter(
                                    (horarioHijo) =>
                                      diasSeleccionados[horarioHijo.id]
                                  )
                                  .map(({ fecha_inicio }) => fecha_inicio),
                              }
                            );

                            setObjectoActivoTurnoExtra({
                              ...objectDefaultTurnoExtra,
                              // permiId: 1,
                              turnIdPersona: itemPersona.id_persona,
                              turnIdDOperativo: itemPadre.id,
                              cedula: itemPersona.perDocNumero,
                              nombres: itemPersona.perNombre,
                              dias,
                              idCliente: itemPadrePadre.perId,
                              ruc: itemPadrePadre.doc,
                              razonSocial: itemPadrePadre.razonsocial,
                              ubicacion: itemPadre.ubicacion,
                              puesto: itemPadre.puesto,
                              turnIdHorario: horario,
                            });
                            setopenTurnoExtra(true);
                          } catch (error) {
                            console.log({ error });
                            mostrarAlerta(error?.response?.data?.msg, "error");
                          }
                        },
                        disabled: false,
                        Icon: Timelapse,
                        color: "secondary",
                        id: 1,
                        ocultar: false,
                        tipo: "icono",
                        variante: "contained",
                        size: "medium",
                        sx: butonIconTransition,
                      },
                    ]}
                  />
                }
              >
                <Typography
                  variant="body1"
                  color="initial"
                  component={"span"}
                  fontSize={".8rem"}
                >
                  {itemPersona.perDocNumero} - {itemPersona.perNombre}
                </Typography>
              </Tooltip>
            </TableCell>
            {itemPersona.horarios?.map(
              ({
                codigo,
                id,
                color,
                id_horario,
                estado,
                estatus_proceso,
                actividad_asignada,
              }) => (
                <TableCell
                  fontSize="small"
                  align="center"
                  padding="none"
                  className={claseCelda}
                  sx={{
                    padding: "0 !important",
                    margin: "0 !important",
                    background:
                      horarioActivo?.id === id_horario
                        ? horarioActivo?.color + " !important"
                        : "",
                  }}
                  onDoubleClick={() => getHorarioActivo(id_horario)}
                >
                  <Typography
                    variant="body1"
                    color={color}
                    fontSize={".8rem"}
                    fontWeight={"bold"}
                    textAlign={"center"}
                  >
                    {codigo}
                  </Typography>
                  <Badge
                    badgeContent={
                      estado === "TURNO EXTRA"
                        ? "T"
                        : estado === "PERMISO"
                        ? "P"
                        : null
                    }
                    color={
                      estatus_proceso === "PENDIENTE" ? "warning" : "success"
                    }
                    sx={{ padding: 0 }}
                    componentsProps={{
                      badge: {
                        style: {
                          fontWeight: "bold",
                          fontSize: ".8rem",
                          padding: 0,
                        },
                      },
                    }}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Badge
                      badgeContent={actividad_asignada === 1 ? "A" : null}
                      color={"success"}
                      // sx={{ ml: "25%", fontSize: ".6rem" }}
                      componentsProps={{
                        badge: {
                          style: {
                            padding: 0,
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                          },
                        },
                      }}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                      >
                        <Checkbox
                          sx={{ fontSize: ".2rem", padding: 0, margin: 0 }}
                          checked={Boolean(diasSeleccionados[id])}
                          onChange={(e) => {
                            setdiasSeleccionados((prev) => {
                              if (e.target.checked) {
                                return { ...prev, [id]: id };
                              } else {
                                delete prev[id];
                                return { ...prev };
                              }
                            });
                          }}
                        />
                      </Box>
                    </Badge>
                  </Badge>
                </TableCell>
              )
            )}
          </>
        )}
        {/* {editar ? (
          <>
            <TableCell
              fontSize="small"
              sx={{ paddingLeft: "5px" }}
               
              align="center"
              className={claseCelda}
            >
              <TextField
                value={itemEdit.nombre}
                name="nombre"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
    
          </>
        )} */}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregar);
