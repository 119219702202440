export const columns = [
  {
    id: "aviFotos",
    label: "Fotos",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "aviFotos",
    align: "center",
    ocultar: true,
  },
  {
    id: "aviDocumento",
    label: "Documento",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "aviDocumento",
    align: "center",
    ocultar: true,
  },
  {
    id: "aviAsunto",
    label: "Asunto",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "aviAsunto",
    align: "center",
    ocultar: true,
  },
  {
    id: "aviOpcion",
    label: "Opción",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "aviOpcion",
    align: "center",
    ocultar: true,
  },
  {
    id: "aviDetalle",
    label: "Detalle",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "aviDetalle",
    align: "center",
    ocultar: true,
  },
  {
    id: "aviFecReg",
    label: "Fec. Reg",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "aviFecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "aviUser",
    label: "User",
    minWidth: 25,
    tipo: "string",
    orden: 1,
    propiedad: "aviUser",
    align: "center",
    ocultar: true,
  },
];

export const objectDefault = {
  //   aviId: 1,
  aviDirigido: [],
  aviLeidos: [],
  aviAsunto: "", // INPUT
  aviFotos: [],
  aviFechasAviso: [],
  aviFechasAvisoConsultaDB: [],
  aviTipoCalendario: "MULTIPLE",
  aviDocumento: "",
  aviOpcion: "", // SELECT => CLIENTE, GUARDIAS,SUPERVISORES
  aviDetalle: "", //TEXTAREA
  aviUser: "",
  aviFecReg: "",
  aviEmpresa: "",
  Todos: "",
};
