import clienteAxios from "./axios";

const tokenAuth = (token) => {
  if (token) {
    console.log(token);

    clienteAxios.defaults.headers.common["x-auth-token"] = token;
  } else {
    console.log(token);
    delete clienteAxios.defaults.headers.common["x-auth-token"];
  }
};

export default tokenAuth;
