import React, { memo, useContext } from "react";

import {
  Autocomplete,
  FormControl,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../../components/ButtonsAction";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../../styles/stylesRows";
import { trimPropsItem } from "../../../../config/const";
import { objectDefault } from "../data";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const { setopen, socket, supervisoresData } = props;
  const claseCelda = `${classes.celda}`;

  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // COPIA DEL REGISTRO PARA EDITAR
  const [item, setitem] = useState(objectDefault);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.bodeNombre.trim() === "" ||
      item.bodeDireccion.trim() === "" ||
      item.bodeCelular.trim() === "" ||
      item.bodeEmail.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      console.log(
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "bodegas",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          bodeUser: usuario.usuario,
          bodeEmpresa: usuario.rucempresa,
        })
      );
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "bodegas",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          bodeUser: usuario.usuario,
          bodeEmpresa: usuario.rucempresa,
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          sx={{ paddingLeft: "5px" }}
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled(),
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={item.bodeNombre}
              name="bodeNombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={item.bodeDireccion}
              name="bodeDireccion"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={item.bodeCelular}
              name="bodeCelular"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <TextField
              value={item.bodeEmail}
              name="bodeEmail"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            <FormControl sx={{ width: "100%" }}>
              <Autocomplete
                options={supervisoresData.map((item) => {
                  return (
                    item.perApellidos +
                    "/+/" +
                    item.perNombres +
                    "/+/" +
                    item.perId
                  );
                })}
                getOptionLabel={(option) => {
                  return option.replace("/+/", " ").split("/+/")[0];
                }}
                id="controlled-demo"
                value={
                  (item.perApellidos + " " + item.perNombres).trim() === ""
                    ? ""
                    : item.perApellidos + " " + item.perNombres
                }
                onChange={async (event, newValue) => {
                  if (newValue) {
                    const [apellido, nombre, id] = newValue.split("/+/");
                    if (!newValue) return;
                    setitem((prev) => ({
                      ...prev,
                      perApellidos: apellido,
                      perNombres: nombre,
                      bodeNombreResponsable: apellido + " " + nombre,
                      bodeIdResponsable: id,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" required fullWidth />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
          ></TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
