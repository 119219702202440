import React, { memo, useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Table,
  TableBody,
} from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../components/Extra/ConfirmacionDialog";
import { withRouter } from "react-router";
import ButtonsAction from "./ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import { tienePermisoRuta } from "../../../config/validaciones";
import MenuContext from "../../../Context/Menu/MenuContext";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { permisoDenagado, trimPropsItem } from "../../../config/const";
import { AddCircle, PictureAsPdf } from "@mui/icons-material";
import RowItem from "./RowItems/RowItem";
import RowAgregarItem from "./RowItems/RowAgregarItem";
import TablaCabecera from "./TablaCabecera";
import { useEffect } from "react";
import CampoDoc from "../../../components/Extra/CampoDoc";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  hover: {
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  celda: {
    fontSize: ".7rem !important",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const Row = (props) => {
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    puedeExportar,
    desdeOtroLugar,
    socket,
  } = props;
  const [agregarItemLista, setagregarItemLista] = useState(false);
  const [cargando, setcargando] = useState(false);
  const [expandir, setexpandir] = useState(false);
  const { usuario } = useContext(AuthContext);
  const { rows: rowsMenu } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [item, setitem] = useState(row);
  const [editar, seteditar] = useState(false);
  const classes = useRowStyles();
  useEffect(() => {
    setitem(row);
  }, [row]);

  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "iso",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        isoUser: usuario.usuario,
        isoEmpresa: usuario.rucempresa,
        isoId: row.isoId,
      });
    } catch (error) {}
  };

  const handleChange = (e) => {
    setitem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };
  const funcionEdit = () => {
    if (
      !tienePermisoRuta(
        rowsMenu,
        props.location.pathname,
        usuario.rol,
        "editar"
      )
    ) {
      return mostrarAlerta(permisoDenagado, "error");
    }
    seteditar(true);
  };

  const funcCancel = () => {
    setitem(row);
    seteditar(false);
  };
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          tabla: "iso",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          isoUser: usuario.usuario,
          isoEmpresa: usuario.rucempresa,
          isoOtros: item.isoOtros.map((itemOtro) => {
            return {
              ...itemOtro,
              procedimiento: item.isoProcedimiento,
              fechaEmision: item.isoFechaEmision,
            };
          }),
        })
      );
      seteditar(false);
    } catch (error) {}
  };
  const botonDisabled = () => {
    if (
      item.isoProcedimiento === "" ||
      item.isoFechaEmision === "" ||
      item.isoFechaRevision === "" ||
      item.isoVersion === "" ||
      item.isoResponsable === "" ||
      item.isoElaborado === "" ||
      item.isoAprobado === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        titulo={`¿Esta seguro de eliminar este registro?`}
        _id={row._id}
        funcion={handleEliminar}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          row={row}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          editar={editar}
          campoEstado=""
          puedeExportar={puedeExportar}
          desdeOtroLugar={desdeOtroLugar}
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          botonDisabled={botonDisabled}
          mostrarEspandir={true}
          setexpandir={setexpandir}
          expandir={expandir}
        />
        {editar ? (
          <>
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              <CampoDoc
                cargando={cargando}
                setcargando={setcargando}
                propiedad={"isoDocumento"}
                item={item}
                setitem={setitem}
                celda={true}
              />
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.isoProcedimiento}
                name="isoProcedimiento"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="date"
                value={item.isoFechaEmision}
                name="isoFechaEmision"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                type="date"
                value={item.isoFechaRevision}
                name="isoFechaRevision"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.isoVersion}
                name="isoVersion"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.isoResponsable}
                name="isoResponsable"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.isoElaborado}
                name="isoElaborado"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                value={item.isoAprobado}
                name="isoAprobado"
                onChange={(e) => handleChange(e)}
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            {" "}
            <TableCell
              fontSize="small"
              padding="none"
              align="center"
              className={classes.celda}
            >
              {row.isoDocumento === "" ? null : (
                <IconButton
                  aria-label=""
                  onClick={() => window.open(row.isoDocumento)}
                >
                  <PictureAsPdf color="error" />
                </IconButton>
              )}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.isoProcedimiento}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.isoFechaEmision}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.isoFechaRevision}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.isoVersion}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.isoResponsable}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.isoElaborado}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: "5px" }}
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {row.isoAprobado}
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={21}>
          <Collapse in={expandir} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" gutterBottom component="span">
                  Otros
                </Typography>
                <IconButton
                  size="small"
                  aria-label=""
                  color="secondary"
                  onClick={() => {
                    setagregarItemLista(true);
                  }}
                >
                  <AddCircle />
                </IconButton>
              </Box>
              <Table size="small" aria-label="purchases">
                <TablaCabecera
                  columns={[
                    {
                      id: "documento",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "documento",
                    },
                    {
                      id: "procedimiento",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "procedimiento",
                    },
                    {
                      id: "fechaEmision",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "F. Emision",
                    },
                    {
                      id: "fechaRevision",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "F. Revision",
                    },
                    {
                      id: "version",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "version",
                    },
                    {
                      id: "controlCambios",
                      align: "center",
                      minWidth: 50,
                      colSpan: 1,
                      label: "C. de cambios",
                    },
                  ]}
                />
                <TableBody>
                  {agregarItemLista ? (
                    <RowAgregarItem
                      item={item}
                      setitem={setitem}
                      setagregarItemLista={setagregarItemLista}
                      socket={socket}
                    />
                  ) : null}
                  {item.isoOtros.map((consigna, index) => {
                    return (
                      <RowItem
                        consigna={consigna}
                        item={item}
                        setitem={setitem}
                        index={index}
                        key={index}
                        socket={socket}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
