import React, { useState, useContext, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  TextField,
  FormControl,
  Autocomplete,
  MenuItem,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import Dropzone from "../../../components/Extra/Dropzone";
import Draggable from "react-draggable";
import { AddCircle, Cancel } from "@mui/icons-material";
import { IconButton } from "@mui/material";
// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { FechaExacta, trimPropsItem } from "../../../config/const";
import clienteAxios from "../../../config/axios";
import { objectDefault } from "../data";
import CampoDoc from "../../components/CampoDoc";
import ToolBarTable from "../../components/ToolBarTable";
import { useSimpleTable } from "../../hooks/useSimpleTable";
import TablaCabecera from "../../components/TablaCabecera";
import RowAgregarMetodo from "./Metodos/RowAgregarMetodo";
import RowMetodo from "./Metodos/RowMetodo";
import ImagenCelda from "../../components/ImagenCelda";
import useMultimediasUpload from "../../hooks/useMultimediasUpload";
function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "55%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: { width: "90%", marginTop: "2vh" },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: { display: "none" },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },

  form30PC: {
    flexGrow: 1,
    width: "30%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },

  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": { opacity: ".7" },
  },
  center: { justifyContent: "center", alignItems: "center" },
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
  },

  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: { color: "red", fontWeight: "bold" },
}));
const ModalData = (props) => {
  // const { tienePermiso, alertaPermiso } = usePermiso("Examenes");
  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const {
    open,
    setopen,
    editar,
    seteditar,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
    estadoRequest,
  } = props;
  const [receptor, setReceptor] = useState(false);
  const [emisor, setEmisor] = useState(false);
  const {
    setmodificoImagenes,
    subirImagenes,
    imagenes,
    setimagenes,
  } = useMultimediasUpload();
  const {
    setmodificoImagenes: setmodificoImagenesStatus,
    subirImagenes: subirImagenesStatus,
    imagenes: imagenesStatus,
    setimagenes: setimagenesStatus,
  } = useMultimediasUpload();

  // eslint-disable-next-line
  const {
    agregandoMetodo,
    setagregandoMetodo,
    pageMetodo,
    // setPageMetodo,
    rowsPerPageMetodo,
    // setRowsPerPageMetodo,
    handleChangePageMetodo,
    handleChangeRowsPerPageMetodo,
  } = useSimpleTable("Metodo");

  const [seccionActive, setseccionActive] = useState({
    SeccionDatos: true,
    SeccionMetodos: false,
    SeccionAcciones: false,
    SeccionDocumentos: false,
  });
  const {
    SeccionDatos,
    SeccionMetodos,
    SeccionAcciones,
    SeccionDocumentos,
  } = seccionActive;
  // eslint-disable-next-line
  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const [error, seterror] = useState({});
  const [modalStyle] = useState(getModalStyle);
  const {
    // mejMotivo,
    // mejSecuencia,
    mejFecha,
    mejFechaAtencion,
    mejProceso,
    mejPrioridad,
    mejAccionesFecha,
    mejAccionesStatus,
    // mejAccionesDocumento,
    mejAccionesFotos,
    // mejIdEmisor,
    // mejNombreEmisor,
    // mejIdReceptor,
    // mejDocumentoReceptor,
    mejNombreReceptor,
    mejDetalles,
    mejMetodo,
    // mejIdCliente,
    // mejNombreCliente,
    // mejDocumentoCliente,
    // mejAsunto,
    mejDetalleAcciones,
    // mejDocumento,
    // mejGuardias,
    // mejSupervisores,
    // mejAdministracion,
    // mejFechas,
    // mejFechasDB,
    mejFotos,
    // mejUser,
    // mejEmpresa,
    // mejFecReg,
    // mejFecUpd,
  } = item;

  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  const [receptoresData, setreceptoresData] = useState([]);
  useEffect(() => {
    if (usuario.rol.some((itemRol) => itemRol === "ADMIN")) {
      setEmisor(false);
      return setReceptor(false);
    }
    if (ObjectoActivo.mejIdEmisor === Number(usuario.id)) {
      setEmisor(true);
    }
    if (ObjectoActivo.mejIdReceptor === Number(usuario.id)) {
      setReceptor(true);
    }
    // eslint-disable-next-line
  }, [ObjectoActivo]);
  useEffect(() => {
    setitem(ObjectoActivo);
  }, [ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);
    setopen(false);
    seterror({});
    setcargando(false);
    seteditar(false);
    setReceptor(false);
    setEmisor(false);
    setimagenes([]);
    setimagenesStatus([]);
    setmodificoImagenes(false);
    setmodificoImagenesStatus(false);
  };

  //hook de estilos
  const clases = useStyles();

  const validacion = () => {
    if (false) {
      seterror({});
      return true;
    } else {
      return false;
    }
  };
  const handleCrear = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);

      const resDocumentos = await subirImagenes(imagenes, mejFotos);
      const resDocumentosAcciones = await subirImagenesStatus(
        imagenesStatus,
        mejAccionesFotos
      );

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          mejAccionesStatus:
            estadoRequest === "RECLAMO" ? "ASIGNADA" : mejAccionesStatus,
          mejFotos: resDocumentos,
          mejAccionesFotos: resDocumentosAcciones,
          mejAccionesFecha:
            item.mejAccionesFecha.trim() === "" && receptor ? FechaExacta : "",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "mejoracontinua",
          mejUser: usuario.usuario,
          mejEmpresa: usuario.rucempresa,
          mejTipo: estadoRequest,
          mejMotivo: estadoRequest,
          mejIdEmisor: usuario.id, // login id
          mejNombreEmisor: usuario.nombre, // login nombre
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    if (validacion()) {
      return mostrarAlerta("Faltan campos obligatorios", "error");
    }
    try {
      setcargando(true);
      const resDocumentos = await subirImagenes(imagenes, mejFotos);
      const resDocumentosAcciones = await subirImagenesStatus(
        imagenesStatus,
        mejAccionesFotos
      );

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          mejFotos: resDocumentos,
          mejAccionesFotos: resDocumentosAcciones,
          mejAccionesFecha:
            item.mejAccionesFecha.trim() === "" && receptor ? FechaExacta : "",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          tabla: "mejoracontinua",
          mejUser: usuario.usuario,
          mejEmpresa: usuario.rucempresa,
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  if (!open) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {" Mejora Continua"} ({estadoRequest})
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={
                      SeccionDatos
                        ? "contained"
                        : error.mejSecuencia ||
                          error.mejFecha ||
                          error.mejFechaAtencion ||
                          error.mejProceso ||
                          error.mejIdEmisor ||
                          error.mejNombreEmisor ||
                          error.mejIdReceptor ||
                          error.mejDocumentoReceptor ||
                          error.mejNombreReceptor ||
                          error.mejMotivo ||
                          error.mejDetalles
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.mejSecuencia ||
                      error.mejFecha ||
                      error.mejFechaAtencion ||
                      error.mejProceso ||
                      error.mejIdEmisor ||
                      error.mejNombreEmisor ||
                      error.mejIdReceptor ||
                      error.mejDocumentoReceptor ||
                      error.mejNombreReceptor ||
                      error.mejMotivo ||
                      error.mejDetalles
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ SeccionDatos: true })}
                  >
                    Datos Generales
                  </Button>
                  <Button
                    variant={
                      SeccionDocumentos
                        ? "contained"
                        : error.mejDocumento ||
                          error.mejFechas ||
                          error.mejFotos
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.mejDocumento || error.mejFechas || error.mejFotos
                        ? "error"
                        : "secondary"
                    }
                    onClick={() =>
                      setseccionActive({ SeccionDocumentos: true })
                    }
                  >
                    Documentos
                  </Button>
                  <Button
                    variant={
                      SeccionMetodos
                        ? "contained"
                        : error.mejMetodo
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={error.mejMetodo ? "error" : "secondary"}
                    onClick={() => setseccionActive({ SeccionMetodos: true })}
                  >
                    Metodo
                  </Button>
                  <Button
                    variant={
                      SeccionAcciones
                        ? "contained"
                        : error.mejNombreCliente ||
                          error.mejAsunto ||
                          error.mejDetalleAcciones ||
                          error.mejDocumento ||
                          error.mejGuardias ||
                          error.mejSupervisores ||
                          error.mejAdministracion
                        ? "outlined"
                        : null
                    }
                    disableElevation
                    style={{ padding: "8px" }}
                    color={
                      error.mejNombreCliente ||
                      error.mejAsunto ||
                      error.mejDetalleAcciones ||
                      error.mejGuardias ||
                      error.mejSupervisores ||
                      error.mejAdministracion
                        ? "error"
                        : "secondary"
                    }
                    onClick={() => setseccionActive({ SeccionAcciones: true })}
                  >
                    ACCIONES
                  </Button>
                </Box>
              </Box>
              <Divider />

              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                mt={1}
                maxHeight={"60vh"}
                overflow="scroll"
                sx={{ overflowX: "hidden" }}
              >
                {SeccionDatos ? (
                  <>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: FechaExacta,
                      }}
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Fecha"
                      value={mejFecha}
                      name="mejFecha"
                      error={error.mejFecha}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Area"
                      value={mejProceso}
                      name="mejProceso"
                      error={error.mejProceso}
                      onChange={async (e) => {
                        try {
                          const res = await clienteAxios.get(
                            `/personas/filtroporareadepartamento/0?search&estado=${e.target.value}`
                          );

                          setreceptoresData(res.data.data);
                          setitem({
                            ...item,
                            [e.target.name]: e.target.value,
                            mejIdReceptor: "",
                            mejDocumentoReceptor: "",
                            mejNombreReceptor: "",
                          });
                        } catch (error) {}
                      }}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      {[
                        "LOGISTICA",
                        "ADMINISTRACION",
                        "GERENTE",
                        "ASISTENTE DE GERENCIA",
                        "JEFE DE OPERACIONES",
                        "COMPRAS PUBLICAS",
                        "CONTADOR",
                        "SECRETARIA",
                        "SERVICIOS VARIOS",
                        "OTROS",
                      ].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="mejIdEmisor"
                      value={mejIdEmisor}
                      name="mejIdEmisor"
                      error={error.mejIdEmisor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="mejNombreEmisor"
                      value={mejNombreEmisor}
                      name="mejNombreEmisor"
                      error={error.mejNombreEmisor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <FormControl className={clases.formD}>
                      <Autocomplete
                        disableClearable={true}
                        disabled={receptor}
                        options={receptoresData.map((item) => {
                          return (
                            item.perApellidos +
                            "/+/" +
                            item.perNombres +
                            "/+/" +
                            item.perId
                          );
                        })}
                        getOptionLabel={(option) => {
                          return option.replace("/+/", " ").split("/+/")[0];
                        }}
                        id="controlled-demo"
                        value={mejNombreReceptor}
                        onChange={async (event, newValue) => {
                          if (newValue) {
                            const options = newValue.split("/+/");
                            const itemF = receptoresData.find(
                              (itemPersona) =>
                                itemPersona.perId === Number(options[2])
                            );
                            setitem({
                              ...item,
                              mejIdReceptor: itemF.perId,
                              mejDocumentoReceptor: itemF.perDocNumero,
                              mejNombreReceptor:
                                itemF.perApellidos + " " + itemF.perNombres,
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            disabled={receptor}
                            fullWidth
                            label="Receptor"
                            error={error.mejNombreCliente}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </FormControl>
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="mejIdReceptor"
                      value={mejIdReceptor}
                      name="mejIdReceptor"
                      error={error.mejIdReceptor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="mejDocumentoReceptor"
                      value={mejDocumentoReceptor}
                      name="mejDocumentoReceptor"
                      error={error.mejDocumentoReceptor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    {/* <TextField
                      className={clases.formD}
                      size="small"
                      label="mejNombreReceptor"
                      value={mejNombreReceptor}
                      name="mejNombreReceptor"
                      error={error.mejNombreReceptor}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <TextField
                      disabled
                      className={clases.formD}
                      size="small"
                      label="Motivo"
                      value={estadoRequest}
                      name="mejMotivo"
                      error={error.mejMotivo}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      {["CORRECTIVO", "RECLAMO"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: new Date(
                          Date.now() -
                            new Date().getTimezoneOffset() * 60 * 1000 +
                            86400000
                        )
                          .toISOString()
                          .split("T")[0],
                      }}
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Fecha de atención"
                      value={mejFechaAtencion}
                      name="mejFechaAtencion"
                      error={error.mejFechaAtencion}
                      onChange={(e) => handleChange(e)}
                    />{" "}
                    <TextField
                      className={clases.formD}
                      disabled={receptor}
                      size="small"
                      label="Prioridad"
                      value={mejPrioridad}
                      name="mejPrioridad"
                      error={error.mejPrioridad}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      {["ALTA", "MEDIA", "BAJA"].map((item) => {
                        return <MenuItem value={item}>{item} </MenuItem>;
                      })}
                    </TextField>
                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      disabled={receptor}
                      size="small"
                      label="Detalles"
                      value={mejDetalles}
                      name="mejDetalles"
                      error={error.mejDetalles}
                      onChange={(e) => handleChange(e)}
                    />
                  </>
                ) : null}
                {SeccionMetodos ? (
                  <>
                    {error.invProveedor ? (
                      <Typography
                        variant="caption"
                        color="error"
                        fontWeight="bold"
                      >
                        INGRESE AL MENOS UN PROVEEDOR.
                      </Typography>
                    ) : null}
                    <Paper className={clases.root}>
                      <ToolBarTable
                        count={mejMetodo.length}
                        rowsPerPage={rowsPerPageMetodo}
                        page={pageMetodo}
                        handleChangePage={handleChangePageMetodo}
                        handleChangeRowsPerPage={handleChangeRowsPerPageMetodo}
                        ocultarPaginacion={false}
                        simplePagination={true}
                        botonesIzquierdos={[
                          {
                            tooltip: "Agregar",
                            texto: "Agregar",
                            funcion: () => {
                              setagregandoMetodo(true);
                            },
                            disabled: mejMetodo.length >= 5 || emisor,
                            Icon: AddCircle,
                            color: "primary",
                            id: 1,
                            ocultar: false,
                            tipo: "boton",
                            variante: "contained",
                            size: "medium",
                            sx: {},
                          },
                        ]}
                        botonesDerechos={[]}
                      />

                      <TableContainer className={clases.container}>
                        <Table
                          stickyHeader
                          aria-label="sticky table"
                          size="small"
                        >
                          <TableHead>
                            <TablaCabecera
                              columns={[
                                {
                                  id: "metodo",
                                  align: "center",
                                  minWidth: 200,
                                  tipo: "string",
                                  label: "Porque",
                                },
                                {
                                  id: "porque",
                                  align: "center",
                                  minWidth: 200,
                                  tipo: "string",
                                  label: "Respuesta",
                                },
                              ]}
                              habilitarOrdenar={false}
                            />
                          </TableHead>

                          <TableBody component={"div"}>
                            {agregandoMetodo ? (
                              <RowAgregarMetodo
                                setagregar={setagregandoMetodo}
                                setitemPadre={setitem}
                                itemPadre={item}
                              />
                            ) : null}
                            {mejMetodo
                              .slice(
                                pageMetodo * rowsPerPageMetodo,
                                pageMetodo * rowsPerPageMetodo +
                                  rowsPerPageMetodo
                              )
                              .map((itemHijo, index) => {
                                return (
                                  <RowMetodo
                                    setitemPadre={setitem}
                                    itemPadre={item}
                                    itemHijo={itemHijo}
                                    index={index}
                                    key={index}
                                  />
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </>
                ) : null}
                {SeccionAcciones ? (
                  <>
                    <TextField
                      disabled
                      InputLabelProps={{ shrink: true }}
                      type="date"
                      className={clases.formD}
                      size="small"
                      label="Fec. Respuesta"
                      value={mejAccionesFecha}
                      name="mejAccionesFecha"
                      error={error.mejAccionesFecha}
                      onChange={(e) => handleChange(e)}
                    />

                    <TextField
                      className={clases.formD}
                      size="small"
                      label="Estado"
                      value={mejAccionesStatus}
                      name="mejAccionesStatus"
                      error={error.mejAccionesStatus}
                      onChange={(e) => handleChange(e)}
                      select
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem value="" disabled>
                        Seleccione.
                      </MenuItem>
                      <MenuItem value={mejAccionesStatus} disabled>
                        {mejAccionesStatus}
                      </MenuItem>
                      {!emisor
                        ? ObjectoActivo.mejAccionesStatus === "NO PROCESADO"
                          ? ["ASIGNADA", "ANULADA"].map((item, index) => {
                              return (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              );
                            })
                          : ObjectoActivo.mejAccionesStatus === "ASIGNADA"
                          ? ["PROCESADO"].map((item, index) => {
                              return (
                                <MenuItem value={item} key={index}>
                                  {item}
                                </MenuItem>
                              );
                            })
                          : ""
                        : ObjectoActivo.mejAccionesStatus === "NO PROCESADO"
                        ? ["ASIGNADA", "ANULADA"].map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          })
                        : ObjectoActivo.mejAccionesStatus === "ASIGNADA"
                        ? ["PROCESADO", "ANULADA"].map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          })
                        : ""}
                    </TextField>

                    <TextField
                      multiline
                      rows={3}
                      variant="outlined"
                      className={clases.formAllW}
                      size="small"
                      label="Detalle Acciones"
                      value={mejDetalleAcciones}
                      name="mejDetalleAcciones"
                      error={error.mejDetalleAcciones}
                      onChange={(e) => handleChange(e)}
                    />
                    <CampoDoc
                      disabled={emisor}
                      disabledE={emisor}
                      setcargando={setcargando}
                      propiedad={"mejAccionesDocumento"}
                      item={item}
                      setitem={setitem}
                      celda={false}
                    />
                    {emisor ? (
                      <>
                        <ImagenCelda
                          propiedad={mejAccionesFotos}
                          array={true}
                          claseCelda={clases.formAllW}
                          texto="Imagenes:"
                        />
                      </>
                    ) : (
                      <Dropzone
                        imagenes={imagenesStatus}
                        setimagenes={(e) => {
                          setmodificoImagenesStatus(true);
                          setimagenesStatus(e);
                        }}
                        fotosItem={mejAccionesFotos}
                      />
                    )}
                  </>
                ) : null}
                {SeccionDocumentos ? (
                  <>
                    <>
                      <CampoDoc
                        disabled={receptor}
                        disabledE={receptor}
                        setcargando={setcargando}
                        propiedad={"mejDocumento"}
                        item={item}
                        setitem={setitem}
                        celda={false}
                      />
                      {receptor ? (
                        <>
                          <ImagenCelda
                            propiedad={mejFotos}
                            array={true}
                            claseCelda={clases.formAllW}
                            texto="Imagenes:"
                          />
                        </>
                      ) : (
                        <Dropzone
                          imagenes={imagenes}
                          setimagenes={(e) => {
                            setmodificoImagenes(true);
                            setimagenes(e);
                          }}
                          fotosItem={mejFotos}
                        />
                      )}
                    </>
                  </>
                ) : null}
              </Box>
              {/* <Divider /> */}
              <Box
                mt={2}
                display="flex"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                flexWrap={"wrap"}
              >
                <Box
                  className={clases.formD}
                  alignItems="center"
                  display={"flex"}
                  justifyContent="space-around"
                ></Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  className={clases.formD}
                >
                  <Button
                    disabled={
                      mejAccionesStatus === "ANULADA" ||
                      mejAccionesStatus === "PROCESADO" ||
                      cargando
                    }
                    className={clases.formD}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (editar) {
                        handleEditar();
                      } else {
                        handleCrear();
                      }
                    }}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalData);
