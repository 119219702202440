export const columns = [
  {
    id: "ciuNombre",
    label: "Nombre",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "ciuNombre",
    align: "center",
    ocultar: true,
  },
  {
    id: "ciuRecReg",
    label: "Fec. Reg",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "ciuRecReg",
    align: "center",
    ocultar: true,
  },
  {
    id: "ciuUser",
    label: "User",
    minWidth: 250,
    tipo: "string",
    orden: 1,
    propiedad: "ciuUser",
    align: "center",
    ocultar: true,
  },
];

 
export const objectDefault = {
  ciuNombre: "",
  ciuUser: "",
  ciuEmpresa: "",
  ciuRecReg: "",
  Todos: "",
};
