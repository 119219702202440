import React, { useState, useContext, useEffect, memo } from "react";

import Draggable from "react-draggable";
// eslint-disable-next-line
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import clienteAxios from "../../../config/axios";
import ToolBarTable from "../../components/ToolBarTable";
import TablaCabecera from "../../components/TablaCabecera";
import RowIngreso from "./Ingresos/RowIngreso";
import RowAgregarIngreso from "./Ingresos/RowAgregarIngreso";

import RowEgreso from "./Egresos/RowEgreso";
import RowAgregarEgreso from "./Egresos/RowAgregarEgreso";

import RowAgregarLiquidacion from "./Liquidaciones/RowAgregarLiquidacion";
import RowLiquidacion from "./Liquidaciones/RowLiquidacion";
import {
  getMonths,
  navegadorCompatible,
  replaceCaracteres,
  trimPropsItem,
} from "../../../config/const";
import { useSimpleTable } from "../../hooks/useSimpleTable";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  TextField,
  Fade,
  FormControl,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Autocomplete,
  MenuItem,
} from "@mui/material";
import { AddCircle, Cancel } from "@mui/icons-material";
import RowAgregarOtro from "./Otros/RowAgregarOtro";
import RowOtro from "./Otros/RowOtro";
import { objectDefault } from "../data";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",
    overflow: "scroll",
    maxHeight: "95vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "5vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  margin: {
    margin: theme.spacing(1),
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  ocultar: {
    display: "none",
  },
  inputPadding: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(0),
    fontSize: "1rem",
  },
  formD: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
    },
  },
  large: {
    width: theme.spacing(13),
    height: theme.spacing(13),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      opacity: ".7",
    },
  },

  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    width: "100%",
  },
  correo: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "45%",

    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  error: {
    color: "red",
    fontWeight: "bold",
  },
}));

const ModalAsistencia = (props) => {
  const {
    ver,
    setver,
    estadoRequest,
    editar,
    seteditar,
    setopen,
    open,
    ObjectoActivo,
    changeObjetoActivoState,
    socket,
  } = props;
  const containerRef = React.useRef(null);
  const {
    agregandoIngreso,
    setagregandoIngreso,
    pageIngreso,
    // setPageIngreso,
    rowsPerPageIngreso,
    // setRowsPerPageIngreso,
    handleChangePageIngreso,
    handleChangeRowsPerPageIngreso,
  } = useSimpleTable("Ingreso");
  const {
    agregandoEgreso,
    setagregandoEgreso,
    pageEgreso,
    // setPageEgreso,
    rowsPerPageEgreso,
    // setRowsPerPageEgreso,
    handleChangePageEgreso,
    handleChangeRowsPerPageEgreso,
  } = useSimpleTable("Egreso");
  const {
    agregandoLiquidacion,
    setagregandoLiquidacion,
    pageLiquidacion,
    // setPageLiquidacion,
    rowsPerPageLiquidacion,
    // setRowsPerPageLiquidacion,
    handleChangePageLiquidacion,
    handleChangeRowsPerPageLiquidacion,
  } = useSimpleTable("Liquidacion");
  const {
    agregandoOtros,
    setagregandoOtros,
    pageOtros,
    // setPageOtros,
    rowsPerPageOtros,
    // setRowsPerPageOtros,
    handleChangePageOtros,
    handleChangeRowsPerPageOtros,
  } = useSimpleTable("Otros");
  const [seccionActive, setseccionActive] = useState({
    datosPrincipales: true,
    ingresos: false,
    egresos: false,
    liquidacion: false,
  });
  const {
    datosPrincipales,
    ingresos,
    egresos,
    liquidacion,
    otros,
  } = seccionActive;
  const [clientesData, setclientesData] = useState([]);
  const [personasData, setpersonasData] = useState([]);
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  // eslint-disable-next-line
  const { usuario } = useContext(AuthContext);
  //props

  const [item, setitem] = useState(ObjectoActivo);
  const [cargando, setcargando] = useState(false);
  const reducer = (previousValue, currentValue) =>
    Number(previousValue) + Number(currentValue);
  // eslint-disable-next-line
  const {
    // rolpCodigo,
    // rolpIdCliente,
    rolpDocNumCliente,
    rolpNomCliente,
    rolpCedula,
    rolpNombres,
    rolpSueldo,
    rolpCargo,
    rolpCiudad,
    rolpDiaLabo,
    rolpPeriodo,
    rolpPerEstatus,
    rolpNumCuenta,
    rolpBancoRemu,
    rolpEmail,
    rolpIngresos,
    rolpEgresos,
    rolpLiquidaciones,
    rolpOtros,
  } = item;

  // eslint-disable-next-line
  const [error, seterror] = useState({
    rolpCodigo: false,
    rolpIdCliente: false,
    rolpDocNumCliente: false,
    rolpNomCliente: false,
    rolpCedula: false,
    rolpNombres: false,
    rolpSueldo: false,
    rolpCargo: false,
    rolpCiudad: false,
    rolpDiaLabo: false,
    rolpPeriodo: false,
    rolpPerEstatus: false,
    rolpNumCuenta: false,
    rolpBancoRemu: false,
    rolpEmail: false,
    rolpIngresos: false,
    rolpEgresos: false,
  });

  useEffect(() => {
    const getData = async () => {
      const resIngreso = await clienteAxios.get(
        "/aportes/filtro/0?tipo=INGRESO&search"
      );
      const resEgreso = await clienteAxios.get(
        "/aportes/filtro/0?tipo=EGRESO&search"
      );
      const resLiquidacion = await clienteAxios.get(
        "/aportes/filtro/0?tipo=LIQUIDACION&search"
      );

      if (ObjectoActivo.rolpDocNumCliente !== "") {
        const res = await clienteAxios.get(
          `/personas/listado/:codigo?docnumero=${ObjectoActivo.rolpDocNumCliente}`
        );
        setpersonasData(res.data.data);
      }
      setitem({
        ...ObjectoActivo,
        rolpIngresos: resIngreso.data.data.map((itemResIngreso) => {
          return {
            valor: itemResIngreso.apoPorcentaje,
            nombre: itemResIngreso.apoAporte,
          };
        }),
        rolpEgresos: resEgreso.data.data.map((itemEgreso) => {
          return {
            valor: itemEgreso.apoPorcentaje,
            nombre: itemEgreso.apoAporte,
          };
        }),
        rolpLiquidaciones: resLiquidacion.data.data.map((itemEgreso) => {
          return {
            valor: itemEgreso.apoPorcentaje,
            nombre: itemEgreso.apoAporte,
          };
        }),
      });
    };
    if (open && !editar) {
      getData();
    } else if (open) {
      const getDataEdit = async () => {
        const resIngreso = await clienteAxios.get(
          `/rolpagodetalle/filtro/0?tipo=INGRESO&cedula=${ObjectoActivo.rolpCedula}&periodo=${ObjectoActivo.rolpPeriodo}`
        );
        const resEgreso = await clienteAxios.get(
          `/rolpagodetalle/filtro/0?tipo=EGRESO&cedula=${ObjectoActivo.rolpCedula}&periodo=${ObjectoActivo.rolpPeriodo}`
        );
        // const resLiquidacion = await clienteAxios.get(
        //   `/rolpagodetalle/filtro/0?tipo=LIQUIDACION&cedula=${ObjectoActivo.rolpCedula}&periodo=${ObjectoActivo.rolpPeriodo}`
        // );
        const resOtros = await clienteAxios.get(
          `/rolpagodetalle/filtro/0?tipo=OTROS&cedula=${ObjectoActivo.rolpCedula}&periodo=${ObjectoActivo.rolpPeriodo}`
        );
        console.log({
          ...ObjectoActivo,
          rolpIngresos: resIngreso.data.data.map((itemResIngreso) => {
            return {
              valor: itemResIngreso.roldDatos.valor,
              nombre: itemResIngreso.roldDatos.nombre,
              roldId: itemResIngreso.roldId,
            };
          }),
          rolpEgresos: resEgreso.data.data.map((itemEgreso) => {
            return {
              valor: itemEgreso.roldDatos.valor,
              nombre: itemEgreso.roldDatos.nombre,
              roldId: itemEgreso.roldId,
            };
          }),
          // rolpLiquidaciones: resLiquidacion.data.data.map((itemEgreso) => {
          //   return {
          //     valor: itemEgreso.roldDatos.valor,
          //     nombre: itemEgreso.roldDatos.nombre,
          //     roldId: itemEgreso.roldId,
          //   };
          // }),
          rolpOtros: resOtros.data.data.map((itemEgreso) => {
            return {
              valor: itemEgreso.roldDatos.valor,
              nombre: itemEgreso.roldDatos.nombre,
              roldId: itemEgreso.roldId,
            };
          }),
        });

        setitem({
          ...ObjectoActivo,
          rolpIngresos: resIngreso.data.data.map((itemResIngreso) => {
            return {
              valor: itemResIngreso.roldDatos.valor,
              nombre: itemResIngreso.roldDatos.nombre,
              roldId: itemResIngreso.roldId,
            };
          }),
          rolpEgresos: resEgreso.data.data.map((itemEgreso) => {
            return {
              valor: itemEgreso.roldDatos.valor,
              nombre: itemEgreso.roldDatos.nombre,
              roldId: itemEgreso.roldId,
            };
          }),
          // rolpLiquidaciones: resLiquidacion.data.data.map((itemEgreso) => {
          //   return {
          //     valor: itemEgreso.roldDatos.valor,
          //     nombre: itemEgreso.roldDatos.nombre,
          //     roldId: itemEgreso.roldId,
          //   };
          // }),
          rolpOtros: resOtros.data.data.map((itemEgreso) => {
            return {
              valor: itemEgreso.roldDatos.valor,
              nombre: itemEgreso.roldDatos.nombre,
              roldId: itemEgreso.roldId,
            };
          }),
        });
      };
      getDataEdit();
    }

    // eslint-disable-next-line
  }, [open, ObjectoActivo]);

  const defaultActive = () => {
    changeObjetoActivoState(objectDefault);

    setopen(false);
    seteditar(false);
    seterror({
      rolpCodigo: false,
      rolpIdCliente: false,
      rolpDocNumCliente: false,
      rolpNomCliente: false,
      rolpCedula: false,
      rolpNombres: false,
      rolpSueldo: false,
      rolpCargo: false,
      rolpCiudad: false,
      rolpDiaLabo: false,
      rolpPeriodo: false,
      rolpPerEstatus: false,
      rolpNumCuenta: false,
      rolpBancoRemu: false,
      rolpEmail: false,
      rolpIngresos: false,
      rolpEgresos: false,
    });
    // setseccionActive({
    //   datosPersonalesSeccion: true,
    //   datosContablesSeccion: false,
    // });
    setver(false);
    setcargando(false);
    setseccionActive({
      datosPrincipales: true,
      ingresos: false,
      egresos: false,
      otros: false,
    });
  };
  // eslint-disable-next-line
  const handleChange = (e) => {
    const res = replaceCaracteres(e.target.value);
    setitem({
      ...item,
      [e.target.name]: res,
    });
  };

  //hook de estilos
  const clases = useStyles();
  //definiendo los estilos del modal

  const [modalStyle] = useState(getModalStyle);

  const submit = () => {
    if (true) {
      return false;
    } else {
      return true;
    }
  };

  // eslint-disable-next-line
  const handleCrear = async () => {
    try {
      if (
        rolpDocNumCliente === "" ||
        rolpCedula === "" ||
        rolpPeriodo === "" ||
        rolpDiaLabo === ""
      ) {
        mostrarAlerta("Faltan datos obligatorios por llenar", "error");
        return seterror({
          rolpDocNumCliente: rolpDocNumCliente === "" ? true : false,
          rolpCedula: rolpCedula === "" ? true : false,
          rolpPeriodo: rolpPeriodo === "" ? true : false,
          rolpDiaLabo: rolpDiaLabo === "" ? true : false,
        });
      }
      setcargando(true);

      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          rolpCodigo: rolpCedula + "-" + rolpPeriodo,
          tabla: "rolpago",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          rolpUser: usuario.usuario,
          rolpEmpresa: usuario.rucempresa,
          rolpIngresos: [],
          rolpEgresos: [],
          rolpOtros: [],
          rolpLiquidaciones: [],
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };
  const handleEditar = async () => {
    try {
      if (
        rolpDocNumCliente === "" ||
        rolpCedula === "" ||
        rolpPeriodo === "" ||
        rolpDiaLabo === ""
      ) {
        mostrarAlerta("Faltan datos obligatorios por llenar", "error");
        return seterror({
          rolpDocNumCliente: rolpDocNumCliente === "" ? true : false,
          rolpCedula: rolpCedula === "" ? true : false,
          rolpPeriodo: rolpPeriodo === "" ? true : false,
          rolpDiaLabo: rolpDiaLabo === "" ? true : false,
        });
      }
      setcargando(true);

      socket.current.emit(
        "client:actualizarData",
        trimPropsItem({
          ...item,
          rolpCodigo: rolpCedula + "-" + rolpPeriodo,
          tabla: "rolpago",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          rolpUser: usuario.usuario,
          rolpEmpresa: usuario.rucempresa,
          rolpIngresos: [],
          rolpEgresos: [],
          rolpOtros: [],
          rolpLiquidaciones: [],
        })
      );
      setcargando(false);
      return defaultActive();
    } catch (error) {
      setcargando(false);
      mostrarAlerta("Hubo un error", "error");
      return defaultActive();
    }
  };

  // eslint-disable-next-line

  if (!open || !item.rolpEgresos) {
    return null;
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Draggable handle="#handle">
          <Box display="flex" justifyContent="center">
            <div style={modalStyle} className={clases.paper}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  {`${editar ? "Editar" : "Agregar"} ${"Rol Pago"}.`}
                </Typography>{" "}
                <Typography
                  style={{ cursor: "-webkit-grab" }}
                  id="handle"
                  variant="button"
                  color="initial"
                  align="center"
                >
                  Total a pagar: $
                  {(
                    (rolpIngresos.length === 0
                      ? null
                      : rolpIngresos
                          .map((row) => {
                            return Number(row.valor);
                          })
                          .reduce(reducer)) -
                    (rolpEgresos.length === 0
                      ? null
                      : rolpEgresos
                          .map((row) => {
                            return Number(row.valor);
                          })
                          .reduce(reducer))
                  ).toFixed(2)}
                </Typography>
                <Box display="flex" alignItems="center">
                  <IconButton
                    aria-label=""
                    style={{ margin: "0px" }}
                    onClick={() => {
                      defaultActive();
                    }}
                  >
                    <Cancel color="secondary" />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems=" stretch"
                width="100%"
              >
                <Box>
                  <Button
                    variant={datosPrincipales ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ datosPrincipales: true })}
                  >
                    Datos Principales
                  </Button>
                  <Button
                    variant={ingresos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ ingresos: true })}
                  >
                    Ingresos
                  </Button>
                  <Button
                    variant={egresos ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ egresos: true })}
                  >
                    Egresos
                  </Button>{" "}
                  {/* <Button
                    variant={liquidacion ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ liquidacion: true })}
                  >
                    Liquidacion
                  </Button> */}
                  <Button
                    variant={otros ? "contained" : null}
                    disableElevation
                    style={{ padding: "8px" }}
                    color="secondary"
                    onClick={() => setseccionActive({ otros: true })}
                  >
                    Otros
                  </Button>
                </Box>
                {/* <FormControlLabel
                control={
                  <Switch
                    checked={
                      conEstado === "ACTIVO" || conEstado === "ACTIVA"
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setitem({
                        ...item,
                        conEstado: e.target.checked ? "ACTIVA" : "INACTIVO",
                      });
                    }}
                    name="antoine"
                    color="primary"
                  />
                }
                label="Estado"
              /> */}
              </Box>
              <Divider />
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="flex-end"
                style={{ maxHeight: "65vh", overflow: "scroll" }}
                p={1}
                ref={containerRef}
              >
                {datosPrincipales ? (
                  <Fade
                    direction="up"
                    in={datosPrincipales}
                    style={{ width: "100%" }}
                  >
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                      <>
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            disableClearable={true}
                            options={clientesData.map((item) => {
                              return (
                                item.cliDocNumero +
                                "/+/" +
                                item.cliRazonSocial +
                                "/+/" +
                                item.cliId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option.replace("/+/", " ").split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={rolpDocNumCliente + " " + rolpNomCliente}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                try {
                                  const res = await clienteAxios.get(
                                    `/personas/listado/:codigo?docnumero=${options[0]}`
                                  );

                                  setpersonasData(res.data.data);

                                  setitem({
                                    ...item,
                                    rolpDocNumCliente: options[0],
                                    rolpNomCliente: options[1],
                                    rolpIdCliente: options[2],
                                    rolpCedula: "",
                                    rolpIdPersona: "",
                                    rolpNombres: "",
                                    rolpSueldo: "",
                                    rolpCargo: "",
                                    rolpCiudad: "",
                                    // rolpDiaLabo: "",
                                    // rolpPeriodo: "",
                                    rolpPerEstatus: "",
                                    rolpNumCuenta: "",
                                    rolpBancoRemu: "",
                                    rolpEmail: "",
                                    rolpDocTipo: "",
                                    rolpTipoCuenta: "",
                                    rolpFormaPago: "",
                                    rolpTelefono: "",
                                  });
                                } catch (error) {
                                  return mostrarAlerta(
                                    "No hay personas",
                                    "error"
                                  );
                                }
                              } else {
                                setitem({
                                  ...item,
                                  rolpIdCliente: "",
                                  rolpDocNumCliente: "",
                                  rolpNomCliente: "",
                                  rolpCedula: "",
                                  rolpIdPersona: "",
                                  rolpNombres: "",
                                  rolpSueldo: "",
                                  rolpCargo: "",
                                  rolpCiudad: "",
                                  // rolpDiaLabo: "",
                                  // rolpPeriodo: "",
                                  rolpPerEstatus: "",
                                  rolpNumCuenta: "",
                                  rolpBancoRemu: "",
                                  rolpEmail: "",
                                  rolpDocTipo: "",
                                  rolpTipoCuenta: "",
                                  rolpFormaPago: "",
                                  rolpTelefono: "",
                                });
                                setclientesData([]);
                                setpersonasData([]);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                disabled={editar}
                                required
                                fullWidth
                                label="Cliente"
                                error={error.rolpDocNumCliente}
                                onChange={async (e) => {
                                  if (e.target.value === "") {
                                    return mostrarAlerta(
                                      "Ingrese datos",
                                      "error"
                                    );
                                  }
                                  try {
                                    if (e.target.value.length < 3) {
                                      return;
                                    }
                                    const res = await clienteAxios.get(
                                      `/clientes/filtro/0?search=${e.target.value}&estado=CLIENTES`
                                    );

                                    setclientesData(res.data.data);
                                  } catch (error) {
                                    setitem({
                                      ...item,
                                      rolpIdCliente: "",
                                      rolpDocNumCliente: "",
                                      rolpNomCliente: "",
                                      rolpCedula: "",
                                      rolpIdPersona: "",
                                      rolpNombres: "",
                                      rolpSueldo: "",
                                      rolpCargo: "",
                                      rolpCiudad: "",
                                      // rolpDiaLabo: "",
                                      // rolpPeriodo: "",
                                      rolpPerEstatus: "",
                                      rolpNumCuenta: "",
                                      rolpBancoRemu: "",
                                      rolpEmail: "",
                                    });
                                    setclientesData([]);
                                    setpersonasData([]);
                                    return mostrarAlerta(
                                      "No hay clientes",
                                      "error"
                                    );
                                  }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                        >
                          <Autocomplete
                            disableClearable={true}
                            options={personasData.map((item) => {
                              return (
                                item.perDocNumero +
                                "/+/" +
                                item.perApellidos +
                                "/+/" +
                                item.perNombres +
                                "/+/" +
                                item.perId
                              );
                            })}
                            getOptionLabel={(option) => {
                              return option
                                .replace("/+/", " ")
                                .replace("/+/", " ")
                                .split("/+/")[0];
                            }}
                            id="controlled-demo"
                            value={rolpCedula + " " + rolpNombres}
                            onChange={async (event, newValue) => {
                              if (newValue) {
                                const options = newValue.split("/+/");

                                const itemF = personasData.find(
                                  (itemPersona) =>
                                    itemPersona.perId === Number(options[3])
                                );

                                setitem({
                                  ...item,
                                  rolpCedula: itemF.perDocNumero,
                                  rolpIdPersona: itemF.perId,
                                  rolpNombres:
                                    itemF.perApellidos + " " + itemF.perNombres,
                                  rolpSueldo: itemF.perRemuneracion,
                                  rolpCargo: itemF.perCargo,
                                  rolpCiudad: itemF.perCiudad,
                                  // rolpDiaLabo: "",
                                  // rolpPeriodo: "",
                                  rolpPerEstatus: itemF.perEstado,
                                  rolpNumCuenta: itemF.perCtaBancaria,
                                  rolpBancoRemu: itemF.perBancoRemuneracion,
                                  rolpEmail: itemF.perEmail,
                                  rolpDocTipo: itemF.perDocTipo,
                                  rolpTipoCuenta: itemF.perTipoCuenta,
                                  rolpFormaPago: itemF.perFormaPago,
                                  rolpTelefono: itemF.perTelefono,
                                });
                              } else {
                                setitem({
                                  ...item,
                                  rolpCedula: "",
                                  rolpIdPersona: "",
                                  rolpNombres: "",
                                  rolpSueldo: "",
                                  rolpCargo: "",
                                  rolpCiudad: "",
                                  // rolpDiaLabo: "",
                                  // rolpPeriodo: "",
                                  rolpPerEstatus: "",
                                  rolpNumCuenta: "",
                                  rolpBancoRemu: "",
                                  rolpEmail: "",
                                  rolpDocTipo: "",
                                  rolpTipoCuenta: "",
                                  rolpFormaPago: "",
                                  rolpTelefono: "",
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                disabled={editar}
                                required
                                fullWidth
                                error={error.rolpCedula}
                                label="Persona"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpCedula"
                          label="Cédula"
                          value={rolpCedula}
                          name="rolpCedula"
                          error={error.rolpCedula}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpNombres"
                          label="Nombres"
                          value={rolpNombres}
                          name="rolpNombres"
                          error={error.rolpNombres}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpSueldo"
                          label="Sueldo"
                          value={rolpSueldo}
                          name="rolpSueldo"
                          error={error.rolpSueldo}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpCargo"
                          label="Cargo"
                          value={rolpCargo}
                          name="rolpCargo"
                          error={error.rolpCargo}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpCiudad"
                          label="Ciudad"
                          value={rolpCiudad}
                          name="rolpCiudad"
                          error={error.rolpCiudad}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          type="number"
                          disabled={estadoRequest === "PROCESADO"}
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpDiaLabo"
                          label="Dia L."
                          value={rolpDiaLabo}
                          name="rolpDiaLabo"
                          error={error.rolpDiaLabo}
                          onChange={(e) => handleChange(e)}
                        />
                        {navegadorCompatible ? (
                          <TextField
                            type="month"
                            disabled={estadoRequest === "PROCESADO"}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              className: clases.inputPadding,
                              min: new Date(
                                new Date().setMonth(new Date().getMonth() - 1)
                              )
                                .toISOString()
                                .substr(0, 7),
                            }}
                            className={clases.formD}
                            size="small"
                            id="rolpPeriodo"
                            label="Periodo"
                            value={rolpPeriodo}
                            name="rolpPeriodo"
                            error={error.rolpPeriodo}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                        ) : (
                          <TextField
                            disabled={estadoRequest === "PROCESADO"}
                            inputProps={{
                              className: clases.inputPadding,
                            }}
                            className={clases.formD}
                            size="small"
                            label="Periodo"
                            value={rolpPeriodo}
                            name="rolpPeriodo"
                            error={error.rolpPeriodo}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ displayEmpty: true }}
                          >
                            <MenuItem value="" disabled>
                              Seleccione.
                            </MenuItem>
                            {getMonths(true).map((item) => {
                              return <MenuItem value={item}>{item} </MenuItem>;
                            })}
                          </TextField>
                        )}

                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpPerEstatus "
                          label="Estado"
                          value={rolpPerEstatus}
                          name="rolpPerEstatus "
                          error={error.rolpPerEstatus}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpNumCuenta"
                          label="N. Cuenta"
                          value={rolpNumCuenta}
                          name="rolpNumCuenta"
                          error={error.rolpNumCuenta}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpBancoRemu"
                          label="Banco R."
                          value={rolpBancoRemu}
                          name="rolpBancoRemu"
                          error={error.rolpBancoRemu}
                          // onChange={(e) => handleChange(e)}
                        />
                        <TextField
                          disabled
                          inputProps={{ className: clases.inputPadding }}
                          className={clases.formD}
                          size="small"
                          id="rolpEmail"
                          label="Email"
                          value={rolpEmail}
                          name="rolpEmail"
                          error={error.rolpEmail}
                          // onChange={(e) => handleChange(e)}
                        />
                      </>
                    </Box>
                  </Fade>
                ) : null}

                {ingresos ? (
                  <Fade direction="up" in={ingresos} style={{ width: "100%" }}>
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                      <>
                        <Paper className={clases.root}>
                          <ToolBarTable
                            count={rolpIngresos.length}
                            rowsPerPage={rowsPerPageIngreso}
                            page={pageIngreso}
                            handleChangePage={handleChangePageIngreso}
                            handleChangeRowsPerPage={
                              handleChangeRowsPerPageIngreso
                            }
                            ocultarPaginacion={false}
                            simplePagination={true}
                            botonesIzquierdos={[
                              {
                                tooltip: "Agregar",
                                texto: "Agregar Ingreso",
                                funcion: () => {
                                  setagregandoIngreso(true);
                                },
                                disabled: false,
                                Icon: AddCircle,
                                color: "primary",
                                id: 1,
                                ocultar: false,
                                tipo: "boton",
                                variante: "contained",
                                size: "medium",
                                sx: {},
                              },
                            ]}
                            botonesDerechos={[]}
                          />

                          <TableContainer className={clases.container}>
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              size="small"
                            >
                              <TableHead>
                                <TablaCabecera
                                  columns={[
                                    {
                                      id: "nombre",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Nombre",
                                    },
                                    {
                                      id: "valor",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Valor",
                                    },
                                  ]}
                                  habilitarOrdenar={false}
                                />
                              </TableHead>

                              <TableBody>
                                {agregandoIngreso ? (
                                  <RowAgregarIngreso
                                    setagregar={setagregandoIngreso}
                                    setitem={setitem}
                                    item={item}
                                  />
                                ) : null}
                                {rolpIngresos.map((row, index) => {
                                  return (
                                    <RowIngreso
                                      deshabilitarbotones={
                                        estadoRequest === "PROCESADO"
                                      }
                                      item={item}
                                      setitem={setitem}
                                      row={row}
                                      index={index}
                                      key={index}
                                      editarPadre={editar}
                                    />
                                  );
                                })}
                                <TableRow>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  >
                                    <Typography
                                      variant="button"
                                      color="initial"
                                      align="center"
                                    >
                                      Total: $
                                      {(rolpIngresos.length === 0
                                        ? null
                                        : rolpIngresos
                                            .map((row) => {
                                              return Number(row.valor);
                                            })
                                            .reduce(reducer)
                                      )
                                        // -
                                        // (rolpEgresos.length === 0
                                        //   ? null
                                        //   : rolpEgresos
                                        //       .map((row) => {
                                        //         return Number(row.valor);
                                        //       })
                                        //       .reduce(reducer))
                                        .toFixed(2)}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </>
                    </Box>
                  </Fade>
                ) : null}

                {egresos ? (
                  <Fade direction="up" in={egresos} style={{ width: "100%" }}>
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                      <>
                        <Paper className={clases.root}>
                          <ToolBarTable
                            count={rolpEgresos.length}
                            rowsPerPage={rowsPerPageEgreso}
                            page={pageEgreso}
                            handleChangePage={handleChangePageEgreso}
                            handleChangeRowsPerPage={
                              handleChangeRowsPerPageEgreso
                            }
                            ocultarPaginacion={false}
                            simplePagination={true}
                            botonesIzquierdos={[
                              {
                                tooltip: "Agregar",
                                texto: "Agregar Egreso",
                                funcion: () => {
                                  setagregandoEgreso(true);
                                },
                                disabled: false,
                                Icon: AddCircle,
                                color: "primary",
                                id: 1,
                                ocultar: false,
                                tipo: "boton",
                                variante: "contained",
                                size: "medium",
                                sx: {},
                              },
                            ]}
                            botonesDerechos={[]}
                          />

                          <TableContainer className={clases.container}>
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              size="small"
                            >
                              <TableHead>
                                <TablaCabecera
                                  columns={[
                                    {
                                      id: "nombre",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Nombre",
                                    },
                                    {
                                      id: "valor",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Valor",
                                    },
                                  ]}
                                  habilitarOrdenar={false}
                                />
                              </TableHead>

                              <TableBody>
                                {agregandoEgreso ? (
                                  <RowAgregarEgreso
                                    setagregar={setagregandoEgreso}
                                    setitem={setitem}
                                    item={item}
                                  />
                                ) : null}
                                {rolpEgresos.map((row, index) => {
                                  return (
                                    <RowEgreso
                                      deshabilitarbotones={
                                        estadoRequest === "PROCESADO"
                                      }
                                      item={item}
                                      setitem={setitem}
                                      row={row}
                                      index={index}
                                      key={index}
                                      editarPadre={editar}
                                    />
                                  );
                                })}
                                <TableRow>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  >
                                    <Typography
                                      variant="button"
                                      color="initial"
                                      align="center"
                                    >
                                      Total: $
                                      {rolpEgresos.length === 0
                                        ? null
                                        : rolpEgresos
                                            .map((row) => {
                                              return Number(row.valor);
                                            })
                                            .reduce(reducer)
                                            .toFixed(2)}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </>
                    </Box>
                  </Fade>
                ) : null}

                {liquidacion ? (
                  <Fade
                    direction="up"
                    in={liquidacion}
                    style={{ width: "100%" }}
                  >
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                      <>
                        <Paper className={clases.root}>
                          <ToolBarTable
                            count={rolpLiquidaciones.length}
                            rowsPerPage={rowsPerPageLiquidacion}
                            page={pageLiquidacion}
                            handleChangePage={handleChangePageLiquidacion}
                            handleChangeRowsPerPage={
                              handleChangeRowsPerPageLiquidacion
                            }
                            ocultarPaginacion={false}
                            simplePagination={true}
                            botonesIzquierdos={[
                              {
                                tooltip: "Agregar",
                                texto: "Agregar Liquidacion",
                                funcion: () => {
                                  setagregandoLiquidacion(true);
                                },
                                disabled: false,
                                Icon: AddCircle,
                                color: "primary",
                                id: 1,
                                ocultar: false,
                                tipo: "boton",
                                variante: "contained",
                                size: "medium",
                                sx: {},
                              },
                            ]}
                            botonesDerechos={[]}
                          />

                          <TableContainer className={clases.container}>
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              size="small"
                            >
                              <TableHead>
                                <TablaCabecera
                                  columns={[
                                    {
                                      id: "nombre",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Nombre",
                                    },
                                    {
                                      id: "valor",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Valor",
                                    },
                                  ]}
                                  habilitarOrdenar={false}
                                />
                              </TableHead>

                              <TableBody>
                                {agregandoLiquidacion ? (
                                  <RowAgregarLiquidacion
                                    setagregar={setagregandoLiquidacion}
                                    setitem={setitem}
                                    item={item}
                                  />
                                ) : null}
                                {rolpLiquidaciones.map((row, index) => {
                                  return (
                                    <RowLiquidacion
                                      deshabilitarbotones={
                                        estadoRequest === "PROCESADO"
                                      }
                                      item={item}
                                      setitem={setitem}
                                      row={row}
                                      index={index}
                                      key={index}
                                      editarPadre={editar}
                                    />
                                  );
                                })}
                                <TableRow>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  >
                                    <Typography
                                      variant="button"
                                      color="initial"
                                      align="center"
                                    >
                                      Total: $
                                      {rolpLiquidaciones.length === 0
                                        ? null
                                        : rolpLiquidaciones
                                            .map((row) => {
                                              return Number(row.valor);
                                            })
                                            .reduce(reducer)
                                            .toFixed(2)}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </>
                    </Box>
                  </Fade>
                ) : null}
                {otros ? (
                  <Fade direction="up" in={otros} style={{ width: "100%" }}>
                    <Box display="flex" flexWrap="wrap" alignItems="flex-end">
                      <>
                        <Paper className={clases.root}>
                          <ToolBarTable
                            count={rolpOtros.length}
                            rowsPerPage={rowsPerPageOtros}
                            page={pageOtros}
                            handleChangePage={handleChangePageOtros}
                            handleChangeRowsPerPage={
                              handleChangeRowsPerPageOtros
                            }
                            ocultarPaginacion={false}
                            simplePagination={true}
                            botonesIzquierdos={[
                              {
                                tooltip: "Agregar",
                                texto: "Agregar Otro",
                                funcion: () => {
                                  setagregandoOtros(true);
                                },
                                disabled: false,
                                Icon: AddCircle,
                                color: "primary",
                                id: 1,
                                ocultar: false,
                                tipo: "boton",
                                variante: "contained",
                                size: "medium",
                                sx: {},
                              },
                            ]}
                            botonesDerechos={[]}
                          />

                          <TableContainer className={clases.container}>
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              size="small"
                            >
                              <TableHead>
                                <TablaCabecera
                                  columns={[
                                    {
                                      id: "nombre",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Nombre",
                                    },
                                    {
                                      id: "valor",
                                      align: "center",
                                      minWidth: 200,
                                      tipo: "string",
                                      label: "Valor",
                                    },
                                  ]}
                                  habilitarOrdenar={false}
                                />
                              </TableHead>

                              <TableBody>
                                {agregandoOtros ? (
                                  <RowAgregarOtro
                                    setagregar={setagregandoOtros}
                                    setitem={setitem}
                                    item={item}
                                  />
                                ) : null}
                                {rolpOtros.map((row, index) => {
                                  return (
                                    <RowOtro
                                      deshabilitarbotones={
                                        estadoRequest === "PROCESADO"
                                      }
                                      item={item}
                                      setitem={setitem}
                                      row={row}
                                      index={index}
                                      key={index}
                                      editarPadre={editar}
                                    />
                                  );
                                })}
                                <TableRow>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  ></TableCell>
                                  <TableCell
                                    padding="none"
                                    size="small"
                                    align="center"
                                  >
                                    <Typography
                                      variant="button"
                                      color="initial"
                                      align="center"
                                    >
                                      Total: $
                                      {rolpOtros.length === 0
                                        ? null
                                        : rolpOtros
                                            .map((row) => {
                                              return Number(row.valor);
                                            })
                                            .reduce(reducer)
                                            .toFixed(2)}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                      </>
                    </Box>
                  </Fade>
                ) : null}
              </Box>
              {/* <Divider /> */}
              {ver ? null : (
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                    mt={1}
                  >
                    <Button
                      style={{ width: "30%" }}
                      variant="contained"
                      color="primary"
                      disabled={
                        submit() || cargando || estadoRequest === "PROCESADO"
                      }
                      onClick={() => {
                        if (editar) {
                          handleEditar();
                        } else {
                          handleCrear();
                        }
                      }}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              )}
            </div>
          </Box>
        </Draggable>
      </Box>
    </Modal>
  );
};

export default memo(ModalAsistencia);
