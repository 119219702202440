import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";

import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  PictureAsPdf,
  QrCode,
  Restore,
} from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { usePermiso } from "../../hooks/usePermiso";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import clienteAxios from "../../../config/axios";
import { trimPropsItem } from "../../../config/const";
import CeldaUser from "../../components/CeldaUser";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const {
    arrayExport,
    setarrayExport,
    row,
    index,
    campoExport,
    seteditar,
    setopen,
    changeObjetoActivoState,
    socket,
    itemQr,
    setitemQr,
    setopenQr,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    row.editado === true
      ? classes.celdaEditado
      : row.nuevo === true
      ? classes.celdaNuevo
      : row.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Inventario");
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...row,
          nombre: usuario.nombre,
          tabla: "inventario",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          invUser: usuario.usuario + "/+/" + row[campoExport],
          invEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        tabla: "inventario",
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        invUser: usuario.usuario,
        invEmpresa: usuario.rucempresa,
        [campoExport]: row[campoExport],
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  // FUNCION PARA EDITAR, ABRE EL MODAL COLOCA EN EDITAR EL MODAL Y CAMBIA EL OBJETO ACTIVO
  const funcionEdit = () => {
    setopen(true);
    seteditar(true);
    changeObjetoActivoState({
      ...row,
      invProveedor: row.invProveedor.map((proveedor) => ({
        ...proveedor,
        anterior: proveedor.cantidad,
      })),
    });
  };
  // ESCUCHA LOS CAMBIOS DEL REGISTRO

  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={row}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== row.invUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 3,
              ocultar: !row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 1,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                funcionEdit();
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 2,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "QR",
              texto: "",
              funcion: () => {
                setitemQr({
                  ...itemQr,
                  invId: row.invId,
                  rucempresa: row.invEmpresa,
                });
                setopenQr(true);
              },
              disabled: false,
              Icon: QrCode,
              color: "primary",
              id: 3,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/inventarioindividual?invId=${row.invId}&rucempresa=${row.invEmpresa}&usuario=${usuario.usuario}&nombre=${usuario.nombre}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 4,
              ocultar: row.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "medium",
              sx: butonIconTransition,
            },
            {
              tooltip: "Estado",
              texto: "",
              funcion: () => {},
              disabled: false,
              Icon: "",
              color: "primary",
              id: 5,
              ocultar: row.eliminado,
              tipo: "check",
              estado: row.invEstado === "ACTIVO" || row.invEstado === "ACTIVA",
              variante: "",
              size: "medium",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          {row.invTipo === "VESTIMENTAS" || row.invTipo === "UTILITARIOS" ? (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invTipo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invSerie}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invMarca}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invModelo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invStock}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invTalla}
              </TableCell>

              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(row.invFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>
              <CeldaUser user={row.invUser} claseCelda={claseCelda} />
            </>
          ) : null}
          {row.invTipo === "ARMAS" ? (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invSerie}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invMarca}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invModelo}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invDisponible}
              </TableCell>{" "}
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invTipoArma}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(row.invFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>
              <CeldaUser user={row.invUser} claseCelda={claseCelda} />
            </>
          ) : null}
          {row.invTipo === "MUNICIONES" ? (
            <>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invSerie}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invNombre}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invMarca}
              </TableCell>
              <TableCell size="small" align="center" className={claseCelda}>
                {row.invStock}
              </TableCell>

              <TableCell size="small" align="center" className={claseCelda}>
                {new Date(row.invFecReg).toLocaleString("es-ES", {
                  weekday: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hour: "2-digit",
                })}
              </TableCell>

              <CeldaUser user={row.invUser} claseCelda={claseCelda} />
            </>
          ) : null}
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
