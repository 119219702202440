import React, { memo, useContext, useEffect } from "react";
import { useState } from "react";
import { withRouter } from "react-router";

import ButtonsAction from "./ButtonsAction";
import AlertaContext from "../../../../../Context/Alerta/AlertaContext";
import clienteAxios from "../../../../../config/axios";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  TableCell,
  TableRow,
  TextField,
  FormControl,
  Autocomplete,
} from "@mui/material";
import ConfirmacionDialog from "../../../../components/ConfirmacionDialog";
import { Cancel, DeleteForever } from "@mui/icons-material";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  celda: {
    fontSize: ".7rem !important",
    "&:hover": {
      opacity: ".7",
      cursor: "pointer",
    },
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowArma = (props) => {
  const { row, index, campoExport, item, setitem, deshabilitarbotones } = props;
  const [itemRow, setItemRow] = useState(row);
  const { mostrarAlerta } = useContext(AlertaContext);
  const [clientesData, setclientesData] = useState([]);
  useEffect(() => {
    setItemRow(row);
    // eslint-disable-next-line
  }, [item]);
  const classes = useRowStyles();
  const handleEliminar = async (_id) => {
    setitem({
      ...item,
      perBonos: item.perBonos.filter((item, itemIndex) => {
        return index !== itemIndex;
      }),
    });
  };
  const funcionEdit = () => {
    seteditar(true);
  };
  const [editar, seteditar] = useState(false);
  const funcCancel = () => {
    setItemRow(row);
    seteditar(false);
  };
  const botonDisabled = () => {
    if (itemRow.nombre === "" || itemRow.valor === "") {
      return true;
    } else {
      return false;
    }
  };
  const funcSubmit = () => {
    setitem({
      ...item,
      perBonos: item.perBonos.map((item, itemIndex) => {
        if (index === itemIndex) {
          return itemRow;
        } else {
          return item;
        }
      }),
    });
    seteditar(false);
  };
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  return (
    <React.Fragment>
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />

      <TableRow className={classes.root} selected={index % 2 === 0}>
        {/* boton */}
        <ButtonsAction
          setitem={setitem}
          index={index}
          itemRow={itemRow}
          row={itemRow}
          item={item}
          setopenConfirmDialog={setopenConfirmDialog}
          funcionEdit={funcionEdit}
          ocultarEditar={false}
          ocultarDelete={false}
          mostrarEstado={false}
          campoExport={campoExport}
          campoEstado="estado"
          funcCancel={funcCancel}
          funcSubmit={funcSubmit}
          editar={editar}
          deshabilitarbotones={deshabilitarbotones}
          botonDisabled={botonDisabled}
        />

        {editar ? (
          <>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <FormControl fullWidth>
                <Autocomplete
                  disableClearable={true}
                  options={clientesData.map((item) => {
                    return item.apoAporte;
                  })}
                  getOptionLabel={(option) => {
                    return option.replace("/+/", " ");
                  }}
                  id="controlled-demo"
                  value={itemRow.nombre}
                  paperprops
                  onChange={async (event, newValue) => {
                    if (newValue) {
                      const options = newValue.split("/+/");
                      setItemRow({ ...itemRow, nombre: options[0] });
                    } else {
                      setItemRow({ ...itemRow, nombre: "" });
                      setclientesData([]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      fullWidth
                      onChange={async (e) => {
                        if (e.target.value === "") {
                          return mostrarAlerta("Ingrese datos", "error");
                        }
                        try {
                          const resIngreso = await clienteAxios.get(
                            `/aportes/filtro/0?tipo=INGRESO&search=${e.target.value}`
                          );

                          setclientesData(resIngreso.data.data);
                        } catch (error) {
                          setItemRow({ ...itemRow, nombre: "" });
                          setclientesData([]);

                          return mostrarAlerta("No hay registros", "error");
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              <TextField
                id=""
                type={"number"}
                value={itemRow.valor}
                onChange={(e) =>
                  setItemRow({
                    ...itemRow,
                    valor: e.target.value,
                  })
                }
                size="small"
                fullWidth
              />
            </TableCell>
          </>
        ) : (
          <>
            {" "}
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.nombre}
            </TableCell>
            <TableCell
              padding="none"
              size="small"
              align="center"
              className={classes.celda}
            >
              {itemRow.valor}
            </TableCell>
          </>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(RowArma));
