// FUNCION QUE CREA EL OBJETO BUSCADOR
export const defaultFullBuscador = (columns) => {
  const json = {};
  columns.forEach((itemPropiedad) => {
    Object.entries({ itemPropiedad }).forEach((item) => {
      if (itemPropiedad.tipo === "string") {
        json[`${item[1].propiedad}`] = "";
      } else if (itemPropiedad.tipo === "numero") {
        json[`${item[1].propiedad}1`] = "";
        json[`${item[1].propiedad}2`] = "";
      } else if (itemPropiedad.tipo === "fecha") {
        json[`${item[1].propiedad}1`] = "";
        json[`${item[1].propiedad}2`] = "";
      }
    });
  });
  return json;
};
export const reducer = (previousValue, currentValue) =>
  Number(previousValue) + Number(currentValue);

export const sumarMinutos = (horaExistente, minutosASumar) => {
  const [hora, minutos] = horaExistente.split(":");
  const totalMinutosExistente = parseInt(hora) * 60 + parseInt(minutos);

  const totalMinutos = totalMinutosExistente + minutosASumar;
  let horas = Math.floor(totalMinutos / 60);
  let minutosRestantes = totalMinutos % 60;

  if (horas >= 24) {
    horas -= 24;
  }

  const horaFormateada = horas.toString().padStart(2, "0");
  const minutosFormateados = minutosRestantes.toString().padStart(2, "0");

  const resultado = horaFormateada + ":" + minutosFormateados;
  return resultado;
};
export const estaEntre = (hora1, hora2, hora3) => {
  const [h1, m1] = hora1.split(":");
  const [h2, m2] = hora2.split(":");
  const [h3, m3] = hora3.split(":");
  let tiempo1 = parseInt(h1) * 60 + parseInt(m1);
  let tiempo2 = parseInt(h2) * 60 + parseInt(m2);
  let tiempo3 = parseInt(h3) * 60 + parseInt(m3);

  if (tiempo2 < tiempo1) {
    tiempo2 += 24 * 60; // si la segunda hora es anterior a la primera, sumar 24 horas
  }

  if (tiempo3 < tiempo1) {
    tiempo3 += 24 * 60; // si la tercera hora es anterior a la primera, sumar 24 horas
  }

  return tiempo3 >= tiempo1 && tiempo3 <= tiempo2;
};

export const compararFechasPorDia = (fecha1, fecha2) => {
  var fecha = new Date(fecha1);
  var hoy = new Date(fecha2);

  if (
    fecha.getFullYear() > hoy.getFullYear() ||
    (fecha.getFullYear() === hoy.getFullYear() &&
      fecha.getMonth() > hoy.getMonth()) ||
    (fecha.getFullYear() === hoy.getFullYear() &&
      fecha.getMonth() === hoy.getMonth() &&
      fecha.getDate() >= hoy.getDate())
  ) {
    
  } else {
    return true;
  }
};
