import { useContext } from "react";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import AuthContext from "../../Context/Auth/AuthContext";
import MenuContext from "../../Context/Menu/MenuContext";

export const usePermiso = (component) => {
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  const alertaPermiso = (tipoPermiso) => {
    mostrarAlerta(`No tiene permiso para: ${tipoPermiso}`, "error");
  };

  const resPermiso = rows.find(
    (itemMenu) => itemMenu.sidInfo.component === component
  );
  const tienePermiso = (tipoPermiso) => {
    console.log({ resPermiso, tipoPermiso });

    const res = resPermiso.sidInfo[tipoPermiso].some((item) => {
      return usuario.rol.some((rol) => rol === item);
    });

    return res;
  };

  return { tienePermiso, alertaPermiso };
};
