import React, { memo, useState } from "react";

import { withRouter } from "react-router";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableCell,
  Tooltip,
} from "@mui/material";
import { Cancel, Check, ExpandLess, ExpandMore } from "@mui/icons-material";
import ModalEmail from "./ModalEmail";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const ButtonsAction = (props) => {
  const {
    arrayExport,
    setarrayExport,
    campoExport,
    row,

    mostrarEstado,
    campoEstado,
    editar,
    funcCancel,
    funcSubmit,
    botonDisabled,
    setexpandido,
    expandido,
    personasData,
    funcionReload,
    itemExport,
    setitemExport,
  } = props;
  const classes = useRowStyles();

  const [openModalEmail, setopenModalEmail] = useState(false);
  return (
    <>
      {" "}
      <ModalEmail open={openModalEmail} setopen={setopenModalEmail} row={row} />
      {arrayExport ? (
        <TableCell
          size="small"
          padding="checkbox"
          style={{
            borderRight: "1px solid #ccc",
            maxWidth: "1rem",
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <FormControlLabel
              className={classes.margin}
              control={
                <Checkbox
                  size="small"
                  className={classes.margin}
                  checked={
                    personasData.length === 0
                      ? false
                      : !personasData.some((item) => {
                          return !itemExport.hijos.some((itemPersonaData) => {
                            return itemPersonaData === item[campoExport];
                          });
                        })
                  }
                  onChange={async (e) => {
                    if (e.target.checked) {
                      if (personasData.length === 0 && !expandido) {
                        const response = await funcionReload(true);
                        const itemDeEsteRow = {
                          padre: row[campoExport],
                          // periodo: row.antPeriodo,
                          hijos: response.map(
                            (itemPersona) => itemPersona[campoExport]
                          ),
                        };

                        if (!itemExport.padre === "") {
                          const res = arrayExport.map((itemArray) => {
                            if (itemArray.padre === row[campoExport]) {
                              return itemDeEsteRow;
                            } else {
                              return itemArray;
                            }
                          });

                          setarrayExport(res);
                          setitemExport(itemDeEsteRow);
                        } else {
                          setarrayExport([...arrayExport, itemDeEsteRow]);
                          setitemExport(itemDeEsteRow);
                        }
                        return setexpandido(true);
                      }

                      if (itemExport.padre === "") {
                        const itemDeEsteRow = {
                          padre: row[campoExport],
                          // periodo: row.antPeriodo,
                          hijos: personasData.map(
                            (itemPersona) => itemPersona[campoExport]
                          ),
                        };
                        setarrayExport([...arrayExport, itemDeEsteRow]);
                        setitemExport(itemDeEsteRow);
                      } else {
                        const itemDeEsteRow = {
                          padre: row[campoExport],
                          // periodo: row.antPeriodo,
                          hijos: personasData.map(
                            (itemPersona) => itemPersona[campoExport]
                          ),
                        };
                        const res = arrayExport.map((itemArray) => {
                          if (itemArray.padre === row[campoExport]) {
                            return itemDeEsteRow;
                          } else {
                            return itemArray;
                          }
                        });

                        setarrayExport(res);
                        setitemExport(itemDeEsteRow);
                      }
                    } else {
                      setarrayExport(
                        arrayExport.filter((itemArray) => {
                          return itemArray.padre !== row[campoExport];
                        })
                      );
                      setitemExport({
                        padre: "",
                        hijos: [],
                      });
                    }
                  }}
                  color="secondary"
                />
              }
            />
          </Box>
        </TableCell>
      ) : null}
      <TableCell padding="none" style={{ borderRight: "1px solid #ccc" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          ml={1}
          mr={1}
        >
          <>
            {editar ? (
              <>
                <Tooltip title="Cancelar">
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      funcCancel();
                    }}
                  >
                    <Cancel fontSize="small" color="secondary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Confirmar">
                  <IconButton
                    className={classes.margin}
                    disabled={botonDisabled ? botonDisabled() : false}
                    onClick={() => {
                      funcSubmit();
                    }}
                  >
                    <Check
                      fontSize="small"
                      color={
                        botonDisabled
                          ? botonDisabled()
                            ? "inherit"
                            : "secondary"
                          : "secondary"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Expandir">
                  <IconButton
                    className={classes.margin}
                    onClick={() => {
                      setexpandido(!expandido);

                      if (personasData.length === 0 && !expandido) {
                        funcionReload(true);
                      }
                    }}
                  >
                    {expandido ? (
                      <ExpandLess color="secondary" />
                    ) : (
                      <ExpandMore color="secondary" />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
          {mostrarEstado ? (
                      <Tooltip title="Estado">
              <FormControlLabel
                className={classes.margin}
                control={
                  <Checkbox
                    value="ACTIVO"
                    className={classes.margin}
                    checked={
                      row[campoEstado] === "ACTIVA" ||
                      row[campoEstado] === "ACTIVO"
                        ? true
                        : false
                    }
                    color="primary"
                  />
                }
              />
            </Tooltip>
          ) : null}{" "}
          <Box display="flex" alignItems={"center"}>
            {/* <Tooltip title="Leidos">
              <IconButton
                aria-label=""
                className={classes.margin}
                onClick={() => setopenDialog(true)}
              >
                <FormatListNumbered fontSize="small" />
              </IconButton>
            </Tooltip> */}
            {/* {row.conLeidos.length === 0 ? null : (
              <Badge
                badgeContent={row.conLeidos.length}
                color="primary"
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                {row.conLeidos.length}
              </Badge>
            )} */}
          </Box>
        </Box>
      </TableCell>
    </>
  );
};

export default withRouter(memo(ButtonsAction));
