import { ImageListItem, ImageListItemBar, lighten } from "@material-ui/core";
import { OpenInBrowser } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  ImageList,
  ListItem,
  ListItemText,
} from "@mui/material";

export const IncomingMessage = ({ msg }) => {
  return (
    <ListItem disablePadding>
      <Avatar src={msg.foto} className="Avatar" />
      <Box className="BoxMessage bg_BoxMessage">
        <ListItemText
          className="ListItemText"
          primary={`${msg.nombres} - ${new Date(msg.msg_FecReg).toLocaleString(
            "es-ES",
            {
              year: "numeric",
              month: "short",
              day: "2-digit",
              minute: "2-digit",
              hour: "2-digit",
            }
          )}  `}
          primaryTypographyProps={{ fontStyle: "italic", fontSize: ".7rem" }}
          secondary={msg.message_text}
          secondaryTypographyProps={{
            color: (theme) => theme.palette.primary.contrastText,
            fontSize: ".9rem",
          }}
        />{" "}
        <ImageList sx={{ width: "350px" }} cols={1} rowHeight={"auto"}>
          {msg.message_fotos.map((fileNuevo) => {
            return (
              <ImageListItem key={fileNuevo}>
                <img
                  src={fileNuevo}
                  alt={"Archivo"}
                  loading="lazy"
                  style={{ minHeight: "50px", maxWidth: "350px" }}
                />
                <ImageListItemBar
                  sx={{
                    background: (theme) =>
                      lighten(theme.palette.secondary.dark, 0.5),
                  }}
                  title={"Adjunto"}
                  actionIcon={
                    <IconButton
                      aria-label="Abrir en el navegador"
                      color="secondary"
                      onClick={() => window.open(fileNuevo)}
                      size="small"
                    >
                      <OpenInBrowser fontSize="small" />
                    </IconButton>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Box>
    </ListItem>
  );
};
