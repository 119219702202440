import React, { memo, useContext } from "react";

import { TableCell, TableRow, TextField } from "@mui/material";

import { useState } from "react";
import { withRouter } from "react-router";
import ButtonsAction from "../../components/ButtonsAction";
import AuthContext from "../../../Context/Auth/AuthContext";
import { Cancel, Check } from "@mui/icons-material";
import { butonIconTransition, useRowStyles } from "../../styles/stylesRows";
import { FechaExacta, trimPropsItem } from "../../../config/const";
import { objectDefault } from "../data";
import CampoDoc from "../../components/CampoDoc";

const Row = (props) => {
  // CLASES
  const classes = useRowStyles();

  // PROPS
  const { setopen, socket, estadoRequest } = props;
  const [cargando, setcargando] = useState(false);
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // COPIA DEL REGISTRO PARA EDITAR

  const [item, setitem] = useState(objectDefault);
  //onChange
  const handleChange = (e) => {
    setitem({ ...item, [e.target.name]: e.target.value });
  };
  // DESHABILITAR BOTON
  const botonDisabled = () => {
    if (
      item.planNombre.trim() === "" ||
      item.planYear.trim() === "" ||
      item.planFec1.trim() === "" ||
      item.planFec2.trim() === "" ||
      item.planVersion.trim() === ""
    ) {
      return true;
    }
  };
  // ELIMINAR EL REGISTRO
  const funcSubmit = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...item,
          nombre: usuario.nombre,
          tabla: "planificacion",
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          planUser: usuario.usuario,
          planEmpresa: usuario.rucempresa,
          planTipo: estadoRequest,
          planIdEmisor: usuario.id, // login id
          planNombreEmisor: usuario.nombre, // login nombre
        })
      );
      setitem(objectDefault);
      setopen(false);
    } catch (error) {}
  };
  return (
    <React.Fragment>
      <TableRow className={classes.root} component={"form"}>
        {/* boton */}
        <TableCell
          size="small"
          align="center"
          className={classes.celda}
        ></TableCell>
        <ButtonsAction
          botones={[
            {
              tooltip: "Cancelar",
              texto: "",
              funcion: () => {
                setopen(false);
                setitem(objectDefault);
                // setItemEdit(itemHijo);
              },
              disabled: false,
              Icon: Cancel,
              color: "error",
              id: 1,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Guardar",
              texto: "",
              funcion: () => {
                funcSubmit();
              },
              disabled: botonDisabled() || cargando,
              Icon: Check,
              color: "success",
              id: 2,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
              submit: true,
            },
          ]}
        />
        <>
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <CampoDoc
              cargando={cargando}
              setcargando={setcargando}
              propiedad={"planDocumentoCabecera"}
              item={item}
              setitem={setitem}
              celda={true}
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              value={item.planNombre}
              name="planNombre"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              type={"number"}
              inputProps={{ min: FechaExacta }}
              value={item.planYear}
              name="planYear"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              type={"date"}
              inputProps={{ min: FechaExacta }}
              value={item.planFec1}
              name="planFec1"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              type={"date"}
              inputProps={{ min: FechaExacta }}
              value={item.planFec2}
              name="planFec2"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            <TextField
              value={item.planVersion}
              name="planVersion"
              onChange={(e) => handleChange(e)}
              size="small"
              fullWidth
            />
          </TableCell>
          <TableCell size="small" align="center" className={classes.celda}>
            {usuario.usuario}
          </TableCell>
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
