import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import MuiImageSlider from "mui-image-slider";

// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  IconButton,
  ListItem,
  ListItemText,
  Avatar,
  Badge,
  Collapse,
  Box,
  Divider,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
const ItemLista = (props) => {
  const [openCollapse, setopenCollapse] = useState(false);
  const { item, actAsunto } = props;

  const useStyles = makeStyles((theme) => ({
    formD: {
      boxShadow: "0px 0px 1px 1px #e1e1e1",
      flexGrow: 1,
      margin: theme.spacing(1.5),
      width: "45%",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        margin: theme.spacing(1),
        width: "95%",
        "&:last-of-type": {
          marginBottom: "0",
        },
      },
    },
    formAllW: {
      flexGrow: 1,
      padding: theme.spacing(0.25),
      margin: theme.spacing(0.25),
      width: "95%",
      [theme.breakpoints.down("md")]: {
        padding: "0",
        margin: "2px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        margin: "2px",
      },
    },
    imagenSlider: {
      maxWidth: "1rem",
    },
    textoCard: {
      flexBasis: "100%",
      [theme.breakpoints.down("sm")]: {
        flexBasis: "30%",
      },
    },
    textoCardSize: {
      fontSize: "5rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: ".5rem",
      },
    },
  }));
  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <>
      <ListItem style={{ paddingLeft: "1rem" }} key={item.perId}>
        {actAsunto === "RONDAS" ? (
          <>
            <Badge
              badgeContent={
                Object.values(item.trabajos).flatMap((itemT) => {
                  return itemT;
                }).length
              }
              color="secondary"
            >
              <IconButton
                size="small"
                onClick={() => setopenCollapse(!openCollapse)}
              >
                {openCollapse ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Badge>
            <ListItemText primary={item.nombres + " ** " + item.docnumero} />
            <Avatar alt={`Avatar: ${item.nombres} `} src={item.perFoto} />
          </>
        ) : (
          <>
            <Badge
              badgeContent={
                Object.values(item.trabajos).flatMap((itemT) => {
                  return itemT;
                }).length
              }
              color="secondary"
            >
              <IconButton
                size="small"
                onClick={() => setopenCollapse(!openCollapse)}
              >
                {openCollapse ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Badge>
            <ListItemText primary={item.nombres + " ** " + item.docnumero} />
            <Avatar alt={`Avatar: ${item.nombres} `} src={item.perFoto} />
          </>
        )}
      </ListItem>{" "}
      <Divider />
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Box display="flex" justifyContent="flex-start" flexWrap={"wrap"}>
          {actAsunto === "RONDAS" ? (
            <>
              {Object.entries(item.trabajos).flatMap((itemTRONDAS, index) => {
                return (
                  <>
                    <Typography
                      variant="button"
                      color="primary"
                      style={{ width: "100%", margin: ".5rem 0 0 0" }}
                      textAlign="center"
                    >
                      {itemTRONDAS[0].replace("ronda", "RONDA ")}
                    </Typography>
                    {itemTRONDAS[1].map((itemT) => {
                      return (
                        <Box display="flex" className={classes.formD}>
                          {itemT.fotos.length === 0 ? null : (
                            <MuiImageSlider
                              images={itemT.fotos.map((item) => item.url)}
                              fitToImageHeight={true}
                              classes={{
                                img: { width: "50%", height: "5rem" },
                                root: {
                                  flexBasis: "30%",
                                },
                              }}
                            />
                          )}

                          <Box p={2} className={classes.textoCard}>
                            <Typography
                              variant="subtitle1"
                              fontWeight={"700"}
                              color="primary"
                              component={"p"}
                              fontSize={".85rem"}
                            >
                              Fecha:
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="secundary"
                              component={"p"}
                              fontSize={".85rem"}
                            >
                              {itemT.fecha}
                            </Typography>{" "}
                            <Typography
                              variant="subtitle1"
                              fontWeight={"700"}
                              color="primary"
                              component={"p"}
                              fontSize={".85rem"}
                            >
                              Ronda:
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="secundary"
                              component={"p"}
                              fontSize={".85rem"}
                            >
                              {itemTRONDAS[0]}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              fontWeight={"700"}
                              color="primary"
                              component={"p"}
                              fontSize={".85rem"}
                            >
                              Detalle:
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="secundary"
                              component={"p"}
                              fontSize={".85rem"}
                            >
                              {itemT.detalle}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </>
                );
              })}
            </>
          ) : (
            <>
              {item.trabajos.map((itemT) => {
                return (
                  <Box display="flex" className={classes.formD}>
                    {itemT.fotos.length === 0 ? null : (
                      <MuiImageSlider
                        images={itemT.fotos.map((item) => item.url)}
                        fitToImageHeight={true}
                        classes={{
                          img: { width: "50%", height: "5rem" },
                          root: {
                            flexBasis: "30%",
                          },
                        }}
                      />
                    )}

                    <Box p={2} className={classes.textoCard}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={"700"}
                        color="primary"
                        component={"p"}
                        fontSize={".85rem"}
                      >
                        Fecha:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="secundary"
                        component={"p"}
                        fontSize={".85rem"}
                      >
                        {itemT.fecha}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        fontWeight={"700"}
                        color="primary"
                        component={"p"}
                        fontSize={".85rem"}
                      >
                        Detalle:
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        color="secundary"
                        component={"p"}
                        fontSize={".85rem"}
                      >
                        {itemT.detalle}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </>
          )}
        </Box>{" "}
        <Divider style={{ height: ".5rem" }} />
      </Collapse>{" "}
    </>
  );
};

export default ItemLista;
