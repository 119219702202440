import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { AddCircle, Delete } from "@mui/icons-material";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import MuiPhoneNumber from "material-ui-phone-number";
import { isValidNumber } from "libphonenumber-js";
import { validarEmail } from "../../../config/validaciones";
import ModalLayout from "./ModalLayout";
import { useModalStyle } from "../../styles/styleModal";

export const ModalContacto = ({
  open,
  setopen,
  newContacto,
  setNewContacto,
  contactoDefault,
  row,
}) => {
  const clases = useModalStyle();
  const [cargando, setcargando] = useState(false);
  const { mostrarAlerta } = useContext(AlertaContext);
  const onDefault = () => {
    setopen(false);
    setNewContacto(contactoDefault);
  };
  return (
    <ModalLayout
      open={open}
      defaultActive={onDefault}
      titulo={`Medio de comunicación: ${newContacto.medioComunicacion}`}
      id="ModalComunicacion"
    >
      <Box className={clases.container}>
        <FormControl className={clases.formAllW}>
          <Typography variant="subtitle1" color="primary">
            Ruc Cliente:{" "}
            <Typography variant="subtitle1" color="secondary" component="span">
              {row.venRucCliente}
            </Typography>
          </Typography>
        </FormControl>
        <FormControl className={clases.formAllW}>
          <Typography variant="subtitle1" color="primary">
            Cliente:{" "}
            <Typography variant="subtitle1" color="secondary" component="span">
              {row.venNomCliente}
            </Typography>
          </Typography>
        </FormControl>
        <FormControl className={clases.formAllW}>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" color="primary">
              Contactos:
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                setNewContacto({
                  ...newContacto,
                  contacto: ["", ...newContacto.contacto],
                });
              }}
            >
              <AddCircle fontSize="small" color="secondary" />
            </IconButton>
          </Box>
        </FormControl>
        {newContacto.contacto.map((contacto, index) => {
          if (newContacto.medioComunicacion === "Correo") {
            return (
              <TextField
                className={clases.formD}
                value={contacto}
                onChange={(e) => {
                  setNewContacto({
                    ...newContacto,
                    contacto: newContacto.contacto.map(
                      (contacto, indexItem) => {
                        if (indexItem === index) {
                          return e.target.value;
                        } else {
                          return contacto;
                        }
                      }
                    ),
                  });
                }}
                type="email"
                label={`${
                  newContacto.medioComunicacion === "Correo"
                    ? "Correos"
                    : "Contactos"
                } #${index}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => {
                          setNewContacto({
                            ...newContacto,
                            contacto: newContacto.contacto.filter(
                              (contacto, indexItem) => {
                                return indexItem !== index;
                              }
                            ),
                          });
                        }}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            );
          }
          return (
            <MuiPhoneNumber
              defaultCountry={"ec"}
              regions={"america"}
              className={clases.formD}
              value={contacto}
              onChange={(e) => {
                setNewContacto({
                  ...newContacto,
                  contacto: newContacto.contacto.map((contacto, indexItem) => {
                    if (indexItem === index) {
                      return e;
                    } else {
                      return contacto;
                    }
                  }),
                });
              }}
              type="tel"
              label={`${
                newContacto.medioComunicacion === "Correo"
                  ? "Correos"
                  : "Contactos"
              } #${index}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      onClick={() => {
                        setNewContacto({
                          ...newContacto,
                          contacto: newContacto.contacto.filter(
                            (contacto, indexItem) => {
                              return indexItem !== index;
                            }
                          ),
                        });
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          );
        })}
      </Box>

      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="100%" display="flex" justifyContent="flex-end" mt={1}>
          <Button
            style={{ width: "30%" }}
            variant="contained"
            color="primary"
            disabled={cargando}
            onClick={async () => {
              try {
                if (
                  newContacto.contacto.some(
                    ((contact) => contact === "" || !isValidNumber(contact)) ||
                      newContacto.contacto.length === 0
                  ) &&
                  newContacto.medioComunicacion === "Whatsapp"
                ) {
                  return mostrarAlerta("Error en los contactos", "error");
                }
                if (
                  (newContacto.contacto.some(
                    (contact) => contact === "" || validarEmail(contact)
                  ) ||
                    newContacto.contacto.length === 0) &&
                  newContacto.medioComunicacion === "Correo"
                ) {
                  return mostrarAlerta("Error en los contactos", "error");
                }
                setcargando(true);
                const res = await clienteAxios.post("/ventas/envio_correo", {
                  id: row.venId,
                  correos: newContacto.contacto,
                });

                mostrarAlerta(res.data.msg, "success");
                setcargando(false);
              } catch (error) {
                console.log({ error });
                mostrarAlerta("Hubo un error", "error");
                setcargando(false);
              }
            }}
          >
            Enviar
          </Button>
        </Box>
      </Box>
    </ModalLayout>
  );
};
