import {
  Box,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
} from "@mui/material";
import React from "react";
import DialogMap from "../../../components/DialogMap";
import { useState } from "react";
import {
  Cancel,
  Check,
  Close,
  Delete,
  Map,
  Restore,
} from "@mui/icons-material";
import { useMemo } from "react";

const Actividad = (props) => {
  const { eveActividad, actEveDatos, eliminado, setitem } = props;
  const [openMap, setopenMap] = useState(false);
  const eliminar = () => {
    setitem((prev) => {
      return {
        ...prev,
        actEveDatos: {
          ...prev.actEveDatos,
          eveActividades: prev.actEveDatos.eveActividades.filter(
            (actividadMap) => actividadMap.id !== eveActividad.id
          ),
          eveActividadesEliminadas: actEveDatos.eveActividadesEliminadas
            ? [eveActividad, ...prev.actEveDatos.eveActividadesEliminadas]
            : [eveActividad],
        },
      };
    });
  };
  const restaurar = () => {
    setitem((prev) => {
      return {
        ...prev,
        actEveDatos: {
          ...prev.actEveDatos,
          eveActividadesEliminadas: prev.actEveDatos.eveActividadesEliminadas.filter(
            (actividadMap) => actividadMap.id !== eveActividad.id
          ),
          eveActividades: actEveDatos.eveActividades
            ? [...prev.actEveDatos.eveActividades, eveActividad]
            : [eveActividad],
        },
      };
    });
  };

  const colorError = useMemo(
    () =>
      eveActividad.horario.length === 0 &&
      actEveDatos.evePerfil !== "SUPERVISOR",
    // eslint-disable-next-line
    [eveActividad]
  );
  return (
    <DialogContent dividers>
      {openMap ? (
        <DialogMap
          open={openMap}
          setopen={setopenMap}
          setitem={() => {}}
          item={eveActividad}
          editar={true}
          propiedad={eveActividad.coordenadas}
          nombrePropiedad={"coordenadas"}
          solover={true}
        />
      ) : null}
      <DialogContentText>
        <Box
          key={eveActividad.id}
          display="flex"
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Box display="flex" alignItems={"center"}>
            <Typography
              variant="body1"
              color={colorError ? "error" : "secondary"}
              fontWeight={"bold"}
            >
              Nombre:{" "}
              <Typography
                variant="body1"
                color={colorError ? "error" : "secondary"}
                component={"span"}
              >
                {eveActividad.nombre}
              </Typography>
            </Typography>
            <IconButton
              color={eliminado ? "success" : "error"}
              onClick={() => {
                eliminado ? restaurar() : eliminar();
              }}
            >
              {eliminado ? <Restore /> : <Delete />}
            </IconButton>
          </Box>

          <Box ml={1} mr={1}></Box>
          {actEveDatos.eveTipo === "RONDAS" ? (
            <Typography
              variant="body1"
              color={colorError ? "error" : "secondary"}
              fontWeight={"bold"}
            >
              Coordenadas:{" "}
              <Typography
                variant="body1"
                color={colorError ? "error" : "secondary"}
                component={"span"}
              >
                <IconButton aria-label="" onClick={() => setopenMap(true)}>
                  <Map />
                </IconButton>
              </Typography>
            </Typography>
          ) : null}
        </Box>
        <Box display="flex" justifyContent={"center"} width={"100%"}>
          <Typography
            variant="body1"
            color={colorError ? "error" : "primary"}
            fontWeight={"bold"}
          >
            H. Inicio
          </Typography>
          <Box ml={1} mr={1}></Box>
          <Typography
            variant="body1"
            color={colorError ? "error" : "primary"}
            fontWeight={"bold"}
          >
            H. Final
          </Typography>
        </Box>
        {eveActividad.horario.map((itemHorario) => (
          <Box
            display="flex"
            justifyContent={"center"}
            width={"100%"}
            alignItems={"center"}
          >
            <IconButton
              color={itemHorario.eliminado ? "error" : "success"}
              onClick={() => {
                setitem((prev) => {
                  return {
                    ...prev,
                    actEveDatos: {
                      ...prev.actEveDatos,
                      eveActividades: actEveDatos.eveActividades.map(
                        (actividadMap) =>
                          actividadMap.id === eveActividad.id
                            ? {
                                ...actividadMap,
                                horario: actividadMap.horario.map(
                                  (horarioAct) =>
                                    horarioAct.id === itemHorario.id
                                      ? {
                                          ...horarioAct,
                                          eliminado: !itemHorario.eliminado,
                                        }
                                      : horarioAct
                                ),
                              }
                            : actividadMap
                      ),
                    },
                  };
                });
              }}
            >
              {itemHorario.eliminado ? <Close /> : <Check />}
            </IconButton>
            <Typography
              variant="body1"
              color={itemHorario.eliminado ? "error" : "secondary"}
              sx={{
                textDecoration: itemHorario.eliminado ? "line-through" : "",
              }}
            >
              {itemHorario.hora}
            </Typography>
            <Box ml={1} mr={1}></Box>
            <Typography
              variant="body1"
              color={itemHorario.eliminado ? "error" : "secondary"}
              sx={{
                textDecoration: itemHorario.eliminado ? "line-through" : "",
              }}
            >
              {itemHorario.horaFinal}
            </Typography>
          </Box>
        ))}
      </DialogContentText>
    </DialogContent>
  );
};

export default Actividad;
