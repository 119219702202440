import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import React from "react";
import ToolBarTable from "../../../../components/ToolBarTable";
import { useSimpleTable } from "../../../../hooks/useSimpleTable";
import TablaCabecera from "../../../../components/TablaCabecera";
import RowAgregarPrenda from "./RowAgregarPrenda";
import RowPrenda from "./RowPrenda";
import { AddCircle } from "@mui/icons-material";

const Prenda = (props) => {
  const { item, setitem } = props;
  const {
    agregandoPrenda,
    setagregandoPrenda,
    pagePrenda,
    // setPagePrenda,
    rowsPerPagePrenda,
    // setRowsPerPagePrenda,
    handleChangePagePrenda,
    handleChangeRowsPerPagePrenda,
  } = useSimpleTable("Prenda");

  return (
    <Paper sx={{ width: "100%" }}>
      <ToolBarTable
        count={item.iopPrendas.length}
        rowsPerPage={rowsPerPagePrenda}
        page={pagePrenda}
        handleChangePage={handleChangePagePrenda}
        handleChangeRowsPerPage={handleChangeRowsPerPagePrenda}
        ocultarPaginacion={false}
        simplePagination={true}
        botonesIzquierdos={[
          {
            tooltip: "Agregar Prenda Cliente",
            texto: "Agregar Prenda Cliente",
            funcion: () => {
              setagregandoPrenda(true);
            },
            disabled: false,
            Icon: AddCircle,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[]}
      />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TablaCabecera
              columns={[
                {
                  id: "item",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "item",
                },
                {
                  id: "cantidad",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "cantidad",
                },
                {
                  id: "detalle",
                  align: "center",
                  minWidth: 100,
                  tipo: "string",
                  label: "detalle",
                },
              ]}
              habilitarOrdenar={false}
            />
          </TableHead>

          <TableBody component={"div"}>
            {agregandoPrenda ? (
              <RowAgregarPrenda
                setagregar={setagregandoPrenda}
                setitemPadre={setitem}
                itemPadre={item}
              />
            ) : null}
            {item.iopPrendas
              .slice(
                pagePrenda * rowsPerPagePrenda,
                pagePrenda * rowsPerPagePrenda + rowsPerPagePrenda
              )
              .map((rowHijo, index) => {
                return (
                  <RowPrenda
                    setitemPadre={setitem}
                    itemPadre={item}
                    rowHijo={rowHijo}
                    index={index}
                    key={rowHijo.id}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Prenda;
