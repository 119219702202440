import React, { memo, useState, useContext, useRef } from "react";

import { Box, Button, Divider } from "@mui/material";

import Tabla from "./Componentes/Tabla";
import BarraTablas from "../../components/BarraTablas";
import clienteAxios from "../../../config/axios";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import ModalB from "../../components/ModalB";
import DialogOpciones from "../../components/DialogOpciones";
import { useCrud } from "../../hooks/useCrud";
// COLUMNAS

import { columns, objectDefault } from "./data";
// FUNCION QUE CREA EL OBJETO BUSCADOR
import { defaultFullBuscador } from "../../functions/funciones";
import { Update } from "@mui/icons-material";

const Ausencia = () => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);
  // ABRIR MODAL
  // eslint-disable-next-line
  const [open, setopen] = useState(false);

  // BOOLEANO DE SI SE ESTA BUSCANDO O NO
  const [buscando, setbuscando] = useState(false);
  // SI SE ESTA EDITANDO O NO
  // eslint-disable-next-line
  const [editar, seteditar] = useState(false);
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // BOLLEANO DE SI ESTA ABIERTO EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // OBJETO ACTIVO O PLANTILLA
  // eslint-disable-next-line
  const [ObjectoActivo, setObjetoActivo] = useState(objectDefault);
  // NOMBRE DE LA TABLA
  const [tabla] = useState("anticipo");
  // ESTADO O TAB
  const [estadoRequest, setestadoRequest] = useState("GUARDIA");
  // STRING DE BUSQUEDA
  const [search, setsearch] = useState("");
  // CAMPO POR EL CUAL SE FILTRAN LOS REGISTROS PARA EXPORTAR
  const [campoExport] = useState("act_asigId");
  // ARRAY DE EXPORTACION
  const [arrayExport, setarrayExport] = useState({});
  // CAMPOS DE BUSQUEDA EXPANDIDOS
  const [openMB, setopenMB] = useState(true);

  // OBJETO BUSCADOR
  const [fullBuscador, setfullBuscador] = useState(
    defaultFullBuscador(columns)
  );
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
  });

  // HOOK PARA FUNCIONES DEL CRUD
  const { eliminarState } = useCrud(
    rows,
    setrows,
    campoExport,
    "antUser",
    usuario
  );
  // REFERENCIA DEL SOCKET
  const socket = useRef();

  // EVENTO QUE CAMBIA LA DATA DEL OBJETO BUSCADOR
  const handleChange = (e) => {
    setfullBuscador({ ...fullBuscador, [e.target.name]: e.target.value });
  };
  // CAMBIA EL OBJETO ACTIVO DEL STATE GENERALMENTE PARA EDITAR
  const changeObjetoActivoState = (item) => {
    setObjetoActivo(item);
  };
  // FUNCION QUE OBTIENE LA DATA DE LA TABLA
  const getDataState = async (
    cantidad,
    page,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    grupo,
    cliente,
    fecha,
    subEstado
  ) => {
    try {
      const res = await clienteAxios.get(
        `/actividades_asignadas/?cantidad=${cantidad}&page=${page}&search=${
          search ? search : ""
        }&input=${input ? input : ""}&orden=${orden ? orden : ""}&datos=${
          datos ? datos : ""
        }&rucempresa=${rucempresa ? rucempresa : ""}&perfil=${
          estado ? estado : ""
        }&grupo=${grupo ? grupo : ""}&id_persona=${
          cliente ? cliente : ""
        }&fecha=${fecha ? fecha : ""}&estado=${subEstado ? subEstado : " "}`
      );

      return res;
    } catch (error) {
      mostrarAlerta("Hubo un error");
    }
  };
  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    grupo,
    fecha,
    subEstado
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      grupo,
      fecha,
      subEstado
    );
    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // FUNCION DE BUSQUEDA AVANZADA
  const funcionB = async () => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre"
      // fecha
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  // FUNCION PARA CAMBIAR PAGINA
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        "padre"
        // fecha
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row[campoExport] === item[campoExport];
        });

        return !existe;
      });

      setrows([...rows, ...resultado]);
    }
    setPage(newPage);
  };

  // eslint-disable-next-line
  // FUNCION QUE CAMBIA LOS REGISTROS O FILAS POR PAGINA
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre"
      // fecha
    );
  };
  // FUNCION QUE ORDENA DESCENDENTE O ASCENDENTEMENTE
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre"
      // fecha
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  // REFRES SEARCH SIN ALTERAR
  const funcionReload = async () => {
    setcargando(true);
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre"
      // fecha
    );
    setcargando(false);
  };

  // FUNCION QUE PONE LA DATA DE POR DEFECTO O HACE UN REFRESH SEARCH POR DEFECTO
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador(columns);
    setfullBuscador(fullBRes);
    await obtenerState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest,
      "padre"
      // fecha
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };

  return (
    <Box>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[]}
        titulo={"Mas Opciones"}
      />
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por todos los campos"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={false}
        botones={[
          {
            tooltip: "PROCESAR ACTIVIDADES",
            texto: "PROCESAR ACTIVIDADES",
            funcion: async () => {
              try {
                const res = await clienteAxios.post(
                  "/actividades_asignadas/incumplidas"
                );
                mostrarAlerta(res.data.msg);
                funcionDefault();
              } catch (error) {
                mostrarAlerta(error.response?.msg, "error");
              }
            },
            disabled: false,
            Icon: Update,
            color: "error",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
        ]}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada (${estadoRequest})`}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columns}
        handleChange={handleChange}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      >
        <Box>
          {["GUARDIA", "SUPERVISOR", "ADMINISTRACION"].map((itemEstado) => (
            <Button
              size="small"
              variant={estadoRequest === itemEstado ? "contained" : null}
              disabled={cargando}
              onClick={async () => {
                setcargando(true);
                await obtenerState(
                  rowsPerPage,
                  0,
                  "",
                  input.id,
                  orden,
                  JSON.stringify(fullBuscador),
                  rucempresa,
                  itemEstado,
                  "padre"
                );

                setPage(0);
                setestadoRequest(itemEstado);
                setcargando(false);
              }}
              disableElevation
              style={{ padding: ".5rem", borderRadius: "15px 15px  0px 0px" }}
              color="primary"
            >
              {itemEstado}
            </Button>
          ))}
        </Box>
      </Box>
      <Divider />
      <Tabla
        // PAGINAS Y PAGINACION
        page={page}
        rowsPerPage={rowsPerPage}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        // OPEN Y EDIT
        setopen={setopen}
        seteditar={seteditar}
        // ORDEN
        input={input}
        orden={orden}
        // BUSCAR
        search={search}
        fullBuscador={fullBuscador}
        // EXPORTAR
        arrayExport={arrayExport}
        setarrayExport={setarrayExport}
        campoExport={campoExport}
        // CARGANDO
        cargando={cargando}
        setcargando={setcargando}
        // DATA
        rows={rows}
        columns={columns}
        estadoRequest={estadoRequest}
        // FUNCIONES DATA
        getDataState={getDataState}
        obtenerState={obtenerState}
        changeObjetoActivoState={changeObjetoActivoState}
        funcionOrdenar={funcionOrdenar}
        funcionReload={funcionReload}
        eliminarState={eliminarState}
        // SOCKET
        tabla={tabla}
        socket={socket}
      />
    </Box>
  );
};

export default memo(Ausencia);
