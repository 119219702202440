import React, { useContext, useState } from "react";
// CONTEXT
import MenuContext from "../../../Context/Menu/MenuContext";
import { List } from "@mui/material";
import AuthContext from "../../../Context/Auth/AuthContext";
import { withRouter } from "react-router-dom";
import Categoria from "./Categorias/Categoria";

const Sidebar = (props) => {
  // CONTEXT
  const { rows } = useContext(MenuContext);
  const { usuario } = useContext(AuthContext);
  //   PROPS
  const { open, mobile, handleDrawerToggle, buscador } = props;
  // STATE
  const [openExpand, setOpenExpand] = useState({});

  // FUNCIONES
  const handleClick = (e, a) => {
    setOpenExpand({
      ...openExpand,
      [e]: !a,
    });
  };

  function activeRoute(routeName) {
    return props.history.location.pathname.indexOf(routeName) > -1
      ? true
      : false;
  }

  const bloquearFuncion = (item) => {
    if (item.sidInfo.bloquearAreaDepartamento === "SI") {
      const bloquear = item.sidInfo.areasDepartamento.some(
        (itemArea) => itemArea === usuario.areadepartamento
      );

      if (bloquear) {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <>
      <List sx={{ p: 0, pt: 1 }} component="nav">
        {/* //configuracion */}
        {rows.map((categoria, index) => {
          // validacion categoria.sidInfo
          if (categoria.sidInfo.tipo !== "categoria") {
            return null;
          }

          if (bloquearFuncion(categoria)) {
            return null;
          }

          const buscadorActivo = buscador.trim() !== "";
          const subCategorias = rows
            .filter((subCategoria) => {
              if (bloquearFuncion(subCategoria)) {
                return false;
              }
              const subSubCategorias = !subCategoria.sidInfo.expandible
                ? []
                : rows.filter((subSubCategoria) => {
                    if (bloquearFuncion(subSubCategoria)) {
                      return false;
                    }
                    if (buscadorActivo) {
                      return (
                        subSubCategoria.sidInfo.subcategoria ===
                          subCategoria.sidInfo.path &&
                        subSubCategoria.sidInfo.name
                          .toLowerCase()
                          .includes(buscador.toLowerCase().trim())
                      );
                    } else {
                      return (
                        subSubCategoria.sidInfo.subcategoria ===
                        subCategoria.sidInfo.path
                      );
                    }
                  });

              if (buscadorActivo) {
                return (
                  subCategoria.sidInfo.categoria === categoria.sidInfo.path &&
                  (subCategoria.sidInfo.name
                    .toLowerCase()
                    .includes(buscador.toLowerCase().trim()) ||
                    subSubCategorias.length !== 0)
                );
              } else {
                return (
                  subCategoria.sidInfo.categoria === categoria.sidInfo.path
                );
              }
            })
            .map((subCategoria) => {
              const subSubCategorias = !subCategoria.sidInfo.expandible
                ? []
                : rows.filter((subSubCategoria) => {
                    if (bloquearFuncion(subSubCategoria)) {
                      return false;
                    }

                    if (buscadorActivo) {
                      return (
                        subSubCategoria.sidInfo.subcategoria ===
                          subCategoria.sidInfo.path &&
                        subSubCategoria.sidInfo.name
                          .toLowerCase()
                          .includes(buscador.toLowerCase().trim())
                      );
                    } else {
                      return (
                        subSubCategoria.sidInfo.subcategoria ===
                        subCategoria.sidInfo.path
                      );
                    }
                  });
              return {
                ...subCategoria,
                subSubCategorias,
              };
            });
          if (buscadorActivo) {
            if (
              categoria.sidInfo.name
                .toLowerCase()
                .includes(buscador.toLowerCase().trim()) ||
              subCategorias.length !== 0
            ) {
              return (
                <Categoria
                  subCategorias={subCategorias}
                  openExpand={openExpand}
                  setOpenExpand={setOpenExpand}
                  categoria={categoria}
                  key={categoria.sidId}
                  mobile={mobile}
                  handleDrawerToggle={handleDrawerToggle}
                  activeRoute={activeRoute}
                  open={open}
                  rows={rows}
                  handleClick={handleClick}
                />
              );
            } else {
              return null;
            }
          } else {
            return (
              <Categoria
                subCategorias={subCategorias}
                openExpand={openExpand}
                setOpenExpand={setOpenExpand}
                categoria={categoria}
                key={categoria.sidId}
                mobile={mobile}
                handleDrawerToggle={handleDrawerToggle}
                activeRoute={activeRoute}
                open={open}
                rows={rows}
                handleClick={handleClick}
              />
            );
          }
        })}
      </List>
    </>
  );
};

export default withRouter(Sidebar);
