import React from "react";
import { OpenInBrowser } from "@mui/icons-material";
import {
  DialogContentText,
  Typography,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  ImageList,
} from "@mui/material";
const Trabajo = (props) => {
  const { itemTrabajo, index } = props;
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <DialogContentText
        id="scroll-dialog-description"
        ref={descriptionElementRef}
        tabIndex={-1}
        mb={2}
      >
        <Typography variant="body1" color="primary">
          {index + 1} - {itemTrabajo.fecha.substr(0, 16).replace("T", " ")}
        </Typography>
        <Typography variant="body2" color="secondary">
          {itemTrabajo.detalle}
        </Typography>
      </DialogContentText>
      <ImageList variant="masonry" cols={2} gap={3}>
        {itemTrabajo.fotos.map((item) => (
          <ImageListItem key={item.url}>
            <img
              src={`${item.url}?w=200&fit=crop&auto=format`}
              srcSet={`${item.url}?w=200&fit=crop&auto=format&dpr=2 2x`}
              alt={item.nombre}
              loading="lazy"
            />
            <ImageListItemBar
              title={"Abrir en el navegador"}
              actionIcon={
                <IconButton
                  onClick={() => window.open(item.url)}
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.nombre}`}
                >
                  <OpenInBrowser />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>{" "}
    </>
  );
};

export default Trabajo;
