import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Badge,
  Collapse,
  List,
} from "@mui/material";
import { Cancel, Check, ExpandLess, ExpandMore } from "@mui/icons-material";
import { quitarTildes } from "../../../../../../config/const";
import ListaItemPreguntas from "./ListaItemPreguntas";
import ListaItemRespuestas from "./ListaItemRespuestas";
import AlertaContext from "../../../../../../Context/Alerta/AlertaContext";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const { mostrarAlerta } = useContext(AlertaContext);
  const { item, setitem, setagregarItemLista, estadoRequest } = props;
  const { usuario, socket } = useContext(AuthContext);
  const [itemNuevo, setItemNuevo] = useState({
    pregunta: "",
    propiedad: "",
    tipoCampo: "",
    opcionesLista: [],
    respuestas: [],
  });

  const classes = useRowStyles();
   

  // const handleChange = (e) => {
  //   setItemNuevo({
  //     ...itemNuevo,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const botonDisabled = () => {
    if (
      itemNuevo.pregunta.trim() === "" ||
      itemNuevo.tipoCampo.trim() === "" ||
      ((itemNuevo.tipoCampo === "LISTA" ||
        itemNuevo.tipoCampo === "MULTIPLE LISTA" ||
        itemNuevo.tipoCampo === "PUNTAJE") &&
        itemNuevo.opcionesLista.length === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

   
  const [expandirLista, setexpandirLista] = useState(false);
  const [itemListaAgregar, setitemListaAgregar] = useState("");
  const [expandirPreguntas, setexpandirPreguntas] = useState(false);
  const [itemPreguntasAgregar, setitemPreguntasAgregar] = useState("");
  const handleAgregar = async () => {
    try {
      const docPreguntas = [
        {
          ...itemNuevo,
          propiedad: quitarTildes(itemNuevo.propiedad),
        },
        ...item.docPreguntas,
      ];

      socket.emit("client:actualizarData", {
        ...item,
        tabla: "evaluacion",
        docPreguntas,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        docUser: usuario.usuario,
        docEmpresa: usuario.rucempresa,
      });
      setitem({
        ...item,
        docPreguntas,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregarItemLista(false);
                setItemNuevo({
                  pregunta: "",
                  propiedad: "",
                  tipoCampo: "",
                  opcionesLista: [],
                  respuestas: [],
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();
                setagregarItemLista(false);
                setItemNuevo({
                  pregunta: "",
                  propiedad: "",
                  tipoCampo: "",
                  opcionesLista: [],
                  respuestas: [],
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>
          </Box>
        </TableCell>
        {/* las demas columnas */}
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            autoFocus
            id="pregunta"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.pregunta}
            name="pregunta"
            onChange={(e) =>
              setItemNuevo({
                ...itemNuevo,
                [e.target.name]: e.target.value,
                propiedad: e.target.value
                  .replace(/\s+/g, "_")
                  .toLocaleLowerCase(),
              })
            }
            helperText="Agregando"
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            id="tipoCampo"
            inputProps={{ style: { textAlign: "center" } }}
            value={itemNuevo.tipoCampo}
            name="tipoCampo"
            onChange={(e) => {
              setItemNuevo({
                ...itemNuevo,
                tipoCampo: e.target.value,
                opcionesLista:
                  e.target.value === "PUNTAJE"
                    ? [
                        "NADA SATISFECHO (1)",
                        "POCO SATISFECHO (2)",
                        "NEUTRAL (3)",
                        "MUY SATISFECHO (4)",
                        "TOTALMENTE SATISFECHO (5)",
                      ]
                    : itemNuevo.opcionesLista,
                respuestas: [],
              });
            }}
            helperText="Agregando"
            size="small"
            fullWidth
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">Seleccione.</MenuItem>
            {[
              "TEXTO",
              "NUMERICO",
              "AREA TEXTO",
              "LISTA",
              "MULTIPLE LISTA",
              "PUNTAJE",
            ].map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </TextField>
        </TableCell>
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          {itemNuevo.tipoCampo === "MULTIPLE LISTA" ||
          itemNuevo.tipoCampo === "LISTA" ||
          itemNuevo.tipoCampo === "PUNTAJE" ? (
            <>
              <Badge
                badgeContent={itemNuevo.opcionesLista.length}
                color="success"
              >
                <IconButton
                  aria-label=""
                  onClick={() => setexpandirLista(!expandirLista)}
                  size="small"
                >
                  {expandirLista ? (
                    <ExpandLess color="primary" fontSize="small" />
                  ) : (
                    <ExpandMore color="primary" fontSize="small" />
                  )}
                </IconButton>
              </Badge>
              <Collapse
                in={expandirLista}
                timeout="auto"
                unmountOnExit
                sx={{ maxHeight: "10rem", overflow: "scroll" }}
              >
                <TextField
                  autoFocus
                  inputProps={{ style: { textAlign: "center" } }}
                  value={itemListaAgregar}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (
                        itemNuevo.opcionesLista.some(
                          (itemLista) =>
                            itemLista.toLowerCase().trim() ===
                            itemListaAgregar.toLowerCase().trim()
                        )
                      ) {
                        return mostrarAlerta(
                          "Ya existe un item en la lista identico."
                        );
                      }
                      if (itemListaAgregar.trim() === "") {
                        return;
                      }
                      setItemNuevo({
                        ...itemNuevo,
                        opcionesLista: [
                          itemListaAgregar.trim(),
                          ...itemNuevo.opcionesLista,
                        ],
                      });
                      setitemListaAgregar("");
                    }
                  }}
                  onChange={(e) => {
                    setitemListaAgregar(e.target.value);
                  }}
                  size="small"
                  helperText="Pulse 'Enter' para agregar"
                  fullWidth
                />
                <List dense={true} sx={{ padding: 0 }}>
                  {itemNuevo.opcionesLista.map((itemLista, index) => {
                    return (
                      <ListaItemPreguntas
                        key={index}
                        itemLista={itemLista}
                        index={index}
                        itemEdit={itemNuevo}
                        setItemEdit={setItemNuevo}
                        checksRespuestas={
                          itemNuevo.tipoCampo === "LISTA" ||
                          itemNuevo.tipoCampo === "MULTIPLE LISTA" ||
                          itemNuevo.tipoCampo === "PUNTAJE"
                        }
                      />
                    );
                  })}
                </List>
              </Collapse>
            </>
          ) : (
            "- "
          )}
        </TableCell>{" "}
        {estadoRequest === "EVALUACIONES" ? (
          <TableCell
            fontSize="small"
            padding="none"
            align="center"
            className={classes.celda}
          >
            <>
              <Badge badgeContent={itemNuevo.respuestas.length} color="success">
                <IconButton
                  aria-label=""
                  onClick={() => setexpandirPreguntas(!expandirPreguntas)}
                  size="small"
                >
                  {expandirPreguntas ? (
                    <ExpandLess color="primary" fontSize="small" />
                  ) : (
                    <ExpandMore color="primary" fontSize="small" />
                  )}
                </IconButton>
              </Badge>
              <Collapse
                in={expandirPreguntas}
                timeout="auto"
                unmountOnExit
                sx={{ maxHeight: "10rem", overflow: "scroll" }}
              >
                {itemNuevo.tipoCampo === "LISTA" ||
                itemNuevo.tipoCampo === "MULTIPLE LISTA" ||
                itemNuevo.tipoCampo === "PUNTAJE" ? null : (
                  <TextField
                    autoFocus
                    inputProps={{ style: { textAlign: "center" } }}
                    value={itemPreguntasAgregar}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (itemPreguntasAgregar.trim() === "") {
                          return;
                        }
                        setItemNuevo({
                          ...itemNuevo,
                          respuestas: [
                            itemPreguntasAgregar,
                            ...itemNuevo.respuestas,
                          ],
                        });
                        setitemPreguntasAgregar("");
                      }
                    }}
                    onChange={(e) => {
                      setitemPreguntasAgregar(e.target.value);
                    }}
                    size="small"
                    helperText="Pulse 'Enter' para agregar"
                    fullWidth
                  />
                )}
                <List dense={true} sx={{ padding: 0 }}>
                  {itemNuevo.respuestas.map((itemLista, index) => {
                    return (
                      <ListaItemRespuestas
                        key={itemLista + index}
                        itemLista={itemLista}
                        index={index}
                        itemEdit={itemNuevo}
                        setItemEdit={setItemNuevo}
                        checksRespuestas={
                          itemNuevo.tipoCampo === "LISTA" ||
                          itemNuevo.tipoCampo === "MULTIPLE LISTA" ||
                          itemNuevo.tipoCampo === "PUNTAJE"
                        }
                      />
                    );
                  })}
                </List>
              </Collapse>
            </>
          </TableCell>
        ) : null}
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
