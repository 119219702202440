export const columns = [
  {
    id: "secuNombre",
    label: "Código",
    minWidth: 150,
    tipo: "string",

    propiedad: "secuNombre",
  },
  {
    id: "secuNum1",
    label: "Establecimiento",
    minWidth: 150,
    tipo: "string",

    propiedad: "secuNum1",
  },
  {
    id: "secuNum2",
    label: "P. Emision",
    minWidth: 150,
    tipo: "string",

    propiedad: "secuNum2",
  },
  {
    id: "secuNum3",
    label: "Secuencia",
    minWidth: 150,
    tipo: "string",

    propiedad: "secuNum3",
  },
  {
    id: "secuFecReg",
    label: "Fec. Reg.",
    minWidth: 150,
    tipo: "string",

    propiedad: "secuFecReg",
  },
  {
    id: "secuUser",
    label: "User",
    minWidth: 150,
    tipo: "string",

    propiedad: "secuUser",
  },
];

export const objectDefault = {
  secuNombre: "",
  secuNum1: "",
  secuNum2: "",
  secuNum3: "",
  secuEstado: "ACTIVO",
  secuEmpresa: "",
  secuUser: "",
  secuFecReg: "",
  Todos: "",
};
