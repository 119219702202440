import React, { useState, useContext, useEffect, memo, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Box,
  Divider,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  FormControl,
  Autocomplete,
  Popper,
  TextField,
  InputAdornment,
} from "@mui/material";

import Draggable from "react-draggable";

import { Cancel, Close, PictureAsPdf, VideoLibrary } from "@mui/icons-material";
// eslint-disable-next-line

import MenuContext from "../../../Context/Menu/MenuContext";
import AuthContext from "../../../Context/Auth/AuthContext";
import { useRowStyles } from "../../styles/stylesRows";

function getModalStyle() {
  return {
    borderRadius: "15px",
    position: "absolute",

    overflow: "scroll",
    maxHeight: "80vh",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",

    width: "70%",
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    marginTop: "8vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "2vh",
    },
  },
  formD: {
    flexGrow: 1,
    margin: theme.spacing(0.5),
    width: "45%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "2px",
      width: "95%",
    },
  },
  formAllW: {
    flexGrow: 1,
    padding: theme.spacing(0.25),
    margin: theme.spacing(0.25),
    width: "95%",
    [theme.breakpoints.down("md")]: { padding: "0", margin: "2px" },
    [theme.breakpoints.down("sm")]: { padding: "0", margin: "2px" },
  },
  video: {
    width: "325px",
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: `${250}px`,
      height: `${250 * 0.625}px`,
    },
    [theme.breakpoints.down("sm")]: {
      width: `${150}px`,
      height: `${150 * 0.625}px`,
    },
  },
  ocultar: {
    display: "none",
  },
}));

const ModalAsignatura = (props) => {
  const {
    categoriaActiva,
    changeCategoriaActiva, // eslint-disable-next-line
  } = useContext(MenuContext);

  // eslint-disable-next-line

  //props
  const { open, setopen } = props;

  const [categoria, setcategoria] = useState(categoriaActiva);
  const { component } = categoria.sidInfo;
  const { usuario } = useContext(AuthContext);
  useEffect(() => {
    setcategoria(categoriaActiva);
  }, [categoriaActiva]);

  const [search, setsearch] = useState("");
  const [tutorialesSearch, settutorialesSearch] = useState([]);
  const tutoriales = useMemo(() => {
    const esAdmin = usuario.rol.some(
      (rolItem) => rolItem === "ADMIN" || rolItem === "SUPERADMIN"
    );
    if (esAdmin) {
      return categoria?.tutoContenido || [];
    } else {
      return (
        categoria.tutoContenido?.filter((item) =>
          item.perfiles.some((perfil) =>
            usuario.rol.some((rolItem) => rolItem === perfil)
          )
        ) || []
      );
    }
  }, [categoria]);
  const defaultActive = () => {
    changeCategoriaActiva({
      sidId: 0,
      sidInfo: {
        orden: 1,
        path: "",
        name: "",
        icon: "",
        component: "",
        layout: "",
        tipo: "",
        ruta: false,
        expandible: true,
        permisos: ["USUARIO"],
        agregar: ["USUARIO"],
        editar: ["USUARIO"],
        ver: ["USUARIO"],
        eliminar: ["USUARIO"],
        impExp: ["USUARIO"],
        categoriaEmpresa: [],
        tutoriales: [],
      },
    });
    setopen(false);
  };

  //hook de estilos
  const clases = useStyles();

  const [modalStyle] = useState(getModalStyle);
  const [openDialog, setopenDialog] = useState(false);
  const [urlVideo, seturlVideo] = useState("");
  const classes = useRowStyles();

  const PopperMy = function(props) {
    return (
      <Popper {...props} style={classes.popper} placement="bottom-start" />
    );
  };
  if (!open) {
    return null;
  }
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openDialog}
        onClose={() => setopenDialog(false)}
        aria-labelledby="draggable-dialog-title"
      >
        {urlVideo === "" || !openDialog ? null : (
          <iframe
            height="500"
            title="YouTube video player"
            //  https://youtu.be/xcJtL7QggTI
            //
            src={`${urlVideo.split("/")[0]}/${
              urlVideo.split("/")[1]
            }/www.youtube.com/embed/${urlVideo.split("/")[3]}`}
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          ></iframe>
        )}
      </Dialog>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Draggable handle="#handle">
            <Box display="flex" justifyContent="center">
              <div style={modalStyle} className={clases.paper}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  style={{ cursor: "-webkit-grab" }}
                >
                  <Typography
                    id="handle"
                    variant="button"
                    color="initial"
                    align="center"
                  >
                    {component}
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      aria-label=""
                      style={{ margin: "0px" }}
                      onClick={() => {
                        defaultActive();
                      }}
                    >
                      <Cancel color="secondary" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <FormControl fullWidth>
                  <Autocomplete
                    sx={{ mb: 0.5 }}
                    PopperComponent={PopperMy}
                    disableClearable={true}
                    options={tutoriales.map((item) => {
                      return item.nombreVideo + "/+/" + item.id;
                    })}
                    getOptionLabel={(option) => {
                      return option.split("/+/")[0];
                    }}
                    id="controlled-demo"
                    value={search}
                    onChange={async (event, newValue) => {
                      if (newValue) {
                        const values = newValue.split("/+/");
                        setsearch(newValue);
                        settutorialesSearch(
                          tutoriales.filter((tuto) => tuto.id === values[1])
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Buscar videos"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                              <InputAdornment position="end">
                                {search && (
                                  <IconButton
                                    aria-label=""
                                    color="error"
                                    onClick={() => {
                                      setsearch("");
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <Divider />

                <List
                  sx={{
                    width: "100%",
                    flexGrow: "1",
                  }}
                >
                  {[...(search ? tutorialesSearch : tutoriales)]?.map(
                    (tutorial) => {
                      return tutorial.urlVideo.split("/")[2] === "youtu.be" ? (
                        <ListItem
                          component={"button"}
                          disablePadding
                          onClick={() => {
                            seturlVideo(tutorial.urlVideo);
                            setopenDialog(true);
                          }}
                        >
                          <ListItemAvatar
                            sx={{ mr: 2 }}
                            className={clases.video}
                          >
                            <iframe
                              title="YouTube video player"
                              //  https://youtu.be/xcJtL7QggTI
                              //

                              height="100%"
                              width="100%"
                              src={`${tutorial.urlVideo.split("/")[0]}/${
                                tutorial.urlVideo.split("/")[1]
                              }/www.youtube.com/embed/${
                                tutorial.urlVideo.split("/")[3]
                              }`}
                              allowfullscreen="allowfullscreen"
                              mozallowfullscreen="mozallowfullscreen"
                              msallowfullscreen="msallowfullscreen"
                              oallowfullscreen="oallowfullscreen"
                              webkitallowfullscreen="webkitallowfullscreen"
                            ></iframe>
                          </ListItemAvatar>

                          <ListItemText
                            primary={`${
                              tutorial.nombreVideo
                            } - ${tutorial.perfiles.join(", ")}`}
                            primaryTypographyProps={{
                              sx: { fontSize: ".9rem" },
                            }}
                            secondaryTypographyProps={{
                              sx: { fontSize: ".8rem" },
                            }}
                            secondary={tutorial.descVideo}
                          />
                          <Typography variant="body2">
                            {tutorial.orientacion}
                          </Typography>
                        </ListItem>
                      ) : (
                        <ListItem
                          component={"button"}
                          onClick={() => {
                            window.open(tutorial.urlVideo);
                          }}
                        >
                          <ListItemAvatar>
                            <PictureAsPdf color="error" />
                          </ListItemAvatar>

                          <ListItemText
                            primary={tutorial.nombreVideo}
                            secondary={tutorial.descVideo}
                          />
                        </ListItem>
                      );
                    }
                  )}
                </List>
                <Divider />
                <Divider />
              </div>
            </Box>
          </Draggable>
        </Box>
      </Modal>{" "}
    </>
  );
};

export default memo(ModalAsignatura);
