import React, { memo, useContext } from "react";

import { useState } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
// MATERIAL
import { makeStyles } from "@mui/styles";
import { Box, IconButton, TableCell, TableRow, TextField } from "@mui/material";
import { Cancel, Check } from "@mui/icons-material";
import CampoDoc from "../../../../components/Extra/CampoDoc";
const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: ".5px solid #ccc",
    },
  },
  margin: {
    margin: "0px",
    padding: "2px",
  },
  active: {
    borderBottom: "5px solid #ccc",
    backgroundColor: "#354046",
  },
  color: {
    color: "white",
    margin: "0px",
    padding: "2px",
  },
}));

const RowAgregarItem = (props) => {
  // eslint-disable-next-line
  const [cargando, setcargando] = useState(false);
  const { item, setitem, setagregarItemLista, socket } = props;
  const { usuario } = useContext(AuthContext);
  const [itemNuevo, setItemNuevo] = useState({
    procedimiento: item.isoProcedimiento,
    documento: "",
    fechaEmision: item.isoFechaEmision,
    fechaRevision: "",
    version: "",
    controlCambios: "",
  });

  const classes = useRowStyles();
   

  const handleChange = (e) => {
    setItemNuevo({
      ...itemNuevo,
      [e.target.name]: e.target.value,
    });
  };

  const botonDisabled = () => {
    if (
      itemNuevo.fechaRevision === "" ||
      itemNuevo.version === "" ||
      itemNuevo.controlCambios === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

   

  const handleAgregar = async () => {
    try {
      const isoOtros = [
        {
          ...itemNuevo,
        },
        ...item.isoOtros,
      ];

      socket.current.emit("client:actualizarData", {
        ...item,
        tabla: "iso",
        isoOtros,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        isoUser: `${item.isoUser.split(" ** ")[0]} ** ${usuario.usuario}`,
        isoEmpresa: usuario.rucempresa,
      });
      setitem({
        ...item,
        isoOtros,
      });
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <IconButton
              className={classes.margin}
              onClick={() => {
                setagregarItemLista(false);
                setItemNuevo({
                  procedimiento: item.isoProcedimiento,
                  documento: "",
                  fechaEmision: item.isoFechaEmision,
                  fechaRevision: "",
                  version: "",
                  controlCambios: "",
                });
              }}
            >
              <Cancel fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              color={
                botonDisabled
                  ? botonDisabled()
                    ? "inherit"
                    : "secondary"
                  : "secondary"
              }
              disabled={botonDisabled()}
              className={classes.margin}
              onClick={() => {
                handleAgregar();
                setagregarItemLista(false);
                setItemNuevo({
                  procedimiento: item.isoProcedimiento,
                  documento: "",
                  fechaEmision: item.isoFechaEmision,
                  fechaRevision: "",
                  version: "",
                  controlCambios: "",
                });
              }}
            >
              <Check fontSize="small" />
            </IconButton>{" "}
          </Box>
        </TableCell>
        {/* las demas columnas */}{" "}
        <TableCell
          fontSize="small"
          padding="none"
          align="center"
          className={classes.celda}
        >
          <CampoDoc
            cargando={cargando}
            setcargando={setcargando}
            propiedad={"documento"}
            item={itemNuevo}
            setitem={setItemNuevo}
            celda={true}
          />
        </TableCell>
        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            disabled
            value={itemNuevo.procedimiento}
            name="procedimiento"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            disabled
            type="date"
            value={itemNuevo.fechaEmision}
            name="fechaEmision"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            type="date"
            autoFocus
            value={itemNuevo.fechaRevision}
            name="fechaRevision"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemNuevo.version}
            name="version"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
        <TableCell
          fontSize="small"
          sx={{ paddingLeft: "5px" }}
          padding="none"
          align="center"
          className={classes.celda}
        >
          <TextField
            value={itemNuevo.controlCambios}
            name="controlCambios"
            onChange={(e) => handleChange(e)}
            size="small"
            fullWidth
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default memo(RowAgregarItem);
