import React, { memo, useContext } from "react";

import { TableCell, TableRow } from "@mui/material";

import { useState } from "react";
import ConfirmacionDialog from "../../../../../components/ConfirmacionDialog";
import ImagenCelda from "../../../../../components/ImagenCelda";
import { withRouter } from "react-router";
import ButtonsAction from "../../../../../components/ButtonsAction";
import AuthContext from "../../../../../../Context/Auth/AuthContext";
import {
  Cancel,
  Create,
  DeleteForever,
  PictureAsPdf,
  Restore,
} from "@mui/icons-material";
import {
  butonIconTransition,
  useRowStyles,
} from "../../../../../styles/stylesRows";
import { trimPropsItem } from "../../../../../../config/const";
import clienteAxios from "../../../../../../config/axios";
import CollapseCell from "../../../../../components/CollapseCell";
import ModalTrabajos from "./ModalTrabajos";
import DialogCalendario from "./DialogCalendario";
import CeldaUser from "../../../../../components/CeldaUser";
const Row = (props) => {
  // CLASES
  const classes = useRowStyles();
  // PROPS
  const {
    // HOOK DE PERMISO
    tienePermiso,
    alertaPermiso,
    // ROW
    index,
    rowHijo,
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // FUNCION STATE
    changeObjetoActivoState,
    // SOCKET
    socket,
    tabla,
    // FROM OTHER SITE
    fromIOP,
  } = props;
  const claseCelda = `${
    index % 2 === 0 ? classes.celda : classes.celdaSecundario
  } ${
    rowHijo.editado === true
      ? classes.celdaEditado
      : rowHijo.nuevo === true
      ? classes.celdaNuevo
      : rowHijo.eliminado === true
      ? classes.celdaEliminado
      : ""
  }`;
  // HOOK DE PERMISO
  // CONTEXT
  const { usuario } = useContext(AuthContext);
  // ABRE EL DIALOG DE CONFIRMACION
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  // MODAL CALENDARIO
  const [openDialogCalendario, setopenDialogCalendario] = useState(false);
  // MODAL TRABAJOS
  const [openModalTrabajos, setopenModalTrabajos] = useState(false);
  //Celda collapse
  const [celComprimida, setcelComprimida] = useState({});
  // ELIMINAR EL REGISTRO
  const handleEliminar = async (_id) => {
    try {
      socket.current.emit("client:eliminarData", {
        nombre: usuario.nombre,
        tabla,
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        actUser: usuario.usuario,
        actEmpresa: usuario.rucempresa,
        [campoExport]: rowHijo[campoExport],
        actDocCliente: rowHijo.actDocCliente,
      });
      setopenConfirmDialog(false);
    } catch (error) {}
  };
  const funcRecuperar = () => {
    try {
      socket.current.emit(
        "client:guardarData",
        trimPropsItem({
          ...rowHijo,
          nombre: usuario.nombre,
          tabla,
          rucempresa: usuario.rucempresa,
          rol: usuario.rol,
          actUser: usuario.usuario + "/+/" + rowHijo[campoExport],
          actEmpresa: usuario.rucempresa,
        })
      );
    } catch (error) {}
  };
  return (
    <React.Fragment>
      {openDialogCalendario ? (
        <DialogCalendario
          rowHijo={rowHijo}
          openDialogCalendario={openDialogCalendario}
          setopenDialogCalendario={setopenDialogCalendario}
        />
      ) : null}
      <ModalTrabajos
        open={openModalTrabajos}
        setopen={setopenModalTrabajos}
        row={rowHijo}
      />
      <ConfirmacionDialog
        open={openConfirmDialog}
        setopen={setopenConfirmDialog}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar este registro?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: () => {
              handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenConfirmDialog(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <TableRow className={classes.root}>
        {/* boton */}
        <ButtonsAction
          claseCelda={claseCelda}
          arrayExport={arrayExport}
          setarrayExport={setarrayExport}
          campoExport={campoExport}
          row={rowHijo}
          agrupado={true}
          rowPadre={rowPadre}
          botones={[
            {
              tooltip: "Recuperar",
              texto: "",
              funcion: () => {
                if (!tienePermiso("añadir")) {
                  return alertaPermiso("añadir");
                }
                funcRecuperar();
              },
              disabled: usuario.usuario !== rowHijo.actUser.split("/+/")[0],
              Icon: Restore,
              color: "success",
              id: 1,
              ocultar: !rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "Eliminar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("eliminar")) {
                  return alertaPermiso("eliminar");
                }
                setopenConfirmDialog(true);
              },
              disabled: false,
              Icon: DeleteForever,
              color: "error",
              id: 2,
              ocultar: rowHijo.eliminado || fromIOP,
              tipo: "icono",
              variante: "contained",
              size: "small",
              // css
              sx: butonIconTransition,
            },
            {
              tooltip: "Editar Registro",
              texto: "",
              funcion: () => {
                if (!tienePermiso("editar")) {
                  return alertaPermiso("editar");
                }
                setopen(true);
                seteditar(true);
                changeObjetoActivoState(rowHijo);
              },
              disabled: false,
              Icon: Create,
              color: "secondary",
              id: 3,
              ocultar: rowHijo.eliminado,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            {
              tooltip: "PDF",
              texto: "",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}/reportes/actividad_varias_personas?rucempresa=${rowHijo.actEmpresa}&actId=${rowHijo.actId}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 5,
              ocultar: false,
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },

            {
              tooltip: "QR RONDA",
              texto: "RONDA",
              funcion: () => {
                window.open(
                  `${clienteAxios.defaults.baseURL}reportes/qrronda_actividad?actId=${rowHijo.actId}&rucempresa=${rowHijo.actEmpresa}`
                );
              },
              disabled: false,
              Icon: PictureAsPdf,
              color: "error",
              id: 5,
              ocultar: rowHijo.actAsunto !== "RONDAS",
              tipo: "icono",
              variante: "contained",
              size: "small",
              sx: butonIconTransition,
            },
            // {
            //   tooltip: "Trabajos",
            //   texto: "",
            //   funcion: () => {
            //     setopenModalTrabajos(true);
            //   },
            //   disabled: false,
            //   Icon: Visibility,
            //   color: "primary",
            //   id: 6,
            //   ocultar: rowHijo.eliminado,
            //   tipo: "icono",
            //   variante: "contained",
            //   size: "small",
            //   sx: butonIconTransition,
            // },

            {
              tooltip: "Estado",
              texto: "",
              funcion: (e) => {},
              disabled: false,
              Icon: "",
              color: "secondary",
              id: 7,
              ocultar: rowHijo.eliminado,
              tipo: "check",
              estado: rowHijo.actEstado === "ACTIVA",
              variante: "",
              size: "small",
              sx: butonIconTransition,
            },
          ]}
        />

        <>
          <ImagenCelda
            propiedad={rowHijo.actFotosActividad}
            array={true}
            claseCelda={claseCelda}
          />
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.actEveTipo}
          </TableCell>
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.actEveDatos.eveNombre}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            onClick={() => setopenDialogCalendario(true)}
            sx={{ cursor: "pointer" }}
          >
            {
              Object.keys({
                ...rowHijo.actGuardias,
                ...rowHijo.actSupervisores,
                ...rowHijo.actAdministracion,
              })[0]
            }
            {" - "}
            {
              Object.keys({
                ...rowHijo.actGuardias,
                ...rowHijo.actSupervisores,
                ...rowHijo.actAdministracion,
              })[
                Object.keys({
                  ...rowHijo.actGuardias,
                  ...rowHijo.actSupervisores,
                  ...rowHijo.actAdministracion,
                }).length - 1
              ]
            }
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            onClick={() => setopenDialogCalendario(true)}
            sx={{ cursor: "pointer" }}
          >
            {[
              ...new Set(
                Object.entries(rowHijo.actGuardias)
                  .flatMap((item) => item[1])
                  .map((item) => item.perApellidos + " " + item.perNombres)
              ),
            ].join(" - ")}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            onClick={() => setopenDialogCalendario(true)}
            sx={{ cursor: "pointer" }}
          >
            {[
              ...new Set(
                Object.entries(rowHijo.actSupervisores)
                  .flatMap((item) => item[1])
                  .map((item) => item.perApellidos + " " + item.perNombres)
              ),
            ].join(" - ")}
          </TableCell>
          <TableCell
            size="small"
            align="center"
            className={claseCelda}
            onClick={() => setopenDialogCalendario(true)}
            sx={{ cursor: "pointer" }}
          >
            {[
              ...new Set(
                Object.entries(rowHijo.actAdministracion)
                  .flatMap((item) => item[1])
                  .map((item) => item.perApellidos + " " + item.perNombres)
              ),
            ].join(" - ")}
          </TableCell>
          <CollapseCell
            celComprimida={celComprimida}
            setcelComprimida={setcelComprimida}
            row={rowHijo}
            propiedad={"actObservacion"}
            claseCelda={claseCelda}
          />
          {/* <DocumentoCelda
            propiedad={rowHijo.actDocumento}
            claseCelda={claseCelda}
          /> */}
          <TableCell size="small" align="center" className={claseCelda}>
            {rowHijo.actPrioridad}
          </TableCell>

          <CeldaUser user={rowHijo.actUser} claseCelda={claseCelda} />
        </>
      </TableRow>
    </React.Fragment>
  );
};

export default withRouter(memo(Row));
