import React, { useContext, useEffect, memo, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@mui/material";

import { withRouter } from "react-router-dom";
import Row from "./Row";
import Cargando from "../../../components/Extra/Cargando";
import ToolBarTable from "../../components/ToolBarTable";
import TablaCabecera from "../../components/TablaCabecera";
import AuthContext from "../../../Context/Auth/AuthContext";
import {
  Cancel,
  DeleteForever,
  ListRounded,
  PictureAsPdf,
  Refresh,
} from "@mui/icons-material";
import DialogOpciones from "./DialogOpciones";
import ConfirmacionDialog from "../../components/ConfirmacionDialog";
import clienteAxios from "../../../config/axios";
import { saveAs } from "save-as";
import AlertaContext from "../../../Context/Alerta/AlertaContext";
import { useTableStyles } from "../../styles/styleTable";
import { usePermiso } from "../../hooks/usePermiso";
import { reducer } from "../../functions/funciones";
import { useRowStyles } from "../../styles/stylesRows";

const Tabla = (props) => {
  //clases
  const classes = useTableStyles();
  const classesRow = useRowStyles();
  // props
  const {
    // PAGINAS Y PAGINACION
    page,
    rowsPerPage,
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // ORDEN
    input,
    orden,
    // BUSCAR
    search,
    fullBuscador,
    // EXPORTAR
    arrayExport,
    setarrayExport,
    campoExport,
    // CARGANDO
    cargando,
    // setcargando,
    // DATA
    rows,
    columns,
    estadoRequest,
    // FUNCIONES DATA
    obtenerState,
    changeObjetoActivoState,
    funcionOrdenar,
    funcionReload,
    getDataState,
    // SOCKET
    tabla,
    socket,
    eliminarState,
  } = props;
  // CONTEXT
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // ABRE EL DIALOG DE OPCIONES
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  // ABRE EL DIALOG DE CONFIRMACION PARA ELIMINAR
  const [openCDEliminar, setopenCDEliminar] = useState(false);
  // DESHABILITA BOTONES DEL DIALOG SI ESTA CARGANDO
  const [cargandoDialogOpciones, setcargandoDialogOpciones] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Anticipos");
  // FUNCION PARA ELIMINAR SELECCION
  const handleEliminar = async () => {
    if (!tienePermiso("eliminar")) {
      return alertaPermiso("eliminar");
    }

    await setTimeout(async () => {
      socket.current.emit("client:eliminarDataSeleccionado", {
        rucempresa: usuario.rucempresa,
        rol: usuario.rol,
        tabla,
        lista: arrayExport,
      });
      setcargandoDialogOpciones(false);
      setopenCDEliminar(false);
      setarrayExport({});
    }, 500);
  };

  // OBTIENE LA DATA AL CARGAR EL COMPONENTE
  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      "padre"
      // fecha
    );

    // eslint-disable-next-line
  }, []);

  //si no hay usuarios //rows no cargar el componente
  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <Paper className={classes.root}>
      <ConfirmacionDialog
        open={openCDEliminar}
        setopen={setopenCDEliminar}
        categoria="warning"
        titulo={`¿Esta seguro de eliminar estos registros?`}
        botones={[
          {
            tooltip: "Se eliminaran los registros",
            texto: "SI",
            funcion: async () => {
              await handleEliminar();
            },
            disabled: false,
            Icon: DeleteForever,
            color: "error",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: { marginRight: ".3rem" },
          },
          {
            tooltip: "Cancelar",
            texto: "NO",
            funcion: () => {
              setopenCDEliminar(false);
            },
            disabled: false,
            Icon: Cancel,
            color: "secondary",
            id: 2,
            ocultar: false,
            tipo: "boton",
            variante: "contained",
            size: "medium",
            sx: {},
          },
        ]}
      />
      <DialogOpciones
        estadoRequest={estadoRequest}
        open={openDialogOpciones}
        tabla={tabla}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Exportar",
            funcion: async () => {
              try {
                setcargandoDialogOpciones(true);

                const json = { 
                  lista: arrayExport,
                  rucempresa: usuario.rucempresa,
                  usuario: usuario.usuario,
                  nombre: usuario.nombre, 
                  byestado: false,
                  otros: {},
                };
                console.log(json);
                const res = await clienteAxios.post(
                  `reportes/${tabla}`,
                  {
                    ...json,
                  },
                  { responseType: "blob" }
                );
                const pdfBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                saveAs(pdfBlob, `${tabla}.pdf`);
                setopen(false);
                setcargandoDialogOpciones(false);
              } catch (error) {
                mostrarAlerta("Hubo un error", "error");
                setopen(false);
                setcargandoDialogOpciones(false);
              }
            },
            Icon: PictureAsPdf,
            color: "error",
            id: 1,
            disabled: cargandoDialogOpciones,
          },

          // {
          //   tooltip: "Eliminar",
          //   funcion: () => {
          //     setopenCDEliminar(true);
          //   },
          //   Icon: DeleteForever,
          //   color: "error",
          //   id: 2,
          //   disabled: cargandoDialogOpciones,
          // },
        ]}
        titulo={"Acciones"}
      />
      <ToolBarTable
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ocultarPaginacion={false}
        botonesIzquierdos={[
          {
            tooltip: "Actualizar",
            texto: "Actualizar",
            funcion: () => {
              funcionReload(true);
            },
            disabled: false,
            Icon: Refresh,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "text",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[
          {
            tooltip: "Exportar",
            texto: "",
            funcion: () => {
              setopenDialogOpciones(true);
            },
            disabled: arrayExport.length === 0,
            Icon: ListRounded,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "text",
            size: "medium",
            sx: { margin: 0, padding: 0 },
          },
        ]}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table" id="tabla" size="small">
          <TableHead>
            <TablaCabecera
              rows={rows}
              campoExport={campoExport}
              columns={columns}
              habilitarOrdenar={true}
              //nuevos
              input={input}
              orden={orden}
              funcionOrdenar={funcionOrdenar}
              ocultarPrimeraColumna={false}
              agregarColumna={false}
            />
          </TableHead>
          <TableBody>
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columns.length + 2}
              >
                <Cargando titulo="Cargando..." />
              </TableCell>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowPadre, index) => {
                  return (
                    <Row
                      // KEY
                      key={rowPadre[campoExport]}
                      index={index}
                      // EDIT Y OPEN
                      setopen={setopen}
                      seteditar={seteditar}
                      // EXPORT
                      campoExport={campoExport}
                      arrayExport={arrayExport}
                      setarrayExport={setarrayExport}
                      // DATA
                      rowPadre={rowPadre}
                      estadoRequest={estadoRequest}
                      // FUNCIONES DATA
                      getDataState={getDataState}
                      changeObjetoActivoState={changeObjetoActivoState}
                      eliminarState={eliminarState}
                      // SOCKET
                      tabla={tabla}
                      socket={socket}
                    />
                  );
                })
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              ></TableCell>
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              ></TableCell>

              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              ></TableCell>
              <TableCell
                size="small"
                align="center"
                className={classesRow.celdaFooter}
              >
                $
                {rows.length === 0
                  ? null
                  : rows
                      .map((row) => {
                        return Number(
                          row.tvalor ? row.tvalor.replace(/,/g, "") : "0"
                        );
                      })
                      .reduce(reducer)
                      .toLocaleString()}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default memo(withRouter(Tabla));
