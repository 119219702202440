export const columns = [
  {
    id: "plancCodigo",
    label: "Código",
    minWidth: 200,
    tipo: "string",

    propiedad: "plancCodigo",
  },
  {
    id: "plancNombre",
    label: "Nombre",
    minWidth: 100,
    tipo: "string",
    propiedad: "plancNombre",
  },
  {
    id: "plancTipo",
    label: "Tipo",
    minWidth: 100,
    tipo: "string",

    propiedad: "plancTipo",
  },
  {
    id: "plancFecReg",
    label: "Fec. Reg",
    minWidth: 100,
    tipo: "string",

    propiedad: "plancFecReg",
  },
  {
    id: "plancUser",
    label: "User",
    minWidth: 100,
    tipo: "string",
    propiedad: "plancUser",
  },
];

export const objectDefault = {
  // plancId
  plancCodigo: "",
  plancNombre: "",
  plancDebe: "",
  plancHaber: "",
  plancEmpresa: "",
  plancEstado: "ACTIVA",
  plancUser: "",
  plancFecReg: "",
  plancTipo: "AGRUPACIÓN",
  Todos: "",
};
