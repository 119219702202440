import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import ListPersonas from "./ListPersonas";
import { estaEntre } from "../../../functions/funciones";
import clienteAxios from "../../../../config/axios";
import Cargando from "../../../components/Cargando";
const TabsFechasPersonas = (props) => {
  const {
    focusedDate,
    evento,
    fechasEventosGuardias,
    setfechasEventosGuardias,
    fechasEventosSupervisores,
    setfechasEventosSupervisores,
    fechasEventosAdministracion,
    setfechasEventosAdministracion,
    guardiasData,
    supervisoresData,
    administracionData,
    actIdCliente,
    readOnly,
  } = props;
  const [seccionActiveSelect, setseccionActiveSelect] = useState({
    guardiasSeccion: true,
    supervisoresSeccion: false,
    administracionSeccion: false,
  });
  const {
    guardiasSeccion,
    supervisoresSeccion,
    administracionSeccion,
  } = seccionActiveSelect;
  const [eventosData, seteventosData] = useState([]);
  const [cargando, setcargando] = useState(false);
  const validarPersonas = (personas, sonSupervisores) => {
    if (evento.evePerfil === "SUPERVISOR") {
      if (sonSupervisores) {
        return personas.map((persona) => {
          const arrayFindedTurn = persona.perTurnos.find((arrayTurno) => {
            // SE RECORREN LOS TURNOS DE CADA PERSONA
            return arrayTurno.some((itemTurno) => {
              return itemTurno.desde.split(" ")[0] === focusedDate;
            });
          });
          if (!arrayFindedTurn)
            return {
              ...persona,
              disabled: true,
              causa: "horario",
            };
          const itemTurno = arrayFindedTurn.find((itemTurno) => {
            return itemTurno.desde.split(" ")[0] === focusedDate;
          });

          const horaDesde = itemTurno.desde.split(" ")[1];
          //eslint-disable-next-line
          const horaHasta = itemTurno.hasta.split(" ")[1];
          const horarioCompatible = evento.eveActividades.every((actividad) => {
            // SI CONCUERDA CON EL HORARIO DE LA PERSONA
            const todasLasActividadeComptabiles = actividad.horario
              .filter((item) => !item.eliminado)
              .every((itemHorario) => {
                return (
                  estaEntre(horaDesde, horaHasta, itemHorario.hora) &&
                  estaEntre(horaDesde, horaHasta, itemHorario.horaFinal)
                );
              });

            return todasLasActividadeComptabiles;
          });
          if (!horarioCompatible)
            return {
              ...persona,
              disabled: true,
              causa: "horario",
              perTurnos: itemTurno,
            };
          return {
            ...persona,
            disabled: false,
            perTurnos: itemTurno,
          };
        });
      } else {
        return personas.map((persona) => {
          return {
            ...persona,
            disabled: true,
            causa: "horario",
          };
        });
      }
    }
    return personas.map((persona) => {
      // BOOLEANO DE SI EL BOTON SE HABILITARA SE RECORREN LOS TURNOS DE ESA PERSONA
      const arrayFindedTurn = persona.perTurnos.find((arrayTurno) => {
        // SE RECORREN LOS TURNOS DE CADA PERSONA
        return arrayTurno.some((itemTurno) => {
          return itemTurno.desde.split(" ")[0] === focusedDate;
        });
      });
      if (!arrayFindedTurn)
        return {
          ...persona,
          disabled: true,
          causa: "horario",
        };
      const itemTurno = arrayFindedTurn.find((itemTurno) => {
        return itemTurno.desde.split(" ")[0] === focusedDate;
      });

      const horaDesde = itemTurno.desde.split(" ")[1];
      //eslint-disable-next-line
      const horaHasta = itemTurno.hasta.split(" ")[1];
      const horarioCompatible = evento.eveActividades.every((actividad) => {
        // SI CONCUERDA CON EL HORARIO DE LA PERSONA
        const todasLasActividadeComptabiles = actividad.horario
          .filter((item) => !item.eliminado)
          .every((itemHorario) => {
            return (
              estaEntre(horaDesde, horaHasta, itemHorario.hora) &&
              estaEntre(horaDesde, horaHasta, itemHorario.horaFinal)
            );
          });

        return todasLasActividadeComptabiles;
      });
      if (!horarioCompatible)
        return {
          ...persona,
          disabled: true,
          causa: "horario",
          perTurnos: itemTurno,
        };
      // TRAE LOS EVENTOS DE ESA PERSONA
      const eventosDeEstaPersona = eventosData.filter(
        (eventoPersona) => eventoPersona.act_asigPerId === persona.perId
      );
      const eventoPersona = eventosDeEstaPersona.find((eventoPersona) => {
        return evento.eveActividades.find((actividad) => {
          return eventoPersona.act_asigHorario.some((horarioEventoPersona) => {
            return actividad.horario
              .filter((item) => !item.eliminado)
              .some((itemHorario) => {
                return (
                  estaEntre(
                    itemHorario.hora,
                    itemHorario.horaFinal,
                    horarioEventoPersona.hora
                  ) ||
                  estaEntre(
                    itemHorario.hora,
                    itemHorario.horaFinal,
                    horarioEventoPersona.horaFinal
                  )
                );
              });
          });
        });
      });
      if (eventoPersona)
        return {
          ...persona,
          disabled: true,
          causa: "evento",
          perTurnos: itemTurno,
          eventoPersona,
        };
      return {
        ...persona,
        disabled: false,
        perTurnos: itemTurno,
      };
    });
  };
  useEffect(() => {
    const geteventos = async () => {
      setcargando(true);
      const res = await clienteAxios(
        `actividades_asignadas/fecha?fecha=${focusedDate}&rol=${
          guardiasSeccion
            ? "GUARDIA"
            : supervisoresSeccion
            ? "SUPERVISOR"
            : administracionSeccion
            ? "ADMINISTRACION"
            : ""
        }&idcliente=${guardiasSeccion ? actIdCliente : ""}`
      );
      setcargando(false);
      seteventosData(res.data.data);
    };
    geteventos();
    // eslint-disable-next-line
  }, [seccionActiveSelect, focusedDate, actIdCliente]);

  return (
    <>
      {focusedDate === "" ? null : cargando ? (
        <Cargando titulo={"cargando"} />
      ) : (
        <>
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <Box mt={2}>
              <Button
                variant={guardiasSeccion ? "contained" : null}
                disableElevation
                style={{ padding: "8px" }}
                color="secondary"
                onClick={() => {
                  setseccionActiveSelect({
                    guardiasSeccion: true,
                  });
                }}
              >
                Guardias
              </Button>
              <Button
                variant={supervisoresSeccion ? "contained" : null}
                disableElevation
                style={{ padding: "8px" }}
                color="secondary"
                onClick={() => {
                  setseccionActiveSelect({
                    supervisoresSeccion: true,
                  });
                }}
              >
                Supervisores
              </Button>
              <Button
                variant={administracionSeccion ? "contained" : null}
                disableElevation
                style={{ padding: "8px" }}
                color="secondary"
                onClick={() => {
                  setseccionActiveSelect({
                    administracionSeccion: true,
                  });
                }}
              >
                Administración
              </Button>
            </Box>
            <Box mt={2} disaplay="flex" gap=".5rem">
              <Button
                variant={"contained"}
                size="small"
                disableElevation
                disableTouchRipple={true}
                disableFocusRipple={true}
                style={{ padding: "2px" }}
                color="error"
              >
                OCUPADO
              </Button>
              <Button
                variant={"contained"}
                size="small"
                disableElevation
                disableTouchRipple={true}
                disableFocusRipple={true}
                style={{ padding: "2px" }}
                color="primary"
              >
                NO DISPONILE
              </Button>
              <Button
                variant={"contained"}
                size="small"
                disableElevation
                disableTouchRipple={true}
                disableFocusRipple={true}
                style={{ padding: "2px" }}
                color="secondary"
              >
                Disponible
              </Button>
            </Box>
          </Box>

          {guardiasSeccion ? (
            <ListPersonas
              readOnly={readOnly}
              error={{}}
              item={fechasEventosGuardias}
              setitem={(e) => {
                setfechasEventosGuardias({
                  ...fechasEventosGuardias,
                  [focusedDate]: e[focusedDate],
                });
              }}
              data={validarPersonas(guardiasData, false)}
              propiedad={fechasEventosGuardias[focusedDate]} // {actGuardias}
              propiedadString={focusedDate}
            />
          ) : null}
          {supervisoresSeccion ? (
            <ListPersonas
              readOnly={readOnly}
              error={{}}
              item={fechasEventosSupervisores}
              setitem={(e) => {
                setfechasEventosSupervisores({
                  ...fechasEventosSupervisores,
                  [focusedDate]: e[focusedDate],
                });
              }}
              data={validarPersonas(supervisoresData, true)}
              propiedad={fechasEventosSupervisores[focusedDate]} // {actGuardias}
              propiedadString={focusedDate}
            />
          ) : null}
          {administracionSeccion ? (
            <ListPersonas
              readOnly={readOnly}
              error={{}}
              item={fechasEventosAdministracion}
              setitem={(e) => {
                setfechasEventosAdministracion({
                  ...fechasEventosAdministracion,
                  [focusedDate]: e[focusedDate],
                });
              }}
              data={validarPersonas(administracionData, false)}
              propiedad={fechasEventosAdministracion[focusedDate]} // {actGuardias}
              propiedadString={focusedDate}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default TabsFechasPersonas;
