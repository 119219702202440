import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";
import React from "react";
import ToolBarTable from "../../../components/ToolBarTable";
import TablaCabecera from "../../../components/TablaCabecera";
import { columnsHijos } from "../../data";
import { useState } from "react";
import AuthContext from "../../../../Context/Auth/AuthContext";
import { useContext } from "react";
import { useEffect } from "react";
import Cargando from "../../../components/Cargando";
import RowHijo from "./Componentes/RowHijo";
import { ListRounded, PictureAsPdf, Refresh } from "@mui/icons-material";
import ModalB from "../../../components/ModalB";
import BarraTablas from "../../../components/BarraTablas";
import { usePermiso } from "../../../hooks/usePermiso";
import { useCrud } from "../../../hooks/useCrud";
import DialogOpciones from "../../../components/DialogOpciones";
import AlertaContext from "../../../../Context/Alerta/AlertaContext";
import { saveAs } from "save-as";
import clienteAxios from "../../../../config/axios";
const Hijo = (props) => {
  const {
    // ROW
    rowPadre,
    // OPEN Y EDIT
    setopen,
    seteditar,
    // EXPORT
    campoExport,
    arrayExport,
    setarrayExport,
    // DATA
    // expandir,
    tabla,
    estadoRequest,
    // FUNCION STATE
    getDataState,
    changeObjetoActivoState,
    // SOCKET
    socket,
    subEstado,
  } = props;
  const fecha = rowPadre.regFecReg.substr(0, 7);
  const cliente = "";
  const {
    usuario: { rucempresa },
    usuario,
  } = useContext(AuthContext);
  const { mostrarAlerta } = useContext(AlertaContext);
  // DATA DEL STATE
  // REGISTROS O FILAS
  const [rows, setrows] = useState(null);
  // BOOLEANO DE SI SE ESTA BUSCANDO O NO
  const [buscando, setbuscando] = useState(false);
  // BOOLEANO DE SI ESTA CARGANDO
  const [cargando, setcargando] = useState(false);
  // HOOK DE PERMISO
  const { tienePermiso, alertaPermiso } = usePermiso("Ausencia");
  // ORDEN ASCENDENTE O DESCENDENTE
  const [orden, setorden] = useState(false);
  // STRING DE BUSQUEDA
  const [search, setsearch] = useState("");
  // CAMPOS DE BUSQUEDA EXPANDIDOS
  const [openMB, setopenMB] = useState(true);
  // FUNCION QUE CREA EL OBJETO BUSCADOR
  const defaultFullBuscador = () => {
    const json = {};
    columnsHijos.forEach((itemPropiedad) => {
      Object.entries({ itemPropiedad }).forEach((item) => {
        if (item[1].ocultar) {
          return;
        }
        if (itemPropiedad.tipo === "string") {
          json[`${item[1].propiedad}`] = "";
        } else if (itemPropiedad.tipo === "numero") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        } else if (itemPropiedad.tipo === "fecha") {
          json[`${item[1].propiedad}1`] = "";
          json[`${item[1].propiedad}2`] = "";
        }
      });
    });
    return json;
  };
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const [cargandoDialogOpciones, setcargandoDialogOpciones] = useState(false);
  // OBJETO BUSCADOR
  const [fullBuscador, setfullBuscador] = useState(defaultFullBuscador());
  // PAGINACION
  const [pagination, setpagination] = useState(null);
  //pagina actual
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // ORDEN DE POR DEFECTO
  const [input, setinput] = useState({
    id: campoExport,
  });

  // FUNCION QUE COLOCA LA DATA EN EL STATE
  const obtenerState = async (
    cantidad = 10,
    page = 0,
    search,
    input,
    orden,
    datos,
    rucempresa,
    estado,
    subEstado,
    grupo,
    cliente,
    fecha
  ) => {
    const res = await getDataState(
      cantidad,
      page,
      search,
      input,
      orden,
      datos,
      rucempresa,
      estado,
      subEstado,
      grupo,
      cliente,
      fecha
    );

    setrows(res.data.data.results);
    setpagination(res.data.data.pagination);
  };
  // FUNCION DE BUSQUEDA AVANZADA
  const funcionB = async () => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "hijo",
      cliente,
      fecha
    );
    setPage(0);
    setbuscando(true);
    setopenMB(false);
    setcargando(false);
  };

  // FUNCION PARA CAMBIAR PAGINA
  const handleChangePage = async (event, newPage) => {
    if (newPage * rowsPerPage + rowsPerPage > rows.length) {
      setcargando(true);
      const res = await getDataState(
        rowsPerPage,
        newPage,
        search,
        input.id,
        orden,
        JSON.stringify(fullBuscador),
        rucempresa,
        estadoRequest,
        subEstado,
        "hijo",
        cliente,
        fecha
      );

      const data = res.data.data.results;

      setcargando(false);
      const resultado = data.filter((row) => {
        const existe = rows.some((item, index) => {
          return row[campoExport] === item[campoExport];
        });

        return !existe;
      });

      setrows([...rows, ...resultado]);
    }
    setPage(newPage);
  };

  // eslint-disable-next-line
  // FUNCION QUE CAMBIA LOS REGISTROS O FILAS POR PAGINA
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    obtenerState(
      event.target.value,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "hijo",
      cliente,
      fecha
    );
  };
  // FUNCION QUE ORDENA DESCENDENTE O ASCENDENTEMENTE
  const funcionOrdenar = async (column) => {
    setcargando(true);
    await obtenerState(
      rowsPerPage,
      0,
      search,
      column.id,
      !orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "hijo",
      cliente,
      fecha
    );
    setPage(0);
    setinput(column);
    setorden(!orden);
    setcargando(false);
  };
  // REFRES SEARCH SIN ALTERAR
  const funcionReload = async () => {
    setcargando(true);
    setRowsPerPage(10);
    setPage(0);
    await obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify({ ...fullBuscador }),
      rucempresa,
      estadoRequest,
      subEstado,
      "hijo",
      cliente,
      fecha
    );
    setcargando(false);
  };

  // FUNCION QUE PONE LA DATA DE POR DEFECTO O HACE UN REFRESH SEARCH POR DEFECTO
  const funcionDefault = async () => {
    setcargando(true);
    const fullBRes = defaultFullBuscador();
    setfullBuscador(fullBRes);
    await obtenerState(
      rowsPerPage,
      0,
      "",
      input.id,
      !orden,
      JSON.stringify(fullBRes),
      rucempresa,
      estadoRequest,
      subEstado,
      "hijo",
      cliente,
      fecha
    );

    setPage(0);
    setsearch("");
    setbuscando(false);
    setcargando(false);
    setopenMB(false);
  };
  useEffect(() => {
    //eslint-disable-next-line

    obtenerState(
      10,
      0,
      search,
      input.id,
      orden,
      JSON.stringify(fullBuscador),
      rucempresa,
      estadoRequest,
      subEstado,
      "hijo",
      cliente,
      fecha
    );

    // eslint-disable-next-line
  }, []);
  const {
    agregarState,
    editarState,
    eliminarState,
    eliminarSeleccionState,
  } = useCrud(rows, setrows, campoExport, "antUser", usuario);
  useEffect(() => {
    if (!rowPadre.itemSocket) {
      return;
    }
    const { tipo, item } = rowPadre.itemSocket;
    if (tipo === "agregar") {
      agregarState(item);
    }
    if (tipo === "editar") {
      editarState(item);
    }
    if (tipo === "eliminar") {
      eliminarState(item[campoExport]);
    }
    if (tipo === "eliminarSeleccion") {
      eliminarSeleccionState(item.lista);
    }
    // eslint-disable-next-line
  }, [rowPadre]);

  if (!rows) {
    return <Cargando titulo="Cargando" />;
  }
  if (!pagination) {
    return <Cargando titulo="Cargando" />;
  }

  return (
    <>
      <BarraTablas
        search={search}
        setsearch={setsearch}
        funcion={funcionB}
        buscando={buscando}
        setbuscando={setbuscando}
        LabelBuscador="Buscar por: Cédula, Nombres"
        funcionDefault={funcionDefault}
        ocultarMigasDePan={true}
        botones={[]}
      />{" "}
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        botones={[
          {
            tooltip: "Exportar",
            funcion: async () => {
              try {
                setcargandoDialogOpciones(true);
                console.log({ arrayExport, rowPadre });

                const json = {
                  usuario: usuario.usuario,
                  nombre: usuario.nombre,
                  rucempresa: usuario.rucempresa,
                  lista: {
                    [rowPadre.regCodigo]: arrayExport[rowPadre.regCodigo],
                  },
                  fecha,
                };
                console.log({ json });

                const res = await clienteAxios.post(
                  `reportes/${tabla}`,
                  {
                    ...json,
                  },
                  { responseType: "blob" }
                );
                const pdfBlob = new Blob([res.data], {
                  type: "application/pdf",
                });
                saveAs(pdfBlob, `${tabla}.pdf`);
                setopen(false);
                setcargandoDialogOpciones(false);
              } catch (error) {
                console.log({ error });

                mostrarAlerta("Hubo un error", "error");
                setopen(false);
                setcargandoDialogOpciones(false);
              }
            },
            Icon: PictureAsPdf,
            color: "error",
            id: 1,
            disabled: cargandoDialogOpciones,
          },

          // {
          //   tooltip: "Eliminar",
          //   funcion: () => {
          //     setopenCDEliminar(true);
          //   },
          //   Icon: DeleteForever,
          //   color: "error",
          //   id: 2,
          //   disabled: cargandoDialogOpciones,
          // },
        ]}
        titulo={"Acciones"}
      />
      <ModalB
        defaultFullBuscador={defaultFullBuscador}
        openMB={openMB}
        setopenMB={setopenMB}
        titulo={`Busqueda avanzada (${estadoRequest})`}
        fullBuscador={fullBuscador}
        setfullBuscador={setfullBuscador}
        arrayProps={columnsHijos}
        handleChange={() => {}}
        funcionB={funcionB}
        buscando={buscando}
        funcionDefault={funcionDefault}
      />{" "}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems=" stretch"
        width="100%"
      ></Box>
      <Divider />
      <ToolBarTable
        pagination={pagination}
        rowsPerPage={rowsPerPage}
        rows={rows}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        ocultarPaginacion={false}
        botonesIzquierdos={[
          {
            tooltip: "Actualizar",
            texto: "Actualizar",
            funcion: () => {
              funcionReload();
            },
            disabled: false,
            Icon: Refresh,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "boton",
            variante: "text",
            size: "medium",
            sx: {},
          },
        ]}
        botonesDerechos={[
          {
            tooltip: "Exportar",
            texto: "",
            funcion: () => {
              setopenDialogOpciones(true);
            },
            disabled: arrayExport.length === 0,
            Icon: ListRounded,
            color: "primary",
            id: 1,
            ocultar: false,
            tipo: "icono",
            variante: "text",
            size: "medium",
            sx: { margin: 0, padding: 0 },
          },
        ]}
      />
      <Paper>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TablaCabecera
              rows={rows}
              setarrayExport={setarrayExport}
              campoExport={campoExport}
              columns={columnsHijos}
              habilitarOrdenar={true}
              agrupado={true}
              rowPadre={rowPadre}
              //nuevos
              input={input}
              orden={orden}
              funcionOrdenar={funcionOrdenar}
              ocultarPrimeraColumna={false}
            />
          </TableHead>
          <TableBody component={"div"}>
            {cargando ? (
              <TableCell
                size="small"
                align="center"
                colSpan={columnsHijos.length + 2}
              >
                <Cargando titulo="Cargando..." />
              </TableCell>
            ) : (
              rows.map((rowHijo, index) => {
                return (
                  <RowHijo
                    // HOOK DE PERMISO
                    tienePermiso={tienePermiso}
                    alertaPermiso={alertaPermiso}
                    // ROW
                    index={index}
                    rowHijo={rowHijo}
                    rowPadre={rowPadre}
                    // OPEN Y EDIT
                    setopen={setopen}
                    seteditar={seteditar}
                    // EXPORT
                    campoExport={campoExport}
                    arrayExport={arrayExport}
                    setarrayExport={setarrayExport}
                    // FUNCION STATE
                    changeObjetoActivoState={changeObjetoActivoState}
                    // SOCKET
                    socket={socket}
                    tabla={tabla}
                    // ESTADO
                    estadoRequest={estadoRequest}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
};

export default Hijo;
