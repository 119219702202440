import React, { memo, useContext, useState } from "react";
import IconBreadcrumbs from "../Extra/MigasDePan";
import AlertaContext from "../../Context/Alerta/AlertaContext";
import { withRouter } from "react-router";

import { lightBlue } from "@mui/material/colors";
import { permisoDenagado } from "../../config/const";
import { tienePermisoRuta } from "../../config/validaciones";
import AuthContext from "../../Context/Auth/AuthContext";
import MenuContext from "../../Context/Menu/MenuContext";
import DialogOpciones from "./DialogOpciones";
import { makeStyles } from "@mui/styles";
import { Button, Box, IconButton, TextField } from "@mui/material";
import { Add, Backspace, FindInPage, SearchTwoTone } from "@mui/icons-material"; 
const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("md")]: {
      padding: "5px",
      margin: "2px",
      width: "100%",
    },
    fontWeight: "bold",
    margin: theme.spacing(1),
  },
  margin: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "100%",
    },
    margin: theme.spacing(1),
    width: "60%",
  },
  buscadores: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
      margin: "2px",
      width: "100%",
    },
    flexGrow: 1,
    margin: theme.spacing(1),
    width: "30%",
  },
  buscador: {
    width: "40%",
  },
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: lightBlue[500],
    "&:hover": {
      backgroundColor: lightBlue[700],
    },
  },
  fabProgress: {
    color: lightBlue[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: lightBlue[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  ocultar: {
    display: "none",
  },
}));
const BarraTablas = (props) => {
  const classes = useStyles();
  const [openDialogOpciones, setopenDialogOpciones] = useState(false);
  const {
    setopen,
    ocultarAgregar,
    search,
    setsearch,
    funcion,
    buscando,
    setbuscando,
    disabled,
    LabelBuscador,
    ocultarNigasDePan,
    funcionDefault,
    ocultarBuscar,
    setopenMB,
    subirArchivo,
    funcArchivo,
    ats,
    setopenATS,
    parametroArchivo,
    parametroParaElArchivo,
    setparametroParaElArchivo,
    estadoRequest,
    //nuevaOPcion
    nuevaOpcion,
    funcionNuevaFuncion,
    tituloNuevaOpcion,
    iconoNuevaFuncion,

    //PERSONALIZADO
    personalizado,
    PBText,
    PBColor,
    PBIcono,
    // PBFuncion,
    SBText,
    SBColor,
    SBIcono,
    SBFuncion,
  } = props;
  // const { usuario } = useContext(AuthContext);
   

  const { mostrarAlerta } = useContext(AlertaContext);
  const { usuario } = useContext(AuthContext);
  const { rows } = useContext(MenuContext);
  const handleSubmit = (e) => {
    e.preventDefault();

    if (search.trim() === "") {
      return mostrarAlerta("ingrese una busqueda", "error");
    }
    setbuscando(false);
    funcion(search.trim());
    setbuscando(true);
  };

  return (
    <div>
      <DialogOpciones
        open={openDialogOpciones}
        setopen={setopenDialogOpciones}
        ocultarBuscar={ocultarBuscar}
        setopenMB={setopenMB}
        subirArchivo={subirArchivo}
        funcArchivo={funcArchivo}
        ats={ats}
        setopenATS={setopenATS}
        parametroArchivo={parametroArchivo}
        parametroParaElArchivo={parametroParaElArchivo}
        setparametroParaElArchivo={setparametroParaElArchivo}
        estadoRequest={estadoRequest}
        //NuevaOpcion
        nuevaOpcion={nuevaOpcion}
        funcionNuevaFuncion={funcionNuevaFuncion}
        tituloNuevaOpcion={tituloNuevaOpcion}
        iconoNuevaFuncion={iconoNuevaFuncion}
        //personalizado
        personalizado={personalizado}
        setopenModal={setopen}
      />
      {ocultarNigasDePan ? null : <IconBreadcrumbs />}
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
        pb={2}
        mt={2}
        flexWrap="wrap"
        flexGrow="1"
      >
        <form onSubmit={handleSubmit} className={classes.margin}>
          <TextField
            id=""
            label={LabelBuscador ? LabelBuscador : "Buscar"}
            variant="outlined"
            fullWidth
            color="primary"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <Box display="flex" justifyContent="space-between">
                  {" "}
                  {buscando ? (
                    <IconButton
                      aria-label=""
                      color="secondary"
                      onClick={async () => {
                        await funcionDefault();
                      }}
                    >
                      <Backspace color="error" />
                    </IconButton>
                  ) : null}
                  <IconButton aria-label="" color="secondary" type="submit">
                    <FindInPage />
                  </IconButton>
                </Box>
              ),
              labelWidth: "600",
            }}
          />
        </form>

        <Box
          display="flex"
          justifyContent="flex-end"
          flexWrap="wrap"
          flexGrow="1"
        >
          {personalizado ? (
            <>
              <Button
                variant="contained"
                color={PBColor}
                className={classes.button}
                disabled={disabled}
                onClick={() => {
                  if (
                    !tienePermisoRuta(
                      rows,
                      props.location.pathname,
                      usuario.rol,
                      "añadir"
                    )
                  ) {
                    return mostrarAlerta(permisoDenagado, "error");
                  }
                  setopenDialogOpciones(true);
                }}
                startIcon={<>{PBIcono}</>}
              >
                {PBText}
              </Button>
              <Button
                variant="contained"
                color={SBColor}
                className={classes.button}
                disabled={disabled}
                onClick={() => {
                  if (
                    !tienePermisoRuta(
                      rows,
                      props.location.pathname,
                      usuario.rol,
                      "añadir"
                    )
                  ) {
                    return mostrarAlerta(permisoDenagado, "error");
                  }
                  SBFuncion();
                }}
                startIcon={<>{SBIcono}</>}
              >
                {SBText}
              </Button>
            </>
          ) : (
            <>
              {ocultarAgregar ? null : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={disabled}
                    onClick={() => {
                      setopen(true);
                    }}
                    startIcon={
                      <>
                        {" "}
                        {/* <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        /> */}
                        <Add />
                      </>
                    }
                  >
                    Agregar
                  </Button>
                </>
              )}
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.button}
                startIcon={<SearchTwoTone />}
                onClick={() => setopenMB(true)}
              >
                Busquedas
              </Button>
            </>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default withRouter(memo(BarraTablas));
