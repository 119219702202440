import { useContext } from "react";
import clienteAxios from "../../config/axios";
import AlertaContext from "../../Context/Alerta/AlertaContext";

export const useGetNameUser = () => {
  const { mostrarAlerta } = useContext(AlertaContext);
  const handleGetNameUser = async (documento) => {
    if (!documento) {
      return;
    }
    try {
      const resUser = await clienteAxios.get(`/personas/usuario/${documento}`);
      console.log({ resUser });

      mostrarAlerta(resUser?.data?.nombre, "success");
    } catch (error) {
      console.log({ error });

      mostrarAlerta("Hubo un error", "error");
    }
  };

  return { handleGetNameUser };
};
